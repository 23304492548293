import type { Range } from '@attest/util'

export type DemographicCategory = 'core' | 'work' | 'personal' | 'household'

export type DemographicMap = {
  demographics: Demographic[]
}

export type CategorisedDemographics = {
  [category: string]: Demographic[]
}

export type DemographicDisplayValues = {
  [demographic: string]: DemographicDisplayValue
}

export type DemographicDisplayValue = {
  display: string
  order: number
  type: DemographicType
  values: DefaultOption[]
}

export type DemographicType = 'range' | 'default'

export type DemographicOption = DefaultOption | RangeOption

export type DefaultOption = {
  name: string
  display: string
}

export type RangeOption = Range

export type Demographic = {
  name: string
  display: string
  category: DemographicCategory
  order: number
  targetable: boolean
  isMultipleChoice: boolean
  supportedCountryLanguages: string[]
  type: DemographicType
  options: DemographicOption[]
}

export type Demographics = {
  [key: string]: Demographic
}

export function isDefaultOption(option: DemographicOption): option is DefaultOption {
  const defaultOption = option as DefaultOption
  return defaultOption.name !== undefined && defaultOption.display !== undefined
}

export function isRangeOption(
  option: DemographicOption | string | undefined,
): option is RangeOption {
  if (typeof option !== 'object') return false
  if (!('min' in option) || !('max' in option)) return false

  return option.min !== undefined && option.max !== undefined
}

export function isDefaultOrRange(option: DemographicOption): option is DefaultOption | RangeOption {
  return isDefaultOption(option) || isRangeOption(option)
}

export function isRangeOptions(options: DemographicOption[]): options is RangeOption[] {
  if (options.length > 0) {
    return isRangeOption(options[0])
  }
  return false
}

export function isMultiRangeOptions(entries: DemographicOption[]): entries is RangeOption[] {
  return entries.length > 1 && entries.every(entry => isRangeOption(entry))
}

export function isDefaultOptions(options: DemographicOption[]): options is DefaultOption[] {
  if (options.length > 0) {
    return isDefaultOption(options[0])
  }
  return false
}
