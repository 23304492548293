<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create, type ElementAttributes } from '@attest/vue-tsx'

type TagTheme = 'default' | 'error' | 'green' | 'orange' | 'berry' | 'yellow' | 'coral' | 'blue'
type TagProps = {
  theme?: TagTheme
} & ElementAttributes<'div'>

const baseClass = `c-tag`
const Tag: FunctionalComponent<TagProps> = (props, { slots }) => {
  return (
    <div
      class={{
        [baseClass]: true,
        [`${baseClass}--theme-${props.theme ?? 'default'}`]: true,
      }}
      {...props}
    >
      {slots.default?.()}
    </div>
  )
}

export default create<TagProps>(Tag)
</script>

<style lang="postcss">
.c-tag {
  display: inline-block;
  overflow: hidden;
  width: auto;
  min-width: min-content;
  max-height: 20px;
  border: var(--attest-border-width-s) solid var(--custom-border-color);
  border-radius: 12px;
  background-color: var(--custom-background-color);
  color: var(--custom-color);
  font: var(--attest-font-body-2);
  line-height: 18px;
  padding-inline: var(--attest-spacing-2);
  white-space: nowrap;

  svg {
    color: var(--custom-icon-color);
  }

  &--theme-default {
    --custom-border-color: var(--attest-color-decorative-grey-border-subdued);
    --custom-background-color: var(--attest-color-decorative-grey-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-grey-icon);
    --custom-color: var(--attest-color-decorative-grey-text);
  }

  &--theme-teal {
    --custom-border-color: var(--attest-color-decorative-teal-border-subdued);
    --custom-background-color: var(--attest-color-decorative-teal-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-teal-icon);
    --custom-color: var(--attest-color-decorative-teal-text);
  }

  &--theme-error {
    --custom-border-color: var(--attest-palette-red-80);
    --custom-background-color: var(--attest-palette-red-70);
    --custom-icon-color: var(--attest-palette-red-50);
    --custom-color: var(--attest-color-text-on-interactive);
  }

  &--theme-green {
    --custom-border-color: var(--attest-color-decorative-green-border-subdued);
    --custom-background-color: var(--attest-color-decorative-green-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-green-icon);
    --custom-color: var(--attest-color-decorative-green-text);
  }

  &--theme-orange {
    --custom-border-color: var(--attest-color-decorative-firecracker-border-subdued);
    --custom-background-color: var(--attest-color-decorative-firecracker-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-orange-icon);
    --custom-color: var(--attest-color-decorative-firecracker-text);
  }

  &--theme-yellow {
    --custom-border-color: var(--attest-color-decorative-yellow-border-subdued);
    --custom-background-color: var(--attest-color-decorative-yellow-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-yellow-icon);
    --custom-color: var(--attest-color-decorative-yellow-text);
  }

  &--theme-berry {
    --custom-border-color: var(--attest-color-decorative-berry-border-subdued);
    --custom-background-color: var(--attest-color-decorative-berry-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-berry-icon);
    --custom-color: var(--attest-color-decorative-berry-text);
  }

  &--theme-coral {
    --custom-border-color: var(--attest-color-decorative-coral-border-subdued);
    --custom-background-color: var(--attest-color-decorative-coral-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-coral-icon);
    --custom-color: var(--attest-color-decorative-coral-text);
  }

  &--theme-blue {
    --custom-border-color: var(--attest-color-decorative-blue-border-subdued);
    --custom-background-color: var(--attest-color-decorative-blue-surface-subdued);
    --custom-icon-color: var(--attest-color-decorative-blue-icon);
    --custom-color: var(--attest-color-decorative-blue-text);
  }
}
</style>
