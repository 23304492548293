import { c, type TranslationKeyToCopyMeta } from '@attest/intl'
import { capture } from '@attest/telemetry'

type GetErrorMessageOptions = {
  code: string
  silentFallback?: true
  namespace?: string
}

/**
 * Get error message from error code.
 * - `options.namespace` define custom namespace that errors are defined on
 * - `options.silentFallback` prevent capturing error if using fallback value is intentional
 *
 * @example
 * ```ts
 * const [_, error] = await fetchRequest()
 *
 * if (isFetchError(error)) {
 *   const { code } = error.data
 *   toast(getErrorMessage(`error.message.custom.${code}`, { code, namespace: 'custom'  }))
 *   return
 * }
 * ```
 */
export function getErrorMessage(
  _key: TranslationKeyToCopyMeta,
  options: GetErrorMessageOptions,
): string {
  const { namespace, silentFallback, code } = options
  if (namespace) {
    const copy = c(`error.message.${namespace}.${code}` as any, { silent: true })
    if (copy === undefined) {
      if (!silentFallback) {
        capture(
          new Error(
            `error.message.${namespace}.${code} not part of translations, defaulting to default error messages`,
          ),
        )
      }
    } else {
      return copy
    }
  }

  const copy = c(`error.message.${code}` as any, { silent: true })
  if (copy === undefined) {
    capture(
      new Error(`error.message.${code} not part of translations, defaulting to UNKNOWN message`),
    )
    return c(`error.message.UNKNOWN`)
  }
  return copy
}
