import { transpose, uniq } from 'ramda'

import type { StudyMessageCard } from '../card/message/model'
import type { StudyCard, StudyQuestionCard } from '../card/model'
import {
  createStudyQuestionStructure,
  createStudyQuestionStructureOptionSettings,
} from '../card/question/structure/factory'
import type { StudyQuestionStructure } from '../card/question/structure/model'
import {
  createImageOption,
  createNAOption,
  createNoneOption,
  createTextOption,
} from '../card/question/structure/option/factory'
import type { Option } from '../card/question/structure/option/model'
import { createSubject } from '../card/question/structure/subject/factory'
import type { Subject } from '../card/question/structure/subject/model'

import type { ApiStudyMessageCard } from './message-card/model'
import { createStudyMessageCard } from './message-card/transformer'
import {
  type ApiDisplayLogic,
  type ApiStudyQuestionCard,
  isApiStudyQuestionCard,
} from './question/model'
import {
  createStudyQuestionCard,
  createStudyQuestionMedia,
  transformApiDisplayLogicToModel,
} from './question/transformer'
import type { ApiStudyWave } from './wave'

export type ApiStudyGridQuestionCardStructure = {
  title: string
  answers: {
    subjects: ApiStudyGridQuestionCardStructureSubjects
    options: ApiStudyGridQuestionCardStructureOptions
    choices: ApiStudyGridQuestionCardStructureChoice[]
  }
  next?: string
  display_logic?: ApiDisplayLogic
  title_content?: { image_url?: string; audio_url?: string; video_url?: string }
}

export type ApiStudyGridQuestionCardStructureSubjects = {
  headers: ApiStudyGridQuestionCardStructureSubjectsHeader[]
  randomized: boolean
}

export type ApiStudyGridQuestionCardStructureSubjectsHeader = {
  type: 'text'
  id: string
  text: string
  pinned: boolean
  mask?: {
    card_id: string
    choice_id: string
  }
}

export type ApiStudyGridQuestionCardStructureOptions = {
  headers: ApiStudyGridQuestionCardStructureOptionsHeader[]
  type: 'single_choice' | 'multiple_choice'
  max_selections?: number
  randomized: boolean
}

export type ApiStudyGridQuestionCardStructureOptionsHeader = {
  type: 'text' | 'image'
  id: string
  text: string
  pinned: boolean
  filename?: string
  image_url?: string
}

export type ApiStudyGridQuestionCardStructureChoice = {
  subject_id: string
  values: ApiStudyGridQuestionCardStructureChoiceValue[]
}

export type ApiStudyGridQuestionCardStructureChoiceValue = {
  id: string
  option_id: string
  type: 'cell' | 'na' | 'none'
}

export function createStudyGridQuestionCardStructure({
  apiWave,
  apiStructure,
}: {
  apiWave: ApiStudyWave | undefined
  apiStructure: ApiStudyGridQuestionCardStructure
}): StudyQuestionStructure {
  if (apiWave === undefined) throw new Error('API wave not found')
  return createStudyQuestionStructure({
    title: apiStructure.title,
    waveId: apiWave.id,
    publishedTimestamp: apiWave.published ?? undefined,
    subjects: apiStructure.answers.choices.map((choices, index) => ({
      ...createOptionFromGridSubjectHeader(
        apiStructure.answers.subjects.headers[index],
        apiStructure.answers.subjects.randomized,
      ),
      answerIds: choices.values.flatMap(({ id }) => id),
    })),
    options: transpose(apiStructure.answers.choices.map(({ values }) => values)).map(
      (options, index) => ({
        ...createOptionFromGridOptionHeader(
          uniq(options.flatMap(({ type }) => type))[0],
          apiStructure.answers.options.headers[index],
          apiStructure.answers.options.randomized,
        ),
        answerIds: options.flatMap(({ id }) => id),
      }),
    ),
    optionSettings: createStudyQuestionStructureOptionSettings({
      type: apiStructure.answers.options.type === 'single_choice' ? 'single' : 'multiple',
      limit: apiStructure.answers.options.max_selections ?? undefined,
    }),
    displayLogic: transformApiDisplayLogicToModel(apiStructure.display_logic),
    mediaUrl:
      apiStructure.title_content?.image_url ??
      apiStructure.title_content?.audio_url ??
      apiStructure.title_content?.video_url,
  })
}

function createOptionFromGridOptionHeader(
  type: 'cell' | 'na' | 'none',
  apiHeader: ApiStudyGridQuestionCardStructure['answers']['options']['headers'][number],
  isRandomized: boolean,
): Option {
  if (type === 'na') return createNAOption()
  if (type === 'none') return createNoneOption()
  return createOptionFromGridOptionHeaderCell(apiHeader, isRandomized)
}

function createOptionFromGridOptionHeaderCell(
  apiHeader: ApiStudyGridQuestionCardStructure['answers']['options']['headers'][number],
  isRandomized: boolean,
): Option {
  return apiHeader.image_url
    ? createImageOption({
        id: apiHeader.id,
        answerIds: [apiHeader.id],
        text: apiHeader.text,
        imageUrl: apiHeader.image_url,
        filename: apiHeader.filename ?? undefined,
        isRandomized: isRandomized && !apiHeader.pinned,
        isPinned: apiHeader.pinned,
      })
    : createTextOption({
        id: apiHeader.id,
        answerIds: [apiHeader.id],
        text: apiHeader.text,
        isRandomized: isRandomized && !apiHeader.pinned,
        isPinned: apiHeader.pinned,
      })
}

function createOptionFromGridSubjectHeader(
  apiHeader: ApiStudyGridQuestionCardStructure['answers']['subjects']['headers'][number],
  isRandomized: boolean,
): Subject {
  return createSubject({
    id: apiHeader.id,
    text: apiHeader.text,
    isRandomized: isRandomized && !apiHeader.pinned,
    isPinned: apiHeader.pinned,
    mask: apiHeader.mask
      ? { answerId: apiHeader.mask.choice_id, cardId: apiHeader.mask.card_id }
      : undefined,
  })
}

export type ApiStudyGridQuestionCard = {
  guid: string
  question_guid: string
  card_type: 'grid'
  title: string
  title_content: {
    audio_url?: string
    image_url?: string
    video_url?: string
  }
  answers: {
    options: {
      groups: { name?: string; choices: { id: string }[] }[]
    }
  }
  waves: Record<string, ApiStudyGridQuestionCardStructure>
  template_answers: {
    subjects: { headers: Array<{ type: string; id: string; text: string }> }
    options: { headers: Array<{ type: string; id: string; text: string }> }
  }
}

export function isApiStudyGridQuestionCard(apiCard: {
  card_type: string
}): apiCard is ApiStudyGridQuestionCard {
  return apiCard.card_type === 'grid'
}

export function createStudyGridQuestionCard(
  apiCard: ApiStudyGridQuestionCard,
  apiWaves: ApiStudyWave[],
): StudyQuestionCard {
  return {
    id: apiCard.guid,
    type: 'question',
    title: apiCard.title,
    publishedTimestamp: null,
    deletedTimestamp: null,
    question: {
      id: apiCard.question_guid,
      type: 'grid',
      optionGroups: apiCard.answers.options.groups.map(apiGroup => ({
        text: apiGroup.name,
        ids: apiGroup.choices.map(({ id }) => id),
      })),
      structures: Object.entries(apiCard.waves).map(([waveId, apiStructure]) =>
        createStudyGridQuestionCardStructure({
          apiWave: apiWaves.find(wave => wave.id === waveId),
          apiStructure,
        }),
      ),
    },
    media: createStudyQuestionMedia(apiCard.title_content),
    templateAnswers: apiCard.template_answers,
  }
}

export function createStudyCard(
  apiCard: ApiStudyCard,
  apiWaves: ApiStudyWave[],
): StudyCard | StudyMessageCard {
  if (isApiStudyQuestionCard(apiCard)) {
    return createStudyQuestionCard(apiCard, apiWaves)
  }

  if (isApiStudyGridQuestionCard(apiCard)) {
    return createStudyGridQuestionCard(apiCard, apiWaves)
  }

  return createStudyMessageCard(apiCard, apiWaves)
}

export type ApiStudyCard = ApiStudyMessageCard | ApiStudyQuestionCard
