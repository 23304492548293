import type { RouteLocation, Router } from 'vue-router'

import type { ApiError } from '@attest/_api'
import type {
  DeleteUserError,
  ListUsersErrorType,
  MFACreationError,
  MFADeletionError,
  MFAError,
  PublishSurveyError,
  RestoreSurveyError,
  SignificanceTestError,
  UpdateOrganisationStatusError,
  UpdateProfileError,
  UpdateUserRoleError,
} from '@attest/_api/model/error'
import { c } from '@attest/intl'

import { PASSWORD_MIN_LENGTH } from './input'

export const ERROR_MESSAGES_GENERAL: Record<ApiError, string> = {
  NOT_FOUND: 'This page is not available',
  NOT_FOUND_SURVEY: 'This survey is not available',
  FAILED_TO_SAVE: 'Failed to save',
  NOT_FOUND_DRAFT: 'This draft is not available',
  NETWORK_ERROR: 'Network error, connection lost',
  UNKNOWN_ERROR: 'Something unexpected has gone wrong, \nplease try again',
  UNKNOWN: 'Something unexpected has gone wrong, \nplease try again',
  CRITICAL: `Something unexpected has gone wrong - our engineers have been notified and are working on fixing the problem.\n Please bear with us, or get in touch directly if it's urgent.`,
  UNAUTHORIZED: `Session has expired, \nplease login again`,
  ORGANISATION_NOT_FOUND: 'Organisation not found',
  NOT_SURVEY_CREATOR_ORG_GRANTED: 'Not survey creator org granted',
}

export const ERROR_MESSAGES_UPDATE_PROFILE: Record<UpdateProfileError, string> = {
  MISSING_INFORMATION_FIELDS:
    'One or more fields are missing data. Please fill out the form and try again.',
  MISSING_JOB_ROLE: 'A job role must be selected. Please select a valid option and try again.',
  EMAIL_INVALID: 'Invalid email address',
  COMPANY_NAME_IS_MISSING: 'Company name is missing',
  INVALID_FIELD_CONTENT: 'Company names can only contain alphanumeric characters.',
  INVALID_ORGANISATION_NAME: `Your company name can't contain symbols`,
  EMPTY_ORGANISATION_NAME: 'Company name is missing',
}

export const ERROR_MESSAGES_RESTORE_SURVEY: Record<RestoreSurveyError, string> = {
  NOT_ARCHIVED: c('errors.restore_survey.not_deleted'),
}

export const ERROR_MESSAGES_LIST_USERS: Record<ListUsersErrorType, string> = {
  MAKER_NOT_IN_ORGANISATION: 'User does not belong to this organisation',
}

export const ERROR_MESSAGES_PUBLISH_SURVEY: Record<PublishSurveyError, string> = {
  SURVEY_NOT_DRAFT: 'Survey is not a draft, unable to save',
  SURVEY_NOT_FOUND: 'Survey not found',
  TITLE_TOO_LONG: `Survey title exceeds maximum length`,
  INVALID_TITLE: 'Invalid title provided',
  EXPIRES_BEFORE_STARTS: 'The survey expires before it starts',
  NOT_ENOUGH_QUESTIONS: 'Not enough questions, please add more',
  QUALIFYING_QUESTIONS_NOT_ALLOWED: 'Qualifying questions are not allowed',
  MAX_REPONSES_NOT_ALLOWED: 'You cannot specify the maximum number of responses',
  MISSING_MAX_RESPONSES: 'Max responses is mising',
  UNKNOWN_PRICING_PLAN: 'Unknown pricing plan',
  INVALID_SEGMENTATION_ERROR: 'Invalid segmentation provided',
  TOO_MANY_QUESTIONS: 'You have provided too many questions',
  FAILED_TO_SAVE: 'Unable to process. Please try again',
  INSUFFICIENT_FUNDS: `Insufficient funds. Your account does not currently have enough credit to complete this purchase`,
  STARTS_NOT_SPECIFIED: 'Your survey is missing a start date',
  EXPIRES_NOT_SPECIFIED: 'Your survey is missing an end date',
  BLANK_CARD_NOT_SUPPORTED: 'You have left a blank card in your survey',
  MULTIPLE_STARTING_CARDS: 'Your survey has more than one starting point.',
  FAILED_TO_PUBLISH:
    'We were unable to process your payment. Please try again or contact us at support@askattest.com',
  MISSING_COUNTRY: 'Choose a country',
  MISSING_LANGUAGE: 'Choose a language',
  BLANK_CARD_NOT_ALLOWED: 'One of your cards has no content',
  TARGETING_NOT_ALLOWED: 'There is a problem with your targeted audience.',
  QUOTAS_NOT_ALLOWED: 'There is a problem with your quotas',
  UNSUPPORTED_PRICING_PLAN:
    'We were unable to process your payment. Please try again or contact us at support@askattest.com',
  MAKER_NOT_FOUND: `We couldn't find your account, please contact us at support@askattest.com`,
  WALLET_NOT_FOUND:
    'We were unable to process your payment. Please try again or contact us at support@askattest.com',
  SURVEY_DOES_NOT_BELONG_TO_MAKER: 'This survey belongs to another user',
  INVALID_COUNTRY_LANGUAGE_SEGMENT:
    'You have chosen a demographic that does not exist for this country/language pair',
  INVALID_COUNTRY_LANGUAGE_PAIR: 'The country you have chosen does not support the chosen language',
  INVALID_VIDEO_TITLE_CONTENT: 'One of your videos is invalid, please check the video settings.',
  INVALID_AUDIO_TITLE_CONTENT: 'One of your cards has an invalid audio clip attached.',
  INVALID_IMAGE_TITLE_CONTENT: 'One of your cards has an invalid image attached.',
  TRANSACTION_SALE_FAILURE:
    'Your payment has been declined by your bank. Please contact your bank and then try again, or try using a different card.',
  MESSAGE_CARD_TEXT_TOO_LONG: c('editor.error.purchase.message_card_text_too_long'),
  QUOTA_MAX_RESPONDENTS_ZERO: c('error.message.QUOTA_MAX_RESPONDENTS_ZERO'),
}

export const ERROR_MESSAGES_USER = {
  ...ERROR_MESSAGES_GENERAL,
  MISSING_EMAIL: 'Email address not provided',
  EMAIL_TOO_LONG: 'Email given is too long',
  EMAIL_NOT_CHANGED: 'Email has not changed',
  INVALID_EMAIL: 'Invalid email address',
  EMAIL_INVALID: 'Invalid email address',
  MISSING_PASSWORD: 'Missing password',
  PASSWORD_TOO_SHORT: `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`,
  INCORRECT_PASSWORD: 'Incorrect password',
  NO_PASSWORD:
    'You have not yet set up a password, please click on "Forgot password?" to create one',
  MISSING_BUSINESS_NAME: 'Organisation not provided',
  FIRST_NAME_MISSING: 'Your first name is required',
  LAST_NAME_MISSING: 'A last name is required',
  EMAIL_IS_TAKEN: 'This email is already in use on this service',
  TERMS_NOT_AGREED: 'Please accept the terms and conditions',
  PROCESS_ERROR_INVITER_CODE: 'An error occurred processing your invite',
  INVITE_CODE_INVALID:
    'Invite code was not found.\nIt might not exist, or might not be valid for your account',
  INVALID_INVITER_CODE:
    'Invite code was not found.\nIt might not exist, or might not be valid for your account',
  EXPIRED_INVIER_CODE: 'Invite code has expired, \nplease contact the organisation owner',
  USED_INVITER_CODE: 'Invite code has already been used',
  REVOKED_INVITER_CODE: 'Invite has been revoked, \nplease contact the organisation owner',
  NO_ORGANISATION_FOR_INVITER_CODE: 'The organisation you are joining no longer exists',
  NOT_FOUND_INVITER_CODE:
    'Invite code was not found.\nIt might not exist, or might not be valid for your account',
  ACCOUNT_NOT_ACTIVATED: 'Your account is not yet activated',
  ACCOUNT_BANNED: 'Your account has been removed',
  ACCOUNT_DELETED: 'Your account has been deleted',
  ACCOUNT_DISABLED: 'Your account has been disabled',
  USER_NOT_FOUND: 'Account not found',
  ACCOUNT_LOCKED:
    'Account locked due to too many failed login attempts. Please reset your password or contact customer care.',
  ACCOUNT_BANNED_DUPLICATE: 'This account has been disabled as a duplicate account.',
  INVALID_PAYMENT_METHOD: 'Invalid payment method',
  NEW_PASSWORD_MISSING: 'New password is missing',
  NEW_PASSWORD_TOO_SHORT: 'New password must be at least 6 characters long',
  CURRENT_PASSWORD_MISSING: 'Current password is missing',
  EMAIL_NOT_VALIDATED:
    'Please validate your email address using the link provided in the email we sent',
  UNKNOWN:
    'Oops! Something went wrong on our end. Please try again or contact us at support@askattest.com.',
  ORGANISATION_INACTIVE: 'Your account is inactive, please get in touch.',
  TOO_MANY_ATTEMPTS: 'You have made too many login attempts.',
  INVALID_TOKEN: 'Invalid verification code, please try again',
}

export const ERROR_MESSAGES_INVITE_USER = {
  NOT_FOUND: 'The organisation you want to invite others to could not be found.',
  UNKNOWN: 'Your invitation could not be sent, please try again.',
  INVALID_EMAIL:
    'The email address seems to be invalid. Please check the format of the email address.',
  EMAIL_IS_TAKEN: 'The person you want to invite is already part of an Attest organisation.',
  INACTIVE_MAKER: 'The person you want to invite has been removed from this Attest organisation.',
  UNAUTHORIZED_DOMAIN:
    'It looks like you want to invite a person outside of your company. Please contact customer care.',
}

export const ERROR_MESSAGES_COMMENT = {
  NOT_FOUND: 'This survey is not available',
  NOT_FOUND_SURVEY: 'This survey is not available',
  FAILED_TO_SAVE: 'Your comment could not be sent, please try again.',
  NOT_FOUND_DRAFT: 'This draft is not available',
  NETWORK_ERROR: 'Your comment could not be sent, please try again.',
  UNKNOWN_ERROR: 'Your comment could not be sent, please try again.',
  UNKNOWN: 'Your comment could not be sent, please try again.',
  CRITICAL: `Something unexpected has gone wrong - our engineers have been notified and are working on fixing the problem.\n Please bear with us, or get in touch directly if it's urgent.`,
}

export const ERROR_MESSAGES_CREDIT_POTS = {
  UNAUTHORIZED: "You don't have the permission to do this",
  ORGANISATION_NOT_FOUND: 'Organisation not found',
  NOT_FOUND: 'Organisation not found',
  EMPTY_REQUEST_BODY: 'Your credit pot could not be created, one or more fields are missing.',
  INSUFFICIENT_CREDITS:
    'Your credit pot could not be created, there are not enough credits in the selected pot.',
  NETWORK_ERROR: 'Your credit pot could not be created, please try again.',
  UNKNOWN_ERROR: 'Your credit pot could not be created, please try again.',
  UNKNOWN: 'Your credit pot could not be created, please try again.',
  CRITICAL: `Something unexpected has gone wrong - our engineers have been notified and are working on fixing the problem.\n Please bear with us, or get in touch directly if it's urgent.`,
}

export const ERROR_MESSAGES_CREDIT_POT_MEMBERS = {
  FORBIDDEN: "You don't have the permission to do this",
  ORGANISATION_NOT_FOUND: 'Organisation not found',
  POT_NOT_FOUND: 'The credit pot could not be found.',
  NETWORK_ERROR: 'The credit pot could not get updated, please try again.',
  UNKNOWN_ERROR: 'The credit pot could not get updated, please try again.',
  UNKNOWN: 'The credit pot could not get updated, please try again.',
}

export const ERROR_MESSAGES_IMPERSONATE = {
  UNKNOWN_TARGET_MAKER:
    'The email address seems to be invalid. Please check the format of the email address.',
}

export const ERROR_MESSAGES_FREE_SURVEY = {
  FREE_SURVEY_ALREADY_PURCHASED: `You've already run your free survey.`,
}

export const ERROR_MESSAGES: ErrorMap = { ...ERROR_MESSAGES_GENERAL, ...ERROR_MESSAGES_USER }

export const ERROR_MESSAGES_UPDATE_ORGANISATION_STATUS: Record<
  UpdateOrganisationStatusError,
  string
> = {
  UNAUTHORIZED: "You don't have the permission to do this",
  INVALID_STATUS: 'Unknown status provided',
  ORGANISATION_NOT_FOUND: 'Organisation not found',
  NOT_FOUND: 'Organisation not found',
}

export const ERROR_MESSAGES_DELETE_USER: Record<DeleteUserError, string> = {
  MAKER_NOT_FOUND: "We can't seem to find that user",
  UNAUTHORIZED: "You can't delete that user",
  MAKER_IS_WALLET_OWNER: "You can't delete that user",
  SELF_DELETION: "You can't delete your own account",
}

export const ERROR_MESSAGES_USER_ROLES: Record<UpdateUserRoleError, string> = {
  UNKNOWN: 'The user could not be updated, please try again.',
  NOT_FOUND: "We can't seem to find that user",
  ROLE_ADDITION_ERROR: 'We could not change the role of this user.',
  UNAUTHORIZED: "You can't change the role of this user",
}

const BASE_MFA_ERROR_MESSAGE = `We can't seem to verify your mfa device, please try again or contact us at support@askattest.com`
export const ERROR_MESSAGES_MFA_CREATION: Record<MFACreationError | 'UNKNOWN_ERROR', string> = {
  MFA_ALREADY_EXISTS: BASE_MFA_ERROR_MESSAGE,
  MFA_MISSING: BASE_MFA_ERROR_MESSAGE,
  MFA_ALREADY_VERIFIED: BASE_MFA_ERROR_MESSAGE,
  MAKER_NOT_FOUND: BASE_MFA_ERROR_MESSAGE,
  REQUEST_VALIDATION: BASE_MFA_ERROR_MESSAGE,
  TOTP_REQUIRED: BASE_MFA_ERROR_MESSAGE,
  INVALID_TOKEN: 'Invalid verification code, please try again',
  UNKNOWN_ERROR: BASE_MFA_ERROR_MESSAGE,
}

export const ERROR_MESSAGES_MFA_DELETION: Record<MFADeletionError | 'UNKNOWN_ERROR', string> = {
  MFA_MISSING: BASE_MFA_ERROR_MESSAGE,
  MAKER_NOT_FOUND: BASE_MFA_ERROR_MESSAGE,
  REQUEST_VALIDATION: BASE_MFA_ERROR_MESSAGE,
  INVALID_TOKEN: 'Invalid verification code, please try again',
  UNKNOWN_ERROR: BASE_MFA_ERROR_MESSAGE,
}

export const ERROR_MESSAGES_MFA_CHANGE_PASSWORD: Record<
  Extract<MFAError, 'INVALID_TOKEN'> | 'UNKNOWN_ERROR',
  string
> = {
  INVALID_TOKEN: 'Invalid verification code, please try again',
  UNKNOWN_ERROR: BASE_MFA_ERROR_MESSAGE,
}

export const ERROR_MESSAGES_SIGNIFICANCE_TEST: Record<SignificanceTestError, string> = {
  TARGET_COMPARISON_GROUP_SAME: `Your target group and comparison group can not be the same`,
  SAMPLE_SIZE_TOO_SMALL: `You can not run significance tests on this group, please change your selection`,
}
export type ErrorMap = {
  [K: string]: string
}

export class RedirectError extends Error {
  to: RouteLocation

  constructor(to: RouteLocation) {
    super('Redirection')

    this.to = to

    Object.setPrototypeOf(this, new.target.prototype)
  }

  resolve(router: Router) {
    return router.resolve(this.to)
  }

  getPath(router: Router) {
    return this.resolve(router).href
  }
}
