import Highcharts from 'highcharts'
import highchartsAccessibility from 'highcharts/modules/accessibility'
import type { Pinia } from 'pinia'
import type { App } from 'vue'
import type { Router } from 'vue-router'

import { interceptResponse } from '@attest/_api'
import { setupAppAutoUpdate } from '@attest/app-auto-update'
import { setupClearErrorsOnRouteChange } from '@attest/app-error'
import { CONFIG } from '@attest/config'
import { useEditorSurveyStore } from '@attest/editor-survey'
import { ROUTES } from '@attest/router'
import { Thyme, ThymeZone } from '@attest/thyme'

import { setupAuthentication } from './setup/authentication'
import { setupFeatureSwitchClient, setupFeatureSwitchRouteHandler } from './setup/feature-switch'
import { setupPermission } from './setup/permission'
import { setupClient as setupPolyfills } from './setup/polyfill'
import { setupProgressBar } from './setup/progress-bar'
import { setupTracking } from './setup/tracking'

highchartsAccessibility(Highcharts)

export async function client(
  appSetup: Promise<{ pinia: Pinia; router: Router; app: App }>,
): Promise<void> {
  if (!CONFIG.APP.DISABLE_SERVICE_WORKER && navigator.serviceWorker) {
    navigator.serviceWorker.register('/sw.js')
  }

  await setupPolyfills()

  const { pinia, router, app } = await appSetup

  await setupAuthentication(router)
  await setupFeatureSwitchClient(router, pinia)
  setIntitialTimeZoneId()
  setupFeatureSwitchRouteHandler(router)
  setupTracking(router, CONFIG)
  setupProgressBar(router)
  setupPermission(router)
  setupAppAutoUpdate(router)
  setupClearErrorsOnRouteChange(router)
  exposeAppInternals(router)

  await router.isReady()
  await removeLoadingPlaceholder()

  app.mount('#app')

  // eslint-disable-next-line no-console
  router.onError((error: Error) => console.warn(error))

  interceptResponse(
    response => response,
    async error => {
      if (error.response && error.response.status === 401) {
        navigateToLogin(router)
      }

      throw error
    },
  )
}

function navigateToLogin(router: Router): void {
  if (router.currentRoute.value.name === ROUTES.LOGIN.name) return
  window.location.href = router.resolve({
    name: ROUTES.LOGIN.name,
    query: { redirect: router.currentRoute.value.fullPath },
  }).href
}

function setIntitialTimeZoneId(): void {
  const names = ThymeZone.getIANANames()
  const editorSurveyStore = useEditorSurveyStore()
  const timeZoneId = editorSurveyStore.timeZoneId ?? Thyme.local().zone.name

  editorSurveyStore.timeZoneId = names.includes(timeZoneId) ? timeZoneId : 'Europe/London'
}

function exposeAppInternals(router: Router): void {
  if (!('Cypress' in window)) return
  ;(window as any).__INTERNALS__ = {
    router,
    Highcharts,
  }
}

async function removeLoadingPlaceholder(): Promise<void> {
  const loading = document.getElementById('loading')
  if (!loading) return
  loading.dataset.loaded = 'true'
  loading.addEventListener('transitionend', () => {
    loading?.remove()
    document.getElementById('loading-style')?.remove()
  })
}
