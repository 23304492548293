import { nanoid } from 'nanoid'

import { useDemographicsStore } from '@attest/audience-demographic'
import { isStudyOpenTextQuestionCard, type StudyQuestionCard, useStudyStore } from '@attest/study'
import type { SavedSegmentEntityData } from '@attest/tracking'

import type { SavedFilter } from './model/saved-filters'
import {
  isValueCondition,
  Logic,
  type RangeCondition,
  type Segment,
  type ValueCondition,
} from './model/segment'

export function segmentToSavedFilter(args: { segment: Segment }): SavedFilter {
  return {
    name: args.segment.name,
    id: args.segment.id,
    efl: args.segment.efl,
    isShared: Boolean(args.segment.isShared),
  }
}

export function savedFilterToSegment(args: { savedFilter: SavedFilter }): Segment {
  const demographicsStore = useDemographicsStore()
  const segment = {
    name: args.savedFilter.name,
    id: args.savedFilter.id,
    efl: args.savedFilter.efl,
    logic: Logic.AND,
    isShared: args.savedFilter.isShared,
    groups: [
      {
        id: nanoid(),
        name: '',
        groups: [],
        logic: Logic.AND,
        conditions: [
          ...(Object.entries(args.savedFilter.demographicFilters ?? []).map(
            ([demographicFilterVariable, demographicFilterValues]) => {
              return {
                id: nanoid(),
                type: 'demographic',
                variable: demographicFilterVariable,
                values: demographicFilterValues,
                isMultipleChoice:
                  demographicsStore.demographicNameToDemographic[demographicFilterVariable]
                    ?.isMultipleChoice,
              }
            },
          ) as Array<ValueCondition | RangeCondition>),
          ...(Object.entries(args.savedFilter.studyCardFilters ?? []).map(
            ([cardId, cardFilterValues]) => {
              return {
                id: nanoid(),
                type: isCardOpenTextQuestion(cardId) ? 'openText' : 'answer',
                variable: cardId,
                values: cardFilterValues.answerIds,
                subjects: getSubjectIdsForGridQuestionAnswers(cardId, cardFilterValues.answerIds),
                isMultipleChoice: isCardMultipleChoice(cardId),
              }
            },
          ) as Array<ValueCondition>),
          ...((args.savedFilter.surveyFilters ?? []).map(surveyFilter => ({
            id: nanoid(),
            type: surveyFilter.survey_field,
            variable: surveyFilter.survey_field,
            values: surveyFilter.values,
          })) as Array<ValueCondition>),
        ],
      },
    ],
  }
  return segment
}

export function isCardMultipleChoice(cardId: string): boolean {
  const studyStore = useStudyStore()
  const card = studyStore.cardIdToCard[cardId] as StudyQuestionCard
  return card.question.structures.some(structure => structure.optionSettings.type === 'multiple')
}

export function isCardOpenTextQuestion(cardId: string): boolean {
  const studyStore = useStudyStore()
  const card = studyStore.cardIdToCard[cardId]
  return isStudyOpenTextQuestionCard(card)
}

export function getSubjectIdsForGridQuestionAnswers(
  cardId: string,
  answerIds: string[],
): string[] | undefined {
  const studyStore = useStudyStore()
  const card = studyStore.cardIdToCard[cardId] as StudyQuestionCard
  const subjectsForCard = card.question.structures.flatMap(s => s.subjects)
  return subjectsForCard
    .filter(subject => answerIds.some(answerId => subject.answerIds.includes(answerId)))
    ?.map(subject => subject.id)
    .filter((value, index, array) => array.indexOf(value) === index)
}

export function segmentToEntityForTracking(segment: Segment): SavedSegmentEntityData {
  const flattenedLogics = [
    ...new Array(
      (segment.groups && segment.groups.length > 0 ? segment.groups.length : 1) - 1,
    ).fill(segment.logic),
    ...(segment.groups?.flatMap(group => {
      return new Array((group.conditions.length > 0 ? group.conditions.length : 1) - 1).fill(
        group.logic,
      )
    }) ?? []),
  ]

  const flattenedConditions = segment.groups?.flatMap(group => group.conditions) ?? []

  function conditionCount(
    predicate: (condition: ValueCondition | RangeCondition) => boolean,
  ): number {
    return flattenedConditions.filter(condition => predicate(condition))?.length ?? 0
  }

  function logicCount(logicToCount: Logic): number {
    return flattenedLogics.filter(logic => logic === logicToCount)?.length ?? 0
  }

  return {
    id: segment.id,
    name: segment.name,
    efl: segment.efl ?? '',
    isShared: Boolean(segment.isShared),
    isCount: conditionCount(condition => !condition.isNegated),
    isNotCount: conditionCount(condition => Boolean(condition.isNegated)),
    andCount: logicCount(Logic.AND),
    orCount: logicCount(Logic.OR),
    demographicConditionCount: conditionCount(condition => condition.type === 'demographic'),
    countryConditionCount: conditionCount(condition => condition.type === 'country'),
    audienceConditionCount: conditionCount(condition => condition.type === 'audience'),
    questionConditionCount: conditionCount(condition => condition.type === 'answer'),
    waveConditionCount: conditionCount(condition => condition.type === 'waves'),
    gridQuestionCount: conditionCount(
      condition =>
        condition.type === 'answer' && isValueCondition(condition) && !!condition.subjects?.length,
    ),
    openTextQuestionCount: conditionCount(condition => condition.type === 'openText'),
  }
}
