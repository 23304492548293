<script lang="tsx">
import { defineComponent } from 'vue'

import deleteSVG from '@attest/_assets/icons/delete.svg'
import { COPY } from '@attest/_copy'
import { create, createEmitter } from '@attest/vue-tsx'

import { SvgButton } from './SvgButton'

type RangeInputProps = {
  minValue: number
  maxValue: number
  maxLength?: number
  min?: number
  max?: number
  isError?: boolean
}

type RangeInputEventName = 'setMin' | 'setMax'

export type RangeInputEvents = {
  setMin(payload: { value: number; event: Event }): void
  setMax(payload: { value: number; event: Event }): void
  deleteRange(payload: Event): void
}

const emit = createEmitter<RangeInputEvents>()

const RangeInput = defineComponent({
  props: {
    minValue: { type: Number, default: Number.POSITIVE_INFINITY },
    maxValue: { type: Number, default: Number.POSITIVE_INFINITY },
    maxLength: { type: Number, default: Number.POSITIVE_INFINITY },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    isError: { type: Boolean, default: false },
  },

  data() {
    return {
      isFocussed: false,
    }
  },

  methods: {
    onBlur() {
      this.isFocussed = false
      this.$emit('blur')
    },

    onFocus() {
      this.isFocussed = true
      this.$emit('focus')
    },

    setValueFromEvent(event: Event, eventName: RangeInputEventName) {
      const target = event.target as HTMLInputElement
      if (!target.value) return

      if (this.maxLength && target.value.length > this.maxLength) {
        emit(this, eventName, {
          value: Number.parseInt(target.value.slice(0, this.maxLength)),
          event,
        })
      } else {
        emit(this, eventName, { value: Number.parseInt(target.value), event })
      }
    },
  },

  render() {
    const baseClass = 'c-range-input'
    const rangeInputClass = {
      [baseClass]: true,
      [`${baseClass}--focussed`]: this.isFocussed,
      [`${baseClass}--error`]: this.isError,
    }

    return (
      <div class={rangeInputClass} data-validation={this.isError ? 'invalid' : 'valid'}>
        <div class={`${baseClass}__input-wrap`}>
          <input
            data-name="RangeInputMin"
            class={`${baseClass}__input-field`}
            type="number"
            aria-label={'minimum value'}
            value={this.minValue}
            min={this.min}
            max={this.max}
            onBlur={event => {
              this.setValueFromEvent(event, 'setMin')
              this.onBlur()
            }}
            onFocus={this.onFocus}
          />
          <input
            data-name="RangeInputMax"
            class={`${baseClass}__input-field`}
            type="number"
            aria-label={'maximum value'}
            value={this.maxValue}
            min={this.min}
            max={this.max}
            onBlur={event => {
              this.setValueFromEvent(event, 'setMax')
              this.onBlur()
            }}
            onFocus={this.onFocus}
          />
        </div>
        <SvgButton
          class={`${baseClass}__remove`}
          svg={deleteSVG}
          data-name="RangeInputDeleteRange"
          size="xs"
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            emit(this, 'deleteRange', event)
          }}
          screenReaderText={COPY.REMOVE_RANGE}
        />
      </div>
    )
  },
})

export default create<RangeInputProps, RangeInputEvents>(RangeInput)
</script>
<style lang="postcss">
:root {
  --c-range-input-border-transition: border var(--attest-timing-default) var(--attest-ease);
}

.c-range-input {
  display: inline-flex;
  height: 36px;
  flex-direction: row;
  align-items: center;
  gap: var(--attest-spacing-2);

  &__input-wrap {
    position: relative;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      left: calc(50% - 2px);
      display: block;
      width: var(--attest-border-width-s);
      height: var(--attest-spacing-2);
      background-color: var(--attest-color-border-subdued);
      content: '';
      transition: var(--c-range-input-border-transition);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &--focussed {
    ^&__input-wrap::before,
    ^&__input-wrap::after {
      background-color: var(--attest-color-interactive-default);
    }
  }

  &--error {
    ^&__input-wrap::before,
    ^&__input-wrap::after {
      background-color: var(--attest-color-interactive-negative-default);
    }

    ^&__input-field,
    ^&__input-field:focus {
      border-color: var(--attest-color-interactive-negative-default);
    }
  }

  &__input-field {
    width: 60px;
    height: 100%;
    box-sizing: border-box;
    padding: 0 var(--attest-spacing-2);
    border-style: solid;
    border-color: var(--attest-color-border-subdued);

    /* For Firefox */
    appearance: textfield;
    font-size: var(--attest-font-size-s);
    line-height: var(--attest-line-height-s);
    text-align: center;
    transition: var(--c-range-input-border-transition);

    /* Webkit browsers like Safari and Chrome */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }

    &:focus,
    &:active {
      border-color: var(--attest-color-interactive-default);
    }

    &:first-child {
      border-width: var(--attest-border-width-s) 0 var(--attest-border-width-s)
        var(--attest-border-width-s);
      border-radius: var(--attest-border-radius) 0 0 var(--attest-border-radius);
    }

    &:last-child {
      border-width: var(--attest-border-width-s) var(--attest-border-width-s)
        var(--attest-border-width-s) 0;
      border-radius: 0 var(--attest-border-radius) var(--attest-border-radius) 0;
    }
  }

  &__remove {
    flex: 1 0 auto;
  }
}
</style>
