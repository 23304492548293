<script lang="tsx">
import { defineComponent, type PropType } from 'vue'

import Loading from '../Loading.vue'
import { RoleButton } from '../RoleButton'

import type { FormButtonSize, FormButtonTag, FormButtonVariant } from './FormButton.interface'

export default defineComponent({
  props: {
    elementTag: { type: String as PropType<FormButtonTag>, default: 'button' },
    variant: { type: String as PropType<FormButtonVariant>, default: 'primary' },
    size: { type: String as PropType<FormButtonSize>, default: 'default' },
    fill: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  render() {
    const newVariant = (() => {
      if (this.variant === 'white') return 'secondary'
      if (this.variant === 'secondary-light') return 'secondary'
      if (this.variant === 'orange') return 'error'
      if (this.variant === 'transparent-primary') return 'transparent'
      if (this.variant === 'transparent-inverted') return 'transparent'
      return this.variant
    })()

    const baseClass = 'c-form-button'
    const buttonClass = {
      'o-outline': true,
      [baseClass]: true,
      [`${baseClass}--style-${newVariant}`]: !!newVariant,
      [`${baseClass}--size-${this.size}`]: !!this.size,
      [`${baseClass}--loading`]: !!this.loading,
      [`${baseClass}--fill`]: this.fill,
    }

    const loadingVariant = getLoadingVariant(this.variant)

    return (
      <RoleButton elementTag={this.elementTag} class={buttonClass}>
        {this.$slots.prefix ? (
          <span class={`${baseClass}__prefix`}>{this.$slots.prefix?.()}</span>
        ) : null}
        <span class={`${baseClass}__text`}>
          {!this.loading ? (
            this.$slots.default?.()
          ) : (
            <Loading show={this.loading} variant={loadingVariant} />
          )}
        </span>
        {this.$slots.postfix ? (
          <span class={`${baseClass}__postfix`}>{this.$slots.postfix?.()}</span>
        ) : null}
      </RoleButton>
    )
  },
})

function getLoadingVariant(buttonVariant?: FormButtonVariant | null): 'inverse' | 'standard' {
  if (buttonVariant && ['primary', 'secondary', 'error', 'white'].includes(buttonVariant))
    return 'inverse'
  return 'standard'
}
</script>

<style lang="postcss">
.c-form-button {
  --o-outline-radius: 22px;

  position: relative;
  display: inline-flex;
  height: var(--custom-height);
  box-sizing: border-box;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: var(--custom-padding);
  border: var(--attest-border-width-s) solid
    var(--custom-fill-color, var(--custom-color, var(--custom-border-color)));
  border-radius: 1.375rem;
  background-color: var(--custom-fill-color, var(--custom-background-color));
  box-shadow: none;
  color: var(--custom-color, var(--attest-color-text-default));
  font: var(--custom-font);
  text-align: center;
  transition:
    background-color var(--attest-timing-fast) linear,
    box-shadow var(--attest-timing-fast) linear,
    border var(--attest-timing-fast) linear,
    padding var(--attest-timing-fast) linear,
    color var(--attest-timing-fast) linear;
  user-select: none;
  white-space: nowrap;

  &:disabled,
  &[disabled] {
    pointer-events: none;
    text-decoration: none;
    transition: none;
  }

  &--size-default {
    --custom-height: 36px;
    --custom-padding: 0 var(--attest-spacing-3);
    --custom-font: var(--attest-font-ui-element-2);
  }

  &--size-large {
    --custom-height: 44px;
    --custom-padding: 0 var(--attest-spacing-4);
    --custom-font: var(--attest-font-ui-element-1);
  }

  &--style-primary {
    --custom-fill-color: var(--attest-color-interactive-default);
    --custom-color: var(--attest-color-text-on-interactive);

    &:hover {
      --custom-fill-color: var(--attest-color-interactive-hover);
    }

    &:active {
      --custom-fill-color: var(--attest-color-interactive-active);
    }

    &:disabled,
    &[disabled] {
      --custom-fill-color: var(--attest-color-interactive-disabled);
      --custom-color: var(--attest-color-text-disabled);
    }
  }

  &--style-secondary {
    --custom-border-color: var(--attest-color-surface-5);
    --custom-background-color: var(--attest-color-surface-default);

    &:hover {
      --custom-background-color: var(--attest-color-interactive-subdued-hover);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:active {
      --custom-background-color: var(--attest-color-interactive-subdued-active);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:disabled,
    &[disabled] {
      --custom-background-color: var(--attest-color-surface-default);
      --custom-color: var(--attest-color-text-disabled);
    }
  }

  &--style-error {
    --custom-color: var(--attest-color-text-on-interactive);
    --custom-fill-color: var(--attest-color-interactive-negative-default);

    &:hover {
      --custom-fill-color: var(--attest-color-interactive-negative-hover);
    }

    &:active {
      --custom-fill-color: var(--attest-color-interactive-negative-active);
    }

    &:disabled,
    &[disabled] {
      --custom-fill-color: var(--attest-color-interactive-disabled);
      --custom-color: var(--attest-color-text-disabled);
    }
  }

  &--style-transparent {
    --custom-background-color: transparent;
    --custom-color: var(--attest-color-text-default);

    &:hover,
    &:active {
      --custom-background-color: var(--attest-color-surface-hover);
    }

    &:disabled,
    &[disabled] {
      --custom-background-color: transparent;
      --custom-color: var(--attest-color-interactive-disabled);
    }
  }

  &--style-transparent-error {
    --custom-background-color: transparent;
    --custom-color: var(--attest-color-interactive-negative-default);

    &:hover {
      --custom-background-color: var(--attest-color-interactive-negative-subdued-hover);
    }

    &:active {
      --custom-background-color: var(--attest-color-interactive-negative-subdued-active);
    }

    &:disabled,
    &[disabled] {
      --custom-background-color: transparent;
      --custom-color: var(--attest-color-interactive-disabled);
    }
  }

  &--loading {
    pointer-events: none;
  }

  &__prefix {
    display: flex;
    align-items: center;
  }

  &__prefix ~ &__text {
    margin-left: var(--attest-spacing-1);
  }

  &--fill {
    width: 100%;
  }

  &__postfix {
    display: flex;
    align-items: center;

    &:not(:empty) {
      padding-left: var(--attest-spacing-2);
    }
  }
}
</style>
