<script lang="tsx">
import { defineComponent } from 'vue'

import { create, createEmitter } from '@attest/vue-tsx'

import { Accordion } from '../Accordion'

import type {
  CollapsibleNavbarItemEvents,
  CollapsibleNavbarItemProps,
} from './CollapsibleNavbarItem.interface'

const emit = createEmitter<CollapsibleNavbarItemEvents>()
const CollapsibleNavbarItem = defineComponent({
  props: {
    isExpandedByDefault: { type: Boolean, default: false },
    triggerClass: { type: String, default: '' },
  },

  render() {
    const baseClass = 'c-collapsible-navbar-item'

    return (
      <Accordion
        class={baseClass}
        triggerClass={`${this.triggerClass} ${baseClass}__trigger`}
        isExpandedByDefault={this.isExpandedByDefault}
        onTriggerHover={() => emit(this, 'triggerHover')}
        direction="up"
      >
        {{ title: this.$slots.title, item: this.$slots.item }}
      </Accordion>
    )
  },
})

export default create<CollapsibleNavbarItemProps, CollapsibleNavbarItemEvents>(
  CollapsibleNavbarItem,
)
</script>

<style lang="postcss">
.c-collapsible-navbar-item {
  &__trigger {
    display: flex;
    height: var(--attest-spacing-12);
    padding: var(--attest-spacing-7);
    margin: var(--attest-spacing-2) 0 var(--attest-spacing-4);
  }

  @context scoped {
    .c-arrow {
      @media (--attest-media-s) {
        display: none;
      }

      @media (--attest-media-xl) {
        display: block;
      }
    }
  }
}
</style>
