const UNSUPPORTED_OPERATION = 'reactive-storage: unsupported operation.'
const UNMATCH_SCHEMA_VALUE = (key: any) => {
  return `reactive-storage: ${key} in storage do not match type defined in schema. Returning null.`
}
const UNSUPPORTED_SCHEMA_TYPE = 'reactive-storage: Schema type can only be string[] or String.'

export type SchemaBase = {
  readonly [s: string]: readonly string[] | StringConstructor
}

type ValueOfKeyInSchema<
  Schema extends SchemaBase,
  Key extends keyof Schema,
> = Schema[Key] extends readonly string[] ? Schema[Key][number] : string

type StringKeyOfSchema<Schema extends SchemaBase> = Extract<keyof Schema, string>

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface SynchronisedObjectStorage<Schema extends SchemaBase> extends Storage {
  getItem<Key extends StringKeyOfSchema<Schema>>(key: Key): ValueOfKeyInSchema<Schema, Key> | null
  setItem<Key extends StringKeyOfSchema<Schema>>(
    key: Key,
    value: ValueOfKeyInSchema<Schema, Key>,
  ): void
  removeItem(key: StringKeyOfSchema<Schema>): void
  loadFromStorage(): void
}

type CreateSynchronisedObjectStorageOption<Schema extends SchemaBase> = {
  storage: Storage
  object: { [Key in keyof Schema]: ValueOfKeyInSchema<Schema, Key> | null }
  schema: Schema
  keyPrefix?: string
}
export function createSynchronisedObjectStorage<Schema extends SchemaBase>({
  storage,
  object,
  schema,
  keyPrefix = 'rs',
}: CreateSynchronisedObjectStorageOption<Schema>): SynchronisedObjectStorage<Schema> {
  const keys = Object.keys(schema) as StringKeyOfSchema<Schema>[]

  function doesItemOfKeyMatchSchemaType<Key extends keyof Schema>(
    item: any,
    key: Key,
  ): item is ValueOfKeyInSchema<Schema, Key> {
    const schemaType = schema[key]
    if (Array.isArray(schemaType)) {
      return schemaType.includes(item)
    }

    if (schemaType === String) {
      return typeof item === 'string'
    }

    throw new Error(UNSUPPORTED_SCHEMA_TYPE)
  }

  const prefixKey = (key: string) => `${keyPrefix}-${key}`

  const ReactiveStorage: SynchronisedObjectStorage<Schema> = {
    length: Object.keys(schema).length,

    getItem(key) {
      return object[key]
    },

    setItem(key, value) {
      storage.setItem(prefixKey(key), value)
      object[key] = value
    },

    removeItem(key) {
      storage.removeItem(prefixKey(key))
      object[key] = null
    },

    clear() {
      for (const key of keys) {
        ReactiveStorage.removeItem(key)
      }
    },

    key() {
      throw new Error(UNSUPPORTED_OPERATION)
    },

    loadFromStorage() {
      for (const key of keys) {
        const item = storage.getItem(prefixKey(key))
        if (item !== null && !doesItemOfKeyMatchSchemaType(item, key)) {
          // eslint-disable-next-line no-console
          console.warn(UNMATCH_SCHEMA_VALUE(key))
          continue
        }
        object[key] = item as ValueOfKeyInSchema<Schema, Extract<keyof Schema, string>>
      }
    },
  }

  return ReactiveStorage
}
