export const DEFAULT_COMPANY_SECTOR = 'EMPTY'
export const DEFAULT_COMPANY_SIZE = 'EMPTY'

export const COMPANY_SECTORS = [
  { value: 'EMPTY', translationKey: 'EMPTY' },
  { value: 'Agriculture', translationKey: 'AGRICULTURE' },
  { value: 'Apparel', translationKey: 'APPAREL' },
  { value: 'Banking', translationKey: 'BANKING' },
  { value: 'Biotechnology', translationKey: 'BIOTECHNOLOGY' },
  { value: 'Communications', translationKey: 'COMMUNICATIONS' },
  { value: 'Consulting', translationKey: 'CONSULTING' },
  { value: 'Education', translationKey: 'EDUCATION' },
  { value: 'Electronics', translationKey: 'ELECTRONICS' },
  { value: 'Energy', translationKey: 'ENERGY' },
  { value: 'Engineering', translationKey: 'ENGINEERING' },
  { value: 'Entertainment', translationKey: 'ENTERTAINMENT' },
  { value: 'Environmental', translationKey: 'ENVIRONMENTAL' },
  { value: 'Finance', translationKey: 'FINANCE' },
  { value: 'Food & Beverage', translationKey: 'FOOD_BEVERAGE' },
  { value: 'Government', translationKey: 'GOVERNMENT' },
  { value: 'Healthcare', translationKey: 'HEALTHCARE' },
  { value: 'Insurance', translationKey: 'INSURANCE' },
  { value: 'Manufacturing', translationKey: 'MANUFACTURING' },
  { value: 'Media', translationKey: 'MEDIA' },
  { value: 'Not For Profit', translationKey: 'NOT_FOR_PROFIT' },
  { value: 'Recreation', translationKey: 'RECREATION' },
  { value: 'Retail', translationKey: 'RETAIL' },
  { value: 'Technology', translationKey: 'TECHNOLOGY' },
  { value: 'Telecommunications', translationKey: 'TELECOMMUNICATIONS' },
  { value: 'Utilities', translationKey: 'UTILITIES' },

  { value: 'Other', translationKey: 'OTHER' },
] as const

export const COMPANY_SIZES = [
  {
    value: 'EMPTY',
    translationKey: 'EMPTY',
  },
  {
    value: 'self-employed',
    translationKey: 'SIZE_SELF_EMPLOYED',
  },
  {
    value: '1-10',
    translationKey: 'SIZE_1_10',
  },
  {
    value: '11-50',
    translationKey: 'SIZE_11_50',
  },
  {
    value: '51-200',
    translationKey: 'SIZE_51_200',
  },
  {
    value: '201-500',
    translationKey: 'SIZE_201_500',
  },
  {
    value: '501-1000',
    translationKey: 'SIZE_501_1000',
  },
  {
    value: '1001-5000',
    translationKey: 'SIZE_1001_5000',
  },
  {
    value: '5001-10000',
    translationKey: 'SIZE_5001_10000',
  },
  {
    value: '10001+',
    translationKey: 'SIZE_10001',
  },
] as const
