import { enGB, type TranslationKeyToCopyMeta } from '@attest/locale'
import { capture } from '@attest/telemetry'

import { getCopyString } from './get-copy-string'
import { replacePlaceholders } from './replace-placeholders'
import { replaceTransformers } from './replace-transformers'

export type { TranslationKeyToCopyMeta }

export function c<TranslationKey extends keyof TranslationKeyToCopyMeta>(
  key: TranslationKey,
  {
    silent = false,
    quantity = 1,
    placeholders = {},
    transformers = {},
  }: {
    silent?: boolean
    quantity?: number
    placeholders?: {
      [PlaceholderKey in TranslationKeyToCopyMeta[TranslationKey]['placeholders'][number]]?: string
    }
    transformers?: {
      [TransformerKey in TranslationKeyToCopyMeta[TranslationKey]['transformers'][number]]?: (
        copy: string,
      ) => any
    }
  } = {},
): any {
  if (enGB[key] === undefined) {
    if (!silent) capture(`copy key does not exist: ${String(key)}`)
    return undefined
  }
  return replaceTransformers(
    replacePlaceholders(getCopyString(enGB[key], quantity), placeholders),
    transformers,
  )
}
