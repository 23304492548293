import type { FunctionDirective, ObjectDirective } from 'vue'

const HANDLER = 'DIRECTIVE_SCROLL_END_CONTAINER'
type Handler = HTMLElement & { DIRECTIVE_SCROLL_END_CONTAINER: (e: any) => void }
type BindingValue = (isScrollBottom: boolean) => void

const bind: FunctionDirective<HTMLElement, BindingValue> = (el, binding) => {
  const value: BindingValue = binding.value
  const elBinding = el as Handler
  el.removeEventListener('scroll', elBinding[HANDLER])
  let elHeight: null | number = null

  const setElHeight = () => {
    elHeight = Math.ceil(el.getBoundingClientRect().height)
    return elHeight
  }

  const getElHeight = () => {
    if (elHeight === null) {
      return setElHeight()
    }

    return elHeight
  }

  elBinding[HANDLER] = () => {
    const isEndOfPage = getElHeight() + Math.ceil(el.scrollTop) >= el.scrollHeight

    if (!isEndOfPage) {
      return
    }

    value(isEndOfPage)
  }

  window.addEventListener('resize', setElHeight)
  el.addEventListener('scroll', elBinding[HANDLER])
}

export const scrollEndContainer: ObjectDirective<HTMLElement, BindingValue> = {
  beforeMount: bind,

  updated(el, binding, vnode, oldVnode) {
    if (binding.value === binding.oldValue) return
    bind(el, binding, vnode, oldVnode)
  },

  unmounted(el) {
    const elBinding = el as Handler

    el.removeEventListener('scroll', elBinding[HANDLER])
  },
}
