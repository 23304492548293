import { createEntity, type Entity } from '../base'

export type RangeFilterEntity<D extends RangeFilterEntityData = RangeFilterEntityData> =
  {} & Entity<D>

type RangeFilterEntityData = {
  id: string
  values: { min: number; max: number }[]
}

export function createRangeFilterEntity({ id, values }: RangeFilterEntityData): RangeFilterEntity {
  return createEntity(
    { name: 'range-filter', version: '1-0-0' },
    { id, values: values.map(({ min, max }) => ({ min, max })) },
  )
}
