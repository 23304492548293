import { defineStore } from 'pinia'
import { equals } from 'ramda'
import { nextTick } from 'vue'

import {
  type CardError,
  type CardQualifyingError,
  type CardRoutingAnswerError,
  type ChoiceAnswerError,
  type ExternalTitleError,
  type InternalTitleError,
  isCardQualifyingError,
  isCardRoutingError,
  type SurveyError,
  trackEditorErrors,
  type TranslationError,
} from '@attest/_lib/src/editor-error/editor-error'
import { useAudienceEditorErrorStore, useAudienceEditorStore } from '@attest/audience'
import { useEditorStore } from '@attest/editor'
import {
  type Card,
  type CardGuid,
  isCardChoiceQuestion,
  isCardGridQuestion,
  isGroupCard,
  isQualifying,
  isRankedQuestion,
} from '@attest/editor-card'
import { useEditorDisplayLogicStore } from '@attest/editor-display-logic'
import { createRandomizedGroupGraph } from '@attest/editor-routing-graph'
import { SETTINGS } from '@attest/editor-settings'
import { useUserStore } from '@attest/user'
import { debounce } from '@attest/util'

import { trimCardTexts } from '../card-service'
import { parsePipedQuestionsFromTitle } from '../piping-util'
import { useEditorRoutingGraphStore } from '../routing-graph-store'
import { useEditorSurveyStore } from '../store'

import { getQuestionAnswerErrors } from './answer'
import { getCardErrors } from './card'
import { getCardTextErrors } from './card-text'
import { getCardTitleErrors } from './card-title'
import { getCardDisplayLogicErrors } from './display-logic'
import { getGroupCardErrors } from './group'
import { getCardMaskingErrors } from './masking'
import { getCardGroupedPipingErrors, getCardPipingErrors } from './piping'
import { getCardQualifyingErrors } from './qualifying'
import { getCardRoutingAnswerErrors, getCardRoutingErrors } from './routing'
import {
  getInternalTitleErrors,
  getSchedulingError,
  getSurveyErrors,
  getTitleErrors,
  getTranslationErrors,
} from './survey'

export type EditorErrorsState = {
  placeholderErrorsEnabled: boolean
  card: Record<CardGuid, CardError[]>
  title: ExternalTitleError[]
  internalTitle: InternalTitleError[]
  cardGuidToQuestionAnswerError: Record<CardGuid, ChoiceAnswerError[][]>
  answerRouting: Record<CardGuid, Record<string, CardRoutingAnswerError[]>>
  translation: TranslationError[]
}

export const EDITOR_ERRORS_STORE_NAMESPACE = 'editorErrors'

export function createEditorErrorsState(
  override: Partial<EditorErrorsState> = {},
): EditorErrorsState {
  return {
    placeholderErrorsEnabled: true,
    card: {},
    title: [],
    internalTitle: [],
    cardGuidToQuestionAnswerError: {},
    answerRouting: {},
    translation: [],
    ...override,
  }
}

export const useEditorErrorsStore = defineStore(EDITOR_ERRORS_STORE_NAMESPACE, {
  state: createEditorErrorsState,
  getters: {
    surveyErrors(): SurveyError[] {
      const editorSurveyStore = useEditorSurveyStore()
      const editorRoutingGraphStore = useEditorRoutingGraphStore()

      return getSurveyErrors({
        survey: editorSurveyStore.survey,
        cardErrors: this.allCardErrors,
        sampleSizeErrors: useAudienceEditorErrorStore().sampleSizeErrorsFlat,
        targetingErrors: useAudienceEditorErrorStore().targetingErrorsFlat,
        targetingsErrors: useAudienceEditorErrorStore().targetingsErrors,
        scheduleErrors: this.schedulingErrors,
        titleErrors: this.title,
        internalTitleErrors: this.internalTitle,
        routingGraph: editorSurveyStore.routingGraph,
        longestRoute: Math.max(
          editorRoutingGraphStore.countOfQuestionsInLongestRouteOverall,
          ...Object.values(editorRoutingGraphStore.countOfQuestionsInLongestRouteForAudiences),
        ),
        translationErrors: this.translation,
        isPrivacyPolicyComplete: useUserStore().isPrivacyPolicyComplete,
      })
    },

    schedulingErrors() {
      const editorSurveyStore = useEditorSurveyStore()
      return getSchedulingError({
        startTimestamp: editorSurveyStore.startTimestamp || null,
        timeZoneId: editorSurveyStore.timeZoneId,
        pendingSchedule: useEditorStore().pendingSchedule,
      })
    },

    hasAnySurveyError(): boolean {
      return [
        this.surveyErrors,
        this.title,
        this.internalTitle,
        useAudienceEditorErrorStore().sampleSizeErrorsFlat,
        this.schedulingErrors,
        useAudienceEditorErrorStore().targetingErrorsFlat,
        this.allCardErrors,
      ].some(error => error.length > 0)
    },

    getCardErrors(state): (guid: string) => CardError[] {
      return (guid: string) => {
        const card = useEditorSurveyStore().getCardByGuid({ guid })
        if (
          card &&
          isGroupCard(card) &&
          card.group.cards.flatMap(({ guid: groupedCardGuid }) => state.card[groupedCardGuid])
            .length > 0
        )
          return [
            ...(state.card[guid] ?? []),
            { type: 'GROUP_CARD_CONTAINS_CARDS_WITH_CARD_ERRORS' },
          ]
        return state.card[guid] ?? []
      }
    },

    allCardErrors(state): CardError[] {
      return useEditorSurveyStore()
        .orderedCards.flatMap(card => [...card.group.cards, card])
        .flatMap(card => state.card[card.guid] || [])
    },

    getQuestionAnswersErrors(state) {
      return (guid: string) => state.cardGuidToQuestionAnswerError[guid] || []
    },

    cardRoutingErrors(state) {
      return Object.fromEntries(
        Object.entries(state.card).map(([guid, errors]) => [
          guid,
          errors.filter(isCardRoutingError),
        ]),
      )
    },

    getCardRoutingAnswerErrors(state) {
      return (guid: CardGuid, answerId: string) => state.answerRouting[guid]?.[answerId] || []
    },

    getCardQualifyingErrors(state) {
      return (guid: CardGuid): CardQualifyingError[] => {
        return (state.card[guid] || []).filter(isCardQualifyingError)
      }
    },

    pipingFrom() {
      return (guid: CardGuid): (CardGuid | undefined)[] => {
        const card = trimmedCardByGuid(guid)
        if (!card) return []
        return parsePipedQuestionsFromTitle(card.title, useEditorSurveyStore().orderedCards)
      }
    },
  },
  actions: {
    setPlaceHoldersEnabledState(enabled: boolean): void {
      this.placeholderErrorsEnabled = enabled
    },

    updateAllCardsErrors: debounce((options?: { excludeGuids: CardGuid[] }) => {
      useEditorErrorsStore().updateAllCardsErrorsHandler(options)
    }, SETTINGS.UPDATE_ERRORS_DELAY),

    updateAllCardsErrorsHandler(options?: { excludeGuids: CardGuid[] }): void {
      useEditorSurveyStore()
        .orderedCards.flatMap(card => [card, ...card.group.cards])
        .forEach(({ guid }) => {
          if (options?.excludeGuids.includes(guid)) return
          this.updateCardErrors(guid)
        })
    },

    async updateCardErrors(guid: CardGuid): Promise<void> {
      const guidToDisplayLogicRule = useEditorDisplayLogicStore().guidToDisplayLogicRule
      const editorSurveyStore = useEditorSurveyStore()
      const editorRoutingGraphStore = useEditorRoutingGraphStore()
      const errorsStore = useEditorErrorsStore()
      const cards = editorSurveyStore.orderedCards
      const { answerQuotas, getActiveAnswerQuotasForCard } = useEditorSurveyStore()

      const card = trimmedCardByGuid(guid)
      if (!card) {
        return
      }

      const { isImage, answers } = card.question.options
      const placeholderErrorsEnabled = errorsStore.placeholderErrorsEnabled
      if (isCardChoiceQuestion(card) || isCardGridQuestion(card) || isRankedQuestion(card)) {
        const answerErrors = answers.map(answer =>
          getQuestionAnswerErrors({
            isImage,
            answer,
            answers,
            placeholderErrorsEnabled,
          }),
        )
        trackEditorErrors(
          errorsStore.cardGuidToQuestionAnswerError[guid]?.flat(),
          answerErrors.flat(),
          'editor-warning',
          card,
        )
        errorsStore.setQuestionAnswerErrors({
          guid,
          errors: answerErrors,
        })
      }
      if (useEditorSurveyStore().getGroupedCardGuids.has(guid)) {
        const groupedErrors = [
          ...getCardErrors({
            card,
            cards,
            cardsVisibleForAudiences: editorRoutingGraphStore.cardsReachableForAudiences,
          }),
          ...getCardTitleErrors({ card, placeholderErrorsEnabled }),
          ...getCardTextErrors({ card, placeholderErrorsEnabled }),
          ...getCardGroupedPipingErrors({ card }),
        ]
        trackEditorErrors(errorsStore.card[guid], groupedErrors, 'editor-error', card)
        errorsStore.setCardErrors({ guid, errors: groupedErrors })
        return
      }

      const randomizedGroupGraph = createRandomizedGroupGraph(
        editorSurveyStore.routingGraph,
        new Set(cards.filter(({ isRandomized }) => isRandomized).map(c => c.guid)),
      )
      const routingErrors = answers.map(answer => ({
        guid,
        answerId: answer.id,
        errors: getCardRoutingAnswerErrors({
          card,
          orderedCards: cards,
          randomizedGroupGraph,
          routingGraph: editorSurveyStore.routingGraph,
          edgesThatCauseACycle: editorSurveyStore.routingGraphAndCycles.edgesThatCauseCycles,
          answer,
        }),
      }))
      const qualifyingErrors = isQualifying(card)
        ? getCardQualifyingErrors({
            card,
            cards,
            allAnswerQuotas: Object.values(answerQuotas),
            answerQuotas: getActiveAnswerQuotasForCard(card.guid),
          })
        : []
      const cardErrors = [
        ...getCardErrors({
          card,
          cards,
          cardsVisibleForAudiences: editorRoutingGraphStore.cardsReachableForAudiences,
        }),
        ...getCardTitleErrors({ card, placeholderErrorsEnabled }),
        ...getCardPipingErrors({
          card,
          cards,
          pipingFrom: this.pipingFrom(guid),
          routingGraph: editorSurveyStore.routingGraph,
          randomizedGroupGraph,
        }),
        ...getCardMaskingErrors({
          card,
          routingGraph: editorSurveyStore.routingGraph,
          cards,
        }),
        ...getCardTextErrors({ card, placeholderErrorsEnabled }),
        ...getCardDisplayLogicErrors({
          card,
          cards,
          routingGraph: editorSurveyStore.routingGraph,
          randomizedGroupGraph,
          guidToDisplayLogicRule,
          pipingFrom: this.pipingFrom(guid),
          answerQuotas: editorSurveyStore.answerQuotas,
        }),
        ...qualifyingErrors,
        ...getCardRoutingErrors({
          card,
          orderedCards: cards,
          routingGraph: editorSurveyStore.routingGraph,
          edgesThatCauseACycle: editorSurveyStore.routingGraphAndCycles.edgesThatCauseCycles,
          randomizedGroupGraph,
        }),
        ...routingErrors.flatMap(({ errors }) => errors),
        ...getGroupCardErrors({
          card,
          routingGraph: editorSurveyStore.routingGraph,
          cards,
        }),
      ]
      trackEditorErrors(errorsStore.card[guid], cardErrors, 'editor-error', card)
      errorsStore.setCardErrors({ guid, errors: cardErrors })
      if (routingErrors.length > 0) {
        await nextTick()
        errorsStore.setAnswerRoutingErrors(routingErrors)
      }
    },

    setCardErrors({ guid, errors }: { guid: CardGuid; errors: CardError[] }) {
      if (equals(this.card[guid], errors)) return
      this.card[guid] = errors
    },

    setTitleErrors(titleErrors: ExternalTitleError[]): void {
      this.title = titleErrors
    },

    updateTitleErrors(): void {
      const surveyStore = useEditorSurveyStore()
      const errorsStore = useEditorErrorsStore()
      const errors = getTitleErrors(surveyStore.title.trim())
      trackEditorErrors(errorsStore.title, errors)
      this.title = errors
    },

    updateInternalTitleErrors(): void {
      const errors = getInternalTitleErrors(useEditorSurveyStore().internalTitle.trim())
      trackEditorErrors(this.internalTitle, errors)
      this.internalTitle = errors
    },

    setQuestionAnswerErrors({
      guid,
      errors,
    }: {
      guid: CardGuid
      errors: ChoiceAnswerError[][]
    }): void {
      this.cardGuidToQuestionAnswerError[guid] = errors
    },

    setAnswerRoutingErrors(
      answerRoutingErrors: { guid: CardGuid; answerId: string; errors: CardRoutingAnswerError[] }[],
    ): void {
      answerRoutingErrors.forEach(({ guid, answerId, errors }) => {
        if (!this.answerRouting[guid]) {
          this.answerRouting[guid] = {}
        }
        const answerRoutingForCard = this.answerRouting[guid]
        if (answerRoutingForCard !== undefined && !equals(answerRoutingForCard[answerId], errors)) {
          answerRoutingForCard[answerId] = errors
        }
      })
    },

    updateTranslationErrors(): void {
      const editorSurveyStore = useEditorSurveyStore()
      const audienceEditorStore = useAudienceEditorStore()
      const countryLanguages = audienceEditorStore.translationLanguages
      this.translation = getTranslationErrors({
        cards: editorSurveyStore.cards,
        surveyTranslations: editorSurveyStore.translations,
        countryLanguages,
        routingGraph: editorSurveyStore.routingGraph,
      })
    },
  },
})

const trimmedCardByGuid = (guid: string): Card | undefined => {
  const card = useEditorSurveyStore().getCardByGuid({ guid })
  if (!card) return
  return trimCardTexts(card)
}
