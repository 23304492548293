import { createEntity, type Entity } from './base'

export type CardEntity<D extends CardEntityData = CardEntityData> = {} & Entity<D>
type CardEntityData = {
  id: string
}

export function createCardEntity({ id }: CardEntityData): CardEntity {
  return createEntity({ name: 'card', version: '1-0-0' }, { id })
}
