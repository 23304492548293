<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import type { FocusTrapperProps } from './FocusTrapper.interface'

const FocusTrapper: FunctionalComponent<FocusTrapperProps> = (props, { slots }) => {
  return [
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div data-focus-trap tabindex={0} onFocus={createFocusElementHandler(props.getLastElement)} />,
    slots.default?.(),
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div data-focus-trap tabindex={0} onFocus={createFocusElementHandler(props.getFirstElement)} />,
  ]
}

export default FocusTrapper

function createFocusElementHandler(getElement: () => HTMLElement): () => void {
  return () => getElement().focus()
}
</script>
