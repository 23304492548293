import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'

/**
 * @deprecated use `axios`
 *
 * ```ts
 * service(store).get()
 *
 * // becomes:
 *
 * axios.get()
 * ```
 */
export class ApiService {
  async get<T = any>(
    url: string,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse<T>> {
    shouldHandleRequest(url)
    return axios.get<T>(url, config)
  }

  async post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    shouldHandleRequest(url)
    return axios.post<T>(url, data, config)
  }

  async put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    shouldHandleRequest(url)
    return axios.put<T>(url, data, config)
  }

  async patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    shouldHandleRequest(url)

    return axios.patch<T>(url, data, config)
  }

  async delete<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    shouldHandleRequest(url)
    return axios.delete<T>(url, { ...config, data })
  }
}

function shouldHandleRequest(url: string): void {
  if (process.env.TEST_ENV !== 'jest') return
  const error = new Error(`Network Request intercepted in test: ${url}`)
  // eslint-disable-next-line no-console
  console.error(error.stack)
  throw error
}
