import axios from 'axios'

import { type ApiError, type InternalError, reasonErrorToInternalError } from '@attest/_api'
import type { SavedApiStudyRecurrence } from '@attest/_api/model/study-recurrence'
import { capture } from '@attest/telemetry'
import { keyBy } from '@attest/util'

import type { StudyStructure } from '../model/study-structure'
import { transformApiRecurrenceToStudyRecurrence } from '../recurrence/transformer'

import { type ApiStudyCard, createStudyCard } from './card'
import { type ApiStudyWave, createStudyWave } from './wave'

export type SurveyViewershipRaw = 'me' | 'team' | 'shared' | 'admin'

export type ApiGetStudyStructure = {
  id: string
  title: string
  internal_title: string
  created_by: string
  owned_by: string
  shared: boolean
  tags: []
  waves: ApiStudyWave[]
  cards: ApiStudyCard[]
  viewership: SurveyViewershipRaw
  recurrence: SavedApiStudyRecurrence | null
  draft_language: string
}

export async function getStudyStructure(args: {
  id: string
}): Promise<StudyStructure | InternalError<ApiError>> {
  try {
    const { data } = await axios.get<ApiGetStudyStructure>(`/api/study/${args.id}/structure`)
    const apiWaves = data.waves.sort((a, b) => {
      if (a.published == null) return 1
      if (b.published == null) return -1
      return b.published - a.published
    })
    const waves = apiWaves.map(createStudyWave)
    const waveSurveyIds = waves.map(({ id }) => id)
    const cards = data.cards.map(card => createStudyCard(card, apiWaves))

    return {
      study: {
        id: data.id,
        title: data.title,
        internalTitle: data.internal_title,
        createdBy: data.created_by,
        ownedBy: data.owned_by,
        cardIds: cards.map(({ id }) => id),
        waveSurveyIds,
        recurrence: transformApiRecurrenceToStudyRecurrence(data.recurrence),
        isShared: data.shared,
        viewership: data.viewership === 'me' ? 'own' : data.viewership,
        draftLanguage: data.draft_language,
      },
      waveSurveyIdToWaveSurvey: keyBy(waves, ({ id }) => id),
      waveAudienceIdToWaveSurvey: keyBy(waves, ({ audienceId }) => audienceId),
      cardIdToCard: keyBy(cards, ({ id }) => id),
    }
  } catch (e) {
    if (e.response?.status !== 404) capture(e)
    return reasonErrorToInternalError(e)
  }
}
