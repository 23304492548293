import { type ResponseData, service, type ServiceResponse } from '@attest/_api'

type UserProcessInviteResponseDataRaw = {} & ResponseData<{
  redirect: string | null
  email: string
  'organisation-name': string
}>

type UserProcessInviteResponseData = {} & ResponseData<{
  redirect: string | null
  user: {
    email: string
  }
  organisation: {
    name: string
  }
}>

export type UserProcessInviteRequestData = {
  inviteCode: string
}
const PROCESS_INVITE_URL = '/json/process-invite'

export async function processInvite(
  data: UserProcessInviteRequestData,
): Promise<ServiceResponse<UserProcessInviteResponseData>> {
  const response = await service().post<UserProcessInviteResponseDataRaw>(PROCESS_INVITE_URL, {
    'invite-code': data.inviteCode,
  })
  const { success } = response.data
  const transformed = {
    redirect: success.redirect,
    user: {
      email: success.email,
    },
    organisation: {
      name: success['organisation-name'],
    },
  }

  const result: ServiceResponse<UserProcessInviteResponseData> = response as any
  result.data.success = transformed
  return result
}
