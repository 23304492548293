import { createEntity, type Entity } from './base'

export type TemplateEntity<D extends TemplateEntityData = TemplateEntityData> = {} & Entity<D>
type TemplateEntityData = {
  id: string
}

export function createTemplateEntity({ id }: TemplateEntityData): TemplateEntity {
  return createEntity({ name: 'template', version: '1-0-0' }, { id })
}
