import type {
  CountriesApiResponse,
  CountryLanguagesApiResponse,
  LanguagesApiResponse,
} from '../model/api'
import type { Country, CountryLanguages, Language } from '../model/country-language'

export function toCountry(country: CountriesApiResponse): Country {
  return {
    name: country.name,
    display: country.display,
    supportedLanguages: country.supported_languages,
  }
}

export function toLanguage(language: LanguagesApiResponse): Language {
  return language as Language
}

export function toCountryLanguages(
  countryLanguagesApi: CountryLanguagesApiResponse,
): CountryLanguages {
  const countryLanguages: CountryLanguages = {}
  for (const [key, value] of Object.entries(countryLanguagesApi)) {
    countryLanguages[key] = {
      country: value.country,
      language: value.language,
      targetingStatus: value.targeting_status === 'DEPRECATED' ? 'deprecated' : 'active',
    }
  }
  return countryLanguages as CountryLanguages
}
