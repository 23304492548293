import { allSimplePaths } from 'graphology-simple-path'

import { intersection } from '@attest/util'

import type { RoutingGraph } from './model'

export function simplePathsTo(routingGraph: RoutingGraph, destinationNodeKey: string): string[][] {
  const rootNodeKeys = getRootNodes(routingGraph)

  return rootNodeKeys.flatMap(rootNodeKey =>
    allSimplePaths(routingGraph, rootNodeKey, destinationNodeKey),
  )
}

export function getRootNodes(graph: RoutingGraph): string[] {
  return graph.filterNodes(node => graph.inDegree(node) === 0)
}

export function forEachRootNode(graph: RoutingGraph, callbackFn: (rootNode: string) => void): void {
  return graph.forEachNode(node => {
    if (graph.inDegree(node) === 0) {
      callbackFn(node)
    }
  })
}

export function getTerminatingNodes(graph: RoutingGraph): string[] {
  return graph.filterNodes(node => graph.outDegree(node) === 0)
}

export function forEachTerminatingNode(
  graph: RoutingGraph,
  callbackFn: (terminatingNode: string) => void,
): void {
  return graph.forEachNode(node => {
    if (graph.outDegree(node) === 0) {
      callbackFn(node)
    }
  })
}

export function getAllIntersectingNodesTo(graph: RoutingGraph, target: string): string[] {
  if (graph.order === 1) {
    return [target]
  }

  return [
    ...intersection(
      getRootNodes(graph)
        .flatMap(source => getPathsFromNodeToNode(graph, source, target))
        .map(nodes => new Set(nodes)),
    ),
  ]
}

export function getPathsFromNodeToNode(
  graph: RoutingGraph,
  source: string,
  target: string,
): string[][] {
  if (!graph.hasNode(source) || !graph.hasNode(target)) {
    return []
  }
  return allSimplePaths(graph, source, target)
}
