import { create } from '@attest/vue-tsx'

import type {
  DescendantsObserverEvents,
  DescendantsObserverProps,
} from './DescendantsObserver.interface'
import Component from './DescendantsObserver.vue'

export * from './DescendantsObserver.interface'

export const DescendantsObserver = create<DescendantsObserverProps, DescendantsObserverEvents>(
  Component,
)
