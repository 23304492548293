export const SETTINGS = {
  DEFAULT_GUID: '_',
  DEFAULT_VERSION: 0,
  DEFAULT_SELECTION_LIMIT: 8,
  MIN_NUM_SUBJECT_HEADERS: 2,
  MIN_NUM_OPEN_TEXT_HEADERS: 1,
  MIN_NUM_OPTION_ANSWERS: 2,
  MAX_NUMBER_QUALIFYING_QUESTIONS: 5,
  MAX_ANSWER_LENGTH: 100,
  MAX_SURVEY_TITLE_LENGTH: 100,
  MIN_SURVEY_TITLE_LENGTH: 3,
  MAX_SURVEY_INTERNAL_TITLE_LENGTH: 100,
  MAX_NUMBER_DRAFT_QUESTIONS: 40,
  MAX_NUMBER_OF_VIDEO_QUESTIONS: 4,
  MAX_NUMBER_OF_QUALIFYING_QUESTIONS_FOR_SURVEY_WITH_VIDEO_QUESTIONS: 4,
  MAX_NUMBER_OF_NON_VIDEO_QUESTIONS_FOR_SURVEY_WITH_VIDEO_QUESTIONS: 4,

  TEXT: {
    MIN_LENGTH: 3,
    MAX_LENGTH: 800,
  },

  QUESTION: {
    AVERAGE_COMPLETION_TIME_SECONDS: 11.3,
    MIN_TITLE_LENGTH: 3,
    MAX_TITLE_LENGTH: 200,
    MAX_TITLE_CHARACTER_LENGTH: 500,
    MAX_ANSWER_LIMIT: 50,

    MIN_ANSWER_LENGTH: 1,
    MAX_ANSWER_LENGTH: 100,

    MIN_ANSWER_COUNT: 2,

    MAX_ANSWER_LIMIT_WITH_LARGE_IMAGES: 6,

    GRID: {
      SUBJECT_AMOUNT_MIN: 2,
      SUBJECT_AMOUNT_MAX: 10,
      SUBJECT_AMOUNT_MAX_IF_MASKING: 20,
      SUBJECT_LENGTH_MAX: 100,
      OPTION_AMOUNT_MIN: 2,
      OPTION_LENGTH_MAX: 100,
    },
  },

  QUESTION_TYPES_WITH_DEFAULT_HAS_NONE: ['single_choice', 'multiple_choice', 'nps', 'grid'],

  OPEN_TEXT: {
    FIELDS: {
      SHORT: 5,
      MEDIUM: 4,
      LONG: 3,
    },
    MAX_FIELD_TITLE_LENGTH: 100,
  },

  VIDEO: {
    MAX_PROMPT_LENGTH: 500,
    MIN_NUM_PROMPTS: 1,
  },

  AUDIENCE: {
    DEFAULT_TYPE: 'panel',
    DEFAULT_COUNTRY: 'GB',
    DEFAULT_LANGUAGE: 'ENG',
    DEFAULT_MIN_AGE_RANGE: 16,
    DEFAULT_MIN_AGE_RANGE_FOR_VIDEO_SURVEY: 18,
    DEFAULT_MAX_AGE_RANGE: 99,
    MAX_AVAILABLE_SAMPLE_SIZE_QUAL: 30,
    MAX_AVAILABLE_SAMPLE_SIZE: 9999999,
    MAX_NAME_CHARACTER_COUNT: 100,
  },

  COMMENTS: {
    COMMENT_MAX_LENGTH: 2000,
  },

  TEMPLATE: {
    PLACEHOLDER_REGEX: /(>[\s\w]*<)/,
  },

  SAMPLE_SIZE_DELAY: 500,
  SAVE_DELAY: 2000,
  UPDATE_ERRORS_DELAY: 500,
  ANSWER_QUOTA_DELAY: 500,
  TYPING_DELAY: 280,
  TOASTER_NAME: 'editor_toaster',
} as const
