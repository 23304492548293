import mitt from 'mitt'

export type EventRecord = {
  [k: string]: (...payload: any) => void
}

export type EventBus<Events extends EventRecord> = {
  has(type: keyof Events): boolean
  on<EventType extends keyof Events>(type: EventType, handler: Events[EventType]): void
  off<EventType extends keyof Events>(type: EventType, handler?: Events[EventType]): void
  emit<EventType extends keyof Events>(
    type: EventType,
    ...payload: Parameters<Events[EventType]>
  ): void
}

export function createEventBus<Events extends EventRecord>(): EventBus<Events> {
  const bus = mitt()
  return {
    ...bus,
    has(type: keyof Events) {
      const event = bus.all.get(type as string)
      return event && event.length > 0
    },
  } as unknown as EventBus<Events>
}
