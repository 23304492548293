<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import '@attest/vue-tsx'

import { BareButton } from '../BareButton'
import { SvgIcon } from '../SvgIcon'

import type { SvgButtonProps } from './SvgButton.interface'

/** @deprecated use AttestIconButton */
const SvgButton: FunctionalComponent<SvgButtonProps> = (props, { slots }) => {
  const {
    svg,
    size = 'l',
    isHoverable = false,
    screenReaderText,
    color,
    iconClass = null,
    ...attrs
  } = props

  const baseClass = 'c-svg-button'
  const svgButtonClass = {
    'o-outline': true,
    [baseClass]: true,
    [`${baseClass}--variant-${props.variant ?? 'default'}`]: true,
    [`${baseClass}--${size}`]: !!size,
    [`${baseClass}--icon-only`]: !slots.prefix && !slots.postfix,
  }

  return (
    <BareButton class={svgButtonClass} {...attrs}>
      {slots.prefix && slots.prefix()}
      <SvgIcon
        svg={svg}
        size={size}
        color={attrs.disabled ? 'disabled' : color}
        isHoverable={isHoverable}
        class={iconClass}
      />
      {screenReaderText && <div class="u-screen-reader-only">{screenReaderText}</div>}
      {slots.postfix && (
        <div class={`${baseClass}__postfix`} data-name="SvgButtonPostfix">
          {slots.postfix?.()}
        </div>
      )}
    </BareButton>
  )
}

export default SvgButton
</script>
<style lang="postcss">
.c-svg-button {
  --o-outline-radius: 22px;
  position: relative;
  display: flex;
  min-width: max-content;
  align-items: center;
  border: var(--attest-border-width-s) solid
    var(--custom-fill-color, var(--custom-color, var(--custom-border-color)));
  border-radius: 1.375rem;
  background-color: var(--custom-fill-color, var(--custom-background-color)) !important;
  box-shadow: none;
  font: var(--attest-font-ui-element-2);
  outline: none;
  transition-duration: var(--attest-timing-fast);
  transition-property: background-color;
  user-select: none;

  &:disabled {
    pointer-events: none;
    text-decoration: none;
    transition: none;
  }

  &--variant-default {
    --custom-border-color: var(--attest-color-surface-5);
    --custom-background-color: var(--attest-color-surface-default);

    &:hover {
      --custom-background-color: var(--attest-color-interactive-subdued-hover);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:active {
      --custom-background-color: var(--attest-color-interactive-subdued-active);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:disabled {
      --custom-background-color: var(--attest-color-surface-default);
      --custom-color: var(--attest-color-interactive-disabled);
    }
  }

  &--variant-borderless {
    --o-outline-focus-offset: -3px;

    &:hover {
      --custom-background-color: var(--attest-color-interactive-subdued-hover);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:active {
      --custom-background-color: var(--attest-color-interactive-subdued-active);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:disabled {
      --custom-background-color: var(--attest-color-surface-default);
      --custom-color: var(--attest-color-interactive-disabled);
    }
  }

  &--variant-grey-borderless {
    border: none;

    &:hover {
      --custom-background-color: var(--attest-palette-grey-200);
      --custom-color: var(--attest-color-interactive-default);
    }

    &:active {
      --custom-background-color: var(--attest-palette-grey-200);
      --custom-color: var(--attest-color-interactive-default);
    }
  }

  &__postfix {
    margin-left: var(--attest-spacing-1);
  }

  &--xs {
    padding: var(--attest-spacing-1);
  }

  &--s {
    height: 36px;
    padding: var(--attest-spacing-2);
  }

  &--m {
    padding: var(--attest-spacing-2);
  }

  &--l {
    padding: var(--attest-spacing-3);
  }

  &--xl {
    padding: var(--attest-spacing-4);
  }

  &--icon-only {
    --o-outline-radius: 50%;

    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &--icon-only&--xs {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
  }
}
</style>
