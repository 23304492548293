import axios, { type AxiosError } from 'axios'

/**
 * Check if the error is API like error
 *
 * @example
 * ```ts
 * import { isFetchError } from '@attest/fetch'
 *
 * const [response, error] = await fetchRequest()
 *
 * if (isFetchError(error)) {
 *  // handle error
 *  return
 * }
 *
 * // handle response
 * ```
 */
export function isFetchError(error: unknown | AxiosError): error is AxiosError {
  return axios.isAxiosError(error)
}
