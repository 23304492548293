<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { createMarkdownString } from '@attest/markdown'
import { create } from '@attest/vue-tsx'

import type { RenderedMarkdownProps } from './interface'

const RenderedMarkdown: FunctionalComponent<RenderedMarkdownProps> = props => {
  const { text, config, ...attrs } = props

  const content = createMarkdownString(text, config)
  const baseClass = 'c-rendered-markdown'
  return <span class={baseClass} innerHTML={content} {...attrs} />
}

export default create<RenderedMarkdownProps>(RenderedMarkdown)
</script>

<style lang="postcss">
.c-rendered-markdown {
  display: inline;

  a {
    color: #4aacff;
    cursor: pointer;
  }
}
</style>
