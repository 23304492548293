import type { PutAnswerQuotasRequestData } from '@attest/survey'

export function transformQuotasToPutAnswerQuotasRequestData(
  quotas: { questionId: string; answers: { id: string; minTarget: number }[] }[],
): PutAnswerQuotasRequestData {
  return {
    value: quotas.map(quota => ({
      question_guid: quota.questionId,
      answer_quotas: quota.answers.map(answer => ({
        choice_ids: [answer.id],
        min_target: answer.minTarget,
      })),
    })),
  }
}
