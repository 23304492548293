export function findAncestorElementBy(
  predicate: (parent: Element) => boolean = () => true,
  element: Element,
): Element | undefined {
  const parent = element.parentElement

  if (!parent) return undefined
  if (predicate(parent)) return parent

  return findAncestorElementBy(predicate, parent)
}

export function isChildOrSelf(parentElement: Node, childElement: Element): boolean {
  return parentElement === childElement || isChildOf(parentElement, childElement)
}

export function isChildOf(parentElement: Node, childElement: Node): boolean {
  return parentElement !== childElement && parentElement.contains(childElement)
}
