import { useMemoize, type UseMemoizeCache } from '@vueuse/core'

import { filter, type FilterNode, type Value } from '@attest/efl'

import type { Round, RoundOutcome } from './model'

type MemoizeOptions<T> = { cache?: UseMemoizeCache<string, Set<T>> }

export function createRoundsFilter(
  options: MemoizeOptions<Round> = { cache: new Map() },
): (initialData: Set<Round>, filterNode: FilterNode) => Set<Round> {
  const withFilterRounds = useMemoize<Set<Round>, Parameters<typeof filter<Round>>>(
    (rounds, node, getField): Set<Round> => filter(rounds, node, getField),
    {
      getKey: (_, node) => node.toJSON(true) as string,
      cache: options.cache,
    },
  )

  return (initialData, filterNode) => withFilterRounds(initialData, filterNode, getRoundField)
}

export type RoundFieldKey =
  | 'survey.id'
  | 'audience.id'
  | 'audience.country'
  | 'waveTimestamp'
  | 'outcome'
  | `subjects.age`
  | `subjects.${string}.*`
  | `answers.*~`
  | `answers.${string}.*.id`
  | `answers.${string}.*.text`
  | `answers.${string}.*.transcript`
  | `answers.${string}.*.order`

function getRoundField(round: Round, key: RoundFieldKey | string): Value {
  switch (key) {
    case 'waveTimestamp':
      return round.waveTimestamp ?? null
    case 'survey.id':
      return round.survey.id
    case 'audience.id':
      return round.audience.id
    case 'audience.country':
      return round.audience.country
    case 'outcome':
      return round.outcome
    case 'answers.*~':
      return Object.keys(round.answers)
    default: {
      const { groups: { cardId = undefined, field = undefined } = {} } =
        key.match(/^answers\.(?<cardId>.+)\.\*\.(?<field>.+)$/) ?? {}
      if (cardId && field) {
        return (round.answers[cardId] ?? []).map(answer => answer[field as keyof typeof answer])
      }

      if (key === 'subjects.age') {
        return round.subjects.age
      }

      const { groups: { name = undefined } = {} } = key.match(/^subjects\.(?<name>.+)\.\*$/) ?? {}
      if (name && round.subjects[name]) {
        const subject = round.subjects[name]
        return Array.isArray(subject) ? subject : ([subject] as Value)
      }

      return null
    }
  }
}

// This although ugly allows us to strongly type the field key to the expected
// efl values that can be used for that key.
// IE ...fieldAndValues('wave.id', 20) will fail
type RoundFieldKeyToAcceptedValues<K extends RoundFieldKey> = K extends `outcome`
  ? RoundOutcome | RoundOutcome[]
  : K extends `waveTimestamp` | `answers.${string}.*.order` | `subjects.age`
    ? number | number[] | [min: number, max: number] | [min: number, max: number][]
    : K extends
          | `survey.id`
          | `subjects.${string}.*`
          | `audience.id`
          | `audience.country`
          | `answers.*~`
          | `answers.${string}.*.id`
          | `answers.${string}.*.text`
          | `answers.${string}.*.transcript`
      ? string | string[]
      : Value

export function fieldAndValues<K extends RoundFieldKey, V extends RoundFieldKeyToAcceptedValues<K>>(
  key: K,
  value: V,
): [RoundFieldKey, V] {
  return [key, value]
}
