import type {
  BaseApiStudyRecurrence,
  SavedApiStudyRecurrence,
} from '@attest/_api/model/study-recurrence'

import type { StudyRecurrence } from './model'

export function transformApiRecurrenceToStudyRecurrence(
  apiRecurrence?: SavedApiStudyRecurrence | null,
): StudyRecurrence | null {
  if (!apiRecurrence) return null
  return {
    interval: apiRecurrence.interval,
    recurrenceInterval: apiRecurrence.recurrence_interval === 'DAYS' ? 'DAYS' : 'MONTHS',
    timezoneId: apiRecurrence.time_zone_id,
    nextRecurrenceTimestamp: apiRecurrence.timestamp,
  }
}

export function transformRecurrenceToBaseApiRecurrence(
  timeZoneId: string,
  recurrence?: {
    interval: number
    recurrenceInterval: 'DAYS' | 'MONTHS'
    hasFixedInterval: boolean
  } | null,
): BaseApiStudyRecurrence | undefined | null {
  if (recurrence === undefined) return undefined
  if (recurrence === null) return null
  return {
    interval: recurrence.interval,
    recurrence_interval: recurrence.recurrenceInterval,
    time_zone_id: timeZoneId,
  }
}
