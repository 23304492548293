<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { SvgImage } from '../SvgImage'

import type { SvgIconProps } from './interface'

/** @deprecated use packages/design-system/src/components/AttestIcon/AttestIcon.vue
 */
const SvgIcon: FunctionalComponent<SvgIconProps> = props => {
  const { svg, color, size = 'l', isHoverable = false, ...attrs } = props

  const baseClass = 'c-svg-icon'
  const classes = {
    [baseClass]: true,
    [`${baseClass}--${size}`]: true,
    [`${baseClass}--${color}`]: !!color,
    [`${baseClass}--hoverable`]: isHoverable,
  }

  return <SvgImage class={classes} svg={svg} {...attrs} />
}

export default SvgIcon
</script>

<style lang="postcss">
.c-svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--custom-icon-color, var(--attest-color-decorative-grey-icon));

  &--xs,
  &--s,
  &--m,
  &--l,
  &--xl {
    width: 16px;
    height: 16px;
  }

  & > svg {
    flex: 1 1 auto;
    color: currentcolor;
  }

  &--red {
    color: var(--attest-color-interactive-negative-default);
  }

  &--white {
    color: var(--attest-palette-white);
  }

  &--berry {
    color: var(--attest-palette-berry-60);
  }

  &--disabled {
    color: var(--attest-color-text-disabled);
  }
}
</style>
