import type { LDSingleKindContext } from 'launchdarkly-js-client-sdk'

type User = {
  guid: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  jobRole: string | null
  interestTags: {
    name: string
    isSelected: boolean
  }[]
  hasResearchExperience: boolean | null
  organisation: {
    name: string | null
    guid: string | null
    users: unknown[]
  }
  companySector: string | null
  companySize: string | null
  role: string[]
  registrationTimestamp: number
}

export function toContext(user: User, data: Record<string, any> = {}): LDSingleKindContext {
  if (!user.guid) return { key: 'LOGGED_OUT_ANONYMOUS', kind: 'user', anonymous: true }

  return {
    kind: 'user',
    key: user.guid,
    email: user.email ?? undefined,
    firstName: user.firstName ?? undefined,
    lastName: user.lastName ?? undefined,
    organisationGuid: user.organisation.guid ?? undefined,
    organisationName: user.organisation.name ?? undefined,
    organisationSize: user.organisation.users.length,
    companySector: user.companySector ?? undefined,
    companySize: user.companySize ?? undefined,
    hasResearchExperience: user.hasResearchExperience ?? undefined,
    jobRole: user.jobRole ?? undefined,
    userRole: user.role,
    interestTags: user.interestTags.filter(tag => tag.isSelected).map(({ name }) => name),
    registrationTimestamp: user.registrationTimestamp,
    ...data,

    _meta: {
      privateAttributes: ['email', 'firstName', 'lastName', 'organisationName'],
    },
  }
}
