import { isLocalStorageAvailable } from '@attest/browser'

import { createReactiveStorage } from './plugin'

export const EXAMPLE_SURVEY_CATEGORIES = [
  'BRAND_TRACKING',
  'CREATIVE_TESTING',
  'NEW_PRODUCT_DEVELOPMENT',
  'CONSUMER_PROFILING',
  'INTERNATIONAL_EXPANSION',
] as const

export enum DISMISS_OPTIONS {
  DISMISSED = 'DISMISSED',
  NOT_DISMISSED = 'NOT DISMISSED',
}

export enum TOGGLE_OPTIONS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

const reactiveLocalStorage = createReactiveStorage({
  storage: isLocalStorageAvailable() ? window.localStorage : undefined,
  schema: {
    registerReferrerCategory: EXAMPLE_SURVEY_CATEGORIES,
    plansBannerIsDismissed: Object.values(DISMISS_OPTIONS),
    templatesModalIsDismissed: Object.values(DISMISS_OPTIONS),
    resultsBreakdownShowValue: Object.values(TOGGLE_OPTIONS),
    resultsBreakdownShowPercentage: Object.values(TOGGLE_OPTIONS),
  },
} as const)

export default reactiveLocalStorage
export { useReactiveLocalStorage, useLocalStorageSet } from './hook'
