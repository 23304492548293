<script lang="tsx">
import { defineComponent, type PropType, Transition } from 'vue'

import { assertIsElement } from '@attest/dom'
import { emit, EventName } from '@attest/util'

import { FocusTrapper } from '../FocusTrapper'
import { Heading } from '../Heading'
import { RenderedMarkdown } from '../RenderedMarkdown'

import type { ModalSize } from './ModalDefault.interface'

export default defineComponent({
  props: {
    titleDisplay: { type: String, default: null },
    show: { type: Boolean, default: false },
    size: { type: String as PropType<ModalSize>, default: null },
    transitionName: { type: String, default: 'c-modal' },
    handleCancel: { type: Function as PropType<(event: Event) => void>, required: true },
    clickOverlayToCancel: { type: Boolean, default: true },
    dialogClass: { type: String, default: null },
    dialogBodyClass: { type: String, default: null },
    dialogHeaderClass: { type: String, default: null },
    dialogFooterClass: { type: String, default: '' },
    dialogHeaderTitleClass: { type: String, default: null },
    canCancel: { type: Boolean, default: true },
    hasPadding: { type: Boolean, default: true },
  },

  data() {
    return {
      previousElement: null as HTMLElement | null,
    }
  },

  watch: {
    show: {
      handler(value: boolean) {
        this.emitToggle(value)
        if (value) {
          this.focusModal()
        }
      },
      immediate: true,
    },
  },

  mounted() {
    if (!this.show) return

    this.emitToggle(this.show)
  },

  beforeUnmount() {
    if (!this.show) return

    this.emitToggle(false)
  },

  methods: {
    emitToggle(value: boolean) {
      emit(EventName.MODAL_TOGGLE, { value })
    },

    cancel(event: Event) {
      if (!this.canCancel || !this.show) {
        return
      }

      this.handleCancel(event)
    },

    focusModal(): void {
      this.$nextTick(() => {
        this.$nextTick(() => {
          try {
            this.getFocusRef().focus()
          } catch {
            /* empty */
          }
        })
      })
    },

    getFocusRef(): HTMLElement {
      assertIsElement(this.$refs.focus, HTMLElement)
      return this.$refs.focus
    },
  },

  render() {
    const id = `c-modal-${this.$attrs.id || (this as any)._.id}`

    const baseModalClass = 'c-modal'
    const modalClass = {
      [baseModalClass]: true,
      [`${baseModalClass}--${this.size}`]: !!this.size,
      [`${baseModalClass}--no-close`]: !this.canCancel,
      [`${baseModalClass}--no-padding`]: !this.hasPadding,
      [`${baseModalClass}--explicit-cancel`]: !this.clickOverlayToCancel,
    }

    const baseDialogClass = `${baseModalClass}__dialog`
    const dialogClass = {
      [baseDialogClass]: true,
      [this.dialogClass || '']: !!this.dialogClass,
    }

    const headerClass = {
      [`${baseDialogClass}-header`]: true,
      [this.dialogHeaderClass || '']: !!this.dialogHeaderClass,
    }

    const headerTitleClass = {
      [`${baseDialogClass}-header-title`]: true,
      [this.dialogHeaderTitleClass || '']: !!this.dialogHeaderTitleClass,
    }

    const baseDialogBodyClass = `${baseDialogClass}-body`
    const dialogBodyClass = {
      [baseDialogBodyClass]: true,
      [this.dialogBodyClass || '']: !!this.dialogBodyClass,
    }

    return (
      <Transition name={this.transitionName}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
        <aside
          class={modalClass}
          aria-hidden={!this.show}
          hidden={!this.show}
          v-show={this.show}
          onKeyup={event => event.key === 'Escape' && this.cancel(event)}
          onClick={event => this.clickOverlayToCancel && this.cancel(event)}
          data-name="Modal"
        >
          <FocusTrapper
            getFirstElement={() => this.getFocusRef()}
            getLastElement={() => this.$refs.dismiss as HTMLElement}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
              data-name="ModalDefault"
              ref="focus"
              class={dialogClass}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabindex={0}
              role="dialog"
              onClick={e => e.stopPropagation()}
              aria-labelledby={id}
            >
              <div class={headerClass} data-name="ModalDefaultDialogHeader">
                {this.titleDisplay && (
                  <Heading
                    data-name="ModalDefaultTitle"
                    type="display-small"
                    align="left"
                    class={headerTitleClass}
                    id={id}
                  >
                    <RenderedMarkdown text={this.titleDisplay} />
                  </Heading>
                )}
                {this.$slots.headerMeta?.()}
              </div>

              <div class={dialogBodyClass} data-name="ModalDefaultBody">
                {this.$slots.default?.()}
              </div>
              {this.$slots.footer ? (
                <div
                  class={{
                    [`${baseDialogClass}-footer`]: true,
                    [this.dialogFooterClass]: !!this.dialogFooterClass,
                  }}
                >
                  {this.$slots.footer?.()}
                </div>
              ) : null}
            </div>
          </FocusTrapper>
        </aside>
      </Transition>
    )
  },
})
</script>

<style lang="postcss">
:root {
  --modal-padding: var(--attest-spacing-6);
}

.c-modal {
  position: fixed;
  z-index: var(--z-index-modal);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0 0 0 / 60%);
  text-align: center;

  &__dialog {
    position: relative;
    display: inline-flex;
    overflow: visible;
    width: 90%;
    max-width: 480px;
    max-height: calc(100% - var(--attest-spacing-12));
    box-sizing: border-box;
    flex-direction: column;
    padding: 0;
    border-radius: var(--attest-border-radius-l);
    margin: 0;
    background: var(--attest-color-surface-default);
    outline: none;
    text-align: center;
    transition:
      transform var(--attest-timing-default) ease var(--attest-timing-faster),
      opacity var(--attest-timing-default) ease var(--attest-timing-faster);
    vertical-align: top;
    white-space: normal;

    &-header {
      display: flex;
      flex-shrink: 0;
      flex-wrap: nowrap;
      align-items: center;
      padding: var(--attest-spacing-6) var(--attest-spacing-6) 0;

      &-title {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
      }

      &-dismiss {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        padding: 0 0 0 var(--attest-spacing-2);
        border: none;
        margin: 0;
        margin-left: auto;
        background-color: transparent;

        @context scoped {
          &-icon {
            width: var(--attest-spacing-6);
            height: var(--attest-spacing-6);
          }
        }
      }
    }

    &-body {
      display: flex;
      overflow: auto;
      flex-direction: column;
      padding: var(--attest-spacing-4) var(--attest-spacing-6);

      &:last-of-type {
        padding-bottom: var(--attest-spacing-10);
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: var(--attest-spacing-6) var(--attest-spacing-6);
    }

    @media (--attest-media-s) {
      width: 100%;
    }

    @media (--attest-media-l) {
      min-width: 420px;
    }
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;

    ^&__dialog {
      opacity: 0;
      transform: scale(0.9);
    }
  }

  &-leave-active,
  &-enter-active {
    transition: opacity var(--attest-timing-default) ease;

    ^&__dialog {
      transition:
        transform var(--attest-timing-default) ease,
        opacity var(--attest-timing-default) ease;
    }
  }

  &-enter-to,
  &-leave-from {
    opacity: 1;

    ^&__dialog {
      opacity: 1;
      transform: scale(1);
    }
  }

  &--small {
    ^&__dialog {
      max-width: calc(var(--attest-spacing-2) * 44);
    }
  }

  &--medium {
    ^&__dialog {
      max-width: calc(var(--attest-spacing-2) * 85);
    }
  }

  &--large {
    ^&__dialog {
      max-width: calc(var(--attest-spacing-2) * 100);
    }
  }

  @context scoped {
    &--no-padding {
      .c-modal__dialog-body {
        padding: 0;
        border-radius: 18px;
      }
    }
  }
}
</style>
