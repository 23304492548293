<script lang="tsx">
import { getLangDir } from 'rtl-detect'
import { defineComponent, type DefineComponent, type PropType } from 'vue'

import { removeMarkdownCharacters } from '@attest/markdown'
import { Debounce } from '@attest/util'
import { castSlots, create, createEmitter, renderSlotOrView } from '@attest/vue-tsx'

import { FormField } from '../FormField'
import { Tag } from '../Tag'
import TextareaAutosize from '../TextareaAutosize.vue'

import type { TextInputProps, TextInputSlots } from './interface'

export type TextInputEvents = {
  valueChange(value: string): void
  showError(payload: boolean): void
}

export const emit = createEmitter<TextInputEvents>()
const nativeEmit = createEmitter<Record<string, any>>()

const TextInput = defineComponent({
  model: {
    prop: 'value',
    event: 'value-change',
  },

  props: {
    type: { type: String as PropType<TextInputProps['type']>, default: 'text' },
    maxChars: { type: Number, default: Number.POSITIVE_INFINITY },
    maxCount: { type: Number, default: Number.POSITIVE_INFINITY },
    value: { type: [String, Number], default: '' },
    getLengthOfValue: {
      type: Function as PropType<(value: string) => number>,
      default: (value: string) => value.length,
    },
    sizeVariant: { type: String as PropType<TextInputProps['sizeVariant']>, default: 'default' },
    styleVariant: { type: String as PropType<TextInputProps['styleVariant']>, default: 'default' },
    textColor: { type: String as PropType<TextInputProps['textColor']>, default: 'default' },
    textAlign: { type: String as PropType<TextInputProps['textAlign']>, default: 'left' },
    error: { type: String, default: null as string | null },
    right: { type: Boolean, default: false },
    validate: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: undefined },
    autocomplete: { type: String, default: undefined },
    autofocus: { type: Boolean, default: undefined },
    inputClass: { type: String, default: undefined },
    inputFieldClass: { type: String, default: undefined },
    name: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    step: { type: Number, default: undefined },
    wrapText: { type: Boolean, default: false },
    showPlaceholder: { type: Boolean, default: true },
    showErrorText: { type: Boolean, default: true },
    errorClass: { type: String, default: undefined },
    hideErrorIcon: { type: Boolean, default: false },
    removeLabel: { type: Boolean, default: false },
    language: { type: String, default: 'en' },
    pattern: { type: String, default: undefined },
    direction: { type: String as PropType<TextInputProps['direction']>, default: undefined },
    allowsNewLines: { type: Boolean, default: false },
    screenReaderText: { type: String, default: undefined },
    label: { type: String, default: undefined },
    ignoreDirty: { type: Boolean, default: false },
  },

  data() {
    return {
      isDirty: typeof this.error !== 'string' || !!this.value,
      isFocussed: false,
      validationError: '',
      debounceOnInput: new Debounce(() => {}),
    }
  },

  computed: {
    showError(): boolean {
      return (this.ignoreDirty || this.isDirty) && this.validate
    },

    displayError(): string | null {
      if (!this.showError) return null
      if (typeof this.error === 'string') return this.error
      return this.validationError || null
    },

    hasError(): boolean {
      return typeof this.displayError === 'string' || this.showMaxCount
    },

    hasValue(): boolean {
      return this.value !== undefined && this.value !== null && this.value !== ''
    },

    valueLength(): number {
      return this.getLengthOfValue(removeMarkdownCharacters(String(this.value)))
    },

    showMaxCount(): boolean {
      return this.maxCount !== Number.POSITIVE_INFINITY && this.valueLength > this.maxCount
    },

    isEmpty(): boolean {
      return !this.hasValue && this.type !== 'number'
    },

    textDirection(): TextInputProps['direction'] | undefined {
      return this.direction || getLangDir(this.language as string)
    },
  },

  mounted() {
    this.debounceOnInput = new Debounce(() => {
      this.validationError = this.getValidationMessage()
    }, 200)
  },

  methods: {
    getValidationMessage() {
      const input = (
        this.wrapText ? (this.$refs.input as DefineComponent)?.$el : this.$refs.input
      ) as HTMLInputElement | undefined
      if (!input) return ''
      emit(this, 'showError', !input.checkValidity())
      return input.checkValidity() ? '' : input.validationMessage
    },

    onInput(event: Event) {
      this.debounceOnInput.invoke()
      if (
        !(event.target instanceof HTMLInputElement) &&
        !(event.target instanceof HTMLSelectElement) &&
        !(event.target instanceof HTMLTextAreaElement)
      ) {
        return
      }
      event.target.value = event.target.value.slice(0, this.maxChars)

      emit(this, 'valueChange', event.target.value)
      nativeEmit(this, 'input', event)
    },

    onBlur(e: FocusEvent) {
      this.isDirty = true
      this.isFocussed = false
      this.validationError = this.getValidationMessage()
      nativeEmit(this, 'blur', e)
    },

    onTextAreaKeydown(e: KeyboardEvent) {
      if (e.keyCode === 13 && !this.allowsNewLines) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      this.onKeydown(e)
    },

    onKeydown(e: KeyboardEvent) {
      e.stopPropagation()
      const hasMaxChars = this.maxChars !== Number.POSITIVE_INFINITY
      const valueGreaterThanMax = this.valueLength >= this.maxChars
      const isMaxChars = hasMaxChars && valueGreaterThanMax && !e.ctrlKey
      if (isMaxChars && e.key.length === 1) e.preventDefault()
      nativeEmit(this, 'keydown', e)
    },

    onFocus(e: FocusEvent) {
      if (this.readonly) return
      this.isFocussed = true
      nativeEmit(this, 'focus', e)
    },

    onClick(e: MouseEvent) {
      nativeEmit(this, 'click', e)
    },
  },

  render() {
    const slots = castSlots<TextInputSlots>(this.$slots)
    const baseClass = 'c-text-input'
    const placeholder = this.placeholder
    const containerClass = {
      [baseClass]: true,
      [`${baseClass}--error`]: this.hasError,
      [`${baseClass}--disabled`]: this.disabled,
      [`${baseClass}--right`]: this.right,
      [`${baseClass}--has-placeholder`]: !!placeholder,
      [`${baseClass}--size-${this.sizeVariant}`]: !!this.sizeVariant,
      [`${baseClass}--style-${this.styleVariant}`]: !!this.styleVariant,
      [`${baseClass}--focus`]: this.isFocussed,
      [`${baseClass}--empty`]: this.isEmpty,
      [`${baseClass}--text-color-${this.textColor}`]: true,
      [`${baseClass}--text-align-${this.textAlign}`]: true,
      [`${baseClass}--wrap`]: this.wrapText,
    }

    const componentInputFieldClass = {
      [`${this.inputFieldClass}`]: !!this.inputFieldClass,
      [`${baseClass}__input-field`]: true,
      [`${baseClass}__input-field--${this.type}`]: !!this.type,
      [`${baseClass}__input-field--has-error`]: !!this.hasError,
    }

    const inputData = {
      ref: 'input',
      class: componentInputFieldClass,
      'data-name': 'TextInputInput',
      name: this.name,
      required: this.required,
      disabled: this.disabled,
      readonly: this.readonly,
      value: this.value,
      'aria-label': placeholder || undefined,
      'aria-invalid': this.hasError,
      'aria-describedBy': this.hasError ? 'TextInputError' : undefined,
      autofocus: this.autofocus,
      dir: this.textDirection,
      lang: this.language,
      pattern: this.pattern,
      placeholder: this.placeholder,
      onFocus: (e: FocusEvent) => {
        e.stopPropagation()
        this.onFocus(e)
      },
      onBlur: (e: FocusEvent) => {
        e.stopPropagation()
        this.onBlur(e)
      },
      onInput: (e: Event) => {
        e.stopPropagation()
        this.onInput(e)
      },
      onKeydown: this.wrapText ? this.onTextAreaKeydown : this.onKeydown,
      onClick: (e: MouseEvent) => {
        e.stopPropagation()
        this.onClick(e)
      },
    }

    return (
      <FormField
        sizeVariant={this.wrapText ? 'auto' : this.sizeVariant}
        styleVariant={this.styleVariant}
        right={this.right}
        inputClass={this.inputClass}
        placeholder={placeholder}
        placeholderClass={`${baseClass}__placeholder`}
        disabled={this.disabled}
        readonly={this.readonly}
        showPlaceholder={this.showPlaceholder}
        showErrorText={this.showErrorText}
        validationError={this.displayError || ''}
        hasError={this.hasError}
        alignTop={this.wrapText}
        isEmpty={this.isEmpty}
        isFocussed={this.isFocussed}
        defaultSlotClass={containerClass}
        errorClass={this.errorClass}
        hideErrorIcon={this.hideErrorIcon}
        removeLabel={this.removeLabel}
        screenReaderText={this.screenReaderText}
      >
        {{
          label: this.$slots.label ?? (() => this.label),
          pre: () =>
            renderSlotOrView(slots.pre, {
              createView: () => undefined,
            }),
          prefix: () =>
            renderSlotOrView(slots.prefix, {
              createView: () => undefined,
            }),
          default: () => {
            return [
              this.$slots.overlay?.(),
              this.wrapText ? (
                <TextareaAutosize {...inputData} />
              ) : (
                <input
                  min={this.min}
                  max={this.max}
                  step={this.step}
                  type={this.type}
                  autocomplete={this.autocomplete}
                  {...inputData}
                />
              ),
            ]
          },
          errorPrefix: () =>
            this.showMaxCount && (
              <Tag theme="error">{`${this.valueLength} / ${this.maxCount}`}</Tag>
            ),
          postfix: this.$slots.postfix,
          post: this.$slots.post,
        }}
      </FormField>
    )
  },
})

export default create<TextInputProps, TextInputEvents>(TextInput)
</script>

<style lang="postcss">
:root {
  --error-max-height: var(--attest-spacing-16);
  --transition-delay: var(--attest-timing-default);

  --z-index-input: 1;
  --z-index-input-field: 1;
  --z-index-placeholder-icon: 2;
}

.c-text-input {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;

  &__input {
    position: relative;
    z-index: var(--z-index-input);
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    flex-direction: row;
    cursor: text;
    transition: border-color var(--attest-timing-fast) ease;
  }

  &__input-field {
    z-index: var(--z-index-input-field);
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    padding-left: var(--attest-spacing-2);
    border: none;
    margin-top: -1px;
    background: transparent;
    box-shadow: none;
    color: var(--attest-color-text-default);
    cursor: inherit;
    font: var(--attest-font-body-2);
    white-space: nowrap;

    &--number {
      /* For Firefox */
      appearance: textfield;

      /* Webkit browsers like Safari and Chrome */
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        appearance: none;
      }
    }

    &[dir='rtl'] {
      padding-right: var(--attest-spacing-2);
      direction: rtl;
    }

    &--has-error {
      border-color: var(--attest-color-interactive-negative-default);
    }
  }

  &--focus {
    ^&__input {
      border-color: var(--attest-color-interactive-default);
    }
  }

  &--empty&:not(&--error) {
    ^&__input-field {
      padding-right: var(--attest-spacing-2);
    }
  }

  &--right {
    ^&__input-field {
      margin-right: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &--size-xs {
    ^&__input-field {
      font: var(--attest-font-body-2);
    }
  }

  &--size-s {
    ^&--wrap {
      min-height: var(--attest-spacing-12);
    }
  }

  &--size-default {
    ^&--wrap {
      min-height: var(--attest-spacing-12);
    }

    ^&__input-field {
      height: 44px;
    }
  }

  &--size-l {
    ^&__input-field {
      height: auto;
    }
  }

  &--text-align-right {
    ^&__input-field {
      text-align: right;
    }
  }

  &--wrap {
    ^&__input-field {
      width: 100%;
      height: auto;
      outline: none;
      white-space: pre-wrap;
    }
  }

  &--text-color-transparent {
    ^&__input-field {
      color: transparent;
    }
  }

  &--disabled {
    ^&__input-field {
      color: var(--attest-color-text-subdued);
    }
  }

  &--size-l,
  &--wrap {
    ^&__input-field {
      padding: var(--attest-spacing-2);
    }
  }
}
</style>
