import type { ScopeContext } from '@sentry/types'
import { captureException } from '@sentry/vue'
import axios from 'axios'

export function capture(error: unknown, context?: Partial<ScopeContext>): void {
  // eslint-disable-next-line no-console
  console.error(error)
  if (axios.isAxiosError(error) && !error.message) {
    captureException(new Error('Axios Error'), {
      ...context,
      extra: { ...error, ...context?.extra },
    })
    return
  }
  captureException(error, context)
}

export { withScope } from '@sentry/vue'
