import { type FilterBlock, type FilterExpression, Logic, Operator } from '@attest/efl-antlr4'
import { difference, never, union } from '@attest/util'

import { INVERSE } from './factory'
import {
  ArrayOperator,
  type ArrayValue,
  type FilterNode,
  isFilterBlock,
  isFilterExpression,
  type PrimitiveValue,
  type Value,
} from './model'

export type GetField<T> = (data: T, key: string) => Value

export function filter<T = Value>(
  data: Set<T>,
  filterNode: FilterNode,
  getField?: GetField<T>,
): Set<T> {
  return filterWithNode(data, filterNode, getField)
}

function filterWithBlock<T>(
  rounds: Set<T>,
  filterBlock: FilterBlock,
  getField?: GetField<T>,
): Set<T> {
  switch (filterBlock.logic) {
    case Logic.OR:
      return union(filterBlock.items.map(item => filterWithNode(rounds, item, getField)))
    case Logic.AND:
      // eslint-disable-next-line unicorn/no-array-reduce
      return filterBlock.items.reduce((prev, item) => filterWithNode(prev, item, getField), rounds)
    case Logic.NONE:
      return filterWithNode(rounds, filterBlock.items[0], getField)
    default:
      throw never(filterBlock.logic)
  }
}

function filterWithExpression<T>(
  data: Set<T>,
  filterExpression: FilterExpression,
  getField?: GetField<T>,
): Set<T> {
  if (filterExpression.not) {
    return difference(data, filterWithExpression(data, INVERSE(filterExpression), getField))
  }

  const fieldArrayOperatorType =
    filterExpression.key.includes(`[*]`) || filterExpression.key.includes(`.*`)
      ? ArrayOperator.ANY
      : ArrayOperator.NONE

  // current backport until filter expresison support this
  const valueArrayOperator = !Array.isArray(filterExpression.value)
    ? ArrayOperator.NONE
    : filterExpression.operator === Operator.INCLUDESANY ||
        filterExpression.operator === Operator.IN
      ? ArrayOperator.ANY
      : filterExpression.operator === Operator.BETWEEN
        ? ArrayOperator.NONE
        : filterExpression.operator === Operator.INCLUDESALL
          ? ArrayOperator.ALL
          : ArrayOperator.ANY

  return new Set(
    [...data].filter(item =>
      compare(
        filterExpression.operator,
        getField?.(item, filterExpression.key) ?? null,
        fieldArrayOperatorType,
        filterExpression.value,
        valueArrayOperator,
      ),
    ),
  )
}

function filterWithNode<T>(rounds: Set<T>, item: FilterNode, getField?: GetField<T>): Set<T> {
  if (isFilterBlock(item)) {
    return filterWithBlock(rounds, item, getField)
  }

  if (isFilterExpression(item)) {
    return filterWithExpression(rounds, item, getField)
  }

  return rounds
}

function compare(
  operator: Operator,
  field: Value,
  fieldArrayOperator: ArrayOperator,
  value: Value,
  valueArrayOperator: ArrayOperator,
): boolean {
  if (Array.isArray(field)) {
    if (fieldArrayOperator === ArrayOperator.ALL) {
      return field.every(a => compare(operator, a, ArrayOperator.NONE, value, valueArrayOperator))
    }

    if (fieldArrayOperator === ArrayOperator.ANY) {
      return field.some(a => compare(operator, a, ArrayOperator.NONE, value, valueArrayOperator))
    }

    return false
  }

  if (Array.isArray(value)) {
    if (valueArrayOperator === ArrayOperator.ALL) {
      return value.every(b => compare(operator, field, fieldArrayOperator, b, ArrayOperator.NONE))
    }

    if (valueArrayOperator === ArrayOperator.ANY) {
      return value.some(b => compare(operator, field, fieldArrayOperator, b, ArrayOperator.NONE))
    }

    if (Operator.BETWEEN) {
      return compareRange(field, value, (a, [min, max]) => min <= a && a <= max)
    }
    return false
  }

  switch (operator) {
    case Operator.EQ:
      return compareFieldAndValue(field, value, (a, b) => a === b)
    case Operator.GE:
      return compareNumber(field, value, (a, b) => a >= b)
    case Operator.GT:
      return compareNumber(field, value, (a, b) => a > b)
    case Operator.LE:
      return compareNumber(field, value, (a, b) => a <= b)
    case Operator.LT:
      return compareNumber(field, value, (a, b) => a < b)
    case Operator.ENDSWITH:
      return compareString(field, value, (a, b) =>
        a.toLocaleLowerCase().endsWith(b.toLocaleLowerCase()),
      )
    case Operator.STRICTENDSWITH:
      return compareString(field, value, (a, b) => a.endsWith(b))
    case Operator.STARTSWITH:
      return compareString(field, value, (a, b) =>
        a.toLocaleLowerCase().startsWith(b.toLocaleLowerCase()),
      )
    case Operator.STRICTSTARTSWITH:
      return compareString(field, value, (a, b) => a.startsWith(b))
    case Operator.CONTAINS:
      return compareString(field, value, (a, b) =>
        a.toLocaleLowerCase().includes(b.toLocaleLowerCase()),
      )
    case Operator.STRICTCONTAINS:
      return compareString(field, value, (a, b) => a.includes(b))
    case Operator.IN:
      return compare(Operator.EQ, field, fieldArrayOperator, value, valueArrayOperator)
    case Operator.INCLUDES:
      return compare(Operator.EQ, field, fieldArrayOperator, value, valueArrayOperator)
    case Operator.INCLUDESANY:
      return compare(Operator.EQ, field, valueArrayOperator, value, ArrayOperator.ANY)
    case Operator.INCLUDESALL:
      return compare(Operator.EQ, field, valueArrayOperator, value, ArrayOperator.ALL)

    case Operator.BETWEEN:
      return false
    default:
      throw never(operator)
  }
}

function compareFieldAndValue(
  field: PrimitiveValue,
  value: PrimitiveValue,
  predicate: (a: PrimitiveValue, b: PrimitiveValue) => boolean,
): boolean {
  return predicate(field, value)
}

function compareNumber(
  field: PrimitiveValue,
  value: PrimitiveValue,
  predicate: (field: number, value: number) => boolean,
): boolean {
  if (typeof field !== 'number' || typeof value !== 'number') {
    return false
  }
  return predicate(field, value)
}

function compareString(
  field: PrimitiveValue,
  value: PrimitiveValue,
  predicate: (field: string, value: string) => boolean,
): boolean {
  if (typeof field !== 'string' || typeof value !== 'string') {
    return false
  }
  return predicate(field, value)
}

function compareRange(
  field: PrimitiveValue,
  value: ArrayValue,
  predicate: (field: number, value: [min: number, max: number]) => boolean,
): boolean {
  if (typeof field !== 'number' || !Array.isArray(value)) {
    return false
  }
  return predicate(field, value as [number, number])
}
