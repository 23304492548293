import type { ApiAnswerQuota } from './answer-quotas'
import type { ApiDisplayLogic } from './display-logic'
import type { ApiNode } from './node'

export type ApiAnswerGroup = {
  choices: { id: string }[]
  name?: string
}

export type ApiCardType =
  | 'single_choice'
  | 'multiple_choice'
  | 'message'
  | 'blank'
  | 'ranked'
  | 'nps'
  | 'either_or'
  | 'grid'
  | 'grid_v2'
  | 'video'
  | ApiOpenTextType

export type ApiOpenTextType = 'text_response_short' | 'text_response_medium' | 'text_response_long'

const questionCardTypes = [
  'text_response_short',
  'text_response_medium',
  'text_response_long',
  'single_choice',
  'multiple_choice',
  'ranked',
  'nps',
  'either_or',
  'video',
] as const

const questionCardTypeSet: Set<string> = new Set(
  questionCardTypes,
) satisfies Set<ApiCardTypeQuestion>

export type ApiCardTypeQuestion = (typeof questionCardTypes)[number]

export type ApiCard =
  | ApiMessageCard
  | ApiQuestionCard
  | ApiGridQuestionCard
  | ApiGridV2QuestionCard
  | ApiBlankCard

export type SavedApiCard =
  | SavedApiMessageCard
  | SavedApiQuestionCard
  | SavedApiGridQuestionCard
  | SavedApiGridV2QuestionCard
  | SavedApiBlankCard

export type SavedAnswer = Omit<ApiAnswer, 'id'> & { id: string }

export type SavedApiQuestionCard = Omit<ApiQuestionCard, 'answers'> & {
  answers: SavedAnswer[]
  guid: string
  published_at?: number
  question_id: number
  question_guid: string
  creation_time: number
}

export type ApiCardPosition = {
  x: number
  y: number
}

export type ApiMessageCard = {
  card_type: Satisfies<'message', ApiCardType>
  position?: ApiCardPosition | null
  next?: number | null
  text: string
  image_url: string | null
  media_overrides?: {
    [audience_id: string]: {
      image_url: string
    }
  }
  display_logic?: ApiDisplayLogic | null
  translations?: { [languageCountry: string]: { text: string } }
  omitted_from_audiences: string[]
}

export type SavedApiMessageCard = ApiMessageCard & { guid: string; published_at?: number }

type Satisfies<T extends U, U> = T

export type ApiBlankCard = {
  card_type: Satisfies<'blank', ApiCardType>
  display_logic?: ApiDisplayLogic | null
  position?: ApiCardPosition | null
  next?: number | null
}

export type SavedApiBlankCard = ApiBlankCard & { guid: string; published_at?: number }

export type ApiQuestionCard = {
  card_type: Satisfies<ApiCardTypeQuestion, ApiCardType>
  position?: ApiCardPosition | null
  next?: number | null
  title: string
  piping?: { source_question_order: number | null; source_answer_order: number }[]
  randomized: boolean
  na: boolean
  none: boolean
  other: boolean
  answer_quotas: ApiAnswerQuota[]
  max_selections?: number | null
  mask_card_index?: number | null
  answers: ApiAnswer[]
  title_content?: {
    audio_url?: string | null
    image_url?: string | null
    video_url?: string | null
    media_overrides?: {
      [audience_id: string]: {
        audio_url: string | null
        image_url: string | null
        video_url: string | null
      }
    }
  }
  image?: string
  display_logic?: ApiDisplayLogic | null
  groups?: ApiAnswerGroup[]
  image_answer_display_size?: 'large' | 'compact'
  translations?: Record<string, { title: string }>
  omitted_from_audiences: string[]
}

export type SavedApiGridQuestionCard = Omit<ApiGridQuestionCard, 'answers'> & {
  guid: string
  published_at?: number | undefined
  answers: SavedApiAnswerGrid
  question_id: number
  question_guid: string
  creation_time: number
}

export type SavedApiGridV2QuestionCard = Omit<ApiGridV2QuestionCard, 'answers'> & {
  guid: string
  answers: SavedApiAnswerGridV2
  question_id: number
  creation_time?: number
  published_at?: number | undefined
}

export type SavedApiAnswerGrid = {
  subjects: SavedApiAnswerGridSubjects
  options: SavedApiAnswerGridOptions
  choices: ApiAnswerGridChoice[]
}

export type SavedApiAnswerGridV2 = {
  subjects: SavedApiAnswerGridV2Subjects
  options: SavedApiAnswerGridOptions
}

export type ApiGridQuestionCard = Pick<
  ApiQuestionCard,
  'title' | 'piping' | 'title_content' | 'display_logic' | 'translations' | 'omitted_from_audiences'
> & {
  card_type: Satisfies<'grid', ApiCardType>
  position?: ApiCardPosition | null
  next?: number | null
  answers: ApiAnswerGrid
  creation_time?: number
  question_id?: number
  mask_card_index?: number | null
  randomized?: boolean
  none?: boolean
  na?: boolean
  other?: boolean
}

export type ApiGridV2QuestionCard = Omit<ApiGridQuestionCard, 'card_type'> & {
  card_type: Satisfies<'grid_v2', ApiCardType>
}

export type ApiAnswerGrid = {
  subjects: ApiAnswerGridSubjects
  options: ApiAnswerGridOptions
}

export type ApiAnswerGridOptionType = 'single_choice' | 'multiple_choice'

export type ApiAnswerGridSubjects = {
  headers: (ApiAnswer & {
    mask?: {
      answer_index: number
    }
  })[]

  randomized: boolean
  id?: string
}

export type SavedApiAnswerGridSubjects = Omit<ApiAnswerGridSubjects, 'headers'> & {
  headers: (SavedAnswer & { mask?: { answer_index: number } })[]
}

export type SavedApiAnswerGridV2Subject = Omit<SavedAnswer, 'id'> & {
  guid: string
  mask?: { answer_index: number }
}

export type SavedApiAnswerGridV2Subjects = Omit<ApiAnswerGridSubjects, 'headers'> & {
  headers: SavedApiAnswerGridV2Subject[]
}

export type ApiAnswerGridOptions = {
  headers: ApiAnswer[]
  type: ApiAnswerGridOptionType
  randomized: boolean
  max_selections: number | null
  groups?: ApiAnswerGroup[]
  id?: string
  na?: boolean
  none?: boolean
  image_answer_display_size?: 'large' | 'compact'
}

export type SavedApiAnswerGridOptions = Omit<ApiAnswerGridOptions, 'headers'> & {
  headers: SavedAnswer[]
}

export type ApiAnswerGridChoiceValue = {
  id: string
  type: 'cell' | 'na' | 'none'
  subject_id?: string
  option_id?: string
  draft_only?: boolean
}

export type ApiAnswerGridChoice = {
  type: 'list'
  values: ApiAnswerGridChoiceValue[]
  subject_id?: string
}

export type ApiAnswer = {
  id?: string
  type: ApiAnswerResponse
  text?: string
  pinned?: boolean
  qualifying?: boolean
  image_url?: string | null
  filename?: string | null
  next?: number | null
  quota_id?: string | null
  draft_only?: boolean
  translations?: Record<string, { text: string }>
  omitted_from_audiences?: string[]
  media_overrides?: {
    [audienceId: string]: {
      image_url: string
      filename: string
    }
  }
}

type ApiAnswerResponse = 'image' | 'text'

export function isSavedApiAnswerRoutableQuestionCard(apiNode: ApiNode): apiNode is ApiQuestionCard {
  return questionCardTypeSet.has(apiNode.card_type)
}

export function isApiQuestionCard(
  apiCard: ApiNode,
): apiCard is ApiQuestionCard | ApiGridQuestionCard | ApiGridV2QuestionCard {
  return (
    questionCardTypeSet.has(apiCard.card_type) ||
    apiCard.card_type === 'grid' ||
    apiCard.card_type === 'grid_v2'
  )
}

export function isSavedApiQuestionCard(
  apiCard: SavedApiCard,
): apiCard is SavedApiQuestionCard | SavedApiGridQuestionCard | SavedApiGridV2QuestionCard {
  return (
    questionCardTypeSet.has(apiCard.card_type) ||
    apiCard.card_type === 'grid' ||
    apiCard.card_type === 'grid_v2'
  )
}

export function isSavedApiGridQuestionCard(apiCard: ApiCard): apiCard is ApiGridQuestionCard {
  return apiCard.card_type === 'grid'
}
