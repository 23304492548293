import { isFetchError } from './error'

export type DefaultErrorCodes = 'UNKNOWN' | 'NOT_FOUND' | 'UNAUTHORIZED'

/**
 * Get an error code from unknown data, typically an uncontracted response error
 *
 * @example
 * ```ts
 * const [_, error] = await fetchRequest()
 *
 * if (error) {
 *   const code = getErrorCode(error)
 *   return
 * }
 * ```
 */
export function getErrorCode(e: unknown): DefaultErrorCodes {
  if (isFetchError(e)) {
    const code = getCodeFromData(e.response?.data)
    if (code !== 'UNKNOWN') return code as DefaultErrorCodes
    if (e.response?.status === 404) return 'NOT_FOUND'
    if (e.response?.status === 401) return 'UNAUTHORIZED'
  }

  return 'UNKNOWN'
}

function getCodeFromData(data: unknown): string {
  if (Array.isArray(data)) return data[0] ?? 'UNKNOWN'
  if (typeof data === 'string' && data.length > 0) return data
  if (typeof data !== 'object' || data === null) return 'UNKNOWN'

  const possible: Partial<Record<string, any>> = data
  return getCodeFromData(
    possible?.reason ??
      possible?.failure ??
      possible?.cause ??
      possible?.error ??
      possible?.errors ??
      Object.values(possible),
  )
}
