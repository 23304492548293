import type {
  SavedApiBlankCard,
  SavedApiCard,
  SavedApiGridQuestionCard,
  SavedApiMessageCard,
  SavedApiQuestionCard,
} from '@attest/_api/model/card'
import type { ApiGroup, ApiNode, SavedApiNode } from '@attest/_api/model/node'

export function isApiBlankCard(node: SavedApiNode): node is SavedApiBlankCard {
  return node.card_type === 'blank'
}

export function isApiMessageCard(node: SavedApiNode): node is SavedApiMessageCard {
  return node.card_type === 'message'
}

export function isSavedApiGridQuestion(node: SavedApiNode): node is SavedApiGridQuestionCard {
  return node.card_type === 'grid'
}

export function isApiQuestion(node: SavedApiNode): node is SavedApiQuestionCard {
  return (
    !isApiBlankCard(node) &&
    !isApiMessageCard(node) &&
    !isSavedApiGridQuestion(node) &&
    !(node.card_type === 'grid_v2')
  )
}

export function isApiGroup(node: SavedApiNode | ApiNode): node is ApiGroup {
  return node.card_type === 'group'
}

export function isRootRandomizedApiGroup(
  node: SavedApiNode | ApiNode,
): node is ApiGroup & { position: undefined } {
  return isApiGroup(node) && !node.position
}

export function isApiCard(node: SavedApiNode): node is SavedApiCard {
  return node.card_type !== 'group'
}
