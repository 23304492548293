<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create, type ElementAttributes } from '@attest/vue-tsx'

import { Card } from './Card'
import { Heading } from './Heading'

export type BlockProps = {
  title?: string
}

const Block: FunctionalComponent<BlockProps> = (props, { slots }) => {
  const { title, ...attrs } = props

  const baseClass = 'c-block'
  const blockClass = {
    [baseClass]: true,
  }

  const baseTitleClass = `${baseClass}__title`
  const baseBodyClass = `${baseClass}__body`

  return (
    <Card class={blockClass} {...attrs}>
      {title ? (
        <div class={baseTitleClass}>
          <Heading
            type="body-large"
            isBold
            class={`${baseTitleClass}-heading`}
            data-name="block-title"
          >
            {title}
          </Heading>
          {slots.meta && (
            <div class={`${baseClass}__title-meta`} data-name="block-title-meta">
              {slots.meta?.()}
            </div>
          )}
        </div>
      ) : null}
      <div class={baseBodyClass} data-name="block-body">
        {slots.default?.()}
      </div>
    </Card>
  )
}

export default create<BlockProps & ElementAttributes<'div'>>(Block)
</script>

<style lang="postcss">
.c-block {
  display: flex;
  flex-direction: column;
  background-color: var(--attest-color-surface-default);

  &__body {
    display: flex;
    align-items: center;
  }

  &__title {
    display: flex;
    height: calc(var(--attest-spacing-2) * 6);
    box-sizing: border-box;
    align-items: center;
    padding: var(--attest-spacing-5) var(--attest-spacing-6) var(--attest-spacing-3);
    border-bottom: 1px solid var(--block-border-color);

    &-heading {
      @context scoped {
        margin: 0;
      }
    }
  }

  &__title-meta {
    margin-left: auto;
  }
}
</style>
