export type Range = {
  min: number
  max: number
}

export function isRange(range: unknown): range is Range {
  if (typeof range !== 'object') {
    return false
  }

  if (!range) {
    return false
  }

  const maybeRange = range as any
  return typeof maybeRange.min === 'number' && typeof maybeRange.max === 'number'
}

export function expandValuesToRanges(rangeExpansions: number[]): Range[] {
  const ranges: Range[] = []

  const sortedExpansions = rangeExpansions.slice()
  sortedExpansions.sort((a, b) => a - b)

  if (sortedExpansions.length > 0) {
    let min = sortedExpansions[0]
    let max = min
    if (min === undefined || max === undefined) return []

    for (let i = 1; i < sortedExpansions.length; i++) {
      const num = sortedExpansions[i]
      if (num === undefined) continue

      if (max + 1 !== num) {
        ranges.push({ min, max })
        min = num
      }

      max = num
    }

    ranges.push({ min, max })
  }

  return ranges
}

export function expandRangesToValues(filters: Range[]): number[] {
  const filtersLength = filters.length
  const quitEarly = filtersLength === 0

  if (quitEarly) {
    return []
  }

  filters.sort((a, b) => {
    return a.min - b.min
  })

  const firstFilter = filters[0]
  if (!firstFilter) return []
  let lastNum = firstFilter.min - 1

  const numbers = [] as number[]
  for (let i = 0; i < filtersLength; i++) {
    const filter = filters[i]
    if (!filter) continue
    const min = Math.max(lastNum + 1, filter.min)
    const max = filter.max

    for (let j = min; j <= max; j++) {
      numbers.push(j)
    }

    lastNum = Math.max(lastNum, max)
  }

  return numbers
}

export function isOverlappingRanges(range: Range, ranges: Range[]): boolean {
  return ranges.some(curRange => {
    if (range === curRange) return false
    return (
      (range.min <= curRange.min && curRange.min <= range.max) ||
      (range.min <= curRange.max && curRange.max <= range.max) ||
      (curRange.min <= range.min && range.min <= curRange.max) ||
      (curRange.min <= range.max && range.max <= curRange.max)
    )
  })
}

/**
 * Is value inclusive of min and max values (Range)
 *
 * @example
 * ```ts
 * import { isInRange } from '@attest/util'
 *
 * isInRange(2, { min: 1, max: 3 }) // true
 * isInRange(5, { min: 1, max: 3 }) // false
 * ```
 */
export function isInRange(value: number, range: Range): boolean {
  return value >= range.min && value <= range.max
}

/**
 * bound a value between a min and max number
 *
 * @example
 * ```ts
 * import { clamp } from '@attest/util'
 *
 * clamp(2, { min: 1, max: 3 }) // 2
 * clamp(5, { min: 1, max: 3 }) // 3
 * clamp(0, { min: 1, max: 3 }) // 1
 * ```
 */
export function clamp(value: number, range: Range): number {
  return Math.min(Math.max(value, range.min), range.max)
}
