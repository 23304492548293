import type { EmptyStudyResultsCard } from '../../models'
import type {
  StudyResultsAnswer,
  StudyResultsAnswerResponse,
  StudyResultsAnswerType,
} from '../../models/answer/model'
import type { StudyResultsRespondent } from '../../models/respondent/model'

import type {
  ApiStudyResultsResponse,
  ApiStudyResultsResponseCard,
  ApiStudyResultsResponseCardAnswer,
  ApiStudyResultsVideoResponsesData,
  StudyResultsVideoResponsesData,
} from './model'

export function createIdToEmptyStudyResultsCard(
  responses: ApiStudyResultsResponse[],
): Record<string, EmptyStudyResultsCard> {
  const cardIdToCard: Record<string, EmptyStudyResultsCard> = {}

  responses.forEach(response => {
    response.cards.forEach(card => {
      const resultCard = (cardIdToCard[card.id] ??= createEmptyResultCard(card))
      card.answers.forEach(answer => {
        const resultAnswer = (resultCard.answerIdToAnswer[answer.id] ??=
          createEmptyResultCardAnswer(answer))
        resultAnswer.responseIds.add(response.id)
        resultAnswer.responseIdToResponse[response.id] = createResponse(response.id, answer)
      })
    })
  })

  return cardIdToCard
}

function createResponse(
  id: string,
  apiCardAnswer: ApiStudyResultsResponseCardAnswer,
): StudyResultsAnswerResponse {
  if (apiCardAnswer.text)
    return { id, text: apiCardAnswer.text, sentiment: apiCardAnswer.sentiment }
  if (apiCardAnswer.order) return { id, order: apiCardAnswer.order }
  if (apiCardAnswer.video_id)
    return {
      id,
      transcript: apiCardAnswer.transcript,
      videoId: apiCardAnswer.video_id,
    }
  return undefined
}

function createEmptyResultCard(apiCard: ApiStudyResultsResponseCard): EmptyStudyResultsCard {
  return Object.freeze({
    id: apiCard.id,
    answerIdToAnswer: {},
  })
}

function createEmptyResultCardAnswer(
  apiCardAnswer: ApiStudyResultsResponseCardAnswer,
): StudyResultsAnswer {
  return Object.freeze({
    id: apiCardAnswer.id,
    type: createStudyAnswerResponsesToAnswerType(apiCardAnswer),
    responseIds: new Set<string>(),
    responseIdToResponse: {},
  })
}

function createStudyAnswerResponsesToAnswerType(
  apiCardAnswer: ApiStudyResultsResponseCardAnswer,
): StudyResultsAnswerType {
  if ('text' in apiCardAnswer) return 'text'
  if ('order' in apiCardAnswer) return 'ordered'
  return 'choice'
}

export function createRespondent(response: ApiStudyResultsResponse): StudyResultsRespondent {
  return Object.freeze({
    id: response.id,
    waveSurveyId: response.wave_id,
    nameToSegmentation: Object.freeze(response.segmentations),
    outcome: response.outcome,
  })
}

export function createVideoResponse(
  response: ApiStudyResultsVideoResponsesData,
): StudyResultsVideoResponsesData {
  return Object.freeze(
    Object.fromEntries(
      Object.entries(response.video_content).map(([videoId, entry]) => {
        const isAvailable = entry.state === 'available'
        return [
          videoId,
          {
            isAvailable,
            ...(isAvailable ? { videoUrl: entry.access_url, downloadUrl: entry.download_url } : {}),
          },
        ]
      }),
    ),
  )
}
