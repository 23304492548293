import { defineStore } from 'pinia'

import { getErrorCode, getErrorMessage } from '@attest/fetch'

import type { AppError } from './model'

export const APP_ERROR_NAMESPACE = 'appError'

export function createAppErrorState(override: AppError[] = []): { errors: AppError[] } {
  return {
    errors: override,
    ...override,
  }
}

export const useAppErrorStore = defineStore(APP_ERROR_NAMESPACE, {
  state: createAppErrorState,
  actions: {
    set(error: Partial<AppError>, options?: { namespace: string }) {
      const { status = 500, message, stack } = error
      this.errors.push({
        status,
        message: message ?? createErrorMessage(error, options),
        stack,
      })
    },

    shift() {
      this.errors.shift()
    },
  },

  getters: {
    hasError(): boolean {
      return this.errors.length > 0
    },
  },
})

function createErrorMessage(error: unknown, options?: { namespace: string }): string {
  const code = getErrorCode(error)

  if (options?.namespace) {
    getErrorMessage(`error.message.${options.namespace}.${code}` as any, {
      code,
      namespace: options.namespace,
    })
  }

  return getErrorMessage(`error.message.${code}` as any, { code })
}
