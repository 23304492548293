import type { PermissionRole } from './permission-role'
import type { PermissionType } from './permission-type'

export function hasRequiredPermission(
  requiredPermissions: PermissionType[],
  permissions: PermissionType[],
): boolean {
  return requiredPermissions.every(requiredPermission => permissions.includes(requiredPermission))
}

export function hasOneOfRequiredPermission(
  requiredPermissions: PermissionType[],
  permissions: PermissionType[],
): boolean {
  return requiredPermissions.some(requiredPermission => permissions.includes(requiredPermission))
}

export const getPermission = createGetPermissionFor<PermissionType, { role: PermissionRole[] }>({
  CAN_UPDATE_ORGANISATION: ({ role }) => role.some(userRole => ['GLOBAL_ADMIN'].includes(userRole)),
  CAN_MODIFY_USER_ROLES: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  CAN_REACTIVATE_MAKERS: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  CAN_CANCEL_ORGANISATION_INVITATION: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  REJECT_TAKERS: ({ role }) => role.some(userRole => ['GLOBAL_ADMIN'].includes(userRole)),
  CREATE_POT: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  EDIT_POT: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  DELETE_POT: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  TRANSFER_CREDIT: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN', 'ORGANISATION_ADMIN'].includes(userRole)),
  REMOVE_QUOTAS: ({ role }) => role.some(userRole => ['GLOBAL_ADMIN'].includes(userRole)),
  VIEW_MEMBERS: ({ role }) => role.every(userRole => ['MEMBER'].includes(userRole)),
  IMPERSONATE: ({ role }) => role.some(userRole => ['IMPERSONATOR'].includes(userRole)),
  CAN_ACCESS_CPI: ({ role }) => role.some(userRole => ['ATTEST_SURVEY_MANAGER'].includes(userRole)),
  GLOBAL_ADMIN_ACTIONS: ({ role }) => role.some(userRole => ['GLOBAL_ADMIN'].includes(userRole)),
  OVERRIDE_AVAILABLE_SAMPLE: ({ role }) =>
    role.some(userRole => ['GLOBAL_ADMIN'].includes(userRole)),
})

type Predicate<Payload> = (payload: Payload) => boolean
type PermissionPredicates<TPermissionType extends string, Payload> = {
  [permissionType in TPermissionType]: Predicate<Payload>
}
function createGetPermissionFor<TPermissionType extends string, Payload>(
  predicates: PermissionPredicates<TPermissionType, Payload>,
): ((payload: Payload) => TPermissionType[]) & PermissionPredicates<TPermissionType, Payload> {
  const getPermissionForType = (payload: Payload): TPermissionType[] => {
    return (Object.entries(predicates) as [TPermissionType, Predicate<Payload>][])
      .filter(([_permissionType, predicate]) => predicate(payload))
      .map(([type]) => type)
  }

  Object.assign(getPermissionForType, predicates)
  return getPermissionForType as any
}
