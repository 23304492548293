import axios, { type AxiosResponse } from 'axios'

import { service } from '@attest/_api'

import type { Tag, TagColor } from '../model'

import type { ApiTag, AttachTagRequestData, DetachTagRequestData } from './model'
import { transformApiTagToTag, transformTagToApiTag } from './transformer'
import { REQUEST_URL } from './url'

export async function createTag({
  name,
  color,
}: {
  name: string
  color: TagColor
}): Promise<Tag | Error> {
  try {
    const response = await axios.post<ApiTag>(REQUEST_URL.TAG(), {
      name,
      colour: color,
    })
    return transformApiTagToTag(response.data)
  } catch (error) {
    return error as Error
  }
}

export async function updateTag(tag: Tag): Promise<Tag | Error> {
  try {
    const response = await axios.put<ApiTag>(REQUEST_URL.TAG(tag.id), transformTagToApiTag(tag))
    return transformApiTagToTag(response.data)
  } catch (error) {
    return error as Error
  }
}

export async function deleteTag(id: string): Promise<void | Error> {
  try {
    await axios.delete(REQUEST_URL.TAG(id))
  } catch (error) {
    return error as Error
  }
}

export async function listTags(): Promise<Record<string, Tag> | Error> {
  try {
    const response = await axios.get<ApiTag[]>(REQUEST_URL.TAGS)
    return Object.fromEntries(
      response.data.map(apiTag => [apiTag.id, transformApiTagToTag(apiTag)]),
    )
  } catch (error) {
    return error as Error
  }
}

export async function attachTag(data: AttachTagRequestData): Promise<void | Error> {
  try {
    await axios.post(REQUEST_URL.TAG_ATTACHMENT(data.tagId), { guids: data.guids })
  } catch (error) {
    return error as Error
  }
}

export async function detachTag(data: DetachTagRequestData): Promise<void | Error> {
  try {
    await axios.delete(REQUEST_URL.TAG_ATTACHMENT(data.tagId), { data: { guids: data.guids } })
  } catch (error) {
    return error as Error
  }
}

export async function setTagIdsOfSurvey({
  guid,
  tagIds,
}: SetTagIdsOfSurveyData): Promise<AxiosResponse<any>> {
  const requestUrl = REQUEST_URL.TAGS_OF_SURVEY(guid)

  return service().put(requestUrl, { tag_ids: tagIds })
}

type SetTagIdsOfSurveyData = {
  guid: string
  tagIds: string[]
}
