import type { AnswerType, Card, Header, OptionAnswer } from '@attest/editor-card'

export function localiseAnswers({ card, audienceId }: { card: Card; audienceId: string }): {
  localisedCard: Card
  hiddenAnswers: OptionAnswer<AnswerType>[]
} {
  const answersToInclude = card.question.options.answers
    .filter(answer => !answer.omittedFromAudiences.has(audienceId))
    .map(answer => ({ ...answer, media: answer.mediaOverrides?.[audienceId] ?? answer.media }))
  const hiddenAnswers = card.question.options.answers.filter(answer =>
    answer.omittedFromAudiences.has(audienceId),
  )

  return {
    localisedCard: {
      ...card,
      question: {
        ...card.question,
        options: {
          ...card.question.options,
          answers: answersToInclude,
        },
      },
    },
    hiddenAnswers,
  }
}

export function localiseSubjects({
  card,
  audienceId,
  maskingSource,
}: {
  card: Card
  audienceId: string
  maskingSource: Card | undefined
}): { localisedCard: Card; hiddenSubjects?: Header[] } {
  if (card.type !== 'question' || card.question.type !== 'grid') {
    return { localisedCard: card }
  }
  const subjectShouldBeHidden = (header: Header, index: number): boolean => {
    const maskingSourceAnswer = maskingSource?.question.options.answers[index]
    return (
      maskingSourceAnswer?.omittedFromAudiences.has(audienceId) ||
      header.omittedFromAudiences.has(audienceId)
    )
  }
  const hiddenSubjects = card.question.subjects.headers.filter(subjectShouldBeHidden)

  return {
    localisedCard: {
      ...card,
      question: {
        ...card.question,
        subjects: {
          ...card.question.subjects,
          headers: card.question.subjects.headers.filter(
            (header, index) => !subjectShouldBeHidden(header, index),
          ),
        },
      },
    },
    hiddenSubjects,
  }
}
