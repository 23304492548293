import type { Range } from '@attest/util'

export type SelectedFilters = {
  answers: SelectedCardAnswerDetails
  demographics: DemographicFilters
  segment?: string
}

export type SelectedCardAnswerDetails = {} & Record<string, SelectedAnswerDetails[]>

export type SelectedAnswerDetails = { answerId?: string; userId?: string }

export function isNewAnswerFilter(
  cardId: string,
  answerData: SelectedAnswerDetails[],
  selectedAnswers: SelectedCardAnswerDetails,
): boolean {
  if (selectedAnswers[cardId]) {
    return answerData.some(answer => {
      const answerFilterExists = selectedAnswers[cardId].find(selectedAnswer => {
        if (selectedAnswer.userId && selectedAnswer.answerId) {
          return (
            selectedAnswer.userId === answer.userId && selectedAnswer.answerId === answer.answerId
          )
        }
        if (answer.userId) {
          return selectedAnswer.userId === answer.userId
        }
        if (answer.answerId) {
          return selectedAnswer.answerId === answer.answerId
        }
        return false
      })
      return !answerFilterExists
    })
  }
  return true
}

export function isNewDemographicFilter(
  currentFilters: DemographicFilters,
  payload: { name: string; value: Range | string },
): boolean {
  const { name, value } = payload

  const filter = currentFilters[name]

  if (!filter) return true

  if (isRangeFilter(value) && isRangeFilters(filter)) {
    return !filter.some(option => option.min === value.min && option.max === value.max)
  }

  if (typeof value === 'string' && isStringFilter(filter)) {
    return !filter?.includes(value)
  }
  return false
}

export type DemographicFilters = Record<string, Range[] | string[]>

export function isStringFilter(entries: Range[] | string[]): entries is string[] {
  return entries.length > 0 && typeof entries[0] === 'string'
}

export function isRangeFilters(entries: Range[] | string[]): entries is Range[] {
  return entries.length > 0 && isRangeFilter(entries[0])
}

export function isRangeFilter(entry: Range | string): entry is Range {
  const rangeEntry = entry as Range

  return typeof entry === 'object' && rangeEntry.min !== undefined && rangeEntry.max !== undefined
}

export function getValueForRangeFilter(
  defaultValue: Range,
  index: number,
  entry: Range[] | string[] | string | undefined,
): Range {
  if (!entry || typeof entry === 'string' || !entry[index]) return defaultValue

  const entryOption = entry[index]

  return isRangeFilter(entryOption) ? entryOption : defaultValue
}
