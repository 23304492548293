<script lang="tsx">
import { type FunctionalComponent, type IntrinsicElementAttributes, Transition } from 'vue'

import errorSVG from '@attest/_assets/icons/error.svg'

import { SvgIcon } from '../SvgIcon'

import type { FormFieldProps } from './interface'

const getLabelInputClass = (
  baseFieldClass: string,
  inputClass?: string | Record<string, boolean>,
) => {
  const labelInputClass = {
    [baseFieldClass]: true,
  }

  if (inputClass === undefined) {
    return labelInputClass
  }

  if (typeof inputClass === 'string') {
    labelInputClass[inputClass] = true
    return labelInputClass
  }

  return {
    ...labelInputClass,
    ...inputClass,
  }
}

const FormField: FunctionalComponent<FormFieldProps & IntrinsicElementAttributes['div']> = (
  props,
  { slots },
) => {
  const {
    right = false,
    sizeVariant = 'default',
    styleVariant = 'default',
    inputClass,
    showPlaceholder = true,
    showErrorText = true,
    hasError = false,
    validationError = '',
    placeholder = null,
    placeholderClass = '',
    isFocussed = false,
    alignTop = false,
    disabled = false,
    readonly = false,
    isEmpty = false,
    errorClass = '',
    defaultSlotClass,
    hideErrorIcon = false,
    removeLabel = false,
    screenReaderText = undefined,
    ...attrs
  } = props

  const baseClass = 'c-form-field'
  const containerClass = {
    [baseClass]: true,
    [`${baseClass}--error`]: hasError,
    [errorClass]: hasError,
    [`${baseClass}--readonly`]: readonly,
    [`${baseClass}--disabled`]: disabled,
    [`${baseClass}--right`]: right,
    [`${baseClass}--size-${sizeVariant}`]: sizeVariant,
    [`${baseClass}--style-${styleVariant}`]: styleVariant,
    [`${baseClass}--focus`]: isFocussed,
    [`${baseClass}--top`]: alignTop,
    [`${baseClass}--empty`]: isEmpty,
  }

  const baseFieldClass = `${baseClass}__field`

  const labelInputClass = getLabelInputClass(baseFieldClass, inputClass)

  const defaultSlotWrapClass = {
    ...defaultSlotClass,
    [`${baseClass}__field-slot-wrap`]: true,
  }

  const baseInputContainerClass = `${baseFieldClass}-container`
  const inputContainerClass = {
    [baseInputContainerClass]: true,
  }

  const basePrefixedContentClass = `${baseFieldClass}-prefix`
  const basePostfixedContentClass = `${baseFieldClass}-postfix`
  const postfixedContentClass = {
    [`${basePostfixedContentClass}`]: true,
  }

  return (
    <div class={containerClass} data-disabled={`${disabled}`} {...attrs}>
      {(() => {
        const input = (
          <div class={['o-outline', labelInputClass]}>
            {screenReaderText && <span class="u-screen-reader-only">{screenReaderText}</span>}
            {slots.pre?.()}
            <div class={inputContainerClass}>
              <div class={defaultSlotWrapClass}>
                {slots.prefix && <div class={basePrefixedContentClass}>{slots.prefix?.()}</div>}
                {slots.default?.()}
              </div>
            </div>
            <div class={postfixedContentClass}>
              <Transition name="t-fade">
                {slots.errorPrefix?.() && (
                  <div class={`${baseClass}__error-prefix`} data-name="FormFieldErrorPrefix">
                    {slots.errorPrefix?.()}
                  </div>
                )}
              </Transition>
              {slots.postfix && (
                <div class={`${basePostfixedContentClass}-content`}>{slots.postfix?.()}</div>
              )}
            </div>
            {slots.post && <div>{slots.post?.()}</div>}
          </div>
        )

        const label = slots.label?.() ?? props.label
        return label ? (
          <label class={`${baseClass}__label`}>
            <div class={`${baseClass}__label-content`}>{label}</div>
            {input}
          </label>
        ) : (
          input
        )
      })()}
      <Transition name={`${baseClass}__error`}>
        {validationError && showErrorText && (
          <div
            data-name="TextInputError"
            class={`${baseClass}__error`}
            id="TextInputError"
            aria-live="assertive"
            role="status"
          >
            {hasError && !hideErrorIcon && (
              <SvgIcon
                class={`${baseFieldClass}-error-icon`}
                svg={errorSVG}
                size="s"
                color="red"
                data-name="FormFieldErrorIcon"
              />
            )}
            <span>{validationError}</span>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default FormField
</script>

<style lang="postcss">
:root {
  --error-max-height: var(--attest-spacing-16);
  --transition-delay: var(--attest-timing-default);

  --z-index-input-field: 1;
  --z-index-placeholder-icon: 2;
}

.c-form-field {
  &,
  &__label {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
  }

  &__label-content {
    width: 100%;
    margin-bottom: var(--attest-spacing-1);
    font: var(--attest-font-body-2);
  }

  &--has-placeholder&--size-s,
  &--has-placeholder&--size-default {
    ^&__field-slot-wrap {
      > * {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        box-sizing: border-box;
        padding-top: var(--attest-spacing-4);
        padding-bottom: calc(var(--attest-spacing-2) - var(--attest-border-width-s));
      }
    }
  }

  &--has-placeholder&--size-s:not(&--show-placeholder),
  &--has-placeholder&--size-default:not(&--show-placeholder) {
    ^&__field-slot-wrap {
      > * {
        padding-top: var(--attest-spacing-2);
        padding-bottom: var(--attest-spacing-2);
      }
    }
  }

  &__field {
    position: relative;
    display: inline-flex;
    height: 38px;
    box-sizing: border-box;
    flex-direction: row;
    border: var(--attest-border-width-s) solid var(--attest-color-border-default);
    border-radius: var(--attest-border-radius);
    background: var(--attest-color-surface-default);
    transition: border-color var(--attest-timing-fast) ease;

    &:active,
    &:hover {
      border-color: var(--attest-color-border-dark);
    }

    &:focus-within {
      border-color: var(--attest-color-border-dark);
    }

    ^&--error & {
      border-color: var(--attest-color-interactive-negative-default);
    }
  }

  &__field-postfix {
    padding-right: var(--attest-spacing-2);

    &:empty {
      position: absolute;
      top: 0;
      right: 0;
    }

    &:not(:empty)::before {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: calc(var(--attest-spacing-4) * -1);
      width: var(--attest-spacing-4);
      content: '';
      pointer-events: none;
    }
  }

  &__field-postfix-content {
    padding-left: var(--attest-spacing-2);
  }

  &__field-prefix,
  &__field-postfix {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__field-prefix {
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
  }

  &__field-error-icon {
    right: var(--attest-spacing-2);
    flex: 0 0 auto;
    margin-top: 2px;
    margin-right: var(--attest-spacing-half);
    margin-left: 1px;
    pointer-events: none;
  }

  &__field-container {
    position: relative;
    z-index: var(--z-index-input-field);
    width: 70%;
    height: auto;
    flex: 1 1 auto;
    align-items: center;

    &--top {
      & ~ ^^&__field-postfix {
        padding-left: var(--attest-spacing-2);

        &::before {
          display: none;
        }
      }
    }
  }

  &__error {
    display: flex;
    overflow: hidden;
    max-height: var(--error-max-height);
    padding-top: var(--attest-spacing-half);
    margin-top: var(--attest-spacing-1);
    color: var(--attest-color-interactive-negative-default);
    font: var(--attest-font-body-2);
    text-align: left;
    transform: translateY(0);
    transition:
      max-height var(--transition-delay) ease-in-out var(--transition-delay),
      opacity var(--transition-delay) ease var(--transition-delay),
      transform var(--transition-delay) ease var(--transition-delay);
    word-break: break-word;

    &-enter-from,
    &-leave-to {
      max-height: 0;
      opacity: 0;
      transform: translateY(calc(var(--error-height) * -1));
    }
  }

  &__error-prefix {
    position: absolute;
    z-index: 2;
    right: var(--attest-spacing-2);
    bottom: var(--attest-spacing-1);
    display: block;

    .c-tag {
      padding: 0 var(--attest-spacing-1);
      font: var(--attest-font-body-3);
    }
  }

  &--readonly {
    ^&__field {
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;

      &,
      &:hover,
      &:active {
        border-color: var(--attest-color-border-subdued);
        background-color: var(--attest-color-surface-default);
      }
    }
  }

  &--disabled {
    ^&__field {
      cursor: not-allowed;
      user-select: none;

      &,
      &:hover,
      &:active {
        border-color: var(--attest-color-border-subdued);
        background-color: var(--attest-color-interactive-disabled);
      }
    }

    ^&__field-container {
      pointer-events: none;
    }
  }

  &--focus:not(&--readonly) {
    ^&__field {
      border-color: var(--attest-color-border-hover);
    }
  }

  &--empty&:not(&--error) {
    ^&__field-postfix:empty {
      display: none;
    }
  }

  &--size-xs {
    ^&__field-prefix {
      margin-right: var(--attest-spacing-1);
    }
  }

  &--size-s,
  &--size-default,
  &--size-auto,
  &--size-l {
    ^&__field-prefix {
      > *:not(:empty) {
        margin-left: var(--attest-spacing-1);
      }
    }
  }

  &--size-default,
  &--size-s {
    ^&__field-error-icon {
      margin-right: var(--attest-spacing-1);
    }

    ^&__field {
      height: 44px;
    }
  }

  &--size-auto {
    ^&__field-error-icon {
      margin-right: var(--attest-spacing-1);
    }

    ^&__field {
      height: auto;
      min-height: 44px;
    }

    ^&__field-postfix {
      padding-top: 0;
    }
  }

  &--size-l {
    ^&__field-error-icon {
      margin-right: var(--attest-spacing-2);
    }

    ^&__field {
      min-height: var(--attest-spacing-14);
      padding-top: var(--attest-spacing-2);
    }
  }

  &--top {
    ^&__field-prefix,
    ^&__field-postfix {
      align-items: center;
    }

    ^&__field-postfix {
      padding-left: var(--attest-spacing-2);

      &::before {
        display: none;
      }
    }
  }
}
</style>
