import { useStudyInsightsStore } from '@attest/results-core'
import { useSavedSettingsStore } from '@attest/results-saved-settings'
import type { StudyQuestionCard, StudyQuestionType } from '@attest/study'
import { intersection } from '@attest/util'

import { type ColumnDefinitionValue, isStackedColumnDefinitionValue } from './column-definition'
import type { Row, RowChild } from './row'

export const isCrosstabbableCard = (args: { cardId: string }): boolean => {
  const supportedTypes: StudyQuestionType[] = ['choice', 'grid', 'ranked', 'nps']
  return supportedTypes.includes(
    (useStudyInsightsStore().idToCard[args.cardId] as StudyQuestionCard)?.question?.type,
  )
}

export function cellValues(args: {
  baseRespondentIds: Set<string>
  totalRespondentIds: Set<string>
  forwardedTotalRespondentIds?: Set<string>
  rows?: Row[]
  customRowType?: 'nps'
}): {
  percentage: string
  forwardPercentage?: string
  value: string
} {
  const {
    baseRespondentIds,
    totalRespondentIds,
    forwardedTotalRespondentIds,
    rows,
    customRowType,
  } = args

  if (customRowType === 'nps') {
    return {
      percentage: getNPSCellContent({ rows: rows ?? [], columnRespondentIds: totalRespondentIds }),
      forwardPercentage: '',
      value: getNPSCellContent({ rows: rows ?? [], columnRespondentIds: totalRespondentIds }),
    }
  }

  const cellCount = intersection([baseRespondentIds, totalRespondentIds]).size
  const totalCount = totalRespondentIds.size
  const forwardedTotalCount = forwardedTotalRespondentIds?.size

  return {
    percentage: `${totalCount > 0 ? ((cellCount / totalCount) * 100).toFixed(1) : '0'}%`,
    forwardPercentage: forwardedTotalCount
      ? `${totalCount > 0 ? ((cellCount / forwardedTotalCount) * 100).toFixed(1) : '0'}%`
      : undefined,
    value: `${cellCount}`,
  }
}

export function getCellContent(args: {
  baseRespondentIds: Set<string>
  totalRespondentIds: Set<string>
  forwardedTotalRespondentIds?: Set<string>
}): string {
  const show = useSavedSettingsStore().studySettings.crosstabs.show
  const { percentage, forwardPercentage, value } = cellValues(args)

  const percentageToDisplay =
    forwardPercentage && show.forward_percentage ? forwardPercentage : percentage

  return show.percentages && show.values
    ? `${percentageToDisplay} (${value})`
    : show.percentages
      ? percentageToDisplay
      : show.values
        ? value
        : ''
}

export function getNPSCellContent(args: { rows: Row[]; columnRespondentIds: Set<string> }): string {
  const npsGroups = args.rows[0].children
    .slice(1)
    .map(group => intersection([group.respondentIds, args.columnRespondentIds]))
  const total = npsGroups.reduce(
    (prev, curr) => prev + intersection([curr, args.columnRespondentIds]).size,
    0,
  )
  if (total === 0) {
    return `0`
  }
  const [promoters, _neutral, detractors] = npsGroups
  const npsScore = (promoters.size / total - detractors.size / total) * 100
  return npsScore.toPrecision(2)
}

export function getSigTestKey({
  row,
  rowChild,
  column,
}: {
  row: Row
  rowChild: RowChild
  column: ColumnDefinitionValue
}): string {
  return `${row.id}${rowChild.id}${getColumnCacheKey(column)}`
}

export function getColumnCacheKey(column: ColumnDefinitionValue | undefined): string {
  if (column === undefined) {
    return ''
  }

  const base = `${column.type}${column.variable}${column.value}`
  return !isStackedColumnDefinitionValue(column)
    ? base
    : `${base}${getColumnCacheKey(column.stackedColumnDefinitionValue)}`
}
