<script lang="tsx">
import { defineComponent, type PropType } from 'vue'

import { create, type ElementAttributes } from '@attest/vue-tsx'

import type { RoleButtonProps, RoleButtonTag } from './interface'

const RoleButton = defineComponent({
  props: {
    elementTag: { type: String as PropType<RoleButtonTag>, default: 'button' },
  },

  inheritAttrs: false,

  render() {
    const Tag = getTag(this.elementTag)

    return (
      <Tag
        {...getA11yAttrs(this.elementTag)}
        {...getAttrs(this.$attrs)}
        type={this.$attrs.type ?? 'button'}
      >
        {this.$slots.default?.()}
      </Tag>
    )
  },
})

function getTag(elementTag?: RoleButtonTag): any {
  return elementTag || 'button'
}

function getA11yAttrs(elementTag?: RoleButtonTag): Record<string, string | number> {
  if (!elementTag || elementTag === 'button') return {}
  const attrs = { role: 'button' }
  if (elementTag === 'a') return attrs
  return Object.assign(attrs, { tabindex: 0 })
}

function getAttrs(attrs: Record<string, any>): Record<string, any> {
  const newAttrs = { ...attrs }
  if (!newAttrs.disabled) delete newAttrs.disabled
  return newAttrs
}

export default create<RoleButtonProps & ElementAttributes<'a'> & ElementAttributes<'button'>>(
  RoleButton,
)
</script>
