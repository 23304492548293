import { isMergedStudyQuestionStructure } from '../../question'

export type StudyMessageStructure = {
  waveId: string
  nextGuid: string | null
  text: string
  publishedTimestamp?: number
  imageUrl?: string
}

export type MergedStudyMessageStructure = Omit<StudyMessageStructure, 'waveId'>

export function assertIsMergedStudyMessageStructure(
  structure: unknown,
): asserts structure is MergedStudyMessageStructure {
  if (isMergedStudyMessageStructure(structure)) return
  throw new TypeError('Structure is not a message structure')
}

export function isMergedStudyMessageStructure(
  structure: unknown,
): structure is MergedStudyMessageStructure {
  if (typeof structure !== 'object' || structure === null) return false
  return 'nextGuid' in structure && !isMergedStudyQuestionStructure(structure)
}
