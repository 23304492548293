import { nanoid } from 'nanoid'

import { COPY } from '@attest/_copy'

import { STATIC_ANSWER_ID } from '../answer'

import { type ImageOption, STATIC_OPTION_ID, type TextOption } from './model'

export function createTextOption(override: Partial<TextOption> = {}): TextOption {
  return Object.freeze({
    id: nanoid(),
    text: '',
    isQualifying: false,
    isRandomized: false,
    answerIds: [],
    ...override,
  })
}

export function createImageOption(override: Partial<ImageOption> = {}): ImageOption {
  return Object.freeze({
    id: nanoid(),
    text: '',
    imageUrl: '',
    filename: '',
    isQualifying: false,
    isRandomized: false,
    answerIds: [],
    ...override,
  })
}

export function createOtherOption(): TextOption {
  return Object.freeze({
    ...createTextOption(),
    id: STATIC_OPTION_ID.OTHER,
    text: COPY.OTHER,
    answerIds: [STATIC_ANSWER_ID.OTHER],
  })
}

export function createNAOption(): TextOption {
  return Object.freeze({
    ...createTextOption(),
    id: STATIC_OPTION_ID.NA,
    text: COPY.NA,
    answerIds: [STATIC_ANSWER_ID.NA],
  })
}

export function createNoneOption(): TextOption {
  return Object.freeze({
    ...createTextOption(),
    id: STATIC_OPTION_ID.NONE,
    text: COPY.NONE,
    answerIds: [STATIC_ANSWER_ID.NONE],
  })
}
