import type { ImageMedia, Media } from '@attest/_lib/src/media/model/media'

export type CardGuid = string
export type CardType = 'question' | 'text' | 'group'
export type CardTextType = 'text'
export type CardQuestionType =
  | 'single_choice'
  | 'multiple_choice'
  | 'ranked'
  | 'nps'
  | 'open_text'
  | 'grid'
  | 'video'

export type Card = {
  guid: CardGuid
  type: CardType | null
  title: string
  position: CardPosition
  media: Media | null
  mediaOverrides: { [audienceId: string]: Media }
  question: CardQuestion
  text: CardText
  nextGuid: CardGuid | null
  isRandomized: boolean
  group: CardGroup
  publishedTimestamp?: number
  translations?: Record<string, { title: string }>
  shouldUseNewGridTransformer?: boolean
  omittedFromAudiences: Set<string>
}

export type CardPosition = {
  x: number
  y: number
}

export type CardGroup = {
  cards: Card[]
}

export type CardQuestion = {
  questionId?: number
  type: CardQuestionType | null
  subjects: CardQuestionSubjects
  options: CardQuestionOptions
  hasOther: boolean
  hasNone: boolean
  hasNa: boolean
  selectionLimit: number
}

export type CardVideoQuestion<T extends CardQuestionType = CardQuestionType> = {
  questionId?: number
  type: T | null
  text: string
}

export type CardText = {
  type: CardTextType
  text: string
  translations?: CardTextTranslations
}

type CardTextTranslations = Record<string, { text: string }>

export type CardQuestionOptionsSelectionType = 'single' | 'multiple'

export type CardQuestionSubjects = {
  headers: Header[]
  isRandomized: boolean
}

export type CardQuestionOptions = {
  answers: OptionAnswer[]
  isRandomized: boolean
  isImage: boolean
  maxSelections: number | null
  selectionType: CardQuestionOptionsSelectionType
  responseType: AnswerResponseType
  maskingQuestion: {
    isToggled: boolean
    senderGuid: CardGuid
  } | null
  isQualifying?: boolean
  imageDisplaySize?: AnswerImageDisplaySize
  minLength?: number
  maxLength?: number
}

export type AnswerType = 'image' | 'text'

export type OptionAnswer<T extends AnswerType = AnswerType> = {
  nextGuid: CardGuid | null
} & Answer<T>

export type Answer<T extends AnswerType = AnswerType> = {
  id: string
  type: T
  text: string
  isQualifying: boolean
  isPinned: boolean
  mask: { answerId: string } | null
  media: ImageMedia | null
  mediaOverrides: { [audienceId: string]: ImageMedia }
  quotaId: string | null
  isDraft: boolean
  translations?: Record<string, { text: string }>
  omittedFromAudiences: Set<string>
}

export type Header = {
  media: null
  quotaId: null
} & Answer<'text'>

export type AnswerResponseType = 'short' | 'medium' | 'long'
export type AnswerImageDisplaySize = 'compact' | 'large' | null

export type CombinedCardTypeQuestion = {} & CombinedCardType<'question'>
export type CombinedCardTypeText = {} & CombinedCardType<'text'>
export type CombinedCardTypeGroup = {} & CombinedCardType<'group'>
export type CombinedCardTypeEmpty = {} & CombinedCardType<null>

export type ExclusiveCombinedSubType<T extends CardType | null> = T extends null
  ? null
  : T extends 'question'
    ? CardQuestionType
    : CardTextType

export type CombinedCardType<
  C extends CardType | null = CardType | null,
  T extends ExclusiveCombinedSubType<C> = ExclusiveCombinedSubType<C>,
> = {
  type: C
  subType: T
}

export function isCardChoiceQuestion(card: Card): boolean {
  return (
    isQuestionCard(card) &&
    (card.question.type === 'single_choice' || card.question.type === 'multiple_choice')
  )
}

export function isCardMultipleChoiceQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'multiple_choice'
}
export function isCardSingleChoiceQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'single_choice'
}

export function isRankedQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'ranked'
}

export function isCardNpsQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'nps'
}

export function isCardGridQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'grid'
}

export function isCardOpenTextQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'open_text'
}

export function isCardVideoQuestion(card: Card): boolean {
  return isQuestionCard(card) && card.question.type === 'video'
}

export function isQuestionCard(card: Card): boolean {
  return card.type === 'question'
}

export function isQuestionCardOrUninitialized(card: Card): boolean {
  return card.type === 'question' || card.type === null
}

export function isQualifying(card: Card): boolean {
  return (
    isCardChoiceQuestion(card) &&
    card.question.options.answers.some(answer => !!answer.isQualifying)
  )
}

export function isTextCard(card: Card): boolean {
  return card.type === 'text'
}

export function isGroupCard(card: Card): boolean {
  return card.type === 'group'
}

export function cardRoutesToAnotherCard(card: Card): boolean {
  return !!card.nextGuid || answersRouteToAnotherCard(card)
}

export function answersRouteToAnotherCard(card: Card): boolean {
  return isQuestionCard(card) && card.question.options.answers.some(answer => !!answer.nextGuid)
}

export function isCombinedCardTypeQuestion(
  combinedCardType: CombinedCardType,
): combinedCardType is CombinedCardTypeQuestion {
  return combinedCardType.type === 'question'
}

export function isCombinedCardTypeText(
  combinedCardType: CombinedCardType,
): combinedCardType is CombinedCardTypeText {
  return combinedCardType.type === 'text'
}

export function isCombinedCardTypeGroup(
  combinedCardType: CombinedCardType,
): combinedCardType is CombinedCardTypeGroup {
  return combinedCardType.type === 'group'
}

export function isCombinedCardTypeEmpty(
  combinedCardType: CombinedCardType,
): combinedCardType is CombinedCardTypeEmpty {
  return combinedCardType.type === null
}

export function isRoutableCard(card: Card): boolean {
  if (!isQuestionCard(card)) return false

  return isRoutableQuestionCard(card)
}

export function isRoutableQuestionCard(card: Card): boolean {
  if (card.type !== 'question') {
    return false
  }
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
      return true
    case null:
    case 'ranked':
    case 'grid':
    case 'nps':
    case 'open_text':
      return false
    default:
      return false
  }
}

export type AnswerQuotaId = string

export type AnswerQuota = {
  id: AnswerQuotaId
  minTarget: number
}
