import type { CardResponseFilter } from './store'

export function createCardResponseFilter(
  override: Partial<CardResponseFilter> = {},
): CardResponseFilter {
  return {
    answerIds: [],
    responseIds: [],
    answerIdsToResponseIds: {},
    ...override,
  }
}
