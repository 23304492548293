import type { StudyMessageCard, StudyQuestion, StudyQuestionType } from '../card'

export type StudyCardId = string
export type StudyCardType = StudyQuestionCardType | StudyMessageCardType
export type StudyCard = StudyQuestionCard | StudyMessageCard

export type StudyCardNode<T extends StudyCardType = StudyCardType> = {
  type: T
  id: StudyCardId
}

export type StudyCardIdToStudyCard = Record<StudyCardId, StudyCard>

export type StudyQuestionCardType = 'question'
export type StudyMessageCardType = 'message'

type GridQuestionTemplateAnswers = {
  subjects: { headers: Array<{ type: string; id: string; text: string }> }
  options: { headers: Array<{ type: string; id: string; text: string }> }
}

type QuestionTemplateAnswers = Array<{ id: string; type: string; text: string }>

export type StudyQuestionCard<T extends StudyQuestionType = StudyQuestionType> = {
  type: StudyQuestionCardType
  title: string
  templateAnswers: GridQuestionTemplateAnswers | QuestionTemplateAnswers
  question: StudyQuestion<T>
  publishedTimestamp: number | null
  deletedTimestamp: number | null
  media: StudyQuestionCardMedia | null
} & StudyCardNode

export type StudyQuestionCardMedia = {
  type: 'image' | 'audio' | 'video'
  url: string
}

export function isGridTemplateAnswers(
  templateAnswers: unknown,
): templateAnswers is GridQuestionTemplateAnswers {
  if (typeof templateAnswers !== 'object' || templateAnswers === null) return false
  return 'subjects' in templateAnswers
}

export function isStudyCard(card: unknown): card is StudyCardNode<StudyCardType> {
  if (typeof card !== 'object' || card === null) return false
  return 'id' in card
}

export function isStudyQuestionCard(card: unknown): card is StudyQuestionCard {
  if (!isStudyCard(card)) return false
  return 'type' in card && card.type === 'question'
}

export function isStudyMessageCard(card: unknown): card is StudyMessageCard {
  if (!isStudyCard(card)) return false
  return 'type' in card && card.type === 'message'
}

export function isStudyChoiceQuestionCard(card: StudyCard): card is StudyQuestionCard<'choice'> {
  return isStudyQuestionCard(card) && card.question.type === 'choice'
}

export function isStudyRankedQuestionCard(card: StudyCard): card is StudyQuestionCard<'ranked'> {
  return isStudyQuestionCard(card) && card.question.type === 'ranked'
}

export function isStudyOpenTextQuestionCard(
  card: StudyCard,
): card is StudyQuestionCard<'open-text'> {
  return isStudyQuestionCard(card) && card.question.type === 'open-text'
}

export function isStudyVideoQuestionCard(card: StudyCard): card is StudyQuestionCard<'video'> {
  return isStudyQuestionCard(card) && card.question.type === 'video'
}

export function isStudyNPSQuestionCard(card: StudyCard): card is StudyQuestionCard<'nps'> {
  return isStudyQuestionCard(card) && card.question.type === 'nps'
}

export function isStudyGridQuestionCard(card: StudyCard): card is StudyQuestionCard<'grid'> {
  return isStudyQuestionCard(card) && card.question.type === 'grid'
}

export function isStudyFlatQuestionCard(
  card: StudyCard,
): card is StudyQuestionCard<Exclude<StudyQuestionType, 'grid'>> {
  return isStudyQuestionCard(card) && card.question.type !== 'grid'
}

export function assertIsStudyCard(card: unknown): asserts card is StudyCard {
  if (isStudyCard(card)) return
  throw new TypeError('Card is not study card')
}

export function assertIsStudyQuestionCard(card: unknown): asserts card is StudyQuestionCard {
  if (isStudyQuestionCard(card)) return
  throw new TypeError('Card is not a question card')
}

export function assertIsStudyMessageCard(card: unknown): asserts card is StudyMessageCard {
  if (isStudyMessageCard(card)) return
  throw new TypeError('Card is not a message card')
}

export function assertIsStudyGridQuestionCard(
  card: unknown,
): asserts card is StudyQuestionCard<'grid'> {
  if (isStudyQuestionCard(card) && isStudyGridQuestionCard(card)) return
  throw new TypeError('Card is not a grid question card')
}
export function assertIsStudyNPSQuestionCard(
  card: StudyQuestionCard,
): asserts card is StudyQuestionCard<'nps'> {
  if (isStudyNPSQuestionCard(card)) return
  throw new TypeError('Card is not a nps question card')
}

export function assertIsStudyRankedQuestionCard(
  card: StudyQuestionCard,
): asserts card is StudyQuestionCard<'nps'> {
  if (isStudyRankedQuestionCard(card)) return
  throw new TypeError('Card is not a ranked question card')
}

export function assertIsStudyOpenTextQuestionCard(
  card: StudyQuestionCard,
): asserts card is StudyQuestionCard<'open-text'> {
  if (isStudyOpenTextQuestionCard(card)) return
  throw new TypeError('Card is not an open text question card')
}
