import type { Quota, Segmentations } from '@attest/audience'

export type BaseWaveSurvey = {
  title: string
  name: string
  id: string
  audienceId: string
  country: string
  language: string
  createdTimestamp: number
  publishedTimestamp: number | null
  expiredTimestamp: number | null
  startTimestamp: number | null
  timeZoneId: string | null
  responses: number
  maxResponses: number
  status: WaveSurveyStatus
  segmentations: Segmentations
  quotas: Quota[]
  accessCode?: string
  collectsVideoResponses?: boolean
  numQuestionsLongestRoute: number
  useDraftLanguage?: boolean
}

export type SentWaveSurvey = {
  status: Exclude<WaveSurveyStatus, 'draft'>
  publishedTimestamp: number
  startTimestamp: number
  timeZoneId: string
  responses: number
  expiredTimestamp: number
  lastAnswerTimestamp?: number
} & BaseWaveSurvey

export type DraftWaveSurvey = {
  status: 'draft'
  publishedTimestamp: null
  startTimestamp: number | null
  timeZoneId: string | null
  responses: 0
} & BaseWaveSurvey

export type WaveSurvey = SentWaveSurvey | DraftWaveSurvey
export type WaveSurveyIdToWaveSurvey = Record<string, WaveSurvey>
export type WaveAudienceIdToWaveSurvey = Record<string, WaveSurvey>
export type WaveSurveyStatus = 'pending' | 'live' | 'draft' | 'completed' | 'closed' | 'archived'

export function isDraftWaveSurvey(waveSurvey: WaveSurvey): waveSurvey is DraftWaveSurvey {
  return waveSurvey.status === 'draft'
}

export function isSentWaveSurvey(waveSurvey: WaveSurvey): waveSurvey is SentWaveSurvey {
  return !isDraftWaveSurvey(waveSurvey)
}

export function assertIsSentWaveSurvey(
  waveSurvey: SentWaveSurvey,
): asserts waveSurvey is SentWaveSurvey {
  if (isSentWaveSurvey(waveSurvey)) {
    return
  }
  throw new Error('SentWaveSurvey is not of type SentWaveSurvey')
}

export function assertIsWaveSurvey(waveSurvey: unknown): asserts waveSurvey is WaveSurvey {
  if (isWaveSurvey(waveSurvey)) return
  throw new Error('WaveSurvey is not of type WaveSurvey')
}

export function isWaveSurvey(waveSurvey: unknown): waveSurvey is WaveSurvey {
  if (typeof waveSurvey !== 'object' || waveSurvey === null) return false
  return (
    'id' in waveSurvey &&
    'country' in waveSurvey &&
    'language' in waveSurvey &&
    'maxResponses' in waveSurvey
  )
}
