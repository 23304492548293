<script lang="tsx">
import { defineComponent, type IntrinsicElementAttributes, type PropType } from 'vue'

import researchSVG from '@attest/_assets/illustrations/research.svg'
import { create } from '@attest/vue-tsx'

import { Heading } from './Heading'
import { SvgImage } from './SvgImage'

type ContainerErrorProps = {
  status: number
  message?: string | null
  description?: string | null
  svg?: string | null
}

const ContainerError = defineComponent({
  props: {
    status: { type: Number, required: true },
    message: { type: String as PropType<string | null>, default: null },
    description: { type: String as PropType<string | null>, default: null },
    svg: { type: String as PropType<string | null>, default: null },
  },

  head() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore causes error but valid code
    return { title: `Error: ${this.message || this.status}` }
  },

  mounted() {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      const error = new Error(this.message || `${this.status}`)
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },

  render() {
    return (
      <div class="p-error">
        <div class="p-error__wrapper">
          <SvgImage svg={this.svg ?? researchSVG} />
          <Heading class="p-error__header" type="display" data-name="heading-error">
            {this.message}
          </Heading>
          <Heading class="p-error__subtitle" type="body" elementTag="h2">
            {this.$slots.default ??
              this.description ??
              'The link you followed may be broken, or may not be available.'}
          </Heading>
        </div>
      </div>
    )
  },
})

export default create<ContainerErrorProps & IntrinsicElementAttributes['div']>(ContainerError)
</script>

<style lang="postcss">
.p-error {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--attest-color-background-default);
  background-image: url('@attest/_assets/shapes/error.svg?url');
  background-position-x: left;
  background-position-y: calc(100vh - 220px);
  background-repeat: no-repeat;
  background-size: clamp(1000px, 100%, 2200px);

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--attest-spacing-6);
  }

  @context scoped {
    &__header {
      padding-bottom: var(--attest-spacing-3);
      margin-top: var(--attest-spacing-5);
    }
  }

  &__subtitle {
    max-width: 22rem;
    flex: 1 1 auto;
    margin: 0 0 var(--attest-spacing-2) 0;
    text-align: center;
  }
}
</style>
