import { createEntity, type Entity } from './base'

export type SurveyEntity<D extends SurveyEntityData = SurveyEntityData> = {} & Entity<D>
type SurveyEntityData = {
  id: string
}

export function createSurveyEntity({ id }: SurveyEntityData): SurveyEntity {
  return createEntity({ name: 'survey', version: '1-0-0' }, { id })
}
