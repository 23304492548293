import type { CardGuid } from '@attest/editor-card'
import {
  createRoutingGraph,
  getNodesByServingOrder,
  getPathsFromNodeToNode,
  type RoutingGraph,
} from '@attest/routing-graph'
import { isDefined } from '@attest/util'

import { getMaximumQuestionsInGraph, getNumberOfPathsForAudience } from './calculator'

type RouteCardGuid = string
type RouteCardAnswerId = string

export type RouteCard = {
  guid: RouteCardGuid
  position: { x: number; y: number }
  type: 'question' | 'text' | 'group' | null
  nextGuid: RouteCardGuid | null
  group: {
    cards: RouteCard[]
  }
  omittedFromAudiences: Set<string>
  question: {
    options: {
      maskingQuestion: {
        isToggled: boolean
        senderGuid: string
      } | null
      answers: {
        id: RouteCardAnswerId
        nextGuid: RouteCardGuid | null
        omittedFromAudiences: Set<string>
        mask: { answerId: string } | null
      }[]
    }
  }
}

export type CardLink = {
  fromGuid: RouteCardGuid
  fromAnswerId?: RouteCardAnswerId
  toGuid: RouteCardGuid
}

export type Path<C extends RouteCard = RouteCard> = {
  readonly cards: readonly C[]
  readonly links: readonly CardLink[]
  readonly isCyclic: boolean
}

export const getCardsByServingOrder = <C extends RouteCard>(
  graph: RoutingGraph,
  cards: C[],
): C[] => {
  const guidToCard = Object.fromEntries(cards.map(card => [card.guid, card]))
  return [...getNodesByServingOrder(graph)]
    .filter(guid => isDefined(guidToCard[guid]))
    .map(guid => guidToCard[guid])
}

export function getNumberQuestionsLongestRoute(cards: RouteCard[]): number {
  if (cards.length === 0) {
    return 0
  }
  return getMaximumQuestionsInGraph(createRoutingGraph(cards).graph)
}

export function getNumberOfRoutesForAudience(cards: RouteCard[], audienceId: string): number {
  return getNumberOfPathsForAudience(createRoutingGraph(cards).graph, audienceId)
}

export function mapAllNextGuids<C extends RouteCard>(
  card: C,
  fn: (nextGuid: RouteCardGuid) => RouteCardGuid | null,
): C {
  return {
    ...card,
    nextGuid: card.nextGuid ? fn(card.nextGuid) : null,
    question: {
      ...card.question,
      options: {
        ...card.question.options,
        answers: card.question.options.answers.map(answer => ({
          ...answer,
          nextGuid: answer.nextGuid ? fn(answer.nextGuid) : null,
        })),
      },
    },
  }
}

export function getAllLinksFromCard(
  card: Pick<RouteCard, 'guid' | 'nextGuid' | 'question'>,
): CardLink[] {
  return [
    ...getLinksFromCard(card),
    ...card.question.options.answers.flatMap(({ id, nextGuid }) =>
      nextGuid ? [{ fromGuid: card.guid, fromAnswerId: id, toGuid: nextGuid }] : [],
    ),
  ]
}

export function getLinksFromCard(
  card: Pick<RouteCard, 'guid' | 'nextGuid' | 'question'>,
): CardLink[] {
  if (!card.nextGuid) return []
  return [{ fromGuid: card.guid, toGuid: card.nextGuid }]
}

export function getDefaultOnlyPaths<C extends RouteCard>(
  guid: RouteCardGuid,
  cards: C[],
): Path<C>[] {
  return getPaths(guid, cards, getLinksFromCard)
}

function getPaths<C extends RouteCard>(
  guid: RouteCardGuid,
  cards: C[],
  getLinks: (card: C) => CardLink[] = getAllLinksFromCard,
): Path<C>[] {
  const paths: Path<C>[] = []

  for (const { card, path } of createCardIterator(guid, cards, getLinks)) {
    const isEndOfPath = path.isCyclic || !card.nextGuid
    if (isEndOfPath) {
      paths.push(path)
    }
  }

  return paths
}

function* createCardIterator<C extends RouteCard>(
  guid: RouteCardGuid,
  cards: C[],
  getLinks: (card: C) => CardLink[] = getAllLinksFromCard,
  currentPath: Path<C> = { links: [], cards: [], isCyclic: false },
  visitedGuids: Set<RouteCardGuid> = new Set(),
): IterableIterator<{ card: C; path: Path<C> }> {
  const card = cards.find(c => c.guid === guid)
  if (!card) throw new Error(`Cannot find card (${guid}).`)

  if (visitedGuids.has(guid)) {
    yield {
      card,
      path: {
        ...currentPath,
        isCyclic: true,
      },
    }
  } else {
    const newVisitedGuids = new Set(visitedGuids)
    newVisitedGuids.add(guid)

    yield {
      card,
      path: {
        ...currentPath,
        cards: [...currentPath.cards, card],
      },
    }

    for (const link of getLinks(card)) {
      yield* createCardIterator(
        link.toGuid,
        cards,
        getLinks,
        {
          links: [...currentPath.links, link],
          cards: [...currentPath.cards, card],
          isCyclic: false,
        },
        newVisitedGuids,
      )
    }
  }
}

export function isCardFollowedBy(
  routingGraph: RoutingGraph,
  guid: CardGuid,
  followedByGuid: CardGuid | undefined,
): boolean {
  if (!followedByGuid) return false
  if (!routingGraph.hasNode(followedByGuid)) return false

  return getPathsFromNodeToNode(routingGraph, guid, followedByGuid).length > 0
}

export function hasMoreThanNConsecutivePreceedingNeighboursOfType(
  routingGraph: RoutingGraph,
  id: CardGuid,
  n: number,
  filterNeighboursBy: (id: string) => boolean,
  distance = 0,
): boolean {
  const neighboursOfType = routingGraph.filterInNeighbors(id, filterNeighboursBy)
  if (neighboursOfType.length === 0) return distance >= n
  return neighboursOfType.some(node =>
    hasMoreThanNConsecutivePreceedingNeighboursOfType(
      routingGraph,
      node,
      n,
      filterNeighboursBy,
      distance + 1,
    ),
  )
}

export function pathContainsGuid({ cards }: Path, guid: CardGuid): boolean {
  return cards.some(card => card.guid === guid)
}
