import type { CardTextError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import { type Card, isTextCard } from '@attest/editor-card'
import { SETTINGS } from '@attest/editor-settings'
import { removeMarkdownCharacters } from '@attest/markdown'

import { hasImage } from '../card-service'

export const getCardTextErrors = createGetErrorsFor<
  CardTextError['type'],
  { card: Card; placeholderErrorsEnabled?: boolean }
>({
  CARD_TEXT_MISSING({ card }) {
    if (!isTextCard(card) || hasImage(card)) return false
    const { text } = card.text
    return text.length === 0
  },

  CARD_TEXT_TOO_SHORT({ card }) {
    if (!isTextCard(card) || hasImage(card)) return false
    const { text } = card.text
    return text.length < SETTINGS.TEXT.MIN_LENGTH
  },

  CARD_TEXT_TOO_LONG({ card }) {
    if (!isTextCard(card)) return false
    const { text } = card.text
    return removeMarkdownCharacters(text).length > SETTINGS.TEXT.MAX_LENGTH
  },

  CARD_TEXT_HAS_PLACEHOLDER_TEXT({ card, placeholderErrorsEnabled }) {
    if (!isTextCard(card)) return false
    if (!placeholderErrorsEnabled) return false
    const { text } = card.text
    return SETTINGS.TEMPLATE.PLACEHOLDER_REGEX.test(text)
  },
})
