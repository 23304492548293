/**
 * Wraps `Object.groupBy` for type safety for known object structures. The navie `Object.groupBy` returns
 * a `Partial<Record<...>>`, but in certain circumstances the types are known so we can infer the returned structure.
 *
 * @example
 * ```ts
 * import { groupBy } from '@attest/util'
 *
 * type Category = 'film' | 'book'
 * type Item = { category: Category }
 * const list: Item[] = [{ category: 'film' }, { category: 'book' }]
 *
 * // Partial<Record<'film' | 'book', Item[]>>
 * Object.groupBy(list, ({ category }) => category)
 *
 * // Record<'film' | 'book', Item[]>
 * groupBy(list, ({ category }) => category)
 * ```
 */
export function groupBy<T, K extends PropertyKey>(
  list: readonly T[],
  fn: (a: T) => K,
): Record<K, T[]> {
  // Object.groupBy not available in lib.d.ts until TS 5.4
  // eslint-disable-next-line unicorn/no-array-reduce
  return list.reduce(
    (acc, x) => {
      const key = fn(x)
      return {
        ...acc,
        [key]: [...(acc[key] ?? []), x],
      }
    },
    {} as Record<K, T[]>,
  )
}
