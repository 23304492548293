export const isFirefox = () => navigator.userAgent.toLowerCase().includes('firefox')

export const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return (
    userAgent.includes('safari') && !userAgent.includes('chrome') && !userAgent.includes('chromium')
  )
}

export const isMacOS = () => navigator.userAgent.toLowerCase().includes('mac')
