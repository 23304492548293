import type { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { reject } from 'ramda'

import { type ApiError, type InternalError, isAppError } from '@attest/_api'
import {
  attachTag,
  type AttachTagRequestData,
  detachTag,
  type DetachTagRequestData,
} from '@attest/tag'
import {
  createExpectedError,
  createExpectedResolved,
  type Expected,
  isFulfilled,
  keyBy,
} from '@attest/util'

import {
  cloneByGuid,
  type CloneByGuidRequestData,
  type CloseByGuidRequestData,
  get,
  type GetSurveyRequestData,
  list,
  type ListSurveysRequestData,
  type ListSurveysV2RequestData,
  listV2,
  listV2More,
  type OpenByGuidRequestData,
  openOrCloseSurvey,
  saveStudyTitle,
  toSurveyModel,
  undoConvertStudy,
  updateInternalTitle,
  updateStudyOwner,
  updateSurveyArchive,
} from '../api'
import {
  isQuestionCard,
  type QuestionCard,
  type Survey,
  type SurveyCard,
  type SurveyGroup,
  type SurveyGuid,
  type SurveyStatus,
} from '../model'
import { getRandomizedGroups } from '../util'

export type SurveysState = {
  guidToSurvey: Record<string, Survey>
  ownSurveyGuids: string[]
  teamSurveyGuids: string[]
  searchResultGuids: string[]
  commentsForSurveys: { [guid: string]: number }
}

export type DeleteTagActionData = {
  guids: string[]
  tagId: string
}

export const SURVEY_NAMESPACE = 'survey'

export function createSurveyState(override: Partial<SurveysState> = {}): SurveysState {
  return {
    guidToSurvey: {},
    ownSurveyGuids: [],
    teamSurveyGuids: [],
    searchResultGuids: [],
    commentsForSurveys: {},
    ...override,
  }
}

export const useSurveyStore = defineStore(SURVEY_NAMESPACE, {
  state: createSurveyState,
  getters: {
    getSurveyByGuid(state): (guid: string) => Survey | undefined {
      return guid => {
        return state.guidToSurvey[guid]
      }
    },

    getInternalSurveys(state): Survey[] {
      return [...new Set([...state.teamSurveyGuids, ...state.ownSurveyGuids])]
        .map(guid => state.guidToSurvey[guid])
        .filter((survey: Survey | undefined): survey is Survey => Boolean(survey))
    },

    getAllSurveys(state): Survey[] {
      return Object.values(state.guidToSurvey)
    },

    getRandomizedGroupsForSurvey(): (guid: SurveyGuid) => SurveyGroup[] {
      return (guid: SurveyGuid): SurveyGroup[] => {
        const survey = this.getSurveyByGuid(guid)
        if (!survey) return []
        return getRandomizedGroups(survey.groups)
      }
    },

    getRandomizedQuestionCardsForSurvey(): (guid: SurveyGuid) => QuestionCard[] {
      return (guid: SurveyGuid): QuestionCard[] => {
        const survey = this.getSurveyByGuid(guid)
        if (!survey) return []
        return survey.cards.filter(isQuestionCard).filter(card => card.isRandomized)
      }
    },

    allSurveyCards(state): Record<string, SurveyCard> {
      const cards = Object.values(state.guidToSurvey ?? {}).flatMap(survey => survey.cards || [])
      return Object.fromEntries(cards.map(card => [card.id, card]))
    },

    getSurveyCardListFromIds(): (payload: { ids: string[] }) => SurveyCard[] {
      return ({ ids }) => ids.flatMap(id => this.allSurveyCards[id] ?? [])
    },

    getSurveyCardById(): (payload: { id: string }) => SurveyCard | undefined {
      return ({ id }) => this.allSurveyCards[id]
    },

    getSurveyFromCardId(): (payload: { id: string }) => Survey | undefined {
      return ({ id }) => this.getAllSurveys.find(({ cards }) => cards.some(card => card.id === id))
    },

    getQuestionGuidFromCardId(): (payload: { id: string }) => string | undefined {
      return ({ id }) => {
        const card = this.getSurveyCardById({ id })
        return card && isQuestionCard(card) ? card.questionGuid : undefined
      }
    },

    hasPublishedSurvey(): boolean {
      return this.getAllSurveys.some((survey: Survey) => survey.status !== 'draft')
    },
  },
  actions: {
    setSurveyGuids(data: { own: string[]; team: string[] }) {
      if (data.own.length > 0) {
        useSurveyStore().ownSurveyGuids = data.own
      }

      if (data.team.length > 0) {
        useSurveyStore().teamSurveyGuids = data.team
      }
    },

    appendSurveyGuids(data: { own: string[]; team: string[] }) {
      useSurveyStore().ownSurveyGuids = Array.from(
        new Set([...useSurveyStore().ownSurveyGuids, ...data.own]),
      )
      useSurveyStore().teamSurveyGuids = Array.from(
        new Set([...useSurveyStore().teamSurveyGuids, ...data.team]),
      )
    },

    delete(guid: string) {
      if (useSurveyStore().guidToSurvey) {
        delete useSurveyStore().guidToSurvey[guid]
      }
      useSurveyStore().ownSurveyGuids = useSurveyStore().ownSurveyGuids.filter(id => id !== guid)
      useSurveyStore().teamSurveyGuids = useSurveyStore().teamSurveyGuids.filter(id => id !== guid)
    },

    resetSearchGuids() {
      useSurveyStore().searchResultGuids = []
    },

    resetSurveyGuids() {
      useSurveyStore().guidToSurvey = {}
      useSurveyStore().ownSurveyGuids = []
      useSurveyStore().teamSurveyGuids = []
    },

    appendSearchGuids(guids: string[]) {
      useSurveyStore().searchResultGuids = Array.from(
        new Set([...useSurveyStore().searchResultGuids, ...guids]),
      )
    },

    async list(payload: ListSurveysRequestData) {
      const response = await list(payload)

      if (isAppError(response)) return response

      const surveyMap = response.surveys
      useSurveyStore().guidToSurvey = { ...useSurveyStore().guidToSurvey, ...surveyMap }

      if (payload.owner) {
        const trackingGuids = buildTrackingGuids(surveyMap)
        this.setSurveyGuids(trackingGuids)
      }

      return response
    },

    async get(payload: GetSurveyRequestData) {
      const response = await get(payload)
      const survey = toSurveyModel(response.data.success)
      useSurveyStore().guidToSurvey[survey.guid] = survey

      const trackingGuids = buildTrackingGuids({ [survey.guid]: survey })
      this.appendSurveyGuids(trackingGuids)

      return response
    },

    async listV2(payload: ListSurveysV2RequestData = {}) {
      const response = await listV2(payload)

      if (isAppError(response)) return response

      const surveyMap = { ...useSurveyStore().guidToSurvey, ...response.surveys }
      useSurveyStore().guidToSurvey = surveyMap

      const trackingGuids = buildTrackingGuids(surveyMap)
      this.setSurveyGuids(trackingGuids)
      useSurveyStore().commentsForSurveys = {
        ...useSurveyStore().commentsForSurveys,
        ...response.commentsCounts,
      }

      if (payload.search || (!!payload.tagIds && payload.tagIds.length > 0)) {
        this.appendSearchGuids(Object.values(response.surveys).map(({ guid }) => guid))
      }

      return response
    },

    async listV2More(payload: { cursor: string; isSearch: boolean }) {
      const response = await listV2More(payload)

      if (isAppError(response)) return response

      const surveyMap = { ...useSurveyStore().guidToSurvey, ...response.surveys }
      useSurveyStore().guidToSurvey = surveyMap

      const trackingGuids = buildTrackingGuids(surveyMap)
      this.setSurveyGuids(trackingGuids)
      useSurveyStore().commentsForSurveys = {
        ...useSurveyStore().commentsForSurveys,
        ...response.commentsCounts,
      }

      if (payload.isSearch) {
        this.appendSearchGuids(Object.values(response.surveys).map(({ guid }) => guid))
      }

      return response
    },

    async updateSurveyArchive(payload: {
      guid: string
      archive: boolean
    }): Expected<{ status: SurveyStatus } | undefined, Error | AxiosError> {
      const surveyStore = useSurveyStore()

      const [response, error] = await updateSurveyArchive(payload)

      if (error) {
        return [response, error]
      }

      const surveyToUpdate = surveyStore.guidToSurvey[payload.guid]
      if (surveyToUpdate === undefined) {
        return createExpectedError(new Error("couldn't find survey to update"))
      }

      surveyStore.guidToSurvey[payload.guid] = {
        ...surveyToUpdate,
        status: response.status,
      }
      return createExpectedResolved()
    },

    async undoConvertStudy(payload: { studyId: string }): Promise<void | InternalError<ApiError>> {
      const response = await undoConvertStudy(payload.studyId)

      if (!isAppError(response)) {
        const surveys = await Promise.allSettled(
          rejectDraftWaveSurveys(useSurveyStore().guidToSurvey[payload.studyId]?.waves ?? []).map(
            wave => get({ guid: wave.id }),
          ),
        )

        const surveyMap = {
          ...useSurveyStore().guidToSurvey,
          ...keyBy(
            surveys
              .filter(isFulfilled)
              .map(surveyResponse => toSurveyModel(surveyResponse.value.data.success)),

            survey => survey.guid,
          ),
        }
        delete surveyMap[payload.studyId]

        useSurveyStore().guidToSurvey = surveyMap
        this.setSurveyGuids(buildTrackingGuids(surveyMap))
      }

      return response
    },

    async cloneByGuid(payload: CloneByGuidRequestData) {
      const response = await cloneByGuid(payload)

      if (isAppError(response)) return response

      return response.data
    },

    async openCloseByGuid(payload: OpenByGuidRequestData | CloseByGuidRequestData) {
      const { guid } = payload
      const originSurvey = useSurveyStore().guidToSurvey[guid]

      if (!originSurvey) throw new Error(`cannot find survey ${guid}`)

      const action = originSurvey.status === 'closed' ? 'open' : 'close'
      const response = await openOrCloseSurvey({ guid, action })
      await this.get({ guid })

      return response
    },

    async attachTag(payload: AttachTagRequestData) {
      await attachTag(payload)

      payload.guids.forEach(guid => {
        const survey = this.guidToSurvey[guid]
        if (!survey) throw new Error(`cannot find survey ${guid}`)
        if (!survey.tags.includes(payload.tagId)) {
          survey.tags = [...survey.tags, payload.tagId]
        }
      })
    },

    async detachTag(payload: DetachTagRequestData) {
      await detachTag(payload)

      payload.guids.forEach(guid => {
        const survey = this.guidToSurvey[guid]
        if (survey) {
          const newTags = survey.tags.filter(item => item !== payload.tagId)
          survey.tags = newTags
        }
      })
    },

    async updateInternalTitle({
      guid,
      internalTitle,
    }: {
      guid: SurveyGuid
      internalTitle: string
    }): Promise<void | InternalError<ApiError>> {
      const survey = this.getSurveyByGuid(guid)
      if (!survey) return

      const response = await (survey.waves.length > 0
        ? saveStudyTitle({ id: guid, title: internalTitle })
        : updateInternalTitle({ guid, internalTitle }))
      if (isAppError(response)) return response

      this.guidToSurvey[guid] = { ...survey, internalTitle }
    },

    async transferStudyOwner({
      studyId,
      ownerId,
    }: {
      studyId: SurveyGuid
      ownerId: string
    }): Promise<void | InternalError<ApiError>> {
      const survey = this.getSurveyByGuid(studyId)
      if (!survey) return

      const response = await updateStudyOwner({ studyId, ownerId })
      if (isAppError(response)) return response

      this.guidToSurvey[studyId] = { ...survey, makerGuid: ownerId }
    },
  },
})

function buildTrackingGuids(surveyMap: Record<string, Survey>): { own: string[]; team: string[] } {
  const newTeamGuids: string[] = []
  const newOwnGuids: string[] = []
  for (const surveyGuid in surveyMap) {
    if (surveyMap[surveyGuid]?.viewership === 'own') {
      newOwnGuids.push(surveyGuid)
    }
    newTeamGuids.push(surveyGuid)
  }

  return {
    own: newOwnGuids,
    team: newTeamGuids,
  }
}

function rejectDraftWaveSurveys<W extends { status: SurveyStatus }>(waves: W[]): W[] {
  return reject(({ status }) => status === 'draft', waves)
}
