import type { RouteRecordRaw } from 'vue-router'

import { TEMPLATE_LISTING_ROUTES } from '@attest/router'

import { TemplateListing } from './page/TemplateListing'

export function createTemplateListingRoute(): RouteRecordRaw {
  return {
    name: TEMPLATE_LISTING_ROUTES.ROOT.name,
    path: TEMPLATE_LISTING_ROUTES.ROOT.path,
    component: TemplateListing,
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
    },
  }
}
