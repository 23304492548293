import { defineStore } from 'pinia'

import { capture } from '@attest/telemetry'

import { createTag, deleteTag, listTags, updateTag } from '../api'
import type { Tag, TagColor } from '../model'

export type TagState = {
  tagIdToTag: Record<string, Tag>
}

export const TAG_NAMESPACE = 'tag'

export function createTagState(override: Partial<TagState> = {}): TagState {
  return {
    tagIdToTag: {},
    ...override,
  }
}

export const useTagStore = defineStore(TAG_NAMESPACE, {
  state: createTagState,
  actions: {
    setTag(tag: Tag): void {
      this.tagIdToTag[tag.id] = tag
    },

    setTagIdToTag(tagIdToTag: Record<string, Tag>): void {
      this.tagIdToTag = tagIdToTag
    },

    updateTag(tag: Partial<Tag> & Pick<Tag, 'id'>): void {
      const currentTag = this.tagIdToTag[tag.id]
      if (!currentTag) throw new Error('Updating tag that does not exist')

      const updatedTag = { ...currentTag, ...tag }
      const response = updateTag(updatedTag)

      if (response instanceof Error) {
        return capture(response)
      }

      this.tagIdToTag[tag.id] = updatedTag
    },

    async createTag(payload: { name: string; color: TagColor }): Promise<Tag | void> {
      const response = await createTag(payload)

      if (response instanceof Error) {
        return capture(response)
      }

      this.setTag(response)
      return response
    },

    async deleteTag(id: string): Promise<void> {
      const response = await deleteTag(id)

      if (response instanceof Error) {
        return capture(response)
      }

      delete this.tagIdToTag[id]
    },

    async listTags(): Promise<void> {
      const response = await listTags()

      if (response instanceof Error) {
        return capture(response)
      }

      this.setTagIdToTag(response)
    },
  },
  getters: {
    tags(): Tag[] {
      return Object.values(this.tagIdToTag)
    },
  },
})
