import type { RouteLocationNamedRaw } from 'vue-router'

export const TEMPLATE_PREVIEW_ROUTES = {
  ROOT: { name: 'TEMPLATE_PREVIEW', path: '/template/:guid/preview' },
} as const

export function createTemplatePreviewLocation(
  options: Partial<RouteLocationNamedRaw> = {},
): RouteLocationNamedRaw {
  return {
    name: TEMPLATE_PREVIEW_ROUTES.ROOT.name,
    ...options,
  }
}
