import type { DefineComponent } from 'vue'

import type { HeadState } from '@attest/_lib/src/head'
import { CONFIG } from '@attest/config'
import { c } from '@attest/intl'

function getHead(vm: DefineComponent): HeadState | null {
  const { head } = vm.$options
  if (!head) return null

  const { IMAGE } = CONFIG.APP
  const TITLE = c('default.title')
  const DESCRIPTION = c('default.description')
  const result = head.call(vm)

  result.title = result.title ? `${TITLE} | ${result.title}` : TITLE
  result.description = result.description || DESCRIPTION
  result.image = result.image || IMAGE

  return result
}

export function createMixin() {
  return {
    mounted(this: DefineComponent) {
      const head = getHead(this)
      if (head && head.title) {
        document.title = head.title
      }
    },
  }
}
