<script lang="tsx">
import { defineComponent, type ExtractPublicPropTypes, type PropType, type SlotsType } from 'vue'
import { type LocationQueryRaw, type RouteParamsRaw, RouterLink } from 'vue-router'

import { create, type ExtractEvents } from '@attest/vue-tsx'

import { SvgIcon } from '../SvgIcon'

type NavbarItemProps = ExtractPublicPropTypes<typeof navbarItemProps>
const navbarItemProps = {
  title: { type: String, required: true },
  postfixClass: { type: String, required: false },
  icon: { type: String, default: '' },
  to: { type: String, default: '' },
  params: { type: Object as PropType<RouteParamsRaw>, default: {} },
  query: { type: Object as PropType<LocationQueryRaw>, default: {} },
  href: { type: String, default: '' },
  isForceActive: { type: Boolean, default: false },
  iconOnly: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
  color: { type: String as PropType<NavbarColor>, default: 'transparent' },
} as const

type NavbarItemEvents = ExtractEvents<typeof navbarItemEvents>
const navbarItemEvents = {
  mouseenter: () => true,
  click: () => true,
} as const

type NavbarItemSlots = { postfix?(): any }

type NavbarColor = 'transparent' | 'white'

const NavbarItem = defineComponent({
  props: navbarItemProps,
  slots: Object as SlotsType<NavbarItemSlots>,

  setup(props, { slots }) {
    return () => {
      const baseClass = 'c-navbar-item'
      const classes = {
        [baseClass]: true,
        [`${baseClass}--disabled`]: props.isDisabled,
        [`${baseClass}--${props.color}`]: true,
        'is-active': props.isForceActive,
      }

      const iconClasses = {
        [`${baseClass}__icon`]: true,
        [`${baseClass}__icon--disabled`]: props.isDisabled,
      }

      const content = [
        props.icon && <SvgIcon class={iconClasses} svg={props.icon} size="m" />,
        !props.iconOnly && (
          <span data-name="NavbarItemTitle" class="c-navbar-item__title">
            {props.title}
          </span>
        ),
      ]

      if (props.href) {
        return (
          <a href={props.href} target="_blank" aria-label={props.title} class={classes}>
            {content}
          </a>
        )
      }

      if (!props.to) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" class={classes} aria-label={props.title}>
            {content}
          </a>
        )
      }

      return (
        <RouterLink
          active-class="is-active"
          to={{ name: props.to, params: props.params, query: props.query }}
          class={classes}
          aria-label={props.title}
        >
          {content}
          {slots.postfix && (
            <span
              class={{
                [`${baseClass}__postfix`]: true,
                [props.postfixClass || '']: Boolean(props.postfixClass),
              }}
            >
              {slots.postfix?.()}
            </span>
          )}
        </RouterLink>
      )
    }
  },
})

export default create<NavbarItemProps, NavbarItemEvents, NavbarItemSlots>(NavbarItem)
</script>

<style lang="postcss">
.c-navbar-item {
  position: relative;
  display: flex;
  height: var(--attest-spacing-12);
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  border-radius: var(--attest-border-radius-l);
  margin: 0 var(--attest-spacing-3);
  color: var(--attest-color-text-default);
  transition:
    background-color var(--attest-timing-default) var(--attest-ease-in-out),
    font-weight var(--attest-timing-default) var(--attest-ease-in-out);

  @container (width > 100px) {
    padding-left: var(--attest-spacing-4);
  }

  @media (--attest-media-s) {
    justify-content: center;
  }

  @media (--attest-media-xl) {
    justify-content: flex-start;
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: inherit;
  }

  &__icon--disabled {
    color: var(--attest-color-text-subdued);
  }

  &__title {
    margin-left: var(--attest-spacing-4);

    @media (--attest-media-s) {
      display: none;
    }

    @media (--attest-media-xl) {
      display: block;
    }
  }

  &__postfix {
    @media (--attest-media-s) {
      position: absolute;
      top: var(--attest-spacing-2);
      right: var(--attest-spacing-2);
      margin: 0;
    }

    @media (--attest-media-xl) {
      position: static;
      top: inital;
      right: inital;
    }

    &:nth-child(3) {
      margin-left: var(--attest-spacing-4);

      @media (--attest-media-s) {
        display: none;
      }

      @media (--attest-media-xl) {
        display: block;
      }
    }
  }

  &:hover {
    background-color: var(--attest-color-interactive-subdued-hover);
    color: var(--attest-color-interactive-hover);
  }

  &:focus-visible {
    outline: 2px solid var(--attest-color-focus-default);
  }

  &.is-active {
    background-color: var(--attest-palette-grey-50);
    color: var(--attest-color-interactive-default);

    font-family: var(--attest-main-font-family) !important;
    font-size: var(--attest-font-size-m) !important;
    font-weight: var(--attest-font-weight-bold) !important;
    line-height: var(--attest-line-height-l) !important;
  }

  &--disabled {
    color: var(--attest-color-text-subdued);
    pointer-events: none;
  }

  &--white,
  &--white.is-active {
    background: var(--attest-color-surface-subdued);
    color: var(--attest-color-interactive-active);

    ^&__title {
      color: var(--attest-color-interactive-active);
    }

    &:active,
    &:focus,
    &:hover {
      background-color: rgb(255 255 255 / 10%);
      color: var(--attest-color-background-default);

      ^^&__title {
        color: var(--attest-color-background-default);
      }
    }
  }
}
</style>
