import { createEntity, type Entity } from './base'

type CrosstabsUiStateEntityData = {
  sigtestEnabled: boolean
  sigtestConfidence: number
  sigtestComparison: string
  allVariables: string[]
}

export type CrosstabsUiStateEntity<
  D extends CrosstabsUiStateEntityData = CrosstabsUiStateEntityData,
> = {} & Entity<D>

export function createCrosstabsUiStateEntity({
  sigtestEnabled,
  sigtestConfidence,
  sigtestComparison,
  allVariables,
}: CrosstabsUiStateEntityData): CrosstabsUiStateEntity {
  return createEntity(
    { name: 'crosstabs-ui-state', version: '1-0-0' },
    {
      sigtestEnabled,
      sigtestConfidence,
      sigtestComparison,
      allVariables,
    },
  )
}
