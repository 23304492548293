import axios, { type AxiosResponse } from 'axios'

import { ApiService } from './api-service'

export type ResponseData<SuccessData, ErrorData = unknown> = {
  success: SuccessData
  error?: ErrorData
}

/**
 * @deprecated use `AxiosResponse`
 */
export type ServiceResponse<T = any> = AxiosResponse<T>

axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['Cache-Control'] = 'no-cache'

if (typeof window !== 'undefined' && 'Cypress' in window) {
  axios.defaults.baseURL = 'http://localhost:54000/'
}

/**
 * @deprecated use `axios`
 *
 * ```ts
 * service(store).get()
 *
 * // becomes:
 *
 * axios.get()
 * ```
 */
export function service() {
  return new ApiService()
}

export function interceptResponse(
  onFulfilled?: (value: any) => any,
  onRejected?: (error: any) => any,
): number {
  return axios.interceptors.response.use(onFulfilled, onRejected)
}

export function interceptRequest(
  onFulfilled?: (value: any) => any,
  onRejected?: (error: any) => any,
): number {
  return axios.interceptors.request.use(onFulfilled, onRejected)
}
