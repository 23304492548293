import {
  type AnswerType,
  type Card,
  createEmptyAnswer,
  createEmptyHeader,
  type Header,
  type OptionAnswer,
  type SupportedReceiver,
  supportedReceivers,
} from '@attest/editor-card'
import { intersection } from '@attest/util'

export function isMaskingSender(card: Card, cards: Card[]): boolean {
  return cards.some(card2 => isMaskingToCard(card, card2))
}

export function getMaskingReceivers(card: Card, cards: Card[]): Card[] {
  return cards.filter(card2 => isMaskingToCard(card, card2))
}

export function isMaskingToCard(card: Card, to: Card): boolean {
  return (
    to.question.options.maskingQuestion?.senderGuid === card.guid &&
    to.question.options.maskingQuestion?.isToggled
  )
}

export function getSourceAnswer(args: {
  targetCard: Card
  targetAnswerId: string
  allCards: Card[]
}): { sourceCard: Card; sourceAnswer: OptionAnswer | undefined } | undefined {
  const seenCardGuids = new Set([args.targetCard.guid])
  const targetAnswer = args.targetCard.question.options.answers.find(
    a => a.id === args.targetAnswerId,
  )
  let parent = args.targetCard.question.options.maskingQuestion?.isToggled
    ? args.allCards.find(
        card => card.guid === args.targetCard.question.options.maskingQuestion?.senderGuid,
      )
    : undefined
  let answerInParent = parent?.question.options.answers.find(
    a => a.id === targetAnswer?.mask?.answerId,
  )

  while (parent?.question.options.maskingQuestion?.isToggled) {
    if (seenCardGuids.has(parent?.question.options.maskingQuestion?.senderGuid)) {
      break
    }
    parent = args.allCards.find(
      card => card.guid === parent?.question.options.maskingQuestion?.senderGuid,
    )
    answerInParent = parent?.question.options.answers.find(
      a => a.id === answerInParent?.mask?.answerId,
    )

    if (!parent) {
      break
    }
    seenCardGuids.add(parent.guid)
  }

  return parent ? { sourceCard: parent, sourceAnswer: answerInParent } : undefined
}

export function getAncestorSender(card: Card, cards: Card[]): Card | undefined {
  return getSourceAnswer({ targetCard: card, allCards: cards, targetAnswerId: '' })?.sourceCard
}

export function getAncestralTree(card: Card, cards: Card[], visited: Card[] = []): Card[] {
  const senderCard = getSenderCard(card, cards)
  if (!senderCard) return visited
  if (visited.includes(senderCard)) return visited
  return getAncestralTree(senderCard, cards, [...visited, senderCard])
}

function getSenderCard(card: Card, cards: Card[]): Card | undefined {
  const { maskingQuestion } = card.question.options
  if (!maskingQuestion || !maskingQuestion.isToggled) return undefined
  return cards.find(c => c.guid === maskingQuestion.senderGuid) || undefined
}

export function isMaskingReceiver(card: Card): boolean {
  if (!card.question.options.maskingQuestion?.isToggled) return false
  return !!card.question.options.maskingQuestion?.senderGuid
}

export function isCardPotentialSender({ question }: Card): boolean {
  return question.type === 'multiple_choice'
}

export function canMaskCard({ question: { type } }: Card): boolean {
  return type !== null && supportedReceivers.includes(type as SupportedReceiver)
}

export function getPotentialMaskingSenders(card: Card, orderedCards: Card[]): Card[] {
  return orderedCards
    .slice(
      0,
      orderedCards.findIndex(orderedCard => orderedCard.guid === card.guid),
    )
    .filter(isCardPotentialSender)
    .filter(potentialSender => {
      return (
        intersection([
          new Set(getAncestralTree(potentialSender, orderedCards)),
          new Set([potentialSender, card]),
        ]).size === 0
      )
    })
}

export function generateForwardedAnswersForChoiceQuestion(
  sender: Card,
  receiver: Card,
  isToggled: boolean,
): OptionAnswer<AnswerType>[] {
  return (receiver.question.options.answers = sender.question.options.answers.map(
    (answer, index) => {
      const receiverAnswer = createEmptyAnswer(receiver.question.options.answers[index])
      return {
        ...answer,
        text: isToggled ? answer.text : '',
        id: receiverAnswer.id,
        nextGuid: receiverAnswer.nextGuid,
        isQualifying: receiverAnswer.isQualifying,
        mask: receiver.question.options.maskingQuestion?.isToggled ? { answerId: answer.id } : null,
        quotaId: null,
        omittedFromAudiences: new Set(),
      }
    },
  ))
}

export function generateForwardedHeadersForGridQuestion(sender: Card, receiver: Card): Header[] {
  return sender.question.options.answers.map((answer, index) => {
    const receiverHeader = createEmptyHeader(receiver.question.subjects.headers[index])

    return {
      ...answer,
      id: receiverHeader.id,
      media: receiverHeader.media,
      type: receiverHeader.type,
      isQualifying: receiverHeader.isQualifying,
      isPinned: receiverHeader.isPinned,
      mask: receiver.question.options.maskingQuestion?.isToggled ? { answerId: answer.id } : null,
      quotaId: null,
    }
  })
}

export function getUpdatedGridHeaderPositions(
  receiver: Card,
  updatedPositions: Record<string, number>,
): Header[] {
  const getMaskSortValue = (mask: { answerId: string } | null): number =>
    updatedPositions[mask?.answerId ?? 0] ?? -1
  return (receiver.question.subjects.headers ?? []).toSorted(
    (a, b) => getMaskSortValue(a.mask) - getMaskSortValue(b.mask),
  )
}

export function getUpdatedAnswerPositions(
  receiver: Card,
  updatedPositions: Record<string, number>,
): OptionAnswer[] {
  const getMaskSortValue = (mask: { answerId: string } | null): number =>
    updatedPositions[mask?.answerId ?? 0] ?? -1
  return (receiver.question.options.answers ?? []).toSorted(
    (a, b) => getMaskSortValue(a.mask) - getMaskSortValue(b.mask),
  )
}
