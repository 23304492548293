<script lang="tsx">
import { nanoid } from 'nanoid'
import type { FunctionalComponent } from 'vue'

import { COPY } from '@attest/_copy'
import { create } from '@attest/vue-tsx'

import { FormButton } from './FormButton'
import { InlineMessage } from './InlineMessage'
import { ModalDefault } from './ModalDefault'
import type { SvgIconColor } from './SvgIcon'

export type ModalConfirmProps = {
  confirmCopy?: string
  handleConfirm?(event: Event): void
  cancelCopy?: string
  isForm?: boolean
  error?: string | null
  confirmLoading?: boolean
  confirmDisabled?: boolean
  confirmButtonVariant?: 'error' | 'primary'
  canCancel?: boolean
  handleCancel(event: Event): void
  clickOverlayToCancel?: boolean
  titleDisplay?: string | null
  show?: boolean
  transitionName?: string
  size?: 'small' | 'medium' | 'large'
  dialogClass?: string
  dialogBodyClass?: string
  dialogHeaderClass?: string
  dialogFooterClass?: string
  dialogHeaderTitleClass?: string
  headerButtonVariant?: SvgIconColor
  hasPadding?: boolean
}

const ModalConfirm: FunctionalComponent<ModalConfirmProps> = (props, { slots }) => {
  const {
    titleDisplay,
    show,
    handleCancel,
    handleConfirm,
    confirmCopy,
    dialogBodyClass,
    error,
    isForm = false,
    cancelCopy = COPY.MODAL.CANCEL,
    confirmLoading = false,
    confirmDisabled = false,
    dialogHeaderClass,
    dialogFooterClass,
    confirmButtonVariant = 'primary',
    canCancel = true,
    clickOverlayToCancel = true,
    headerButtonVariant,
    ...attrs
  } = props

  const formId = nanoid()
  const onConfirm = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    handleConfirm?.(e)
  }
  const onCancel = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    handleCancel(e)
  }

  const baseClass = 'c-modal-confirm'

  const modalClass = {
    [baseClass]: true,
  }

  const bodyClass = {
    [`${baseClass}__body`]: true,
    [dialogBodyClass || '']: true,
  }

  return (
    <ModalDefault
      class={modalClass}
      {...attrs}
      show={show}
      handleCancel={handleCancel}
      titleDisplay={titleDisplay}
      dialogHeaderClass={dialogHeaderClass}
      dialogBodyClass={dialogBodyClass}
      dialogFooterClass={dialogFooterClass}
      canCancel={canCancel}
      clickOverlayToCancel={clickOverlayToCancel}
      headerButtonVariant={headerButtonVariant}
      data-visibility={show ? 'visible' : 'hidden'}
    >
      {{
        default: () => [
          isForm ? (
            <form onSubmit={onConfirm} id={formId} class={bodyClass} data-name="ModalConfirmForm">
              {slots.default?.()}
            </form>
          ) : (
            <div class={bodyClass}>{slots.default?.()}</div>
          ),
          error && <InlineMessage data-name="ModalConfirmError">{error}</InlineMessage>,
        ],
        headerMeta: slots.headerMeta,
        footer: () => (
          <div class={`${baseClass}__button-wrap`}>
            {slots.footer?.()}
            {canCancel && cancelCopy && (
              <FormButton
                variant="white"
                onClick={onCancel}
                data-name="ModalConfirmCancel"
                class={`${baseClass}__cancel`}
              >
                {cancelCopy}
              </FormButton>
            )}
            {handleConfirm && (
              <button
                class={{
                  [`${baseClass}__submit`]: true,
                  'attest-button': true,
                  'attest-button--variant-filled': true,
                  'attest-button--variant-filled--negative': confirmButtonVariant === 'error',
                  'attest-button--loading': confirmLoading,
                }}
                onClick={isForm ? () => {} : onConfirm}
                disabled={confirmDisabled}
                form={isForm ? formId : undefined}
                type={isForm ? 'submit' : 'button'}
                data-name="ModalConfirmSubmit"
              >
                {confirmCopy}
              </button>
            )}
          </div>
        ),
      }}
    </ModalDefault>
  )
}
export default create<ModalConfirmProps>(ModalConfirm)
</script>

<style lang="postcss">
.c-modal-confirm {
  &__body {
    color: var(--attest-color-background-inverse);
    text-align: left;
  }

  &__button-wrap {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__cancel {
    margin-left: auto;
  }

  &__submit {
    margin-left: var(--attest-spacing-2);
  }
}
</style>
