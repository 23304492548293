import { defineStore } from 'pinia'
import { nextTick } from 'vue'

export type SortMethod = 'draft_order' | 'responses' | 'custom'
export type StudyInsightsUIState = {
  selectedCardIds: string[]
  navigatorSelectedCardId?: string
  isShowingResultsTotals: boolean
  isShowingResultsPercentages: boolean
  cardIdToForwardAnswerEnabled: { [key: string]: boolean }
  studyDisplayLanguage: 'DRAFT' | string
}

export const STUDY_INSIGHTS_UI_STORE_NAMESPACE = 'studyInsightsUI'

export function createStudyInsightsUIStateTree(override: Partial<StudyInsightsUIState> = {}): {
  [STUDY_INSIGHTS_UI_STORE_NAMESPACE]: StudyInsightsUIState
} {
  return { [STUDY_INSIGHTS_UI_STORE_NAMESPACE]: createStudyInsightsUIState(override) }
}

export function createStudyInsightsUIState(
  override: Partial<StudyInsightsUIState> = {},
): StudyInsightsUIState {
  return {
    selectedCardIds: [],
    navigatorSelectedCardId: undefined,
    isShowingResultsPercentages: true,
    isShowingResultsTotals: true,
    cardIdToForwardAnswerEnabled: {},
    studyDisplayLanguage: 'DRAFT',
    ...override,
  }
}

export const useStudyInsightsUIStore = defineStore(STUDY_INSIGHTS_UI_STORE_NAMESPACE, {
  state: createStudyInsightsUIState,
  actions: {
    navigateToCard(cardId: string): void {
      this.navigatorSelectedCardId = undefined
      nextTick(() => {
        this.navigatorSelectedCardId = cardId
      })
    },

    toggleForwardAnswerEnabled(cardId: string, toggleState: boolean): void {
      this.cardIdToForwardAnswerEnabled[cardId] = toggleState
      this.cardIdToForwardAnswerEnabled = { ...this.cardIdToForwardAnswerEnabled }
    },

    toggleSelectedCard(id: string): void {
      if (this.selectedCardIds.includes(id)) {
        this.selectedCardIds.splice(this.selectedCardIds.indexOf(id), 1)
      } else {
        this.selectedCardIds.push(id)
      }
    },

    toggleIsShowingResultsTotals(): void {
      this.isShowingResultsTotals = !this.isShowingResultsTotals
    },

    toggleIsShowingResultsPercentages(): void {
      this.isShowingResultsPercentages = !this.isShowingResultsPercentages
    },
  },
})
