import { nanoid } from 'nanoid'

import {
  type ApiAnswerGridChoice,
  type ApiQuestionCard,
  isSavedApiQuestionCard,
  type SavedAnswer,
  type SavedApiBlankCard,
  type SavedApiCard,
  type SavedApiGridQuestionCard,
  type SavedApiMessageCard,
  type SavedApiQuestionCard,
} from '@attest/_api/model/card'
import type { ApiGroup, SavedApiGroup, SavedApiNode } from '@attest/_api/model/node'
import { COPY } from '@attest/_copy'

import type {
  Answer,
  AnswerGridData,
  AnswerGridHeader,
  BlankCard,
  FlatQuestionCard,
  GridQuestionCard,
  MessageCard,
  StaticAnswer,
  StaticAnswerType,
  SurveyCard,
} from '../../model'
import {
  isApiBlankCard,
  isApiCard,
  isApiGroup,
  isApiMessageCard,
  isApiQuestion,
  isRootRandomizedApiGroup,
  isSavedApiGridQuestion,
} from '../util'

/** @deprecated Consume from `@attest/results-core` instead */
export function toSurveyCardsModel(apiNodes: SavedApiNode[]): SurveyCard[] {
  const apiCards = surveyNodesToApiCards(apiNodes)
  const cardModels = apiCards.map(apiCard => toSurveyCardModel(apiCard, apiCards))
  const randomizedGuids = getRandomizedCardGuids(apiNodes)
  return cardModels.map(card => ({
    ...card,
    isRandomized: randomizedGuids.has(card.id),
  }))
}

function toSurveyCardModel(card: SavedApiCard, cards: SavedApiNode[]): SurveyCard {
  if (isApiBlankCard(card)) {
    return toBlankCardModel(card)
  }

  if (isApiMessageCard(card)) {
    return toMessageCardModel(card)
  }

  if (isSavedApiGridQuestion(card)) {
    return toGridQuestionCardModel(card, cards)
  }

  if (isApiQuestion(card)) {
    return toFlatQuestionCardModel(card, cards)
  }

  throw new Error('Unknown card type returned from server')
}

/** @deprecated Consume from `@attest/results-core` instead */
function surveyNodesToApiCards(apiNodes: SavedApiNode[]): SavedApiCard[] {
  const groupCards = apiNodes.filter((node): node is SavedApiGroup => isApiGroup(node))
  const apiCards = apiNodes.filter(isApiCard)

  if (groupCards.length === 0) return apiCards

  const isGroupInGroup = (groupIndex: number): boolean =>
    groupCards.findIndex(({ children }) => children.some(({ index }) => index === groupIndex)) >= 0

  const getCardNext = (apiCard: SavedApiCard, apiCardIndex: number) => {
    const groupIndex = apiNodes.findIndex(
      node =>
        isApiGroup(node) &&
        node.children.some(({ index }) => getUpdatedIndex(index) === apiCardIndex),
    )

    const group = apiNodes[groupIndex] as ApiGroup | undefined
    if (!group) return getUpdatedIndex(apiCard.next)

    if (isGroupInGroup(groupIndex)) return undefined

    const { children, next } = group
    const updatedIndexInGroup = children.findIndex(
      ({ index }) => getUpdatedIndex(index) === apiCardIndex,
    )

    return getUpdatedIndex(updatedIndexInGroup === children.length - 1 ? next : apiCard.next)
  }

  const getUpdatedIndex = (outdatedIndex?: number | null): number | undefined => {
    if (typeof outdatedIndex !== 'number') return undefined

    const node = apiNodes[outdatedIndex] as SavedApiNode
    const apiCard = apiCards[outdatedIndex]

    if (apiCard && apiCard.guid === node.guid) return outdatedIndex
    if (isApiGroup(node)) {
      return isGroupInGroup(outdatedIndex) ? undefined : getUpdatedIndex(node.children[0]?.index)
    }

    return apiCards.findIndex(({ guid }) => guid === node.guid)
  }

  return apiCards.map((apiCard, apiCardIndex) => {
    const updatedApiCard = {
      ...apiCard,
      next: getCardNext(apiCard, apiCardIndex),
    } as SavedApiCard

    if (!isApiQuestion(updatedApiCard)) return updatedApiCard

    return {
      ...updatedApiCard,
      answers: updatedApiCard.answers.map(answer => ({
        ...answer,
        next: getUpdatedIndex(answer.next ?? undefined),
      })),
    }
  })
}

/** @deprecated Consume from `@attest/results-core` instead */
function getRandomizedCardGuids(apiNodes: SavedApiNode[]): Set<string> {
  const guids = apiNodes.map(apiNode => (apiNode.card_type !== 'group' ? apiNode.guid : nanoid()))
  return new Set(
    apiNodes
      .filter((node): node is SavedApiGroup => isApiGroup(node))
      .flatMap(({ children }) => children)
      .filter(({ randomized }) => randomized)
      .map(({ index }) => guids[index])
      .filter((guid: string | undefined): guid is string => guid !== undefined),
  )
}

function toMessageCardModel(card: SavedApiMessageCard): MessageCard {
  return {
    id: card.guid,
    cardType: card.card_type,
    next: card.next || null,
    position: null,
    title: card.text,
    titleContent: {
      imageUrl: card.image_url || null,
      audioUrl: null,
      videoUrl: null,
    },
  }
}

function toBlankCardModel(card: SavedApiBlankCard): BlankCard {
  return {
    id: card.guid,
    cardType: card.card_type,
    next: card.next || null,
    position: null,
  }
}

function toFlatQuestionCardModel(
  card: SavedApiQuestionCard,
  cards: SavedApiNode[],
): FlatQuestionCard {
  return {
    id: card.guid,
    questionId: card.question_id,
    questionGuid: card.question_guid,
    cardType: card.card_type,
    next: card.next || null,
    position: null,
    title: toQuestionCardTitle(card, cards),
    titleContent: {
      imageUrl: (card.title_content && card.title_content.image_url) || card.image || null,
      audioUrl: (card.title_content && card.title_content.audio_url) || null,
      videoUrl: (card.title_content && card.title_content.video_url) || null,
    },
    hasNa: card.na,
    hasNone: card.none,
    hasOther: card.other,
    isAnswersRandomized: card.randomized,
    selectionLimit: card.max_selections !== undefined ? card.max_selections : null,
    creationTime: card.creation_time,
    isImageType: card.answers && card.answers.length > 0 && card.answers[0]?.type === 'image',
    answers: toAnswerModels({ card, cards }),
    secondaryAnswers: [
      createStaticAnswer('none', !card.none),
      createStaticAnswer('na', !card.na),
      createStaticAnswer('skipped'),
    ],
  }
}

function toGridQuestionCardModel(
  card: SavedApiGridQuestionCard,
  cards: SavedApiNode[],
): GridQuestionCard {
  return {
    id: card.guid,
    questionId: card.question_id,
    questionGuid: card.question_guid,
    cardType: card.card_type,
    next: card.next || null,
    position: null,
    title: toQuestionCardTitle(card, cards),
    titleContent: {
      imageUrl: (card.title_content && card.title_content.image_url) || null,
      audioUrl: (card.title_content && card.title_content.audio_url) || null,
      videoUrl: (card.title_content && card.title_content.video_url) || null,
    },
    creationTime: card.creation_time,
    answers: toAnswerGridDataModel({ card, cards }),
    secondaryAnswers: [createStaticAnswer('skipped')],
  }
}

function toQuestionCardTitle(
  { title, piping = [] }: ApiQuestionCard | SavedApiGridQuestionCard,
  cards: SavedApiNode[],
): string {
  const sourceQuestionIndices = piping.map(({ source_question_order }) => source_question_order)

  return title.replace(/{{answer}}/g, () => {
    const sourceQuestionIndex = sourceQuestionIndices.shift()
    if (typeof sourceQuestionIndex !== 'number') return `{Q?}`
    const numQuestions = getNumberQuestionsUntilIndex(cards, sourceQuestionIndex)

    return `{Q${numQuestions + 1}}`
  })
}

function getNumberQuestionsUntilIndex(cards: SavedApiNode[], index: number): number {
  const groupCards = new Set(
    cards.flatMap(card =>
      isApiGroup(card) && !isRootRandomizedApiGroup(card)
        ? card.children.map(child => cards[child.index])
        : [],
    ),
  )
  return cards.filter(
    (card, i) => !isApiMessageCard(card) && !isApiGroup(card) && !groupCards.has(card) && i < index,
  ).length
}

function toAnswerModels({
  card,
  cards,
}: {
  card: SavedApiQuestionCard
  cards: SavedApiNode[]
}): Answer[] {
  const { answers, other } = card

  let transformedAnswers: Answer[] = []

  if (answers) {
    transformedAnswers = answers.map((answer, answerIndex) =>
      toAnswerModel({
        answer,
        maskingInformation: (() => {
          if (!isSavedApiQuestionCard(card) || typeof card.mask_card_index !== 'number') {
            return
          }
          const referenceCard = cards[card.mask_card_index]
          if (referenceCard === undefined || !isApiQuestion(referenceCard)) {
            return
          }
          const referenceAnswer = referenceCard.answers[answerIndex]
          if (referenceAnswer === undefined) {
            return
          }
          return {
            referenceCardGuid: referenceCard.guid,
            referenceAnswerId: referenceAnswer.id,
          }
        })(),
      }),
    )
  }

  if (other) {
    transformedAnswers.push(createStaticAnswer('other'))
  }

  return transformedAnswers
}

function toAnswerGridDataModel({
  card,
  cards,
}: {
  card: SavedApiGridQuestionCard
  cards: SavedApiNode[]
}): AnswerGridData {
  const { answers } = card
  const { subjects, options, choices } = answers
  const refCard = (() => {
    if (!isSavedApiQuestionCard(card) || typeof card.mask_card_index !== 'number') return
    return cards[card.mask_card_index]
  })()

  const subjectHeaders = subjects.headers.map<AnswerGridHeader>(({ id, pinned, text, mask }) => {
    const refCardAnswer =
      mask && refCard !== undefined && isApiQuestion(refCard)
        ? refCard.answers[mask.answer_index]
        : undefined
    return {
      id,
      type: 'text' as const,
      text: text || '',
      isPinned: pinned || false,
      imageUrl: null,
      filename: null,
      mask:
        refCard && refCardAnswer !== undefined
          ? {
              cardId: refCard.guid,
              answerId: refCardAnswer.id,
            }
          : undefined,
    }
  })

  const optionHeaders = options.headers.map<AnswerGridHeader>(
    ({ id, type, image_url, filename, pinned, text }) => ({
      id,
      type,
      text: text || '',
      isPinned: pinned || false,

      imageUrl: image_url || null,
      filename: filename || null,
    }),
  )

  return {
    subjects: {
      isRandomized: subjects.randomized,
      headers: subjectHeaders,
    },
    options: {
      isRandomized: options.randomized,
      isSingleChoice: options.type === 'single_choice',
      hasNa: gridHasNa(choices),
      hasNone: gridHasNone(choices),
      headers: optionHeaders,
      selectionLimit: options.max_selections !== undefined ? options.max_selections : null,
    },
    choices: choices.map(({ values }) =>
      values.map(({ id, type }) => ({
        id,
        type,
        text: '',
        isPinned: false,
        isQualifying: false,
        next: null,
        imageUrl: null,
        filename: null,
      })),
    ),
  }
}

function gridHasNa(choices: ApiAnswerGridChoice[]): boolean {
  return choices.some(choice => choice.values.some(value => value.type === 'na'))
}

function gridHasNone(choices: ApiAnswerGridChoice[]): boolean {
  return choices.some(choice => choice.values.some(value => value.type === 'none'))
}

function toAnswerModel({
  answer,
  maskingInformation,
}: {
  answer: SavedAnswer
  maskingInformation?: {
    referenceCardGuid: string
    referenceAnswerId: string
  }
}): Answer {
  return {
    id: answer.id,
    type: answer.type,
    text: answer.text || '',
    isPinned: !!answer.pinned,
    isQualifying: !!answer.qualifying,
    next: answer.next || null,
    imageUrl: answer.image_url || null,
    filename: answer.filename || null,
    quotaId: answer.quota_id,
    mask: maskingInformation
      ? {
          cardId: maskingInformation.referenceCardGuid,
          answerId: maskingInformation.referenceAnswerId,
        }
      : undefined,
  }
}

function createStaticAnswer<T extends StaticAnswerType>(
  type: T,
  isDisabled?: boolean,
): StaticAnswer<T> {
  return {
    type,
    isDisabled,
    next: null,
    id: type,
    imageUrl: null,
    filename: null,
    text: getStaticAnswerText(type),
    isPinned: false,
    isQualifying: false,
  }
}

function getStaticAnswerText(type: StaticAnswerType): string {
  switch (type) {
    case 'na':
      return COPY.NA
    case 'none':
      return COPY.NONE
    case 'skipped':
      return COPY.SKIP
    case 'other':
    default:
      return COPY.OTHER
  }
}
