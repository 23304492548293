import { createEntity, type Entity } from './base'

type ExportId =
  | 'automated-insights'
  | 'survey-results'
  | 'credit-transactions'
  | 'question-chart'
  | 'trend-chart'
  | 'question-to-clipboard'
  | 'results-breakdown'

type ExportFormat = 'csv' | 'xlsx' | 'pdf' | 'png' | 'ppt'
type SecondaryFormat = 'long' | 'wide'

export type ExportEntity<D extends ExportEntityData = ExportEntityData> = {} & Entity<D>
type ExportEntityData = {
  id: ExportId
  format: ExportFormat
  secondaryFormat?: SecondaryFormat
  qualifiiedResultsOnly?: boolean
  isPartialSelection?: boolean
  noOfSelectedAudiences?: number
  noOfSelectedDates?: number
  noOfSelectedQuestions?: number
  totalQuestions?: number
}

export function createExportEntity(data: ExportEntityData): ExportEntity {
  return createEntity({ name: 'export', version: '1-0-9' }, data)
}
