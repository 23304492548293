import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  type RouteLocationNormalizedLoaded,
  type RouteLocationResolvedGeneric,
  useRouter,
} from 'vue-router'

import { useStudyStore, type WaveSurvey } from '@attest/study'
import { intersection } from '@attest/util'

import { getStoreKeyForRoute, parseQueryValue } from './util'

function serialiseAudience(audience: { audienceId: string }): string {
  return audience.audienceId
}

function deSerialiseAudience(audience: string): WaveSurvey | undefined {
  return useStudyStore().getWaveSurveyByAudienceId(audience)
}

export const useStudyWaveAudienceFiltersStore = defineStore('studyWaveAudienceFilters', () => {
  const studyStore = useStudyStore()
  const router = useRouter()

  const storedAudiencesForRoute = ref(new Map<string, Set<string>>())
  const defaultAudiences = computed(
    () => new Set(studyStore.sentWaveSurveys.map(wave => serialiseAudience(wave))),
  )
  const serialisedAudiencesForCurrentRoute = computed(() =>
    areAnyAudiencesValid.value
      ? getSerialisedAudiencesForRoute(router.currentRoute.value)
      : defaultAudiences.value,
  )
  const areAnyAudiencesValid = computed(
    () =>
      intersection([
        defaultAudiences.value,
        getSerialisedAudiencesForRoute(router.currentRoute.value),
      ]).size > 0,
  )
  function getSerialisedAudiencesForRoute(
    route: RouteLocationNormalizedLoaded | RouteLocationResolvedGeneric,
  ): Set<string> {
    const { query } = route
    const audiencesForRoute = storedAudiencesForRoute.value.get(getStoreKeyForRoute(route))
    if (audiencesForRoute && audiencesForRoute.size > 0) return audiencesForRoute
    return query.audience ? new Set(parseQueryValue(query.audience)) : defaultAudiences.value
  }

  // update stored state when route changes
  // when navigating between pages the waves are retained
  watch(
    router.currentRoute,
    route => {
      if (!route.params.studyId) return
      if (!route.query.audience) return

      storedAudiencesForRoute.value.set(
        getStoreKeyForRoute(router.currentRoute.value),
        new Set(parseQueryValue(route.query.audience)),
      )
    },
    { immediate: true },
  )

  return {
    serialisedAudiences: serialisedAudiencesForCurrentRoute,
    getSerialisedAudiencesForRoute,
    serialiseAudience,
    deSerialiseAudience,
    areAnyAudiencesValid,
  }
})
