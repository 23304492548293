<script lang="tsx">
import { defineComponent, type PropType } from 'vue'

import userSVG from '@attest/_assets/icons/account_circle.svg'
import registerSVG from '@attest/_assets/icons/app_registration.svg'
import attestLogoSVG from '@attest/_assets/icons/attest-24.svg'
import attestLogoFullSVG from '@attest/_assets/icons/attest-logo-dark.svg'
import closeCircleSVG from '@attest/_assets/icons/cancel.svg'
import pathSVG from '@attest/_assets/icons/conversion_path.svg'
import questionSVG from '@attest/_assets/icons/help.svg'
import loginSVG from '@attest/_assets/icons/login.svg'
import signOutSVG from '@attest/_assets/icons/logout.svg'
import academySVG from '@attest/_assets/icons/school.svg'
import creditsSVG from '@attest/_assets/icons/toll.svg'
import { CollapsibleNavbarItem } from '@attest/_components/src/common/CollapsibleNavbarItem'
import { FormButton } from '@attest/_components/src/common/FormButton'
import HorizontalRule from '@attest/_components/src/common/HorizontalRule/HorizontalRule.vue'
import { NavbarItem } from '@attest/_components/src/common/NavbarItem'
import { NavbarPopover } from '@attest/_components/src/common/NavbarPopover'
import { SvgIcon } from '@attest/_components/src/common/SvgIcon'
import { SvgImage } from '@attest/_components/src/common/SvgImage'
import { COPY } from '@attest/_copy'
import { getVariation } from '@attest/feature-switch'
import { c } from '@attest/intl'
import {
  ADMIN_ROUTES,
  EXTERNAL_PATH,
  ROUTES,
  STUDY_INSIGHTS_ROUTE_NAME,
  TEMPLATE_PREVIEW_ROUTES,
} from '@attest/router'
import { LoggedInInlineUser } from '@attest/user'
import { createEmitter } from '@attest/vue-tsx'

import { NavbarItemsPane } from '../NavbarItemsPane'

import type { NavbarEvents } from './Navbar.interface'

const emit = createEmitter<NavbarEvents>()

export default defineComponent({
  props: {
    showAppButtons: { type: Boolean, required: true },
    routeName: { type: String as PropType<string | undefined>, default: undefined },
  },

  data() {
    return {
      title: '',
    }
  },

  computed: {
    shouldShowWalkThrough() {
      return (
        // this.routeName === ROUTES.SURVEY_RESULTS.name ||
        this.routeName === STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW
      )
    },

    shouldShowGeneralNavbarItems(): boolean {
      return (
        !this.routeName ||
        !Object.values(ADMIN_ROUTES)
          .map<string>(r => r.name)
          .includes(this.routeName)
      )
    },

    alwaysShowFullNavbar() {
      return this.routeName === TEMPLATE_PREVIEW_ROUTES.ROOT.name
    },
  },

  methods: {
    close() {
      emit(this, 'close')
    },

    createPopoverSetter(popoverTitle: string): () => void {
      return () => (this.title = popoverTitle)
    },
  },

  render() {
    const baseClass = 'c-navbar'
    const toBottom = <div class={`${baseClass}__to-bottom`} />
    const navbarItemClass = `${baseClass}__item`

    const Home = (
      <NavbarItem
        class={`${baseClass}__item-logo`}
        icon={attestLogoSVG}
        title="Home"
        to={ROUTES.INDEX.name}
        query={this.$route.query}
        iconOnly
      />
    )

    const HomeLarge = (
      <NavbarItem
        class={`${baseClass}__item-logo-large`}
        postfixClass={`${baseClass}__item-logo-large-logo`}
        title="Home"
        to={ROUTES.INDEX.name}
        query={this.$route.query}
        iconOnly
        data-name="NavbarHome"
      >
        {{ postfix: () => <SvgImage svg={attestLogoFullSVG} /> }}
      </NavbarItem>
    )

    const Academy = (
      <NavbarItem
        class={`${navbarItemClass}__popover-target`}
        icon={academySVG}
        title={c('navbar.academy')}
        href={`${EXTERNAL_PATH.ACADEMY}`}
        onMouseenter={this.createPopoverSetter(c('navbar.academy'))}
        data-name="Academy"
      />
    )

    const WalkThrough = this.shouldShowWalkThrough && (
      <NavbarItem
        class={`${navbarItemClass}__popover-target ${navbarItemClass}--walkthrough`}
        icon={pathSVG}
        title={COPY.NAVIGATION.WALKTHROUGH}
        onMouseenter={this.createPopoverSetter(COPY.NAVIGATION.WALKTHROUGH)}
        data-name="WalkThrough"
      />
    )

    const Help = (
      <NavbarItem
        class={`${navbarItemClass}__popover-target`}
        icon={questionSVG}
        title={COPY.NAVIGATION.HELP}
        href={`${EXTERNAL_PATH.INTERCOM_HELP}`}
        onMouseenter={this.createPopoverSetter(COPY.NAVIGATION.HELP)}
        data-name="NavbarHelp"
      />
    )

    const GeneralItems = this.shouldShowGeneralNavbarItems && [
      Academy,
      WalkThrough,
      Help,
      <HorizontalRule class={`${baseClass}__separator`} />,
      <CollapsibleNavbarItem
        triggerClass={`${navbarItemClass}__popover-target`}
        onTriggerHover={() => (this.title = c('navbar.account'))}
        isExpandedByDefault={
          this.routeName === ROUTES.PROFILE.name ||
          Boolean(
            (getVariation('no-credits') || getVariation('credits-on-checkout-only')) &&
              this.routeName === ROUTES.CREDITS.name,
          )
        }
        data-name="CollapsibleNavbarItemTrigger"
      >
        {{
          title: () => <LoggedInInlineUser />,
          item: () => (
            <>
              {(getVariation('no-credits') || getVariation('credits-on-checkout-only')) && (
                <NavbarItem
                  class={`${navbarItemClass}__popover-target`}
                  icon={creditsSVG}
                  to={ROUTES.CREDITS.name}
                  title={c('navbar.usage')}
                  data-name="NavbarUsage"
                  onClick={this.close}
                  onMouseenter={this.createPopoverSetter(c('navbar.usage'))}
                />
              )}
              <NavbarItem
                class={`${navbarItemClass}__popover-target`}
                icon={userSVG}
                to={ROUTES.PROFILE.name}
                query={this.$route.query}
                title={COPY.NAVIGATION.PROFILE}
                data-name="NavbarSettings"
                onClick={this.close}
                onMouseenter={this.createPopoverSetter(COPY.NAVIGATION.PROFILE)}
              />
              <NavbarItem
                class={`${navbarItemClass}__popover-target`}
                icon={signOutSVG}
                title={COPY.NAVIGATION.LOGOUT}
                data-name="NavbarLogout"
                onClick={() => emit(this, 'logout')}
                onMouseenter={this.createPopoverSetter(COPY.NAVIGATION.LOGOUT)}
              />
            </>
          ),
        }}
      </CollapsibleNavbarItem>,
    ]

    const items = (() => {
      if (!this.showAppButtons)
        return [
          Home,
          HomeLarge,
          <NavbarItem
            class={`${navbarItemClass}__popover-target`}
            icon={loginSVG}
            title={COPY.NAVIGATION.LOGIN}
            data-name="NavbarLogin"
            to={ROUTES.LOGIN.name}
            query={{ ...this.$route.query, redirect: this.$route.fullPath }}
            onMouseenter={this.createPopoverSetter(COPY.NAVIGATION.LOGIN)}
          />,
          <NavbarItem
            class={`${navbarItemClass}__popover-target`}
            icon={registerSVG}
            title={COPY.NAVIGATION.REGISTER}
            data-name="NavbarRegister"
            to={ROUTES.REGISTER.name}
            query={{ ...this.$route.query, redirect: this.$route.fullPath }}
            onMouseenter={this.createPopoverSetter(COPY.NAVIGATION.REGISTER)}
          />,
          toBottom,
          Academy,
          WalkThrough,
          Help,
        ]

      return [
        Home,
        HomeLarge,
        <NavbarItemsPane
          ref="panel"
          routeName={this.routeName}
          itemClass={`${navbarItemClass}__popover-target`}
          onItemHover={title => (this.title = title)}
          onItemClick={this.close}
        />,
        toBottom,
        GeneralItems,
      ]
    })()

    return (
      <nav
        class={{
          [baseClass]: true,
          [`${baseClass}--fullsize`]: this.alwaysShowFullNavbar,
        }}
        role="navigation"
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          class={`${baseClass}__overlay`}
          onClick={this.close}
          onKeyup={(event: KeyboardEvent) => event.code === 'Escape' && this.close()}
        />
        <NavbarPopover
          key={this.$route.name ?? undefined}
          targetClass={`.${navbarItemClass}__popover-target`}
        >
          {this.title}
        </NavbarPopover>
        <div class={`${baseClass}__bar`}>
          <div class={`${baseClass}__bar-items`}>{items}</div>
          <FormButton
            elementTag="div"
            role="button"
            tabindex={0}
            variant="primary"
            class={`${baseClass}__close`}
            ref="close"
            onClick={this.close}
            onKeydown={e => {
              if (e.code === 'Space' || e.code === 'Enter') {
                this.close()
              }
            }}
            data-name="NavbarClose"
          >
            <SvgIcon class={`${baseClass}__close-icon`} svg={closeCircleSVG} />
          </FormButton>
        </div>
      </nav>
    )
  },
})
</script>

<style lang="postcss">
.c-navbar {
  width: 100%;
  height: 100%;

  @media (--attest-media-s) {
    width: auto;
  }

  &__separator {
    width: auto;
    margin: 0 var(--attest-spacing-4);
    opacity: 0.1;

    @media (--attest-media-xl) {
      margin: 0 var(--attest-spacing-6);
    }
  }

  &__bar {
    position: relative;
    width: var(--navbar-width);
    height: 100%;
    border-right: 1px solid var(--attest-color-border-subdued);
    background: var(--attest-color-surface-default);
    color: var(--attest-color-text-default);
    pointer-events: all;

    @media (--attest-media-s) {
      width: var(--navbar-width-small);
    }

    @media (--attest-media-xl) {
      overflow: hidden;
      width: var(--navbar-width);
    }
  }

  &__bar-items {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 0;
    container-type: inline-size;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 65%);

    @media (--attest-media-s) {
      display: none;
      pointer-events: none;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    left: var(--navbar-width);
    width: var(--topbar-height);
    height: var(--topbar-height);
    align-items: center;
    justify-content: center;

    @media (--attest-media-s) {
      display: none;
      pointer-events: none;
    }

    @context scoped {
      padding: 0;
      border-radius: 0;
    }
  }

  &__to-bottom {
    margin-top: auto;
    margin-right: var(--attest-spacing-2);
    margin-left: var(--attest-spacing-2);
  }

  &__divider {
    border-top: var(--attest-border-width-s) var(--attest-color-background-inverse) solid;
    margin: var(--attest-spacing-4) var(--attest-spacing-2);
  }

  &__item-logo,
  &__item-logo-large {
    height: var(--attest-spacing-10);
    margin-top: var(--attest-spacing-7);
    margin-bottom: var(--attest-spacing-7);

    & svg {
      color: hsla(16deg 93% 57% / 100%) !important;
    }

    &,
    &.is-active {
      &::before {
        display: none;
      }
    }
  }

  &__item-logo-large {
    display: none;

    @container (width > 100px) {
      display: flex;
    }

    &:hover {
      background-color: unset;
    }
  }

  &__item-logo {
    display: flex;
    justify-content: center;

    @container (width > 100px) {
      display: none;
    }
  }

  &__item {
    &--walkthrough {
      display: none;

      @media (--attest-media-s) {
        display: flex;
      }
    }
  }
}
</style>
