import { service } from '@attest/_api'
import type { SavedFilter } from '@attest/segments'

import type { SavedFilterApiResponse } from './model'
import { transformSavedFiltersResponse, transformSavedFilterToRequest } from './transformer'

export const RequestUrl = {
  GET_SEGMENTS: (studyGuid: string): string => `/api/results/${studyGuid}/segments`,
  CREATE_SEGMENT: (studyGuid: string): string => `/api/results/${studyGuid}/segments`,
  DELETE_SEGMENT: (studyGuid: string, segmentGuid: string): string =>
    `/api/results/${studyGuid}/segments/${segmentGuid}`,
  UPDATE_SEGMENT: (studyGuid: string, segmentGuid: string): string =>
    `/api/results/${studyGuid}/segments/${segmentGuid}`,
}

export async function getSavedFilters(studyGuid: string): Promise<SavedFilter[]> {
  const response = await service().get<SavedFilterApiResponse[]>(RequestUrl.GET_SEGMENTS(studyGuid))

  return transformSavedFiltersResponse(response.data)
}

export async function updateFilter(
  studyGuid: string,
  filterGuid: string,
  filter: SavedFilter,
): Promise<void> {
  await service().put<SavedFilterApiResponse>(
    RequestUrl.UPDATE_SEGMENT(studyGuid, filterGuid),
    transformSavedFilterToRequest(filter),
  )
}

export async function deleteFilter(studyGuid: string, filterGuid: string): Promise<void> {
  await service().delete<SavedFilterApiResponse>(RequestUrl.DELETE_SEGMENT(studyGuid, filterGuid))
}

export async function createFilter(studyGuid: string, filter: SavedFilter): Promise<void> {
  await service().post<SavedFilterApiResponse>(
    RequestUrl.CREATE_SEGMENT(studyGuid),
    transformSavedFilterToRequest(filter),
  )
}
