import type { FunctionDirective, ObjectDirective } from 'vue'

import { isChildOrSelf } from '@attest/dom'

const HANDLER = 'DIRECTIVE_FOCUS_OUT'
type Handler = HTMLElement & { DIRECTIVE_FOCUS_OUT: (e: any) => void }
type BindingValue = (isInFocus: boolean) => void

const bind: FunctionDirective<HTMLElement, BindingValue> = (el, binding) => {
  const value: BindingValue = binding.value

  const elBinding = el as Handler
  el.removeEventListener('focusout', elBinding[HANDLER])
  elBinding[HANDLER] = (e: FocusEvent) => {
    e.preventDefault()
    const target = e.relatedTarget as HTMLElement
    const isInFocus = isChildOrSelf(el, target)
    if (isInFocus) return
    value(isInFocus)
  }

  el.addEventListener('focusout', elBinding[HANDLER])
}

export const focusOut: ObjectDirective<HTMLElement, BindingValue> = {
  beforeMount: bind,

  updated(el, binding, vnode, oldVnode) {
    if (binding.value === binding.oldValue) return
    bind(el, binding, vnode, oldVnode)
  },

  unmounted(el) {
    const elBinding = el as Handler

    el.removeEventListener('focusout', elBinding[HANDLER])
  },
}
