import { defineStore } from 'pinia'

import { type ApiError, type InternalError, isAppError } from '@attest/_api'

import { get } from '../api'
import {
  type CategorisedDemographics,
  type Country,
  type CountryLanguages,
  type DefaultOption,
  type Demographic,
  type DemographicDisplayValues,
  isDefaultOption,
  isDefaultOrRange,
  type Language,
} from '../model'
import {
  getCategorisedDemographics,
  getCountryLanguageKey,
  getDisplayForRangeType,
  getKeyForRangeType,
  getSupportedDemographicsMap,
} from '../service'

export type DemographicsState = {
  countries: Country[]
  languages: Language[]
  countryLanguages: CountryLanguages
  demographics: Demographic[]
}

export const DEMOGRAPHICS_STORE_NAMESPACE = 'demographics'

export function createDemographicsState(
  override: Partial<DemographicsState> = {},
): DemographicsState {
  return {
    countries: [],
    languages: [],
    countryLanguages: {},
    demographics: [],
    ...override,
  }
}

export const useDemographicsStore = defineStore(DEMOGRAPHICS_STORE_NAMESPACE, {
  state: createDemographicsState,
  actions: {
    set(state: Partial<DemographicsState>): void {
      Object.assign(this.$state, state)
    },

    async getDemographics(): Promise<InternalError<ApiError> | void> {
      if (
        this.countries.length > 0 &&
        this.languages.length > 0 &&
        this.demographics.length > 0 &&
        Object.keys(this.countryLanguages).length > 0
      ) {
        return
      }

      const response = await get()
      if (isAppError(response)) return response
      this.set(response)
    },
  },

  getters: {
    demographicNameToDemographic(): Record<string, Demographic> {
      return Object.fromEntries(
        this.demographics.map(demographic => [demographic.name, demographic]),
      )
    },

    getCountryDisplay(): (name: string | null) => string | null {
      return name => this.countries.find(country => country.name === name)?.display ?? null
    },

    getLanguageDisplay(): (name: string | null) => string | null {
      return name => this.languages.find(language => language.name === name)?.display ?? null
    },

    getDemographicDisplayValues(): DemographicDisplayValues {
      const demographicDisplayValues: DemographicDisplayValues = {}

      this.demographics.forEach(demographic => {
        const display = demographic.display

        const values: DefaultOption[] = demographic.options.filter(isDefaultOrRange).map(option => {
          if (isDefaultOption(option)) return option

          return {
            name: getKeyForRangeType(option),
            display: getDisplayForRangeType(option),
          }
        })

        demographicDisplayValues[demographic.name] = {
          display,
          values,
          order: demographic.order,
          type: demographic.type,
        }
      })

      return demographicDisplayValues
    },

    getLanguagesForCountry(): (countryName: string) => Language[] {
      return countryName => {
        const countryMatch = this.countries.find(country => country.name === countryName)
        if (!countryMatch) {
          return []
        }

        const languageKeys = countryMatch.supportedLanguages.filter(key => {
          const countryLanguageKey = getCountryLanguageKey(countryName, key, this.countryLanguages)
          if (!countryLanguageKey) return false
          return this.countryLanguages[countryLanguageKey].targetingStatus === 'active'
        })
        const languages = languageKeys.map(key => this.languages.find(item => key === item.name))
        return languages.filter(language => language !== undefined) as Language[]
      }
    },

    getDemographicsByCategoryAndOrder(): CategorisedDemographics {
      return getCategorisedDemographics(this.demographics)
    },

    getCountriesByNames(): (names: string[]) => Country[] {
      return names => {
        const nameSet = new Set(names)
        return useDemographicsStore().countries.filter(({ name }) => nameSet.has(name))
      }
    },

    getSupportedDemographics(): (country: string, language: string) => Record<string, Demographic> {
      return (country, language) => {
        const demographicStore = useDemographicsStore()
        const key = getCountryLanguageKey(country, language, demographicStore.countryLanguages)
        return getSupportedDemographicsMap(key, demographicStore.demographics)
      }
    },

    getUnsupportedDemographicKeys(): (
      country: string,
      language: string,
      demographicKeys: string[],
    ) => string[] {
      return (country, language, demographicKeys) => {
        const key = getCountryLanguageKey(country, language, this.countryLanguages)
        const supportedDemographics = getSupportedDemographicsMap(key, this.demographics)
        return demographicKeys.filter(demographic => !supportedDemographics[demographic])
      }
    },
  },
})
