<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create } from '@attest/vue-tsx'

export type UserAvatarProps = {
  theme?: `neutral` | 'yellow' | 'coral' | 'firecracker' | 'green' | 'berry'
  user: { firstName?: string; lastName?: string; email?: string }
}

function getFirstLetter(from?: string) {
  return (from && from.trim() && from.trim()[0]?.toLocaleUpperCase()) || ''
}

function getEmailInitials(email?: string) {
  if (!email) return ''

  return (
    email
      .split('@')[0]
      ?.split(/[^A-Za-z]/)
      .filter(value => value)
      .map(getFirstLetter)
      .slice(0, 2)
      .join('') || getFirstLetter(email)
  )
}

const UserAvatar: FunctionalComponent<UserAvatarProps> = props => {
  const { user, theme = 'neutral', ...attrs } = props

  const { email, firstName, lastName } = user
  const baseClass = 'c-user-avatar'
  const userAvatarClass = {
    [baseClass]: true,
    [`${baseClass}--theme-${theme}`]: true,
  }

  const initials = firstName
    ? `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`
    : getEmailInitials(email)

  return (
    <div class={userAvatarClass} {...attrs}>
      <div class={`${baseClass}__initials`}>{initials}</div>
    </div>
  )
}

export default create<UserAvatarProps>(UserAvatar)
</script>
<style lang="postcss">
.c-user-avatar {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--custom-background-color);
  color: var(--custom-color);
  font-size: var(--attest-font-size-s);
  font-weight: var(--attest-font-weight-bold);
  line-height: var(--attest-line-height-s);

  &,
  &--theme-neutral {
    --custom-background-color: var(--attest-color-decorative-grey-surface-subdued);
    --custom-color: var(--attest-color-decorative-grey-text);
  }

  &--theme-yellow {
    --custom-background-color: var(--attest-color-decorative-yellow-surface-subdued);
    --custom-color: var(--attest-color-decorative-yellow-text);
  }

  &--theme-berry {
    --custom-background-color: var(--attest-color-decorative-berry-surface-subdued);
    --custom-color: var(--attest-color-decorative-berry-text);
  }

  &--theme-coral {
    --custom-background-color: var(--attest-color-decorative-coral-surface-subdued);
    --custom-color: var(--attest-color-decorative-coral-text);
  }

  &--theme-green {
    --custom-background-color: var(--attest-color-decorative-green-surface-subdued);
    --custom-color: var(--attest-color-decorative-green-text);
  }
}
</style>
