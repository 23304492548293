import { trackingManager } from '..'
import type { Entity } from '../entity'
import type { EntityEvent } from '../property'

import type { EventData, EventPayload } from './reference'

export type ActionEvent<E extends Entity[] = Entity[]> = {} & EntityEvent<'action', EventData, E>

type ActionPayload<E extends ActionEvent> = {} & EventPayload<E>

export function trackAction<E extends ActionEvent>(payload: ActionPayload<E>): void {
  const { id, name, entities, event, tracers = [] } = payload
  trackingManager.trigger({
    type: 'action',
    payload: { id, name },
    entities,
    event,
    tracers,
  })
}
