import type {
  CardGroupedPipingError,
  CardPipingError,
} from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import {
  type Card,
  type CardGuid,
  isQuestionCard,
  isQuestionCardOrUninitialized,
} from '@attest/editor-card'
import {
  getAllIntersectingNodesTo,
  isCardFollowedBy,
  type RoutingGraph,
} from '@attest/editor-routing-graph'
import { SETTINGS } from '@attest/editor-settings'

import { findCardByGuid } from '../card-service'
import { getLongestTitleLengthWithPipedAnswers, parseShortcodes } from '../piping-util'

import { getCardTitleErrors } from './card-title'

export const getCardPipingErrors = createGetErrorsFor<
  CardPipingError['type'],
  {
    card: Card
    cards: Card[]
    pipingFrom: (CardGuid | undefined)[]
    routingGraph: RoutingGraph
    randomizedGroupGraph: RoutingGraph
  }
>({
  CARD_PIPING_FROM_INVALID_QUESTION_TYPE({ pipingFrom, cards }) {
    return pipingFrom
      .flatMap(guid => {
        const card = guid ? findCardByGuid(guid, cards) : null
        return card ? [card] : []
      })
      .map(({ question: { type } }) => type)
      .filter(questionType => questionType !== null)
      .some(questionType => questionType !== 'single_choice')
  },

  CARD_PIPING_TITLE_TOO_LONG({ card, pipingFrom, cards }) {
    if (!isQuestionCardOrUninitialized(card)) return false
    if (getCardTitleErrors.CARD_TITLE_TOO_LONG({ card })) return false

    return (
      getLongestTitleLengthWithPipedAnswers(card.title, cards, pipingFrom) >
      SETTINGS.QUESTION.MAX_TITLE_LENGTH
    )
  },

  CARD_PIPING_FROM_FUTURE_QUESTION({ card, pipingFrom, routingGraph }) {
    if (!isQuestionCardOrUninitialized(card)) return false
    if (pipingFrom.length === 0) return false

    return pipingFrom.some(pipingFromGuid =>
      isCardFollowedBy(routingGraph, card.guid, pipingFromGuid),
    )
  },

  CARD_PIPING_FROM_SELF({ card, pipingFrom }) {
    if (!isQuestionCardOrUninitialized(card)) return false

    return pipingFrom.includes(card.guid)
  },

  CARD_PIPING_FROM_DIFFERENT_ROUTE({ card, pipingFrom, routingGraph }) {
    if (!isQuestionCardOrUninitialized(card)) return false

    const pipingFromGuids = pipingFrom.filter((guid): guid is string => typeof guid === 'string')
    if (pipingFromGuids.length === 0) return false

    const intersectingNodes = getAllIntersectingNodesTo(routingGraph, card.guid)
    return pipingFromGuids.some(
      guid => !intersectingNodes.includes(guid) && !isCardFollowedBy(routingGraph, card.guid, guid),
    )
  },

  CARD_PIPING_FROM_NON_EXISTENT_QUESTION({ pipingFrom }) {
    return pipingFrom.includes(undefined)
  },

  CARD_PIPING_FROM_RANDOMIZED_QUESTION({ card, cards, pipingFrom }) {
    if (!isQuestionCardOrUninitialized(card)) return false
    return pipingFrom
      .map(pipingFromGuid => (pipingFromGuid ? findCardByGuid(pipingFromGuid, cards) : null))
      .some(pipingFromCard => pipingFromCard?.isRandomized)
  },

  CARD_PIPING_FROM_QUESTION_BETWEEN_RANDOMIZED_CARDS({
    card,
    cards,
    pipingFrom,
    randomizedGroupGraph,
  }) {
    if (!isQuestionCardOrUninitialized(card)) return false
    return pipingFrom
      .map(pipingFromGuid => (pipingFromGuid ? findCardByGuid(pipingFromGuid, cards) : undefined))
      .some(
        pipingFromCard =>
          !pipingFromCard?.isRandomized && randomizedGroupGraph.hasNode(pipingFromCard?.guid),
      )
  },

  CARD_PIPING_TO_RANDOMIZED_QUESTION({ card, pipingFrom }) {
    if (!isQuestionCard(card)) return false
    return card.isRandomized && pipingFrom.length > 0
  },

  CARD_PIPING_FROM_QUESTION_WITH_EMPTY_ANSWER_TEXT({ cards, pipingFrom }) {
    return pipingFrom
      .map(pipingFromGuid => (pipingFromGuid ? findCardByGuid(pipingFromGuid, cards) : undefined))
      .some(
        pipingFromCard =>
          pipingFromCard?.question.type &&
          pipingFromCard?.question.options.answers.some(a => !a.text),
      )
  },
})

export const getCardGroupedPipingErrors = createGetErrorsFor<
  CardGroupedPipingError['type'],
  { card: Card }
>({
  CARD_IS_PIPING_AND_GROUPED({ card }) {
    return parseShortcodes(card.title).length > 0
  },
})
