import { defineStore } from 'pinia'
import { ref } from 'vue'

import { createStudyInsightsChartExportEntity } from '@attest/results-core'
import { createStudyCardEntitiesFromCard, type StudyQuestionCard } from '@attest/study'
import { trackAction } from '@attest/tracking'

export type ChartMethods = {
  exportChart: (title: string) => void
  exportCopy: () => void
}

export const useChartMethodsStore = defineStore('chartMethods', () => {
  const cardIdToChartMethods = ref<Record<string, ChartMethods>>({})

  function exportChartForCard(card: StudyQuestionCard | undefined, event: Event): void {
    if (!card) return
    if (!cardIdToChartMethods.value[card.id]) {
      return
    }
    cardIdToChartMethods.value[card.id].exportChart(card.title)

    trackAction({
      id: 'download-chart',
      event: event,
      entities: [...createStudyCardEntitiesFromCard(card), createStudyInsightsChartExportEntity()],
    })
  }

  function exportCopyForCard(cardId: string): void {
    if (!cardIdToChartMethods.value[cardId]) {
      return
    }
    cardIdToChartMethods.value[cardId].exportCopy()
  }

  function setExportMethodsForCard(cardId: string, methods: ChartMethods): void {
    if (cardIdToChartMethods.value[cardId]) return
    cardIdToChartMethods.value[cardId] = methods
  }

  function $reset(): void {
    cardIdToChartMethods.value = {}
  }

  return {
    $reset,
    cardIdToChartMethods,
    exportChartForCard,
    exportCopyForCard,
    setExportMethodsForCard,
  }
})
