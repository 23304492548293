<script lang="tsx">
import { nanoid } from 'nanoid'
import { defineComponent, type DefineComponent, type PropType } from 'vue'

import chevronDownSVG from '@attest/_assets/icons/keyboard_arrow_down.svg'
import { c } from '@attest/intl'
import { levenshteinTest } from '@attest/util'
import { castSlots, renderSlotOrView } from '@attest/vue-tsx'

import { RenderedMarkdown } from '../RenderedMarkdown'
import { SvgIcon } from '../SvgIcon'
import { TextInput } from '../TextInput'

import type { DropdownItem, DropdownSlots } from './Dropdown.interface'

export default defineComponent({
  props: {
    selectedItemId: { type: String, default: undefined },
    hasSearch: { type: Boolean, default: false },
    placeholderText: { type: String, default: '' },
    listboxLabelledBy: { type: String, default: '' },
    items: { type: Array as PropType<DropdownItem[]>, required: true },
    widthVariant: { type: String, default: 'default' },
    isDisabled: { type: Boolean, default: false },
    makeSelectedItemText: {
      type: Function as PropType<(item: DropdownItem) => string>,
      default: undefined,
    },
  },

  emits: {
    selectItem: (_payload: { id: string; event: MouseEvent | KeyboardEvent }) => true,
    open: (_payload: { event: MouseEvent | KeyboardEvent }) => true,
  },

  data() {
    return {
      isOpen: false,
      searchString: '',
      localSelectedItemId: undefined as string | undefined,
    }
  },

  computed: {
    dropdownId(): string {
      return nanoid()
    },

    selectedItemText(): string {
      const selectedItem = this.items.find(({ id }) => id === this.selectedItemId)
      return selectedItem !== undefined
        ? (this.makeSelectedItemText?.(selectedItem) ?? selectedItem.text)
        : this.placeholderText
    },

    filteredItems(): DropdownItem[] {
      if (!this.hasSearch) return this.items
      return this.items.filter(({ text }) =>
        levenshteinTest(this.searchString.toLowerCase(), text.toLowerCase()),
      )
    },
  },

  methods: {
    async openDropdown(event: MouseEvent | KeyboardEvent): Promise<void> {
      this.isOpen = !this.isOpen
      if (!this.isOpen) return
      this.searchString = ''
      this.localSelectedItemId = this.selectedItemId

      if (this.hasSearch) {
        this.focusSearchInputField()
        return
      }
      this.focusItemList()
      this.$emit('open', { event })
    },

    closeDropdown(): void {
      this.isOpen = false
    },

    selectItem(event: MouseEvent | KeyboardEvent): void {
      if (this.localSelectedItemId === undefined) return
      this.$emit('selectItem', { id: this.localSelectedItemId, event })
    },

    handleClickItem(id: string, event: MouseEvent): void {
      this.localSelectedItemId = id
      this.selectItem(event)
      this.closeDropdown()
    },

    handleButtonKeyup(e: KeyboardEvent): void {
      if (!['ArrowUp', 'ArrowDown', 'Up', 'Down'].includes(e.key)) return
      e.preventDefault()
      this.openDropdown(e)
      this.handleListboxKeydown(e)
    },

    async handleListboxKeydown(e: KeyboardEvent): Promise<void> {
      if (e.key === 'Enter' || e.key === 'Escape') {
        e.preventDefault()
        this.selectItem(e)
        this.closeDropdown()
        await this.$nextTick()
        ;(this.$refs.button as HTMLElement)?.focus()
        return
      }

      if (this.filteredItems.length === 0) return

      switch (e.key) {
        case 'ArrowUp':
        case 'Up':
          this.handleListboxKeydownArrowUp(e)
          break
        case 'ArrowDown':
        case 'Down':
          this.handleListboxKeydownArrowDown(e)
          break
        case 'Home':
          this.localSelectedItemId = this.filteredItems[0]?.id
          this.selectItem(e)
          break
        case 'End':
          this.localSelectedItemId = this.filteredItems[this.filteredItems.length - 1]?.id
          this.selectItem(e)
          break
      }
    },

    handleListboxKeydownArrowUp(e: KeyboardEvent): void {
      e.preventDefault()
      const currentIndex = this.filteredItems.findIndex(
        item => item.id === this.localSelectedItemId,
      )
      if (currentIndex > 0) this.localSelectedItemId = this.filteredItems[currentIndex - 1]?.id
    },

    handleListboxKeydownArrowDown(e: KeyboardEvent): void {
      e.preventDefault()
      const currentIndex = this.filteredItems.findIndex(
        item => item.id === this.localSelectedItemId,
      )
      if (currentIndex < this.filteredItems.length - 1)
        this.localSelectedItemId = this.filteredItems[currentIndex + 1]?.id
    },

    handleListboxBlur(e: FocusEvent): void {
      if (
        e.relatedTarget instanceof HTMLElement &&
        (this.$refs.button === e.relatedTarget ||
          (this.$refs.list instanceof HTMLElement && this.$refs.list?.contains(e.relatedTarget)) ||
          (this.$refs.search instanceof HTMLElement &&
            this.$refs.search?.contains(e.relatedTarget)))
      )
        return
      this.closeDropdown()
    },

    async focusItemList(): Promise<void> {
      await this.$nextTick()
      ;(this.$refs.list as HTMLElement)?.focus()
    },

    async focusSearchInputField(): Promise<void> {
      await this.$nextTick()
      const searchInput = (this.$refs.search as DefineComponent)?.$el?.querySelector(
        `input`,
      ) as HTMLElement
      searchInput?.focus()
    },
  },

  render() {
    const baseClass = 'c-dropdown'
    const slots = castSlots<DropdownSlots>(this.$slots)

    return (
      <div
        class={{
          [baseClass]: true,
          [`${baseClass}--open`]: this.isOpen,
        }}
      >
        {renderSlotOrView(slots.button, {
          createView: data => {
            return (
              <button
                ref="button"
                type="button"
                aria-haspopup="listbox"
                aria-labelledby={`${this.listboxLabelledBy} ${this.dropdownId}-button`}
                aria-expanded={this.isOpen}
                data-name="DropdownButton"
                disabled={this.isDisabled}
                onKeyup={this.handleButtonKeyup}
                onClick={this.openDropdown}
                id={`${this.dropdownId}-button`}
                class={['o-outline', `${baseClass}__button`]}
                {...data}
              >
                <div
                  class={{
                    [`${baseClass}__button-content`]: true,
                    [`${baseClass}__button-content--placeholder`]: !this.selectedItemId,
                  }}
                  data-name="DropdownButtonLabel"
                >
                  {slots.item
                    ? slots.item({
                        id: this.selectedItemId || '',
                        text: this.selectedItemText ?? this.placeholderText,
                        isSelected: true,
                      })
                    : this.selectedItemText}
                </div>
                {renderSlotOrView(slots.buttonPostfix, {
                  createView: (postfixData, buttonPostfixSlots) => (
                    <SvgIcon
                      size="s"
                      class={`${baseClass}__button-arrow`}
                      svg={chevronDownSVG}
                      v-slots={buttonPostfixSlots}
                      {...postfixData}
                    />
                  ),
                })}
              </button>
            )
          },
          isOpen: this.isOpen,
        })}
        {this.isOpen && (
          <div
            class={{
              [`${baseClass}__wrapper`]: true,
              [`${baseClass}__wrapper--width-${this.widthVariant}`]: true,
            }}
          >
            <ul
              tabindex={-1}
              ref="list"
              role="listbox"
              aria-labelledby={this.listboxLabelledBy}
              aria-activedescendant={this.selectedItemId}
              class={`${baseClass}__wrapper-list`}
              data-name="DropdownList"
              onBlur={this.handleListboxBlur}
              onKeydown={this.handleListboxKeydown}
            >
              {this.hasSearch && (
                <div class={`${baseClass}__search`}>
                  <TextInput
                    ref="search"
                    sizeVariant="s"
                    placeholder="Search"
                    value={this.searchString}
                    onValueChange={value => (this.searchString = value)}
                    onKeydown={event => {
                      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') this.focusItemList()
                    }}
                    onBlur={this.handleListboxBlur}
                    data-name="DropdownSearchInput"
                  />
                </div>
              )}
              {this.filteredItems.map(({ id, text, secondaryText, category }) => [
                slots.prefix?.({
                  id,
                  text,
                  category,
                  filteredItems: this.filteredItems,
                }),
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                  key={id}
                  role="option"
                  class={{
                    [`${baseClass}__wrapper-list-option`]: true,
                    [`${baseClass}__wrapper-list-option--selected`]:
                      this.localSelectedItemId === id,
                  }}
                  aria-selected={this.localSelectedItemId === id}
                  onClick={event => this.handleClickItem(id, event)}
                  data-name={`DropdownItem-${id}`}
                >
                  {slots.item ? (
                    slots.item({
                      id,
                      text,
                      isSelected: this.selectedItemId === id,
                      secondaryText,
                    })
                  ) : (
                    <>
                      <RenderedMarkdown class={`${baseClass}__text`} text={text} />
                      {secondaryText && (
                        <p class={`${baseClass}__secondary-text`}>{secondaryText}</p>
                      )}
                    </>
                  )}
                </li>,
              ])}
            </ul>
            <span aria-live="polite" class="u-screen-reader-only">
              {this.filteredItems.length} {c('dropdown.aria.options.found')}
            </span>
          </div>
        )}
      </div>
    )
  },
})
</script>

<style lang="postcss">
.c-dropdown {
  position: relative;
  box-sizing: border-box;

  &__button {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 38px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 var(--attest-spacing-2);
    border: var(--attest-border-width-s) solid var(--attest-color-border-subdued);
    border-radius: var(--attest-border-radius);
    background-color: var(--attest-color-surface-default);
    text-align: left;
    transition: background var(--attest-timing-fast) var(--attest-ease);

    &:disabled {
      cursor: no-drop;
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        border-color: var(--attest-color-border-hover);
      }
    }
  }

  &__button-content {
    display: flex;
    overflow: hidden;
    width: 100%;
    min-width: 0;
    flex: 1 1 auto;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--placeholder {
      color: var(--attest-color-text-subdued);
    }
  }

  @context scoped {
    &__button-arrow {
      flex: 0 0 auto;
      margin-left: var(--attest-spacing-2);
    }
  }

  &__wrapper {
    position: absolute;
    z-index: var(--z-index-dropdown);
    top: calc(100% + var(--attest-spacing-1));
    right: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    border-radius: var(--attest-border-radius-l);
    background-color: var(--attest-color-surface-default);
    box-shadow: var(--attest-shadow-popover);
  }

  &__wrapper-list {
    width: 100%;
    max-height: 13.2rem;
    padding: 0;
    margin-top: var(--attest-spacing-2);
    list-style: none;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__wrapper--width-wide {
    width: 200%;
    box-shadow: var(--attest-shadow-popover);
  }

  &__wrapper--width-fixed-wide {
    width: calc(var(--attest-spacing-2) * 40);
  }

  &__wrapper-list-option {
    position: relative;
    width: 100%;
    align-items: center;
    padding: var(--attest-spacing-2);
    border: var(--attest-border-width-s) solid var(--attest-color-surface-default);
    background-color: var(--attest-color-surface-default);
    cursor: default;
    font: var(--attest-font-body-2);

    &:hover:not(:active) {
      border-color: var(--attest-color-interactive-subdued-hover);
      background-color: var(--attest-color-interactive-subdued-hover);
    }

    &:active {
      border-color: var(--attest-color-interactive-subdued-hover);
      border-radius: var(--attest-border-radius);
      background-color: var(--attest-color-surface-default);
    }
  }

  &__wrapper-list-option--selected {
    border-color: var(--attest-color-surface-subdued);
    background-color: var(--attest-color-surface-subdued);
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__secondary-text {
    display: block;
    color: var(--attest-color-text-subdued);
    font-size: var(--attest-font-size-s);
  }

  &__search {
    padding: var(--attest-spacing-2);
  }
}
</style>
