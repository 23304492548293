import axios from 'axios'
import { defineStore } from 'pinia'

import { capture } from '@attest/telemetry'

import type { AppAutoUpdate } from './model'

const APP_AUTO_UPDATE = 'appAutoUpdate'

function createAppAutoUpdateState(override: Partial<AppAutoUpdate> = {}): AppAutoUpdate {
  return {
    timestamp: undefined,
    hash: undefined,
    ...override,
  }
}

export const useAppAutoUpdateStore = defineStore(APP_AUTO_UPDATE, {
  state: createAppAutoUpdateState,
  actions: {
    async getLatestVersion(): Promise<[AppAutoUpdate, AppAutoUpdate]> {
      const current = { ...this.$state }
      try {
        const { data } = await axios.get<AppAutoUpdate>('/version/app')
        Object.assign(this, data)
        return [data, current]
      } catch (error) {
        capture(error)
        return [current, current]
      }
    },
  },
})
