import type { RangeOption } from '../model'

export function getKeyForRangeType(option: RangeOption): string {
  return `${option.min}_${option.max}`
}

export function getDisplayForRangeType(option: RangeOption): string {
  return `${option.min} - ${option.max}`
}

export function getRangeTypeFromKey(key: string): RangeOption {
  const values = key.split('_')
  return { min: Number.parseInt(values[0]), max: Number.parseInt(values[1]) }
}

export function getDisplayFromKeyForRangeType(key: string): string {
  const rangeType = getRangeTypeFromKey(key)
  return getDisplayForRangeType(rangeType)
}
