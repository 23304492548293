import type { NonEmptyObject } from 'ts-essentials'
import {
  type AllowedComponentProps,
  type AsyncComponentLoader,
  type AsyncComponentOptions,
  type ComponentCustomProps,
  type ComponentOptionsMixin,
  type ComputedOptions,
  defineAsyncComponent,
  type DefineComponent,
  type MethodOptions,
  type SlotsType,
  type VNode,
  type VNodeProps,
} from 'vue'

import type { ExtractEvents, OnEventHandlers } from './events'
import type { ExtractProps } from './props'

export const EMPTY_VNODE = null as unknown as VNode

export type TsxComponent<
  Props = {},
  Events = {},
  Slots extends Record<string, any> = Record<string, any>,
> = {} & DefineComponent<
  Props,
  {},
  {},
  ComputedOptions,
  MethodOptions,
  ComponentOptionsMixin,
  ComponentOptionsMixin,
  {},
  string,
  VNodeProps & AllowedComponentProps & ComponentCustomProps,
  Omit<Props, keyof OnEventHandlers<Events>> &
    OnEventHandlers<Events> &
    (NonEmptyObject<Slots> extends never ? {} : { 'v-slots'?: Slots }),
  {},
  SlotsType<Slots>
>

export function create<
  Props = unknown,
  Events = {},
  Slots extends Record<string, any> = Record<string, any>,
>(component: unknown): TsxComponent<Props, Events, Slots> {
  return component as TsxComponent<Props, Events, Slots>
}

type ComponentImport = TsxComponent | { default: TsxComponent }
type AsyncComponentImport<T extends () => Promise<ComponentImport>> = AsyncComponentNormalizer<
  Awaited<ReturnType<T>>
>
type AsyncComponentNormalizer<T extends ComponentImport> = T extends TsxComponent ? T : T['default']

export function createAsyncComponent<T extends AsyncComponentLoader>(
  options: Omit<AsyncComponentOptions, 'loader'> & {
    loader: T
  },
): AsyncComponentImport<T> {
  return defineAsyncComponent(options) as AsyncComponentImport<T>
}

export function asTSX<Slots extends SlotsType, T extends DefineComponent>(
  component: T,
): TsxComponent<ExtractProps<T>, ExtractEvents<T>, Slots> {
  return component as any
}
