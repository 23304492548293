import type { SavedFilter } from '@attest/segments'

import type { SavedFilterApiResponse } from './model'

export function transformSavedFiltersResponse(response: SavedFilterApiResponse[]): SavedFilter[] {
  return response.map<SavedFilter>(filterResponse => ({
    id: filterResponse.guid,
    name: filterResponse.name,
    efl: filterResponse.efl,
    demographicFilters: filterResponse.demographic_filters
      ? Object.fromEntries(
          filterResponse.demographic_filters?.map(entry => [entry.demographic_name, entry.values]),
        )
      : undefined,
    studyCardFilters: filterResponse.study_card_filters
      ? Object.fromEntries(
          filterResponse.study_card_filters.map(entry => [
            entry.study_card_guid,
            { answerIds: entry.choice_ids, responseIds: [], answerIdsToResponseIds: {} },
          ]),
        )
      : undefined,
    surveyFilters: filterResponse.survey_filters,
    isShared: filterResponse?.scope === 'ORG',
  }))
}

export function transformSavedFilterToRequest(filter: SavedFilter): SavedFilterApiResponse {
  return {
    guid: filter.id,
    name: filter.name,
    efl: filter.efl,
    survey_filters: [],
    study_card_filters: [],
    demographic_filters: [],
    scope: filter.isShared ? 'ORG' : undefined,
  }
}
