import {
  filterRespondentIdsByDemographics,
  useStudyInsightsStore,
  useStudyResultsStore,
} from '@attest/results-core'
import { getRespondentIdsForSegment } from '@attest/results-saved-filters'
import { type SentWaveSurvey, useStudyStore } from '@attest/study'
import { intersection, isDefined, union } from '@attest/util'

import type { ColumnDefinitionValue } from './model'

export function getDefinitionValueRespondentIds(
  definition: ColumnDefinitionValue,
): Set<string> | undefined {
  const { filteredSentWaveRespondentIds, shouldUsePanelFilters, filteredRespondentIds } =
    useStudyInsightsStore()
  const definitionRespondentIds = getDefinitionValueRespondentIdsFromDefinition(definition)
  if (!definitionRespondentIds) {
    return undefined
  }

  const panelRespondentIds = shouldUsePanelFilters ? filteredRespondentIds : undefined

  return intersection(
    [filteredSentWaveRespondentIds, definitionRespondentIds, panelRespondentIds].filter(isDefined),
  )
}

function getDefinitionValueRespondentIdsFromDefinition(
  definition: ColumnDefinitionValue,
): Set<string> | undefined {
  switch (definition.type) {
    case 'static':
      return getStaticDefinitionValueRespondentIds(definition)
    case 'date':
      return getDateDefinitionValueRespondentIds(definition)
    case 'question':
      return getQuestionDefinitionValueRespondentIds(definition)
    case 'demographic':
      return getDemographicDefinitionValueRespondentIds(definition)
    case 'age':
      return getAgeDefinitionValueRespondentIds(definition)
    case 'country':
      return getCountryDefinitionValueRespondentIds(definition)
    case 'audience':
      return getAudienceDefinitionValueRespondentIds(definition)
    case 'segment':
      return getSegmentDefinitionValueRespondentIds(definition)
    default:
      throw new Error('Definition type not supported')
  }
}

function getStaticDefinitionValueRespondentIds(definition: ColumnDefinitionValue): Set<string> {
  const studyInsightsStore = useStudyInsightsStore()

  if (definition.variable === 'total') {
    return intersection([
      studyInsightsStore.filteredSentWaveRespondentIds,
      studyInsightsStore.cardIdToAnsweredRespondentIds[definition.value] ?? new Set(),
    ])
  }

  return new Set()
}

function getDateDefinitionValueRespondentIds(definition: ColumnDefinitionValue): Set<string> {
  const studyInsightsStore = useStudyInsightsStore()
  return union(
    getSentWavesByPublishedTimestamp(Number.parseInt(definition.value)).map(
      wave => studyInsightsStore.waveSurveyIdToRespondentIds[wave.id],
    ),
  )
}

function getQuestionDefinitionValueRespondentIds(definition: ColumnDefinitionValue): Set<string> {
  const studyInsightsStore = useStudyInsightsStore()
  return union(
    studyInsightsStore
      .getMergedQuestionStructureByCardId(definition.variable)
      .options.filter(({ id }) => definition.value.split(',').includes(id))
      .flatMap(option =>
        option.answerIds.map(
          answerId =>
            studyInsightsStore.cardIdToResultsCard[definition.variable].answerIdToAnswer[answerId]
              .responseIds,
        ),
      ),
  )
}

function getDemographicDefinitionValueRespondentIds(
  definition: ColumnDefinitionValue,
): Set<string> {
  const studyResultsStore = useStudyResultsStore()
  return filterRespondentIdsByDemographics(
    studyResultsStore.respondentIds,
    studyResultsStore.respondentIdToRespondent,
    { [definition.variable]: [definition.value] },
  )
}

function getAgeDefinitionValueRespondentIds(definition: ColumnDefinitionValue): Set<string> {
  const studyResultsStore = useStudyResultsStore()
  const [min, max = Number.POSITIVE_INFINITY] = definition.value
    .split(',')
    .map(v => Number.parseInt(v))
  return filterRespondentIdsByDemographics(
    studyResultsStore.respondentIds,
    studyResultsStore.respondentIdToRespondent,
    { [definition.variable]: [{ min, max }] },
  )
}

function getCountryDefinitionValueRespondentIds(definition: ColumnDefinitionValue): Set<string> {
  const studyInsightsStore = useStudyInsightsStore()
  return union(
    getSentWavesByCountry(definition.value).map(
      wave => studyInsightsStore.waveSurveyIdToRespondentIds[wave.id],
    ),
  )
}

function getAudienceDefinitionValueRespondentIds(definition: ColumnDefinitionValue): Set<string> {
  const studyInsightsStore = useStudyInsightsStore()
  return union(
    getSentWavesByAudience(definition.value).map(
      wave => studyInsightsStore.waveSurveyIdToRespondentIds[wave.id],
    ),
  )
}

function getSegmentDefinitionValueRespondentIds(
  definition: ColumnDefinitionValue,
): Set<string> | undefined {
  return getRespondentIdsForSegment(definition.value)
}

function getSentWavesByPublishedTimestamp(timestamp: number): SentWaveSurvey[] {
  return useStudyStore().sentWaveSurveys.filter(
    ({ publishedTimestamp }) => publishedTimestamp === timestamp,
  )
}

function getSentWavesByCountry(country: string): SentWaveSurvey[] {
  return useStudyStore().sentWaveSurveys.filter(wave => wave.country === country)
}

function getSentWavesByAudience(audienceId: string): SentWaveSurvey[] {
  return useStudyStore().sentWaveSurveys.filter(wave => wave.audienceId === audienceId)
}
