export function replaceTransformers(
  copy: string,
  transformers: {
    [T in string]?: (copy: string) => any
  },
): string | any[] {
  if (!/(\[%\w+\(.+?\)])/.test(copy)) return copy

  return copy.split(/(\[%\w+\(.+?\)])/).map(s => {
    const groups = s.match(/\[%(?<key>\w+)\((?<matchedCopy>.+?)\)]/)?.groups
    if (!groups) return s
    const { matchedCopy, key } = groups
    if (!matchedCopy) throw new ReferenceError('Groups does not contain matchedCopy property')
    return transformers[key as string]?.(matchedCopy) ?? matchedCopy
  })
}
