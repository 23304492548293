import { type ApiError, type InternalError, service } from '@attest/_api'

import type {
  Country,
  CountryLanguages,
  Demographic,
  GetDemographicsResponse,
  Language,
} from '../model'
import { toCountry, toCountryLanguages, toDemographic, toLanguage } from '../transformer'

export const RequestUrl = {
  GET_DEMOGRAPHICS: '/api/demographics',
}

export async function get(): Promise<
  | InternalError<ApiError>
  | {
      countries: Country[]
      languages: Language[]
      countryLanguages: CountryLanguages
      demographics: Demographic[]
    }
> {
  const requestUrl = RequestUrl.GET_DEMOGRAPHICS
  const response = await service().get<GetDemographicsResponse>(requestUrl)
  const { data } = response

  return {
    countries: data.countries.map(toCountry),
    languages: data.languages.map(toLanguage),
    countryLanguages: toCountryLanguages(data.country_languages),
    demographics: data.demographics.map(toDemographic),
  }
}
