import { type CardEntity, createCardEntity, createQuestionEntity } from '@attest/tracking'

import { isStudyQuestionCard, type StudyCard, type StudyCardId } from './model'
import type { StudyQuestionEntity } from './question'

export type StudyCardEntity<D extends StudyCardEntityData = StudyCardEntityData> =
  {} & CardEntity<D>
type StudyCardEntityData = {
  id: StudyCardId
}

export function createStudyCardEntity(data: StudyCardEntityData): StudyCardEntity {
  return createCardEntity(data)
}

export function createStudyCardEntitiesFromCard(
  card: StudyCard,
): [StudyCardEntity, StudyQuestionEntity] | [] {
  if (isStudyQuestionCard(card)) {
    return [createStudyCardEntity({ id: card.id }), createQuestionEntity({ id: card.question.id })]
  }

  return []
}
