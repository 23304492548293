import { create } from '@attest/vue-tsx'

import type {
  CollapsibleNavbarItemEvents,
  CollapsibleNavbarItemProps,
} from './CollapsibleNavbarItem.interface'
import Component from './CollapsibleNavbarItem.vue'

export * from './CollapsibleNavbarItem.interface'
export const CollapsibleNavbarItem = create<
  CollapsibleNavbarItemProps,
  CollapsibleNavbarItemEvents
>(Component)
