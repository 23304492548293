import type { Logic } from '@attest/efl-antlr4'
import type { Range } from '@attest/util'

export type ConditionType = 'demographic' | 'country' | 'audience' | 'waves' | 'answer' | 'openText'
export { Logic } from '@attest/efl-antlr4'

export type ValueCondition = {
  id: string
  variable: string
  isNegated?: boolean
  isMultipleChoice?: boolean
  isOpenText?: boolean
  values: string[]
  subjects?: string[]
  type: ConditionType
}

export type RangeCondition = {
  id: string
  variable: string
  isNegated?: boolean
  values: Range[]
  type: ConditionType
}

export type Segment = {
  name: string
  id: string
  efl?: string
  logic: Logic
  groups?: SegmentGroup[]
  isShared?: boolean
}

export type SegmentGroup = {
  id: string
  logic: Logic
  conditions: Array<ValueCondition | RangeCondition>
  groups?: SegmentGroup[]
}

export const isRangeCondition = (
  condition: RangeCondition | ValueCondition,
): condition is RangeCondition => {
  return condition.variable === 'age'
}

export const isValueCondition = (
  condition: RangeCondition | ValueCondition,
): condition is ValueCondition => {
  return !isRangeCondition(condition)
}
