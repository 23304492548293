import type { CardTitleError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import { type Card, isGroupCard, isTextCard } from '@attest/editor-card'
import { SETTINGS } from '@attest/editor-settings'
import { removeMarkdownCharacters } from '@attest/markdown'

import { getTitleWithoutShortcodes } from '../piping-util'

export const getCardTitleErrors = createGetErrorsFor<
  CardTitleError['type'],
  { card: Card; placeholderErrorsEnabled?: boolean }
>({
  CARD_TITLE_MISSING({ card }) {
    if (isTextCard(card) || isGroupCard(card)) return false
    return getTitleWithoutShortcodes(card.title).length === 0
  },

  CARD_TITLE_TOO_SHORT({ card }) {
    if (isTextCard(card)) return false
    const title = getTitleWithoutShortcodes(card.title)
    return title.length > 0 && title.length < SETTINGS.QUESTION.MIN_TITLE_LENGTH
  },

  CARD_TITLE_TOO_LONG({ card }) {
    if (isTextCard(card)) return false
    return (
      getTitleWithoutShortcodes(removeMarkdownCharacters(card.title)).length >
      SETTINGS.QUESTION.MAX_TITLE_LENGTH
    )
  },

  CARD_TITLE_HAS_PLACEHOLDER_TEXT({ card, placeholderErrorsEnabled }) {
    if (isTextCard(card)) return false
    if (!placeholderErrorsEnabled) return false
    return SETTINGS.TEMPLATE.PLACEHOLDER_REGEX.test(card.title)
  },
})
