import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { type RouteMetaGlobal, ROUTES } from '@attest/router'

const routesNoSidebar: RouteMetaGlobal = {
  showNavbarTopbar: false,
  showProgressBar: false,
  authenticationRequired: 'unauthenticated',
}
const routesPublicMeta: RouteMetaGlobal = {
  showNavbarTopbar: true,
  showProgressBar: true,
  authenticationRequired: 'either',
}

export const routesPublic: readonly RouteRecordRaw[] = [
  {
    name: ROUTES.VALIDATE_EMAIL.name,
    path: ROUTES.VALIDATE_EMAIL.path,
    meta: routesPublicMeta,
    component: () => import('../../container/ValidateEmail.vue'),
    props: getValidateEmailProps,
  },
  {
    name: ROUTES.LOGIN_SSO.name,
    path: ROUTES.LOGIN_SSO.path,
    meta: routesNoSidebar,
    props: getLoginSSOProps,
    component: () => import('../../module/authentication/page/LoginSSO/LoginSSO.vue'),
  },
  {
    name: ROUTES.REGISTER_SSO.name,
    path: ROUTES.REGISTER_SSO.path,
    meta: routesNoSidebar,
    props: getRegisterSSOProps,
    component: () => import('../../module/authentication/page/RegisterSSO/RegisterSSO.vue'),
  },
]

type LoginSSOQuery = {
  fs?: string
  redirect?: string
  code?: string
}

type RegisterSSOQuery = {
  state?: string
} & LoginSSOQuery

type ValidateEmailQuery = {
  redirect?: string
}

function getLoginSSOProps(route: RouteLocation) {
  const query = route.query as LoginSSOQuery
  return {
    redirect: query.redirect,
    code: query.code,
  }
}

function getRegisterSSOProps(route: RouteLocation) {
  const query = route.query as RegisterSSOQuery
  return {
    ...getLoginSSOProps(route),
    state: query.state,
  }
}

function getValidateEmailProps(route: RouteLocation) {
  const query = route.query as ValidateEmailQuery
  return {
    redirect: query.redirect,
  }
}
