<script lang="tsx">
import { mapStores } from 'pinia'
import { PortalTarget } from 'portal-vue'
import { cloneVNode, type DefineComponent, defineComponent, Transition } from 'vue'
import { RouterView } from 'vue-router'

import { DescendantsObserver } from '@attest/_components/src/common/DescendantsObserver'
import ErrorContainer from '@attest/_components/src/common/Error.vue'
import ProgressBar from '@attest/_components/src/common/ProgressBar.vue'
import Toaster from '@attest/_components/src/common/toaster/Toaster.vue'
import Topbar from '@attest/_components/src/common/Topbar.vue'
import { ESCALATOR_PORTAL_TARGET } from '@attest/_constant'
import { useAppErrorStore } from '@attest/app-error'
import { isSafari } from '@attest/browser'
import { assertIsElement } from '@attest/dom'
import { ImpersonateBanner } from '@attest/impersonate'
import { ROUTES } from '@attest/router'
import { capture } from '@attest/telemetry'
import { useUserStore } from '@attest/user'
import { EventName, type EventsPayload, off, on } from '@attest/util'
import { create } from '@attest/vue-tsx'
import type { ExtractSlots } from '@attest/vue-tsx'

import { Navbar } from '../module/navbar/organism/Navbar'

const ContainerApp = defineComponent({
  data() {
    return {
      navbarActive: false,
      isScrollable: true,
      isNavbarRightActive: false,
      isDrawerVisible: false,
    }
  },

  computed: {
    ...mapStores(useAppErrorStore, useUserStore),

    showNavbarTopbar(): boolean {
      if (!this.appErrorStore) {
        return true
      }

      return this.$route.meta?.showNavbarTopbar === true
    },

    showProgressbar(): boolean {
      return !!this.$route.meta?.showProgressBar
    },

    showNavbar(): boolean {
      return this.navbarActive && this.showNavbarTopbar
    },
  },

  created() {
    on(EventName.MODAL_TOGGLE, this.handleModalToggle)
    on(EventName.DRAWER_TOGGLE, this.handleDrawer)
  },

  updated() {
    this.setAnnouncer()
  },

  beforeUnmount() {
    off(EventName.MODAL_TOGGLE, this.handleModalToggle)
    off(EventName.DRAWER_TOGGLE, this.handleDrawer)
  },

  methods: {
    handleModalToggle({ value }: EventsPayload[EventName.MODAL_TOGGLE]) {
      this.isScrollable = !value
    },

    handleDrawer({ visible }: EventsPayload[EventName.DRAWER_TOGGLE]) {
      this.isDrawerVisible = visible
    },

    setAnnouncer() {
      const announcer = this.$refs.announcer as HTMLDivElement
      const main = this.$refs.main as DefineComponent | undefined
      const isElementNode = main?.$el.nodeType === Node.ELEMENT_NODE
      const titleEl = isElementNode && main && main.$el.querySelector('h1')
      const title = (titleEl && titleEl.textContent) || document.title

      if (title && title === announcer.textContent) return

      announcer.textContent = `${title.trim()}, page loaded`

      if (window.self !== window.top) {
        return
      }

      this.$nextTick(() => {
        // For hard loads, when we land and we have autofocus.
        const activeElement = document.activeElement
        if (activeElement instanceof HTMLInputElement && activeElement.autofocus) return

        // For soft loads, when we land we should autofocus the relevant
        // autofocus input.
        const autofocusInput = document.querySelector('input[autofocus]')
        if (autofocusInput instanceof HTMLInputElement) {
          autofocusInput.focus()
          return
        }

        // This is for accessibility to to skip the navbar for the user.
        // So they don't have to tab through the navbar everytime.
        const contentSkipper = this.$refs.contentSkipper as HTMLDivElement
        contentSkipper?.focus()
      })
    },

    openNavbar() {
      this.navbarActive = true
    },

    closeNavbar() {
      this.navbarActive = false
    },

    async logout() {
      await this.userStore.logout()
      const { path } = this.$router.resolve({ name: ROUTES.LOGIN.name })
      window.location.assign(path)
    },
  },

  render() {
    const baseClass = 'p-app'
    const appClass = {
      [baseClass]: true,
    }
    const mainClass = {
      [`${baseClass}__main`]: true,
      [`${baseClass}__main--noscroll`]: !this.isScrollable && !isSafari(),
    }
    const navbarClass = {
      [`${baseClass}__navbar`]: true,
      [`${baseClass}__navbar--show`]: this.showNavbarTopbar,
    }
    const topbarClass = {
      [`${baseClass}__topbar`]: true,
      [`${baseClass}__topbar--show`]: this.showNavbarTopbar,
    }
    const progressClass = {
      [`${baseClass}__progress`]: true,
    }
    const toasterClass = {
      [`${baseClass}__toaster`]: true,
      [`${baseClass}__toaster--position-drawer`]: this.isDrawerVisible,
    }

    const appMainContentId = 'app_main_content'

    const linkTrackingNodes = new WeakSet<HTMLAnchorElement>()
    return (
      <DescendantsObserver
        selector="a"
        onDescendantAdded={node => {
          assertIsElement(node, HTMLAnchorElement)
          if (linkTrackingNodes.has(node)) return
          linkTrackingNodes.add(node)
          node.addEventListener('click', () => {
            const targetUrl = decodeURI(node.href)
            if (targetUrl.length <= 0) return capture(new Error('invalid link'))
          })
        }}
      >
        <div id="app" class={appClass}>
          <div ref="announcer" class="u-screen-reader-only" tabindex={-1} aria-live="assertive" />
          <a
            ref="contentSkipper"
            class="u-screen-reader-only"
            href={`#${appMainContentId}`}
            aria-live="polite"
          >
            Skip to main content
          </a>
          <Transition name="t-app-topbar">
            <Topbar
              class={topbarClass}
              v-show={this.showNavbarTopbar}
              onOpenMenu={this.openNavbar}
              onLogoClick={() => this.$router.push({ name: ROUTES.INDEX.name })}
            />
          </Transition>
          <Transition name="t-app-navbar">
            <Navbar
              class={navbarClass}
              v-show={this.showNavbar}
              showAppButtons={useUserStore().isAuthenticated}
              onLogout={this.logout}
              onClose={this.closeNavbar}
              routeName={this.$route.name ?? undefined}
            />
          </Transition>
          <ImpersonateBanner />
          <div class={`${baseClass}__main-wrapper`}>
            {this.showProgressbar && <ProgressBar class={progressClass} />}
            {this.appErrorStore.hasError ? (
              <ErrorContainer
                ref="main"
                class={mainClass}
                id={appMainContentId}
                {...{ status: 200, ...this.appErrorStore.errors.at(-1) }}
              />
            ) : (
              <RouterView
                v-slots={
                  {
                    default({ Component }) {
                      return [
                        cloneVNode(Component, {
                          ref: 'main',
                          class: mainClass,
                          id: appMainContentId,
                        }),
                      ]
                    },
                  } satisfies ExtractSlots<typeof RouterView>
                }
              />
            )}
            <Toaster class={toasterClass} />
          </div>
          <PortalTarget name={ESCALATOR_PORTAL_TARGET} multiple />
        </div>
      </DescendantsObserver>
    )
  },
})

export default create(ContainerApp)
</script>
<style lang="postcss">
:root {
  --p-app-navbar-transition: opacity var(--attest-timing-slow) var(--attest-ease-in-out);
  --p-app-navbar-bar-transition: transform var(--attest-timing-slow) var(--attest-ease-in-out);
  --p-app-topbar-transition: transform var(--attest-timing-slow) var(--attest-ease-in-out);
  --p-app-main-transition: top var(--attest-timing-slow) var(--attest-ease-in-out),
    width var(--attest-timing-slow) var(--attest-ease-in-out);
}

.p-app {
  --navbar-width: 240px;
  --navbar-width-small: 80px;
  --topbar-height: 40px;
  --drawer-width: 336px;

  display: flex;
  height: 100vh;
  height: 100svh;
  flex-direction: column;

  @media (--attest-media-s) {
    flex-direction: row;
  }

  &__navbar {
    position: fixed;
    z-index: var(--z-index-navbar);
    top: 0;
    left: 0;
    flex: 0 0 auto;

    @media (--attest-media-s) {
      position: static;
      display: none !important;

      &--show {
        display: block !important;
        opacity: 1 !important;

        & .c-navbar__bar {
          transform: translateX(0) !important;
        }
      }
    }
  }

  &__topbar {
    display: none;
    flex: 0 0 auto;

    &--show {
      display: flex;
    }

    @media (--attest-media-s) {
      display: none;
    }
  }

  &__main-wrapper {
    overflow: hidden;
    flex: 1 1 auto;
  }

  &__main {
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    padding: var(--attest-spacing-6);
    -webkit-overflow-scrolling: touch;

    @media (--attest-media-s) {
      padding: 64px;
    }

    &--noscroll {
      overflow: hidden;
    }
  }

  &__progress {
    width: 100%;
  }

  &__toaster {
    @media (--attest-media-s) {
      left: var(--navbar-width);
    }
  }

  @media print {
    height: auto;
    min-height: 100vh;

    &__navbar {
      height: 100vh;
    }

    &__main {
      overflow: visible;
      height: auto;
      min-height: 100%;
    }
  }
}

.t-app-topbar-enter-active,
.t-app-topbar-leave-active {
  &,
  & ~ .p-app__progress {
    transition: var(--p-app-topbar-transition);
  }

  & ~ .p-app__main {
    transition: var(--p-app-main-transition);
  }
}

.t-app-topbar-enter-from,
.t-app-topbar-leave-to {
  transform: translateY(calc(-1 * var(--topbar-height)));

  & ~ .p-app__progress {
    transform: translateY(0);
  }

  & ~ .p-app__main {
    top: 0;
  }
}

.t-app-topbar-leave-from,
.t-app-topbar-enter-to {
  transform: translateY(0);

  & ~ .p-app__progress {
    transform: translateY(var(--topbar-height));
  }

  & ~ .p-app__main {
    top: var(--topbar-height);
  }
}

.t-app-navbar-enter-active,
.t-app-navbar-leave-active {
  transition: var(--p-app-navbar-transition);

  & .c-navbar__bar {
    transition: var(--p-app-navbar-bar-transition);
  }
}

.t-app-navbar-enter-from,
.t-app-navbar-leave-to {
  opacity: 0;

  & .c-navbar__bar {
    transform: translateX(calc(-1 * var(--navbar-width)));
  }
}

.t-app-navbar-leave-from,
.t-app-navbar-enter-to {
  opacity: 1;

  & .c-navbar__bar {
    transform: translateX(0);
  }
}
</style>
