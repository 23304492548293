import { MultiDirectedGraph } from 'graphology'
import { willCreateCycle } from 'graphology-dag'
import type { SerializedEdge } from 'graphology-types'

import { union } from '@attest/util'

import {
  isRoutedNode,
  type RouteNode,
  type RoutingEdgeAttributes,
  type RoutingGraph,
  type RoutingNodeAttributes,
} from './model'

export type EdgeInformation = {
  sourceCardGuid: string
  targetCardGuid: string
  answerRouteEdge: boolean
}

export function createRoutingGraph(nodes: RouteNode[]): {
  graph: RoutingGraph
  edgesThatCauseCycles: EdgeInformation[]
} {
  const groupedNodeMap = new Map(
    nodes.flatMap(node => node.group.cards).map(card => [card.guid, card]),
  )
  const rootNodes = nodes.filter(node => !groupedNodeMap.has(node.guid))
  const allNodes = Object.values(
    Object.fromEntries(nodes.flatMap(node => [node, ...node.group.cards]).map(n => [n.guid, n])),
  )

  const edges = [
    ...createNodeRouteEdges(rootNodes),
    ...createGroupNodeRouteEdges(rootNodes),
    ...createNodeAnswerRouteEdges(rootNodes),
  ]

  const graph: MultiDirectedGraph<RoutingNodeAttributes, RoutingEdgeAttributes> =
    MultiDirectedGraph.from({
      attributes: {},
      nodes: allNodes.map(node => ({
        key: node.guid,
        attributes: {
          type: node.type,
          omittedFromAudiences: new Set(node.omittedFromAudiences),
          ...node.position,
        },
      })),
      edges: [],
      options: {},
    })

  const edgesThatCauseCycles: SerializedEdge<RoutingEdgeAttributes>[] = []

  for (const edge of edges) {
    if (willCreateCycle(graph, edge.source, edge.target)) {
      edgesThatCauseCycles.push(edge)
      continue
    }

    graph.mergeEdgeWithKey(edge.key, edge.source, edge.target, edge.attributes)
  }

  return {
    graph,
    edgesThatCauseCycles: edgesThatCauseCycles.map(edge => ({
      sourceCardGuid: edge.source,
      targetCardGuid: edge.target,
      answerRouteEdge: edge.attributes?.type === 'ANSWER',
    })),
  }
}

function createNodeRouteEdges(nodes: RouteNode[]): SerializedEdge<RoutingEdgeAttributes>[] {
  return nodes
    .filter(isRoutedNode)
    .filter(node => node.type !== 'group')
    .map(node => ({
      key: createDirectedEdgeKey(createCardKey(node), node.nextGuid),
      source: node.guid,
      target: node.nextGuid,
      attributes: { type: 'DEFAULT', omittedFromAudiences: new Set() },
    }))
}

const isRouteEdge = <T>(t: T | undefined): t is T => t !== undefined
function createGroupNodeRouteEdges(nodes: RouteNode[]): SerializedEdge<RoutingEdgeAttributes>[] {
  const groups = nodes.filter(node => node.type === 'group')

  return [
    ...groups
      .map(node => {
        const nextGuid: string | undefined = node.group.cards[0]?.guid ?? node.nextGuid ?? undefined
        if (!nextGuid) return undefined
        return {
          key: createDirectedEdgeKey(createCardKey(node), nextGuid),
          source: node.guid,
          target: nextGuid,
          attributes: {
            type: 'DEFAULT',
            omittedFromAudiences: new Set<string>(),
          },
        } as const
      })
      .filter(isRouteEdge),
    ...groups.flatMap(node =>
      node.group.cards
        .map((card, index) => {
          const nextGuid: string | undefined =
            node.group.cards[index + 1]?.guid ?? node.nextGuid ?? undefined
          if (!nextGuid) return undefined
          return {
            key: createDirectedEdgeKey(createCardKey(card), nextGuid),
            source: card.guid,
            target: nextGuid,
            attributes: {
              type: 'DEFAULT',
              omittedFromAudiences: new Set<string>(),
            },
          } as const
        })
        .filter(t => t !== undefined),
    ),
  ].filter(isRouteEdge)
}

function createNodeAnswerRouteEdges(nodes: RouteNode[]): SerializedEdge<RoutingEdgeAttributes>[] {
  return nodes
    .flatMap(node => [node, ...node.group.cards])
    .flatMap(node =>
      (node.question.options.answers ?? []).filter(isRoutedNode).map(answer => {
        return {
          key: createDirectedEdgeKey(createCardAnswerKey(node, answer), answer.nextGuid),
          source: node.guid,
          target: answer.nextGuid,
          attributes: {
            type: 'ANSWER',
            omittedFromAudiences: collectOmittedFromAudiencesFollowingForwarding(
              nodes,
              answer,
              node,
            ),
          },
        }
      }),
    )
}

type Answer = {
  omittedFromAudiences: Set<string>
  mask: {
    answerId: string
  } | null
}
function collectOmittedFromAudiencesFollowingForwarding(
  allNodes: RouteNode[],
  answer: Answer,
  node: RouteNode,
): Set<string> {
  let result = answer.omittedFromAudiences
  let currentNode: RouteNode | undefined = node
  let currentAnswer: Answer | undefined = answer

  while (currentNode && currentAnswer) {
    const maskingNodeSource: RouteNode | undefined = currentNode.question.options.maskingQuestion
      ?.isToggled
      ? allNodes.find(n => n.guid === currentNode?.question.options.maskingQuestion?.senderGuid)
      : undefined
    const maskingNodeAnswerSource = maskingNodeSource
      ? maskingNodeSource.question.options.answers.find(a => a.id === currentAnswer?.mask?.answerId)
      : undefined

    currentAnswer = maskingNodeAnswerSource
    currentNode = maskingNodeSource
    result = maskingNodeAnswerSource
      ? union([maskingNodeAnswerSource.omittedFromAudiences, result])
      : result
  }

  return result
}

function createCardKey(card: RouteNode): `:card(${string})` {
  return `:card(${card.guid})`
}

function createCardAnswerKey(
  card: RouteNode,
  answer: {
    id: string
  },
): `${ReturnType<typeof createCardKey>}:answer(${string})` {
  return `${createCardKey(card)}:answer(${answer.id})`
}

function createDirectedEdgeKey(source: string, target: string): `${string}->${string}` {
  return `${source}->${target}`
}
