import { createEntity, type Entity } from './base'

export type StudyEntity<D extends StudyEntityData = StudyEntityData> = {} & Entity<D>
export type StudyEntityData = {
  id: string
  type?: 'qual' | 'quant'
}

export function createStudyEntity({ id, type }: StudyEntityData): StudyEntity {
  return createEntity({ name: 'study', version: '1-0-1' }, { id, ...(type && { type }) })
}
