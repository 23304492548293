import type { StudyMessageCard } from '../../card/message/model'
import { createStudyMessageStructure } from '../../card/message/structure/factory'
import type { StudyMessageStructure } from '../../card/message/structure/model'
import type { ApiStudyWave } from '../wave'

import type { ApiStudyMessageCard, ApiStudyMessageCardStructure } from './model'

export function createStudyMessageCard(
  apiCard: ApiStudyMessageCard,
  apiWaves: ApiStudyWave[],
): StudyMessageCard {
  return {
    id: apiCard.guid,
    type: apiCard.card_type,
    text: apiCard.text,
    imageUrl: apiCard.image_url ?? null,
    structures: Object.entries(apiCard.waves).map(([waveId, apiStructure]) =>
      createStudyMessageCardStructure({
        waveId,
        apiStructure,
        apiWave: apiWaves.find(wave => wave.id === waveId),
      }),
    ),
  }
}

export function createStudyMessageCardStructure({
  waveId,
  apiStructure,
  apiWave,
}: {
  waveId: string
  apiStructure: ApiStudyMessageCardStructure
  apiWave?: ApiStudyWave
}): StudyMessageStructure {
  return createStudyMessageStructure({
    waveId,
    nextGuid: apiStructure.next ?? null,
    text: apiStructure.text,
    publishedTimestamp: apiWave?.published ?? undefined,
    imageUrl: apiStructure?.image_url ?? undefined,
  })
}
