<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import type { SvgImageProps } from './interface'

const SvgImage: FunctionalComponent<SvgImageProps> = props => {
  const { svg, ...attrs } = props

  const baseClass = 'c-svg-image'
  const classes = {
    [baseClass]: true,
  }

  return (
    <div
      role="img"
      aria-label={props.alt}
      class={classes}
      innerHTML={(typeof svg === 'object' && 'default' in svg ? (svg as any).default : svg) ?? ''}
      {...attrs}
    />
  )
}

export default SvgImage
</script>

<style lang="postcss">
.c-svg-image {
  display: flex;
  flex: 0 1 auto;
}
</style>
