import type { TrackingEvent } from './event'
import type { TrackingTool, TrackingToolTrigger } from './tracking-tool'

type TrackingManagerAction = (tool: TrackingTool) => void

export class TrackingManager {
  private bufferCount = 0
  private tools: TrackingTool[] = []
  private buffer: Map<number, TrackingManagerAction[]> = new Map()

  public async add(fetch: Promise<TrackingTool<any>>): Promise<void> {
    const bufferId = this.addEventBuffer()
    try {
      const tool = await fetch
      tool.onInitialized(() => this.callBufferedEvents(bufferId, tool))
      tool.init()
      this.tools.push(tool)
    } catch (e) {
      this.buffer.delete(bufferId)
      // eslint-disable-next-line no-console
      console.warn(e)
    }
  }

  public trigger: TrackingToolTrigger<TrackingEvent> = event => {
    this.callAll(tool => tool.trigger(event))
  }

  private getInitialisedTools(): TrackingTool<TrackingEvent>[] {
    return this.tools.filter(tool => tool.isInitialized())
  }

  private async callAll(fn: TrackingManagerAction): Promise<void> {
    try {
      this.bufferEvent(fn)
      await Promise.all(this.getInitialisedTools().map(fn))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }

    return Promise.resolve()
  }

  private addEventBuffer(): number {
    const id = this.bufferCount++
    this.buffer.set(id, [])
    return id
  }

  private bufferEvent(fn: TrackingManagerAction): void {
    if (this.buffer.size > 0) {
      this.buffer.forEach(buffer => buffer.push(fn))
    }
  }

  private callBufferedEvents(id: number, tool: TrackingTool): void {
    const fns: TrackingManagerAction[] = this.buffer.get(id) || []
    fns.forEach(async fn => {
      try {
        await fn(tool)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e)
      }
    })
    this.buffer.delete(id)
  }
}
