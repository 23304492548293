import { defineStore, type StoreActions } from 'pinia'

import type { Card, CardGuid } from '@attest/editor-card'

import {
  type DisplayLogicCondition,
  type DisplayLogicRule,
  displayLogicSubjectHeadersMatch,
  getDisplayLogicTargetsForQuestion,
} from '.'

export type EditorDisplayLogicState = {
  guidToDisplayLogicRule: Record<string, DisplayLogicRule>
}

export const EDITOR_DISPLAY_LOGIC_STORE_NAMESPACE = 'editorDisplayLogic'

export function createEditorDisplayLogicState(
  override: Partial<EditorDisplayLogicState> = {},
): EditorDisplayLogicState {
  return {
    guidToDisplayLogicRule: {},
    ...override,
  }
}

export const useEditorDisplayLogicStore = defineStore(EDITOR_DISPLAY_LOGIC_STORE_NAMESPACE, {
  state: createEditorDisplayLogicState,
  actions: {
    setDisplayLogic(guidToDisplayLogicRule: Record<string, DisplayLogicRule>): void {
      this.guidToDisplayLogicRule = guidToDisplayLogicRule
    },

    setDisplayLogicRule(guid: string, rule: DisplayLogicRule): void {
      if (rule.conditions.length === 0) {
        delete this.guidToDisplayLogicRule[guid]
        return
      }
      this.guidToDisplayLogicRule[guid] = rule
    },

    deleteDisplayLogicRule(guid: string): void {
      delete this.guidToDisplayLogicRule[guid]
    },

    updateDisplayLogicConditions({
      guid,
      conditions,
    }: {
      guid: string
      conditions: DisplayLogicCondition[]
    }): void {
      this.setDisplayLogicRule(guid, {
        ...this.guidToDisplayLogicRule[guid],
        guid,
        conditions,
      })
    },

    updateDisplayLogicConditionsByReferenceQuestion(
      referenceGuid: string,
      override: Partial<DisplayLogicCondition>,
    ): void {
      getDisplayLogicTargetsForQuestion(
        referenceGuid,
        Object.values(this.guidToDisplayLogicRule),
      ).forEach(targetGuid => {
        this.updateDisplayLogicConditions({
          guid: targetGuid,
          conditions: [
            ...this.guidToDisplayLogicRule[targetGuid]?.conditions.map(condition => ({
              ...condition,
              ...override,
            })),
          ],
        })
      })
    },

    removeDisplayLogicConditionsByReferenceQuestion(referenceGuid: string): void {
      getDisplayLogicTargetsForQuestion(
        referenceGuid,
        Object.values(this.guidToDisplayLogicRule),
      ).forEach(guid => {
        this.updateDisplayLogicConditions({
          guid,
          conditions: this.guidToDisplayLogicRule[guid]?.conditions.filter(
            condition => condition.referenceGuid !== referenceGuid,
          ),
        })
      })
    },

    removeDisplayLogicConditionsByAnswer(
      guid: string,
      referenceGuid: string,
      removedAnswerId: string,
    ): void {
      const conditions = this.guidToDisplayLogicRule[guid]?.conditions.flatMap(condition => {
        if (condition.referenceGuid !== referenceGuid) return [condition]
        if (condition.answers.length === 1) return []
        return [
          {
            ...condition,
            answers: condition.answers.filter(({ answerId }) => answerId !== removedAnswerId),
          },
        ]
      })
      this.updateDisplayLogicConditions({
        guid,
        conditions,
      })
    },

    removeDisplayLogicConditionsBySubject(
      guid: string,
      referenceGuid: string,
      subjectId: string,
    ): void {
      const conditions = this.guidToDisplayLogicRule[guid]?.conditions.flatMap(condition => {
        if (condition.referenceGuid !== referenceGuid) return [condition]
        if (condition.answers.length === 1) return []
        return [
          {
            ...condition,
            answers: condition.answers.filter(
              answer => !displayLogicSubjectHeadersMatch(answer, subjectId),
            ),
          },
        ]
      })
      this.updateDisplayLogicConditions({
        guid,
        conditions,
      })
    },
  },

  getters: {
    getDisplayLogicRuleForCard(): (options: { cardGuid: string }) => DisplayLogicRule | undefined {
      return ({ cardGuid }) => {
        return this.guidToDisplayLogicRule[cardGuid]
      }
    },

    getDisplayLogicConditionsForCard(): (options: { cardGuid: string }) => DisplayLogicCondition[] {
      return ({ cardGuid }) => {
        return this.guidToDisplayLogicRule[cardGuid]?.conditions ?? []
      }
    },

    getDisplayLogicTargetsByReferenceQuestion(): ({ guid }: Card) => CardGuid[] {
      return ({ guid }) =>
        getDisplayLogicTargetsForQuestion(guid, Object.values(this.guidToDisplayLogicRule))
    },
  },
})

const SAVE_STUDY_ACTIONS: (keyof StoreActions<ReturnType<typeof useEditorDisplayLogicStore>>)[] = [
  'setDisplayLogicRule',
]
const SAVE_SURVEY_ACTIONS: (keyof StoreActions<ReturnType<typeof useEditorDisplayLogicStore>>)[] = [
  'setDisplayLogicRule',
]
const UPDATE_ALL_ERRORS_ACTIONS: (keyof StoreActions<
  ReturnType<typeof useEditorDisplayLogicStore>
>)[] = ['setDisplayLogicRule', 'deleteDisplayLogicRule']

export const isDisplayLogicActionSaveStudyAction = (
  name: string,
): name is (typeof SAVE_STUDY_ACTIONS)[number] => new Set<string>(SAVE_STUDY_ACTIONS).has(name)

export const isDisplayLogicActionSaveSurveyAction = (
  name: string,
): name is (typeof SAVE_SURVEY_ACTIONS)[number] => new Set<string>(SAVE_SURVEY_ACTIONS).has(name)

export const isDisplayLogicActionUpdateAllErrorsAction = (
  name: string,
): name is (typeof UPDATE_ALL_ERRORS_ACTIONS)[number] =>
  new Set<string>(UPDATE_ALL_ERRORS_ACTIONS).has(name)
