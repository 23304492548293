import {
  isStudyChoiceQuestionCard,
  isStudyGridQuestionCard,
  isStudyNPSQuestionCard,
  isStudyOpenTextQuestionCard,
  isStudyRankedQuestionCard,
  type StudyCard,
} from '@attest/study'

import type { SavedCardSettings } from '../model'

export const getDefaultCardSettings = (card: StudyCard): SavedCardSettings | null => {
  if (isStudyChoiceQuestionCard(card)) return defaultSingleMultiChoiceSettings
  if (isStudyGridQuestionCard(card)) return defaultGridSettings
  if (isStudyNPSQuestionCard(card)) return defaultNPSSettings
  if (isStudyRankedQuestionCard(card)) return defaultRankedSettings
  if (isStudyOpenTextQuestionCard(card)) return defaultOpenTextSettings

  return null
}

export const defaultSingleMultiChoiceSettings: SavedCardSettings = {
  overview: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'default',
    show_on_x_axis: 'row-child',
  },
  trends: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row-child',
    show_nested_results_by: 'row',
  },
  crosstabs: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row-child',
    show_nested_results_by: 'row',
  },
  common: {
    percentage_calculation: 'total_respondents',
  },
}

const defaultRankedSettings: SavedCardSettings = {
  overview: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'default',
    show_on_x_axis: 'rows-by-row-child',
  },
  trends: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row',
    show_nested_results_by: 'row-child',
  },
  crosstabs: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row',
    show_nested_results_by: 'row-child',
  },
  common: {
    percentage_calculation: 'total_respondents',
  },
}

const defaultGridSettings: SavedCardSettings = {
  overview: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'default',
    show_on_x_axis: 'rows-by-row-child',
  },
  trends: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row',
    show_nested_results_by: 'row-child',
  },
  crosstabs: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row',
    show_nested_results_by: 'row-child',
  },
  common: {
    percentage_calculation: 'total_respondents',
  },
}

const defaultNPSSettings: SavedCardSettings = {
  overview: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'default',
    show_on_x_axis: 'row-child',
  },
  trends: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row-child',
    show_nested_results_by: 'row',
  },
  crosstabs: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row-child',
    show_nested_results_by: 'row',
  },
  common: {
    percentage_calculation: 'total_respondents',
  },
}

const defaultOpenTextSettings: SavedCardSettings = {
  overview: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'default',
    show_on_x_axis: 'column',
  },
  trends: {
    theme: 'attest',
    deselected_column_value_ids: ['none', 'na'],
    deselected_row_child_ids: ['none', 'na'],
    selected_visualisation: 'column',
    show_on_x_axis: 'row-child',
    show_nested_results_by: 'row',
  },
  common: {
    percentage_calculation: 'total_respondents',
  },
}
