import levenshtein from 'js-levenshtein'

export function levenshteinTest(searchTerm: string, givenText: string): boolean {
  const LEVENSHTEIN_THRESHOLD = 1
  const splitSearchTerm = searchTerm.split(' ')
  const splitGivenText = givenText.split(' ')

  const levenshteinPassPerWord = splitSearchTerm.map(searchWord => {
    return splitGivenText.some(givenWord => {
      const includesResult = givenWord.includes(searchWord)
      return includesResult || levenshtein(givenWord, searchWord) <= LEVENSHTEIN_THRESHOLD
    })
  })

  return levenshteinPassPerWord.every(word => word === true)
}
