<script lang="tsx">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import ModalConfirm from '@attest/_components/src/common/ModalConfirm.vue'
import { ERROR_MESSAGES_GENERAL } from '@attest/_constant'
import { c } from '@attest/intl'
import { create, createEmitter, EMPTY_VNODE } from '@attest/vue-tsx'

import type { Survey } from '../../../model'
import { useSurveyStore } from '../../../store'

import { trackCancel, trackConfirm } from './tracking'

type DeleteSurveyModalProps = {
  guid: string
  triggerCancelOnConfirm?: boolean
}

export type DeleteSurveyModalEvents = {
  close(): void
  deleted(): void
}

const emit = createEmitter<DeleteSurveyModalEvents>()

const DeleteSurveyModal = defineComponent({
  props: {
    guid: { type: String, required: true },
    triggerCancelOnConfirm: { type: Boolean, default: true },
  },

  data() {
    return {
      loading: false,
      generalError: null as string | null,
      survey: undefined as undefined | Survey,
    }
  },

  computed: {
    ...mapStores(useSurveyStore),

    isSurveyOpen(): boolean {
      return !!this.survey && ['live', 'pending'].includes(this.survey.status)
    },

    isSurveyRecurring(): boolean {
      return !!this.survey?.recurrence
    },

    title(): string {
      return this.isSurveyOpen
        ? c('survey.list.delete_modal.close_survey.confirm')
        : c('survey.list.delete_modal.cancel_recurrence.title')
    },
  },

  watch: {
    guid() {
      this.resetState()
    },
  },

  async mounted() {
    if (!this.surveyStore.getSurveyByGuid(this.guid)) {
      await this.surveyStore.get({
        guid: this.guid,
      })
    }
    this.survey = this.surveyStore.getSurveyByGuid(this.guid)
  },

  methods: {
    resetState() {
      this.loading = false
      this.generalError = null
    },

    async handleDeleteSurvey(event: Event): Promise<void> {
      trackConfirm(this.guid, event)
      this.loading = true
      const guid = this.guid
      const [_, error] = await this.surveyStore.updateSurveyArchive({
        guid,
        archive: true,
      })
      this.loading = false

      if (error) {
        this.generalError = ERROR_MESSAGES_GENERAL.UNKNOWN ?? ERROR_MESSAGES_GENERAL.UNKNOWN
        return
      }

      emit(this, 'deleted')
    },
  },

  render() {
    if (!this.survey) return EMPTY_VNODE
    const baseClass = 'c-modal-delete-survey'

    return (
      <ModalConfirm
        show
        titleDisplay={this.title}
        class={baseClass}
        handleConfirm={this.handleDeleteSurvey}
        confirmCopy={
          this.isSurveyOpen
            ? c('survey.list.delete_modal.close_survey.confirm')
            : c('delete_survey.modal.confirm')
        }
        handleCancel={event => {
          trackCancel(this.guid, event)
          emit(this, 'close')
        }}
        confirmLoading={this.loading}
        error={this.generalError}
        confirmButtonVariant="error"
        data-name="ModalDeleteSurvey"
      >
        {this.isSurveyOpen && (
          <div class="c-modal-delete-survey__body">
            {c('survey.list.delete_modal.close_survey.body')}
          </div>
        )}
        {this.isSurveyRecurring && ['live', 'pending', 'closed'].includes(this.survey.status) && (
          <div class="c-modal-delete-survey__body">
            {c('survey.list.delete_modal.cancel_recurrence.body')}
          </div>
        )}
      </ModalConfirm>
    )
  },
})

export default create<DeleteSurveyModalProps, DeleteSurveyModalEvents>(DeleteSurveyModal)
</script>

<style lang="postcss">
.c-modal-delete-survey {
  &__body:not(:last-of-type) {
    margin-bottom: var(--attest-spacing-4);
  }
}
</style>
