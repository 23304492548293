<script lang="tsx">
import { defineComponent } from 'vue'

import Timeout from './Timeout.vue'

const PausableTimeout = defineComponent({
  props: {
    duration: { type: Number, required: true },
    pause: { type: Boolean, default: false },
  },

  emits: {
    timeout: () => true,
  },

  data() {
    return {
      elapsedTime: 0,
      startTime: 0,
    }
  },

  watch: {
    pause: {
      immediate: true,
      handler() {
        if (this.pause) {
          this.elapsedTime += performance.now() - this.startTime
        } else {
          this.startTime = performance.now()
        }
      },
    },
  },

  render() {
    if (this.pause) return ''

    return (
      <Timeout
        onTimeout={() => this.$emit('timeout')}
        duration={this.duration - this.elapsedTime}
      />
    )
  },
})

export default PausableTimeout
</script>
