import { isNil, sum } from 'ramda'

import {
  type ApiWaveSurvey,
  type BaseApiWaveSurvey,
  type DraftApiWaveSurvey,
  isDraftApiWaveSurvey,
  type SentApiWaveSurvey,
} from '@attest/_api/model/wave-survey'
import {
  transformApiQuotasToQuotas,
  transformApiSegmentationsToSegmentations,
} from '@attest/audience'
import { Thyme } from '@attest/thyme'

import type {
  BaseWaveSurvey,
  DraftWaveSurvey,
  SentWaveSurvey,
  WaveSurvey,
  WaveSurveyStatus,
} from './model'

export function transformWaveResponseToWaves(response: ApiWaveSurvey[]): WaveSurvey[] {
  return response.map(transformWaveResponseToWave)
}

export function transformWaveResponseToWave(response: ApiWaveSurvey): WaveSurvey {
  return isDraftApiWaveSurvey(response)
    ? transformDraftApiWaveToDraftWaveSurvey(response)
    : transformSentApiWaveToSentWaveSurvey(response)
}

function transformBaseApiWaveToBaseWave(response: BaseApiWaveSurvey): BaseWaveSurvey {
  return {
    id: response.id,
    country: response.country,
    audienceId: response.audience.map(a => a.id)[0],
    name: response.name,
    language: response.language,
    createdTimestamp: response.created,
    publishedTimestamp: response.published ?? null,
    expiredTimestamp: response.expires ?? null,
    startTimestamp: response.start_date ?? null,
    timeZoneId: response.time_zone_id ?? null,
    responses: response.num_responses || 0,
    maxResponses: sum(response.audience.map(a => a.max_responses)),
    status: transformWaveResponseStatusToWaveStatus(response.status),
    segmentations: transformApiSegmentationsToSegmentations(response.segmentations),
    quotas: transformApiQuotasToQuotas(response.quotas),
    numQuestionsLongestRoute: 1,
    title: '',
  }
}

function transformDraftApiWaveToDraftWaveSurvey(response: DraftApiWaveSurvey): DraftWaveSurvey {
  return {
    ...transformBaseApiWaveToBaseWave(response),
    publishedTimestamp: null,
    responses: 0,
    status: 'draft',
  }
}

function transformSentApiWaveToSentWaveSurvey(response: SentApiWaveSurvey): SentWaveSurvey {
  return {
    ...transformBaseApiWaveToBaseWave(response),
    publishedTimestamp: response.published,
    startTimestamp: response.start_date,
    timeZoneId: response.time_zone_id,
    status: transformWaveResponseStatusToWaveStatus(response.status) as SentWaveSurvey['status'],
    expiredTimestamp: response.expires,
    lastAnswerTimestamp: response.last_response,
  }
}

export function transformWaveResponseStatusToWaveStatus(response: string): WaveSurveyStatus {
  return response.toLowerCase() as WaveSurveyStatus
}

export function getUTCWaveStartTimestamp({
  startTimestamp,
  timeZoneId,
}: {
  startTimestamp: number | null
  timeZoneId: string | null
}): number | undefined {
  if (isNil(startTimestamp) || isNil(timeZoneId)) return undefined
  return getWaveStartThyme({ startTimestamp, timeZoneId }, { zone: 'UTC' }).toMillis()
}

export function getWaveStartThyme(
  waveSurvey: { startTimestamp: number; timeZoneId: string },
  options: { zone?: string } = {},
): Thyme {
  return Thyme.fromMillis(waveSurvey.startTimestamp, { zone: waveSurvey.timeZoneId }).setZone(
    options.zone ?? Thyme.local().zone,
    { keepLocalTime: true },
  )
}
