import {
  type ApiError,
  type InternalError,
  reasonErrorToInternalError,
  service,
} from '@attest/_api'
import type { PurchaseStudyResponseData } from '@attest/_api/model/study'

import { transformRecurrenceToBaseApiRecurrence } from '../recurrence/transformer'

import { REQUEST_URL } from './url'

export async function purchaseStudyDraft({
  id,
  creditPotGuid,
  recurrence,
  timeZoneId,
}: {
  id: string
  creditPotGuid?: string
  recurrence?: {
    interval: number
    recurrenceInterval: 'DAYS' | 'MONTHS'
    hasFixedInterval: boolean
  } | null
  timeZoneId: string
}): Promise<PurchaseStudyResponseData | InternalError<ApiError>> {
  try {
    const { data } = await service().post<PurchaseStudyResponseData>(
      REQUEST_URL.SURVEY_STUDY_DRAFT({ id }),
      {
        pot_guid: creditPotGuid,
        recurrence: transformRecurrenceToBaseApiRecurrence(timeZoneId, recurrence),
      },
    )
    return data
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}
