import { createWaveEntity, type WaveEntity } from '@attest/tracking'

import { useStudyStore } from '../store'

import { sortWavesByDescendingStartTime } from './sorter'

export type StudyWaveEntity<D extends StudyWaveEntityData = StudyWaveEntityData> =
  {} & WaveEntity<D>
type StudyWaveEntityData = {
  id: string
}

export function createStudyWaveEntity(data: StudyWaveEntityData): WaveEntity {
  return createWaveEntity({ id: data.id })
}

export function createRouteWaveEntity(waveSurveyId: string): WaveEntity {
  if (waveSurveyId === 'latest') {
    const waveSurveys = useStudyStore().waveSurveys
    return createStudyWaveEntity({
      id: sortWavesByDescendingStartTime(waveSurveys)[0]?.id,
    })
  }

  return createStudyWaveEntity({ id: waveSurveyId })
}
