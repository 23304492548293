import type { Tag } from '../model'

import type { ApiTag } from './model'

export function transformApiTagToTag(apiTag: ApiTag): Tag {
  return {
    id: apiTag.id,
    name: apiTag.name,
    color: apiTag.colour,
    creationTime: apiTag.creation_time ?? null,
  }
}

export function transformTagToApiTag(tag: Tag): ApiTag {
  return {
    id: tag.id,
    name: tag.name,
    colour: tag.color,
    creation_time: tag.creationTime ?? undefined,
  }
}
