export function getBCP47LangCode(language: string, country?: string): string {
  switch (language) {
    case 'ARA':
      return 'ar'
    case 'DAN':
      return 'da'
    case 'DUT':
      return 'nl'
    case 'ENG':
      return 'en'
    case 'FIN':
      return 'fi'
    case 'FRE':
      return 'fr'
    case 'GER':
      return 'de'
    case 'HIN':
      return 'hi'
    case 'ITA':
      return 'it'
    case 'JPN':
      return 'ja'
    case 'KOR':
      return 'ko'
    case 'NOR':
      return 'nb'
    case 'POL':
      return 'pl'
    case 'POR':
      return country === 'BR' ? 'pt-BR' : 'pt-PT'
    case 'RON':
      return 'ro'
    case 'RUS':
      return 'ru'
    case 'SPA':
      return 'es'
    case 'SWE':
      return 'sv'
    case 'THA':
      return 'th'
    case 'TUR':
      return 'tr'
    case 'ZHOs':
      return 'zh-Hans'
    case 'HUN':
      return 'hu'
    default:
      return 'en'
  }
}
