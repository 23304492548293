import type { StudyMessageStructure } from '../..'

import type { StudyQuestionStructure } from './model'

export function sortQuestionStructuresByAscendingPublishedTimestamp(
  structures: StudyQuestionStructure[],
): StudyQuestionStructure[] {
  return [...structures].sort(structure => structure.publishedTimestamp ?? 0)
}

export function sortMessageStructuresByAscendingPublishedTimestamp(
  structures: StudyMessageStructure[],
): StudyMessageStructure[] {
  return [...structures].sort(structure => structure.publishedTimestamp ?? 0)
}
