import { reject } from 'ramda'

import {
  isSentWaveSurvey,
  type SentWaveSurvey,
  type WaveSurvey,
  type WaveSurveyStatus,
} from './model'

export function rejectDraftWaveSurveys<W extends { status: WaveSurveyStatus }>(waves: W[]): W[] {
  return reject(({ status }) => status === 'draft', waves)
}

export function filterSentWaveSurveys(waves: WaveSurvey[]): SentWaveSurvey[] {
  return waves.filter(isSentWaveSurvey)
}
