import { useMemoize } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, type ComputedRef, ref, type Ref } from 'vue'

import {
  useCardResponseFiltersStore,
  useResultsDemographicFiltersStore,
  useSegmentFiltersStore,
} from '@attest/results-saved-filters'
import {
  AND,
  createRoundAnswersFilterNode,
  createRoundApprovedFilterNode,
  createRoundCardIdFilterNode,
  createRoundSegmentFilterNode,
  createRoundsFilter,
  createRoundSubjectsFilterNode,
  createRoundSuccessfulFilterNode,
  createRoundSurveyIdsFilterNode,
  type FilterNode,
  generateEFlString,
  type Round,
  type RoundCardAnswer,
} from '@attest/rounds'
import { useStudyStore } from '@attest/study'
import { isDefined } from '@attest/util'

import type { StudyResultsAnswer, StudyResultsRespondent } from '../models'

import { useStudyInsightsStore } from './study-insights'
import { useStudyResultsStore } from './study-results'
import { useStudyWaveFiltersStore } from './wave-filters'

export const useResultsRoundsStore = defineStore('resultsRounds', () => {
  const rounds = ref<Set<Round> | undefined>(undefined)
  const all = useRoundsAll({ rounds })
  const active = useRoundsActive(all)
  const successful = useRoundsSuccessful(active)
  const approved = useRoundsApproved(active)

  const filteredAnswers = useRoundsFilteredAnswers(active)
  const filteredSegments = useRoundsFilteredSegments(filteredAnswers)
  const filteredDemographics = useRoundsFilteredDemographics(filteredSegments)

  const filteredCard = useMemoize((card: { id: string }) =>
    useRoundsCard(filteredDemographics, card),
  )
  const activeCard = useMemoize((card: { id: string }) => useRoundsCard(active, card))
  const card = useMemoize((card: { id: string }) =>
    computed(() => ({
      filtered: filteredCard(card).value,
      active: activeCard(card).value,
    })),
  )

  return {
    toIds,
    rounds,
    all,
    active,
    successful,
    approved,
    successfulFiltered: useRoundsSuccessful(filteredDemographics),
    card,
  }
})

function useRoundsActive(base: ComputedRef<RoundsWithEFL>): ComputedRef<RoundsWithEFL> {
  return useRoundsWithEFL({
    base,
    efl: computed(() =>
      createRoundSurveyIdsFilterNode(
        useStudyWaveFiltersStore().filteredSentWaveSurveys.map(({ id }) => id),
      ),
    ),
  })
}

function useRoundsSuccessful(base: ComputedRef<RoundsWithEFL>): ComputedRef<RoundsWithEFL> {
  return useRoundsWithEFL({
    base,
    efl: computed(() => createRoundSuccessfulFilterNode()),
  })
}

function useRoundsApproved(base: ComputedRef<RoundsWithEFL>): ComputedRef<RoundsWithEFL> {
  return useRoundsWithEFL({
    base,
    efl: computed(() => createRoundApprovedFilterNode()),
  })
}

function useRoundsFilteredDemographics(
  base: ComputedRef<RoundsWithEFL>,
): ComputedRef<RoundsWithEFL> {
  return computed(() => {
    const resultsDemographicFiltersStore = useResultsDemographicFiltersStore()
    const studyInsightsStore = useStudyInsightsStore()
    if (!resultsDemographicFiltersStore.shouldFilter || !studyInsightsStore.shouldUsePanelFilters) {
      return base.value
    }

    return useRoundsWithEFL({
      base,
      efl: computed(() => {
        return AND(
          Object.entries(resultsDemographicFiltersStore.nameToFilters).map(([name, filter]) =>
            createRoundSubjectsFilterNode(name, filter),
          ),
        )
      }),
    }).value
  })
}

function useRoundsCard(
  base: ComputedRef<RoundsWithEFL>,
  card: { id: string },
): ComputedRef<RoundsWithEFL> {
  return useRoundsWithEFL({
    base,
    efl: computed(() => createRoundCardIdFilterNode(card.id)),
  })
}

function useRoundsFilteredAnswers(base: ComputedRef<RoundsWithEFL>): ComputedRef<RoundsWithEFL> {
  return computed(() => {
    const cardResponseFiltersStore = useCardResponseFiltersStore()
    const studyInsightsStore = useStudyInsightsStore()
    if (!cardResponseFiltersStore.shouldFilter || !studyInsightsStore.shouldUsePanelFilters) {
      return base.value
    }

    return useRoundsWithEFL({
      base,
      efl: computed(() =>
        createRoundAnswersFilterNode(
          Object.fromEntries(
            Object.entries(cardResponseFiltersStore.cardIdToFilters).map(([id, filters]) => [
              id,
              filters.answerIds,
            ]),
          ),
        ),
      ),
    }).value
  })
}

function useRoundsFilteredSegments(base: ComputedRef<RoundsWithEFL>): ComputedRef<RoundsWithEFL> {
  return computed(() => {
    const segmentFiltersStore = useSegmentFiltersStore()
    const studyInsightsStore = useStudyInsightsStore()
    const { currentSegmentFilter, shouldFilter } = segmentFiltersStore
    const efl = currentSegmentFilter?.efl
    if (!shouldFilter || !efl || !studyInsightsStore.shouldUsePanelFilters) {
      return base.value
    }

    return useRoundsWithEFL({
      base,
      efl: computed(() => createRoundSegmentFilterNode(efl)),
    }).value
  })
}

function respondentToRoundCardAnswers(
  respondent: StudyResultsRespondent,
  cards: { id: string; answers: (StudyResultsAnswer | undefined)[] }[],
): Record<string, RoundCardAnswer[]> {
  return Object.fromEntries(
    cards
      .map(card => {
        const answers = card.answers
          .map(answer => {
            if (!answer) {
              return undefined
            }

            if (!answer.responseIds.has(respondent.id)) {
              return undefined
            }

            return {
              id: answer.id,
              ...('text' in answer ? { text: answer.text } : {}),
              ...('sentiment' in answer ? { sentiment: answer.sentiment } : {}),
              ...('order' in answer ? { order: answer.order } : {}),
              ...('videoId' in answer ? { videoId: answer.videoId } : {}),
              ...('transcript' in answer ? { transcript: answer.transcript } : {}),
            }
          })
          .filter(isDefined)

        if (answers.length === 0) {
          return undefined
        }

        return [card.id, answers]
      })
      .filter(isDefined),
  )
}

function useRoundsAll(refs: { rounds: Ref<Set<Round> | undefined> }): ComputedRef<RoundsWithEFL> {
  const studyResultsStore = useStudyResultsStore()
  const studyStore = useStudyStore()

  const rounds = computed<Set<Round>>(() => {
    if (refs.rounds.value !== undefined) {
      return refs.rounds.value
    }
    if (studyStore.study) {
      const cards = Object.values(studyResultsStore.cardIdToCard).map(card => ({
        id: card.id,
        answers: Object.values(card.answerIdToAnswer),
      }))
      return new Set(
        [...Object.keys(studyResultsStore.respondentIdToRespondent)]
          .map(id => {
            const respondent = studyResultsStore.respondentIdToRespondent[id]
            const waveSurvey = studyStore.waveSurveyIdToWaveSurvey[respondent.waveSurveyId]
            const publishedTimestamp = waveSurvey?.publishedTimestamp

            if (publishedTimestamp === null || publishedTimestamp === undefined) {
              return undefined
            }
            return {
              id,
              audience: { id: waveSurvey.audienceId, country: waveSurvey.country },
              survey: { id: respondent.waveSurveyId },
              waveTimestamp: publishedTimestamp,
              subjects: respondent.nameToSegmentation,
              answers: respondentToRoundCardAnswers(respondent, cards),
              outcome: respondent.outcome,
            }
          })
          .filter(isDefined),
      )
    }

    return new Set<Round>()
  })

  return computed(() => {
    const filter = createRoundsFilter()
    return {
      filter: node => filter(rounds.value, node),
      rounds: rounds.value,
      ids: toIds(rounds.value),
      efl: undefined,
      eflString: undefined,
    }
  })
}

function toIds(rounds: Set<Round>): Set<string> {
  return new Set([...rounds].map(round => round.id))
}

type RoundsWithEFL = {
  filter: (node: FilterNode) => Set<Round>
  ids: Set<string>
  rounds: Set<Round>
  efl: FilterNode | undefined
  eflString: string | undefined
}
function useRoundsWithEFL({
  base,
  efl,
}: {
  base: ComputedRef<RoundsWithEFL>
  efl: ComputedRef<FilterNode | undefined>
}): ComputedRef<RoundsWithEFL> {
  return computed(() => {
    const filter = createRoundsFilter()
    const rounds = efl.value ? base.value.filter(efl.value) : base.value.rounds
    const combinedEFL =
      base.value.efl === undefined
        ? efl.value
        : AND([base.value.efl, ...(efl.value ? [efl.value] : [])])

    return {
      rounds: rounds,
      ids: toIds(rounds),
      filter: node => filter(rounds, node),
      efl: combinedEFL,
      eflString: combinedEFL === undefined ? undefined : generateEFlString(combinedEFL),
    }
  })
}
