<script lang="tsx">
import { defineComponent, type PropType } from 'vue'

import checkSVG from '@attest/_assets/icons/check-500.svg'
import checkInterdeminateSVG from '@attest/_assets/icons/check_indeterminate_small.svg'
import { createEmitter } from '@attest/vue-tsx'

import { SvgImage } from '../SvgImage'

import type { CheckboxCheckedState, CheckboxEvents } from './Checkbox.interface'

const DEFAULT_CHECKBOX_STATE = 'unchecked' as const

const emit = createEmitter<CheckboxEvents>()

const isTriggerKey = (key: string) => key === ' ' || key === 'Enter'

export default defineComponent({
  props: {
    value: { type: String, default: '' },
    checked: {
      type: [String, Boolean] as PropType<CheckboxCheckedState | boolean>,
      default: DEFAULT_CHECKBOX_STATE,
    },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    hydratedChecked(): CheckboxCheckedState {
      if (typeof this.checked === 'boolean') {
        if (this.checked) return 'checked'
        return 'unchecked'
      }

      return this.checked
    },

    ariaChecked(): 'mixed' | boolean {
      switch (this.hydratedChecked) {
        case 'checked':
          return true
        case 'unchecked':
          return false
        default:
          return 'mixed'
      }
    },
  },

  methods: {
    getCheckboxInput(): HTMLInputElement | undefined {
      return this.$refs.CheckboxInput as HTMLInputElement | undefined
    },

    emitCurrentCheckedState(event: Event) {
      const checkbox = this.getCheckboxInput()
      if (!checkbox || this.disabled) return

      emit(this, 'select', { checked: checkbox.checked ? 'checked' : 'unchecked', event })
    },

    emitToggledCheckedState(event: Event) {
      const checkbox = this.getCheckboxInput()
      if (!checkbox || this.disabled) return

      emit(this, 'select', { checked: checkbox.checked ? 'unchecked' : 'checked', event })
    },
  },

  render() {
    const baseClass = 'c-checkbox'
    const containerClass = {
      [`${baseClass}`]: true,
      [`${baseClass}--intermediate`]: this.hydratedChecked === 'intermediate',
      [`${baseClass}--disabled`]: this.disabled || false,
    }

    const defaultSlots = this.$slots.default?.()
    const customCheckedSlot = this.$slots.customChecked?.()

    const visualBoxBaseClass = `${baseClass}__visual-box`
    const visualBoxClass = {
      [visualBoxBaseClass]: true,
      [`${visualBoxBaseClass}--tick`]: !customCheckedSlot,
      [`${visualBoxBaseClass}--has-content`]: !!defaultSlots,
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <label
        class={containerClass}
        data-name="Checkbox"
        onClick={(e: Event) => {
          e.stopPropagation()
        }}
        onKeydown={(e: KeyboardEvent) => {
          if (isTriggerKey(e.key)) {
            e.stopPropagation()
            e.preventDefault()
            this.emitCurrentCheckedState(e)
          }

          emit(this, 'keydown', e)
        }}
      >
        <input
          type="checkbox"
          class="c-checkbox__input u-screen-reader-only"
          value={this.value}
          checked={this.hydratedChecked === 'checked'}
          onChange={this.emitCurrentCheckedState}
          disabled={this.disabled}
          ref="CheckboxInput"
          aria-hidden
          hidden
        />
        <span
          class={visualBoxClass}
          role="checkbox"
          ref="checkboxInner"
          tabindex={this.disabled ? -1 : 0}
          data-name="CheckboxValue"
          aria-checked={this.ariaChecked}
          aria-disabled={this.disabled}
          aria-label={this.$attrs['aria-label'] as string}
          onFocus={e => emit(this, 'focus', e)}
          onBlur={e => emit(this, 'blur', e)}
          onKeydown={(e: KeyboardEvent) => {
            if (isTriggerKey(e.key)) {
              e.preventDefault()
              e.stopPropagation()
              this.emitToggledCheckedState(e)
            }
            emit(this, 'keydown', e)
          }}
        >
          {this.ariaChecked &&
            (customCheckedSlot || (
              <SvgImage svg={this.checked === 'intermediate' ? checkInterdeminateSVG : checkSVG} />
            ))}
        </span>
        {defaultSlots && <span>{defaultSlots}</span>}
      </label>
    )
  },
})
</script>

<style lang="postcss">
.c-checkbox {
  --c-checkbox-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--attest-color-text-default);
  font: var(--attest-font-body-2);
  outline: none;

  &__visual-box + * {
    margin-left: var(--attest-spacing-2);
  }

  &__visual-box {
    position: relative;
    display: flex;
    min-width: var(--c-checkbox-size);
    min-height: var(--c-checkbox-size);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border: var(--attest-border-width) solid var(--attest-color-border-default);
    border-radius: var(--attest-border-radius);
    background: var(--attest-color-surface-default);
    color: var(--attest-color-text-on-interactive);
    outline: none;

    & > .c-svg-image {
      position: absolute;
      width: unset;
      height: unset;
      inset: 0;
    }

    &:hover,
    &:active {
      border-color: var(--attest-color-border-hover);
    }

    &:active {
      background-color: var(--attest-color-surface-hover);
    }
  }

  &__visual-box:focus-visible::before {
    position: absolute;
    border: var(--attest-border-width-s) solid var(--attest-color-focus-default);
    border-radius: 6px;
    content: '';
    inset: -4px;
  }

  &:not(&--disabled) &__input:checked ~ &__visual-box {
    border-color: var(--attest-color-interactive-default);
  }

  &:is(&--disabled, &:disabled, &[disabled]) &__input:checked ~ &__visual-box {
    border-color: var(--attest-color-border-subdued);
    background-color: var(--attest-color-interactive-subdued-disabled);
    outline: none;
  }

  &__input:checked ~ &__visual-box {
    background-color: var(--attest-color-interactive-default);

    &::after {
      border-color: var(--attest-color-surface-default);
      opacity: 1;
    }
  }

  &--disabled {
    color: var(--attest-color-interactive-disabled);
    pointer-events: none;

    ^&__visual-box {
      border-color: var(--attest-color-interactive-disabled);
      background-color: var(--attest-color-background-default);

      &::after {
        border-color: var(--attest-color-interactive-disabled);
      }
    }
  }

  &--intermediate &__input ~ &__visual-box {
    border-color: var(--attest-color-interactive-default);
    background-color: var(--attest-color-interactive-default);

    &::after {
      top: 50%;
      right: 0;
      left: 0;
      height: 2px;
      border-left: 0;
      margin: 0 auto;
      opacity: 1;
      transform: translateY(-50%);
    }
  }
}
</style>
