import type { UserApiResponse } from '@attest/_api/model/users'

import type { ProfileRole, User } from './model'

export function toProfileRole(apiProfileRole: string): ProfileRole {
  switch (apiProfileRole) {
    case 'global_admin':
      return 'GLOBAL_ADMIN'
    case 'org_admin':
      return 'ORGANISATION_ADMIN'
    case 'impersonator':
      return 'IMPERSONATOR'
    case 'attest_survey_manager':
      return 'ATTEST_SURVEY_MANAGER'
    case 'member':
    default:
      return 'MEMBER'
  }
}

export function toUserModel(userResponse: UserApiResponse): User {
  return {
    guid: userResponse.guid,
    email: userResponse.email,
    firstName: userResponse.first_name,
    lastName: userResponse.last_name,
    roles: userResponse.roles?.map(toProfileRole),
  }
}
