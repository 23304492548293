<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create } from '@attest/vue-tsx'

import type { Variant as StatusIndicatorVariant } from './StatusIndicator/StatusIndicator.interface'
import StatusIndicator from './StatusIndicator/StatusIndicator.vue'

type CounterChipProps = {
  styleVariant?: Variant
  size?: 'default' | 'small'
  indicatorVariant?: StatusIndicatorVariant
}

export type Variant =
  | 'default'
  | 'midnight'
  | 'primary'
  | 'secondary'
  | 'orange'
  | 'green'
  | 'light-green'
  | 'light-red'
  | 'red'
  | 'gold'
  | 'gold-light'
  | 'neutral'
  | 'blue'

const DEFAULT_STYLE_VARIANT: Variant = 'default'

const CounterChip: FunctionalComponent<CounterChipProps> = (props, { slots }) => {
  const { size = 'default', styleVariant, indicatorVariant, ...attrs } = props
  const bannerVariant = styleVariant || DEFAULT_STYLE_VARIANT
  const baseClass = 'c-counter-chip'
  const counterChipClass = {
    [baseClass]: true,
    [`${baseClass}--indicator-${indicatorVariant}`]: !!indicatorVariant,
    [`${baseClass}--style-${bannerVariant}`]: true,
    [`${baseClass}--size-${size}`]: true,
  }

  return (
    <div class={counterChipClass} {...attrs}>
      {!!indicatorVariant && (
        <StatusIndicator data-name="CounterChipStatusIndicator" class={`${baseClass}__status`} />
      )}
      {slots.default?.()}
    </div>
  )
}
export default create<CounterChipProps>(CounterChip)
</script>
<style lang="postcss">
.c-counter-chip {
  position: relative;
  display: inline-flex;
  width: max-content;
  height: 36px;
  box-sizing: border-box;
  align-items: center;
  padding: 0 var(--attest-spacing-4);
  border-radius: 18px;
  font: var(--attest-font-ui-element-2);
  text-align: center;
  white-space: nowrap;

  &--style-default {
    background-color: var(--attest-color-interactive-subdued-hover);
    color: var(--attest-color-interactive-default);
  }

  &--style-primary {
    background-color: var(--attest-color-interactive-default);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-secondary {
    background-color: var(--attest-color-interactive-subdued-hover);
    color: var(--attest-color-text-default);
  }

  &--style-red {
    background-color: var(--attest-color-interactive-negative-default);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-light-red {
    background-color: var(--attest-palette-red-60);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-gold {
    background-color: var(--attest-palette-yellow-40);
    color: var(--attest-color-text-default);
  }

  &--style-gold-light {
    background-color: var(--attest-palette-yellow-20);
    color: var(--attest-color-text-default);
  }

  &--style-midnight {
    background-color: var(--attest-color-interactive-active);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-orange {
    background-color: var(--attest-palette-firecracker-60);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-green {
    background-color: var(--attest-color-interactive-positive-default);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-light-green {
    background-color: var(--attest-palette-green-60);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-blue {
    background-color: var(--attest-palette-blue-30);
    color: var(--attest-color-text-default);
  }

  &--style-neutral {
    background-color: var(--attest-color-surface-hover);
    color: var(--attest-color-text-default);
  }

  &--size-small {
    height: 24px;
    padding: 0 var(--attest-spacing-3);
  }

  &__status {
    position: absolute;
    top: calc(-1 * var(--attest-border-width-s));
    right: calc(-1 * var(--attest-spacing-1));
  }
}
</style>
