import { CONFIG } from '@attest/config'

const VAT = 0.2

export const centsToCreditCents = (
  cents: number,
  rate: number = CONFIG.APP.PRICING.CREDIT_VALUE_PENCE,
): number => {
  if (rate === 0) return 0
  return Math.floor(cents / rate) * 100
}

export const creditCentsToCredit = (creditCents: number): number => {
  return Math.floor(creditCents / 100)
}

export const creditToCreditCents = (credit: number): number => {
  return Math.floor(credit * 100)
}

export const centsToCentsWithVat = (cents: number): number => {
  return Math.ceil(cents + cents * VAT)
}
