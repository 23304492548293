import { ascend, descend, sort } from 'ramda'

import { getUTCWaveStartTimestamp } from './transformer'

export function sortWavesByDescendingStartTime<
  WaveSurvey extends { startTimestamp: number | null; timeZoneId: string | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  return sort(
    descend(wave => getUTCWaveStartTimestamp(wave) ?? Number.POSITIVE_INFINITY),
    waves,
  )
}

export function sortWavesByAscendingStartTime<
  WaveSurvey extends { startTimestamp: number | null; timeZoneId: string | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  return sort(
    ascend(wave => getUTCWaveStartTimestamp(wave) ?? Number.POSITIVE_INFINITY),
    waves,
  )
}

export function sortWavesByDescendingPublishedTimestamp<
  WaveSurvey extends { publishedTimestamp: number | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  return sortWavesByAscendingPubishedTimestamp(waves).toReversed()
}

export function sortWavesByAscendingPubishedTimestamp<
  WaveSurvey extends { publishedTimestamp: number | null },
>(waves: WaveSurvey[]): WaveSurvey[] {
  const getWaveComparitor = (wave: WaveSurvey): number =>
    wave.publishedTimestamp ?? Number.POSITIVE_INFINITY
  return waves.toSorted((a, b) => getWaveComparitor(a) - getWaveComparitor(b))
}
