<script lang="tsx">
import { nanoid } from 'nanoid'
import { defineComponent } from 'vue'

import { createEmitter } from '@attest/vue-tsx'

import Arrow from '../Arrow.vue'
import { BareButton } from '../BareButton'
import ExpandAnimatingPane from '../ExpandAnimatingPane.vue'

import type { AccordionEvents } from './'

const emit = createEmitter<AccordionEvents>()
const uuid = nanoid()
export default defineComponent({
  props: {
    isExpandedByDefault: { type: Boolean, default: false },
    triggerClass: { type: String, default: '' },
    panelClass: { type: String, default: '' },
    direction: { type: String, default: 'down' },
    theme: { type: String, default: 'default' },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return {
      isExpanded: this.isExpandedByDefault,
    }
  },

  watch: {
    isExpandedByDefault(isExpanded) {
      this.isExpanded = isExpanded
    },
    disabled: {
      immediate: false,
      handler(isDisabled: boolean) {
        if (isDisabled) this.isExpanded = false
      },
    },
  },

  methods: {
    clickHandler(event: Event): void {
      this.isExpanded = !this.isExpanded
      emit(this, 'toggle', event, this.isExpanded)
    },
  },

  render() {
    const baseClass = 'c-accordion'

    const expandingPane = (
      <ExpandAnimatingPane class={{ [this.panelClass]: !!this.panelClass }}>
        <div
          id={uuid}
          role="region"
          aria-labelledby={`Accordion-${uuid}`}
          hidden={!this.isExpanded}
          data-name="AccordionPanel"
        >
          {this.$slots.item?.()}
        </div>
      </ExpandAnimatingPane>
    )
    return (
      <div
        class={{
          [baseClass]: true,
          [`${baseClass}--theme-${this.theme}`]: this.theme,
          [`${baseClass}--expanded`]: this.isExpanded,
        }}
      >
        {this.direction === 'up' && expandingPane}
        <BareButton
          class={{
            [`${baseClass}__trigger`]: true,
            [this.triggerClass]: !!this.triggerClass,
          }}
          aria-expanded={this.isExpanded}
          aria-controls={`Accordion-${uuid}`}
          id={`Accordion-${uuid}`}
          data-name="AccordionButton"
          data-expanded={this.isExpanded}
          onClick={this.clickHandler}
          onMouseenter={() => emit(this, 'triggerHover')}
          disabled={this.disabled}
        >
          {this.$slots.title?.()}
          <Arrow
            class={`${baseClass}__trigger-arrow`}
            color="white"
            aria-hidden="true"
            direction={this.isExpanded ? 'down' : 'up'}
          />
        </BareButton>
        {this.$slots.subtitle?.()}
        {this.direction === 'down' && expandingPane}
      </div>
    )
  },
})
</script>

<style lang="postcss">
.c-accordion {
  &__trigger {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--attest-spacing-2);
    color: var(--attest-color-text-default);
    cursor: pointer;

    &:hover {
      color: var(--attest-color-background-inverse);
    }

    &:disabled {
      color: var(--attest-color-interactive-disabled);
    }
  }

  &__trigger[disabled] &__trigger-arrow {
    color: var(--attest-color-interactive-disabled);
  }

  &__trigger:focus:focus-visible &__trigger-arrow {
    outline: auto;
  }

  &__trigger-arrow {
    flex: 0 0 auto;
    margin-left: var(--attest-spacing-2);
  }

  &--theme-dark &__trigger {
    color: var(--attest-color-surface-5);

    &:hover,
    &:focus {
      color: var(--attest-color-background-default);
    }
  }

  &--theme-dark:matches(&--expanded) &__trigger {
    color: var(--attest-color-background-default);
  }

  &--theme-reverse &__trigger {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &--theme-reverse &__trigger-arrow {
    margin-right: var(--attest-spacing-1);
    margin-left: 0;
  }

  &--theme-default &__trigger {
    flex-direction: row;
    justify-content: flex-start;
  }

  &--theme-default &__trigger-arrow {
    margin-right: 0;
    margin-left: var(--attest-spacing-2);
  }
}
</style>
