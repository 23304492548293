import { createSurveyEntity, trackAction } from '@attest/tracking'

export function trackConfirm(guid: string, event: Event): void {
  trackAction({
    id: 'delete-survey-modal-confirm',
    entities: [createSurveyEntity({ id: guid })],
    event,
  })
}

export function trackCancel(guid: string, event: Event): void {
  trackAction({
    id: 'delete-survey-modal-cancel',
    entities: [createSurveyEntity({ id: guid })],
    event,
  })
}
