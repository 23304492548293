import axios from 'axios'

import type { ResponseData } from '@attest/_api'
import type { MFAError } from '@attest/_api/model/error'

export type PasswordError =
  | 'MISSING_PASSWORD'
  | 'PASSWORD_TOO_SHORT'
  | 'INCORRECT_PASSWORD'
  | 'NO_PASSWORD'

export type UserChangePasswordRequestData = {
  currentPassword: string
  newPassword: string
  mfaCode?: string
}

export type UserChangePasswordErrorData = {
  currentPassword: PasswordError
  newPassword: PasswordError
  mfaCode: MFAError
}

export type PasswordErrorResponse = {
  'new-password': PasswordError
  'current-password': PasswordError
  reason: MFAError
}

type UserChangePasswordResponseData = ResponseData<Record<string, never>, PasswordErrorResponse>

const PASSWORD_CHANGE_URL = '/json/password-change'

export async function changePassword(data: UserChangePasswordRequestData) {
  const formData = new URLSearchParams()
  formData.append('current-password', data.currentPassword)
  formData.append('new-password', data.newPassword)
  if (data.mfaCode) formData.append('code', data.mfaCode)

  try {
    const response = await axios.post<UserChangePasswordResponseData>(
      PASSWORD_CHANGE_URL,
      formData,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    )

    return response
  } catch (e: any) {
    const response = e.response.data as UserChangePasswordResponseData

    if (response.error) {
      throw {
        currentPassword: response.error['current-password'] || null,
        newPassword: response.error['new-password'] || null,
        mfaCode: response.error.reason,
      }
    }

    throw {
      currentPassword: 'UNKNOWN',
      newPassword: null,
    }
  }
}
