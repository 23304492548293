<script lang="tsx">
import { defineComponent, type IntrinsicElementAttributes, type PropType } from 'vue'

import { create, createEmitter } from '@attest/vue-tsx'

import PausableTimeout from '../../abstract/PausableTimeout.vue'
import Block from '../../common/Block.vue'
import CounterChip from '../../common/CounterChip.vue'
import { SvgIcon } from '../../common/SvgIcon'

type ToastMode = 'success' | 'primary' | 'error'

export type ToastProps = {
  content: string
  badge?: string
  icon?: string | { default: string }
  uid: string
  duration: number
  isPersist?: boolean
  mode?: ToastMode
}

export type ToastEvents = {
  dismiss(): void
}

const emit = createEmitter<ToastEvents>()

const Toast = defineComponent({
  props: {
    content: { type: String, required: true },
    badge: { type: String as PropType<string | null>, default: null },
    icon: { type: Object as PropType<string | { default: string; required: true }>, default: null },
    uid: { type: String, required: true },
    duration: { type: Number, required: true },
    mode: { type: String as PropType<ToastMode>, default: 'success' },
    isPersist: { type: Boolean, default: false },
  },

  data() {
    return {
      dismissed: false,
    }
  },

  methods: {
    dismiss() {
      if (!this.dismissed) {
        emit(this, 'dismiss')
      }
      this.dismissed = true
    },
  },

  render() {
    const { mode } = this
    const baseClass = 'c-toast'

    return (
      <Block
        onClick={this.dismiss}
        class={`${baseClass} ${baseClass}--style-${mode}`}
        role={this.mode === 'error' ? 'alert' : 'status'}
      >
        <PausableTimeout onTimeout={this.dismiss} duration={this.duration} pause={this.isPersist} />
        {this.icon && <SvgIcon class={`${baseClass}__icon`} svg={this.icon} color="white" />}
        {this.badge && (
          <CounterChip
            data-name="ToastBadge"
            styleVariant="light-green"
            class={`${baseClass}__badge`}
            size="small"
          >
            {this.badge}
          </CounterChip>
        )}
        {this.content}
      </Block>
    )
  },
})

export default create<ToastProps & IntrinsicElementAttributes['div'], ToastEvents>(Toast)
</script>

<style lang="postcss">
.c-toast {
  display: flex;
  min-width: 300px;
  box-sizing: border-box;
  justify-content: center;
  padding: var(--attest-spacing-2) var(--attest-spacing-4);
  border: 0;
  border-radius: var(--attest-border-radius);
  font: var(--attest-font-ui-element-2);
  pointer-events: all;

  &__icon {
    margin-right: var(--attest-spacing-2);
  }

  &__badge {
    min-width: calc(var(--attest-spacing-6) - var(--attest-spacing-1));
    margin-right: var(--attest-spacing-2);
  }

  @context scoped {
    &--style-success {
      background: var(--attest-color-interactive-positive-default);
      color: var(--attest-color-text-on-interactive);
    }

    &--style-primary {
      background: var(--attest-color-interactive-default);
      color: var(--attest-color-text-on-interactive);
    }

    &--style-error {
      background: var(--attest-color-interactive-negative-default);
      color: var(--attest-color-text-on-interactive);
    }
  }
}
</style>
