export type StudyResultsRespondentId = string

type StudyResultsRespondentOutcome = 'COMPLETED' | 'SCREENED_OUT' | 'APPROVED'
export type StudyResultsRespondent = {
  readonly id: StudyResultsRespondentId
  readonly waveSurveyId: string
  readonly outcome: StudyResultsRespondentOutcome
  readonly nameToSegmentation: Record<string, StudyResultsRespondentSegmentation>
}

export type StudyResultsRespondentSegmentation = number | string | string[]

export function isCompletedRespondent(respondent: StudyResultsRespondent): boolean {
  return respondent.outcome === 'COMPLETED' || respondent.outcome === 'APPROVED'
}

export function isApprovedRespondent(respondent: StudyResultsRespondent): boolean {
  return respondent.outcome === 'APPROVED'
}
