import { createStudyEntity, type StudyEntity, type StudyEntityData } from '@attest/tracking'

import { useStudyStore } from './store'

export { createStudyEntity }

export function createStudyEntityWithType(data: StudyEntityData): StudyEntity {
  const studyStore = useStudyStore()
  return createStudyEntity({
    ...data,
    ...(studyStore.isStudy(data.id) && {
      type: studyStore.isVideoResponseSurvey ? 'qual' : 'quant',
    }),
  })
}
