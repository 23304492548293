import type { GroupCardError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import { type Card, isGroupCard, isTextCard } from '@attest/editor-card'
import type { RoutingGraph } from '@attest/editor-routing-graph'

import { getCardByGuid, isGroupedIn } from '../card-service'

type GroupCardErrorType = Exclude<
  GroupCardError['type'],
  'GROUP_CARD_CONTAINS_CARDS_WITH_CARD_ERRORS'
>

export const getGroupCardErrors = createGetErrorsFor<
  GroupCardErrorType,
  { card: Card; routingGraph: RoutingGraph; cards: Card[] }
>({
  EMPTY_GROUP({ card }) {
    if (!isGroupCard(card)) return false
    return card.group.cards.length === 0
  },
  ADJACENT_TEXT_CARDS_IN_GROUP({ card }) {
    if (!isGroupCard(card)) return false
    const { cards } = card.group
    return cards.some((groupedCard, index) => {
      if (!isTextCard(groupedCard)) return false
      const prevCard = cards[index - 1]
      const nextCard = cards[index + 1]
      return (prevCard && isTextCard(prevCard)) || (nextCard && isTextCard(nextCard))
    })
  },
  ADJACENT_TEXT_CARD_AND_GROUP_TEXT_CARD({ card, routingGraph, cards }) {
    if (!isGroupCard(card)) {
      return false
    }

    const firstCard = card.group.cards[0]
    const lastCard = card.group.cards[card.group.cards.length - 1]
    if (firstCard === undefined || lastCard === undefined) {
      return false
    }

    const isFirstGroupCardTextCard = isTextCard(firstCard)
    const isLastGroupCardTextCard = isTextCard(lastCard)

    return (
      (isFirstGroupCardTextCard &&
        routingGraph.someInNeighbor(card.guid, id => {
          const previousCard = cards.find(({ guid }) => guid === id)
          return previousCard && isTextCard(previousCard)
        })) ||
      (isLastGroupCardTextCard &&
        routingGraph.someOutNeighbor(lastCard.guid, id => {
          const nextCard = cards.find(({ guid }) => guid === id)
          return nextCard && isTextCard(nextCard)
        }))
    )
  },
  ADJACENT_TEXT_CARDS_BETWEEN_GROUPS({ card, routingGraph, cards }) {
    if (!isGroupCard(card)) {
      return false
    }
    const firstCard = card.group.cards[0]
    const lastCard = card.group.cards[card.group.cards.length - 1]
    if (firstCard === undefined || lastCard === undefined) {
      return false
    }

    const isFirstGroupedCardTextCard = isTextCard(firstCard)
    const isLastGroupedCardTextCard = isTextCard(lastCard)

    return (
      (isFirstGroupedCardTextCard &&
        routingGraph.someInNeighbor(card.guid, id => {
          const previousCard = getCardByGuid(cards, id)
          return previousCard && isGroupedIn(previousCard, cards) && isTextCard(previousCard)
        })) ||
      (isLastGroupedCardTextCard &&
        routingGraph.someOutNeighbor(lastCard.guid, id => {
          const nextGroupedCard = getCardByGuid(cards, id)?.group.cards[0]
          return nextGroupedCard && isTextCard(nextGroupedCard)
        }))
    )
  },
})
