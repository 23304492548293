type HeadingAlign = 'left' | 'center' | 'right'
type HeadingType =
  | 'display-extra-large'
  | 'display-large'
  | 'display'
  | 'display-small'
  | 'body-large'
  | 'body'
  | 'caption'
  | 'tiny'
type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
type HeadingTheme = 'default' | 'dark' | 'inverse' | 'light' | 'error'

export const HeadingTypeTagMap: Record<HeadingType, HeadingTag> = {
  'display-extra-large': 'h1',
  'display-large': 'h1',
  display: 'h1',
  'display-small': 'h2',
  'body-large': 'h3',
  body: 'h4',
  caption: 'h5',
  tiny: 'h5',
}

export type HeadingProps = {
  type: HeadingType
  elementTag?: HeadingTag
  align?: HeadingAlign
  theme?: HeadingTheme
  isBold?: boolean
}
