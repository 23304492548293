import type { RouteRecordRaw } from 'vue-router'

export const TRENDS_BY_ATTEST_ROUTES = {
  ROOT: { name: 'TRENDS_BY_ATTEST', path: '/trends-by-attest' },
} as const

export function createTrendsByAttestRouteRecord(): RouteRecordRaw {
  return {
    name: TRENDS_BY_ATTEST_ROUTES.ROOT.name,
    path: TRENDS_BY_ATTEST_ROUTES.ROOT.path,
    component: async () => (await import('./components/view/TrendsByAttest.vue')).default,
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
    },
  }
}
