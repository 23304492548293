import type { Audience } from '..'
import { QUAL_COUNTRIES, QUAL_DEMOGRAPHICS, QUAL_LANGUAGES } from '../constant'

export function audienceIsSupportedForVideoQuestionSurvey(targeting: Audience): boolean {
  return (
    QUAL_COUNTRIES.includes(targeting.country) &&
    QUAL_LANGUAGES.includes(targeting.language) &&
    Object.keys(targeting.segmentations).every(key => QUAL_DEMOGRAPHICS.includes(key))
  )
}
