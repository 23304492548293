import { nanoid } from 'nanoid'

export class TrackingTracer {
  public name: string
  public trace: TrackingTrace

  constructor(name: string, trace?: TrackingTrace) {
    this.name = name
    this.trace = new TrackingTrace(trace)
  }

  public next(): TrackingTracer {
    this.trace = this.trace.next()
    return this
  }
}

class TrackingTrace {
  public id: string
  public traceId: string
  public parentId: string | undefined

  constructor(trace?: TrackingTrace) {
    this.id = nanoid()
    this.traceId = trace?.traceId ?? nanoid()
    this.parentId = trace?.id
  }

  public next(): TrackingTrace {
    return new TrackingTrace(this)
  }
}

const tracerRegistry = new Map<string, TrackingTracer>()

export function createTrackingTracer(name: string): TrackingTracer {
  const tracer = tracerRegistry.get(name)
  if (tracer) return tracer

  const newTracer = new TrackingTracer(name)
  tracerRegistry.set(name, newTracer)
  return newTracer
}
