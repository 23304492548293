import type { APIInterestTag, GetProfileResponse } from '@attest/_api/model/users'

import type { InterestTag, Profile } from './model'

export function toProfile(args: GetProfileResponse): Profile {
  return {
    guid: args.guid,
    email: args.email,
    firstName: args.first_name,
    lastName: args.last_name,
    jobRole: args.job_role,
    phoneNumber: args.phone_number,
    hasResearchExperience: args.has_research_experience,
    interestTags: toProfileInterestTags(args.interest_tags),
    companySector: args.company_sector,
    companySize: args.company_size,
    mfa: args.mfa,
  }
}

export function toProfileInterestTags(args?: APIInterestTag[]): InterestTag[] | undefined {
  return (
    args?.map(tag => ({
      isSelected: tag.selected,
      name: tag.name,
    })) || undefined
  )
}
