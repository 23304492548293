import type { AxiosResponse } from 'axios'

import { type ResponseData, service } from '@attest/_api'
import type { RegistrationExperience } from '@attest/authentication'

export type UserRegisterRequestData = {
  email: string
  password: string
  firstName: string
  lastName: string
  terms: boolean
  inviteCode?: string
  isMarketingEmailOptOut: boolean
  experience?: RegistrationExperience
}

export type UserRegisterResponseData = {} & ResponseData<Record<string, never>>

const REGISTER_URL = '/json/register'

export async function register(
  data: UserRegisterRequestData,
): Promise<AxiosResponse<UserRegisterResponseData>> {
  const formData = new URLSearchParams({
    email: data.email,
    password: data.password,
    'first-name': data.firstName,
    'last-name': data.lastName,
    terms: `${data.terms}`,
    'marketing-email-opt-out': `${data.isMarketingEmailOptOut}`,
    ...(typeof data.experience !== 'undefined' && { experience: data.experience }),
    ...(!!data.inviteCode && { 'invite-code': data.inviteCode }),
  })

  return service().post<UserRegisterResponseData>(REGISTER_URL, formData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}
