import type { RouteLocation } from 'vue-router'

import type { Entity } from './base'

type GetTrackingEntities = (to: RouteLocation) => Entity<unknown>[]

export function useGetTrackingEntities(getTrackingEntities: GetTrackingEntities): {
  getTrackingEntities: GetTrackingEntities
} {
  return { getTrackingEntities }
}
