import { c } from '../copy'

export function formatOrdinal(
  n: number,
  options: { locale: string } = { locale: 'en-GB' },
): string {
  return `${n}${c(
    `ordinal.suffix.${new Intl.PluralRules(options.locale, { type: 'ordinal' }).select(n)}`,
  )}`
}
