import type { ChoiceAnswerError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import type { Card, OptionAnswer } from '@attest/editor-card'
import { SETTINGS } from '@attest/editor-settings'

import { hasImage } from '../card-service'

export const getQuestionAnswerErrors = createGetErrorsFor<
  ChoiceAnswerError['type'],
  {
    isImage: boolean
    answer: Card['question']['options']['answers'][number]
    answers: OptionAnswer[]
    placeholderErrorsEnabled?: boolean
  }
>({
  DUPLICATE_ANSWERS: ({ answer, answers, isImage }) => {
    return (
      !isImage &&
      answer.text.trim() !== '' &&
      answers
        .filter(({ id }) => id !== answer.id)
        .some(({ text }) => text.trim().toLowerCase() === answer.text.trim().toLowerCase())
    )
  },
  IMAGE_CAPTION_ANSWER_TOO_LONG: ({ isImage, answer }) => {
    if (!isImage) return false
    return answer.text.length > SETTINGS.QUESTION.MAX_ANSWER_LENGTH
  },
  TEXT_ANSWER_TOO_LONG: ({ isImage, answer }) => {
    if (isImage) return false
    return answer.text.length > SETTINGS.QUESTION.MAX_ANSWER_LENGTH
  },
  QUALIFYING_EMPTY_ANSWER: ({ isImage, answer }) => {
    if (!answer.isQualifying) return false
    if (isImage) return !hasImage(answer)
    return answer.text.length === 0
  },
  IMAGE_ANSWERS_MISSING_IMAGES: ({ isImage, answer }) => {
    if (!isImage) return false
    return answer.media === null && answer.text.length > 0
  },
  ANSWER_HAS_PLACEHOLDER: ({ answer, isImage, placeholderErrorsEnabled }) => {
    if (isImage) return false
    if (!placeholderErrorsEnabled) return false
    return SETTINGS.TEMPLATE.PLACEHOLDER_REGEX.test(answer.text)
  },
})

export const hasMinValidAnswers = (card: Card): boolean => {
  const { isImage, answers } = card.question.options

  const validAnswers = answers.filter(answer => {
    if (isImage) return hasImage(answer)
    return answer.text.length > 0
  })

  return validAnswers.length >= SETTINGS.QUESTION.MIN_ANSWER_COUNT
}
