<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import type { BannerProps, BannerVariant } from './Banner.interface'

const DEFAULT_STYLE_VARIANT: BannerVariant = 'midnight'

const Banner: FunctionalComponent<BannerProps> = (props, { slots }) => {
  const { styleVariant, round, ...attrs } = props

  const bannerVariant = styleVariant || DEFAULT_STYLE_VARIANT
  const baseClass = 'c-banner'
  const bannerClass = {
    [baseClass]: true,
    [`${baseClass}--style-round`]: !!round,
    [`${baseClass}--style-${bannerVariant}`]: true,
  }

  return (
    <div class={bannerClass} {...attrs}>
      {slots.default?.()}
    </div>
  )
}

export default Banner
</script>
<style lang="postcss">
.c-banner {
  padding: var(--attest-spacing-3);
  font: var(--attest-font-body-1);
  text-align: left;

  &--style-round {
    border-radius: var(--attest-border-radius);
  }

  &--style-primary,
  &--style-midnight {
    color: var(--attest-color-text-on-interactive);
  }

  &--style-primary {
    background-color: var(--attest-color-interactive-default);
  }

  &--style-midnight {
    background-color: var(--attest-color-interactive-active);
  }

  &--style-error {
    background-color: var(--attest-color-interactive-negative-default);
    color: var(--attest-color-text-on-interactive);
  }

  &--style-grey {
    background-color: var(--attest-color-surface-subdued);
    color: var(--attest-color-background-inverse);
  }

  &--style-light-green {
    background-color: var(--attest-color-decorative-green-border-subdued);
  }

  &--style-light-red {
    background-color: var(--attest-color-decorative-red-border-subdued);
  }
}
</style>
