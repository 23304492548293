<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import type { CardProps } from './Card.interface'

const Card: FunctionalComponent<CardProps> = (props, { slots }) => {
  const baseClass = 'c-card'
  const { isSelected, ...attrs } = props

  const classes = {
    [baseClass]: true,
    [`${baseClass}--selected`]: isSelected,
  }

  return (
    <div class={classes} {...attrs}>
      {slots.default?.()}
    </div>
  )
}

export default Card
</script>

<style lang="postcss">
.c-card {
  border-radius: var(--attest-border-radius-l);
  background-color: var(--attest-color-surface-default);
  box-shadow: var(--attest-shadow-card);

  &--selected {
    box-shadow: var(--attest-shadow-card-active);
  }
}
</style>
