import type { SurveyGroup } from '../model'

export function getRandomizedGroups(groups: SurveyGroup[]): SurveyGroup[] {
  const randomizedGroupChildrenGuidSet = getRandomizedGroupChildrenGuidSet(groups)

  return groups.filter(group => randomizedGroupChildrenGuidSet.has(group.id))
}

function getRandomizedGroupChildrenGuidSet(groups: SurveyGroup[]): Set<string> {
  return new Set(
    groups.flatMap(group =>
      group.children.filter(child => child.isRandomized).map(child => child.id),
    ),
  )
}

export function getSurveyGroupForChildGuid(id: string, groups: SurveyGroup[]): SurveyGroup | null {
  return groups.find(group => group.children.some(child => child.id === id)) ?? null
}
