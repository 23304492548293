export function filterSet<T>(
  predicate: (value: T, index: number, set: T[]) => boolean,
  set: Set<T>,
): Set<T> {
  return new Set([...set].filter(predicate))
}

export function mapSet<T, U>(
  mapper: (value: T, index: number, set: T[]) => U,
  set: Set<T>,
): Set<U> {
  return new Set([...set].map(mapper))
}
