import { forEachRootNode, type RouteNode, type RoutingGraph } from '@attest/routing-graph'
import { sum } from '@attest/util'

type SubtreeWeightsCalculateFn = (subtreeRootNodeKey: string, neighbourValues: number[]) => number

function subGraphWeightsByDfsWithPredicate({
  graph,
  fromNode,
  subtreeWeights = {},
  followEdgePredicate,
  calculateWeightsOfSubtree,
}: {
  graph: RoutingGraph
  fromNode: string
  subtreeWeights: Record<string, number>
  followEdgePredicate: (sourceNode: string, neighbourNode: string) => boolean
  calculateWeightsOfSubtree: SubtreeWeightsCalculateFn
}): Record<string, number> {
  let cache = { ...subtreeWeights }
  const neighbourValues = graph
    .filterOutNeighbors(fromNode, neighbour => followEdgePredicate(fromNode, neighbour))
    .map(n => {
      cache =
        n in cache
          ? cache
          : subGraphWeightsByDfsWithPredicate({
              graph,
              calculateWeightsOfSubtree,
              followEdgePredicate,
              subtreeWeights: cache,
              fromNode: n,
            })
      return cache[n]
    })

  return { ...cache, [fromNode]: calculateWeightsOfSubtree(fromNode, neighbourValues) }
}

function subGraphWeightByDfsForAudience({
  graph,
  audienceId,
  fromNode,
  subtreeWeights,
  calculateWeightsOfSubtree,
}: {
  graph: RoutingGraph
  audienceId: string
  fromNode: string
  subtreeWeights: Record<string, number>
  calculateWeightsOfSubtree: SubtreeWeightsCalculateFn
}): Record<string, number> {
  return subGraphWeightsByDfsWithPredicate({
    graph,
    fromNode,
    subtreeWeights,
    followEdgePredicate: (sourceNode, targetNode) => {
      return audienceCanAccessTargetNodeViaEdge({
        graph,
        audienceId,
        sourceNode,
        targetNode,
      })
    },
    calculateWeightsOfSubtree,
  })
}

function subGraphWeightsByDfs({
  graph,
  fromNode,
  subtreeWeights,
  calculateWeightsOfSubtree,
}: {
  graph: RoutingGraph
  fromNode: string
  subtreeWeights: Record<string, number>
  calculateWeightsOfSubtree: SubtreeWeightsCalculateFn
}): Record<string, number> {
  return subGraphWeightsByDfsWithPredicate({
    graph,
    fromNode,
    subtreeWeights,
    followEdgePredicate: () => true,
    calculateWeightsOfSubtree,
  })
}

export function getMaximumQuestionsInGraph(graph: RoutingGraph): number {
  let currentMax = 0
  let subtreeValues: Record<string, number> = {}

  forEachRootNode(graph, rootNode => {
    subtreeValues = subGraphWeightsByDfs({
      graph,
      fromNode: rootNode,
      subtreeWeights: subtreeValues,
      calculateWeightsOfSubtree: (subtreeRootNodeKey: string, neighboursValues: number[]) => {
        const valueForNode = graph.getNodeAttributes(subtreeRootNodeKey).type === 'question' ? 1 : 0
        return valueForNode + Math.max(...neighboursValues, 0)
      },
    })
    currentMax = Math.max(currentMax, subtreeValues[rootNode])
  })

  return currentMax
}

export function getMaximumQuestionsForAudience(graph: RoutingGraph, audienceId: string): number {
  let currentMax = 0
  let subtreeValues: Record<string, number> = {}

  forEachRootNode(graph, rootNode => {
    subtreeValues = subGraphWeightByDfsForAudience({
      graph,
      audienceId,
      fromNode: rootNode,
      subtreeWeights: subtreeValues,
      calculateWeightsOfSubtree: (rootOfSubgraph, neighbourValues) => {
        const nodeAttributes = graph.getNodeAttributes(rootOfSubgraph)
        const nodeIsVisible = !nodeAttributes.omittedFromAudiences.has(audienceId)
        const valueForNode = nodeIsVisible && nodeAttributes.type === 'question' ? 1 : 0
        return valueForNode + Math.max(...neighbourValues, 0)
      },
    })
    currentMax = Math.max(currentMax, subtreeValues[rootNode])
  })

  return currentMax
}

export function getNumberOfPathsForAudience(graph: RoutingGraph, audienceId: string): number {
  let countOfPaths = 0
  let pathsInSubtree: Record<string, number> = {}

  forEachRootNode(graph, rootNode => {
    pathsInSubtree = subGraphWeightByDfsForAudience({
      graph,
      audienceId,
      fromNode: rootNode,
      subtreeWeights: pathsInSubtree,
      calculateWeightsOfSubtree: (rootOfSubgraph, neighbourValues) => {
        if (neighbourValues.length === 0) {
          const nodeIsVisible = !graph
            .getNodeAttributes(rootOfSubgraph)
            .omittedFromAudiences.has(audienceId)
          return nodeIsVisible ? 1 : 0
        }
        return sum(...neighbourValues)
      },
    })
    countOfPaths += pathsInSubtree[rootNode]
  })

  return countOfPaths
}

function audienceCanAccessTargetNodeViaEdge({
  graph,
  audienceId,
  sourceNode,
  targetNode,
}: {
  graph: RoutingGraph
  audienceId: string
  sourceNode: string
  targetNode: string
}): boolean {
  const edgeAttributes = graph.getEdgeAttributes(graph.edges(sourceNode, targetNode)[0])
  if (edgeAttributes.omittedFromAudiences.has(audienceId)) {
    return false
  }
  const sourceNodeAttributes = graph.getNodeAttributes(sourceNode)
  if (
    sourceNodeAttributes.omittedFromAudiences.has(audienceId) &&
    edgeAttributes.type !== 'DEFAULT'
  ) {
    return false
  }
  return true
}

export function getNumberOfPathsInGraph(graph: RoutingGraph): number {
  let countOfPaths = 0
  let pathsInSubtree: Record<string, number> = {}

  forEachRootNode(graph, rootNode => {
    pathsInSubtree = subGraphWeightsByDfs({
      graph,
      fromNode: rootNode,
      subtreeWeights: pathsInSubtree,
      calculateWeightsOfSubtree: (_nodeKey, neighbourValues) => {
        if (neighbourValues.length === 0) {
          return 1
        }
        return sum(...neighbourValues)
      },
    })
    countOfPaths += pathsInSubtree[rootNode]
  })

  return countOfPaths
}

export function nodeIsUnreachableForAllAudiences(
  routingGraph: RoutingGraph,
  targetNode: RouteNode,
  audiences: Set<string>,
): boolean {
  return [...audiences].every(
    audienceId =>
      !nodeIsReachableForAudience({
        graph: routingGraph,
        audienceId,
        targetNodeKey: targetNode.guid,
      }),
  )
}

export function nodeIsReachableForAudience({
  graph,
  targetNodeKey,
  audienceId,
}: {
  graph: RoutingGraph
  targetNodeKey: string
  audienceId: string
}): boolean {
  let foundNode = false
  let nodesVisited: Record<string, number> = {}

  forEachRootNode(graph, rootNode => {
    if (foundNode) {
      return
    }
    nodesVisited = subGraphWeightsByDfsWithPredicate({
      graph,
      fromNode: rootNode,
      subtreeWeights: nodesVisited,
      followEdgePredicate: (sourceNode, targetNode) => {
        if (foundNode) {
          return false
        }
        return audienceCanAccessTargetNodeViaEdge({
          graph,
          audienceId,
          sourceNode,
          targetNode,
        })
      },
      calculateWeightsOfSubtree: node => {
        if (node === targetNodeKey) {
          foundNode = true
        }
        return 0
      },
    })
  })

  return foundNode
}

export function nodesReachableForAudience({
  graph,
  audienceId,
}: {
  graph: RoutingGraph
  audienceId: string
}): Set<string> {
  let nodesReachable: Record<string, number> = {}

  forEachRootNode(graph, rootNode => {
    nodesReachable = subGraphWeightByDfsForAudience({
      graph,
      audienceId,
      fromNode: rootNode,
      subtreeWeights: nodesReachable,
      calculateWeightsOfSubtree: (node: string) => {
        const nodeAccessibleToAudience = !graph
          .getNodeAttributes(node)
          .omittedFromAudiences.has(audienceId)
        return nodeAccessibleToAudience ? 1 : 0
      },
    })
  })

  return new Set([
    ...Object.entries(nodesReachable)
      .filter(([_nodeKey, isReachable]) => isReachable === 1)
      .map(([nodeKey]) => nodeKey),
  ])
}
