export const QUAL_COUNTRIES = ['GB', 'US']
export const QUAL_LANGUAGES = ['ENG']
export const QUAL_DEMOGRAPHICS = [
  'age',
  'gender_v2',
  'home_region',
  'earnings_household_v3',
  'usa_region',
  'usa_earnings_household',
]
