import { defineStore } from 'pinia'

type EditorState = {
  isSaving: boolean
  isLoading: boolean
  saveTimestamp: number | null
  visitedCardGuids: string[]
  pendingSchedule: { timestamp: number; timeZoneId: string } | null
}

export function createEditorState(override: Partial<EditorState> = {}): EditorState {
  return {
    isSaving: false,
    isLoading: true,
    saveTimestamp: null,
    visitedCardGuids: [],
    pendingSchedule: null,
    ...override,
  }
}

export const EDITOR_STORE_ID = 'editor'

export const useEditorStore = defineStore(EDITOR_STORE_ID, {
  state: createEditorState,
  actions: {
    setIsSaving(isSaving: boolean): void {
      this.isSaving = isSaving
    },
    setIsLoading(isLoading: boolean): void {
      this.isLoading = isLoading
    },
    setSaveTimestamp(saveTimestamp: number | null): void {
      this.saveTimestamp = saveTimestamp
    },
    setVisited(guid: string): void {
      this.visitedCardGuids.push(guid)
    },
    setPendingSchedule(pendingSchedule: { timestamp: number; timeZoneId: string } | null): void {
      this.pendingSchedule = pendingSchedule
    },
  },
})
