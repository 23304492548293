<script lang="tsx">
const baseClass = 'c-new-badge'
</script>
<script setup lang="tsx">
import { AttestGradientLabel, AttestIcon } from '@attest/design-system'
import Sparkles from '@attest/design-system/assets/icons/sparkles.svg?component'
import { c } from '@attest/intl'


defineRender(
  <AttestGradientLabel class={baseClass}>
    <div class={`${baseClass}__content`}>
      <AttestIcon>
        <Sparkles />
      </AttestIcon>
      <span>{c('new.label')}</span>
    </div>
  </AttestGradientLabel>,
)
</script>

<style lang="postcss">
.c-new-badge {
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  vertical-align: sub;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
