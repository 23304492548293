import { type ResponseData, service } from '@attest/_api'
import type { LoginError } from '@attest/_api/model/error'

export type UserLoginRequestData = {
  email: string
  password: string
  inviteCode: string | null
  code?: string
}

export type UserLoginResponseData = {} & ResponseData<Record<string, never>, LoginError>

export const LOGIN_URL = '/api/login'

export async function login(data: UserLoginRequestData) {
  return service().post<UserLoginResponseData>(LOGIN_URL, {
    email: data.email,
    password: data.password,
    code: data.code,
    invite_code: data.inviteCode,
  })
}
