import type { Router } from 'vue-router'

import { interceptRequest, interceptResponse } from '@attest/_api'
import { emit, EventName } from '@attest/util'

type RouterEventPayload = {
  status?: 'error'
}

const REQUEST_TIME_BUFFER = 10
const FINISH_TIME_BUFFER = 180

export async function setupProgressBar(router: Router) {
  let requestsCounter = 0

  await router.isReady()
  router.beforeEach((_route, _from, next) => {
    incrementStart(true)
    next()
  })

  router.afterEach(() => incrementFinish())

  router.onError(() => {
    incrementFinish({ status: 'error' })
  })

  interceptRequest(config => {
    incrementStart()
    return config
  })

  interceptResponse(
    response => {
      incrementRouterFinish()
      return response
    },
    error => {
      incrementRouterFinish({ status: 'error' })
      throw error
    },
  )

  function incrementStart(reset?: boolean) {
    if (reset) {
      requestsCounter = 0
    }

    if (requestsCounter <= 0) emit(EventName.PROGRESS_START, {})
    requestsCounter++
  }

  function incrementRouterFinish(eventPayload: RouterEventPayload = {}) {
    setTimeout(() => incrementFinish(eventPayload), REQUEST_TIME_BUFFER)
  }

  function incrementFinish(eventPayload: RouterEventPayload = {}) {
    requestsCounter--

    setTimeout(() => {
      if (requestsCounter <= 0) {
        requestsCounter = 0
        emit(EventName.PROGRESS_FINISH, eventPayload)
      } else {
        emit(EventName.PROGRESS_INC, eventPayload)
      }
    }, FINISH_TIME_BUFFER)
  }
}
