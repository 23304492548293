import {
  type ApiError,
  type InternalError,
  reasonErrorToInternalError,
  service,
} from '@attest/_api'

import { REQUEST_URL } from './url'

export async function updateQualifyingAnswers(args: {
  surveyGuid: string
  questionId: string
  requestData: { value: string[] }
}): Promise<void | InternalError<ApiError>> {
  try {
    await service().put(REQUEST_URL.MODIFY_QUALIFYING_ANSWERS(args.surveyGuid, args.questionId), {
      ...args.requestData,
    })
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}
