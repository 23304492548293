import MarkdownIt from 'markdown-it'

const DISABLED_MARKDOWN_RULES = [
  'table',
  'code',
  'fence',
  'blockquote',
  'hr',
  'list',
  'reference',
  'html_block',
  'heading',
  'lheading',
  'paragraph',
  'escape',
  'backticks',
  'strikethrough',
  'link',
  'image',
  'autolink',
  'html_inline',
  'entity',
  'normalize',
  'replacements',
  'text',
]

const ENABLED_MARKDOWN_RULES = ['inline', 'block', 'emphasis', 'newline']

const MARKDOWN_ELEMENT = {
  bold: '**',
  italic: '_',
}

export type MarkdownConfig = { breaks?: boolean; typographer?: boolean; linkify?: boolean }

export function createMarkdownString(input: string, config: MarkdownConfig = {}): string {
  const markdown = new MarkdownIt(config)
    .disable(DISABLED_MARKDOWN_RULES)
    .enable(ENABLED_MARKDOWN_RULES)

  return markdown.renderInline(input)
}

export function determineMarkdownShortcut(
  event: KeyboardEvent,
): keyof typeof MARKDOWN_ELEMENT | null {
  if ((event.ctrlKey || event.metaKey) && event.key === 'b') return 'bold'
  if ((event.ctrlKey || event.metaKey) && event.key === 'i') return 'italic'
  return null
}

export function decorateMarkdown(
  text: string,
  selectionIndex: { start: number; end: number },
  emphasis: 'bold' | 'italic',
): { selectionStart: number; selectionEnd: number; text: string } {
  const start = text.slice(0, selectionIndex.start)
  const middle = text.slice(selectionIndex.start, selectionIndex.end)
  const end = text.slice(selectionIndex.end)
  const [left, ...restStart] = start.split(' ').reverse()
  const [right, ...restEnd] = end.split(' ')
  const markdownTagLength = MARKDOWN_ELEMENT[emphasis].length

  if (selectionIndex.start === selectionIndex.end) {
    return { text, selectionStart: selectionIndex.start, selectionEnd: selectionIndex.end }
  }

  if (left?.includes(MARKDOWN_ELEMENT[emphasis]) && right?.includes(MARKDOWN_ELEMENT[emphasis])) {
    return {
      text:
        [...restStart.reverse(), left.replace(MARKDOWN_ELEMENT[emphasis], '')].join(' ') +
        middle +
        [right.replace(MARKDOWN_ELEMENT[emphasis], ''), ...restEnd].join(' '),
      selectionStart: selectionIndex.start - markdownTagLength,
      selectionEnd: selectionIndex.end - markdownTagLength,
    }
  }

  if (
    middle.slice(0, markdownTagLength) === MARKDOWN_ELEMENT[emphasis] &&
    middle.slice(-markdownTagLength) === MARKDOWN_ELEMENT[emphasis]
  ) {
    return {
      text: start + middle.slice(markdownTagLength, middle.length - markdownTagLength) + end,
      selectionStart: selectionIndex.start,
      selectionEnd: selectionIndex.end - markdownTagLength * 2,
    }
  }

  return {
    text: start + MARKDOWN_ELEMENT[emphasis] + middle + MARKDOWN_ELEMENT[emphasis] + end,
    selectionStart: selectionIndex.start + markdownTagLength,
    selectionEnd: selectionIndex.end + markdownTagLength,
  }
}

function decodeHTMLEntities(text: string): string {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = text
  return textArea.value
}

export function removeMarkdownCharacters(text: string) {
  return decodeHTMLEntities(
    createMarkdownString(text, { typographer: true }).replace(/<[^>]+>/g, ''),
  )
}

export function removeListFormatting(text: string) {
  return text.replace(/^(?:\d+\.|\s*[•-])\s*/gm, '')
}
