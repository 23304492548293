import type { Router } from 'vue-router'

import { useAppErrorStore } from './store'

export function setupClearErrorsOnRouteChange(router: Router): void {
  router.beforeEach(() => {
    while (useAppErrorStore().errors.length > 1) {
      useAppErrorStore().shift()
    }
  })

  router.afterEach(() => {
    if (useAppErrorStore().hasError) useAppErrorStore().shift()
  })
}
