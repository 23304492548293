import axios from 'axios'

import {
  type ApiError,
  type InternalError,
  reasonErrorToInternalError,
  service,
} from '@attest/_api'
import { expectedHandler } from '@attest/util'

import type { RejectTakersReason } from '../models'

import { REQUEST_URL } from './url'

export async function approveTakers(args: { round_guids: string[] }): Promise<undefined | Error> {
  const [, error] = await expectedHandler(() => axios.post(REQUEST_URL.APPROVE_TAKERS, args))

  if (error !== undefined) {
    return error
  }

  return undefined
}

export async function rejectTakers({
  guid,
  reason,
  responseIds,
  cardIds,
  waveSurveyId,
}: {
  guid: string
  reason: RejectTakersReason
  cardIds: string[]
  responseIds: string[]
  waveSurveyId: string
}): Promise<void | InternalError<ApiError>> {
  try {
    await service().post(REQUEST_URL.REJECT_TAKERS({ id: guid, waveSurveyId }), {
      reason,
      response_ids: responseIds,
      card_guids: cardIds,
    })
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}
