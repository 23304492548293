import { createPinia, type Pinia } from 'pinia'
import PortalVue from 'portal-vue'
import { type App, createApp } from 'vue'
import type { Router } from 'vue-router'

import { clickOut } from '@attest/_lib/src/directive/click-out'
import { focusOut } from '@attest/_lib/src/directive/focus-out'
import { focusWindow } from '@attest/_lib/src/directive/focus-window'
import { lazyLoad } from '@attest/_lib/src/directive/lazy-load'
import { scrollEndContainer } from '@attest/_lib/src/directive/scroll-end-container'
import ReactiveStorage from '@attest/_lib/src/vue-plugins/reactive-local-storage/plugin'
import { CONFIG } from '@attest/config'
import { setUser } from '@attest/telemetry'
import { useUserStore } from '@attest/user'

import ContainerApp from '../container/App.vue'
import { createRouter } from '../router'

import { createMixin } from './hooks'

export async function setupApp(): Promise<{
  pinia: Pinia
  router: Router
  app: App
}> {
  const pinia = createPinia()
  const router = createRouter()

  const app = createApp({
    render() {
      return <ContainerApp />
    },
  })

  app.config.performance = CONFIG.APP.PERFORMANCE_TOOLS
  app
    .use(PortalVue)
    .use(pinia)
    .use(router)
    .use(ReactiveStorage)
    .directive('click-out', clickOut)
    .directive('focus-out', focusOut)
    .directive('focus-window', focusWindow)
    .directive('lazy-load', lazyLoad)
    .directive('scroll-end-container', scrollEndContainer)

  setupGlobals(app)

  return { pinia, router, app }
}

let globalsSetup = false
function setupGlobals(app: App): void {
  if (globalsSetup) return

  globalsSetup = true

  app.mixin(createMixin())

  import('@attest/telemetry').then(({ setup }) => {
    setup({
      dsn: CONFIG.APP.SDK.SENTRY_URL,
      version: CONFIG.APP.VERSION,
      environment: CONFIG.APP.ENV ?? 'qa',
      baseUrl: CONFIG.APP.MAKER_URL,
      app,
    })
    setUser({ ...useUserStore().$state })
  })
}
