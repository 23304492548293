import axios, { type AxiosError } from 'axios'

import type { ListInvitedUsersApiResponse } from '@attest/_api/model/user-invites'
import { expectedHandler, type ExpectedHandler } from '@attest/util'

import type { InvitedUser } from './model'

const REQUEST_URL = {
  USER_INVITES: (organisationId: string) => `/api/orgs/${organisationId}/invites`,
}

export async function listUserInvites(args: {
  id: string
}): ExpectedHandler<InvitedUser[], AxiosError> {
  return expectedHandler(async () => {
    const { data } = await axios.get<ListInvitedUsersApiResponse>(REQUEST_URL.USER_INVITES(args.id))
    return data.invites.map(invite => {
      return {
        email: invite.email,
        guid: invite.guid,
        timestamp: invite.timestamp,
        expiryTimestamp: invite.expiry_timestamp,
        inviterGuid: invite.inviter_guid,
      }
    })
  })
}
