import { create } from '@attest/vue-tsx'

import type {
  DOMMutationObserverEvents,
  DOMMutationObserverProps,
} from './DOMMutationObserver.interface'
import Component from './DOMMutationObserver.vue'

export * from './DOMMutationObserver.interface'

export const DOMMutationObserver = create<DOMMutationObserverProps, DOMMutationObserverEvents>(
  Component,
)
