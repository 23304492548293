import type { CardMaskingError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import { type Card, isGroupCard } from '@attest/editor-card'
import { getMaskingReceivers, isMaskingReceiver, isMaskingSender } from '@attest/editor-masking'
import {
  getAllIntersectingNodesTo,
  isCardFollowedBy,
  type RoutingGraph,
} from '@attest/editor-routing-graph'

export const getCardMaskingErrors = createGetErrorsFor<
  CardMaskingError['type'],
  {
    card: Card
    cards: Card[]
    routingGraph: RoutingGraph
  }
>({
  CARD_MASKING_TO_DIFFERENT_ROUTE({ card, routingGraph, cards }) {
    if (!isMaskingSender(card, cards)) return false

    return getMaskingReceivers(card, cards).some(
      ({ guid: receiverId }) =>
        !getAllIntersectingNodesTo(routingGraph, receiverId).includes(card.guid),
    )
  },
  RECEIVER_OR_SENDER_ARE_RANDOMIZED({ card, cards }) {
    return card.isRandomized && (isMaskingSender(card, cards) || isMaskingReceiver(card))
  },
  RECEIVER_NOT_IN_SAME_GROUP_AS_SENDER({ card, cards }) {
    if (!isMaskingReceiver(card)) return false

    return cards
      .filter(isGroupCard)
      .map(({ group }) => group.cards.map(({ guid }) => guid))
      .some(
        guids =>
          guids.includes(card.guid) &&
          !guids.includes(card.question.options.maskingQuestion?.senderGuid || ''),
      )
  },
  SENDER_MASKING_AFTER_RECEIVER({ card, cards, routingGraph }) {
    if (!isMaskingSender(card, cards)) return false

    return getMaskingReceivers(card, cards).some(({ guid: receiverId }) =>
      isCardFollowedBy(routingGraph, receiverId, card.guid),
    )
  },
})
