import type { Quota, Segmentations } from '@attest/audience'

import type { WaveSurvey } from '../wave/model'

export type ApiStudyWave = {
  title: string
  id: string
  audience: {
    id: string
    country: string
    language: string
    name: string
    max_responses?: number
    use_draft_language?: boolean
  }
  created: number
  published?: number
  num_responses: number
  start_date?: number
  time_zone_id?: string
  expires?: number
  status: string
  segmentations: Segmentations
  quotas: Quota[]
  access_code?: string
  collects_video_responses?: boolean
  last_response?: number
  longest_route?: number
}

export function createStudyWave(apiWave: ApiStudyWave): WaveSurvey {
  return {
    title: apiWave.title,
    id: apiWave.id,
    audienceId: apiWave.audience.id,
    name: apiWave.audience.name,
    country: apiWave.audience.country,
    language: apiWave.audience.language,
    createdTimestamp: apiWave.created,
    publishedTimestamp: apiWave.published ?? null,
    expiredTimestamp: apiWave.expires ?? null,
    startTimestamp: apiWave.start_date ?? null,
    timeZoneId: apiWave.time_zone_id ?? null,
    responses: apiWave.num_responses ?? 0,
    maxResponses: apiWave.audience.max_responses ?? Number.POSITIVE_INFINITY,
    status: apiWave.status.toLowerCase(),
    segmentations: apiWave.segmentations ?? {},
    quotas: apiWave.quotas ?? [],
    accessCode: apiWave.access_code,
    collectsVideoResponses: apiWave.collects_video_responses,
    lastAnswerTimestamp: apiWave.last_response,
    numQuestionsLongestRoute: apiWave.longest_route ?? 0,
    useDraftLanguage: apiWave.audience.use_draft_language,
  } as WaveSurvey
}
