import type { Newable } from 'ts-essentials'
import type { FunctionalComponent, IntrinsicElementAttributes, VNodeChild } from 'vue'

import type { OnEventHandlers } from './events'
import type { TsxComponent } from './tsx'

export type Slots<Payloads> = {
  [Key in keyof Payloads]?: (payload: Payloads[Key]) => VNodeChild
}

export type ExtractSlots<Component extends Newable<any>> = InstanceType<Component>['$slots']

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function castSlots<P>(slots: any): P {
  return slots
}

export function renderSlotOrView<P extends (...args: any[]) => VNodeChild>(
  slot: P | undefined,
  payload: Parameters<P>[0],
): VNodeChild {
  if (!slot) return payload?.createView()

  return slot(payload)
}

type CreateViewData<Props, Events> = Partial<
  IntrinsicElementAttributes & Props & OnEventHandlers<Events>
>

type ExtractCreateViewParams<C> =
  C extends TsxComponent<infer Props, infer Events, infer S>
    ? [CreateViewData<Props, Events>, S]
    : C extends FunctionalComponent<infer Props, infer Events, infer S>
      ? [CreateViewData<Props, Events>, S]
      : C extends Newable<infer Instance>
        ? Instance extends { $props: infer Props; $slots: infer S }
          ? [CreateViewData<Props, unknown>, S]
          : never
        : never

type ExtractCreateView<
  Component,
  D extends ExtractCreateViewParams<Component> = ExtractCreateViewParams<Component>,
> = (data?: D[0] | undefined, slots?: D[1]) => VNodeChild

type ExtractCreateViewWithExtraData<
  Component,
  ExtraData,
  D extends ExtractCreateViewParams<Component> = ExtractCreateViewParams<Component>,
> = (data?: (D[0] & ExtraData) | undefined, slots?: D[1]) => VNodeChild

export type CreateView<Component> = ExtractCreateView<Component>
export type CreateViewWithPartialDataOverride<
  Component,
  Attributes extends keyof ExtractCreateViewParams<Component>[0],
> = (
  data?: Pick<ExtractCreateViewParams<Component>[0], Attributes> | undefined,
  slots?: ExtractCreateViewParams<Component>[1],
) => VNodeChild
export type CreateViewWithExtraData<Component, ExtraData> = ExtractCreateViewWithExtraData<
  Component,
  ExtraData
>
