import EflService from '@attest/efl-antlr4'

import type { FilterNode } from './feature/model'

export { generateEflFromSegment, generateEFlString } from './feature/generator/generator'
export { translateEflToSegment } from './feature/translator/translator'
export { filter, type GetField } from './feature/filter'
export { type FilterNode, type Value, ArrayOperator } from './feature/model'
export {
  NOT,
  EQUAL,
  IN,
  INCLUDES,
  BETWEEN,
  LESS_THAN,
  LESS_THAN_OR_EQUAL,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL,
  CONTAINS,
  STRICT_CONTAINS,
  STARTS_WITH,
  STRICT_STARTS_WITH,
  ENDS_WITH,
  STRICT_ENDS_WITH,
  AND,
  AND_GROUP,
  OR,
  OR_GROUP,
  NONE,
} from './feature/factory'

export { FilterBlock, FilterExpression } from '@attest/efl-antlr4'

export function eflStringToFilterNode(query: string): FilterNode {
  return EflService.getFilterFromQueryString(query)
}
