export type ToastOptions = {
  uid?: string
  duration?: number
  badge?: string
  icon?: string | { default: string }
  mode?: 'success' | 'primary' | 'error'
}

type Toaster = {
  toast: (content: string, opts: ToastOptions) => string
  dismiss: (uid: string) => void
}

const toasters = new Map<string, Toaster>()
export const DEFAULT_TOASTER_NAME = 'default'

let readyFns: ((toasterName: string) => boolean)[] = []

export function registerToaster(toasterName: string, toastTrigger: Toaster): () => boolean {
  if (toasters.has(toasterName)) {
    throw new Error(`Toaster name '${toasterName}' has been registered.`)
  }

  toasters.set(toasterName, toastTrigger)
  callReadyFns(toasterName)
  return () => unregisterToaster(toasterName)
}

export function unregisterToaster(toasterName: string): boolean {
  return toasters.delete(toasterName)
}

export function onReady(fn: (toasterName: string) => boolean): void {
  readyFns.push(fn)
}

function callReadyFns(toasterName: string): void {
  readyFns = readyFns.filter(fn => !fn(toasterName))
}

export function toast(
  content: string,
  options: ToastOptions & { toasterName?: string } = {},
): string | void {
  const { toasterName = DEFAULT_TOASTER_NAME, ...transfer } = options

  const toaster = toasters.get(toasterName)
  if (!toaster) return
  return toaster.toast(content, transfer)
}
