import { CONFIG } from '@attest/config'

import type { Money } from '../model/money'
import { centsToCentsWithVat, centsToCreditCents } from '../pricing'

type CalculatePriceParams = {
  audience: 'own' | 'panel'
  surveyType: 'quant' | 'qual'
  numResponses: number
  numQuestions: number
  numCountries: number
  paymentMethod: PaymentMethod
  targetingInfo?: {
    numQuestions: number
    numResponses: number
  }[]
}

type PaymentMethod = 'wallet' | 'card'

export function calculateSurveyPrice(params: CalculatePriceParams): Money {
  if (params.audience === 'own') return calculateSurveyPriceOwn(params)
  return calculateSurveyPricePanel(params)
}

function calculateSurveyPricePanel(params: CalculatePriceParams): Money {
  const { numResponses, numQuestions, numCountries, paymentMethod } = params
  const credits = (() => {
    if (params.surveyType === 'qual') {
      return numCountries * 5000
    }
    if (params.targetingInfo === undefined) {
      return numResponses * numQuestions
    }
    return params.targetingInfo.reduce((acc, el) => acc + el.numQuestions * el.numResponses, 0)
  })()
  const creditCents = credits * 100

  if (paymentMethod === 'wallet') {
    return toCreditsMoney(creditCents)
  }

  const pricePerCredit = getPricePerCredit(paymentMethod)
  const totalPrice = creditCents * (pricePerCredit / 100)
  const totalPriceWithVat = centsToCentsWithVat(totalPrice)

  return { cents: totalPriceWithVat, currency: 'GBP' }
}

function calculateSurveyPriceOwn(params: CalculatePriceParams): Money {
  const pricePerCredit = getPricePerCredit(params.paymentMethod)
  const tripleWinCostCents = CONFIG.APP.TRIPLE_WIN.PRICE_EX_VAT_POUNDS * 100
  const centsWithVat = centsToCentsWithVat(tripleWinCostCents)
  const creditCents = centsToCreditCents(centsWithVat, pricePerCredit)

  return toCreditsMoney(creditCents)
}

function getPricePerCredit(paymentMethod: PaymentMethod): number {
  if (paymentMethod === 'wallet') {
    return CONFIG.APP.PRICING.CREDIT_VALUE_PENCE
  }

  return CONFIG.APP.PRICING.CREDIT_UNIT_COST_PENCE
}

function toCreditsMoney(creditCents: number): Money {
  return { cents: creditCents, currency: 'CDT' }
}
