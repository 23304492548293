import type { SampleSizeError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import { SETTINGS } from '@attest/editor-settings'

import type { AudienceType } from '../model'

export const getSampleSizeErrors = createGetErrorsFor<
  SampleSizeError['type'],
  {
    sampleSize: number
    availableSampleSize: number | null
    audienceType: AudienceType
    isVideoResponseSurvey?: boolean
  }
>({
  SAMPLE_SIZE_TOO_BIG: ({ sampleSize, availableSampleSize, audienceType }) => {
    if (availableSampleSize === null) return false
    if (availableSampleSize === 0) return false
    if (audienceType === 'own') return false
    return sampleSize > availableSampleSize
  },
  SAMPLE_SIZE_TOO_SMALL: ({ sampleSize, availableSampleSize, audienceType }) => {
    if (availableSampleSize === null) return false
    if (audienceType === 'own') return false
    return sampleSize <= 0 || sampleSize === undefined
  },
  AVAILABLE_SIZE_TOO_SMALL: ({ availableSampleSize }) => {
    return availableSampleSize === 0
  },
  QUAL_SAMPLE_SIZE_TOO_HIGH: ({ sampleSize, isVideoResponseSurvey = false }) => {
    if (!isVideoResponseSurvey) return false
    return sampleSize > SETTINGS.AUDIENCE.MAX_AVAILABLE_SAMPLE_SIZE_QUAL
  },
})
