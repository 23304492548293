export const UI_SETTINGS = {
  ESCALATOR_PRIORITY: {
    EDITOR: {
      ALERTS: 1,
    },
  },
  SELECT_DEBOUNCE_SEARCH_TIME: 500,
}

export const DEFAULT_LANGUAGE = 'ENG'
