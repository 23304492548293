import {
  type ApiAnswerGroup,
  type ApiError,
  reasonErrorToInternalError,
  service,
  type ServiceRequest,
} from '@attest/_api'

import type { StudyQuestionGroup } from '../card/question/model'

export const saveStudyResultsCardAnswerGroups: ServiceRequest<
  null,
  ApiError,
  {
    studyGuid: string
    cardId: string
    groups: StudyQuestionGroup[]
  }
> = async ({ studyGuid, cardId, groups }) => {
  try {
    await service().put(`/api/study/${studyGuid}/card/${cardId}/answer-groups`, {
      groups: toStudyAnswerGroupPersistRequest(groups),
    })
    return null
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export function toStudyAnswerGroupPersistRequest(
  answerGroups: StudyQuestionGroup[],
): ApiAnswerGroup[] {
  return answerGroups.map(group => {
    return {
      choices: group.ids.map(id => ({ id })),
      name: group.text,
    }
  })
}
