import { nanoid } from 'nanoid'

export const CARD_EDITOR_VIEW = {
  LIST: 'list',
  MAP: 'map',
} as const

type CardEditorViewKey = keyof typeof CARD_EDITOR_VIEW
export type CardEditorView = (typeof CARD_EDITOR_VIEW)[CardEditorViewKey]

const CARD_EDITOR_VIEW_SESSION_STORAGE_KEY = `EDITOR-DRAFT-VIEW-${nanoid()}` as const
function createCardEditorViewSessionKey(guid: string) {
  return `${CARD_EDITOR_VIEW_SESSION_STORAGE_KEY}-${guid}`
}

export function getCardEditorViewSessionValue(guid?: string): string | undefined {
  if (!guid) return undefined
  return window.sessionStorage.getItem(createCardEditorViewSessionKey(guid)) ?? undefined
}

export function setCardEditorViewSessionValue(guid?: string, name?: string): void {
  if (!guid) return
  if (!name) return window.sessionStorage.removeItem(createCardEditorViewSessionKey(guid))
  window.sessionStorage.setItem(createCardEditorViewSessionKey(guid), name)
}
