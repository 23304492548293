import type { RouteRecordRaw } from 'vue-router'

import { ROUTES, STUDY_INSIGHTS_ROUTE_NAME } from '@attest/router'

export const routesRedirect: readonly RouteRecordRaw[] = [
  {
    path: '/surveys/purchase',
    redirect: { name: ROUTES.INDEX.name },
  },
  {
    path: '/surveys/own',
    redirect: { name: ROUTES.SURVEY_LIST.name },
  },
  {
    path: '/profile',
    redirect: { name: ROUTES.PROFILE.name },
  },
  {
    path: '/user/settings',
    redirect: { name: ROUTES.PROFILE.name },
  },
  {
    path: '/survey/:studyId/result/:surveyGuid',
    redirect: to => {
      return {
        name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW,
        params: to.params,
      }
    },
  },
  {
    path: '/survey/:studyId/insights/(.*)',
    redirect: to => {
      return {
        name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW,
        params: to.params,
      }
    },
  },
  {
    path: '/results/survey/:studyId/(.*)',
    redirect: to => {
      return {
        name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW,
        params: to.params,
      }
    },
  },
  {
    path: '/survey/:studyId/results/:title?',
    redirect: to => {
      return {
        name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW,
        params: to.params,
      }
    },
  },
]
