const NUM_DECIMAL_PLACES = 1
const NUM_DECIMAL_PLACES_MULT = 10 ** NUM_DECIMAL_PLACES

export function toOptionalDecimalPercent(value: number): string {
  return `${toOptionalDecimal(value)}%`
}

export function toOptionalDecimal(value: number): number {
  const round = Math.round(value * NUM_DECIMAL_PLACES_MULT)

  return round / NUM_DECIMAL_PLACES_MULT
}

export function toNumberWithCommas(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function toPercentage(
  value: number,
  {
    digitsAfterDecimal = 0,
    indefiniteTransformer = () => `0%`,
  }: {
    digitsAfterDecimal?: number
    indefiniteTransformer?: (n: number) => string
  } = {},
): string {
  if (!Number.isFinite(value)) return indefiniteTransformer(value)

  return `${(value * 100).toFixed(digitsAfterDecimal)}%`
}
