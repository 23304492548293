import type { ApiAnswerQuota } from '@attest/_api/model/answer-quotas'

export type ApiStudyQuestionCardStructureAnswer = {
  id: string
  pinned: boolean
  qualifying: boolean
  text: string
  type: 'text' | 'image'
  image_url?: string
  filename?: string
  next?: string
  mask?: {
    card_id: string
    choice_id: string
  }
}

type ApiDisplayLogicCondition = 'all_selected' | 'any_selected' | 'none_selected'

export type ApiDisplayLogic = {
  conditions: {
    choice_ids: string[]
    condition: ApiDisplayLogicCondition
    study_card_guid: string
  }[]
  operator: 'and' | 'or'
}

export type ApiStudyQuestionCardStructure = {
  title: string
  answers: ApiStudyQuestionCardStructureAnswer[]
  randomized: boolean
  na: boolean
  none: boolean
  other: boolean
  next?: string
  max_selections?: number
  display_logic?: ApiDisplayLogic
  answer_quotas?: ApiAnswerQuota[]
  title_content?: { image_url?: string; audio_url?: string; video_url?: string }
}

export type ApiStudyQuestionCard = {
  guid: string
  question_guid: string
  title: string
  title_content: {
    audio_url?: string
    image_url?: string
    video_url?: string
  }
  card_type:
    | 'single_choice'
    | 'multiple_choice'
    | 'ranked'
    | 'nps'
    | 'either_or'
    | 'text_response_short'
    | 'text_response_medium'
    | 'text_response_long'
    | 'video'
  groups: { name?: string; choices: { id: string }[] }[]
  waves: Record<string, ApiStudyQuestionCardStructure>
  template_answers: Array<{ id: string; type: string; text: string }>
}

export function isApiStudyQuestionCard(apiCard: {
  card_type: string
}): apiCard is ApiStudyQuestionCard {
  return [
    'single_choice',
    'multiple_choice',
    'ranked',
    'nps',
    'either_or',
    'text_response_short',
    'text_response_medium',
    'text_response_long',
    'video',
  ].includes(apiCard.card_type)
}
