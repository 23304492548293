import type { Quota } from './quota'
import type { Segmentations } from './segmentations'

export type AudienceType = 'panel' | 'own'

export type Audience = {
  id: string
  name: string | undefined
  country: string
  language: string
  sampleSize: number
  segmentations: Segmentations
  quotas: Quota[]
  audienceId: string | null
  useDraftLanguage: boolean
}

export function isOwnAudience(audienceType: AudienceType) {
  return audienceType === 'own'
}

export type SampleSize = {
  availableSampleSize: number
  desiredSampleSize: number
}
