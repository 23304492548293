import { nanoid } from 'nanoid'
import type { FunctionDirective, ObjectDirective } from 'vue'

import { isChildOrSelf } from '@attest/dom'

const DATASET_KEY_FOR_UID = 'clickOutUid'

const idUnlistenMap: Map<string, () => void> = new Map()

type BindingValue = (event: Event) => any
const listenClickOut = (el: HTMLElement, onClickOut: BindingValue): (() => void) => {
  let isMouseDownFromOutside = false

  const onMouseDown = (e: Event) => {
    const { target } = e
    if (!(target instanceof HTMLElement)) return
    isMouseDownFromOutside = !isChildOrSelf(el, target)
  }

  const onMouseUp = (e: Event) => {
    if (!isMouseDownFromOutside) return
    const { target } = e
    if (!(target instanceof HTMLElement)) return
    if (!isChildOrSelf(el, target)) {
      onClickOut(e)
    }
    isMouseDownFromOutside = false
  }

  document.addEventListener('mousedown', onMouseDown)
  document.addEventListener('touchstart', onMouseDown)
  document.addEventListener('mouseup', onMouseUp)
  document.addEventListener('touchend', onMouseUp)

  return () => {
    document.removeEventListener('mousedown', onMouseDown)
    document.removeEventListener('touchstart', onMouseDown)
    document.removeEventListener('mouseup', onMouseUp)
    document.removeEventListener('touchend', onMouseUp)
  }
}

const bind: FunctionDirective<HTMLElement, BindingValue> = (el, binding) => {
  const uid = nanoid()
  el.dataset[DATASET_KEY_FOR_UID] = uid

  const unlisten = listenClickOut(el, binding.value)
  idUnlistenMap.set(uid, unlisten)
}

const unbind: FunctionDirective<HTMLElement, BindingValue> = (el: HTMLElement) => {
  const uid = el.dataset[DATASET_KEY_FOR_UID]
  if (!uid) return

  const unlisten = idUnlistenMap.get(uid)
  if (unlisten) unlisten()
  idUnlistenMap.delete(uid)
}

export const clickOut: ObjectDirective<HTMLElement, BindingValue> = {
  beforeMount: bind,

  updated(el, binding, vnode, oldVnode) {
    if (binding.value === binding.oldValue) return
    unbind(el, binding, vnode, oldVnode)
    bind(el, binding, vnode, oldVnode)
  },

  unmounted: unbind,
}
