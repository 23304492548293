<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import errorSVG from '@attest/_assets/icons/error.svg'
import infoSVG from '@attest/_assets/icons/info.svg'
import warningSVG from '@attest/_assets/icons/warning.svg'
import { create, type ElementAttributes } from '@attest/vue-tsx'

import { SvgIcon } from '../SvgIcon'

import type { InlineMessageProps, Variant } from './InlineMessage.interface'

const DEFAULT_STYLE_VARIANT: Variant = 'error'
const DEFAULT_ROLE = 'status'

const InlineMessage: FunctionalComponent<InlineMessageProps> = (props, { slots }) => {
  const { styleVariant, role, ...attrs } = props
  const InlineMessageVariant = styleVariant || DEFAULT_STYLE_VARIANT
  const baseClass = 'c-inline-message'
  const inlineMessageClass = {
    [baseClass]: true,
    [`${baseClass}--style-${InlineMessageVariant}`]: true,
  }

  return (
    <div class={inlineMessageClass} role={role || DEFAULT_ROLE} {...attrs}>
      {styleVariant === 'default-icon' && slots.icon?.()}
      {styleVariant === 'warning-icon' && (
        <SvgIcon svg={warningSVG} size="s" class={`${baseClass}__icon`} />
      )}
      {(styleVariant === 'message-icon' || styleVariant === 'info-icon') && (
        <SvgIcon svg={infoSVG} size="s" class={`${baseClass}__icon`} />
      )}
      {styleVariant === 'error-icon' && (
        <SvgIcon svg={errorSVG} size="s" class={`${baseClass}__icon`} />
      )}
      <div class={`${baseClass}__content`}>{slots.default?.()}</div>
    </div>
  )
}
export default create<InlineMessageProps & ElementAttributes<'div'>>(InlineMessage)
</script>
<style lang="postcss">
.c-inline-message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--attest-spacing-4);
  border-radius: var(--attest-border-radius);
  font: var(--attest-font-body-2);
  text-align: left;

  &__icon {
    flex: 0 0 auto;
    margin-right: var(--attest-spacing-2);
  }

  &--style-default,
  &--style-default-icon {
    background-color: var(--attest-color-surface-hover);
  }

  &--style-error-icon,
  &--style-error {
    background-color: var(--attest-palette-red-40);
  }

  &--style-message-icon,
  &--style-message {
    background-color: var(--attest-color-interactive-subdued-hover);
    color: var(--attest-color-text-default);
  }

  &--style-info-icon,
  &--style-info {
    background-color: var(--attest-color-interactive-subdued-hover);
    color: var(--attest-color-text-default);
  }

  &--style-warning-icon,
  &--style-warning {
    background-color: var(--attest-palette-yellow-20);
    color: var(--attest-color-text-default);
  }
}
</style>
