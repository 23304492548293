<script lang="tsx">
import { PortalTarget } from 'portal-vue'
import { defineComponent } from 'vue'

import attestSVG from '@attest/_assets/icons/attest-24.svg'
import burgerSVG from '@attest/_assets/icons/menu.svg'
import { COPY } from '@attest/_copy'
import { create, createEmitter } from '@attest/vue-tsx'

import { BareButton } from './BareButton'
import { SvgIcon } from './SvgIcon'

type TopbarProps = {}

export type TopbarEvents = {
  openMenu(): void
  logoClick(): void
}

const emit = createEmitter<TopbarEvents>()

const Topbar = defineComponent({
  methods: {
    onOpenMenu() {
      emit(this, 'openMenu')
    },

    onLogoClick() {
      emit(this, 'logoClick')
    },
  },

  render() {
    return (
      <div class="c-topbar">
        <div class="c-topbar__left">
          <BareButton
            aria-label={COPY.OPEN_MENU}
            class="c-topbar__menu"
            onClick={this.onOpenMenu}
            data-name="TopbarMenuButton"
          >
            <SvgIcon svg={burgerSVG} size="m" />
          </BareButton>
        </div>
        <div class="c-topbar__absolute-center">
          <SvgIcon svg={attestSVG} size="m" onClick={this.onLogoClick} />
        </div>
        <div class="c-topbar__right">
          <PortalTarget name="topbar-additional" />
        </div>
      </div>
    )
  },
})

export default create<TopbarProps, TopbarEvents>(Topbar)
</script>

<style lang="postcss">
.c-topbar {
  position: relative;
  z-index: var(--z-index-topbar);
  display: flex;
  width: 100%;
  height: var(--topbar-height);
  align-items: center;
  background: var(--attest-color-surface-default);
  color: var(--attest-color-text-default);

  &__absolute-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__right {
    padding-right: var(--attest-spacing-2);
    margin-left: auto;
  }

  &__menu {
    display: flex;
    width: var(--topbar-height);
    height: 100%;
    align-items: center;
    justify-content: center;
    color: currentcolor;
  }
}
</style>
