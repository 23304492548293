<script lang="tsx">
import type { FunctionalComponent } from 'vue'
import { RouterView } from 'vue-router'

import { VIEWS } from '@attest/router'
import { create, type ExtractSlots } from '@attest/vue-tsx'

import { DefaultNavbar } from '../DefaultNavbar'

import type { NavbarItemsPaneEvents, NavbarItemsPaneProps } from './NavbarItemsPane.interface'

const NavbarItemsPane: FunctionalComponent<NavbarItemsPaneProps, NavbarItemsPaneEvents> = (
  props,
  { emit },
) => {
  const { routeName, itemClass, ...attrs } = props

  const baseClass = 'c-navbar-items-pane'
  const navbarPaneClass = baseClass
  const navbarItemsClass = `${baseClass}__items`

  return (
    <div {...{ class: navbarPaneClass }} {...attrs}>
      <DefaultNavbar
        class={navbarItemsClass}
        routeName={routeName}
        itemClass={itemClass}
        onItemClick={() => emit('itemClick')}
        onItemHover={title => emit('itemHover', title)}
      />
      <RouterView
        name={VIEWS.NAVBAR}
        v-slots={
          {
            default: ({ Component }: any) => {
              if (!Component) return []
              return [
                <div class={navbarItemsClass}>
                  <Component
                    itemClass={itemClass}
                    onItemClick={() => emit('itemClick')}
                    onItemHover={(title: string) => emit('itemHover', title)}
                  />
                </div>,
              ]
            },
          } satisfies ExtractSlots<typeof RouterView>
        }
      />
    </div>
  )
}

export default create<NavbarItemsPaneProps, NavbarItemsPaneEvents>(NavbarItemsPane)
</script>
<style lang="postcss">
:root {
  --timing-navbar: var(--attest-timing-slow);
}

.c-navbar-items-pane {
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  &__items {
    display: grid;
    width: 100%;
    flex: 0 0 100%;
    backface-visibility: hidden;
    gap: var(--attest-spacing-1);
  }

  &--t-slide {
    &-move {
      pointer-events: none;
      transition: transform var(--timing-navbar) var(--attest-ease);
    }

    &-enter-active,
    &-leave-active {
      pointer-events: none;
      transition:
        transform var(--timing-navbar) var(--attest-ease),
        opacity var(--timing-navbar) var(--attest-ease);
    }

    &-leave-active {
      position: absolute;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  @media (--attest-media-s) {
    &-enter-active,
    &-leave-active {
      transition:
        transform var(--timing-navbar) var(--attest-ease),
        opacity var(--attest-timing-fast) var(--attest-ease);
    }
  }

  @media (--attest-media-xl) {
    transition:
      transform var(--timing-navbar) var(--attest-ease),
      opacity var(--timing-navbar) var(--attest-ease);
  }
}
</style>
