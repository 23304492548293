import { isNil } from 'ramda'

import { COPY } from '@attest/_copy'
import type {
  CardError,
  EditorErrorType,
  ExternalTitleError,
  InternalTitleError,
  SampleSizeError,
  ScheduleError,
  SurveyError,
  TargetingError,
  TargetingsError,
  TranslationError,
} from '@attest/_lib/src/editor-error/editor-error'
import { translationKey } from '@attest/_lib/src/translation/translation-key'
import { createGetErrorsFor } from '@attest/editor'
import {
  type Card,
  cardRoutesToAnotherCard,
  isQualifying,
  isQuestionCard,
  isTextCard,
} from '@attest/editor-card'
import { getTerminatingNodes, type RoutingGraph } from '@attest/editor-routing-graph'
import { SETTINGS } from '@attest/editor-settings'
import { getVariation } from '@attest/feature-switch'
import { Thyme } from '@attest/thyme'
import { isDefined } from '@attest/util'

import {
  flattenCards,
  getCardByGuid,
  getNextGuids,
  hasCompleteTranslationsForLanguage,
  isCardVideoQuestion,
} from '../card-service'
import { hasConsecutiveTextCard, isSurveyTextCardsOnly } from '../card-util'
import type { Survey, SurveyTranslations } from '../store'

export const getSurveyErrors = createGetErrorsFor<
  SurveyError['type'],
  {
    survey: Survey
    cardErrors: CardError[]
    sampleSizeErrors: SampleSizeError[]
    titleErrors: ExternalTitleError[]
    internalTitleErrors: InternalTitleError[]
    scheduleErrors: ScheduleError[]
    targetingErrors: TargetingError[]
    targetingsErrors: TargetingsError[]
    routingGraph: RoutingGraph
    longestRoute: number
    translationErrors: TranslationError[]
    isPrivacyPolicyComplete: boolean
  }
>({
  TEXT_CARD_RANDOMISED_WITH_LAST_QUESTION_CARD: ({ survey }) => {
    const cards = Object.values(survey.cards)
    const lastCard = cards[cards.length - 1]
    if (lastCard === undefined) {
      return false
    }
    return (
      lastCard.isRandomized &&
      cards.filter(({ isRandomized }) => isRandomized).some(({ type }) => type === 'text')
    )
  },
  QUALIFYING_IN_MIDDLE_OF_SURVEY: ({ survey }) => {
    return Object.values(survey.cards).some(card => {
      if (isTextCard(card) || !cardRoutesToAnotherCard(card)) return false
      const nextCards = getNextGuids(card)
        .map(nextGuid => survey.cards[nextGuid])
        .filter(isDefined)
      return (
        !isQualifying(card) &&
        nextCards.some(nextCard => {
          if (isTextCard(nextCard)) {
            const cardAfterNextCard =
              nextCard.nextGuid === null ? undefined : survey.cards[nextCard.nextGuid]
            return (
              nextCard.nextGuid !== null &&
              cardAfterNextCard !== undefined &&
              isQualifying(cardAfterNextCard)
            )
          }

          return isQualifying(nextCard)
        })
      )
    })
  },
  ALL_QUESTIONS_ARE_QUALIFYING: ({ survey }) => {
    const questions: Card[] = []

    Object.values(survey.cards).forEach(card => {
      if (isQuestionCard(card)) {
        questions.push(card)
      } else if (card.type === 'group') {
        questions.push(...card.group.cards.filter(isQuestionCard))
      }
    })

    const qualifyingQuestions = questions.filter(isQualifying)
    return questions.length > 0 && questions.length === qualifyingQuestions.length
  },
  TOO_MANY_QUESTIONS_IN_ROUTE: ({ survey, longestRoute }) => {
    const cards = Object.values(survey.cards)

    if (cards.length <= 0) {
      return false
    }

    return longestRoute > SETTINGS.MAX_NUMBER_DRAFT_QUESTIONS
  },
  TOO_MANY_TEXT_CARDS_IN_A_ROW: ({ survey }) =>
    !isSurveyTextCardsOnly(survey) && hasConsecutiveTextCard(survey),
  ENDING_CARD_IS_A_TEXT_CARD: ({ survey, routingGraph }) =>
    !isSurveyTextCardsOnly(survey) &&
    getTerminatingNodes(routingGraph).some(id => {
      const card = survey.cards[id] ?? getCardByGuid(Object.values(survey.cards), id)
      return card?.type === 'text'
    }),
  ALL_CARDS_ARE_TEXT_CARDS: ({ survey }) => isSurveyTextCardsOnly(survey),
  TARGETING_AGE_RANGES_OVERLAP: ({ targetingErrors }) =>
    targetingErrors.some(({ type }) => type === 'TARGETING_AGE_RANGES_OVERLAP'),
  TARGETING_AGE_RANGES_INVALID: ({ targetingErrors }) =>
    targetingErrors.some(({ type }) => type === 'TARGETING_AGE_RANGES_INVALID'),
  TARGETING_BELOW_MIN_AGE: ({ targetingErrors }) =>
    targetingErrors.some(({ type }) => type === 'TARGETING_BELOW_MIN_AGE'),
  VIDEO_SURVEY_TARGETING_BELOW_MIN_AGE: ({ targetingErrors }) =>
    targetingErrors.some(({ type }) => type === 'VIDEO_SURVEY_TARGETING_BELOW_MIN_AGE'),
  TARGETING_QUOTAS_MISALIGNED: ({ targetingErrors }) =>
    targetingErrors.some(({ type }) => type === 'TARGETING_QUOTAS_MISALIGNED'),
  TARGETING_QUOTA_BELOW_SAMPLE_SIZE: ({ targetingErrors }) =>
    targetingErrors.some(({ type }) => type === 'TARGETING_QUOTA_BELOW_SAMPLE_SIZE'),
  EMPTY_TARGETINGS: ({ targetingsErrors }) =>
    targetingsErrors.some(({ type }) => type === 'EMPTY_TARGETINGS'),
  CARDS_CANNOT_BE_REACHED: ({ cardErrors }) =>
    cardErrors.some(({ type }) => type === 'CARD_ROUTING_NOT_REACHABLE'),
  TOO_MANY_QUALIFYING_QUESTIONS: ({ survey }) =>
    Object.values(survey.cards).filter(isQualifying).length >
    SETTINGS.MAX_NUMBER_QUALIFYING_QUESTIONS,
  SAMPLE_SIZE_TOO_BIG: ({ sampleSizeErrors }) =>
    sampleSizeErrors.some(({ type }) => type === 'SAMPLE_SIZE_TOO_BIG'),
  QUAL_SAMPLE_SIZE_TOO_HIGH: ({ sampleSizeErrors }) =>
    sampleSizeErrors.some(({ type }) => type === 'QUAL_SAMPLE_SIZE_TOO_HIGH'),
  AVAILABLE_SIZE_TOO_SMALL: ({ sampleSizeErrors }) =>
    sampleSizeErrors.some(({ type }) => type === 'AVAILABLE_SIZE_TOO_SMALL'),
  SAMPLE_SIZE_TOO_SMALL: ({ sampleSizeErrors }) =>
    sampleSizeErrors.some(({ type }) => type === 'SAMPLE_SIZE_TOO_SMALL'),
  SURVEY_TITLE_IS_MISSING: ({ titleErrors }) =>
    titleErrors.some(({ type }) => type === 'SURVEY_TITLE_IS_MISSING'),
  SURVEY_TITLE_TOO_LONG: ({ titleErrors }) =>
    titleErrors.some(({ type }) => type === 'SURVEY_TITLE_TOO_LONG'),
  SURVEY_TITLE_TOO_SHORT: ({ titleErrors }) =>
    titleErrors.some(({ type }) => type === 'SURVEY_TITLE_TOO_SHORT'),
  SURVEY_INTERNAL_TITLE_TOO_LONG: ({ internalTitleErrors }) =>
    internalTitleErrors.some(({ type }) => type === 'SURVEY_INTERNAL_TITLE_TOO_LONG'),
  QUESTIONS_HAVE_ERRORS: ({ cardErrors }) => cardErrors.length > 0,
  EMPTY_SURVEY: ({ survey }) => Object.values(survey.cards).length === 0,
  START_BEFORE_NOW: ({ scheduleErrors }) =>
    scheduleErrors.some(({ type }) => type === 'START_BEFORE_NOW'),
  START_BEFORE_PENDING_SCHEDULE: ({ scheduleErrors }) =>
    scheduleErrors.some(({ type }) => type === 'START_BEFORE_PENDING_SCHEDULE'),
  MISSING_PRIVACY_POLICY: ({ survey, isPrivacyPolicyComplete }) => {
    if (Object.values(survey.cards).findIndex(isCardVideoQuestion) < 0) return false
    return !isPrivacyPolicyComplete
  },
  VIDEO_QUESTIONS_NOT_ENABLED: ({ survey }) => {
    const cards = flattenCards(Object.values(survey.cards))
    return cards.some(isCardVideoQuestion) && !getVariation('qual-video-question')
  },
  VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_VIDEO_QUESTIONS: ({ survey }) => {
    const cards = flattenCards(Object.values(survey.cards))
    const countOfVideoQuestions = cards.filter(isCardVideoQuestion).length
    return countOfVideoQuestions > SETTINGS.MAX_NUMBER_OF_VIDEO_QUESTIONS
  },
  VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_QUALIFYING_QUESTIONS: ({ survey }) => {
    const cards = flattenCards(Object.values(survey.cards))
    if (cards.findIndex(isCardVideoQuestion) < 0) return false
    return (
      cards.filter(isQualifying).length >
      SETTINGS.MAX_NUMBER_OF_QUALIFYING_QUESTIONS_FOR_SURVEY_WITH_VIDEO_QUESTIONS
    )
  },
  MISSING_TRANSLATIONS: ({ translationErrors }) =>
    translationErrors.some(({ type }) => type === 'MISSING_TRANSLATIONS'),
  VIDEO_QUESTION_SURVEY_WITH_TOO_MANY_NON_VIDEO_QUESTIONS: ({ survey }) => {
    const questionCards = flattenCards(Object.values(survey.cards)).filter(isQuestionCard)
    const countOfVideoQuestions = questionCards.filter(isCardVideoQuestion).length
    const countOfNonVideoQuestions = questionCards.filter(
      card => !isCardVideoQuestion(card) && !isQualifying(card),
    ).length

    return (
      countOfVideoQuestions > 0 &&
      countOfNonVideoQuestions >
        SETTINGS.MAX_NUMBER_OF_NON_VIDEO_QUESTIONS_FOR_SURVEY_WITH_VIDEO_QUESTIONS
    )
  },
})

export function getGeneralSurveyErrors(surveyErrors: SurveyError[]): SurveyError[] {
  const generalErrors: Set<EditorErrorType> = new Set([
    'TEXT_CARD_RANDOMISED_WITH_LAST_QUESTION_CARD',
    'QUALIFYING_IN_MIDDLE_OF_SURVEY',
    'ALL_QUESTIONS_ARE_QUALIFYING',
    'TOO_MANY_QUESTIONS_IN_ROUTE',
    'TOO_MANY_TEXT_CARDS_IN_A_ROW',
    'ENDING_CARD_IS_A_TEXT_CARD',
    'ALL_CARDS_ARE_TEXT_CARDS',
    'TOO_MANY_QUALIFYING_QUESTIONS',
    'EMPTY_SURVEY',
  ])
  return surveyErrors.filter((surveyError: SurveyError) => generalErrors.has(surveyError.type))
}

export const getTitleErrors = createGetErrorsFor<ExternalTitleError['type'], string>({
  SURVEY_TITLE_IS_MISSING: title => title.length === 0,
  SURVEY_TITLE_TOO_LONG: title => title.length > SETTINGS.MAX_SURVEY_TITLE_LENGTH,
  SURVEY_TITLE_TOO_SHORT: title =>
    title.length > 0 && title.length < SETTINGS.MIN_SURVEY_TITLE_LENGTH,
})

export const getInternalTitleErrors = createGetErrorsFor<InternalTitleError['type'], string>({
  SURVEY_INTERNAL_TITLE_TOO_LONG: title => title.length > SETTINGS.MAX_SURVEY_INTERNAL_TITLE_LENGTH,
})

export const getTranslationErrors = createGetErrorsFor<
  TranslationError['type'],
  {
    cards: Card[]
    surveyTranslations: SurveyTranslations
    countryLanguages: { country: string; language: string; audienceIds: string[] }[]
    routingGraph: RoutingGraph
  }
>({
  MISSING_TRANSLATIONS: ({ cards, surveyTranslations, countryLanguages, routingGraph }) => {
    const hasCompleteCardTranslations = cards
      .flatMap(c => (c.type === 'group' ? c.group.cards : c))
      .every(card =>
        countryLanguages.every(countryLanguage =>
          hasCompleteTranslationsForLanguage({ card, countryLanguage, routingGraph }),
        ),
      )

    const hasCompleteSurveyTranslations = countryLanguages.every(countryLanguage => {
      const key = translationKey(countryLanguage)
      const translationForKey = surveyTranslations[key]
      return (
        translationForKey && Object.values(translationForKey)?.every(translation => translation)
      )
    })

    return !hasCompleteCardTranslations || !hasCompleteSurveyTranslations
  },
})

export const getSchedulingError = createGetErrorsFor<
  ScheduleError['type'],
  {
    startTimestamp: number | null
    timeZoneId: string | null
    pendingSchedule: { timestamp: number; timeZoneId: string } | null
  }
>({
  START_BEFORE_NOW: ({ startTimestamp }) => {
    if (startTimestamp === null) return false
    const startThyme = Thyme.fromMillis(startTimestamp)
    const nowThyme = Thyme.utc()

    return startThyme < nowThyme
  },

  START_BEFORE_PENDING_SCHEDULE({ startTimestamp, timeZoneId, pendingSchedule }) {
    if (isNil(pendingSchedule)) return false

    return (
      Thyme.fromMillis(startTimestamp ?? Thyme.now(), { zone: timeZoneId }) <
      Thyme.fromMillis(pendingSchedule.timestamp, { zone: pendingSchedule.timeZoneId })
    )
  },
})

export function getTitleErrorsText(
  errors: (ExternalTitleError | InternalTitleError)[],
): string | undefined {
  if (errors.length > 0) {
    const errorsCopy = errors.map(error => {
      return toTitleErrorCopy(error)
    })
    return errorsCopy[0]
  }

  return undefined
}

function toTitleErrorCopy(error: ExternalTitleError | InternalTitleError): string {
  switch (error.type) {
    case 'SURVEY_TITLE_IS_MISSING':
      return COPY.EDITOR.ERRORS[error.type]
    case 'SURVEY_TITLE_TOO_LONG':
      return COPY.EDITOR.ERRORS[error.type](SETTINGS.MAX_SURVEY_TITLE_LENGTH)
    case 'SURVEY_TITLE_TOO_SHORT':
      return COPY.EDITOR.ERRORS[error.type](SETTINGS.MIN_SURVEY_TITLE_LENGTH)
    case 'SURVEY_INTERNAL_TITLE_TOO_LONG':
      return COPY.EDITOR.ERRORS[error.type](SETTINGS.MAX_SURVEY_INTERNAL_TITLE_LENGTH)
  }
}
