import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { ADMIN_ROUTES, VIEWS } from '@attest/router'

export function createAdminRoute(): RouteRecordRaw {
  return {
    name: ADMIN_ROUTES.ROOT.name,
    path: ADMIN_ROUTES.ROOT.path,
    components: {
      default: async () => (await import('./component/view/AdminHome/AdminHome.vue')).default,
      [VIEWS.NAVBAR]: async () =>
        (await import('./component/feature/AdminNavbar/AdminNavbar.vue')).default,
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
      permissionRequired: ['GLOBAL_ADMIN_ACTIONS'],
    },
  }
}

export function createAdminSurveysRoute(): RouteRecordRaw {
  return {
    name: ADMIN_ROUTES.SURVEYS.name,
    path: ADMIN_ROUTES.SURVEYS.path,
    components: {
      default: async () => (await import('./component/view/AdminSurveys/AdminSurveys.vue')).default,
      [VIEWS.NAVBAR]: async () =>
        (await import('./component/feature/AdminNavbar/AdminNavbar.vue')).default,
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
      permissionRequired: ['GLOBAL_ADMIN_ACTIONS'],
    },
  }
}

export function createAdminStudyRoute(): RouteRecordRaw {
  return {
    name: ADMIN_ROUTES.STUDY.name,
    path: ADMIN_ROUTES.STUDY.path,
    components: {
      default: async () => (await import('./component/view/AdminStudy/AdminStudy.vue')).default,
      [VIEWS.NAVBAR]: async () =>
        (await import('./component/feature/AdminNavbar/AdminNavbar.vue')).default,
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
      permissionRequired: ['GLOBAL_ADMIN_ACTIONS'],
    },
    props: {
      default: transformRouteToAdminStudyProps,
    },
  }
}

function transformRouteToAdminStudyProps(to: RouteLocation): { guid: string } {
  return {
    guid: to.params.surveyGuid as string,
  }
}
