type Procedure = (...args: any[]) => void

export const DEFAULT_MILLISECONDS = 180

// @deprecated use debounce method
export class Debounce<F extends Procedure = Procedure> {
  public readonly invoke: F

  private timer = -1
  private doLater: () => void = () => {}
  private readonly method: F
  private readonly waitMilliseconds: number

  public constructor(func: F, waitMilliseconds = DEFAULT_MILLISECONDS) {
    this.method = func
    this.waitMilliseconds = waitMilliseconds

    this.clear = this.clear.bind(this)
    this.invoke = this.caller.bind(this) as F
  }

  public clear(): void {
    clearTimeout(this.timer)
  }

  public flush(): void {
    this.doLater()
  }

  private caller(...args: any[]): void {
    this.doLater = () => {
      this.timer = -1
      this.method(...args)
    }

    this.clear()
    this.timer = setTimeout(() => {
      this.doLater()
      this.doLater = () => {}
    }, this.waitMilliseconds) as any as number
  }
}

export function debounce<T extends unknown[], O>(
  f: (this: O, ...args: T) => any,
  ms: number,
): (this: O, ...args: T) => void {
  let timeoutId = -1

  return function (...args) {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => Reflect.apply(f, this, args), ms)
  }
}
