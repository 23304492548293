import { nanoid } from 'nanoid'

import { isSavedApiQuestionCard } from '@attest/_api/model/card'
import type { SavedApiNode } from '@attest/_api/model/node'
import type { AnswerQuota, AnswerQuotaId } from '@attest/editor-card'
import { keyBy } from '@attest/util'

import type { PutAnswerQuotasRequestData } from '../model'
import { isApiCard, isSavedApiGridQuestion } from '../util'

export type CardGuidToAnswerIdToQuota = {} & Record<string, Record<string, AnswerQuota>>

export function transformApiCardsToCardGuidToAnswerQuota(
  apiNode: SavedApiNode[],
): CardGuidToAnswerIdToQuota {
  return Object.fromEntries(
    apiNode.map(node => [node.guid, transformApiNodeToAnswerIdToAnswerQuota(node)]),
  )
}

function transformApiNodeToAnswerIdToAnswerQuota(
  apiNode: SavedApiNode,
): Record<string, AnswerQuota> {
  if (
    !isApiCard(apiNode) ||
    !isSavedApiQuestionCard(apiNode) ||
    isSavedApiGridQuestion(apiNode) ||
    apiNode.card_type === 'grid_v2'
  ) {
    return {}
  }

  return Object.fromEntries(
    apiNode.answer_quotas.flatMap(apiAnswerQuota =>
      apiAnswerQuota.answer_indexes.map(index => [
        apiNode.answers[index]?.id,
        { id: nanoid(), minTarget: apiAnswerQuota.min_target },
      ]),
    ),
  )
}

export function transformCardGuidToAnswerIdToQuotaToQuotaIdToQuota(
  cardGuidToAnswerIdToQuota: Record<string, Record<string, AnswerQuota>>,
): Record<AnswerQuotaId, AnswerQuota> {
  return keyBy(
    Object.values(cardGuidToAnswerIdToQuota).flatMap(answerIdToQuota =>
      Object.values(answerIdToQuota),
    ),
    ({ id }) => id,
  )
}

export function transformAnswerQuotasToAnswerQuotasRequestData(
  questionGuidToAnswerIdToQuota: Record<string, Record<string, AnswerQuota>>,
): PutAnswerQuotasRequestData {
  return {
    value: Object.entries(questionGuidToAnswerIdToQuota).map(questionEntry => {
      return {
        question_guid: questionEntry[0],
        answer_quotas: Object.entries(questionEntry[1]).map(answerEntry => {
          return {
            choice_ids: [answerEntry[0]],
            min_target: answerEntry[1].minTarget,
          }
        }),
      }
    }),
  }
}
