import type { CardQualifyingError } from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import {
  type AnswerQuota,
  areAnswerQuotasValid,
  type Card,
  isAnswerQuotasSumFull,
  isAnswerQuotasSumTooHigh,
  isQualifying as isCardQualifying,
} from '@attest/editor-card'
import { getAncestorSender } from '@attest/editor-masking'
import { union } from '@attest/util'

export const getCardQualifyingErrors = createGetErrorsFor<
  CardQualifyingError['type'],
  {
    card: Card
    cards: Card[]
    allAnswerQuotas: AnswerQuota[]
    answerQuotas: AnswerQuota[]
  }
>({
  QUALIFYING_WITH_ANSWER_ROUTES_IS_NOT_LAST_QUALIFYING: ({ card, cards, allAnswerQuotas }) => {
    if (!isCardQualifying(card)) return false
    if (allAnswerQuotas.length === 0) return false
    if (!card.question.options.answers.some(({ nextGuid }) => !!nextGuid)) return false
    return cards.filter(c => isCardQualifying(c)).at(-1)?.guid !== card.guid
  },
  QUALIFYING_QUOTAS_SUM_TOO_HIGH: ({ card, answerQuotas }) => {
    if (!isCardQualifying(card)) return false
    return isAnswerQuotasSumTooHigh(answerQuotas)
  },
  QUALIFYING_QUOTAS_AMOUNT_USED_UP: ({ card, answerQuotas }) => {
    if (!isCardQualifying(card)) return false
    return (
      isAnswerQuotasSumFull(answerQuotas) &&
      card.question.options.answers.some(({ isQualifying, quotaId }) => isQualifying && !quotaId)
    )
  },
  INVALID_ANSWER_QUOTA_VALUES: ({ card, answerQuotas }) => {
    if (!isCardQualifying(card)) return false
    return !areAnswerQuotasValid(answerQuotas)
  },
  AUDIENCE_CANT_QUALIFY: ({ card, cards }) => {
    if (!isCardQualifying(card)) {
      return false
    }

    const sourceCard = getAncestorSender(card, cards)
    const audiencesLocalised = [
      ...union(
        card.question.options.answers
          .map(a => a.omittedFromAudiences)
          .concat(sourceCard?.question.options.answers.map(a => a.omittedFromAudiences) ?? []),
      ),
    ]

    for (const audienceId of audiencesLocalised) {
      const answersVisibleToAudience = card.question.options.answers.filter(
        (answer, index) =>
          !answer.omittedFromAudiences.has(audienceId) &&
          !sourceCard?.question.options.answers[index]?.omittedFromAudiences.has(audienceId),
      )

      const noVisibleAnswersAreQualifying = answersVisibleToAudience.every(
        answer => !answer.isQualifying,
      )

      if (noVisibleAnswersAreQualifying) {
        return true
      }
    }

    return false
  },
})
