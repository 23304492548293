import { defineStore } from 'pinia'

import { type ApiError, type InternalError, isAppError } from '@attest/_api'
import { type ExclusiveSample, listExclusiveSamplesForSurvey } from '@attest/survey'

export type StudyManagementState = {
  waveDrawerActiveId: string | undefined
  surveyGuidToExclusiveSamples: Record<string, ExclusiveSample[]>
}

export function createStudyManagementState(
  override: Partial<StudyManagementState> = {},
): StudyManagementState {
  return {
    waveDrawerActiveId: undefined,
    surveyGuidToExclusiveSamples: {},
    ...override,
  }
}

export const STUDY_MANAGEMENT_STORE_ID = 'studyManagement'

export const useStudyManagementStore = defineStore(STUDY_MANAGEMENT_STORE_ID, {
  state: createStudyManagementState,

  actions: {
    async fetchExclusiveSamples({
      guid,
    }: {
      guid: string
    }): Promise<ExclusiveSample[] | InternalError<ApiError>> {
      const surveyGuidToExclusiveSamples = await listExclusiveSamplesForSurvey({ guid })
      if (isAppError(surveyGuidToExclusiveSamples)) return surveyGuidToExclusiveSamples
      this.surveyGuidToExclusiveSamples[guid] = surveyGuidToExclusiveSamples
      return surveyGuidToExclusiveSamples
    },

    async getOrFetchExclusiveSamples({
      guid,
    }: {
      guid: string
    }): Promise<ExclusiveSample[] | InternalError<ApiError>> {
      return this.surveyGuidToExclusiveSamples[guid] ?? (await this.fetchExclusiveSamples({ guid }))
    },
  },
})
