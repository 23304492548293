import type { CardGuid } from '@attest/editor-card'

export enum DISPLAY_LOGIC_ANSWER_OPERATOR {
  ALL_SELECTED = 'ALL_SELECTED',
  ANY_SELECTED = 'ANY_SELECTED',
  NONE_SELECTED = 'NONE_SELECTED',
}

export type DisplayLogicOperator = 'AND' | 'OR'

export type DisplayLogicRule = {
  guid: CardGuid
  operator: DisplayLogicOperator
  conditions: DisplayLogicCondition[]
}
export type DisplayLogicCondition = {
  referenceGuid: CardGuid
  answers: DisplayLogicAnswer[]
  answerOperator: DISPLAY_LOGIC_ANSWER_OPERATOR
}
export type DisplayLogicAnswer = {
  answerId: string
  subjectId?: string
}
