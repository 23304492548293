import type { RouteRecordRaw } from 'vue-router'

import { TEMPLATE_PREVIEW_ROUTES, VIEWS } from '@attest/router'
import { createTemplateEntity, useGetTrackingEntities } from '@attest/tracking'

import {
  TemplatePreviewNavbar,
  type TemplatePreviewNavbarProps,
} from './organism/TemplatePreviewNavbar'
import { TemplatePreview } from './page/TemplatePreview'

export function createTemplatePreviewRoute(): RouteRecordRaw {
  return {
    name: TEMPLATE_PREVIEW_ROUTES.ROOT.name,
    path: TEMPLATE_PREVIEW_ROUTES.ROOT.path,
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'either',
      ...useGetTrackingEntities(route => [
        createTemplateEntity({ id: route.params.guid as string }),
      ]),
    },
    components: {
      default: TemplatePreview,
      [VIEWS.NAVBAR]: TemplatePreviewNavbar,
    },
    props: {
      default(route) {
        return {
          guid: route.params.guid,
        }
      },
      [VIEWS.NAVBAR](route): TemplatePreviewNavbarProps {
        return {
          guid: route.params.guid as string,
        }
      },
    },
  }
}
