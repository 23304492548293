import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { STUDY_MANAGEMENT_ROUTES, VIEWS } from '@attest/router'

export function createStudyManagementRoute(
  props: (route: RouteLocation) => { studyId: string },
): RouteRecordRaw {
  return {
    name: STUDY_MANAGEMENT_ROUTES.ROOT.name,
    path: STUDY_MANAGEMENT_ROUTES.ROOT.path,
    components: {
      [VIEWS.STUDY_NAVBAR]: async () =>
        (
          await import(
            '@attest/study/src/components/organism/StudyNavbar/ResultsAndManageNavbar.vue'
          )
        ).default,
      default: async () => (await import('./organism/StudyManagement')).StudyManagement,
    },
    props: { default: props },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
    },
  }
}
