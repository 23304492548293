import { createRouter as createVueRouter, createWebHistory, type Router } from 'vue-router'

import { routes } from './routes'

export function createRouter(): Router {
  const router = createVueRouter({
    history: createWebHistory('/'),
    routes,
  })

  return router
}
