<script lang="tsx">
import { defineComponent, type PropType } from 'vue'

import { createEmitter, EMPTY_VNODE } from '@attest/vue-tsx'

import type { DOMMutationObserverEvents } from './DOMMutationObserver.interface'

const emit = createEmitter<DOMMutationObserverEvents>()

export default defineComponent({
  props: {
    attributeFilter: { type: Object as PropType<string[]>, default: undefined },
    attributeOldValue: { type: Boolean, default: undefined },
    attributes: { type: Boolean, default: undefined },
    characterData: { type: Boolean, default: undefined },
    characterDataOldValue: { type: Boolean, default: undefined },
    childList: { type: Boolean, default: undefined },
    subtree: { type: Boolean, default: undefined },
  },

  data() {
    return {
      mutationObserver: undefined as MutationObserver | undefined,
    }
  },

  mounted() {
    this.mutationObserver = new MutationObserver(this.onMutation)
    this.mutationObserver.observe(this.$el, {
      attributeFilter: this.attributeFilter,
      attributeOldValue: this.attributeOldValue,
      attributes: this.attributes,
      characterData: this.characterData,
      characterDataOldValue: this.characterDataOldValue,
      childList: this.childList,
      subtree: this.subtree,
    })
  },

  beforeUnmount() {
    this.mutationObserver?.disconnect()
  },

  methods: {
    onMutation(mutations: MutationRecord[]): void {
      for (const mutation of mutations) {
        emit(this, 'mutation', mutation)
      }
    },
  },

  render() {
    return this.$slots.default?.()[0] ?? EMPTY_VNODE
  },
})
</script>
