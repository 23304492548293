import { computed, type ComputedRef, type Ref, ref } from 'vue'

import type { FallibleResource } from './model'

type Operation<T> = (existingData: T | undefined) => Promise<T>

export function useFallible<T>(options?: {
  onError?: (payload: { error: unknown; refetch: () => Promise<void> }) => void | Promise<unknown>
}): {
  data: ComputedRef<FallibleResource<T>>
  trigger: (operation: Operation<T>) => Promise<void>
  set: (newValue: T) => void
  reset: () => void
} {
  const state = ref<FallibleResource<T>>({ status: 'waiting' }) as Ref<FallibleResource<T>>

  const trigger = async (operation: Operation<T>): Promise<void> => {
    if (state.value.status === 'loading') {
      return
    }
    const previousData = state.value.status === 'succeeded' ? state.value.data : undefined
    state.value = { status: 'loading', previousData }
    const result = await operation(previousData)
      .then(data => ({ status: 'succeeded' as const, data }))
      .catch((error: unknown) => {
        return { status: 'failed' as const, error }
      })

    state.value = result
    if (state.value.status === 'failed') {
      await options?.onError?.({
        error: state.value.error,
        refetch: () => trigger(operation),
      })
    }
  }

  return {
    data: computed(() => state.value),
    trigger,
    set: (newValue: T) => {
      state.value = { status: 'succeeded', data: newValue }
    },
    reset: () => (state.value = { status: 'waiting' }),
  }
}
