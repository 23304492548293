import {
  type ApiError,
  apiErrorToError,
  type InternalError,
  service,
  type ServiceRequest,
} from '@attest/_api'
import type { AuthenticateResponseSuccess } from '@attest/_api/model/authenticate'

import type { Authentication, RegistrationExperience } from './model'

export const REQUEST_URL = {
  AUTHENTICATION: '/api/authentication',
  SSO_REGISTER_URL: '/api/sso/register/url',
}

export const authenticate: ServiceRequest<Authentication, ApiError> = async (): Promise<
  Authentication | InternalError<ApiError>
> => {
  try {
    const response = await service().get<AuthenticateResponseSuccess>(REQUEST_URL.AUTHENTICATION)
    return {
      authenticated: response.data.authenticated,
      impersonated: response.data.impersonated,
    }
  } catch (e) {
    return apiErrorToError(e)
  }
}

export function ssoRegister({
  redirect = '/',
  experience,
  redirectURI,
}: {
  redirect?: string
  experience?: RegistrationExperience
  redirectURI?: string
} = {}): void {
  const queryParams = new URLSearchParams({
    terms: 'true',
    redirect,
    ...(typeof experience !== 'undefined' && { experience }),
    ...(redirectURI && { 'redirect-uri': redirectURI }),
  })

  if (window.top === window) {
    window.location.assign(`${REQUEST_URL.SSO_REGISTER_URL}?${queryParams}`)
  } else {
    ;(window.top as any).location = `${REQUEST_URL.SSO_REGISTER_URL}?${queryParams}`
  }
}
