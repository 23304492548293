import type { RoutingGraph } from './model'

function comparePosition(a: { x: number; y: number }, b: { x: number; y: number }): number {
  if (a.y !== b.y) return Math.sign(a.y - b.y)
  return Math.sign(a.x - b.x)
}

export function createNodeByPositionSorter(graph: RoutingGraph) {
  return (a: string, b: string): number =>
    comparePosition(graph.getNodeAttributes(a), graph.getNodeAttributes(b))
}
