import { ref } from 'vue'

import { useFallible } from './use-fallible'

export function useRetry<T>(): ReturnType<typeof useFallible<T>> {
  const attempts = ref(0)

  return useFallible<T>({
    onError: async ({ refetch }) => {
      attempts.value += 1
      if (attempts.value < 3) {
        await refetch()
      }
    },
  })
}
