<script lang="tsx">
import type { MarkOptional } from 'ts-essentials'
import type { FunctionalComponent } from 'vue'

import { UserAvatar, type UserAvatarProps } from '@attest/_components/src/common/UserAvatar'
import { create } from '@attest/vue-tsx'

import type { User } from '../../model'

type InlineUserProps = {
  theme?: UserAvatarProps['theme']
  user: MarkOptional<User, 'email'>
}

const InlineUser: FunctionalComponent<InlineUserProps> = (props, { slots }) => {
  const baseClass = 'c-inline-user'
  const { user, theme, ...attrs } = props

  return (
    <div class={baseClass} {...attrs}>
      <UserAvatar
        data-name="InlineUserUserAvatar"
        user={user}
        theme={theme}
        class={`${baseClass}__avatar`}
      />
      <div class={`${baseClass}__name-wrapper`}>
        <p
          data-name="InlineUserName"
          class={{
            [`${baseClass}__name`]: true,
          }}
        >
          {user.firstName ? `${user.firstName} ${user.lastName}` : user.email}
        </p>
        {slots.default?.()}
      </div>
    </div>
  )
}

export default create<InlineUserProps>(InlineUser)
</script>
<style lang="postcss">
.c-inline-user {
  display: flex;
  min-width: 0;
  align-items: center;

  &__avatar {
    display: inline-flex;
    flex: 0 0 auto;
  }

  &__name-wrapper {
    overflow: hidden;
    margin-left: var(--attest-spacing-2);
  }

  &__name {
    display: block;
    overflow: hidden;
    color: currentcolor;
    font-weight: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
