type FishersExactTestResult = {
  lessPValue: number
  greaterPValue: number
  twoTailedPValue: number
}

/**
 *
 * @param {number} n11 - split 1 outcome 1
 * @param {number} n12 - split 2 outcome 2
 * @param {number} n21 - split 2 outcome 2
 * @param {number} n22 - split 2 outcome 2
 */
export function fishersExactTest(
  n11: number,
  n12: number,
  n21: number,
  n22: number,
): FishersExactTestResult {
  const row1 = n11 + n12
  const col1 = n11 + n21
  const n = n11 + n12 + n21 + n22
  const { sleft, sright, sless, slarg } = exact(n11, row1, col1, n)

  const left = sless
  const right = slarg
  const twotail = Math.min(sleft + sright, 1)

  return {
    lessPValue: left,
    greaterPValue: right,
    twoTailedPValue: twotail,
  }
}

function lngamm(z: number): number {
  let x = 0
  x += 0.1659470187408462e-6 / (z + 7)
  x += 0.9934937113930748e-5 / (z + 6)
  x -= 0.1385710331296526 / (z + 5)
  x += 12.50734324009056 / (z + 4)
  x -= 176.6150291498386 / (z + 3)
  x += 771.3234287757674 / (z + 2)
  x -= 1259.139216722289 / (z + 1)
  x += 676.5203681218835 / z
  x += 0.9999999999995183
  return Math.log(x) - 5.58106146679532777 - z + (z - 0.5) * Math.log(z + 6.5)
}

function lnfact(n: number): number {
  if (n <= 1) {
    return 0
  }

  return lngamm(n + 1)
}

function lnbico(n: number, k: number): number {
  return lnfact(n) - lnfact(k) - lnfact(n - k)
}

type HyperState = {
  isValid: boolean
  n11: number
  row1: number
  col1: number
  n: number
  prob: number
}

function newHyperState(): HyperState {
  return {
    n11: 0,
    row1: 0,
    col1: 0,
    n: 0,
    prob: 0,
    isValid: false,
  }
}

function hyper(state: HyperState, n11: number): number {
  return hyper0(state, n11, 0, 0, 0)
}

function hyper0(s: HyperState, n11: number, row1: number, col1: number, n: number): number {
  if (s.isValid && (row1 | col1 | n) === 0) {
    if (!(n11 % 10 === 0)) {
      if (n11 === s.n11 + 1) {
        s.prob *= ((s.row1 - s.n11) / n11) * ((s.col1 - s.n11) / (n11 + s.n - s.row1 - s.col1))
        s.n11 = n11
        return s.prob
      }

      if (n11 === s.n11 - 1) {
        s.prob *= (s.n11 / (s.row1 - n11)) * ((s.n11 + s.n - s.row1 - s.col1) / (s.col1 - n11))
        s.n11 = n11
        return s.prob
      }
    }
    s.n11 = n11
  } else {
    s.n11 = n11
    s.row1 = row1
    s.col1 = col1
    s.n = n
    s.isValid = true
  }
  s.prob = hyper323(s.n11, s.row1, s.col1, s.n)
  return s.prob
}

function hyper323(n11: number, row1: number, col1: number, n: number): number {
  return Math.exp(lnbico(row1, n11) + lnbico(n - row1, col1 - n11) - lnbico(n, col1))
}

type ExactResult = {
  sleft: number
  sright: number
  sless: number
  slarg: number
}

function exact(n11: number, row1: number, col1: number, n: number): ExactResult {
  const max = Math.min(row1, col1)
  const min = Math.max(row1 + col1 - n, 0)
  if (min === max) {
    return {
      sleft: 1,
      sright: 1,
      sless: 1,
      slarg: 1,
    }
  }

  const hyperState = newHyperState()
  const prob = hyper0(hyperState, n11, row1, col1, n)

  let sleft = 0
  let p = hyper(hyperState, min)
  let i = min + 1
  while (p <= 0.99999999 * prob) {
    sleft += p
    p = hyper(hyperState, i)
    i += 1
  }

  i -= 1
  if (p <= 1.00000001 * prob) {
    sleft += p
  } else {
    i += 1
  }

  let sright = 0
  p = hyper(hyperState, max)

  let j = max - 1
  while (p <= 0.99999999 * prob) {
    sright += p
    p = hyper(hyperState, j)
    j -= 1
  }

  j += 1
  if (p <= 1.00000001 * prob) {
    sright += p
  } else {
    j += 1
  }

  let sless = 0
  let slarg = 0
  if (Math.abs(i - n11) < Math.abs(j - n11)) {
    sless = sleft
    slarg = 1 - sleft + prob
  } else {
    sless = 1 - sright + prob
    slarg = sright
  }

  return {
    sleft,
    sright,
    sless,
    slarg,
  }
}
