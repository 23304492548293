<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create, type ElementAttributes } from '@attest/vue-tsx'

import { RoleButton } from '../RoleButton'

import type { BareButtonProps } from './interface'

const BareButton: FunctionalComponent<BareButtonProps> = (props, { slots }) => {
  const { elementTag, selected, ...attrs } = props

  const bareButtonClass = { 'c-bare-button': true }

  return (
    <RoleButton elementTag={elementTag} class={bareButtonClass} selected={selected} {...attrs}>
      {slots.default?.()}
    </RoleButton>
  )
}

export default create<BareButtonProps & ElementAttributes<'a'>>(BareButton)
</script>

<style lang="postcss">
.c-bare-button {
  padding: 0;
  border: none;
  background-color: transparent;

  &[disabled] {
    pointer-events: none;
  }
}
</style>
