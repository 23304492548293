import type { ImageMedia } from '@attest/_lib/src/media/model/media'
import { keyBy } from '@attest/util'

import { createEmptyAnswer } from './factory'
import {
  type Card,
  type CardQuestionOptions,
  type CardQuestionSubjects,
  type CardQuestionType,
  type CombinedCardType,
  isCardGridQuestion,
  isCardMultipleChoiceQuestion,
  isCombinedCardTypeQuestion,
  isCombinedCardTypeText,
  type OptionAnswer,
} from './model'

export function isCompleteCombinedCardType(combinedCardType: CombinedCardType): boolean {
  if (isCombinedCardTypeQuestion(combinedCardType)) {
    return combinedCardType.subType !== null
  }

  if (isCombinedCardTypeText(combinedCardType)) {
    return combinedCardType.subType !== null
  }

  return false
}

export function isChoiceCardQuestionType(cardQuestionType: CardQuestionType): boolean {
  return cardQuestionType === 'single_choice' || cardQuestionType === 'multiple_choice'
}

export function updateAnswerText(answer: OptionAnswer, text: string): OptionAnswer {
  return { ...answer, text }
}

export function updateAnswerImage(
  options: CardQuestionOptions,
  payload: { index: number; media: ImageMedia | null },
): CardQuestionOptions {
  const { index, media } = payload
  const answers = options.answers.map((answer, answerIndex) => {
    if (answerIndex !== index) return answer
    return { ...answer, media: media }
  })

  return { ...options, answers }
}

export function addOptionAnswer(options: CardQuestionOptions): CardQuestionOptions {
  const newAnswer = createEmptyAnswer()
  const answers = [...options.answers, newAnswer]
  return { ...options, answers }
}

export function removeOptionAnswer(
  options: CardQuestionOptions,
  index: number,
): CardQuestionOptions {
  const answers = [...options.answers]
  answers.splice(index, 1)
  return {
    ...options,
    maxSelections: options.answers.length >= answers.length ? null : options.maxSelections,
    answers,
  }
}

export function removeSubjectsHeader(
  subjects: CardQuestionSubjects,
  index: number,
): CardQuestionSubjects {
  const headers = [...subjects.headers]
  headers.splice(index, 1)
  return { ...subjects, headers }
}

export function togglePinnedForAnswer(
  options: CardQuestionOptions,
  index: number,
): CardQuestionOptions {
  const answers = options.answers.map((answer, answerIndex) => {
    if (answerIndex !== index) return answer
    return { ...answer, isPinned: !answer.isPinned }
  })

  return { ...options, answers }
}

export function supportsOther(card: Card): boolean {
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
      return !card.question.options.isImage
    case 'grid':
    case 'open_text':
    case 'ranked':
    case 'nps':
    default:
      return false
  }
}

export function supportsNone(card: Card): boolean {
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
    case 'nps':
    case 'grid':
      return true
    case 'open_text':
    case 'ranked':
    default:
      return false
  }
}

export function supportsNa(card: Card): boolean {
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
    case 'grid':
    case 'open_text':
    case 'ranked':
    case 'nps':
      return true
    default:
      return false
  }
}

export function supportsQualifying(card: Card): boolean {
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
      return true
    case 'grid':
    case 'open_text':
    case 'ranked':
    case 'nps':
    default:
      return false
  }
}

export function supportsImageAnswers(card: Card): boolean {
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
    case 'grid':
      return true
    case 'open_text':
    case 'ranked':
    case 'nps':
    default:
      return false
  }
}

export function supportsRandomizeAnswers(card: Card): boolean {
  switch (card.question.type) {
    case 'single_choice':
    case 'multiple_choice':
    case 'grid':
    case 'ranked':
      return true
    case 'open_text':
    case 'nps':
    default:
      return false
  }
}

export function supportsPipingFrom(card: Card): boolean {
  return card.question.type === 'single_choice'
}

export function supportsSelectionType(card: Card): boolean {
  return card.question.type === 'grid'
}

export function supportsMaxSelectionLimit(card: Card): boolean {
  switch (card.question.type) {
    case 'multiple_choice':
    case 'grid':
      return true
    case 'single_choice':
    case 'ranked':
    case 'open_text':
    case 'nps':
    default:
      return false
  }
}

export function canSelectMultiple(card: Card): boolean {
  return supportsSelectionType(card)
    ? card.question.options.selectionType === 'multiple'
    : isCardMultipleChoiceQuestion(card)
}

export function isCardSingleChoiceGridQuestion(card: Card): boolean {
  return isCardGridQuestion(card) && card.question.options.selectionType === 'single'
}

export const supportedReceivers = ['grid', 'multiple_choice', 'single_choice'] as const
export type SupportedReceiver = (typeof supportedReceivers)[number]

export function captureCardsPositioningSnapshot(
  cards: Card[],
): Record<string, { guid: string; position: { x: number; y: number }; nextGuid: string }> {
  return JSON.parse(
    JSON.stringify({
      ...keyBy(
        cards.map(({ guid, position, nextGuid }) => ({
          guid,
          position,
          nextGuid,
        })),
        ({ guid }) => guid,
      ),
    }),
  )
}
