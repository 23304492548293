import {
  type ApiError,
  type InternalError,
  reasonErrorToInternalError,
  service,
  type ServiceRequest,
} from '@attest/_api'
import type {
  FeasibilityArgs,
  FeasibilityError,
  FeasibilityOverrideRequestData,
} from '@attest/_api/model/feasibility'

export const REQUEST_URL = {
  FEASIBILITY: '/api/feasibility',
  FEASIBILITY_PUBLIC: '/api/feasibility-public',
  FEASIBILITY_OVERRIDE: (guid: string) => `/api/surveys/${guid}/sample-size-override` as const,
  SAVED_AUDIENCES: (organisationId: string) => `/api/${organisationId}/audiences`,
}

export const feasibility: ServiceRequest<
  number,
  FeasibilityError,
  FeasibilityArgs
> = async args => {
  if (args.country === '') return 0
  try {
    const response = await service().post<{ estimated_reach: number }>(
      REQUEST_URL.FEASIBILITY,
      args,
    )
    return response.data.estimated_reach
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export const feasibilityPublic: ServiceRequest<
  number,
  FeasibilityError,
  FeasibilityArgs
> = async args => {
  if (args.country === '') return 0
  try {
    const response = await service().post<{ estimated_reach: number }>(
      REQUEST_URL.FEASIBILITY_PUBLIC,
      args,
    )
    return response.data.estimated_reach
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export async function feasibilityOverride(args: {
  guid: string
  requestData: FeasibilityOverrideRequestData
}): Promise<void | InternalError<ApiError>> {
  try {
    await service().put(REQUEST_URL.FEASIBILITY_OVERRIDE(args.guid), { ...args.requestData })
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}
