import {
  browserTracingIntegration,
  init,
  replayIntegration,
  setUser as setSentryUser,
  thirdPartyErrorFilterIntegration,
} from '@sentry/vue'
import type { App } from 'vue'

export function setup({
  dsn,
  environment,
  version,
  baseUrl,
  app,
}: {
  dsn: string | undefined
  environment: string
  version: string
  baseUrl: string
  app: App
}): void {
  init({
    dsn,
    app,
    integrations: [
      browserTracingIntegration(),
      replayIntegration(),
      thirdPartyErrorFilterIntegration({
        filterKeys: ['dashboard'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    debug: import.meta.env.DEV,
    environment,
    release: version,
    tracesSampleRate: 0.3,
    tracePropagationTargets: [baseUrl, /^\//],
    tracingOptions: {
      trackComponents: true,
    },
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        if (!hint) return breadcrumb

        const target = hint.event.target as HTMLElement
        const dataAttributes = target.dataset
        breadcrumb.data = {
          dataAttributes,
        }
      }
      return breadcrumb
    },
    beforeSend(event, hint) {
      /* eslint-disable-next-line no-console */
      if (hint) console.error(hint.originalException || hint.syntheticException)
      return event
    },
  })
}

export function setUser(user: {
  [prop: string]: any
  guid: string | null
  email?: string | null
}): void {
  const { guid: id, email, ...state } = user
  setSentryUser(
    id
      ? {
          ...state,
          id: id ?? undefined,
          email: email ?? undefined,
        }
      : null,
  )
}
