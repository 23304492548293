import {
  AND,
  BETWEEN,
  eflStringToFilterNode,
  EQUAL,
  FilterBlock,
  FilterExpression,
  type FilterNode,
  IN,
  INCLUDES,
  OR,
} from '@attest/efl'
import { isRange, type Range } from '@attest/util'

import { isFilterBlock, isFilterExpression } from '../../../results/packages/efl/src/feature/model'

import { fieldAndValues, type RoundFieldKey } from './filter'

export function createRoundSurveyIdsFilterNode(surveyIds: string[]): FilterNode {
  if (surveyIds.length === 1) {
    return EQUAL(...fieldAndValues('survey.id', surveyIds[0]))
  }
  return IN(...fieldAndValues('survey.id', surveyIds))
}

export function createRoundSurveyIdFilterNode(surveyId: string): FilterNode {
  return EQUAL(...fieldAndValues('survey.id', surveyId))
}

export function createRoundSegmentFilterNode(efl: string): FilterNode {
  const node = eflStringToFilterNode(efl)

  if (isFilterBlock(node) && node.items.length === 1) {
    return convertSegmentFilterNode(node.items[0] as FilterNode)
  }

  return convertSegmentFilterNode(node)
}

function convertSegmentFilterNode(node: FilterNode): FilterNode {
  if (isFilterBlock(node)) {
    return new FilterBlock(
      node.items.map(item => convertSegmentFilterNode(item as FilterNode)),
      node.logic,
    )
  }

  if (isFilterExpression(node)) {
    return new FilterExpression(
      convertSegmentFilterKey(node.key),
      node.type,
      node.category,
      node.operator,
      node.value,
      node.not,
    )
  }

  return new FilterBlock()
}

function convertSegmentFilterKey(key: string): RoundFieldKey {
  if (key === 'survey_guid' || key === 'survey.id') {
    return 'survey.id'
  }

  if (key === 'audience' || key === 'audience.country') {
    return 'audience.id'
  }

  if (key === 'country' || key === 'audience.country') {
    return 'audience.country'
  }

  if (key === 'wave_published_time' || key === 'waveTimestamp') {
    return 'waveTimestamp'
  }

  if (key === 'outcome') {
    return 'outcome'
  }

  if (key === 'age' || key === 'subjects.age') {
    return 'subjects.age'
  }

  const { groups: { cardId = undefined, field = undefined } = {} } =
    key.match(/^answers\.(?<cardId>.+)(\.|\.\*\.)(?<field>.+)$/) ?? {}
  if (cardId && field) {
    return `answers.${cardId}.*.${field}` as RoundFieldKey
  }

  if (key.startsWith('subjects.')) {
    return key as RoundFieldKey
  }

  return `subjects.${key}.*`
}

export function createRoundSuccessfulFilterNode(): FilterNode {
  return IN(...fieldAndValues('outcome', ['COMPLETED', 'APPROVED']))
}

export function createRoundCompletedFilterNode(): FilterNode {
  return EQUAL(...fieldAndValues('outcome', 'COMPLETED'))
}

export function createRoundApprovedFilterNode(): FilterNode {
  return EQUAL(...fieldAndValues('outcome', 'APPROVED'))
}

export function createRoundAnswersFilterNode(filters: Record<string, string[]>): FilterNode {
  const entries = Object.entries(filters)
  if (entries.length === 1) {
    return createRoundAnswerIdsFilterNode(entries[0][0], entries[0][1])
  }

  return AND(
    Object.entries(filters).map(([id, answers]) => createRoundAnswerIdsFilterNode(id, answers)),
  )
}

export function createRoundAnswerIdsFilterNode(cardId: string, answersIds: string[]): FilterNode {
  return INCLUDES(...fieldAndValues(`answers.${cardId}.*.id`, answersIds))
}

export function createRoundRankedAnswerFilterNode(
  cardId: string,
  answerIds: string[],
  order: number,
): FilterNode {
  return AND(
    createRoundAnswerIdsFilterNode(cardId, answerIds),
    createRoundAnswerOrderFilterNode(cardId, order),
  )
}

export function createRoundAnswerOrderFilterNode(cardId: string, order: number): FilterNode {
  return EQUAL(...fieldAndValues(`answers.${cardId}.*.order`, order))
}

export function createRoundSubjectsFilterNode(
  name: string,
  filters: Range[] | string[],
): FilterNode {
  if (filters.length === 1) {
    return createRoundSubjectFilterNode(name, filters[0])
  }

  if (filters.every(isRange)) {
    return OR(filters.map(filter => createRoundSubjectFilterNode(name, filter)))
  }

  return INCLUDES(...fieldAndValues(`subjects.${name}.*`, filters))
}

function createRoundSubjectFilterNode(name: string, filter: Range | string): FilterNode {
  if (isRange(filter)) {
    return BETWEEN(...fieldAndValues(`subjects.age`, [filter.min, filter.max]))
  }

  return INCLUDES(...fieldAndValues(`subjects.${name}.*`, filter))
}

export function createRoundCardIdFilterNode(cardId: string): FilterNode {
  return EQUAL(...fieldAndValues(`answers.*~`, cardId))
}

export function createRoundWaveTimestampFilterNode(waveTimestamp: number): FilterNode {
  return EQUAL(...fieldAndValues('waveTimestamp', waveTimestamp))
}

export function createRoundAudienceIdFilterNode(audienceId: string): FilterNode {
  return EQUAL(...fieldAndValues('audience.id', audienceId))
}

export function createRoundAudienceCountryFilterNode(country: string): FilterNode {
  return EQUAL(...fieldAndValues('audience.country', country))
}
