import type { FunctionDirective, ObjectDirective } from 'vue'

const mounted: FunctionDirective<HTMLElement> = el => {
  if ('IntersectionObserver' in window) {
    createObserver(el)
    window.requestIdleCallback(() => loadImageFromContainer(el))
    return
  }

  loadImageFromContainer(el)
}

function createObserver(containerEl: HTMLElement): void {
  const options: IntersectionObserverInit = {
    rootMargin: '10px 0px',
    threshold: 0.01,
  }

  const handleIntersect = handleIntersectOnElement(containerEl)
  const observer = new IntersectionObserver(handleIntersect, options)

  observer.observe(containerEl)
}

function loadImageFromContainer(containerEl: HTMLElement): void {
  const imageEl = getImageElementFromContainer(containerEl)

  if (!imageEl) return

  imageEl.addEventListener('load', () => containerEl.classList.add('loaded'))
  // eslint-disable-next-line no-console
  imageEl.addEventListener('error', e => console.error(e))

  if (imageEl.dataset.url) {
    imageEl.src = imageEl.dataset.url
  }
}

function getImageElementFromContainer(containerEl: HTMLElement): HTMLImageElement | null {
  return containerEl.querySelector('img')
}

function handleIntersectOnElement(el: HTMLElement): IntersectionObserverCallback {
  return (entries, observer) => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadImageFromContainer(el)
        observer.unobserve(el)
      }
    })
  }
}

export const lazyLoad: ObjectDirective = { mounted }
