import type { ApiRecurrence } from '@attest/_api/model/recurrence'

import type { Recurrence } from '../../recurrence'

export function transformApiRecurrenceToRecurrence(
  apiRecurrence: ApiRecurrence | null,
): Recurrence | null {
  if (apiRecurrence === null) return null
  return {
    interval: apiRecurrence.interval,
    recurrenceInterval: transformApiRecurrenceInterval(apiRecurrence.recurrence_interval),
    hasFixedInterval: apiRecurrence.fixed_interval,
  }
}

export function transformApiRecurrenceInterval(
  apiRecurrenceInterval: string,
): Recurrence['recurrenceInterval'] {
  if (apiRecurrenceInterval === 'DAYS') {
    return 'DAYS'
  }
  return 'MONTHS'
}
