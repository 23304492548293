import { collectBy, countBy } from 'ramda'
import type { RouteLocation } from 'vue-router'

import { STUDY_INSIGHTS_ROUTE_NAME } from '@attest/router'
import {
  getWaveStartThyme,
  isStudyOpenTextQuestionCard,
  isStudyVideoQuestionCard,
  type SentWaveSurvey,
  type StudyQuestionCard,
  type StudyQuestionType,
} from '@attest/study'
import { ThymeFormats } from '@attest/thyme'
import { punctuationless } from '@attest/util'

import { isStudyResultsTextAnswer, type StudyResultsCard } from '../models'

export enum STUDY_INSIGHTS_SAVED_SETTINGS_VIEW_KEYS {
  TRENDS = 'trends_and_analysis',
  ANALYSIS = 'analysis',
}

type ChartType =
  | 'line'
  | 'column'
  | 'stacked-column'
  | 'nps'
  | 'text-column'
  | 'ranked-line'
  | 'ranked-column'
  | 'pie'
  | 'stacked'

export function getDefaultChartType({
  cardType,
  wavesCount,
  view,
}: {
  cardType: StudyQuestionType
  wavesCount: number
  view: STUDY_INSIGHTS_SAVED_SETTINGS_VIEW_KEYS
}): ChartType {
  switch (cardType) {
    case 'grid':
      return 'stacked-column'
    case 'nps':
      return view === STUDY_INSIGHTS_SAVED_SETTINGS_VIEW_KEYS.TRENDS ? 'line' : 'stacked-column'
    case 'open-text':
    case 'video':
      return 'text-column'
    case 'ranked':
      return 'line'
    default: {
      if (wavesCount >= 5) return 'line'
      return 'column'
    }
  }
}

export function getFormattedDate(timestamp: number, timeZoneId: string): string {
  return getWaveStartThyme({ startTimestamp: timestamp, timeZoneId }).toLocaleString(
    ThymeFormats.DATE_LONG,
  )
}

export function getDefaultSeriesForStudyQuestion(
  card: StudyQuestionCard,
  results: StudyResultsCard,
  limit = 5,
  minWordLength = 5,
): string[] {
  if (!isStudyOpenTextQuestionCard(card) && !isStudyVideoQuestionCard(card)) {
    return []
  }

  const getUniqueWords = (text: string): string[] => [
    ...new Set(
      punctuationless(text)
        .toLocaleLowerCase()
        .trim()
        .split(/\s+/)
        .filter(word => word.length >= minWordLength),
    ),
  ]

  const responses = Object.values(results.answerIdToAnswer).flatMap(answer =>
    isStudyResultsTextAnswer(answer) ? Object.values(answer.responseIdToResponse) : [],
  )

  const texts = collectBy(({ id }) => id, responses).map(responsesFromSameId =>
    responsesFromSameId.map(({ text }) => text).join(''),
  )

  return Object.entries(
    countBy(
      word => word,
      texts.flatMap(text => getUniqueWords(text)),
    ),
  )
    .sort(([, count1], [, count2]) => count2 - count1)
    .slice(0, limit)
    .map(([word]) => word)
}

export function isCardIncludingStructureWithinWaves(
  card: StudyQuestionCard,
  waves: SentWaveSurvey[],
): boolean {
  return waves.some(wave => card.question.structures.map(({ waveId }) => waveId).includes(wave.id))
}

export function isOverview($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW
}

export function isCrosstabs($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.CROSSTABS
}

export function isTrends($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.TRENDS
}

export function isAnalysisByGender($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS && $route.params.groupBy === 'by-gender'
}

export function isAnalysisByAge($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS && $route.params.groupBy === 'by-age'
}

export function isAnalysisByCountry($route: RouteLocation): boolean {
  return (
    $route.name === STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS && $route.params.groupBy === 'by-country'
  )
}
