<script lang="tsx">
import { defineComponent, type PropType } from 'vue'

import {
  assertIsElement,
  getFirstScrollableYParent,
  isElementHorizontallyContainedIn,
  isElementVerticallyContainedIn,
} from '@attest/dom'
import { createEmitter } from '@attest/vue-tsx'

import PausableTimeout from '../../abstract/PausableTimeout.vue'

import type {
  EventHandlerTypes,
  PopoverEvent,
  PopoverHorizontalAlign,
  PopoverType,
  PopoverVerticalAlign,
  TriggerEvent,
  TriggerEventSetup,
} from './'

const DEFAULT_TRIGGER_EVENTS: TriggerEvent[] = ['hover', 'focus']
const DEFAULT_POPOVER_TYPE: PopoverType = 'info'
const DEFAULT_HORIZONTAL_ALIGN: PopoverHorizontalAlign = 'right'
const DEFAULT_VERTICAL_ALIGN: PopoverVerticalAlign = 'bottom'

const PERSIST_THROTTLE = 200

const emit = createEmitter<PopoverEvent>()

const EVENTS: Record<TriggerEvent, TriggerEventSetup> = {
  click: {
    add(targetElement, allEventHandlers) {
      targetElement?.addEventListener('click', allEventHandlers.handleElementClick)
    },
    remove: (targetElement, allEventHandlers) => {
      targetElement?.removeEventListener('click', allEventHandlers.handleElementClick)
    },
  },
  hover: {
    add(targetElement, allEventHandlers) {
      targetElement?.addEventListener('mouseenter', allEventHandlers.handleActivateEvent)
      targetElement?.addEventListener('mouseleave', allEventHandlers.handleDeactivateEvent)
    },
    remove(targetElement, allEventHandlers) {
      targetElement?.removeEventListener('mouseenter', allEventHandlers.handleActivateEvent)
      targetElement?.removeEventListener('mouseleave', allEventHandlers.handleDeactivateEvent)
    },
  },
  focus: {
    add(targetElement, allEventHandlers) {
      targetElement?.addEventListener('focus', allEventHandlers.handleActivateEvent)
      targetElement?.addEventListener('blur', allEventHandlers.handleDeactivateEvent)
    },
    remove(targetElement, allEventHandlers) {
      targetElement?.removeEventListener('focus', allEventHandlers.handleActivateEvent)
      targetElement?.removeEventListener('blur', allEventHandlers.handleDeactivateEvent)
    },
  },
}

export default defineComponent({
  props: {
    target: { type: String, required: true },
    triggerEvents: {
      type: Array as PropType<TriggerEvent[]>,
      default: () => DEFAULT_TRIGGER_EVENTS,
    },
    container: { type: String as PropType<string | null>, default: null },
    type: { type: String as PropType<PopoverType>, default: DEFAULT_POPOVER_TYPE },
    forceAlign: { type: Boolean, default: false },
    defaultHorizontalAlign: {
      type: String as PropType<PopoverHorizontalAlign>,
      default: DEFAULT_HORIZONTAL_ALIGN,
    },
    defaultVerticalAlign: {
      type: String as PropType<PopoverVerticalAlign>,
      default: DEFAULT_VERTICAL_ALIGN,
    },
    persist: { type: Boolean, default: true },
    forceShow: { type: Boolean, default: false },
    isShown: { type: Boolean, default: true },
    hideAfterMs: { type: Number, default: null as number | null },
  },

  data() {
    return {
      isVisible: false,
      isHovering: false,
      isMobile: false,
      hasActivated: this.forceShow,
      horizontalAlign: this.defaultHorizontalAlign,
      verticalAlign: this.defaultVerticalAlign,
      resizeTimer: null as any,
      currentTargetElement: null as HTMLElement | null,
      retryWithIntervalTimeout: null as null | NodeJS.Timeout,
    }
  },
  computed: {
    popoverIsVisible(): boolean {
      return this.forceShow || (this.isShown && this.isVisible)
    },

    eventHandlers(): Record<EventHandlerTypes, EventListener> {
      return {
        handleElementClick: this.handleElementClick,
        handleDocumentClick: this.handleDocumentClick,
        handleActivateEvent: this.handleActivateEvent,
        handleDeactivateEvent: this.handleDeactivateEvent,
      }
    },
  },
  watch: {
    forceShow(): void {
      if (this.forceShow) {
        this.activateEvents({} as Event)
      } else {
        this.clickDeactivateEvents()
      }
    },
  },
  async mounted() {
    const retryWithIntervalUntil = async (
      predicate: () => boolean,
      interval: number,
      { maxNumberOfTries = 1 } = {},
    ): Promise<boolean> => {
      if (maxNumberOfTries <= 0) return false

      if (predicate()) return true

      await new Promise(res => {
        this.retryWithIntervalTimeout = setTimeout(res, interval)
      })
      return retryWithIntervalUntil(predicate, interval, { maxNumberOfTries: maxNumberOfTries - 1 })
    }

    const INTERVAL = 200

    const isTargetFound = await retryWithIntervalUntil(
      () => document.querySelectorAll(this.target).length > 0,
      INTERVAL,
      { maxNumberOfTries: 10 },
    )

    if (!isTargetFound) {
      return
    }

    const targetElements = document.querySelectorAll(this.target)

    targetElements.forEach(targetElement => this.addActivationEvents(targetElement))

    this.currentTargetElement = targetElements[0] as HTMLElement

    if (this.persist) {
      this.addActivationEvents(this.$refs.popover as Element)
    }
  },

  beforeUnmount() {
    clearTimeout(this.resizeTimer)
    if (this.retryWithIntervalTimeout !== null) {
      clearTimeout(this.retryWithIntervalTimeout)
    }

    document
      .querySelectorAll(this.target)
      .forEach(targetElement => this.removeActivationEvents(targetElement))

    this.removeActivationEvents(this.$refs.popover as HTMLElement)
  },

  methods: {
    toggleHover() {
      this.isHovering = !this.isHovering
    },

    async activateEvents(e: Event) {
      this.hasActivated = true

      await this.$nextTick()
      this.setupPosition()

      this.isHovering = true
      this.isVisible = true
      emit(this, 'activate', e)

      document.addEventListener('click', this.handleDocumentClick)
    },

    handleActivateEvent(e: Event): void {
      this.updateTargetElementFromEvent(e)
      this.activateEvents(e)
    },

    handleElementClick(e: Event): void {
      e.preventDefault()

      this.hasActivated = true
      this.updateTargetElementFromEvent(e)

      if (this.popoverIsVisible && this.hideAfterMs === null) {
        this.clickDeactivateEvents(e)
      } else if (!this.popoverIsVisible) {
        this.activateEvents(e)
      }
    },

    updateTargetElementFromEvent(e: Event): void {
      if (!e.target) return

      assertIsElement(e.target)
      const closestTarget = e.target.closest<HTMLElement>(this.target)

      if (closestTarget) {
        this.currentTargetElement = closestTarget
      }
    },

    handleDocumentClick(event: Event): void {
      if (!this.isVisible) return

      assertIsElement(event.target)
      const closestTarget = event.target.closest(this.target) ?? event.target
      const isClickingTriggerElement = closestTarget === this.currentTargetElement
      const isClickingPopoverContent =
        this.isShown &&
        event.target instanceof Node &&
        (this.$refs.popover as HTMLDivElement)?.contains(event.target)

      if (isClickingPopoverContent) return
      if (isClickingTriggerElement) return

      this.clickDeactivateEvents(event)
    },

    clickDeactivateEvents(event?: Event): void {
      this.isHovering = false
      this.isVisible = false

      emit(this, 'deactivate', event)
      document.removeEventListener('click', this.handleDocumentClick)
    },

    handleDeactivateEvent(event: Event): void {
      this.isHovering = false

      if (this.persist) {
        setTimeout(() => {
          if (!this.isHovering) this.isVisible = false
        }, PERSIST_THROTTLE)

        return
      }

      this.isVisible = false
      emit(this, 'deactivate', event)
      document.removeEventListener('click', this.handleDocumentClick)
    },

    resize(): void {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => this.setupPosition(), 20)
    },

    async setupPosition(): Promise<void> {
      const popover = this.$refs.popover as HTMLElement

      if (!this.currentTargetElement || !popover || this.currentTargetElement === popover) return

      popover.style.left = `${this.calculatePopoverOffsetLeftPx(
        this.currentTargetElement,
        popover,
      )}px`
      popover.style.top = `${this.calculatePopoverOffsetTopPx(
        this.currentTargetElement,
        popover,
      )}px`

      const container = this.container
        ? document.querySelector(this.container)
        : getFirstScrollableYParent(this.currentTargetElement)

      if (!container) throw new Error(`cannot find container ${this.container}`)
      if (!isElementHorizontallyContainedIn(popover, container)) {
        await this.$nextTick()
        if (!this.forceAlign) {
          this.horizontalAlign = this.horizontalAlign === 'left' ? 'right' : 'left'
        }

        popover.style.left = `${this.calculatePopoverOffsetLeftPx(
          this.currentTargetElement,
          popover,
        )}px`
      }

      if (this.verticalAlign !== 'center' && !isElementVerticallyContainedIn(popover, container)) {
        await this.$nextTick()
        if (!this.forceAlign) {
          this.verticalAlign = this.verticalAlign === 'top' ? 'bottom' : 'top'
        }
        popover.style.top = `${this.calculatePopoverOffsetTopPx(
          this.currentTargetElement,
          popover,
        )}px`
      }
    },

    calculatePopoverOffsetLeftPx(trigger: HTMLElement, popover: HTMLElement): number {
      const popoverClient = popover.getBoundingClientRect()
      const isVerticallyCentered = this.verticalAlign === 'center'
      const { offsetLeft, offsetWidth } = trigger
      const popoverWidth = popoverClient.width

      switch (this.horizontalAlign) {
        case 'left': {
          return isVerticallyCentered ? offsetLeft - popoverWidth : offsetLeft
        }
        case 'right': {
          return isVerticallyCentered
            ? offsetLeft + offsetWidth
            : offsetLeft - popoverWidth + offsetWidth
        }
        case 'center':
        default: {
          return offsetLeft - popoverWidth / 2 + offsetWidth / 2
        }
      }
    },

    calculatePopoverOffsetTopPx(trigger: HTMLElement, popover: HTMLElement): number {
      const popoverClient = popover.getBoundingClientRect()
      const triggerClient = trigger.getBoundingClientRect()

      switch (this.verticalAlign) {
        case 'top':
          return trigger.offsetTop + triggerClient.height
        case 'center':
          return trigger.offsetTop + triggerClient.height / 2 - popoverClient.height / 2
        default:
          return trigger.offsetTop - popoverClient.height
      }
    },

    addActivationEvents(targetElement?: Element): void {
      if (this.forceShow) return
      window.addEventListener('resize', this.resize)

      this.triggerEvents.forEach(trigger => EVENTS[trigger].add(targetElement, this.eventHandlers))
    },

    removeActivationEvents(targetElement?: Element): void {
      if (this.forceShow) return
      window.removeEventListener('resize', this.resize)

      this.triggerEvents.forEach(trigger =>
        EVENTS[trigger].remove(targetElement, this.eventHandlers),
      )
    },

    getContent() {
      const content = this.$slots.default?.().filter(node => node !== undefined) ?? []
      if (content.length > 0) return content
      if (this.currentTargetElement) {
        return (
          this.currentTargetElement.dataset.popoverText ||
          this.currentTargetElement.getAttribute('data-popover-text') ||
          content ||
          ''
        )
      }

      return ''
    },
  },

  render() {
    const baseClass = 'c-popover'
    const popoverClass = {
      [baseClass]: true,
      [`${baseClass}--cover`]: this.isMobile,
      [`${baseClass}--activated`]: this.hasActivated,
      [`${baseClass}--type-${this.type}`]: !!this.type,
      [`${baseClass}--${this.verticalAlign}-${this.horizontalAlign}`]: true,
      'u-attest-body--small': true,
    }

    return (
      <aside
        ref="popover"
        class={popoverClass}
        hidden={!this.popoverIsVisible || !this.hasActivated}
      >
        {this.popoverIsVisible && this.hideAfterMs !== null && (
          <PausableTimeout
            onTimeout={() => this.clickDeactivateEvents()}
            duration={this.hideAfterMs}
          />
        )}
        {this.getContent()}
      </aside>
    )
  },
})
</script>

<style lang="postcss">
.c-popover {
  position: absolute;
  z-index: var(--z-index-popover);
  display: inline-table;
  box-sizing: border-box;
  padding: var(--attest-spacing-4);
  border-color: transparent;
  border-color: var(--attest-color-background-inverse);
  border-radius: var(--attest-border-radius);
  backface-visibility: hidden;
  background-color: var(--attest-color-background-inverse);
  color: var(--attest-color-text-on-interactive);
  transition: opacity var(--attest-timing-fast) var(--attest-ease);

  &--type-white {
    border-color: var(--attest-color-surface-default);
    background-color: var(--attest-color-surface-default);
    box-shadow: var(--attest-shadow-popover);
    color: var(--attest-color-text-default);
  }

  &--top-right,
  &--top-left,
  &--top-center {
    transform: translateY(var(--attest-spacing-2));
  }

  &::after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-color: inherit;
    border-top: var(--attest-border-width-s) solid transparent;
    border-top-color: inherit;
    border-left: var(--attest-border-width-s) solid transparent;
    border-left-color: inherit;
    background: inherit;
    content: '';
  }

  &--top-left {
    &::after {
      right: auto;
      bottom: calc(100% - 2px);
      left: 18px;
      transform: rotate(45deg);
      transform-origin: bottom;
    }
  }

  &--top-right {
    &::after {
      right: 18px;
      bottom: calc(100% - 2px);
      left: auto;
      transform: rotate(45deg);
      transform-origin: bottom;
    }
  }

  &--top-center {
    &::after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      transform-origin: bottom left;
    }
  }

  &--center-left {
    margin-left: calc(-1 * var(--attest-spacing-2));

    &::after {
      top: auto;
      right: -1px;
      bottom: 50%;
      left: auto;
      transform: translateY(-50%) rotate(135deg);
      transform-origin: bottom;
    }
  }

  &--center-right {
    margin-left: var(--attest-spacing-2);

    &::after {
      top: 50%;
      right: auto;
      bottom: auto;
      left: -1px;
      transform: translateY(-50%) rotate(-45deg);
      transform-origin: bottom;
    }
  }

  &--bottom-left,
  &--bottom-right,
  &--bottom-center {
    margin: 0 0 var(--attest-spacing-half);
    transform: translateY(calc(-1 * var(--attest-spacing-2)));
  }

  &--bottom-left {
    &::after {
      top: auto;
      right: auto;
      bottom: 0;
      left: 18px;
      transform: translateX(-50%) rotate(45deg);
      transform-origin: bottom left;
    }
  }

  &--bottom-right {
    margin-left: calc(1.5 * var(--attest-spacing-2));

    &::after {
      top: auto;
      right: 18px;
      bottom: 2px;
      left: auto;
      transform: rotate(-135deg);
      transform-origin: bottom;
    }
  }

  &--bottom-center {
    border-color: transparent;

    &::after {
      top: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      transform-origin: bottom left;
    }
  }

  &--cover {
    position: fixed;
    right: 10%;
    left: 10%;
    width: 80%;
    max-width: none;
    box-sizing: border-box;
    margin: 0;

    &::after {
      display: none;
    }
  }

  &[hidden] {
    opacity: 0;
    transition:
      opacity var(--attest-timing-fast) var(--attest-ease),
      visibility 0s var(--attest-ease) var(--attest-timing-fast);
    visibility: hidden;
  }

  &:not(&--activated) {
    display: none;
  }
}
</style>
