import type { RouteRecordRaw } from 'vue-router'

export enum BOOK_A_DEMO_ROUTE_NAME {
  BOOK_A_DEMO = 'BOOK_A_DEMO',
}

const BOOK_A_DEMO_ROUTE_PATH = {
  [BOOK_A_DEMO_ROUTE_NAME.BOOK_A_DEMO]: '/book-a-demo',
}

export function createBookADemoRoute(): RouteRecordRaw {
  return {
    name: BOOK_A_DEMO_ROUTE_NAME.BOOK_A_DEMO,
    path: BOOK_A_DEMO_ROUTE_PATH[BOOK_A_DEMO_ROUTE_NAME.BOOK_A_DEMO],
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
    },
    component: async () => (await import('./page/BookADemo.vue')).default,
  }
}
