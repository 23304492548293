import { omit } from 'ramda'
import type { Merge } from 'ts-essentials'

import { mergeDeepAllRight } from '@attest/util'

import { sortMessageStructuresByAscendingPublishedTimestamp } from '../..'

import type { MergedStudyMessageStructure, StudyMessageStructure } from './model'

export function createStudyMessageStructure(
  override: Merge<
    Partial<StudyMessageStructure>,
    {
      waveId: string
    }
  >,
): StudyMessageStructure {
  return Object.freeze({
    waveId: override.waveId,
    nextGuid: override.nextGuid ?? null,
    text: override.text ?? '',
    publishedTimestamp: override.publishedTimestamp ?? undefined,
    imageUrl: override.imageUrl ?? undefined,
  })
}

export function createMergedMessageStructure(
  structures: StudyMessageStructure[],
): MergedStudyMessageStructure {
  const sortedStructures = sortMessageStructuresByAscendingPublishedTimestamp(structures)
  return omit(['waveId'], mergeDeepAllRight(sortedStructures))
}
