import { CARD_EDITOR_VIEW } from './editor-survey-drafter'

export const STUDY_UPDATER_ROUTE_NAME = {
  EDITOR: 'STUDY::UPDATER::EDITOR',
  EDITOR_CARDS_LIST_VIEW: 'STUDY::UPDATER::EDITOR_CARDS::LIST_VIEW',
  EDITOR_CARDS_MAP_VIEW: 'STUDY::UPDATER::EDITOR_CARDS::EDITOR_VIEW',
  REVIEW: 'STUDY::UPDATER::REVIEW',
  REVIEW_CHECKOUT: 'STUDY::UPDATER::REVIEW::CHECKOUT',
  AUDIENCE: 'STUDY::UPDATER::AUDIENCE',
}

export const StudyUpdaterCardEditorViewToRouteName = {
  [CARD_EDITOR_VIEW.LIST]: STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_LIST_VIEW,
  [CARD_EDITOR_VIEW.MAP]: STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_MAP_VIEW,
}
