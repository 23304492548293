import { createEntity, type Entity } from './base'

export type SurveyPricingEntity<D extends SurveyPricingEntityData = SurveyPricingEntityData> =
  {} & Entity<D>

type SurveyPricingEntityData = {
  cost: number
  surveyLength: number
}

export function createSurveyPricingEntity({
  cost,
  surveyLength,
}: SurveyPricingEntityData): SurveyPricingEntity {
  return createEntity({ name: 'survey-pricing', version: '1-0-0' }, { cost, surveyLength })
}
