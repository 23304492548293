import axios, { type AxiosError } from 'axios'

import { type ExpectedHandler, expectedHandler } from '@attest/util'

import type { ApiSurveyRoundIdData } from './model'

export async function getSurveyStudyRoundIdsByWave(args: {
  id: string
  waveSurveyIds?: string[]
  eflQuery: string
}): ExpectedHandler<ApiSurveyRoundIdData[], AxiosError> {
  const params = new URLSearchParams()
  params.append('query', args.eflQuery)
  params.append('fields', 'round_guid')

  if (args.waveSurveyIds) {
    params.append('waves', args.waveSurveyIds.join(','))
  }
  return expectedHandler(async () => {
    const { data } = await axios.get<ApiSurveyRoundIdData[]>(`/api/v1/study/${args.id}/rounds`, {
      params,
    })

    return data
  })
}
