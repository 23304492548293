import { defineStore } from 'pinia'
import { equals, mergeDeepLeft, mergeDeepRight } from 'ramda'
import type { DeepPartial } from 'ts-essentials'

import type { InternalError, ServiceError } from '@attest/_api'
import { useStudyStore } from '@attest/study'
import { capture } from '@attest/telemetry'
import { useUserStore } from '@attest/user'

import * as api from './api'
import { getDefaultCardSettings } from './factory/settings.factory'
import type {
  SavedCardIdToSavedCardSettings,
  SavedCardSettings,
  SavedStudySettings,
} from './model/saved-settings'

type SavedSettingsState = {
  studyGuid: string | null
  settings: SavedCardIdToSavedCardSettings
  studySettings: SavedStudySettings
  settingsVersion: number
}

export const SAVED_SETTINGS_STORE_NAMESPACE = 'savedSettings'

export function createSettingsStoreState(
  override: Partial<SavedSettingsState> = {},
): SavedSettingsState {
  return {
    studyGuid: null,
    settings: {},
    studySettings: {
      overview: {
        order: 'draft',
        show: {
          percentages: true,
          values: true,
          forward_percentage: false,
        },
      },
      trends: {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      },
      analysis: {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      },
      crosstabs: {
        order: 'draft',
        show: {
          percentages: true,
          values: true,
          forward_percentage: false,
        },
      },
    },
    settingsVersion: 0,
    ...override,
  }
}

export const useSavedSettingsStore = defineStore(SAVED_SETTINGS_STORE_NAMESPACE, {
  state: createSettingsStoreState,

  getters: {
    getSettingsByCardId(): (cardId: string) => SavedCardSettings {
      return (cardId: string) => this.settings[cardId]
    },

    hasCardWithCustomOrder(): boolean {
      return false
    },
  },

  actions: {
    async get(studyGuid: string): Promise<InternalError<ServiceError<never>> | void> {
      if (!useUserStore().isAuthenticated) {
        this.setInitialCardSettings()
        this.studyGuid = studyGuid
        return
      }

      if (this.studyGuid === studyGuid) return
      try {
        const savedSettings = await api.getSavedSettings(studyGuid)
        const cardIds = Object.keys(useStudyStore().cardIdToCard)
        const settings: SavedCardIdToSavedCardSettings = {}

        if (!savedSettings.study) {
          this.setInitialStudySettings()
        }

        if (!savedSettings.study?.trends) {
          this.setInitialStudyTrendsSettings()
        } else {
          this.studySettings.trends = savedSettings.study.trends
        }
        if (!savedSettings.study?.analysis) {
          this.setInitialStudyAnalysisSettings()
        } else {
          this.studySettings.analysis = savedSettings.study.analysis
        }
        if (!savedSettings.study?.crosstabs) {
          this.setInitialStudyCrosstabsSettings()
        } else {
          this.studySettings.crosstabs = savedSettings.study.crosstabs
        }
        if (!savedSettings.study?.overview) {
          this.setInitialStudyOverviewSettings()
        } else {
          this.studySettings.overview = savedSettings.study.overview
        }

        if (!savedSettings.cards) {
          this.setInitialCardSettings()
          return
        }

        cardIds.forEach(cardId => {
          const storedSettings = savedSettings.cards[cardId] ?? {}
          if (storedSettings.overview?.selected_visualisation) {
            storedSettings.overview.selected_visualisation =
              storedSettings.overview.selected_visualisation.toLowerCase() as SavedCardSettings['overview']['selected_visualisation']
          }
          const defaultSettings = getDefaultCardSettings(useStudyStore().cardIdToCard[cardId])
          if (!defaultSettings) return
          settings[cardId] = storedSettings
            ? mergeDeepLeft(storedSettings, defaultSettings)
            : defaultSettings
        })
        this.settings = settings
        this.settingsVersion = savedSettings.settingsVersion
      } catch (error) {
        this.setInitialCardSettings()
        capture(error)
      } finally {
        this.studyGuid = studyGuid
      }
    },

    async update(cardId: string, setting: DeepPartial<SavedCardSettings>) {
      const cardSettings = this.settings[cardId]

      if (!cardSettings) return

      const updated = mergeDeepRight(cardSettings, setting) as SavedCardSettings

      if (equals(cardSettings, updated)) return

      this.settings[cardId] = updated

      if (!useUserStore().isAuthenticated || !this.studyGuid) return

      try {
        const newSettingsVersion = this.settingsVersion + 1
        api.updateSavedSettings(
          this.studyGuid,
          this.settings,
          newSettingsVersion,
          this.studySettings,
        )
        this.settingsVersion = newSettingsVersion
      } catch (e) {
        throw new Error(e)
      }
    },

    async updateStudySettings() {
      if (!useUserStore().isAuthenticated || !this.studyGuid) return

      try {
        const newSettingsVersion = this.settingsVersion + 1
        api.updateSavedSettings(
          this.studyGuid,
          this.settings,
          newSettingsVersion,
          this.studySettings,
        )
        this.settingsVersion = newSettingsVersion
      } catch (e) {
        throw new Error(e)
      }
    },

    setInitialCardSettings() {
      const studyStore = useStudyStore()
      const cardIds = Object.keys(studyStore.cardIdToCard)
      const settings: SavedCardIdToSavedCardSettings = {}
      cardIds.forEach(cardId => {
        const defaultSettings = getDefaultCardSettings(studyStore.cardIdToCard[cardId])
        if (!defaultSettings) return
        settings[cardId] = defaultSettings
      })
      this.settings = settings
    },

    setInitialStudySettings() {
      this.studySettings = {
        trends: {
          order: 'draft',
          show: {
            percentages: true,
            forward_percentage: false,
          },
        },
        analysis: {
          order: 'draft',
          show: { percentages: true, forward_percentage: false },
        },
        overview: {
          order: 'draft',
          show: {
            values: true,
            percentages: true,
            forward_percentage: false,
          },
        },
        crosstabs: {
          order: 'draft',
          show: { values: true, percentages: true, forward_percentage: false },
        },
      }
    },

    setInitialStudyTrendsSettings() {
      this.studySettings.trends = {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      }
    },

    setInitialStudyAnalysisSettings() {
      this.studySettings.analysis = {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      }
    },

    setInitialStudyOverviewSettings() {
      this.studySettings.overview = {
        order: 'draft',
        show: {
          values: true,
          percentages: true,
          forward_percentage: false,
        },
      }
    },

    setInitialStudyCrosstabsSettings() {
      this.studySettings.crosstabs = {
        order: 'draft',
        show: {
          percentages: true,
          values: true,
          forward_percentage: false,
        },
      }
    },
  },
})
