import { STATIC_ANSWER_ID } from '@attest/study'

export type StudyResultsAnswerType = 'text' | 'ordered' | 'choice'
export type Sentiment = 'positive' | 'neutral' | 'negative'

type StudyResponseToType<R = StudyResultsAnswerResponse> = R extends StudyResultsAnswerTextResponse
  ? 'text'
  : R extends StudyResultsAnswerOrderedResponse
    ? 'ordered'
    : 'choice'

export type StudyResultsAnswer<R extends StudyResultsAnswerResponse = StudyResultsAnswerResponse> =
  {
    id: string
    type: StudyResponseToType<R>
    responseIds: Set<string>
    responseIdToResponse: Record<string, Readonly<R>>
  }

export type StudyResultsAnswerResponseId = string
export type StudyResultsAnswerResponse =
  | StudyResultsAnswerChoiceResponse
  | StudyResultsAnswerTextResponse
  | StudyResultsAnswerOrderedResponse
  | StudyResultsAnswerVideoResponse

export type StudyResultsAnswerChoiceResponse = undefined

export type StudyResultsAnswerTextResponse = {
  readonly id: string
  readonly text: string
  readonly sentiment?: Sentiment
}

export type StudyResultsAnswerOrderedResponse = {
  readonly id: string
  readonly order: number
}

export type StudyResultsAnswerVideoResponse = {
  readonly id: string
  readonly transcript?: string
  readonly videoId: string
}

export function isStudyResultsOrderedAnswer(
  answer?: StudyResultsAnswer,
): answer is StudyResultsAnswer<StudyResultsAnswerOrderedResponse> {
  return !!answer && answer.type === 'ordered'
}

export function isStudyResultsTextAnswer(
  answer?: StudyResultsAnswer,
): answer is StudyResultsAnswer<StudyResultsAnswerTextResponse> {
  return !!answer && answer.type === 'text'
}

export function isStudyResultsChoiceAnswer(
  answer?: StudyResultsAnswer,
): answer is StudyResultsAnswer<StudyResultsAnswerChoiceResponse> {
  return !!answer && answer.type === 'choice'
}

export function isStudyResultsStaticAnswer(
  answer?: StudyResultsAnswer,
): answer is StudyResultsAnswer<StudyResultsAnswerChoiceResponse | StudyResultsAnswerTextResponse> {
  return (
    !!answer &&
    (answer.id === STATIC_ANSWER_ID.OTHER ||
      answer.id === STATIC_ANSWER_ID.NA ||
      answer.id === STATIC_ANSWER_ID.NONE ||
      answer.id === STATIC_ANSWER_ID.SKIPPED)
  )
}

export function isStudyResultsSkippedAnswer(
  answer?: StudyResultsAnswer,
): answer is StudyResultsAnswer<StudyResultsAnswerChoiceResponse> {
  return !!answer && answer.id === STATIC_ANSWER_ID.SKIPPED
}
