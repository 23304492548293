import { createEntity, type Entity } from '../base'

export type FilterGroupEntity<D extends FilterGroupEntityData = FilterGroupEntityData> =
  {} & Entity<D>

export type FilterGroupEntityData = {
  id: string
  isEnabled: boolean
  filterIds: string[]
}

export function createFilterGroupEntity({
  id,
  isEnabled,
  filterIds,
}: FilterGroupEntityData): FilterGroupEntity {
  return createEntity({ name: 'filter-group', version: '1-0-0' }, { id, isEnabled, filterIds })
}
