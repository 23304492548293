import { createEventBus } from './event-bus'

/**
 * Event map
 */
export enum EventName {
  MODAL_TOGGLE = 'modal::toggle',
  PROGRESS_START = 'progress::start',
  PROGRESS_INC = 'progress::inc',
  PROGRESS_FINISH = 'progress::finish',
  BROWSER_AUDIO_TRIGGERED = 'browser::audio::triggered',
  TOPBAR_TOGGLE = 'topbar::toggle',
  DRAWER_TOGGLE = 'drawer::toggle',
  WAVE_SELECTOR_TOGGLE = 'wave-selector::toggle',
}

export type EventsPayload = {
  [EventName.MODAL_TOGGLE]: { value: boolean }
  [EventName.PROGRESS_START]: {}
  [EventName.PROGRESS_INC]: {}
  [EventName.PROGRESS_FINISH]: {}
  [EventName.TOPBAR_TOGGLE]: {}
  [EventName.BROWSER_AUDIO_TRIGGERED]: { playerUniqueId: string }
  [EventName.DRAWER_TOGGLE]: { visible: boolean }
  [EventName.WAVE_SELECTOR_TOGGLE]: {}
}

/**
 * Internals
 */

export type EventHandler<T extends EventName> = (payload: EventsPayload[T]) => void

/* @deprecated use `native/event/event-bus` instead */
export const bus = createEventBus()

/* @deprecated use `native/event/event-bus` instead */
export function emit<T extends EventName>(name: T, payload: EventsPayload[T]) {
  bus.emit(name, payload)
}

/* @deprecated use `native/event/event-bus` instead */
export function on<T extends EventName>(name: T, handler: EventHandler<T>) {
  bus.on(name, handler)
}

/* @deprecated use `native/event/event-bus` instead */
export function off<T extends EventName>(name: T, handler: EventHandler<T>) {
  bus.off(name, handler)
}
