<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import chevronTopSVG from '@attest/_assets/icons/keyboard_arrow_up.svg'
import { create } from '@attest/vue-tsx'

import { SvgIcon, type SvgIconSize } from './SvgIcon'

type ArrowProps = {
  color?: ArrowColor
  direction?: ArrowDirection
  size?: SvgIconSize
}

export type ArrowColor = 'primary' | 'white'
export type ArrowDirection = 'up' | 'down' | 'right'

const Arrow: FunctionalComponent<ArrowProps> = props => {
  const { color = 'primary', direction = 'down', size = 's', ...attrs } = props

  const baseClass = 'c-arrow'
  const arrowClass = {
    [`${baseClass}`]: true,
    [`${baseClass}--direction-${direction}`]: true,
  }

  return <SvgIcon svg={chevronTopSVG} size={size} class={arrowClass} {...attrs} />
}
export default create<ArrowProps>(Arrow)
</script>
<style lang="postcss">
.c-arrow {
  transform: rotate(0);
  transition: transform ease-in-out var(--attest-timing-default);

  &--direction-up {
    transform: rotate(180deg);
  }

  &--direction-right {
    transform: rotate(90deg);
  }
}
</style>
