import { groupBy } from '@attest/util'

import type { CategorisedDemographics, Demographic, Demographics } from '../model'

export function getSupportedDemographicsMap(
  countryLanguageKey: string | null,
  demographics: Demographic[],
): Demographics {
  const demographicsMap: Demographics = {}
  const supportedDemographics = demographics
    .filter(demographic => demographic.targetable)
    .filter(
      demographic =>
        countryLanguageKey && demographic.supportedCountryLanguages.includes(countryLanguageKey),
    )
  supportedDemographics.forEach(demographic => {
    demographicsMap[demographic.name] = demographic
  })

  return demographicsMap
}

export function getCategorisedDemographics(demographics: Demographic[]): CategorisedDemographics {
  return groupBy(
    demographics.map(item => ({ ...item })).toSorted((a, b) => a.order - b.order),
    ({ category }) => category,
  )
}
