import { initialize, type LDClient, type LDUser } from 'launchdarkly-js-client-sdk'

import { loadDefaults } from './defaults'

let client = undefined as LDClient | undefined

export function initializeClient(key: string, user: LDUser, hash: string): void {
  client = initialize(key, user, { hash })
  loadDefaults()
}

export async function identify(user: LDUser, hash: string): Promise<void> {
  await withClient().identify(user, hash)
}

export function withClient(): LDClient {
  assertClient(client)
  return client
}

function assertClient(ldClient?: LDClient): asserts ldClient is LDClient {
  if (!ldClient) throw new ReferenceError('Feature Flag client not setup')
}
