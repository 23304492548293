import type { AnswerMask } from './answer-mask'

export type AnswerType = 'text' | 'image' | 'cell' | StaticAnswerType
export type StaticAnswerType = 'na' | 'none' | 'skipped' | 'other'

export type Answer<T extends AnswerType = AnswerType> = {
  type: T
  text: string
  id: string
  isPinned: boolean
  isQualifying: boolean
  imageUrl: string | null
  filename: string | null
  next: number | string | null
  isDisabled?: boolean
  mask?: AnswerMask
  quotaId?: string | null
}

export type StaticAnswer<T extends StaticAnswerType = StaticAnswerType> = {
  id: T
  type: T
} & Answer<T>

export type AnswerGridData = {
  subjects: AnswerGridDataSubjects
  options: AnswerGridDataOptions
  choices: AnswerGridDataChoices
}

export type AnswerGridDataSubjects = {
  headers: AnswerGridHeader[]
  isRandomized: boolean
}

export type AnswerGridDataOptions = {
  headers: AnswerGridHeader[]
  isSingleChoice: boolean
  isRandomized: boolean
  hasNa: boolean
  hasNone: boolean
  selectionLimit: number | null
}

export type AnswerGridDataChoices = Answer[][]

export type AnswerGridHeaderType = Extract<'text' | 'image', AnswerType>

export type AnswerGridHeader = {
  id: string
  type: AnswerGridHeaderType
  text: string
  isPinned: boolean
  imageUrl: string | null
  filename: string | null
  mask?: AnswerMask
}

export const STATIC_ANSWER_MAP: Record<string, AnswerType> = {
  NA: 'na',
  NONE: 'none',
  SKIP: 'skipped',
  OTHER: 'other',
}

export function isAnswerIdNa(answerId?: string): answerId is 'na' {
  return answerId === 'na'
}

export function isAnswerIdNone(answerId?: string): answerId is 'none' {
  return answerId === 'none'
}

export function isAnswerIdSkipped(answerId?: string): answerId is 'skipped' {
  return answerId === 'skipped'
}

export function isAnswerIdOther(answerId?: string): answerId is 'other' {
  return answerId === 'other'
}

export function getGridChoicesByOption(choices: AnswerGridDataChoices): AnswerGridDataChoices {
  return choices.length > 0
    ? choices[0].map((_choice, index) => choices.map((_, i) => choices[i][index]))
    : []
}
