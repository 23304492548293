import {
  isStudyChoiceQuestionCard,
  isStudyGridQuestionCard,
  isStudyNPSQuestionCard,
  isStudyOpenTextQuestionCard,
  isStudyRankedQuestionCard,
  isStudyVideoQuestionCard,
  type MergedStudyQuestionStructure,
  STATIC_ANSWER_ID,
  type StudyCard,
  type StudyQuestionCard,
} from '@attest/study'
import { keyBy } from '@attest/util'

import type {
  EmptyStudyResultsCard,
  StudyResultsCard,
  StudyResultsChoiceCard,
  StudyResultsGridCard,
  StudyResultsNPSCard,
  StudyResultsOpenTextCard,
  StudyResultsRankedCard,
  StudyResultsVideoCard,
} from './models'
import {
  createStudyResultsChoiceAnswer,
  createStudyResultsNAAnswer,
  createStudyResultsNoneAnswer,
  createStudyResultsOrderedAnswer,
  createStudyResultsOtherAnswer,
  createStudyResultsSkippedAnswer,
  createStudyResultsTextAnswer,
} from './models/answer/factory'

export function createResultCardFromStudyCard(
  card: StudyCard,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: Partial<EmptyStudyResultsCard | StudyResultsCard>,
): StudyResultsCard {
  if (isStudyGridQuestionCard(card))
    return createResultCardFromStudyGridQuestionCard(
      card,
      mergedStructure,
      resultsCard as StudyResultsGridCard,
    )
  if (isStudyChoiceQuestionCard(card))
    return createResultCardFromChoiceQuestionCard(
      card,
      mergedStructure,
      resultsCard as StudyResultsChoiceCard,
    )
  if (isStudyRankedQuestionCard(card))
    return createResultCardFromRankedQuestionCard(
      card,
      mergedStructure,
      resultsCard as StudyResultsRankedCard,
    )
  if (isStudyOpenTextQuestionCard(card))
    return createResultCardFromOpenTextQuestionCard(
      card,
      mergedStructure,
      resultsCard as StudyResultsOpenTextCard,
    )
  if (isStudyVideoQuestionCard(card))
    return createResultCardFromVideoQuestionCard(
      card,
      mergedStructure,
      resultsCard as StudyResultsVideoCard,
    )
  if (isStudyNPSQuestionCard(card))
    return createResultCardFromNPSQuestionCard(
      card,
      mergedStructure,
      resultsCard as StudyResultsNPSCard,
    )
  throw new Error('Unhandled question type')
}

function createResultCardFromRankedQuestionCard(
  card: StudyQuestionCard<'ranked'>,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: EmptyStudyResultsCard<StudyResultsRankedCard>,
): StudyResultsRankedCard {
  return {
    id: card.id,
    answerIdToAnswer: Object.assign(
      keyBy(
        mergedStructure.options
          .flatMap(({ answerIds }) => answerIds)
          .map(id =>
            createStudyResultsOrderedAnswer({
              id,
              ...resultsCard?.answerIdToAnswer?.[id],
            }),
          ),

        ({ id }) => id,
      ),
      {
        [STATIC_ANSWER_ID.NA]: createStudyResultsNAAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NA],
        }),
        [STATIC_ANSWER_ID.SKIPPED]: createStudyResultsSkippedAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.SKIPPED],
        }),
      },
    ),
  }
}

function createResultCardFromChoiceQuestionCard(
  card: StudyQuestionCard<'choice'>,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: EmptyStudyResultsCard<StudyResultsChoiceCard>,
): StudyResultsChoiceCard {
  return {
    id: card.id,
    answerIdToAnswer: Object.assign(
      keyBy(
        mergedStructure.options
          .flatMap(({ answerIds }) => answerIds)
          .map(id =>
            createStudyResultsChoiceAnswer({
              id,
              ...resultsCard?.answerIdToAnswer?.[id],
            }),
          ),
        ({ id }) => id,
      ),
      {
        [STATIC_ANSWER_ID.OTHER]: createStudyResultsOtherAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.OTHER],
        }),
        [STATIC_ANSWER_ID.NA]: createStudyResultsNAAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NA],
        }),
        [STATIC_ANSWER_ID.NONE]: createStudyResultsNoneAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NONE],
        }),
        [STATIC_ANSWER_ID.SKIPPED]: createStudyResultsSkippedAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.SKIPPED],
        }),
      },
    ),
  }
}

function createResultCardFromNPSQuestionCard(
  card: StudyQuestionCard<'nps'>,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: EmptyStudyResultsCard<StudyResultsNPSCard>,
): StudyResultsNPSCard {
  return {
    id: card.id,
    answerIdToAnswer: Object.assign(
      keyBy(
        mergedStructure.options
          .flatMap(({ answerIds }) => answerIds)
          .map(id =>
            createStudyResultsChoiceAnswer({
              id,
              ...resultsCard?.answerIdToAnswer?.[id],
            }),
          ),

        ({ id }) => id,
      ),
      {
        [STATIC_ANSWER_ID.NA]: createStudyResultsNAAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NA],
        }),
        [STATIC_ANSWER_ID.NONE]: createStudyResultsNoneAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NONE],
        }),
        [STATIC_ANSWER_ID.SKIPPED]: createStudyResultsSkippedAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.SKIPPED],
        }),
      },
    ),
  }
}

function createResultCardFromOpenTextQuestionCard(
  card: StudyQuestionCard<'open-text'>,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: EmptyStudyResultsCard<StudyResultsOpenTextCard>,
): StudyResultsOpenTextCard {
  return {
    id: card.id,
    answerIdToAnswer: Object.assign(
      keyBy(
        mergedStructure.options
          .flatMap(({ answerIds }) => answerIds)
          .map(id =>
            createStudyResultsTextAnswer({
              id,
              ...resultsCard?.answerIdToAnswer?.[id],
            }),
          ),
        ({ id }) => id,
      ),
      {
        [STATIC_ANSWER_ID.NA]: createStudyResultsNAAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NA],
        }),
        [STATIC_ANSWER_ID.SKIPPED]: createStudyResultsSkippedAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.SKIPPED],
        }),
      },
    ),
  }
}

function createResultCardFromVideoQuestionCard(
  card: StudyQuestionCard<'video'>,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: EmptyStudyResultsCard<StudyResultsVideoCard>,
): StudyResultsVideoCard {
  return {
    id: card.id,
    answerIdToAnswer: Object.assign(
      keyBy(
        mergedStructure.options
          .flatMap(({ answerIds }) => answerIds)
          .map(id =>
            createStudyResultsTextAnswer({
              id,
              ...resultsCard?.answerIdToAnswer?.[id],
            }),
          ),
        ({ id }) => id,
      ),
      {
        [STATIC_ANSWER_ID.NA]: createStudyResultsNAAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.NA],
        }),
        [STATIC_ANSWER_ID.SKIPPED]: createStudyResultsSkippedAnswer({
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.SKIPPED],
        }),
      },
    ),
  }
}

function createResultCardFromStudyGridQuestionCard(
  card: StudyQuestionCard<'grid'>,
  mergedStructure: MergedStudyQuestionStructure,
  resultsCard?: EmptyStudyResultsCard<StudyResultsGridCard>,
): StudyResultsGridCard {
  return {
    id: card.id,
    answerIdToAnswer: Object.assign(
      keyBy(
        mergedStructure.options
          .flatMap(({ answerIds }) => answerIds)
          .map(id =>
            createStudyResultsChoiceAnswer({
              id,
              ...resultsCard?.answerIdToAnswer?.[id],
            }),
          ),
        answer => answer.id,
      ),
      {
        [STATIC_ANSWER_ID.SKIPPED]: createStudyResultsChoiceAnswer({
          id: STATIC_ANSWER_ID.SKIPPED,
          ...resultsCard?.answerIdToAnswer?.[STATIC_ANSWER_ID.SKIPPED],
        }),
      },
    ),
  }
}
