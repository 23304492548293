import { findAncestorElementBy } from './tree'
import { walkAllElements } from './tree-walker'

export function scrollToElementIfHidden(element: HTMLElement): void {
  const isVerticallyVisible = isElementVerticallyVisible(element)
  const isHorizontallyVisible = isElementHorizontallyVisible(element)

  if (isVerticallyVisible && isHorizontallyVisible) return

  element.scrollIntoView({
    block: isElementHeightSmallerThanScreen(element) ? 'center' : 'start',
    inline: 'center',
    behavior: 'smooth',
  })
}

export function isElementHorizontallyVisible(el: HTMLElement): boolean {
  const elRect = el.getBoundingClientRect()
  const firstScrollableParent = getFirstScrollableXParent(el)
  const parentRect = firstScrollableParent.getBoundingClientRect()

  return elRect.left >= parentRect.left && elRect.right <= parentRect.right
}

export function isElementVerticallyVisible(el: HTMLElement): boolean {
  const elRect = el.getBoundingClientRect()
  const firstScrollableParent = getFirstScrollableYParent(el)
  const parentRect = firstScrollableParent.getBoundingClientRect()

  return elRect.top >= parentRect.top && elRect.bottom <= parentRect.bottom
}

export function isElementHorizontallyContainedIn(
  element: Element,
  possibleContainer: Element,
): boolean {
  const { left, right } = element.getBoundingClientRect()
  const possibleContainerClientRect = possibleContainer.getBoundingClientRect()
  return left >= possibleContainerClientRect.left && right <= possibleContainerClientRect.right
}

export function isElementVerticallyContainedIn(
  element: Element,
  possibleContainer: Element,
): boolean {
  const { top, bottom } = element.getBoundingClientRect()
  const possibleContainerClientRect = possibleContainer.getBoundingClientRect()
  return bottom <= possibleContainerClientRect.bottom && top >= possibleContainerClientRect.top
}

export function getFirstScrollableXParent(element: Element): Element {
  return (
    findAncestorElementBy(parent => {
      const overflowX = window.getComputedStyle(parent).overflowX
      const isScrollable = overflowX !== 'visible' && overflowX !== 'hidden'

      return isScrollable && parent.scrollWidth >= parent.clientWidth
    }, element) || document.body
  )
}

export function getFirstScrollableYParent(element: Element): Element {
  return (
    findAncestorElementBy(parent => {
      const overflowY = window.getComputedStyle(parent).overflowY
      const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'

      return isScrollable && parent.scrollHeight >= parent.clientHeight
    }, element) || document.body
  )
}

export function isElementOrDescendantFocused(element: Element): boolean {
  if (!document || !document.activeElement) return false
  if (document.activeElement === element) return true
  return (
    typeof findAncestorElementBy(parent => parent === element, document.activeElement) !==
    'undefined'
  )
}

function isElementHeightSmallerThanScreen(el: HTMLElement): boolean {
  return (
    el.getBoundingClientRect().height <
    (window.innerHeight || document.documentElement.clientHeight)
  )
}

export function getBoundingRelativeRect(
  element: HTMLElement,
  relativeTo: Element,
): { x: number; y: number; width: number; height: number } {
  const { left: relativeLeft, top: relativeTop } = relativeTo.getBoundingClientRect()
  const { scrollLeft, scrollTop } = relativeTo
  const { left, top, width, height } = element.getBoundingClientRect()
  return {
    x: left - relativeLeft + scrollLeft,
    y: top - relativeTop + scrollTop,
    width,
    height,
  }
}

export function getTextWidth(text: string, font: string): number {
  if (typeof CanvasRenderingContext2D !== 'function') return 0
  const context = document.createElement('canvas').getContext('2d')
  if (!(context instanceof CanvasRenderingContext2D)) return 0
  context.font = font
  return context.measureText(text).width
}

export function removeChildElementsBySelector(
  rootElement: HTMLElement,
  selector: string,
): HTMLElement {
  const elements = rootElement.querySelectorAll<HTMLElement>(selector)
  elements.forEach(el => el.remove())
  return rootElement
}

export function getLongestWidthOfElement(element: HTMLElement): number {
  return Math.max(...[...walkAllElements(element)].map(el => el.offsetWidth ?? 0))
}
