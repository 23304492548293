import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { SETTINGS } from '@attest/editor-settings'
import {
  createStudyInsightsCardResponseFilterEntities,
  createStudyInsightsCountryFilterEntities,
  createStudyInsightsDateFilterEntities,
  createStudyInsightsDemographicFilterEntities,
} from '@attest/results-core'
import { useCrosstabs2ColumnDefinitionsStore, useSigTestStore } from '@attest/results-crosstabs'
import { STUDY_INSIGHTS_ROUTE_NAME, VIEWS } from '@attest/router'
import { createCrosstabsUiStateEntity, useGetTrackingEntities } from '@attest/tracking'
import { createResultsVideoResponsesRoute } from '@attest/video-responses'

export function createStudyInsightsRoute(
  props: (route: RouteLocation) => { studyId: string },
): RouteRecordRaw {
  return {
    path: `results`,
    alias: 'insights',
    name: STUDY_INSIGHTS_ROUTE_NAME.ROOT,
    components: {
      default: async () =>
        (
          await import(
            '@attest/results-components/src/_/study-insights/organism/StudyInsights/StudyInsights.vue'
          )
        ).default,
      [VIEWS.STUDY_NAVBAR]: async () =>
        (
          await import(
            '@attest/study/src/components/organism/StudyNavbar/ResultsAndManageNavbar.vue'
          )
        ).default,
    },
    props: {
      default: props,
      [VIEWS.NAVBAR]: to => ({
        guid: to.params.guid === SETTINGS.DEFAULT_GUID ? undefined : to.params.guid,
      }),
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'either',
      ...useGetTrackingEntities(() => [
        ...createStudyInsightsCountryFilterEntities(),
        ...createStudyInsightsDateFilterEntities(),
        ...createStudyInsightsCardResponseFilterEntities(),
        ...createStudyInsightsDemographicFilterEntities(),
      ]),
    },
    children: [
      {
        path: '',
        redirect(to) {
          return { name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW, params: to.params, query: to.query }
        },
        meta: {
          authenticationRequired: 'either',
        },
      },
      createResultsVideoResponsesRoute(),
      {
        name: STUDY_INSIGHTS_ROUTE_NAME.TRENDS,
        path: 'trends',
        component: async () =>
          (
            await import(
              '@attest/results-components/src/_/study-insights/organism/StudyInsightsGraphView/StudyInsightsGraphView.vue'
            )
          ).default,
        props(route: RouteLocation) {
          return props(route)
        },
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'either',
        },
      },
      {
        name: STUDY_INSIGHTS_ROUTE_NAME.CROSSTABS,
        path: 'analysis',
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'either',
          ...useGetTrackingEntities(() => [
            (() => {
              const sigTest = useSigTestStore()
              const { dynamicDefinitions } = useCrosstabs2ColumnDefinitionsStore()
              return createCrosstabsUiStateEntity({
                sigtestEnabled: sigTest.enabled.crosstabs,
                sigtestConfidence: (1 - sigTest.pValue.crosstabs) * 100,
                sigtestComparison:
                  sigTest.comparison.crosstabs === 'total' ||
                  sigTest.comparison.crosstabs === 'previous-column'
                    ? sigTest.comparison.crosstabs
                    : `${sigTest.comparison.crosstabs.variable}::${sigTest.comparison.crosstabs.value}`,
                allVariables: dynamicDefinitions.flatMap(definition =>
                  definition.values.map(value => `${definition.variable}::${value}`),
                ),
              })
            })(),
          ]),
        },

        component: async () =>
          (await import('@attest/results-components/src/view/Crosstabs/Crosstabs.vue')).default,
      },
      {
        name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW,
        path: 'overview',
        alias: '(.*)',
        component: async () =>
          (
            await import(
              '@attest/results-components/src/_/study-insights/organism/StudyInsightsOverviewView/StudyInsightsOverviewView.vue'
            )
          ).default,
        props,
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'either',
        },
      },
    ],
  }
}
