import type { RouteLocation, RouteLocationNamedRaw, RouteRecordRaw } from 'vue-router'

import {
  CARD_EDITOR_VIEW,
  type CardEditorProps,
  getCardEditorViewSessionValue,
  setCardEditorViewSessionValue,
} from '@attest/editor-survey-drafter/src/core/components/organism/CardEditor'
import {
  STUDY_UPDATER_ROUTE_NAME,
  StudyUpdaterCardEditorViewToRouteName,
  VIEWS,
} from '@attest/router'

import { STUDY_UPDATER_VIEWS } from './organism/StudyUpdater/interface'

export function createStudyUpdaterRoute(
  props: (route: RouteLocation) => { studyId: string },
): RouteRecordRaw {
  return {
    path: 'edit',
    components: {
      default: async () => (await import('./organism/StudyUpdater/StudyUpdater.vue')).default,
      [VIEWS.STUDY_NAVBAR]: async () =>
        (await import('./organism/StudyUpdaterNavbar/StudyUpdaterNavbar.vue')).default,
    },
    props: {
      default: props,
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
    },
    redirect(to) {
      return {
        name: STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_LIST_VIEW,
        query: to.query,
      }
    },
    children: [
      createStudyUpdaterEditorRoute(props),
      {
        path: 'audience',
        components: {
          [STUDY_UPDATER_VIEWS.BODY]: async () =>
            (await import('./organism/StudyUpdaterAudience')).StudyUpdaterAudience,
          [STUDY_UPDATER_VIEWS.FOOTER]: async () =>
            (await import('./organism/StudyUpdaterAudience')).StudyUpdaterAudienceFooter,
        },
        props: {
          [STUDY_UPDATER_VIEWS.BODY]: props,
        },
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'authenticated',
        },
        children: [
          {
            name: STUDY_UPDATER_ROUTE_NAME.AUDIENCE,
            path: '',
            meta: {
              authenticationRequired: 'authenticated',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            props: {
              managingAudiencesForStudy: true,
            },
            component: async () =>
              (await import('@attest/audience-editor/src/organism/ManageAudiences'))
                .ManageAudiences,
          },
        ],
      },
      {
        name: STUDY_UPDATER_ROUTE_NAME.REVIEW,
        path: 'review',
        components: {
          [STUDY_UPDATER_VIEWS.BODY]: async () =>
            (await import('./organism/StudyUpdaterReview')).StudyUpdaterReview,
          [STUDY_UPDATER_VIEWS.FOOTER]: async () =>
            (await import('./organism/StudyUpdaterReview')).StudyUpdaterReviewFooter,
        },
        props: {
          [STUDY_UPDATER_VIEWS.BODY]: props,
        },
        meta: {
          showNavbarTopbar: true,
          showProgressBar: true,
          authenticationRequired: 'authenticated',
        },
        children: [
          {
            name: STUDY_UPDATER_ROUTE_NAME.REVIEW_CHECKOUT,
            path: 'checkout',
            component: async () =>
              (await import('./organism/StudyUpdaterCheckout')).StudyUpdaterCheckout,
            meta: {
              showNavbarTopbar: true,
              showProgressBar: true,
              authenticationRequired: 'authenticated',
            },
          },
        ],
      },
    ],
  }
}

function createStudyUpdaterEditorRoute(
  props: (route: RouteLocation) => { studyId: string },
): RouteRecordRaw {
  return {
    path: '',
    components: {
      [STUDY_UPDATER_VIEWS.BODY]: async () =>
        (await import('./organism/StudyUpdaterEditor')).StudyUpdaterEditor,
      [STUDY_UPDATER_VIEWS.FOOTER]: async () =>
        (await import('./organism/StudyUpdaterEditor')).StudyUpdaterEditorFooter,
    },
    props: {
      [STUDY_UPDATER_VIEWS.BODY]: props,
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
    },
    children: [
      {
        path: 'draft',
        alias: '',
        meta: {
          authenticationRequired: 'authenticated',
          showNavbarTopbar: true,
          showProgressBar: false,
        },
        name: STUDY_UPDATER_ROUTE_NAME.EDITOR,
        redirect(to) {
          const { studyId } = props(to)
          return {
            name:
              getCardEditorViewSessionValue(studyId) ??
              STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_LIST_VIEW,
          }
        },
        props(to): CardEditorProps {
          const { studyId } = props(to)
          const view =
            to.name === STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_MAP_VIEW
              ? CARD_EDITOR_VIEW.MAP
              : CARD_EDITOR_VIEW.LIST
          setCardEditorViewSessionValue(studyId, (to.name as string) ?? undefined)
          return { view, viewToRouteName: StudyUpdaterCardEditorViewToRouteName }
        },
        component: async () =>
          (
            await import(
              '@attest/editor-survey-drafter/src/core/components/organism/CardEditor/CardEditor.vue'
            )
          ).default,
        children: [
          {
            name: STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_LIST_VIEW,
            path: 'list',
            meta: {
              authenticationRequired: 'authenticated',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            component: async () =>
              (await import('./organism/StudyUpdaterEditorListView/StudyUpdaterEditorListView.vue'))
                .default,
          },
          {
            name: STUDY_UPDATER_ROUTE_NAME.EDITOR_CARDS_MAP_VIEW,
            path: 'map',
            meta: {
              authenticationRequired: 'authenticated',
              showNavbarTopbar: true,
              showProgressBar: false,
            },
            component: async () =>
              (await import('./organism/StudyUpdaterEditorMapView/StudyUpdaterEditorMapView.vue'))
                .default,
          },
        ],
      },
    ],
  }
}

export function createStudyUpdaterLocation({
  studyId,
}: {
  studyId: string
}): RouteLocationNamedRaw {
  return {
    name: STUDY_UPDATER_ROUTE_NAME.EDITOR,
    params: {
      studyId,
    },
  }
}
