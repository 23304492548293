export const REQUEST_URL = {
  RESULTS: '/api/results',
  MARK_AS_PQL_URL: (makerGuid: string): string => `/api/makers/${makerGuid}/pql`,
  GET_SURVEY: (guid: string) => `/api/results/${guid}/structure` as const,
  INCREASE_SAMPLE: (guid: string) => `/api/surveys/${guid}` as const,
  GET_EXCLUSIVE_SAMPLES: (guid: string) => `/api/survey/${guid}/exclusive-samples` as const,
  APPROVE_TAKERS: '/api/approve-results',
  REJECT_TAKERS: ({ id, waveSurveyId }: { id: string; waveSurveyId: string }) =>
    `/api/study/${id}/wave/${waveSurveyId}/reject-results`,
}
