import type { Card } from '@attest/editor-card'

import { flattenCards } from './card-service'
import type { Survey } from './store'

export function isSurveyTextCardsOnly(survey: Survey): boolean {
  const cards = Object.values(survey.cards)
  return cards.length > 0 && cards.every(card => card.type === 'text')
}

export function isVideoResponseSurvey(cards: Card[]): boolean {
  return flattenCards(cards).some(card => card.question.type === 'video')
}

export function hasConsecutiveTextCard(survey: Survey): boolean {
  return Object.values(survey.cards).some(card => {
    const nextCard = card.nextGuid ? survey.cards[card.nextGuid] : null
    return nextCard ? card.type === 'text' && nextCard.type === 'text' : false
  })
}
