import { createDuplicateSurveyEntity, createSurveyEntity, trackAction } from '@attest/tracking'
import { createTrackingTracer } from '@attest/tracking/src/tracer'

export const trackDuplicateSurvey = ({
  originalGuid,
  clonedGuid,
  event,
}: {
  originalGuid: string
  clonedGuid: string
  event: Event
}): void =>
  trackAction({
    id: 'create-duplicate-survey',
    event,
    entities: [
      createDuplicateSurveyEntity({
        originalId: originalGuid,
        newId: clonedGuid,
      }),
    ],
    tracers: [createTrackingTracer('survey-creation')],
  })

export const trackDuplicateSurveyExcludeRespondents = ({
  originalGuid,
  clonedGuid,
  event,
}: {
  originalGuid: string
  clonedGuid: string
  event: Event
}): void =>
  trackAction({
    id: 'duplicate-survey-excluding-respondents',
    event,
    entities: [
      createDuplicateSurveyEntity({
        originalId: originalGuid,
        newId: clonedGuid,
      }),
    ],
    tracers: [createTrackingTracer('survey-creation')],
  })

export const trackCancelDuplicateSurvey = ({
  event,
  guid,
}: {
  event: Event
  guid?: string
}): void =>
  trackAction({
    id: 'cancel-duplicate-survey',
    event,
    entities: guid ? [createSurveyEntity({ id: guid })] : [],
  })
