import type { MarkOptional } from 'ts-essentials'

import {
  type InternalError,
  reasonErrorToInternalErrorList,
  service,
  type ServiceError,
  type ServiceRequest,
} from '@attest/_api'
import type { GetProfileError, UpdateProfileError } from '@attest/_api/model/error'
import type { GetProfileArgs, UpdateProfilePayload } from '@attest/_api/model/users'

import type { Profile } from './model'
import { toProfile } from './transformer'

const REQUEST_URL = {
  MAKER_SUFFIX: (makerGuid: string) => `/members/${makerGuid}`,
}

const ARGS_TO_API_PARAM_NAMES = {
  hasResearchExperience: 'has_research_experience',
  jobRole: 'job_role',
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  phoneNumber: 'phone_number',
  organisationName: 'organization_name',
  companySector: 'company_sector',
  companySize: 'company_size',
} as const

type APIPayload = {
  has_research_experience?: UpdateProfilePayload['hasResearchExperience']
  job_role?: UpdateProfilePayload['jobRole']
  first_name?: UpdateProfilePayload['firstName']
  last_name?: UpdateProfilePayload['lastName']
  email?: UpdateProfilePayload['email']
  phone_number?: UpdateProfilePayload['phoneNumber']
  organization_name?: UpdateProfilePayload['organisationName']
  company_sector?: UpdateProfilePayload['companySector']
  company_size?: UpdateProfilePayload['companySize']

  interest_tags?: APIInterestTag[]
}

type APIInterestTag = {
  name: string
  selected: boolean
}

export const updateProfile: ServiceRequest<
  {},
  ServiceError<UpdateProfileError>,
  UpdateProfilePayload & { userGuid: string; requestUrlPrefix?: string }
> = async args => {
  try {
    const URL_PREFIX = 'requestUrlPrefix' in args ? args.requestUrlPrefix : ''
    const url = `${URL_PREFIX}${REQUEST_URL.MAKER_SUFFIX(args.userGuid)}`

    const apiPayload: APIPayload = Object.fromEntries(
      Object.entries(ARGS_TO_API_PARAM_NAMES)
        .filter(([requestKey]) => requestKey in args)
        .map(([requestKey, apiKey]) => [apiKey, args[requestKey as keyof typeof args]]),
    )

    if (args.phoneNumber === '') {
      delete apiPayload.phone_number
    }

    if (args.interestTags) {
      apiPayload.interest_tags = args.interestTags.map(
        (interestTag): APIInterestTag => ({
          selected: interestTag.isSelected,
          name: interestTag.name,
        }),
      )
    }

    if (Object.keys(apiPayload).length > 0) {
      await service().patch(url, apiPayload)
    }

    return true
  } catch (error) {
    return reasonErrorToInternalErrorList(error)
  }
}

export const getProfile: ServiceRequest<
  Profile,
  ServiceError<GetProfileError>,
  MarkOptional<GetProfileArgs, 'userOrganisationGuid'> & { requestUrlPrefix?: string }
> = async (args): Promise<Profile | InternalError<ServiceError>> => {
  try {
    const URL_PREFIX = 'requestUrlPrefix' in args ? args.requestUrlPrefix : ''
    const url = `${URL_PREFIX}${REQUEST_URL.MAKER_SUFFIX(args.userGuid)}`
    const response = await service().get(url)
    return toProfile(response.data)
  } catch (error) {
    return reasonErrorToInternalErrorList(error)
  }
}
