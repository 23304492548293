import { defineStore } from 'pinia'
import { difference } from 'ramda'
import { computed, watch } from 'vue'
import { type LocationQueryRaw, type RouteLocationRaw, useRouter } from 'vue-router'

import { sortWavesByAscendingStartTime, useStudyStore } from '@attest/study'

import { useStudyResultsStore } from '../study-results'

import { useStudyWaveAudienceFiltersStore } from './wave-audience-filters'
import { useStudyWaveDateFiltersStore } from './wave-date-filters'

export const useStudyWaveFiltersStore = defineStore('studyWaveFilters', () => {
  const router = useRouter()
  const studyStore = useStudyStore()
  const studyWaveDateFiltersStore = useStudyWaveDateFiltersStore()
  const studyWaveAudienceFiltersStore = useStudyWaveAudienceFiltersStore()

  const filteredSentWaveSurveys = computed(() =>
    sortWavesByAscendingStartTime(
      studyStore.sentWaveSurveys.filter(
        ({ publishedTimestamp, audienceId }) =>
          studyWaveAudienceFiltersStore.serialisedAudiences.has(
            studyWaveAudienceFiltersStore.serialiseAudience({ audienceId }),
          ) && studyWaveDateFiltersStore.publishedTimestamps.has(publishedTimestamp),
      ),
    ),
  )

  async function setFilters(payload: {
    publishedTimestamps?: Set<number>
    audiences?: Set<string>
  }): Promise<void> {
    const currentRoute = router.currentRoute.value
    if (!currentRoute.matched.some(match => match.name === 'STUDY::INSIGHTS::ROOT')) return

    await router.replace({
      ...currentRoute,
      query: {
        ...currentRoute.query,
        ...(payload.publishedTimestamps && { date: [...payload.publishedTimestamps].join(',') }),
        ...(payload.audiences && { audience: [...payload.audiences].join(',') }),
      },
    })
  }

  // fetch new waves
  // this should be in the study results store when converted to composition api
  watch(router.currentRoute, async route => {
    if (useStudyStore().study?.id !== route.params.studyId || !useStudyStore().study) return

    const studyResultsStore = useStudyResultsStore()
    const [_, unresolvedWaveIds] = await studyResultsStore.getStudyResultsByWave({
      waveSurveyIds: filteredSentWaveSurveys.value.map(({ id }) => id),
    })

    if (unresolvedWaveIds) {
      const unavailableWaves = filteredSentWaveSurveys.value.filter(wave =>
        unresolvedWaveIds.map(({ id }) => id).includes(wave.id),
      )

      await setFilters({
        publishedTimestamps: new Set(
          difference(
            [...studyWaveDateFiltersStore.publishedTimestamps],
            unavailableWaves.map(({ publishedTimestamp }) => publishedTimestamp),
          ),
        ),
        audiences: new Set(
          difference(
            [...studyWaveAudienceFiltersStore.serialisedAudiences],
            unavailableWaves.map(wave => studyWaveAudienceFiltersStore.serialiseAudience(wave)),
          ),
        ),
      })
    }
  })

  return {
    publishedTimestamps: computed(() => studyWaveDateFiltersStore.publishedTimestamps),
    serialisedAudiences: computed(() => studyWaveAudienceFiltersStore.serialisedAudiences),
    deserialisedAudiences: computed(() =>
      [...studyWaveAudienceFiltersStore.serialisedAudiences]
        .filter(audience => !!audience)
        .map(serialisedAudience =>
          studyWaveAudienceFiltersStore.deSerialiseAudience(serialisedAudience),
        )
        .filter(audience => !!audience),
    ),
    filteredSentWaveSurveys,
    setFilters,
    getPublishedTimestampsForRoute: studyWaveDateFiltersStore.getPublishedTimestampsForRoute,
    getSerialisedAudiencesForRoute: studyWaveAudienceFiltersStore.getSerialisedAudiencesForRoute,
  }
})

export function createWaveFilterQuery(rawRoute: RouteLocationRaw): LocationQueryRaw {
  const router = useRouter()
  if (!router) return {}

  const route = router.resolve(rawRoute)
  const { getSerialisedAudiencesForRoute } = useStudyWaveAudienceFiltersStore()
  const { getPublishedTimestampsForRoute } = useStudyWaveDateFiltersStore()
  return {
    ...route.query,
    audience: [...getSerialisedAudiencesForRoute(route)].join(','),
    date: [...getPublishedTimestampsForRoute(route)].join(','),
  }
}
