<script lang="tsx">
import { mapStores } from 'pinia'
import { defineComponent, type PropType } from 'vue'

import { Banner } from '@attest/_components/src/common/Banner'
import { FormButton } from '@attest/_components/src/common/FormButton'
import RangeInput from '@attest/_components/src/common/RangeInput.vue'
import { COPY } from '@attest/_copy'
import {
  isTargetingRangeError,
  type TargetingRangeError,
} from '@attest/_lib/src/editor-error/editor-error'
import { getTargetingErrors, type Quota, useAudienceEditorErrorStore } from '@attest/audience'
import { SETTINGS } from '@attest/editor-settings'
import { useEditorSurveyStore } from '@attest/editor-survey'
import { isOverlappingRanges, type Range } from '@attest/util'
import { create, createEmitter } from '@attest/vue-tsx'

export type SetupRangeProps = {
  segmentations: Range[]
  country: string
  demographicName?: string
}

export type SetupRangeEvents = {
  setOptions(range: Range[]): void
}

const emit = createEmitter<SetupRangeEvents>()

const SetupRange = defineComponent({
  props: {
    segmentations: { type: Array as PropType<Range[]>, required: true },
    demographicName: { type: String, default: '' },
    country: { type: String, required: true },
  },

  data() {
    return {
      ranges: this.segmentations,
      isDirty: false,
    }
  },

  computed: {
    ...mapStores(useEditorSurveyStore),
    rangeErrors(): TargetingRangeError[] {
      return getTargetingErrors({
        sampleSize: 0,
        segmentations: { age: this.ranges },
        quotas: [] as Quota[],
        surveyIsVideoResponseSurvey: this.editorSurveyStore.isVideoResponseSurvey,
      }).filter(isTargetingRangeError)
    },
    defaultMinAgeRange(): number {
      return this.editorSurveyStore.isVideoResponseSurvey
        ? SETTINGS.AUDIENCE.DEFAULT_MIN_AGE_RANGE_FOR_VIDEO_SURVEY
        : SETTINGS.AUDIENCE.DEFAULT_MIN_AGE_RANGE
    },
  },

  watch: {
    segmentations: {
      immediate: true,
      handler(newSegmentations: Range[]) {
        if (newSegmentations) {
          this.ranges = this.segmentations
        }
      },
    },
  },

  mounted() {
    useAudienceEditorErrorStore().updateTargetingErrors({
      isVideoResponseSurvey: this.editorSurveyStore.isVideoResponseSurvey,
    })
  },

  methods: {
    addRange() {
      this.ranges = [
        ...this.ranges,
        {
          min: this.defaultMinAgeRange,
          max: SETTINGS.AUDIENCE.DEFAULT_MAX_AGE_RANGE,
        },
      ]

      emit(this, 'setOptions', this.ranges)
    },

    deleteRangeAt(index: number) {
      const ranges = [...this.ranges.slice(0, index), ...this.ranges.slice(index + 1)]
      this.ranges = ranges

      emit(this, 'setOptions', this.ranges)
      useAudienceEditorErrorStore().updateTargetingErrors({
        isVideoResponseSurvey: this.editorSurveyStore.isVideoResponseSurvey,
      })
    },

    showErrorForRange(range: Range) {
      return range.min > range.max || isOverlappingRanges(range, this.ranges)
    },

    updateRange(range: Range, index: number) {
      const ranges = [...this.ranges]
      ranges[index] = range
      this.ranges = ranges

      emit(this, 'setOptions', this.ranges)
      this.isDirty = true
      useAudienceEditorErrorStore().updateTargetingErrors({
        isVideoResponseSurvey: this.editorSurveyStore.isVideoResponseSurvey,
      })
    },
  },

  render() {
    const baseClass = 'c-setup-range'
    return (
      <div class={baseClass}>
        <div class={`${baseClass}__range-list`}>
          {this.ranges.map((range, index) => (
            <RangeInput
              data-name="SetupRangeRangeInput"
              key={`range-input-${index}`}
              class={`${baseClass}__range-input`}
              minValue={range.min}
              maxValue={range.max}
              onSetMin={({ value: min }) => this.updateRange({ ...range, min }, index)}
              onSetMax={({ value: max }) => this.updateRange({ ...range, max }, index)}
              maxLength={2}
              onDeleteRange={() => this.deleteRangeAt(index)}
              isError={this.isDirty && this.showErrorForRange(range)}
            />
          ))}
        </div>
        <div class={`${baseClass}__add-range-wrapper`}>
          <FormButton
            elementTag="button"
            variant="secondary"
            onClick={this.addRange}
            data-name="SetupRangeAddRange"
          >
            {COPY.ADD_AGE_RANGE}
          </FormButton>
        </div>
        {this.rangeErrors.map(error => (
          <Banner class={`${baseClass}__error-banner`} styleVariant="error" data-name="RangeError">
            {error.type === 'TARGETING_BELOW_MIN_AGE' ||
            error.type === 'VIDEO_SURVEY_TARGETING_BELOW_MIN_AGE'
              ? COPY.EDITOR.ERRORS[error.type](this.defaultMinAgeRange)
              : COPY.EDITOR.ERRORS[error.type]}
          </Banner>
        ))}
      </div>
    )
  },
})

export default create<SetupRangeProps, SetupRangeEvents>(SetupRange)
</script>
<style lang="postcss">
.c-setup-range {
  width: 100%;

  &__range-list {
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__range-input,
  &__add-range-wrapper {
    margin-bottom: var(--attest-spacing-2);
  }

  &__range-input {
    margin-right: var(--attest-spacing-2);
  }

  &__add-range-wrapper {
    display: flex;
  }

  &__error-banner {
    margin-top: var(--attest-spacing-2);
  }
}
</style>
