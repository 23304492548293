<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create } from '@attest/vue-tsx'

import type { HorizontalRuleProps } from './HorizontalRule.interface'

const HorizontalRule: FunctionalComponent<HorizontalRuleProps> = props => {
  const { text, theme = 'thin', align = 'center', ...attrs } = props
  const baseClass = 'c-horizontal-rule'
  const horizontalRuleClass = {
    [baseClass]: true,
    [`${baseClass}--${align}`]: true,
    [`${baseClass}--${theme}`]: true,
  }

  return (
    <div class={horizontalRuleClass} {...attrs}>
      <hr class={`${baseClass}__rule`} role="presentation" />
      {text && <span class={`${baseClass}__text`}>{text}</span>}
      <hr class={`${baseClass}__rule`} role="presentation" />
    </div>
  )
}

export default create<HorizontalRuleProps>(HorizontalRule)
</script>
<style lang="postcss">
.c-horizontal-rule {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  &__text {
    padding: 0;
    margin: var(--attest-spacing-4) 0;
    color: var(--attest-color-text-subdued);
    font: var(--attest-font-body-2);
    white-space: nowrap;
  }

  &__rule {
    position: static;
    width: 100%;
    height: var(--attest-border-width-s);
    padding: 0;
    border: none;
    margin: var(--attest-spacing-4) 0;
    background: var(--attest-color-border-subdued);
  }

  &--thin {
    ^&__text {
      padding-right: var(--attest-spacing-2);
      padding-left: var(--attest-spacing-2);
    }
  }

  &--wide {
    ^&__text {
      padding-right: var(--attest-spacing-10);
      padding-left: var(--attest-spacing-10);
    }
  }

  &--left {
    ^&__text {
      order: -1;
      padding-left: 0;
    }
  }

  &--center {
    ^&__text {
      order: 0;
    }
  }

  &--right {
    ^&__text {
      order: 1;
      padding-right: 0;
    }
  }
}
</style>
