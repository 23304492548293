import { createEntity, type Entity } from './base'

type PermissionId = 'SURVEY_CAN_EDIT'
export type PermissionEntity<D extends PermissionEntityData = PermissionEntityData> = {} & Entity<D>
type PermissionEntityData = {
  id: PermissionId
  value: boolean
}

export function createPermissionEntity({ id, value }: PermissionEntityData): PermissionEntity {
  return createEntity({ name: 'permission', version: '1-0-0' }, { id, value })
}
