import type { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { isNil } from 'ramda'

import {
  type InternalError,
  isAppError,
  isAppErrorList,
  type ServiceError,
  type ServiceResponse,
} from '@attest/_api'
import type {
  DeleteUserError,
  ListUsersErrorType,
  UpdateProfileError,
  UpdateUserRoleError,
} from '@attest/_api/model/error'
import type {
  UpdateProfilePayload,
  UserRoleActionType,
  UserRoleEndpointType,
} from '@attest/_api/model/users'
import type { RegistrationExperience } from '@attest/authentication'
import { authenticate } from '@attest/authentication'
import type { InvitedUser } from '@attest/organisation'
import {
  deleteUser,
  editUserPermissions,
  listUserInvites,
  listUsers,
  ORGANISATION_BASE_URL,
  transferUsersEntities,
} from '@attest/organisation'
import { setUser } from '@attest/telemetry'
import { trackingManager } from '@attest/tracking'
import type { PermissionType } from '@attest/user-permission'
import {
  getPermission,
  hasOneOfRequiredPermission,
  hasRequiredPermission,
} from '@attest/user-permission'

import { changePassword, type UserChangePasswordRequestData } from '../change-password'
import { forgotPassword, type UserForgotPasswordRequestData } from '../forgot-password'
import { login, type UserLoginRequestData } from '../login'
import { logout } from '../logout'
import type { ProfileRole, User } from '../model'
import { processInvite } from '../process-invite'
import {
  type CompanySector,
  type CompanySize,
  getProfile,
  type InterestTag,
  isCompanySector,
  isCompanySize,
  type MFAType,
  updateProfile,
} from '../profile'
import { register, type UserRegisterRequestData } from '../register'
import { resetPassword, type UserResetPasswordRequestData } from '../reset-password'
import { getUserDetails, type UserGetResponseData } from '../service'
import { toUserModel } from '../transformer'
import { type UserValidateEmailRequestData, validateEmail } from '../validate-email'

export type UserOrganisation = {
  name: string | null
  guid: string | null
  users: User[]
  invitedUsers: InvitedUser[]
  emailsToBeInvited: string[]
}

export type UserLegalEntity = {
  dataControllerEmail: string | null
  legalName: string | null
  privacyPolicyUrl: string | null
}

export type UserStoreState = {
  guid: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  authenticated: boolean | null
  isImpersonate: boolean | null
  role: ProfileRole[]
  mfa: MFAType | null
  registrationTimestamp: number
  intercomUserHash: string | null
  interestTags: InterestTag[]
  jobRole: string | null
  hasResearchExperience: boolean | null
  companySector: null | CompanySector
  companySize: null | CompanySize
  experience: RegistrationExperience | null
  phoneNumber: string | null
  organisation: UserOrganisation
  legalEntity: UserLegalEntity
}

export const USER_STORE_NAMESPACE = 'user'

export function createUserStoreState(override: Partial<UserStoreState> = {}): UserStoreState {
  return {
    guid: null,
    firstName: null,
    lastName: null,
    email: null,
    authenticated: null,
    isImpersonate: null,
    role: [],
    mfa: null,
    registrationTimestamp: 0,
    intercomUserHash: null,
    interestTags: [] as InterestTag[],
    jobRole: null,
    hasResearchExperience: null,
    companySector: null,
    companySize: null,
    experience: null,
    phoneNumber: null,
    organisation: {
      name: null,
      guid: null,
      users: [],
      invitedUsers: [],
      emailsToBeInvited: [],
    },
    legalEntity: {
      dataControllerEmail: null,
      legalName: null,
      privacyPolicyUrl: null,
    },
    ...override,
  }
}

export const useUserStore = defineStore(USER_STORE_NAMESPACE, {
  state: createUserStoreState,
  actions: {
    setUser(user: Partial<UserStoreState>): void {
      this.$patch(user)
    },
    async getUser(): Promise<ServiceResponse<UserGetResponseData>> {
      try {
        const userResponse = await getUserDetails()
        const { user, organisation, legalEntity } = userResponse.data.success
        this.setUser(user)
        setUser({ ...this.$state })

        this.organisation.guid = organisation.guid
        this.organisation.name = organisation.name
        this.legalEntity = legalEntity

        trackingManager.trigger({ type: 'SetUser' })
        trackingManager.trigger({ type: 'IdentifyUser', payload: { guid: user.guid } })

        return userResponse
      } catch (error) {
        if (this.authenticated) {
          this.logout()
        }
        throw new Error(`Profile failure on login: ${error}`)
      }
    },
    async authenticate() {
      const response = await authenticate()
      if (isAppError(response)) {
        this.authenticated = false
        this.isImpersonate = false
        return response
      }
      this.authenticated = response.authenticated
      this.isImpersonate = response.impersonated
      return response
    },
    async getUserAndAdditionalInfo(): Promise<ServiceResponse<UserGetResponseData>> {
      const userResponse = await this.getUser()

      return userResponse
    },
    async login(payload: UserLoginRequestData) {
      const response = await login(payload)
      this.authenticated = true
      await this.getUserAndAdditionalInfo()
      setUser({ ...this.$state })
      trackingManager.trigger({ type: 'CompletedLogin', payload: { method: 'native' } })
      return response
    },
    async logout() {
      const response = await logout()
      this.$reset()

      trackingManager.trigger({ type: 'CompletedLogout' })
      return response
    },
    async register(payload: UserRegisterRequestData) {
      const response = await register(payload)
      this.authenticated = true
      await this.getUserAndAdditionalInfo()
      trackingManager.trigger({
        type: 'CompletedRegistration',
        payload: {
          method: 'native',
          inviteCode: payload.inviteCode,
          experience: payload.experience,
        },
      })
      return response
    },
    async processInvite(url: URL) {
      const inviteCode = url.searchParams.get('invite-code')

      // If route has no invite code continue
      if (isNil(inviteCode)) return true

      // If there is an invite code then process
      const response = await processInvite({ inviteCode })
      const { user, organisation, redirect } = response.data.success

      this.setUser(user)
      this.organisation.name = organisation.name
      if (redirect && redirect !== url.pathname) {
        location.pathname = redirect
      }
      return true
    },
    async getProfile() {
      const userGuid = this.guid
      if (!userGuid) throw new Error('Missing user guid in profile request')
      const userOrganisationGuid = this.organisation?.guid
      if (!userOrganisationGuid) throw new Error('Missing organisation guid in profile request')

      const response = await getProfile({
        userGuid,
        userOrganisationGuid,
        requestUrlPrefix: ORGANISATION_BASE_URL(userOrganisationGuid),
      })

      if (!isAppErrorList<any>(response)) {
        if (response.jobRole !== undefined) {
          this.jobRole = response.jobRole
        }

        if (response.phoneNumber !== undefined) {
          this.phoneNumber = response.phoneNumber
        }

        if (response.hasResearchExperience !== undefined) {
          this.hasResearchExperience = response.hasResearchExperience
        }

        if (isCompanySector(response.companySector)) {
          this.companySector = response.companySector
        }

        if (isCompanySize(response.companySize)) {
          this.companySize = response.companySize
        }

        if (response.interestTags !== undefined) {
          this.interestTags = response.interestTags
        }

        this.mfa = response.mfa ?? null
      }

      return response
    },

    async updateProfile(
      payload: UpdateProfilePayload,
    ): Promise<InternalError<ServiceError<UpdateProfileError>> | Record<string, never>> {
      if (!this.organisation.guid)
        throw new Error('Missing organisation guid in updateProfile request')
      if (!this.guid) throw new Error('Missing user guid in updateProfile request')

      const response = await updateProfile({
        ...payload,
        userGuid: this.guid,
        requestUrlPrefix: ORGANISATION_BASE_URL(this.organisation.guid),
      })

      if (!isAppErrorList(response)) {
        if (payload.hasResearchExperience !== undefined) {
          this.hasResearchExperience = payload.hasResearchExperience
        }

        if (payload.firstName) this.firstName = payload.firstName
        if (payload.lastName) this.lastName = payload.lastName

        if (payload.phoneNumber !== undefined) {
          this.phoneNumber = payload.phoneNumber
        }

        if (payload.organisationName !== undefined) {
          this.organisation.name = payload.organisationName
        }

        if (isCompanySector(payload.companySector)) {
          this.companySector = payload.companySector
        }

        if (isCompanySize(payload.companySize)) {
          this.companySize = payload.companySize
        }

        if (payload.interestTags) {
          this.interestTags = payload.interestTags
        }

        if (payload.jobRole !== undefined) {
          this.jobRole = payload.jobRole
        }
      }

      return response
    },

    async forgotPassword(payload: UserForgotPasswordRequestData) {
      return forgotPassword(payload)
    },

    async changePassword(payload: UserChangePasswordRequestData) {
      return changePassword(payload)
    },

    async resetPassword(payload: UserResetPasswordRequestData) {
      return resetPassword(payload)
    },

    async validateEmail(payload: UserValidateEmailRequestData) {
      return validateEmail(payload)
    },

    async listUsers(): Promise<InternalError<ServiceError<ListUsersErrorType>> | User[]> {
      const { guid } = this.organisation
      if (!guid) return []

      const response = await listUsers(guid)

      if (isAppError(response)) return response

      const users = response.members.map(member => toUserModel(member))
      this.organisation.users = users

      return users
    },

    async listInvitedUsers(): Promise<void | AxiosError | Error> {
      const { guid } = this.organisation
      if (!guid) return
      const [response, error] = await listUserInvites({ id: guid })
      if (error) return error
      this.organisation.invitedUsers = response
    },

    async deleteUser(args: {
      userToDeleteGuid: string
      newOwnerOfUsersSurveysGuid?: string
    }): Promise<InternalError<ServiceError<DeleteUserError>> | void> {
      const previousUsers = this.organisation.users
      const filteredUsers = previousUsers.filter(user => user.guid !== args.userToDeleteGuid)
      this.organisation.users = filteredUsers

      if (args.newOwnerOfUsersSurveysGuid) {
        const [, transferError] = await transferUsersEntities({
          fromUserGuid: args.userToDeleteGuid,
          toUserGuid: args.newOwnerOfUsersSurveysGuid,
        })

        if (transferError !== undefined) {
          this.organisation.users = previousUsers
          return { errors: [{ reason: 'UNKNOWN' }] }
        }
      }

      const response = await deleteUser(args.userToDeleteGuid)

      if (response !== undefined) {
        this.organisation.users = previousUsers
        return response
      }
      return undefined
    },

    async editUserPermissions(payload: {
      guid: string
      endpoint: UserRoleEndpointType
      action: UserRoleActionType
    }): Promise<InternalError<ServiceError<UpdateUserRoleError>> | void> {
      const { guid } = this.organisation
      if (!guid) {
        return
      }
      const response = await editUserPermissions({
        userGuid: payload.guid,
        organisationGuid: guid,
        endpoint: payload.endpoint,
        action: payload.action,
      })

      if (isAppError(response)) {
        return response
      }
    },
  },
  getters: {
    user(): User | null {
      if (!this.guid || !this.email) return null

      return {
        guid: this.guid,
        email: this.email,
        firstName: this.firstName ?? '',
        lastName: this.lastName ?? '',
      }
    },

    isAuthenticated(): boolean {
      return this.authenticated === true
    },

    getUserInOrganisation(): (userGuid: string) => User | undefined {
      return userGuid => this.organisation.users.find(({ guid }) => guid === userGuid)
    },

    hasPermission(): (requiredPermissions: PermissionType[]) => boolean {
      return requiredPermissions =>
        hasRequiredPermission(
          requiredPermissions,
          getPermission({
            role: this.role,
          }),
        )
    },

    hasAtLeastOneOfPermissions(): (requiredPermissions: PermissionType[]) => boolean {
      return requiredPermissions =>
        hasOneOfRequiredPermission(
          requiredPermissions,
          getPermission({
            role: this.role,
          }),
        )
    },

    isPrivacyPolicyComplete(): boolean {
      return (
        !!this.legalEntity.legalName &&
        !!this.legalEntity.privacyPolicyUrl &&
        !!this.legalEntity.dataControllerEmail
      )
    },
  },
})
