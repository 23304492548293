<script lang="tsx">
import { defineComponent } from 'vue'

const Timeout = defineComponent({
  props: {
    duration: { type: Number, required: true },
  },

  emits: {
    timeout: () => true,
  },

  data() {
    return {
      timeoutId: -1,
    }
  },

  watch: {
    duration: {
      immediate: true,
      handler() {
        this.clear()
        this.start()
      },
    },
  },

  beforeUnmount() {
    this.clear()
  },

  methods: {
    start() {
      this.timeoutId = window.setTimeout(() => this.$emit('timeout'), this.duration)
    },

    clear() {
      window.clearTimeout(this.timeoutId)
    },
  },

  render() {
    return ''
  },
})

export default Timeout
</script>
