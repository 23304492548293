import type { RouteLocationNamedRaw, RouteLocationRaw } from 'vue-router'

export enum STUDY_INSIGHTS_ROUTE_NAME {
  ROOT = 'STUDY::INSIGHTS::ROOT',
  OVERVIEW = 'STUDY::INSIGHTS::OVERVIEW',
  GRAPH = 'STUDY::INSIGHTS::GRAPH',
  TRENDS = 'STUDY::INSIGHTS::TRENDS',
  ANALYSIS = 'STUDY::INSIGHTS::ANALYSIS',
  CROSSTABS = 'STUDY::INSIGHTS::CROSSTABS',
  VIDEO_RESULTS = 'STUDY::INSIGHTS::VIDEO_RESULTS::CARD',
}

export const STUDY_INSIGHTS_ROUTE_LABEL = {
  [STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW]: `Overview`,
  [STUDY_INSIGHTS_ROUTE_NAME.TRENDS]: `Trends`,
  [STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS]: `Analysis`,
  [`${STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS}::by-country`]: `Country`,
  [`${STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS}::by-gender`]: `Gender`,
  [`${STUDY_INSIGHTS_ROUTE_NAME.ANALYSIS}::by-age`]: `Age`,
  [`${STUDY_INSIGHTS_ROUTE_NAME.CROSSTABS}`]: `Analysis`,
  [STUDY_INSIGHTS_ROUTE_NAME.VIDEO_RESULTS]: `Video Responses`,
} as const

export function createStudyInsightsOverviewLocation({
  studyId,
}: {
  studyId: string
}): RouteLocationNamedRaw {
  return {
    name: STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW,
    params: {
      studyId,
    },
  }
}

export function createStudyInsightsByTimeLocation({
  studyId,
}: {
  studyId: string
}): RouteLocationRaw {
  return {
    name: STUDY_INSIGHTS_ROUTE_NAME.TRENDS,
    params: {
      studyId,
    },
  }
}
