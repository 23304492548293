import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import { useStudyStore } from '@attest/study'
import { capture } from '@attest/telemetry'
import { createExpectedError, createExpectedResolved, type Expected } from '@attest/util'

import { getSurveyStudyRoundIdsByWave } from '../api'

export const useSegmentRoundsStore = defineStore('segmentRounds', () => {
  const studyStore = useStudyStore()

  const eflToRoundIds = ref<Record<string, string[]>>({})
  const eflQueries = ref<Set<string>>(new Set())
  const isLoading = ref<Record<string, boolean>>({})

  function $reset(): void {
    eflToRoundIds.value = {}
    eflQueries.value.clear()
  }

  async function getRoundsByEfl(args: { efl: string }): Promise<Expected<unknown, Error>> {
    if (!isLoading.value[args.efl] && !eflQueries.value.has(args.efl)) {
      isLoading.value[args.efl] = true
      const [roundsData, apiError] = await getSurveyStudyRoundIdsByWave({
        id: studyId.value ?? '',
        eflQuery: args.efl,
      })
      isLoading.value[args.efl] = false

      if (apiError) {
        capture(apiError, {
          extra: {
            efl: args.efl,
            study: studyId.value,
          },
        })
        return createExpectedError(apiError)
      }

      eflToRoundIds.value[args.efl] = roundsData.map(round => round.round_guid)
      eflQueries.value.add(args.efl)
    }

    return createExpectedResolved()
  }

  // reset state if study id shifts
  const studyId = computed(() => studyStore.study?.id)
  watch(studyId, $reset)

  return {
    eflToRoundIds,
    eflQueries,
    isLoading,

    getRoundsByEfl,

    $reset,
  }
})
