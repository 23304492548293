import { nanoid } from 'nanoid'

import type { Subject } from './model'

export function createSubject(override: Partial<Subject> = {}): Subject {
  return Object.freeze({
    id: nanoid(),
    text: '',
    isRandomized: false,
    isPinned: false,
    answerIds: [],
    mask: undefined,
    ...override,
  })
}
