<script lang="tsx">
import { nanoid } from 'nanoid'
import { defineComponent } from 'vue'

import { frameThrottle } from '@attest/util'
import { create, type ElementAttributes } from '@attest/vue-tsx'

type TextareaAutosizeProps = ElementAttributes<'textarea'>

const TextareaAutosize = defineComponent({
  data() {
    return {
      id: nanoid(),
      throttledResizeHandler: null as null | (() => void),
      resizeObserver: null as null | ResizeObserver,
    }
  },

  computed: {
    value(): string {
      return this.$attrs.value as string
    },
  },

  watch: {
    value(newValue) {
      const element = this.$refs.textarea
      if (!(element instanceof HTMLTextAreaElement)) return
      this.setHeight(element, 'auto')

      if (newValue.length > 0) {
        this.setScrollHeight(element)
      }
    },
  },

  mounted() {
    const element = this.$refs.textarea as HTMLTextAreaElement
    this.setScrollHeight(element)
    this.throttledResizeHandler = frameThrottle(this.onWindowResize)
    window.addEventListener('resize', this.throttledResizeHandler)

    if (typeof ResizeObserver !== 'undefined' && ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.throttledResizeHandler?.()
      })
      this.resizeObserver.observe(element)
    }
  },

  beforeUnmount() {
    if (this.throttledResizeHandler) {
      window.removeEventListener('resize', this.throttledResizeHandler)
    }
    this.resizeObserver?.disconnect()
  },

  methods: {
    onWindowResize() {
      const element = this.$refs.textarea
      if (!(element instanceof HTMLTextAreaElement)) return
      this.setScrollHeight(element)
    },

    setScrollHeight(element: HTMLTextAreaElement) {
      this.setHeight(element, 'auto')
      this.setHeight(element, `${element.scrollHeight}px`)
    },

    setHeight(element: HTMLTextAreaElement, value: string) {
      element.style.height = value
    },
  },

  render() {
    const textareaClass = 'c-textarea-autosize'

    return (
      <textarea id={this.id} class={textareaClass} rows={1} ref="textarea">
        {this.value}
      </textarea>
    )
  },
})

export default create<TextareaAutosizeProps>(TextareaAutosize)
</script>
<style lang="postcss">
.c-textarea-autosize {
  width: 100%;
  box-sizing: border-box;
  padding-top: var(--attest-spacing-1);
  line-height: var(--attest-line-height-m);
  outline: none;
  overflow-y: hidden;
  resize: none;
  white-space: pre-line;

  &::placeholder {
    color: var(--attest-color-text-subdued);
  }
}

@context scoped {
  .c-textarea-autosize {
    margin: 0;
  }
}
</style>
