import { createEntity, type Entity } from './base'

export type DuplicateSurveyEntity<D extends DuplicateSurveyEntityData = DuplicateSurveyEntityData> =
  {} & Entity<D>
type DuplicateSurveyEntityData = {
  originalId: string
  newId: string
}

export function createDuplicateSurveyEntity({
  originalId,
  newId,
}: DuplicateSurveyEntityData): DuplicateSurveyEntity {
  return createEntity({ name: 'duplicate-survey', version: '1-0-0' }, { originalId, newId })
}
