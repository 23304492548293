export async function setupClient(): Promise<void> {
  await Promise.all([setupRequestIdleCallback()])
}

async function setupRequestIdleCallback(): Promise<void> {
  if ('requestIdleCallback' in window) {
    return
  }

  await import('requestidlecallback')
}
