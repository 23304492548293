import { sum } from 'ramda'

import { type FlatQuestionCard, STATIC_ANSWER_MAP } from '@attest/survey'
import { percentageOf, toOptionalDecimal } from '@attest/util'

/**
 * Calculates the average for ranked position for an answer.
 * E.g. If given [1, 0, 0, 4], the average position would be 3.4
 */
export function calculateRankedValueAverages(totalValue: number, positionValues: number[]): number {
  if (totalValue === 0) {
    return 0
  }

  const value = sum(
    positionValues.map((currentValue, index) => ((index + 1) * currentValue) / totalValue),
  )

  return toOptionalDecimal(value)
}

export function calculateNpsScore(
  totalPromoters: number,
  totalPassive: number,
  totalDetractors: number,
): number {
  const total = totalPromoters + totalDetractors + totalPassive
  const promotersPercentage = percentageOf(total, totalPromoters)
  const detractorsPercentage = percentageOf(total, totalDetractors)
  const npsPercentage = promotersPercentage - detractorsPercentage

  if (npsPercentage === 0) {
    return npsPercentage
  }

  return Math.round(npsPercentage * 10) / 10
}

export function getPromoterAnswerIdsFromSurveyCard(surveyCard: FlatQuestionCard): string[] {
  return surveyCard.answers
    .filter(answer => answer.text === '9' || answer.text === '10')
    .map(answer => answer.id)
}

export function getDetractorAnswerIdsFromSurveyCard(surveyCard: FlatQuestionCard): string[] {
  return surveyCard.answers
    .filter(
      answer =>
        answer.text === '0' ||
        answer.text === '1' ||
        answer.text === '2' ||
        answer.text === '3' ||
        answer.text === '4' ||
        answer.text === '5' ||
        answer.text === '6',
    )
    .map(answer => answer.id)
}

export function getPassiveAnswerIdsFromSurveyCard(surveyCard: FlatQuestionCard): string[] {
  return surveyCard.answers
    .filter(answer => answer.text === '7' || answer.text === '8')
    .map(answer => answer.id)
}

type SortableChoiceResponse = {
  answerItemData: {
    answerId: string
  }
  markerPercentage: number
  graphBarPercentage?: number
}

export function choiceResponsesSorter(
  itemA: SortableChoiceResponse,
  itemB: SortableChoiceResponse,
) {
  const a =
    itemA.answerItemData.answerId === STATIC_ANSWER_MAP.OTHER ? -1 : (itemA.graphBarPercentage ?? 0)
  const b =
    itemB.answerItemData.answerId === STATIC_ANSWER_MAP.OTHER ? -1 : (itemB.graphBarPercentage ?? 0)

  return b - a
}

type SortableRankedResponse = {
  value?: string
  positionValues: { graphBarPercentage: number }[]
}

export function rankedResponsesSorter(a: SortableRankedResponse, b: SortableRankedResponse) {
  if (a.value === undefined || b.value === undefined) {
    return 0
  }

  const aValue = Number.parseFloat(a.value)
  const bValue = Number.parseFloat(b.value)

  if (aValue === bValue) {
    return b.positionValues[0].graphBarPercentage - a.positionValues[0].graphBarPercentage
  }
  return aValue - bValue
}
