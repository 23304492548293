import type {
  ComponentPublicInstance,
  EmitsOptions,
  Events,
  ObjectEmitsOptions,
  SetupContext,
} from 'vue'

export type EventsOn<E = unknown> = Events & ObjectEmitsOptions

type EmitterContext = ComponentPublicInstance | Function | SetupContext

type EventName<Name extends string> = Name extends `on${Capitalize<string>}`
  ? Name
  : `on${Capitalize<Name>}`

type EventNameNormalized<Name> = Name extends `on${Capitalize<string>}` ? never : Name
export type OnEventHandlers<E> = {
  [K in keyof E as EventName<string & K>]?: E[K] extends Function | undefined
    ? E[K] | null
    : ((payload: E[K]) => any) | null
}

export const EMPTY_PAYLOAD: [any] = [] as any
export type OnEventRecord = {} & Record<string, OnEventMethod>
export type OnEventMethod = (...payload: any[]) => any

export function createEmitter<E>() {
  return function accessor<K extends keyof E>(
    context: EmitterContext,
    name: EventNameNormalized<K>,
    ...payload: E[K] extends OnEventMethod ? Parameters<E[K]> : E[K] extends void ? [] : [E[K]]
  ) {
    if ('$emit' in context) {
      context.$emit(getEventName(context.$attrs, name as string), ...payload)
      return
    }

    if ('emit' in context) {
      context.emit(getEventName(context.attrs, name as string), ...payload)
      return
    }

    context(getEventName(context, name as string), ...payload)
  }
}

function getEventName(listeners: Record<string, any>, name: string): string {
  const eventName = name.replace(/^on/, '').replace(/^./, k => k.toLowerCase())

  return listeners[eventName] ? eventName : eventName.replace(/[A-Z]/g, k => `-${k.toLowerCase()}`)
}

export type EmitsToProps<T extends EmitsOptions> = T extends string[]
  ? {
      [K in string & `on${Capitalize<T[number]>}`]?: (...args: any[]) => any
    }
  : T extends ObjectEmitsOptions
    ? {
        [K in string & `on${Capitalize<string & keyof T>}`]?: K extends `on${infer C}`
          ? T[Uncapitalize<C>] extends null
            ? (...args: any[]) => any
            : (...args: T[Uncapitalize<C>] extends (...args: infer P) => any ? P : never) => any
          : never
      }
    : {}
export type ExtractEvents<P extends EmitsOptions> = EmitsToProps<P>
