import { isNotNil, pick, sum } from 'ramda'
import { computed } from 'vue'

import { formatOrdinal } from '@attest/intl'
import {
  calculateRankedValueAverages,
  isStudyResultsOrderedAnswer,
  type StudyResultsAnswer,
  useStudyInsightsStore,
} from '@attest/results-core'
import { useSavedSettingsStore } from '@attest/results-saved-settings'
import {
  isGridTemplateAnswers,
  isNonStaticOption,
  isStaticOption,
  isStudyQuestionCard,
  type Option,
  type StudyQuestionGroup,
  type Subject,
} from '@attest/study'
import { intersection, union } from '@attest/util'

import { type SigTestContext, useSigTestStore } from './store'

export type Row = {
  id: string
  title?: string
  children: RowChild[]
  isForwarded: boolean
}
export type RowChild = {
  id: string
  title: string
  isQualifying: boolean
  isForwarded: boolean
  routingTargetCardId?: string
  respondentIds: Set<string>
  forwardedRespondentIds?: Set<string>
  customRowType?: 'nps'
}

const savedStudySettingsKey = computed(() => {
  const sigtestContext = useSigTestStore().sigTestContext
  const savedStudySettingsKeys: Record<SigTestContext, 'trends' | 'overview' | 'crosstabs'> = {
    trends: 'trends',
    overview: 'overview',
    crosstabs: 'crosstabs',
  }

  return savedStudySettingsKeys[sigtestContext]
})

const sortOrder = computed(() => {
  return useSavedSettingsStore().studySettings[savedStudySettingsKey.value].order ?? 'draft'
})

const calculateByForwarded = computed(() => {
  return useSavedSettingsStore().studySettings[savedStudySettingsKey.value].show.forward_percentage
})

export function createCrosstabRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const card = studyInsightsStore.getQuestionCardById(cardId)

  let rows: Row[]

  switch (card.question.type) {
    case 'choice':
      rows = getChoiceQuestionRows(cardId)
      break
    case 'nps':
      rows = getNpsQuestionRows(cardId)
      break
    case 'ranked':
      rows = getRankedQuestionRows(cardId)
      break
    case 'grid':
      rows = getGridQuestionRows(cardId)
      break
    default:
      throw new Error('card type not supported')
  }

  rows = rows.filter(row => row.id !== 'na')

  if (studyInsightsStore.shouldUsePanelFilters) {
    const filteredRespondentIds = studyInsightsStore.getFilteredResponseIdsByCardId(cardId)
    return rows.map(row => ({
      ...row,
      children: row.children.map(childRow => ({
        ...childRow,
        respondentIds: intersection([childRow.respondentIds, filteredRespondentIds]),
      })),
    }))
  }
  return rows
}

function getGridQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const card = studyInsightsStore.getQuestionCardById(cardId)
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]

  return cardStructure.subjects.map(subject => {
    return {
      id: subject.id,
      title: subject.text,
      isForwarded: Boolean(subject.mask),
      children: createOptionRows({
        cardId,
        groups: [
          ...card.question.optionGroups,
          ...cardStructure.options
            .filter(isStaticOption)
            .map(option => ({ text: option.text, ids: [option.id] })),
        ],
        options: cardStructure.options,
        answerIdToAnswer: pick(subject.answerIds, resultCard.answerIdToAnswer),
        subject,
      }),
    }
  })
}

function getChoiceQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const card = studyInsightsStore.getQuestionCardById(cardId)
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]

  return [
    {
      id: cardId,
      title: undefined,
      isForwarded: false,
      children: createOptionRows({
        cardId,
        groups: card.question.optionGroups,
        options: cardStructure.options,
        answerIdToAnswer: resultCard.answerIdToAnswer,
      }),
    },
  ]
}

function getNpsQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]

  function createNpsGroupedRow(data: { id: string; title: string; values: string[] }): RowChild {
    const options = cardStructure.options.filter(option => data.values.includes(option.text))
    return {
      id: data.id,
      title: data.title,
      isQualifying: false,
      isForwarded: false,
      respondentIds: union(
        options.flatMap(option =>
          option.answerIds.map(id => resultCard.answerIdToAnswer[id].responseIds),
        ),
      ),
    }
  }

  return [
    {
      id: cardId,
      title: undefined,
      isForwarded: false,
      children: [
        {
          id: 'nps',
          title: 'NPS',
          isForwarded: false,
          respondentIds: new Set(),
          isQualifying: false,
          customRowType: 'nps',
        },
        createNpsGroupedRow({ id: 'promoters', title: 'Promoters (9-10)', values: ['9', '10'] }),
        createNpsGroupedRow({ id: 'passives', title: 'Passives (7-8)', values: ['7', '8'] }),
        createNpsGroupedRow({
          id: 'detractors',
          title: 'Detractors (0-6)',
          values: ['0', '1', '2', '3', '4', '5', '6'],
        }),
        ...cardStructure.options.filter(isStaticOption).map(option =>
          createNpsGroupedRow({
            id: option.id,
            title: option.text,
            values: [option.id],
          }),
        ),
      ],
    },
  ]
}

function getRankedQuestionRows(cardId: string): Row[] {
  const studyInsightsStore = useStudyInsightsStore()
  const cardStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]
  const options = cardStructure.options.filter(isNonStaticOption)
  const staticOptions = cardStructure.options.filter(isStaticOption)
  const rows = options.map(option => ({
    id: option.id,
    title: option.text,
    isForwarded: false,
    children: Array.from({ length: options.length }, (_, index) => ({
      id: `${index + 1}`,
      title: formatOrdinal(index + 1),
      respondentIds: new Set(
        option.answerIds
          .map(id => resultCard.answerIdToAnswer[id])
          .filter(isStudyResultsOrderedAnswer)
          .flatMap(answer =>
            Object.entries(answer.responseIdToResponse)
              .filter(([_1, response]) => response.order === index + 1)
              .map(([id]) => id),
          ),
      ),
      isQualifying: false,
      isForwarded: false,
      routingTargetCardId: option.next,
    })),
  }))

  return [
    ...(sortOrder.value === 'draft'
      ? rows
      : rows.toSorted((a, b) => {
          const aResponses = a.children.map(({ respondentIds }) => respondentIds.size)
          const bResponses = b.children.map(({ respondentIds }) => respondentIds.size)
          return (
            calculateRankedValueAverages(sum(aResponses), aResponses) -
            calculateRankedValueAverages(sum(bResponses), bResponses)
          )
        })),
    ...staticOptions.map(staticOption => ({
      id: staticOption.id,
      title: '',
      isForwarded: false,
      children: [
        {
          id: staticOption.id,
          title: staticOption.text,
          respondentIds: union(
            options.flatMap(option =>
              option.answerIds
                .map(id => resultCard.answerIdToAnswer[id])
                .filter(isNotNil)
                .map(({ responseIds }) => responseIds),
            ),
          ),
          isQualifying: false,
          isForwarded: false,
        },
      ],
    })),
  ]
}

function createOptionRows(data: {
  cardId: string
  groups: StudyQuestionGroup[]
  subject?: Subject
  options: readonly Option[]
  answerIdToAnswer: Record<string, StudyResultsAnswer>
}): RowChild[] {
  const children = data.groups.flatMap(group => {
    const options = group.ids
      .map(groupId => data.options.find(option => option.id === groupId))
      .filter(isNotNil)

    if (options.length === 0) return []

    return [
      {
        id: group.ids.join(','),
        isQualifying: options.every(({ isQualifying }) => isQualifying),
        isForwarded: options.every(({ mask }) => mask),
        routingTargetCardId:
          options.every(o => o.next) && new Set(options.map(o => o.next)).size === 1
            ? options.find(o => o.next)?.next
            : undefined,
        title: getGroupTitle(data.cardId, group),
        respondentIds: union(
          options.flatMap(option =>
            option.answerIds
              .map(id => data.answerIdToAnswer[id])
              .filter(isNotNil)
              .map(({ responseIds }) => responseIds),
          ),
        ),
        forwardedRespondentIds: union(
          options.flatMap(option => {
            if (data.subject?.mask || option.mask) {
              if (option.mask) {
                return useStudyInsightsStore().cardIdToResultsCard[option.mask.cardId]
                  .answerIdToAnswer[option.mask.answerId].responseIds
              }
              if (data.subject && data.subject.mask) {
                return useStudyInsightsStore().cardIdToResultsCard[data.subject.mask.cardId]
                  .answerIdToAnswer[data.subject.mask.answerId].responseIds
              }
            }
            return []
          }),
        ),
      },
    ]
  })

  const studyCard = useStudyInsightsStore().idToCard[data.cardId]
  const answerIdsInDraftOrder = [
    ...(isStudyQuestionCard(studyCard)
      ? isGridTemplateAnswers(studyCard.templateAnswers)
        ? (studyCard.templateAnswers.options.headers?.map(header => header.id) ?? [])
        : (studyCard.templateAnswers?.map(answer => answer.id) ?? [])
      : []
    )?.filter(id => !['none', 'na', 'other'].includes(id)),
    'none',
    'na',
    'other',
  ]

  return sortOrder.value === 'draft'
    ? children.toSorted(
        (a, b) => answerIdsInDraftOrder.indexOf(a.id) - answerIdsInDraftOrder.indexOf(b.id),
      )
    : children.toSorted((a, b) => {
        if (calculateByForwarded.value && (a.isForwarded || b.isForwarded)) {
          return b.forwardedRespondentIds.size - a.forwardedRespondentIds.size
        }
        return b.respondentIds.size - a.respondentIds.size
      })
}

function getGroupTitle(cardId: string, group: StudyQuestionGroup): string {
  const studyInsightsStore = useStudyInsightsStore()
  const mergedStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)

  return (
    group.text ??
    group.ids
      .map(id => mergedStructure.options.find(option => option.id === id))
      .filter(isNotNil)
      .map(({ text }) => text)
      .join(', ')
  )
}
