import { findAndRemoveInPlace } from './array'

type QueueFunction = (next: (...args: unknown[]) => void) => void

type QueueType = 'MANUAL' | 'AUTOMATIC'

export class Queue {
  private pending: QueueFunction[]
  private queueType: QueueType
  private onCompleteEvents: (() => void)[]

  constructor(queueType: QueueType = 'AUTOMATIC') {
    this.queueType = queueType
    this.pending = []
    this.onCompleteEvents = []
  }

  push(fn: QueueFunction): Queue {
    this.pending.push(fn)

    if (this.queueType === 'AUTOMATIC' && this.pending.length === 1) {
      this.flush()
    }

    return this
  }

  size(): number {
    return this.pending.length
  }

  empty(): Queue {
    this.pending.length = 0
    return this
  }

  flush(): Queue {
    if (this.pending.length <= 0) {
      this.onCompleteEvents.forEach(method => method())
      return this
    }

    const fn = this.pending[0]
    if (!fn) return this

    fn(() => {
      findAndRemoveInPlace(this.pending, pendingFn => pendingFn === fn)
      this.flush()
    })

    return this
  }

  onComplete(method: () => void): () => Queue {
    this.onCompleteEvents.push(method)

    return () => {
      const index = this.onCompleteEvents.indexOf(method)
      this.onCompleteEvents.splice(index, 1)
      return this
    }
  }
}
