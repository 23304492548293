export function frameThrottle<T extends any[], R>(fn: (...args: T) => R): typeof fn {
  let lastResult: R
  let isReadyForNextCall = true

  return function (...args: T): R {
    if (!isReadyForNextCall) return lastResult

    lastResult = fn(...args)
    isReadyForNextCall = false
    window.requestAnimationFrame(() => (isReadyForNextCall = true))
    return lastResult
  }
}
