import type { RouteRecordRaw } from 'vue-router'

import { RESULTS_VIDEO_RESPONSES_ROUTE_NAME } from '@attest/router'
import { createStudyEntityWithType } from '@attest/study'
import { createQuestionEntity, useGetTrackingEntities } from '@attest/tracking'

export function createResultsVideoResponsesRoute(): RouteRecordRaw {
  return {
    name: RESULTS_VIDEO_RESPONSES_ROUTE_NAME,
    path: `video-responses/:cardId`,
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
      ...useGetTrackingEntities(route => [
        createStudyEntityWithType({ id: route.params.studyId as string }),
        createQuestionEntity({ id: route.params.cardId as string }),
      ]),
    },

    components: {
      default: async () => await import('./page/VideoResponses.vue'),
    },
    props: {
      default(route): { studyId: string; cardId: string } {
        return { studyId: route.params.studyId as string, cardId: route.params.cardId as string }
      },
    },
  }
}
