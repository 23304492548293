import { FilterBlock, FilterExpression } from '@attest/efl-antlr4'

export type FilterNode = FilterBlock['items'][number]

export type ArrayValue = number[] | string[] | boolean[] | null[]
export type PrimitiveValue = number | string | boolean | null
export type Value = ArrayValue | PrimitiveValue

// To support new array operations syntax
// can later be imported from main package
export enum ArrayOperator {
  'ANY' = 'ANY',
  'ALL' = 'ALL',
  'NONE' = 'NONE',
}

export function isFilterExpression(item: unknown): item is FilterExpression {
  return item instanceof FilterExpression
}

export function isFilterBlock(item: unknown): item is FilterBlock {
  return item instanceof FilterBlock
}

export function isEmptyFilterBlock(item: unknown): boolean {
  return isFilterBlock(item) && item.items.length === 0
}
