export const translationKey = ({
  language,
  country,
}: {
  language: string
  country: string
}): string => `${country}_${language}`

export const parseTranslationKey = (
  key: string,
): { language: string; country: string } | undefined => {
  const [country, language] = key.split('_')
  if (!language || !country) {
    return undefined
  }
  return {
    language,
    country,
  }
}
