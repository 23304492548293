import { createEntity, type Entity } from './base'

export type WaveEntity<D extends WaveEntityData = WaveEntityData> = {} & Entity<D>
type WaveEntityData = {
  id: string
}

export function createWaveEntity({ id }: WaveEntityData): WaveEntity {
  return createEntity({ name: 'wave', version: '1-0-0' }, { id })
}
