import { isAppError } from '@attest/_api'
import type { FeasibilityArgs, FeasibilityError } from '@attest/_api/model/feasibility'
import { capture } from '@attest/telemetry'

import type { AudienceEditorExclusiveSample } from '../exclusive-sample'
import type { Audience, AudienceType } from '../model'
import { toApiQuotas } from '../quota'
import { toApiSegmentations } from '../segmentations'

import { feasibility, feasibilityPublic } from './service'

export async function fetchAvailableSampleSize(args: {
  audience: Audience
  isAuthenticated: boolean
  audienceType: AudienceType
  exclusiveSamples: AudienceEditorExclusiveSample[]
  surveyGuid: string | undefined
  startTimestamp: number | undefined
  totalQuestions: number
  collectsVideoResponses: boolean
  countOfQualifyingQuestions: number
}): Promise<number> {
  const response = await (args.isAuthenticated ? feasibility : feasibilityPublic)(
    createFeasibilityRequest(args),
  )

  if (!isAppError<FeasibilityError>(response)) return response

  capture(new Error(`Error when fetching available sample size (reason: ${response.generalError})`))
  return 0
}

export function createFeasibilityRequest({
  audience,
  audienceType,
  exclusiveSamples,
  surveyGuid,
  startTimestamp,
  totalQuestions,
  collectsVideoResponses,
  countOfQualifyingQuestions,
}: {
  audience: Audience
  audienceType: AudienceType
  exclusiveSamples: AudienceEditorExclusiveSample[]
  surveyGuid: string | undefined
  startTimestamp: number | undefined
  totalQuestions: number
  collectsVideoResponses: boolean
  countOfQualifyingQuestions: number
}): FeasibilityArgs {
  return {
    country: audience.country,
    language: audience.language,
    quotas: toApiQuotas(audience.quotas),
    exclusive_sample_guids: audienceType === 'own' ? [] : exclusiveSamples.map(({ guid }) => guid),
    qualifications: toApiSegmentations(audience.segmentations),
    qualifying_questions: countOfQualifyingQuestions,
    survey_guid: surveyGuid,
    start_date: startTimestamp ?? null,
    total_questions: totalQuestions,
    collects_video_responses: collectsVideoResponses,
  }
}
