import type {
  CardRoutingAnswerError,
  CardRoutingError,
} from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import {
  type Card,
  isQuestionCard,
  isQuestionCardOrUninitialized,
  type OptionAnswer,
} from '@attest/editor-card'
import { type EdgeInformation, getRootNodes, type RoutingGraph } from '@attest/editor-routing-graph'

import { hasImage } from '../card-service'

export const getCardRoutingErrors = createGetErrorsFor<
  CardRoutingError['type'],
  {
    card: Card
    orderedCards: Card[]
    routingGraph: RoutingGraph
    edgesThatCauseACycle: EdgeInformation[]
    randomizedGroupGraph: RoutingGraph
  }
>({
  CARD_ROUTING_IS_CYCLIC({ card, edgesThatCauseACycle }) {
    return edgesThatCauseACycle.some(
      edge => edge.sourceCardGuid === card.guid && !edge.answerRouteEdge,
    )
  },

  CARD_ROUTING_NOT_REACHABLE({ card, routingGraph }) {
    return getRootNodes(routingGraph).slice(1).includes(card.guid)
  },

  CARD_ROUTING_TOO_MANY_ROUTES_ON_MULTIPLE_CHOICE_QUESTION({ card }) {
    if (!isQuestionCard(card) || card.question.type !== 'multiple_choice') return false
    const routedToGuids = card.question.options.answers.flatMap(({ nextGuid }) =>
      !nextGuid ? [] : [nextGuid],
    )
    const uniqueRoutedToGuids = new Set(routedToGuids)

    return uniqueRoutedToGuids.size > 1
  },

  CARD_ROUTING_TO_OTHER_THAN_FIRST_RANDOMISED_CARD({ card, randomizedGroupGraph }) {
    if (!isQuestionCard(card) || card.isRandomized || !card.nextGuid) return false
    if (randomizedGroupGraph.hasNode(card.guid)) return false

    return (
      randomizedGroupGraph.hasNode(card.nextGuid) &&
      randomizedGroupGraph.inEdges(card.nextGuid).length > 0
    )
  },

  CARD_DISCONNECTED_FROM_MAIN_RANDOMISATION_GROUP({ card, randomizedGroupGraph }) {
    if (!isQuestionCard(card) || !card.isRandomized) return false

    return !randomizedGroupGraph.hasNode(card.guid)
  },
})

export const getCardRoutingAnswerErrors = createGetErrorsFor<
  CardRoutingAnswerError['type'],
  {
    card: Card
    orderedCards: Card[]
    randomizedGroupGraph: RoutingGraph
    edgesThatCauseACycle: EdgeInformation[]
    routingGraph: RoutingGraph
    answer: OptionAnswer
  }
>({
  CARD_ROUTING_ANSWER_AND_RANDOMIZED_QUESTION({ card, answer }) {
    return card.isRandomized && isQuestionCard(card) && answer.nextGuid !== null
  },

  CARD_ROUTING_ANSWER_IS_CYCLIC({ card, answer, edgesThatCauseACycle }) {
    return edgesThatCauseACycle.some(
      edge => edge.sourceCardGuid === card.guid && edge.targetCardGuid === answer.nextGuid,
    )
  },

  CARD_ROUTING_ANSWER_IS_EMPTY({ card, answer }) {
    if (answer.nextGuid === null) return false
    if (!isQuestionCard(card)) return false
    if (card.question.options.isImage) return !hasImage(answer)

    return answer.text.length <= 0
  },

  CARD_ROUTING_ANSWER_FROM_QUESTION_BETWEEN_RANDOMIZED_CARDS({
    card,
    randomizedGroupGraph,
    answer,
  }) {
    if (!isQuestionCard(card) || answer.nextGuid === null) return false

    return !card.isRandomized && randomizedGroupGraph.hasNode(card.guid)
  },

  CARD_ROUTING_ANSWER_TO_OTHER_THAN_FIRST_RANDOMISED_CARD({ card, randomizedGroupGraph, answer }) {
    if (!isQuestionCardOrUninitialized(card) || !answer.nextGuid) return false

    return (
      randomizedGroupGraph.hasNode(answer.nextGuid) &&
      randomizedGroupGraph.inEdges(answer.nextGuid).length > 0
    )
  },
})
