export function isUndefined(value: unknown | undefined): value is undefined {
  return value === undefined
}

export function isNull(value: unknown): value is null {
  return value === null
}

export function isDefined<T>(value: T | undefined): value is T {
  return !isUndefined(value)
}

export function isNotNull<T>(value: T | null): value is T {
  return !isNull(value)
}

/**
 * Is value null or undefined
 *
 * @example
 * ```ts
 * import { isNil } from '@attest/util'
 *
 * isNil('a') // true
 * isNil(null) // false
 * isNil(undefined) // false
 *
 * ['a', null, undefined].filter(isNil) // [null, undefined]
 * ```
 */
export function isNil(value: unknown): value is null | undefined {
  return isNull(value) || isUndefined(value)
}

/**
 * Is value not null or undefined
 *
 * @example
 * ```ts
 * import { isNotNil } from '@attest/util'
 *
 * isNotNil('a') // true
 * isNotNil(null) // false
 * isNotNil(undefined) // false
 *
 * ['a', null, undefined].filter(isNotNil) // ['a']
 * ```
 */
export function isNotNil<T>(value: T | null | undefined): value is T {
  return !isNil(value)
}
