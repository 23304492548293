export function isLocalStorageAvailable(): boolean {
  const mod = 'attest-storage-check-magic-9527e38f'
  try {
    window.localStorage.setItem(mod, mod)
    window.localStorage.removeItem(mod)
    return true
  } catch {
    return false
  }
}
