import { defineStore } from 'pinia'
import { equals, isNil, mathMod } from 'ramda'

import { type ApiError, type InternalError, isAppError } from '@attest/_api'
import { updateAnswerQuotas } from '@attest/survey'
import { eqUnordered, isDefined } from '@attest/util'

import { getStudyStructure, updateQualifyingAnswers } from '../api'
import { purchaseStudyDraft } from '../api/purchase-study-draft'
import { saveStudyResultsCardAnswerGroups } from '../api/save-study-results-card-answer-groups'
import { transformQuotasToPutAnswerQuotasRequestData } from '../api/transformer'
import {
  assertIsStudyQuestionCard,
  isStaticOption,
  isStudyQuestionCard,
  type StudyCard,
  type StudyQuestionCard,
  type StudyQuestionGroup,
  type StudyQuestionStructure,
} from '../card'
import { isStudy, type Study, type StudyStructure } from '../model'
import type { StudyRecurrence } from '../recurrence'
import {
  type DraftWaveSurvey,
  filterSentWaveSurveys,
  isDraftWaveSurvey,
  type SentWaveSurvey,
  sortWavesByAscendingStartTime,
  type WaveSurvey,
} from '../wave'

export type StudyState = {
  study: Study | null
  cardIdToCard: Record<string, StudyCard>
  waveSurveyIdToWaveSurvey: Record<string, WaveSurvey>
  waveAudienceIdToWaveSurvey: Record<string, WaveSurvey>
}

export const STUDY_NAMESPACE = 'study'

export function createStudyState(override: Partial<StudyState> = {}): StudyState {
  return {
    study: null,
    waveSurveyIdToWaveSurvey: {},
    waveAudienceIdToWaveSurvey: {},
    cardIdToCard: {},
    ...override,
  }
}

export const useStudyStore = defineStore(STUDY_NAMESPACE, {
  state: createStudyState,
  actions: {
    setStudy(study: Study): void {
      this.study = study
    },

    patchStudy(override: Partial<Study>): void {
      if (!this.study) throw new Error('Cannot patch a study that is not set')
      Object.assign(this.study, override)
    },

    setWaveSurveyIdToWaveSurvey(waveSurveyIdToWaveSurvey: Record<string, WaveSurvey>): void {
      this.waveSurveyIdToWaveSurvey = waveSurveyIdToWaveSurvey
    },

    setWaveAudienceIdToWaveSurvey(waveAudienceIdToWaveSurvey: Record<string, WaveSurvey>): void {
      this.waveAudienceIdToWaveSurvey = waveAudienceIdToWaveSurvey
    },

    setCardIdToCard(cardIdToCard: Record<string, StudyCard>): void {
      this.cardIdToCard = cardIdToCard
    },

    updateWaveSurvey({
      id,
      override,
    }: {
      id: string
      override: Partial<Omit<WaveSurvey, 'id'>>
    }): void {
      this.waveSurveyIdToWaveSurvey[id] = {
        ...this.waveSurveyIdToWaveSurvey[id],
        ...override,
      } as any
    },

    async getStudy({
      id,
      force = false,
    }: {
      id?: string
      force?: boolean
    }): Promise<StudyStructure | InternalError<ApiError> | void> {
      const studyId = id ?? this.study?.id
      if (!studyId) return
      if (!force && studyId === this.study?.id) return
      const response = await getStudyStructure({ id: studyId })
      if (isAppError(response)) return response

      this.setStudy(response.study)
      this.setWaveSurveyIdToWaveSurvey(response.waveSurveyIdToWaveSurvey)
      this.setWaveAudienceIdToWaveSurvey(response.waveAudienceIdToWaveSurvey)
      this.setCardIdToCard(response.cardIdToCard)
      return response
    },

    setRecurrence(payload: { id: string; recurrence: StudyRecurrence | null }) {
      if (!this.isStudy(payload.id)) return
      this.patchStudy({ id: payload.id, recurrence: payload.recurrence })
    },

    async purchaseNewWave({
      id,
      creditPotGuid,
      recurrence,
      timeZoneId,
    }: {
      id: string
      creditPotGuid?: string
      recurrence?: {
        interval: number
        recurrenceInterval: 'DAYS' | 'MONTHS'
        hasFixedInterval: boolean
      } | null
      timeZoneId: string
    }): ReturnType<typeof purchaseStudyDraft> {
      const response = await purchaseStudyDraft({
        id,
        creditPotGuid,
        recurrence,
        timeZoneId,
      })

      if (!isAppError(response)) {
        await this.getStudy({ id, force: true })
        return response
      }

      return response
    },

    setOptionGroups({ cardId, groups }: { cardId: string; groups: StudyQuestionGroup[] }): void {
      const card = this.cardIdToCard[cardId]
      assertIsStudyQuestionCard(card)
      card.question.optionGroups = groups
    },

    async saveMergedOptionGroups({
      cardId,
      groups,
      text,
    }: {
      cardId: string
      groups: StudyQuestionGroup[]
      text: string | undefined
    }): Promise<void> {
      const currentGroups = this.getOptionGroups(cardId)
      this.mergeOptionGroups({ cardId, groups, text })

      if (!this.userCanEditAnswerGroup) return

      const response = await this.saveOptionGroups(cardId)

      if (isAppError(response)) {
        this.setOptionGroups({ cardId, groups: currentGroups })
        throw response
      }
    },

    mergeOptionGroups({
      cardId,
      groups,
      text,
    }: {
      cardId: string
      groups: StudyQuestionGroup[]
      text: string | undefined
    }): void {
      const card = this.cardIdToCard[cardId]
      assertIsStudyQuestionCard(card)

      const flattenedGroupIds = new Set(groups.flatMap(g => g.ids))

      card.question.optionGroups = card.question.optionGroups.flatMap(group => {
        if (!groups[0]) return group
        if (eqUnordered(group.ids, groups[0].ids)) {
          return [{ ...groups[0], ids: groups.flatMap(({ ids }) => ids), text }]
        }
        return group.ids.length === 1 && flattenedGroupIds.has(group.ids[0] ?? '') ? [] : [group]
      })
    },

    async saveOptionGroups(cardId: string): Promise<null | InternalError<ApiError>> {
      if (!this.study) throw new ReferenceError('Study does not exist')

      const card = this.cardIdToCard[cardId]
      assertIsStudyQuestionCard(card)

      const groups = card.question.optionGroups

      return await saveStudyResultsCardAnswerGroups({
        studyGuid: this.study.id,
        cardId,
        groups,
      })
    },

    updateOptionGroup({
      cardId,
      group,
      override,
    }: {
      cardId: string
      group: StudyQuestionGroup
      override: Partial<StudyQuestionGroup>
    }): void {
      const card = this.cardIdToCard[cardId]
      assertIsStudyQuestionCard(card)

      const foundGroup = card.question.optionGroups?.find(equals(group))
      if (!foundGroup) throw new ReferenceError('Unable to find group in card')
      Object.assign(foundGroup, override)
    },

    async saveUnmergedOptionGroup({
      cardId,
      group,
    }: {
      cardId: string
      group: StudyQuestionGroup
    }): Promise<void> {
      const currentGroups = this.getOptionGroups(cardId)
      this.unmergeOptionGroup({ cardId, group })

      if (!this.userCanEditAnswerGroup) return

      const response = await this.saveOptionGroups(cardId)

      if (isAppError(response)) {
        this.setOptionGroups({ cardId, groups: currentGroups })
        throw response
      }
    },

    unmergeOptionGroup({ cardId, group }: { cardId: string; group: StudyQuestionGroup }): void {
      const card = this.cardIdToCard[cardId]
      assertIsStudyQuestionCard(card)

      card.question.optionGroups = card.question.optionGroups.flatMap(optionGroup => {
        return eqUnordered(optionGroup.ids, group.ids)
          ? optionGroup.ids.map(id => ({ text: undefined, ids: [id] }))
          : [optionGroup]
      })
    },

    async saveUpdatedOptionGroup({
      cardId,
      group,
      override,
    }: {
      cardId: string
      group: StudyQuestionGroup
      override: Partial<StudyQuestionGroup>
    }): Promise<void> {
      const currentGroups = this.getOptionGroups(cardId)
      this.updateOptionGroup({ cardId, group, override })

      if (!this.userCanEditAnswerGroup) return

      const response = await this.saveOptionGroups(cardId)

      if (isAppError(response)) {
        this.setOptionGroups({ cardId, groups: currentGroups })
        throw response
      }
    },

    async updateQualifyingAnswers({
      guid,
      questionId,
      qualifyingAnswerIds,
    }: {
      guid: string
      questionId: string
      qualifyingAnswerIds: string[]
    }): Promise<InternalError<ApiError> | void> {
      return await updateQualifyingAnswers({
        surveyGuid: guid,
        questionId,
        requestData: { value: qualifyingAnswerIds },
      })
    },

    async updateAnswerQuotas({
      guid,
      quotas,
    }: {
      guid: string
      quotas: { questionId: string; answers: { id: string; minTarget: number }[] }[]
    }): Promise<InternalError<ApiError> | void> {
      const qualifyingQuestions = this.cards.filter(isStudyQuestionCard).filter(card => {
        const structure = this.getWaveQuestionStructure({
          waveSurveyId: guid,
          questionId: card.id,
        })
        return structure.options.some(({ isQualifying }) => isQualifying)
      })

      const quotasQuestionIds = new Set(quotas.map(({ questionId }) => questionId))

      return await updateAnswerQuotas({
        guid,
        requestData: transformQuotasToPutAnswerQuotasRequestData([
          ...quotas,
          ...qualifyingQuestions
            .filter(({ question }) => !quotasQuestionIds.has(question.id))
            .map(({ question }) => {
              const structure = question.structures.find(({ waveId }) => waveId === guid)
              if (!structure) throw new Error(`Cannot find structure for question`)
              return {
                questionId: question.id,
                answers: structure.options
                  .filter(option => !isStaticOption(option) && isDefined(option.minTarget))
                  .map(({ id, minTarget }) => {
                    if (isNil(minTarget)) throw new Error('Option should have minTarget')
                    return { id, minTarget }
                  }),
              }
            }),
        ]),
      })
    },
  },

  getters: {
    isStudy(): (id: string) => boolean {
      return id => isStudy(this.study) && this.study.id === id
    },

    waveSurveys(): WaveSurvey[] {
      return Object.values(this.waveSurveyIdToWaveSurvey)
    },

    cards(): StudyCard[] {
      return Object.values(this.cardIdToCard)
    },

    sentWaveSurveys(): SentWaveSurvey[] {
      return filterSentWaveSurveys(this.waveSurveys).filter(s => s.status !== 'archived')
    },

    sentWaveSurveysIncludingArchived(): SentWaveSurvey[] {
      return filterSentWaveSurveys(this.waveSurveys)
    },

    draftWaveSurveys(): DraftWaveSurvey[] {
      return this.waveSurveys.filter(isDraftWaveSurvey)
    },

    getSentWaveSurveyAt(): (index: number) => SentWaveSurvey | undefined {
      return index => {
        const sentWaveSurveys = this.sentWaveSurveys
        return sortWavesByAscendingStartTime(sentWaveSurveys)[
          mathMod(index, sentWaveSurveys.length)
        ]
      }
    },

    getWaveSurveyById(): (id: string) => WaveSurvey | undefined {
      return id => this.waveSurveyIdToWaveSurvey[id]
    },

    getWaveSurveyByAudienceId(): (id: string) => WaveSurvey | undefined {
      return id => this.waveAudienceIdToWaveSurvey[id]
    },

    latestPublishedWave(): SentWaveSurvey[] {
      const latestWave = this.getSentWaveSurveyAt(-1)
      if (!latestWave) return []
      return this.sentWaveSurveys.filter(
        ({ publishedTimestamp }) => publishedTimestamp === latestWave.publishedTimestamp,
      )
    },

    waveQuestionCards(): (waveSurveyId: string) => StudyQuestionCard[] {
      return waveSurveyId =>
        this.cards
          .filter(isStudyQuestionCard)
          .filter(studyCard =>
            studyCard.question.structures.some(({ waveId }) => waveId === waveSurveyId),
          )
    },

    canSigTestWave(): (waveSurvey: WaveSurvey) => boolean {
      return waveSurvey =>
        waveSurvey.responses >= 1 &&
        this.cards.some(
          card =>
            isStudyQuestionCard(card) &&
            (card.question.type === 'choice' || card.question.type === 'nps'),
        )
    },

    canSigTestWaveWithId(): (waveSurveyId: string) => boolean {
      return waveSurveyId => {
        const waveSurvey = this.getWaveSurveyById(waveSurveyId)
        return !!waveSurvey && this.canSigTestWave(waveSurvey)
      }
    },

    getOptionGroups(): (cardId: string) => StudyQuestionGroup[] {
      return cardId => {
        const card = this.cardIdToCard[cardId]
        assertIsStudyQuestionCard(card)
        return card.question.optionGroups
      }
    },

    isOwnStudy(): boolean {
      return this.study?.viewership === 'own'
    },

    userCanEditAnswerGroup(): boolean {
      return this.isOwnStudy || this.study?.viewership === 'team'
    },

    isVideoResponseSurvey(): boolean {
      return !!this.sentWaveSurveys[0]?.collectsVideoResponses
    },

    getWaveQuestionStructure(): (payload: {
      questionId: string
      waveSurveyId: string
    }) => StudyQuestionStructure {
      return ({ questionId, waveSurveyId }) => {
        const questionCard = this.cardIdToCard[questionId]
        assertIsStudyQuestionCard(questionCard)
        const structure = questionCard.question.structures.find(
          ({ waveId }) => waveId === waveSurveyId,
        )
        if (!structure) throw new ReferenceError(`Cannot find question structure for question card`)
        return structure
      }
    },
  },
})
