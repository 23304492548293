import type { RouteLocation, Router } from 'vue-router'

import { ERROR_MESSAGES_GENERAL } from '@attest/_constant'
import { useAppErrorStore } from '@attest/app-error'
import { HttpError } from '@attest/server/error'
import { useUserStore } from '@attest/user'
import type { PermissionType } from '@attest/user-permission'

const routePermissionTest = (route: Pick<RouteLocation, 'meta'>): boolean => {
  const permissionRequired = route.meta?.permissionRequired as PermissionType[] | undefined
  if (permissionRequired && permissionRequired.length > 0) {
    return useUserStore().hasPermission(permissionRequired)
  }
  return true
}

export async function setupPermission(router: Router): Promise<void> {
  await router.isReady()
  if (!routePermissionTest(router.currentRoute.value)) {
    useAppErrorStore().set(new HttpError(ERROR_MESSAGES_GENERAL.NOT_FOUND, 404))
  }

  router.beforeEach((to, _, next) => {
    if (routePermissionTest(to)) {
      next()
    } else {
      useAppErrorStore().set(new HttpError(ERROR_MESSAGES_GENERAL.NOT_FOUND, 404))
    }
  })
}
