import { createEntity, type Entity } from './base'

export type QuestionEntity<D extends QuestionEntityData = QuestionEntityData> = {} & Entity<D>
type QuestionEntityData = {
  id: string
  type?: string | null
}

export function createQuestionEntity({ id, type }: QuestionEntityData): QuestionEntity {
  const payload: QuestionEntityData = { id }
  if (type !== undefined) {
    payload.type = type
  }

  return createEntity({ name: 'question', version: '1-0-1' }, payload)
}
