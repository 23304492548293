import { getActivePinia } from 'pinia'
import type { Router } from 'vue-router'

import type { Config } from '@attest/config'

import { TrackingManager } from './tracking-manager'

export const trackingManager = new TrackingManager()

export * from './property'
export * from './event/index'
export * from './entity'

export async function init(router: Router, config: Config) {
  const gtm = getGTM(router, config)

  trackingManager.add(gtm)
  if (!getActivePinia()?.state.value.user?.isImpersonate) {
    trackingManager.add(getClickstream(router, config))
    trackingManager.add(getChameleon(router, config))
  }
}

function getGTM(router: Router, config: Config) {
  return import('./tool/gtm/gtm').then(({ Gtm }) => new Gtm(router, config))
}

function getClickstream(router: Router, config: Config) {
  return import('./tool/clickstream').then(({ Clickstream }) => new Clickstream(router, config))
}

function getChameleon(router: Router, config: Config) {
  return import('./tool/chameleon').then(({ Chameleon }) => new Chameleon(router, config))
}
