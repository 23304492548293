<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { type HeadingProps, HeadingTypeTagMap } from './'

const Heading: FunctionalComponent<HeadingProps> = (props, { slots }) => {
  const { type, elementTag, align = 'left', theme = 'default', isBold = false, ...attrs } = props

  const HeadingTag = elementTag || HeadingTypeTagMap[type]
  const baseClass = 'c-heading'

  const newType = (() => {
    if (type === 'display-large' || type === 'display-extra-large') return 'u-attest-heading'
    if (type === 'display') return 'u-attest-heading--medium'
    if (type === 'display-small') return 'u-attest-heading--small'
    if (type === 'body-large' || type === 'body') return 'u-attest-body'
    if (type === 'tiny' || type === 'caption') return 'u-attest-body--small'
    return type
  })()

  const HeadingClass = {
    [`${baseClass}`]: true,
    [newType]: true,
    [`${baseClass}--align-${align}`]: true,
    [`${baseClass}--is-bold`]: isBold,
    [`${baseClass}--theme-${theme}`]: true,
  }

  return (
    <HeadingTag class={HeadingClass} {...attrs}>
      {slots.default?.()}
    </HeadingTag>
  )
}

export default Heading
</script>

<style lang="postcss">
.c-heading {
  margin: 0;

  &--is-bold {
    font-weight: var(--attest-font-weight-bold);
  }

  &:not(&--is-bold) {
    font-weight: var(--attest-font-weight-normal);
  }

  &--align-left {
    text-align: left;
  }

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }

  &--theme-dark {
    color: var(--attest-color-background-inverse);
  }

  &--theme-inverse {
    color: var(--attest-color-text-on-interactive);
  }

  &--theme-light {
    color: var(--attest-color-text-subdued);
  }

  &--theme-error {
    color: var(--attest-color-interactive-negative-default);
  }
}
</style>
