<script lang="tsx">
import { defineComponent } from 'vue'

import { Banner } from '@attest/_components/src/common/Banner'
import { FormButton } from '@attest/_components/src/common/FormButton'
import { COPY } from '@attest/_copy'
import { useUserStore } from '@attest/user'
import { EMPTY_VNODE } from '@attest/vue-tsx'

type ImpersonateBannerState = {
  position: 'top' | 'bottom'
}

export default defineComponent({
  data(): ImpersonateBannerState {
    return { position: 'top' }
  },

  computed: {
    email(): string {
      return useUserStore().email || ''
    },

    isImpersonated(): boolean {
      return !!useUserStore().isImpersonate
    },
  },

  methods: {
    async logout(e: Event) {
      e.preventDefault()
      e.stopPropagation()
      await useUserStore().logout()
      window.location.assign('/')
    },
  },

  render() {
    const baseClass = 'c-impersonate-banner'

    return this.isImpersonated ? (
      <Banner
        class={[baseClass, `${baseClass}--position-${this.position}`]}
        styleVariant="error"
        data-name="ImpersonateBanner"
        onClick={() => (this.position = this.position === 'top' ? 'bottom' : 'top')}
      >
        {COPY.IMPERSONATE.DESCRIPTION(this.email)}
        <FormButton
          variant="error"
          onClick={this.logout}
          class={`${baseClass}__logout`}
          data-name="ImpersonateBannerLogout"
        >
          {COPY.NAVIGATION.LOGOUT}
        </FormButton>
      </Banner>
    ) : (
      EMPTY_VNODE
    )
  },
})
</script>

<style lang="postcss">
.c-impersonate-banner {
  position: fixed;
  z-index: var(--z-index-app-banner);
  left: 50%;
  display: flex;
  width: 60%;
  max-width: max-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--attest-spacing-2) var(--attest-spacing-4);
  cursor: default;
  opacity: 0.7;
  transform: translateX(-50%);

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }

  &--position-top {
    top: 0;
    border-radius: 0 0 var(--attest-border-radius-l) var(--attest-border-radius-l);
  }

  &--position-bottom {
    bottom: 0;
    border-radius: var(--attest-border-radius-l) var(--attest-border-radius-l) 0 0;
  }

  &__logout {
    margin-left: var(--attest-spacing-4);
    pointer-events: auto;
  }
}
</style>
