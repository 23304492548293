import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import mailSvg from '@attest/_assets/illustrations/mail.svg'
import { c } from '@attest/intl'
import { ROUTES } from '@attest/router'

const LoggedOutMeta = {
  authenticate: false,
  showProgressBar: true,
  authenticationRequired: 'unauthenticated',
} as const

export const routesLoggedOut: readonly RouteRecordRaw[] = [
  {
    name: ROUTES.LOGIN.name,
    path: ROUTES.LOGIN.path,
    meta: LoggedOutMeta,
    props: getLoggedOutProps,
    component: () => import('../../container/logged-out/Login.vue'),
  },
  {
    name: ROUTES.REGISTER.name,
    path: ROUTES.REGISTER.path,
    meta: LoggedOutMeta,
    props: getRegisterProps,
    component: () => import('../../module/authentication/page/Register/Register.vue'),
  },
  {
    name: ROUTES.REGISTER_INVITE_EXPIRED.name,
    path: ROUTES.REGISTER_INVITE_EXPIRED.path,
    meta: LoggedOutMeta,
    props: {
      status: 400,
      message: c('registration.error.invite_code_expired.title'),
      description: c('registration.error.invite_code_expired.description'),
      svg: mailSvg,
    },
    component: () => import('@attest/_components/src/common/Error.vue'),
  },
  {
    name: ROUTES.FORGOT_PASSWORD.name,
    path: ROUTES.FORGOT_PASSWORD.path,
    meta: LoggedOutMeta,
    props: getForgetPasswordProps,
    component: () => import('../../module/authentication/page/ForgotPassword/ForgotPassword.vue'),
  },
  {
    name: ROUTES.RESET_PASSWORD.name,
    path: ROUTES.RESET_PASSWORD.path,
    meta: LoggedOutMeta,
    props: getResetPasswordProps,
    component: () => import('../../module/authentication/page/ResetPassword/ResetPassword.vue'),
  },
]

type LoggedOutQuery = {
  experience?: string
  email?: string
  'invite-code'?: string
  redirect?: string
}

function getLoggedOutProps(route: RouteLocation) {
  const query = route.query as LoggedOutQuery

  return {
    inviteCode: query['invite-code'],
    email: query.email,
    experience: query.experience,
    redirect: query.redirect,
    error: route.params.error,
  }
}

function getForgetPasswordProps(route: RouteLocation) {
  const query = route.query as LoggedOutQuery
  return {
    inviteCode: query['invite-code'],
    redirect: query.redirect,
    experience: query.experience,
  }
}

function getRegisterProps(route: RouteLocation): LoggedOutQuery {
  const loggedOutProps = getLoggedOutProps(route)

  if (loggedOutProps.redirect === '/surveys') {
    loggedOutProps.redirect = undefined
  }

  return loggedOutProps
}

type ResetPasswordQuery = {
  email?: string
  'reset-code'?: string
} & LoggedOutQuery
function getResetPasswordProps(route: RouteLocation) {
  const query = route.query as ResetPasswordQuery
  return {
    email: query.email,
    inviteCode: query['invite-code'],
    resetCode: query['reset-code'],
  }
}
