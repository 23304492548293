import { getTerminatingNodes } from './calculator'
import type { RoutingGraph } from './model'
import { createNodeByPositionSorter } from './sorter'

export function traverseByServingOrderToNode(
  graph: RoutingGraph,
  target: string,
): Generator<string> {
  const visited = new Set()
  function* servingOrderToNodeGenerator(source: string): Generator<string> {
    visited.add(source)
    const neighbors = graph
      .inNeighbors(source)
      .filter(neighbor => !visited.has(neighbor))
      .sort(createNodeByPositionSorter(graph))

    for (const neighbor of neighbors) {
      yield* servingOrderToNodeGenerator(neighbor)
    }
    yield source
  }

  return servingOrderToNodeGenerator(target)
}

export function getNodesByServingOrder(graph: RoutingGraph): Set<string> {
  return new Set(
    getTerminatingNodes(graph).flatMap(target => [...traverseByServingOrderToNode(graph, target)]),
  )
}
