import { getActivePinia } from 'pinia'
import { nextTick } from 'vue'
import type { RouteLocation, Router } from 'vue-router'

import type { Config } from '@attest/config'
import { init as initTrackingManager, trackingManager } from '@attest/tracking'

export async function setupTracking(router: Router, config: Config): Promise<void> {
  initTrackingManager(router, config)

  localStorage.removeItem('snowplowOutQueue_sp1_post2')
  localStorage.removeItem('snowplowOutQueue_sp1_post2.expires')
  localStorage.removeItem('aws-solution/clickstream-web/failedEventsKey')

  await router.isReady()
  trackingManager.trigger({ type: 'Init' })
  trackingManager.trigger({
    type: 'IdentifyUser',
    payload: { guid: getActivePinia()?.state.value.user?.guid },
  })

  nextTick(() => {
    trackingManager.trigger({
      type: 'ViewPage',
      payload: {
        name: router.currentRoute.value.name?.toString() || router.currentRoute.value.path,
      },
    })
  })

  router.afterEach((to: RouteLocation) => {
    nextTick(() => {
      trackingManager.trigger({
        type: 'ViewPage',
        payload: { name: to.name?.toString() || to.path },
      })
    })
  })
}
