import type { RouteLocation, RouteRecordRaw } from 'vue-router'

import { creditCentsToCredit } from '@attest/_lib/src/pricing/pricing'
import { calculateSurveyPrice } from '@attest/_lib/src/pricing/service/calculator'
import { useAudienceEditorStore } from '@attest/audience'
import { useEditorRoutingGraphStore, useEditorSurveyStore } from '@attest/editor-survey'
import type { StudyProps } from '@attest/results-components/src/view/Study'
import { createStudyInsightsOverviewLocation, STUDY_ROUTES, VIEWS } from '@attest/router'
import { createStudyEntityWithType, type StudyNavbarProps } from '@attest/study'
import { useGetTrackingEntities } from '@attest/tracking'
import {
  createSurveyPricingEntity,
  type SurveyPricingEntity,
} from '@attest/tracking/src/entity/survey-pricing'

export function createStudyRoute(): RouteRecordRaw {
  return {
    name: STUDY_ROUTES.ROOT.name,
    path: STUDY_ROUTES.ROOT.path,
    alias: ['/study/:studyId'],
    redirect(to) {
      return createStudyInsightsOverviewLocation({ studyId: to.params.studyId as string })
    },
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
      ...useGetTrackingEntities(route => [
        createStudyEntityWithType({ id: route.params.studyId as string }),
        createEditorSurveyPricingEntity(),
      ]),
    },
    components: {
      default: async () =>
        (await import('@attest/results-components/src/view/Study/Study.vue')).default,
      [VIEWS.NAVBAR]: async () =>
        (await import('@attest/study/src/components/organism/StudyNavbar/StudyNavbar.vue')).default,
    },
    props: {
      default: getStudyProps,
      [VIEWS.NAVBAR](route): StudyNavbarProps {
        return getStudyProps(route)
      },
    },
    children: [],
  }
}

export function getStudyProps(route: RouteLocation): StudyProps {
  const id = route.params.studyId as string
  // force uppercase for survey GUIDS
  const studyId = process.env.NODE_ENV === 'production' && id.length === 14 ? id.toUpperCase() : id
  return {
    studyId,
  }
}

function createEditorSurveyPricingEntity(): SurveyPricingEntity {
  return createSurveyPricingEntity({
    cost: creditCentsToCredit(
      calculateSurveyPrice({
        audience: useAudienceEditorStore().type,
        surveyType: useEditorSurveyStore().isVideoResponseSurvey ? 'qual' : 'quant',
        numResponses: useAudienceEditorStore().respondentCount,
        numQuestions: useEditorRoutingGraphStore().countOfQuestionsInLongestRouteOverall,
        numCountries: useAudienceEditorStore().filledAudiences.length,
        paymentMethod: 'wallet',
      }).cents,
    ),
    surveyLength: useEditorRoutingGraphStore().countOfQuestionsInLongestRouteOverall,
  })
}
