import { whereEq } from 'ramda'

import type { CountryLanguages } from '../model'

export const getCountryLanguageKey = (
  country: string,
  language: string,
  countryLanguages: CountryLanguages,
): string | null =>
  Object.keys(countryLanguages).find(key =>
    whereEq({ country, language }, countryLanguages[key]),
  ) ?? null
