import type {
  CardDisplayLogicError,
  CardError,
  CardGroupedPipingError,
  CardMaskingError,
  CardPipingError,
  CardQualifyingError,
  CardRoutingAnswerError,
  CardRoutingError,
  CardTextError,
  CardTitleError,
  GroupCardError,
} from '@attest/_lib/src/editor-error/editor-error'
import { createGetErrorsFor } from '@attest/editor'
import {
  type Card,
  isCardChoiceQuestion,
  isCardGridQuestion,
  isCardOpenTextQuestion,
  isCardVideoQuestion,
  isGroupCard,
  isQualifying,
  isQuestionCard,
  isRankedQuestion,
  isTextCard,
} from '@attest/editor-card'
import { localiseAnswers, localiseSubjects } from '@attest/editor-localisation'
import { getAncestorSender } from '@attest/editor-masking'
import { SETTINGS } from '@attest/editor-settings'

import { getQuestionAnswerErrors, hasMinValidAnswers } from './answer'

type CardErrorType = Exclude<
  CardError['type'],
  | CardTitleError['type']
  | CardPipingError['type']
  | CardMaskingError['type']
  | CardGroupedPipingError['type']
  | CardTextError['type']
  | CardRoutingError['type']
  | CardRoutingAnswerError['type']
  | GroupCardError['type']
  | CardQualifyingError['type']
  | CardDisplayLogicError['type']
>

export const getCardErrors = createGetErrorsFor<
  CardErrorType,
  { card: Card; cards: Card[]; cardsVisibleForAudiences: Record<string, Set<string>> }
>({
  MISSING_QUESTION_TYPE: ({ card }) => {
    if (isTextCard(card) || isGroupCard(card)) return false
    return card.question.type === null
  },
  IMAGE_ANSWERS_MISSING_IMAGES: ({ card }) => {
    const { isImage, answers } = card.question.options
    if (!isCardChoiceQuestion(card) || !isImage) return false
    const hasCaptionWithoutImage = answers.some(answer =>
      getQuestionAnswerErrors.IMAGE_ANSWERS_MISSING_IMAGES({ isImage, answer, answers }),
    )
    return hasCaptionWithoutImage || !hasMinValidAnswers(card)
  },
  IMAGE_CAPTION_ANSWERS_TOO_LONG: ({ card }) => {
    if (!isCardChoiceQuestion(card)) return false
    const { isImage, answers } = card.question.options
    return answers.some(answer =>
      getQuestionAnswerErrors.IMAGE_CAPTION_ANSWER_TOO_LONG({ isImage, answer, answers }),
    )
  },
  TEXT_ANSWERS_TOO_LONG: ({ card }) => {
    if (!isCardChoiceQuestion(card) && !isRankedQuestion(card)) {
      return false
    }
    const { isImage, answers } = card.question.options
    return answers.some(answer =>
      getQuestionAnswerErrors.TEXT_ANSWER_TOO_LONG({ isImage, answer, answers }),
    )
  },

  NOT_ENOUGH_ANSWERS_FOR_AUDIENCE: ({ card, cardsVisibleForAudiences }) => {
    const answers = card.question.options.answers

    const allAudiencesCardHasLocalisationsFor = new Set(
      answers.flatMap(answer => [...answer.omittedFromAudiences]),
    )

    return [...allAudiencesCardHasLocalisationsFor].some(audienceId => {
      const { localisedCard } = localiseAnswers({ card, audienceId })
      const countOfAnswersShownToAudience = localisedCard.question.options.answers.length
      return (
        countOfAnswersShownToAudience < 2 && cardsVisibleForAudiences[audienceId]?.has(card.guid)
      )
    })
  },
  NOT_ENOUGH_ANSWERS: ({ card }) =>
    !card.question.options.isImage &&
    (isCardChoiceQuestion(card) || isRankedQuestion(card)) &&
    !hasMinValidAnswers(card),
  GRID_NOT_ENOUGH_OPTIONS: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    return (
      card.question.options.answers.filter(
        answer =>
          (answer.type === 'text' && !!answer.text) ||
          (answer.type === 'image' && !!answer.media?.url),
      ).length < SETTINGS.QUESTION.GRID.OPTION_AMOUNT_MIN
    )
  },
  GRID_NOT_ENOUGH_SUBJECTS: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    return (
      card.question.subjects.headers.filter(header => !!header.text).length <
      SETTINGS.QUESTION.GRID.SUBJECT_AMOUNT_MIN
    )
  },
  GRID_NOT_ENOUGH_SUBJECTS_FOR_AUDIENCE: ({ card, cards, cardsVisibleForAudiences }) => {
    if (!isCardGridQuestion(card)) {
      return false
    }
    const subjects = card.question.subjects.headers
    const allAudiencesCardHasLocalisationsFor = new Set(
      subjects.flatMap(subject => [...subject.omittedFromAudiences]),
    )
    return [...allAudiencesCardHasLocalisationsFor].some(audienceId => {
      const localisedCard = localiseSubjects({
        maskingSource: getAncestorSender(card, cards),
        card,
        audienceId,
      })
      const countOfSubjectsShownToAudience =
        localisedCard.localisedCard.question.subjects.headers.length
      return (
        countOfSubjectsShownToAudience < 2 && cardsVisibleForAudiences[audienceId]?.has(card.guid)
      )
    })
  },
  GRID_TOO_MANY_SUBJECTS: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    if (card.question.options.maskingQuestion?.isToggled) return false
    return (
      card.question.subjects.headers.filter(header => !!header.text).length >
      SETTINGS.QUESTION.GRID.SUBJECT_AMOUNT_MAX
    )
  },
  GRID_TOO_MANY_SUBJECTS_IF_MASKING: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    if (!card.question.options.maskingQuestion?.isToggled) return false
    return (
      card.question.subjects.headers.filter(header => !!header.text).length >
      SETTINGS.QUESTION.GRID.SUBJECT_AMOUNT_MAX_IF_MASKING
    )
  },
  GRID_SUBJECTS_TOO_LONG: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    return card.question.subjects.headers.some(
      subject => subject.text.length > SETTINGS.QUESTION.GRID.SUBJECT_LENGTH_MAX,
    )
  },
  GRID_OPTIONS_TOO_LONG: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    return card.question.options.answers.some(
      option => option.text.length > SETTINGS.QUESTION.GRID.OPTION_LENGTH_MAX,
    )
  },
  GRID_OPTIONS_MISSING_IMAGES: ({ card }) => {
    if (!isCardGridQuestion(card)) return false
    const { isImage, answers } = card.question.options
    return (
      isImage &&
      answers.some(answer => !answer.media || answer.media.type !== 'image' || !answer.media.url)
    )
  },

  VIDEO_PROMPT_TOO_LONG: ({ card }) => {
    if (!isCardVideoQuestion(card)) return false
    return (card.question.options.answers[0]?.text ?? '').length > SETTINGS.VIDEO.MAX_PROMPT_LENGTH
  },

  OPEN_TEXT_FIELD_TITLE_TOO_LONG: ({ card }) => {
    if (!isCardOpenTextQuestion(card)) return false
    const { options } = card.question
    return options.answers.some(
      answer => answer.text.length > SETTINGS.OPEN_TEXT.MAX_FIELD_TITLE_LENGTH,
    )
  },
  QUESTION_RANDOMIZED_AND_QUALIFYING: ({ card }) =>
    card.isRandomized && isQuestionCard(card) && isQualifying(card),
})
