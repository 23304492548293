import axios from 'axios'

import {
  reasonErrorToInternalError,
  reasonErrorToInternalErrorList,
  service,
  type ServiceError,
  type ServiceRequest,
} from '@attest/_api'
import type { DeleteUserError, ListUsersErrorType } from '@attest/_api/model/error'
import type { UserRoleArgs, UsersApiResponse } from '@attest/_api/model/users'
import { type ExpectedHandler, expectedHandler } from '@attest/util'

export const REQUEST_URL = {
  USER: (guid: string): string => `/api/users/${guid}`,
  LIST_USERS: (organisationId: string): string => `/api/orgs/${organisationId}/members`,
  ORG_ADMIN: (makerGuid: string): string => `/api/makers/${makerGuid}/roles/org_admin`,
  GLOBAL_ADMIN: (makerGuid: string): string => `/api/makers/${makerGuid}/roles/global_admin`,
}

export const listUsers: ServiceRequest<
  UsersApiResponse,
  ServiceError<ListUsersErrorType>,
  string
> = async args => {
  try {
    const response = await service().get<UsersApiResponse>(REQUEST_URL.LIST_USERS(args))
    return response.data
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export const deleteUser: ServiceRequest<
  void,
  ServiceError<DeleteUserError>,
  string
> = async args => {
  try {
    await service().delete(REQUEST_URL.USER(args))
    return undefined
  } catch (error) {
    return reasonErrorToInternalErrorList(error)
  }
}

export const editUserPermissions: ServiceRequest<void, ServiceError, UserRoleArgs> = async args => {
  try {
    await service()[args.action](`${REQUEST_URL[args.endpoint](args.userGuid)}`)
    return
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export function transferUsersEntities(args: {
  fromUserGuid: string
  toUserGuid: string
}): ExpectedHandler<unknown, Error> {
  return expectedHandler(() =>
    axios.post(`api/users/${args.fromUserGuid}/transfer`, {
      target_maker_guid: args.toUserGuid,
    }),
  )
}
