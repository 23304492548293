<script lang="tsx">
import { defineComponent, type PropType } from 'vue'
import { isNavigationFailure, type RouteLocationNamedRaw } from 'vue-router'

import { isAppError } from '@attest/_api'
import { Checkbox } from '@attest/_components/src/common/Checkbox'
import ModalConfirm from '@attest/_components/src/common/ModalConfirm.vue'
import { toast } from '@attest/_components/src/common/toaster'
import { COPY } from '@attest/_copy'
import { c } from '@attest/intl'
import { SURVEY_DRAFTER_ROUTE_NAME } from '@attest/router'
import { createEmitter } from '@attest/vue-tsx'

import { type CloneByGuidResponseData, get as getSurvey } from '../../../api'
import {
  trackCancelDuplicateSurvey,
  trackDuplicateSurvey,
  trackDuplicateSurveyExcludeRespondents,
} from '../../../duplicate-survey'
import { setExclusiveSamples } from '../../../exclusive-samples'
import { useSurveyStore } from '../../../store'

import type { ModalDuplicateSurveyEvents, ModalDuplicateSurveyProps } from './interface'

const emit = createEmitter<ModalDuplicateSurveyEvents>()

export default defineComponent({
  props: {
    guid: { type: String, required: true },
    accessCode: { type: [String, null] as PropType<string | null>, default: null },
    bodyCopy: { type: String, default: COPY.MODAL.DUPLICATE_SURVEY.BODY },
    confirmCopy: { type: String, default: COPY.MODAL.DUPLICATE_SURVEY.CONFIRM },
    show: { type: Boolean, required: true },
    status: { type: String as PropType<ModalDuplicateSurveyProps['status']>, default: 'draft' },
    title: { type: String, default: COPY.MODAL.DUPLICATE_SURVEY.TITLE },
    viewership: {
      type: String as PropType<ModalDuplicateSurveyProps['viewership']>,
      default: 'shared',
    },
    isVideoSurvey: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      isExcludeRespondents: false,
    }
  },

  computed: {
    canExcludeRespondents(): boolean {
      return (
        this.status !== 'draft' &&
        this.viewership === 'own' &&
        !this.accessCode &&
        !this.isVideoSurvey
      )
    },
  },

  methods: {
    async handleDuplicateSurvey(event: Event) {
      this.loading = true
      const guid = this.guid

      try {
        const response = await useSurveyStore().cloneByGuid({ guid })

        if (isAppError(response)) {
          toast(c('dupliate_survey.error.unknown'), { mode: 'error' })
          return
        }

        trackDuplicateSurvey({
          originalGuid: guid,
          clonedGuid: response.success.guid,
          event,
        })

        if (this.isExcludeRespondents) {
          await this.appendExclusiveSampleGuids(response.success.guid, [guid])
          trackDuplicateSurveyExcludeRespondents({
            event,
            originalGuid: guid,
            clonedGuid: response.success.guid,
          })
        }

        await this.$router.push(this.getPostDuplicateLocation(response))

        emit(this, 'afterDuplicate')
      } catch (error) {
        if (isNavigationFailure(error)) {
          throw new Error(`Navigation error when redirecting from duplicate survey modal: ${error}`)
        }

        throw error
      } finally {
        this.loading = false
      }
    },

    async appendExclusiveSampleGuids(guid: string, excludedGuids: string[]): Promise<void> {
      const apiSurvey = (await getSurvey({ guid })).data.success

      await setExclusiveSamples({
        guid,
        excludedGuids: [...new Set([...apiSurvey.exclusive_sample_guids, ...excludedGuids])],
      })
    },

    handleCancel(event: Event) {
      trackCancelDuplicateSurvey({
        event,
        guid: this.$route.params.guid ? undefined : this.guid,
      })
      emit(this, 'cancel')
    },

    getPostDuplicateLocation(response: CloneByGuidResponseData) {
      const { success, warnings } = response
      const guid = success.guid
      const location: RouteLocationNamedRaw = {
        name: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR,
        params: { guid },
        state: { prompt: 'title' },
      }

      if (warnings && warnings.length > 0) {
        location.query = { warnings: warnings.join(',') }
      }

      return location
    },
  },

  render() {
    const baseClass = 'c-modal-duplicate-survey'

    return (
      <ModalConfirm
        titleDisplay={this.title}
        class={baseClass}
        handleConfirm={this.handleDuplicateSurvey}
        confirmCopy={this.confirmCopy}
        handleCancel={e => this.handleCancel(e)}
        confirmLoading={this.loading}
        show={this.show}
        data-name="ModalDuplicateSurvey"
      >
        <div class={`${baseClass}__body`}>{this.bodyCopy}</div>
        {this.canExcludeRespondents && (
          <Checkbox
            data-name="ModalDuplicateSurveyExcludeRespondentsCheckbox"
            class={`${baseClass}__exclude-respondents-label`}
            checked={this.isExcludeRespondents}
            onSelect={({ checked }) => (this.isExcludeRespondents = checked === 'checked')}
            aria-label={c('survey.duplicate.duplicate_modal.checkbox_title')}
          >
            <div>
              {c('survey.duplicate.duplicate_modal.checkbox_title')}
              <div class={`${baseClass}__exclude-respondents-description`}>
                {c('survey.duplicate.duplicate_modal.checkbox_descritpion')}
              </div>
            </div>
          </Checkbox>
        )}
      </ModalConfirm>
    )
  },
})
</script>

<style lang="postcss">
.c-modal-duplicate-survey {
  &__exclude-respondents-label {
    align-items: flex-start;
    margin-top: var(--attest-spacing-6);
  }

  &__exclude-respondents-description {
    color: var(--attest-color-background-inverse);
  }
}
</style>
