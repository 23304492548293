export type SelfDescribingJson<T extends Record<keyof T, unknown> = Record<string, unknown>> = {
  /**
   * The schema string
   * @example 'com.askattest/web_page/jsonschema/1-0-0'
   */
  schema: string
  /**
   * The data object which should conform to the supplied schema
   */
  data: T
}

export type Entity<D = any> = {} & SelfDescribingJson<D>

export function createEntity<D>(
  schema: { name: string; version: `${number}-${number}-${number}` },
  data: D,
): Entity<D> {
  return {
    schema: `iglu:com.askattest/${schema.name}/jsonschema/${schema.version}`,
    data,
  }
}
