import type { AxiosError } from 'axios'

import type { ApiError, ServiceError as ApiServiceError } from './error'

/**
 * @deprecated type is no longer required
 *
 * ```ts
 * export const name: ServiceRequest<A, B, C> = async (args): Promise<A | B> => { }
 *
 * // becomes:
 *
 * export async function name(args: C): Promise<A | B> { }
 * ```
 */
export type ServiceRequest<Response, E = ApiError, Args = undefined> = Args extends undefined
  ? () => Promise<Response | InternalError<E>>
  : (args: Args) => Promise<Response | InternalError<E>>

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export type ApiFieldError<T> = {
  error: Record<string, ApiServiceError<T>>
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export type InternalError<T> = {
  fields?: Record<string, T>
  generalError?: ApiServiceError<T>
  cause?: T
  errors?: {
    field?: string
    reason: ApiServiceError<T>
  }[]
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function reasonErrorToInternalError<T extends string | ApiError = ApiError>(
  e: any,
): InternalError<T | ApiError> {
  if (!isServiceError(e) || !e.response) {
    return getUnknownInternalError()
  }

  if (e.response.status === 401) return { generalError: 'UNAUTHORIZED' }
  if (e.response.status === 404) return { generalError: 'NOT_FOUND' }

  if (!e.response.data?.error && !e.response.data?.cause) {
    return getUnknownInternalError()
  }

  const { error, cause } = e.response.data

  if (typeof error === 'object' && Object.keys(error).length === 0)
    return { generalError: 'UNKNOWN' }

  return { generalError: (error?.reason || error?.failure || error?.cause || error || cause) as T }
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function reasonErrorToInternalErrorList<T extends string = ApiError>(
  e: any,
): InternalError<T | ApiError> {
  if (
    !isServiceError(e) ||
    !e.response ||
    e.response.status === 500 ||
    !e.response.data.errors ||
    !Array.isArray(e.response.data.errors)
  )
    return getUnknownInternalErrorList()

  const { errors }: InternalError<T> = e.response.data

  if (e.response.status === 401) return { generalError: 'UNAUTHORIZED' }
  if (e.response.status === 404 && errors.length === 0) return { errors: [{ reason: 'NOT_FOUND' }] }
  return { errors: errors.map(error => ({ reason: error.reason })) }
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function apiErrorToError(e: unknown): InternalError<'UNKNOWN'> {
  if (isServiceError(e) && e.response) {
    if (e.response.data && e.response.data.error !== true) return e.response.data.error
    if (e.response.status === 404) return { generalError: 'NOT_FOUND' }
  }

  return getUnknownInternalError()
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function getUnknownInternalError(): InternalError<'UNKNOWN'> {
  return { generalError: 'UNKNOWN' }
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function getUnknownInternalErrorList(): InternalError<'UNKNOWN'> {
  return { errors: [{ reason: 'UNKNOWN' }] }
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function isUnknownInternalError(error: any): error is InternalError<'UNKNOWN'> {
  return typeof error === 'object' && error.generalError === 'UNKNOWN'
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function isAppError<T>(data: any): data is InternalError<T> {
  if (!data || typeof data !== 'object') return false
  return (
    (data.fields !== undefined && typeof data.fields === 'object') ||
    data.generalError !== undefined
  )
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function isAppErrorList<T>(data: any): data is InternalError<T> {
  if (!data || typeof data !== 'object') return false
  return data.errors !== undefined && Array.isArray(data.errors)
}

/**
 * @deprecated use async error handling flow returning original error typing the response payload as a contract.
 *
 * use `getErrorCode` from `@attest/fetch` in consumer to get potential error codes from unknown error response data
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
export function getFieldErrorsFromServiceError(e: any): Record<string, string> | null {
  if (!isServiceError(e) || !e.response) return null
  return e.response.data.error
}

/**
 * @deprecated use `isFetchError` from `@attest/fetch`
 *
 * https://www.notion.so/askattest/API-Async-Error-Handling-c149573020c64750b4c94db05e944498
 */
function isServiceError(e: any): e is AxiosError<any> {
  if (!e || typeof e !== 'object') return false
  const serviceError = e as AxiosError<any>
  return serviceError.config !== undefined && typeof serviceError.config === 'object'
}
