<script lang="tsx">
import { defineComponent, type PropType, Transition } from 'vue'

import loadingDotsSVG from '@attest/_assets/icons/more_horiz.svg'
import { create } from '@attest/vue-tsx'

import { SvgIcon } from './SvgIcon'

type LoadingVariant = 'inverse' | 'standard' | 'flat'

type LoadingProps = {
  show: boolean
  variant?: LoadingVariant
}

// @vue/component
const Loading = defineComponent({
  props: {
    show: { type: Boolean, required: true },
    variant: { type: String as PropType<LoadingVariant>, default: 'standard' },
  },

  render() {
    const baseClass = 'c-loading'
    const loadingClass = {
      [`${baseClass}`]: true,
      [`${baseClass}--${this.variant}`]: !!this.variant,
    }
    const circleClass = {
      [`${baseClass}__circle`]: true,
      [`${baseClass}__circle--animate`]: this.show,
    }
    return (
      <div class={loadingClass}>
        <Transition name="t-fade">
          {this.variant === 'flat' ? (
            <SvgIcon
              class={`${baseClass}__flat-indicator`}
              aria-hidden="true"
              svg={loadingDotsSVG}
            />
          ) : (
            <div v-show={this.show} class="c-loading__indicator">
              <div class={circleClass} />
              <div class={circleClass} />
              <div class={circleClass} />
            </div>
          )}
        </Transition>

        {this.$slots.default ? (
          <div class={{ 'c-loading__content': true, 'c-loading__content--show': !this.show }}>
            {this.$slots.default?.()}
          </div>
        ) : null}
      </div>
    )
  },
})

export default create<LoadingProps>(Loading)
</script>

<style lang="postcss">
:root {
  --c-loading-circle-size: 7px;
  --c-loading-circle-size-small: 4px;
  --c-loading-circle-animation: 1.8s ease-in-out 0s infinite normal c-loading-circle-animation;
  --c-loading-circle-stagger: 0.1s;

  --c-loading-circle-offset: -11px;
}

@keyframes c-loading-circle-animation {
  0% {
    opacity: 1;
    transform: none;
  }

  20% {
    transform: translateY(var(--c-loading-circle-offset));
  }

  10%,
  30% {
    opacity: 0.3;
  }

  60% {
    transform: none;
  }

  100% {
    opacity: 1;
  }
}

.c-loading {
  position: relative;

  &__indicator {
    display: flex;
    margin-top: var(--attest-spacing-2);
    gap: var(--c-loading-circle-size);
  }

  &__circle {
    width: var(--c-loading-circle-size);
    height: var(--c-loading-circle-size);
    border-radius: 50%;
  }

  @context scoped {
    &__flat-indicator {
      position: relative;
      bottom: 0;
      height: 2px;
    }
  }

  &__content {
    opacity: 0;
    transition:
      opacity var(--attest-timing-default) linear,
      visibility 0s linear var(--attest-timing-default);
    visibility: hidden;

    &--show {
      opacity: 1;
      transition:
        opacity var(--attest-timing-default) linear,
        visibility 0s linear 0s;
      visibility: inherit;
    }
  }

  &--standard &__circle {
    background-color: var(--attest-color-interactive-default);
  }

  &--inverse &__circle {
    background-color: var(--attest-color-background-default);
  }

  &__circle--animate {
    animation: var(--c-loading-circle-animation);

    &:nth-child(2) {
      animation-delay: calc(var(--c-loading-circle-stagger) * 1);
    }

    &:last-child {
      animation-delay: calc(var(--c-loading-circle-stagger) * 2);
    }
  }
}
</style>
