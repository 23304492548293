export function sum(...values: number[]): number {
  return values.reduce((acc, el) => acc + el, 0)
}

export function minByList<X>(getComparee: (x: X) => number, it: Iterable<X>): X | undefined {
  const xs = Array.from(it)
  const comparees = xs.map(getComparee)
  const least = Math.min(...comparees)
  return Number.isNaN(least) ? undefined : xs[comparees.indexOf(least)]
}

export function maxByList<X>(getComparee: (x: X) => number, it: Iterable<X>): X | undefined {
  return minByList(x => -1 * getComparee(x), it)
}

export function maxList(it: Iterable<number>): number | undefined {
  return maxByList(a => a, it)
}

export function percentageOf(base: number, value: number): number {
  if (base === 0 || value === 0) {
    return 0
  }

  return (100 / base) * value
}

export function randomInteger(from: number, to: number): number {
  if (to < from) throw new Error('expecting from > to')
  return from + Math.floor(Math.random() * (to - from + 1))
}

export function getMaxConsecutivesCount(xs: number[]): number {
  return Math.max(
    getAscendingConsecutivesCount(xs),
    getAscendingConsecutivesCount([...xs].reverse()),
  )
}

function getAscendingConsecutivesCount(xs: number[]): number {
  if (xs.length <= 0) return 0

  const consecutivesFromStart = getConsecutivesFromStart(xs)
  return Math.max(
    consecutivesFromStart.length,
    getAscendingConsecutivesCount(xs.slice(consecutivesFromStart.length)),
  )
}

function getConsecutivesFromStart(xs: number[]): number[] {
  return xs.slice(
    0,
    (() => {
      for (let i = 1; i < xs.length; i++) {
        const prev = xs[i - 1]
        if (prev === undefined) continue
        if (xs[i] !== prev + 1) return i
      }
      return xs.length
    })(),
  )
}

// ref: https://www.mathsisfun.com/data/least-squares-regression.html
export function createLeastSquaresRegression(
  data: [x: number, y: number][],
): (x: number) => number {
  const xSum = sum(...data.map(([x]) => x))
  const xSquaredSum = sum(...data.map(([x]) => x ** 2))
  const ySum = sum(...data.map(([_x, y]) => y))
  const xySum = sum(...data.map(([x, y]) => x * y))
  const m = (data.length * xySum - xSum * ySum) / (data.length * xSquaredSum - xSum ** 2)
  const b = (ySum - m * xSum) / data.length

  return (x: number): number => m * x + b
}
