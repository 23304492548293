<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create } from '@attest/vue-tsx'

import type { StatusIndicatorProps } from './StatusIndicator.interface'

const StatusIndicator: FunctionalComponent<StatusIndicatorProps> = (props, { slots }) => {
  const { styleVariant = 'primary', ...attrs } = props

  const baseClass = 'c-status-indicator'
  const indicatorClass = {
    [baseClass]: true,
    [`${baseClass}--style-${styleVariant}`]: true,
  }

  return (
    <div class={indicatorClass} {...attrs}>
      {slots.default?.()}
    </div>
  )
}
export default create<StatusIndicatorProps>(StatusIndicator)
</script>
<style lang="postcss">
.c-status-indicator {
  width: 9px;
  height: 9px;
  box-sizing: border-box;
  border: var(--attest-border-width-s) solid transparent;
  border-radius: 50%;

  &--style-primary {
    border-color: var(--attest-color-background-default);
    background-color: var(--attest-color-interactive-default);
  }
}
</style>
