import type { Answer, AnswerGridData, AnswerType, StaticAnswer } from '..'

export type SurveyCardType = ValidSurveyCardType | 'blank'

export type ValidSurveyCardType =
  | 'group'
  | 'single_choice'
  | 'multiple_choice'
  | 'message'
  | 'ranked'
  | 'nps'
  | 'either_or'
  | 'grid'
  | 'video'
  | FreeTextSurveyCardType

export type FreeTextSurveyCardType =
  | 'text_response_short'
  | 'text_response_medium'
  | 'text_response_long'

export type QuestionCardType = Extract<SurveyCardType, FlatQuestionCardType | 'grid'>
export type FlatQuestionCardType = Exclude<SurveyCardType, 'message' | 'grid' | 'blank' | 'group'>
export type QuestionFreeTextCardType = Extract<
  FlatQuestionCardType,
  'text_response_short' | 'text_response_medium' | 'text_response_long'
>
export type QuestionChoiceCardType = Extract<
  FlatQuestionCardType,
  'single_choice' | 'multiple_choice' | 'either_or' | 'nps'
>

export type RoutedCard<T extends SurveyCardType> = {
  id: string
  cardType: T
  position: RoutedCardPosition | null
  next: number | null
  isRandomized?: boolean
}

export type RoutedCardPosition = {
  x: number | null
  y: number | null
}

export type SurveyCard = MessageCard | BlankCard | QuestionCard | GroupCard
export type QuestionCard = FlatQuestionCard | GridQuestionCard

export type GroupCard = RoutedCard<'group'>

export type FlatQuestionSecondaryAnswers = [
  StaticAnswer<'none'>,
  StaticAnswer<'na'>,
  StaticAnswer<'skipped'>,
]

export type FlatQuestionCard<T extends FlatQuestionCardType = FlatQuestionCardType> = {
  questionId: number
  questionGuid: string
  creationTime: number
  title: string
  hasNa: boolean
  hasNone: boolean
  hasOther: boolean
  isAnswersRandomized: boolean
  isImageType: boolean
  selectionLimit: number | null
  answers: Answer[]
  secondaryAnswers: FlatQuestionSecondaryAnswers
  titleContent: SurveyTitleContent
} & RoutedCard<T>

export type GridQuestionSecondaryAnswers = [StaticAnswer<'skipped'>]
export type GridQuestionCard = {
  questionId: number
  questionGuid: string
  creationTime: number
  title: string
  answers: AnswerGridData
  secondaryAnswers: GridQuestionSecondaryAnswers
  titleContent: SurveyTitleContent
} & RoutedCard<'grid'>

export type BlankCard = {} & RoutedCard<'blank'>

export type MessageCard = {
  title: string
  titleContent: SurveyTitleContent
} & RoutedCard<'message'>

export type SurveyTitleContent = {
  imageUrl: string | null
  audioUrl: string | null
  videoUrl: string | null
}

export type SurveyErrorCardError = 'NOT_FOUND'

export type SurveyErrorCard = {
  cardType: 'error'
  error: SurveyErrorCardError
}

export type SurveyDisplayCard = SurveyCard | SurveyErrorCard

export function getQuestionCardChoicesFlattened(card: QuestionCard): Answer<AnswerType>[] {
  if (isGridQuestionCard(card)) {
    return ([] as Answer[]).concat(...card.answers.choices)
  }

  return card.answers
}

export function isQuestionCard(card: {
  cardType: SurveyDisplayCard['cardType']
}): card is QuestionCard {
  return (
    card.cardType !== 'message' &&
    card.cardType !== 'blank' &&
    card.cardType !== 'error' &&
    card.cardType !== 'group'
  )
}

export function isMessageCard(card: SurveyDisplayCard): card is MessageCard {
  return card.cardType === 'message'
}

export function isBlankCard(card: SurveyDisplayCard): card is BlankCard {
  return card.cardType === 'blank'
}

export function isFlatQuestionCard(card: SurveyDisplayCard): card is FlatQuestionCard {
  return isQuestionCard(card) && card.cardType !== 'grid'
}

export function isRankedQuestionCard(card: SurveyDisplayCard): card is FlatQuestionCard<'ranked'> {
  return card.cardType === 'ranked'
}

export function isNpsQuestionCard(card: SurveyDisplayCard): card is FlatQuestionCard<'nps'> {
  return card.cardType === 'nps'
}

export function isFreeTextQuestionCard(
  card: SurveyDisplayCard,
): card is FlatQuestionCard<QuestionFreeTextCardType> {
  return (
    card.cardType === 'text_response_short' ||
    card.cardType === 'text_response_medium' ||
    card.cardType === 'text_response_long'
  )
}

export function isVideoQuestionCard(card: SurveyDisplayCard): card is FlatQuestionCard<'video'> {
  return card.cardType === 'video'
}

export function isChoiceQuestionCard(
  card: SurveyDisplayCard,
): card is FlatQuestionCard<QuestionChoiceCardType> {
  return (
    card.cardType === 'single_choice' ||
    card.cardType === 'multiple_choice' ||
    card.cardType === 'either_or' ||
    card.cardType === 'nps'
  )
}

export function hasGrouping(card: { cardType: SurveyCardType }): boolean {
  return card.cardType === 'single_choice' || card.cardType === 'multiple_choice'
}

export function isGridQuestionCard(card: SurveyDisplayCard): card is GridQuestionCard {
  return card.cardType === 'grid'
}

export function isErrorCard(card: SurveyDisplayCard): card is SurveyErrorCard {
  return card.cardType === 'error'
}

export function isValidSigTestQuestionCard(
  card: SurveyDisplayCard,
): card is FlatQuestionCard<'single_choice' | 'multiple_choice'> {
  return (
    isFlatQuestionCard(card) &&
    (card.cardType === 'single_choice' || card.cardType === 'multiple_choice')
  )
}

export function hasRouting(card: SurveyCard): boolean {
  if (isChoiceQuestionCard(card)) {
    return card.answers.some(answer => answer.next !== null && answer.next !== undefined)
  }

  return false
}

export function hasQualifying(card: SurveyCard): boolean {
  if (isChoiceQuestionCard(card)) {
    return card.answers.some(answer => answer.isQualifying)
  }

  return false
}

export function hasPinned(card: SurveyCard): boolean {
  if (isChoiceQuestionCard(card)) {
    return card.answers.some(answer => answer.isPinned)
  }

  if (isGridQuestionCard(card)) {
    return (
      card.answers.subjects.headers.some(subject => subject.isPinned) ||
      card.answers.options.headers.some(header => header.isPinned)
    )
  }

  return false
}

export function hasNA(card: SurveyCard): boolean {
  if (isNASupported(card)) {
    if (card.cardType === 'grid') {
      return card.answers.options.hasNa
    }

    return card.hasNa
  }

  return false
}

export function hasNone(card: SurveyCard): boolean {
  if (isNASupported(card)) {
    if (card.cardType === 'grid') {
      return card.answers.options.hasNone
    }

    return card.hasNone
  }

  return false
}

export function hasOther(card: SurveyCard): boolean {
  if (isChoiceQuestionCard(card)) {
    return card.answers.some(answer => answer.id === 'other')
  }

  return false
}

export function isNASupported(
  card: SurveyCard,
): card is FlatQuestionCard<FlatQuestionCardType> | GridQuestionCard {
  const cardType = card.cardType

  return (
    cardType === 'single_choice' ||
    cardType === 'multiple_choice' ||
    cardType === 'either_or' ||
    cardType === 'ranked' ||
    cardType === 'text_response_short' ||
    cardType === 'text_response_medium' ||
    cardType === 'text_response_long' ||
    cardType === 'nps' ||
    cardType === 'grid'
  )
}

export function isNoneSupported(
  card: SurveyCard,
): card is FlatQuestionCard<QuestionChoiceCardType | 'nps'> | GridQuestionCard {
  const cardType = card.cardType

  return (
    cardType === 'single_choice' ||
    cardType === 'multiple_choice' ||
    cardType === 'either_or' ||
    cardType === 'nps' ||
    cardType === 'grid'
  )
}

export function isOtherSupported(
  card: SurveyCard,
): card is FlatQuestionCard<QuestionChoiceCardType> {
  if (isChoiceQuestionCard(card) && !isNpsQuestionCard(card)) {
    return !card.answers.some(answer => !!answer.imageUrl)
  }

  return false
}

export function isGroupCard(card: SurveyCard): card is GroupCard {
  return card.cardType === 'group'
}
