import type { RouteLocation, RouteLocationRaw, Router } from 'vue-router'

import { onReady } from '@attest/_components/src/common/toaster'
import { ROUTES } from '@attest/router'
import { capture } from '@attest/telemetry'
import { useUserStore } from '@attest/user'

export async function setupAuthentication(router: Router): Promise<void> {
  const url = new URL(location.href)
  const route = router.resolve(url.pathname)

  try {
    await useUserStore().processInvite(url)
  } catch (e) {
    capture(e)
    onReady(() => {
      router.replace({
        name: ROUTES.REGISTER_INVITE_EXPIRED.name,
        query: route.query,
      })
      return true
    })
  }

  await useUserStore().authenticate()
  if (useUserStore().authenticated) {
    await useUserStore().getUserAndAdditionalInfo()
  }
  const redirect = testAuthentication(route)
  if (redirect) await router.replace(redirect)

  router.beforeResolve(async (to, _, next) => {
    const toRedirect = testAuthentication(to)
    if (toRedirect) return next(toRedirect)
    next()
  })
}

function testAuthentication(to: RouteLocation): RouteLocationRaw | undefined {
  const { authenticationRequired = 'authenticated' } = to.meta ?? {}
  const { authenticated: isAuthenticated } = useUserStore()

  if (authenticationRequired === 'unauthenticated' && isAuthenticated) {
    return (to.query.redirect as string) ?? { name: ROUTES.INDEX.name }
  }

  if (authenticationRequired === 'authenticated' && !isAuthenticated) {
    return { name: ROUTES.LOGIN.name, query: { redirect: to.fullPath } }
  }

  return undefined
}
