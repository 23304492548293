import {
  allSimplePaths,
  getNodesByServingOrder,
  reverse,
  type RoutingGraph,
  subgraph,
} from '@attest/routing-graph'

export function createRandomizedGroupGraph(
  graph: RoutingGraph,
  randomizedNodes: Set<string>,
): RoutingGraph {
  const nodes = [...getNodesByServingOrder(graph)]
  const firstRandomizedCard = nodes.find(node => randomizedNodes.has(node))
  const lastRandomizedCard = nodes.reverse().find(node => randomizedNodes.has(node))

  if (!firstRandomizedCard) return subgraph(graph, [])
  if (firstRandomizedCard === lastRandomizedCard) return subgraph(graph, [firstRandomizedCard])

  return subgraph(graph, [
    ...new Set(...allSimplePaths(reverse(graph), lastRandomizedCard, firstRandomizedCard)),
  ])
}
