export const demographicFilterGroups = {
  householdIncomeDemographics: [
    'australia_weekly_earnings_household',
    'brazil_monthly_earnings_household',
    'canada_annual_earnings_household',
    'china_monthly_earnings_household',
    'earnings_household',
    'earnings_household_v2',
    'earnings_household_v3',
    'france_english_annual_earnings_household',
    'france_french_annual_earnings_household',
    'germany_annual_earnings_household',
    'india_annual_earnings_household',
    'ireland_annual_earnings_household',
    'italy_annual_earnings_household',
    'japan_monthly_earnings_household',
    'mexico_monthly_earnings_household',
    'russia_annual_earnings_household',
    'spain_annual_earnings_household',
    'usa_earnings_household',
  ],
  regionDemographics: [
    'australia_area',
    'australia_state',
    'austria_state',
    'belgium_arrondissements_fr',
    'belgium_arrondissements_nl',
    'belgium_province_fr',
    'belgium_province_nl',
    'belgium_region_fr',
    'belgium_region_nl',
    'brazil_state',
    'bulgaria_state',
    'canada_region',
    'china_city_tier',
    'china_prefecture',
    'china_province',
    'czech_republic_region',
    'denmark_region',
    'ecuador_state',
    'finland_region',
    'france_departement',
    'france_region',
    'germany_bundesland',
    'home_region',
    'india_region',
    'italy_provincia',
    'italy_region',
    'japan_prefecture',
    'japan_region',
    'lithuania_region',
    'netherlands_region',
    'norway_region',
    'poland_province',
    'poland_region',
    'portugal_region',
    'russia_region',
    'serbia_state',
    'slovakia_region',
    'slovenia_region',
    'spain_comunidad_autonoma',
    'spain_provincia',
    'sweden_county',
    'ukraine_state',
    'usa_dma',
    'usa_region',
    'usa_state',
    'work_region',
  ],
}
