import { defineStore } from 'pinia'
import { sum } from 'ramda'
import { computed, ref } from 'vue'

import { DEFAULT_LANGUAGE } from '@attest/_constant'
import { SETTINGS } from '@attest/editor-settings'
import { useRetry } from '@attest/fallible'
import { c } from '@attest/intl'
import { capture } from '@attest/telemetry'

import type { AudienceEditorExclusiveSample } from '../exclusive-sample'
import { createAudience } from '../factory'
import { fetchAvailableSampleSize } from '../feasibility'
import type { Audience, AudienceType } from '../model'
import { getBCP47LangCode } from '../util'
import { audienceIsSupportedForVideoQuestionSurvey } from '../video-question-survey'

export type AudienceEditorState = {
  audiences: Audience[]
  availableSampleSizes: Record<string, number>
  type: AudienceType
  exclusiveSamples: AudienceEditorExclusiveSample[]
  unsupportedDemographicKeys: string[]
  draftLanguage: string | undefined
  audienceIdToApiAnswerId: Map<string, string>
}

export const DEFAULT_AUDIENCE_TYPE = 'panel'
export const AUDIENCE_EDITOR_STORE_NAMESPACE = 'audienceEditor'

export function createAudienceEditorState(
  override: Partial<AudienceEditorState> = {},
): AudienceEditorState {
  return {
    audiences: [],
    availableSampleSizes: {},
    type: DEFAULT_AUDIENCE_TYPE,
    exclusiveSamples: [],
    unsupportedDemographicKeys: [],
    draftLanguage: undefined,
    audienceIdToApiAnswerId: new Map(),
    ...override,
  }
}

export const useAudienceEditorStore = defineStore(AUDIENCE_EDITOR_STORE_NAMESPACE, () => {
  const state = {
    audiences: ref<Audience[]>([]),
    availableSampleSizes: ref<Record<string, number>>({}),
    type: ref<AudienceType>(DEFAULT_AUDIENCE_TYPE),
    exclusiveSamples: useRetry<AudienceEditorExclusiveSample[]>(),
    unsupportedDemographicKeys: ref<string[]>([]),
    draftLanguage: ref<string | undefined>(undefined),
    audienceIdToApiAnswerId: ref<Map<string, string>>(new Map()),
  }

  const actions = {
    setAudiences(audiences: Audience[]): void {
      state.audiences.value = audiences
    },
    deleteAudience(id: string): void {
      state.audiences.value = state.audiences.value.filter(t => t.id !== id)
    },
    updateAudience(id: string, override: Partial<Audience>): void {
      const index = state.audiences.value.findIndex(audience => audience.id === id)
      if (index < 0) {
        return
      }
      const currentAudienceValue = state.audiences.value[index]
      if (currentAudienceValue === undefined) {
        return
      }
      state.audiences.value[index] = {
        ...currentAudienceValue,
        ...override,
      }
      if ('language' in override && state.draftLanguage.value === undefined) {
        state.draftLanguage.value = override.language
      }
    },
    updateAudiencesLanguage(language: string): void {
      state.audiences.value = state.audiences.value.map(audience => ({
        ...audience,
        language,
      }))
    },
    updateAudienceName(id: string, name: string): void {
      const audience = state.audiences.value.find(a => a.id === id)
      if (audience === undefined) {
        return
      }
      audience.name = name
    },
    addAudience(audience: Audience): void {
      state.audiences.value.push(audience)
    },
    duplicateAudience(id: string): void {
      const audienceToBeCopied = state.audiences.value.find(t => t.id === id)
      if (audienceToBeCopied === undefined) {
        return
      }
      const { id: _id, ...audienceParams } = audienceToBeCopied
      let name = c('editor.audience-editor.audience-name.name-copy', {
        placeholders: { baseName: audienceToBeCopied.name },
      })
      while (state.audiences.value.some(a => a.id !== _id && a.name === name)) {
        name = c('editor.audience-editor.audience-name.name-copy', {
          placeholders: { baseName: name },
        })
      }
      state.audiences.value.push(createAudience({ ...audienceParams, name }))
    },
    setDraftLanguageToSurveyLanguage(): void {
      this.setDraftLanguage(getters.filledAudiences.value[0]?.language ?? DEFAULT_LANGUAGE)
    },
    async fetchAvailableSampleSizesForTargeting(args: {
      audienceId: string
      isAuthenticated: boolean
      audienceType: AudienceType
      exclusiveSamples: AudienceEditorExclusiveSample[]
      surveyGuid: string | undefined
      startTimestamp: number | undefined
      totalQuestions: number
      collectsVideoResponses: boolean
      countOfQualifyingQuestions: number
    }): Promise<void> {
      const audience = state.audiences.value.find(({ id }) => id === args.audienceId)
      if (audience === undefined) {
        return capture(new Error("tried to fetch sample size for audience ID that doesn't exist"), {
          extra: { audienceId: args.audienceId },
        })
      }

      state.availableSampleSizes.value[args.audienceId] = await fetchAvailableSampleSize({
        audience: audience,
        ...args,
      })
    },
    /**
     * @deprecated This should only be used by `useSetAudience`
     */
    setAudienceType(newType: AudienceType): void {
      state.type.value = newType
    },
    setAudienceTypeWithoutSave(newType: AudienceType): void {
      state.type.value = newType
    },
    setExclusiveSamples(newExclusiveSamples: AudienceEditorExclusiveSample[]): void {
      state.exclusiveSamples.set(newExclusiveSamples)
    },
    setUnsupportedDemographicKeys(newUnsupportedDemographicKeys: string[]): void {
      state.unsupportedDemographicKeys.value = newUnsupportedDemographicKeys
    },
    setDraftLanguage(language: string): void {
      state.draftLanguage.value = language
      for (const audience of state.audiences.value) {
        audience.useDraftLanguage = audience.language === language
      }
    },
    $reset: () => {
      state.audiences.value = []
      state.availableSampleSizes.value = {}
      state.type.value = DEFAULT_AUDIENCE_TYPE
      state.exclusiveSamples.reset()
      state.unsupportedDemographicKeys.value = []
      state.draftLanguage.value = undefined
    },
  }

  const getters = {
    filledAudiences: computed((): Audience[] => {
      return state.audiences.value.filter(({ country }) => country !== '')
    }),
    // TODO: Deprecate once multi-language surveys are released
    selectedLanguage: computed((): string => {
      return state.audiences.value[0]?.language ?? SETTINGS.AUDIENCE.DEFAULT_LANGUAGE
    }),
    // TODO: Deprecate once multi-language surveys are released
    defaultedCountry: computed((): string => {
      return state.audiences.value[0]?.country ?? SETTINGS.AUDIENCE.DEFAULT_COUNTRY
    }),
    respondentCount: computed((): number => {
      return sum(getters.filledAudiences.value.map(({ sampleSize }) => sampleSize))
    }),
    translationLanguages: computed(
      (): { country: string; language: string; audienceIds: string[] }[] => {
        const translationLanguages = {} as Record<
          string,
          { country: string; language: string; audienceIds: string[] }
        >

        for (const audience of state.audiences.value.filter(
          ({ useDraftLanguage, country }) => !useDraftLanguage && Boolean(country),
        )) {
          if (translationLanguages[`${audience.country}-${audience.language}`] === undefined) {
            translationLanguages[`${audience.country}-${audience.language}`] = {
              country: audience.country,
              language: audience.language,
              audienceIds: [audience.id],
            }
          } else {
            translationLanguages[`${audience.country}-${audience.language}`]?.audienceIds.push(
              audience.id,
            )
          }
        }

        return Object.values(translationLanguages)
      },
    ),
    uiLanguage: computed((): string => {
      return state.draftLanguage.value !== undefined
        ? getBCP47LangCode(
            state.draftLanguage.value,
            // Due to lack of localisation support, use the first country that supports the default language (if there is one)
            state.audiences.value.find(({ language }) => language === state.draftLanguage.value)
              ?.country,
          )
        : getBCP47LangCode(getters.selectedLanguage.value, getters.defaultedCountry.value)
    }),
    unsupportedAudiencesForQual: computed((): Audience[] => {
      return state.audiences.value.filter(t => !audienceIsSupportedForVideoQuestionSurvey(t))
    }),
  }

  return {
    ...state,
    ...actions,
    ...getters,
  }
})
