import { defineStore } from 'pinia'
import type { DeepPartial } from 'ts-essentials'

import type { ImageMedia, Media } from '@attest/_lib/src/media/model/media'
import { translationKey } from '@attest/_lib/src/translation/translation-key'
import {
  type AnswerQuota,
  type AnswerQuotaId,
  type Card,
  type CardGuid,
  type CardPosition,
  type CardQuestion,
  type CardQuestionOptions,
  type CombinedCardTypeQuestion,
  createAnswerQuota,
  createCombinedCardType,
  createEmptyCard,
  createEmptyGroupCard,
  createEmptyTextCard,
  duplicateCard,
  getActiveAnswerQuotas,
  type Header,
  isChoiceCardQuestionType,
  isGroupCard,
  type OptionAnswer,
  removeOptionAnswer,
  removeSubjectsHeader,
} from '@attest/editor-card'
import {
  DISPLAY_LOGIC_ANSWER_OPERATOR,
  getDisplayLogicTargetsForAnswer,
  getDisplayLogicTargetsForSubject,
  useEditorDisplayLogicStore,
} from '@attest/editor-display-logic'
import {
  canMaskCard,
  generateForwardedAnswersForChoiceQuestion,
  generateForwardedHeadersForGridQuestion,
  getMaskingReceivers,
  getUpdatedAnswerPositions,
  getUpdatedGridHeaderPositions,
  isCardPotentialSender,
} from '@attest/editor-masking'
import {
  createRoutingGraph,
  type EdgeInformation,
  getAllLinksFromCard,
  getCardsByServingOrder,
  getNumberOfPathsInGraph,
  isCardFollowedBy,
  mapAllNextGuids,
  type RoutingGraph,
} from '@attest/editor-routing-graph'
import { SETTINGS } from '@attest/editor-settings'
import { getVariation } from '@attest/feature-switch'
import type { Recurrence, SurveyStatus } from '@attest/survey'
import { isDefined } from '@attest/util'

import {
  findFirstRight,
  flattenCards,
  getCardByGuid as getCardByGuidHelper,
  getCardGroupedIn,
  getCardNumbersFromOrderedCards,
  getCardsDirectlyToRight,
  getCardsOnRow,
  getFreePositionAfterLastCardOnRow,
  getIdDisplay,
  getLeftCard,
  getNextFreePositionOnRow,
  getNumberQuestions,
  getSurveyCardById,
  hasCompleteTranslationsForLanguage,
  isCardRouteImmediateRight,
  sortCardsInRows,
  updateMessageType,
  updateQuestionType,
} from './card-service'
import { getCardTitleWithUpdatedShortcodes, parsePipedQuestionsFromTitle } from './piping-util'

type EditorStatus = 'draft' | 'published' | SurveyStatus

export type SurveyCardRecord = Record<CardGuid, Card>

export type SurveyTranslationField = 'title'

export type SurveyTranslations = {
  [country_language_key: string]: {
    [field in SurveyTranslationField]: string
  }
}

export type Viewership = 'me' | 'team' | 'shared' | 'admin'

export type Survey = {
  cards: SurveyCardRecord
}

export type EditorSurveyState = {
  title: string
  internalTitle: string
  titleImage: ImageMedia | null
  survey: Survey
  selectedCardGuid: string | null
  selectedGroupedCardGuid: string | null
  cardGuidToApiNodeGuid: Map<string, string>
  cardGuidToPipingSourceGuids: Record<string, string[]>
  answerAndCardGuidToApiAnswerGuid: { [cardGuid: string]: { [answerGuid: string]: string } }
  subjectAndCardGuidToApiSubjectGuid: { [cardGuid: string]: { [subjectGuid: string]: string } }
  surveyIsQueuedForUpdate: boolean
  answerQuotas: Record<AnswerQuotaId, AnswerQuota>
  recurrence: Recurrence | null
  version: number
  makerGuid: string | null
  createdTimestamp: number | null
  guid: string | null
  isShared: boolean
  viewership: Viewership
  timeZoneId: string | null
  status: EditorStatus
  startTimestamp: number | null
  translations: SurveyTranslations
  shouldShowTranslationContentChangeWarning: boolean
  shouldShowQualRestrictedAudienceInformation: boolean
}

export type CardUpdate = DeepPartial<Card> & {
  guid: string
  question?: CardQuestionUpdate
  media?: Media | null
}

export type CardQuestionUpdate = DeepPartial<CardQuestion> & {
  subjects?: { headers?: Header[] }
  options?: { answers?: OptionAnswer[] }
}

export const EDITOR_SURVEY_STORE_NAMESPACE = 'editorSurvey'

export function createEditorSurveyState(
  override: Partial<EditorSurveyState> = {},
): EditorSurveyState {
  return {
    title: '',
    internalTitle: '',
    titleImage: null,
    survey: {
      cards: {},
    },
    answerQuotas: {},
    cardGuidToApiNodeGuid: new Map<string, string>(),
    cardGuidToPipingSourceGuids: {},
    answerAndCardGuidToApiAnswerGuid: {},
    subjectAndCardGuidToApiSubjectGuid: {},
    selectedCardGuid: null,
    selectedGroupedCardGuid: null,
    surveyIsQueuedForUpdate: false,
    recurrence: null,
    version: SETTINGS.DEFAULT_VERSION,
    makerGuid: null,
    createdTimestamp: null,
    guid: null,
    isShared: false,
    viewership: 'me',
    timeZoneId: null,
    status: 'draft',
    startTimestamp: null,
    translations: {},
    shouldShowTranslationContentChangeWarning: false,
    shouldShowQualRestrictedAudienceInformation: true,
    ...override,
  }
}

export const useEditorSurveyStore = defineStore(EDITOR_SURVEY_STORE_NAMESPACE, {
  state: createEditorSurveyState,
  actions: {
    setTitle(title: string): void {
      this.title = title
    },

    setInternalTitle(internalTitle: string): void {
      this.internalTitle = internalTitle
    },

    setTitleImage(titleImage: ImageMedia | null): void {
      this.titleImage = titleImage
    },

    setSurvey(survey: Survey): void {
      this.survey = survey
    },

    setSurveyTranslations(translations: SurveyTranslations): void {
      this.translations = translations
      this.shouldShowTranslationContentChangeWarning = true
    },

    addCard(newCard: Card): void {
      this.survey.cards[newCard.guid] = newCard
      this.setGridVersionFlagOnCard(newCard)
      this.updateCardNexts()
      this.updateCardsPiping()
    },

    updateCard(card: Omit<CardUpdate, 'text' | 'question' | 'media'>): void {
      const cardToUpdate = this.getCardByGuid({ guid: card.guid })
      if (cardToUpdate) {
        Object.assign(cardToUpdate, card)
      }
    },

    setGridVersionFlagOnCard(card: Card): void {
      const cardToUpdate = this.getCardByGuid({ guid: card.guid })
      if (cardToUpdate === undefined) {
        return
      }
      if (cardToUpdate.question.type === 'grid') {
        cardToUpdate.shouldUseNewGridTransformer = getVariation('grid-v2')
      }
    },

    addEmptyTextCard(position?: CardPosition): Card {
      const card = createEmptyTextCard({ position })
      this.addCard(card)
      return card
    },

    addEmptyQuestionCard(position: CardPosition): Card {
      const card = createEmptyCard({ position })
      this.addCard(card)
      return card
    },

    addEmptyGroupCard(position: CardPosition): Card {
      const card = createEmptyGroupCard({ position })
      this.addCard(card)
      return card
    },

    addCardsToEndOfSurvey(cards: Card[]): void {
      const lastCard = this.cards[this.cards.length - 1]
      const nextPositionX = lastCard ? lastCard.position.x + 1 : 0
      for (const [index, card] of cards.entries()) {
        this.addCard({
          ...card,
          position: {
            x: nextPositionX + index,
            y: lastCard?.position.y ?? 0,
          },
        })
      }
      this.selectedCardGuid = cards[0]?.guid ?? null
    },

    deleteCard(guid: string): void {
      const displayLogicStore = useEditorDisplayLogicStore()
      const cardToDelete = this.getCardByGuid({ guid })
      if (cardToDelete === undefined) {
        return
      }
      const parents = this.cards.filter(card => {
        return getAllLinksFromCard(card).some(({ toGuid }) => cardToDelete.guid === toGuid)
      })
      for (const parent of parents) {
        this.updateCard(
          mapAllNextGuids(parent, nextGuid => (nextGuid === cardToDelete.guid ? null : nextGuid)),
        )
      }

      getMaskingReceivers(cardToDelete, this.cards).forEach(maskingReceiverCard => {
        maskingReceiverCard.question.options.maskingQuestion = null
        maskingReceiverCard.question.options.answers.forEach(answer => (answer.mask = null))
      })

      delete this.survey.cards[guid]
      this.shiftRightNeighboursLeft(cardToDelete.position)
      this.updateCardNexts()
      this.updateCardsPiping()
      displayLogicStore.deleteDisplayLogicRule(guid)
      displayLogicStore.removeDisplayLogicConditionsByReferenceQuestion(guid)
    },

    duplicateCard(guid: string): Card | undefined {
      const displayLogicStore = useEditorDisplayLogicStore()
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return undefined
      }
      const duplicatedCard = duplicateCard(
        { ...card, nextGuid: null },
        { ...card.position, x: card.position.x + 1 },
      )
      const cardsOnRight = getCardsDirectlyToRight(card, this.cards)

      cardsOnRight.forEach(cardRight => this.incrementXPosition(cardRight.guid))

      if (card.nextGuid === cardsOnRight[0]?.guid) {
        card.nextGuid = duplicatedCard.guid
      }
      const ruleForCard = displayLogicStore.guidToDisplayLogicRule[guid]
      if (ruleForCard !== undefined) {
        displayLogicStore.setDisplayLogicRule(duplicatedCard.guid, {
          ...ruleForCard,
          guid: duplicatedCard.guid,
        })
      }
      this.deleteAnswerQuotasFromQuestion(duplicatedCard)
      this.addCard(duplicatedCard)

      return duplicatedCard
    },

    setCardRoute({ guid, nextGuid }: { guid: CardGuid; nextGuid: CardGuid | null }): void {
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return
      }
      this.updateCard({ ...card, nextGuid })
    },

    setCardTitle({ guid, title }: { guid: CardGuid; title: string }): void {
      const card = this.getCardByGuid({ guid })
      if (!card) return
      card.title = title
    },

    updateCardNexts(): void {
      const rows = sortCardsInRows(this.cards)
      for (const row of rows) {
        for (const [index, card] of row.entries()) {
          const nextCardInRow = row[index + 1]
          const nextGuid = nextCardInRow !== undefined ? nextCardInRow.guid : null
          if (card.nextGuid && !isCardRouteImmediateRight(card, row)) {
            continue
          }
          if (
            card.nextGuid &&
            card.position.y !== this.getCardByGuid({ guid: card.nextGuid })?.position.y
          ) {
            continue
          }
          const cardToUpdate = this.getCardByGuid({ guid: card.guid })
          if (cardToUpdate === undefined) {
            continue
          }
          if (card.nextGuid !== nextGuid) {
            cardToUpdate.nextGuid = nextGuid
          }
        }
      }
    },

    shiftRightNeighboursLeft(initialPosition: CardPosition): void {
      function shift(position: CardPosition, cards: Card[]): void {
        const cardsOnSameRow = getCardsOnRow(cards, position.y)

        const getCardToImmediateRight = (nextCardXPosition: number): Card | undefined =>
          cardsOnSameRow.find(card => card.position.x === nextCardXPosition)

        const cardToImmediateRight = getCardToImmediateRight(position.x + 1)
        if (!cardToImmediateRight) return

        const nextPosition = { ...cardToImmediateRight.position }

        Object.assign(cardToImmediateRight.position, {
          x: nextPosition.x - 1,
        })

        const cardToNextImmediateRight = getCardToImmediateRight(nextPosition.x + 1)
        if (cardToNextImmediateRight) shift(nextPosition, cards)
      }

      shift(initialPosition, this.cards)
    },

    updateCardPosition(options: { guid: CardGuid; position: CardPosition }): Card | undefined {
      const { guid, position } = options
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return undefined
      }

      Object.assign(card.position, position)

      const leftCard = getLeftCard(card, this.cards)
      if (leftCard) {
        this.updateCard({
          guid: leftCard.guid,
          nextGuid: card.guid,
        })
      }

      this.removeCardRoutes(card.guid)
      this.updateCardNexts()
      this.updateCardsPiping()
      this.removeInvalidDisplayLogic()

      return card
    },

    removeInvalidDisplayLogic(): void {
      const displayLogicStore = useEditorDisplayLogicStore()
      for (const card of this.cards) {
        const displayLogicForCard = displayLogicStore.guidToDisplayLogicRule[card.guid]
        if (displayLogicForCard === undefined) {
          continue
        }
        const invalidDisplayLogic = displayLogicForCard.conditions.some(
          condition =>
            !isCardFollowedBy(this.routingGraphAndCycles.graph, condition.referenceGuid, card.guid),
        )
        if (invalidDisplayLogic) {
          displayLogicStore.deleteDisplayLogicRule(card.guid)
        }
      }
    },

    decrementXPositions(guids: CardGuid[]): void {
      guids.forEach(guid => {
        const nextCard = this.cards.find(card => card.guid === guid)
        if (nextCard) nextCard.position.x -= 1
      })
      this.updateCardNexts()
      this.updateCardsPiping()
    },

    incrementXPositions(guids: CardGuid[]): void {
      guids.forEach(guid => {
        const nextCard = this.cards.find(card => card.guid === guid)
        if (nextCard) this.incrementXPosition(nextCard.guid)
      })
      this.updateCardNexts()
      this.updateCardsPiping()
    },

    incrementXPosition(guid: string): void {
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return
      }
      card.position.x += 1
    },

    removeCardRoutes(cardGuid: CardGuid): void {
      this.cards
        .filter(({ guid, nextGuid }) => !!nextGuid && (nextGuid === cardGuid || guid === cardGuid))
        .forEach(card => {
          this.updateCard({
            guid: card.guid,
            nextGuid: null,
          })
        })
    },

    deleteGroupedCard(guid: CardGuid, groupedCardGuid: CardGuid): void {
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return
      }
      const updatedGroupCards = card.group.cards.filter(c => c.guid !== groupedCardGuid)

      this.updateCard({
        ...card,
        group: {
          ...card.group,
          cards: updatedGroupCards,
        },
      })
    },

    addCardToGroup(groupGuid: CardGuid, card: Card): void {
      const groupCard = getSurveyCardById(this.survey, groupGuid)
      this.updateCard({
        ...groupCard,
        group: {
          ...groupCard.group,
          cards: [...groupCard.group.cards, card],
        },
      })
      this.setGridVersionFlagOnCard(card)
    },

    addEmptyGroupQuestionCard(guid: CardGuid): Card {
      const questionCard = createEmptyCard()
      this.addCardToGroup(guid, questionCard)
      return questionCard
    },

    addEmptyGroupTextCard(guid: CardGuid): Card {
      const textCard = createEmptyTextCard()
      this.addCardToGroup(guid, textCard)
      return textCard
    },

    duplicateGroupedCard(guid: CardGuid, groupedCardGuid: CardGuid): Card | void {
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return
      }
      const { cards } = card.group

      const groupedCardIndex = card.group.cards.findIndex(c => c.guid === groupedCardGuid)
      const groupedCard = card.group.cards[groupedCardIndex]

      if (groupedCard === undefined) {
        return
      }

      const groupedCards = [...cards]
      const duplicatedCard = duplicateCard(groupedCard)
      groupedCards.splice(groupedCardIndex + 1, 0, duplicatedCard)

      const updatedCard = {
        ...card,
        group: {
          ...card.group,
          cards: groupedCards,
        },
      }
      this.updateCard(updatedCard)
      return duplicatedCard
    },

    moveCardIntoGroup(card: Card, groupCardGuid: CardGuid): Card | void {
      const isGroupedCardGuid = this.getGroupedCardGuids.has(card.guid)

      const cardCopy = {
        ...card,
        nextGuid: null,
        isRandomized: false,
      }

      if (cardCopy.question) {
        cardCopy.question = {
          ...cardCopy.question,
          options: {
            ...cardCopy.question.options,
            isQualifying: false,
            maskingQuestion: null,
            answers: cardCopy.question.options.answers.map(answer => ({
              ...answer,
              isQualifying: false,
              nextGuid: null,
              mask: null,
            })),
          },
        }
      }

      if (isGroupedCardGuid) {
        const parentGroupCard = getCardGroupedIn(this.cards, card.guid)
        this.deleteGroupedCard(parentGroupCard.guid, card.guid)
      } else {
        this.deleteCard(card.guid)
      }

      const displayLogicStore = useEditorDisplayLogicStore()
      if (displayLogicStore.guidToDisplayLogicRule[card.guid]) {
        displayLogicStore.deleteDisplayLogicRule(card.guid)
      }

      this.addCardToGroup(groupCardGuid, cardCopy)
      return cardCopy
    },

    moveCardOutOfGroup(card: Card): Card | void {
      const isGroupedCardGuid = this.getGroupedCardGuids.has(card.guid)
      if (!isGroupedCardGuid) return
      const parentGroupCard = getCardGroupedIn(this.cards, card.guid)

      const cardCopy = {
        ...card,
        position: getNextFreePositionOnRow(
          this.orderedCards.at(-1)?.position ?? {
            x: 0,
            y: 0,
          },
          this.orderedCards,
        ),
      }

      this.deleteGroupedCard(parentGroupCard.guid, card.guid)
      this.addCard(cardCopy)
      return cardCopy
    },

    updateCardAndReferences(payload: CardUpdate): void {
      const { guid, question, text, position, media, ...card } = payload
      const cardToUpdate = this.getCardByGuid({ guid })
      if (cardToUpdate === undefined) {
        return
      }
      this.updateCard({ guid, ...card })
      if (question) this.updateCardQuestion({ guid, question })
      if (media !== undefined) this.updateCardMedia({ guid, media })
      const x = position?.x
      const y = position?.y
      if (
        x !== undefined &&
        y !== undefined &&
        (cardToUpdate.position.x !== x || cardToUpdate.position.y !== y)
      )
        this.updateCardPosition({
          guid,
          position: { x, y },
        })
      if (text && text.text) this.updateCardText({ guid, text: text.text })
      if (payload.nextGuid !== cardToUpdate.nextGuid) this.updateCardNexts()
    },

    updateCardQuestion(payload: { guid: CardGuid; question: CardQuestionUpdate }): void {
      const card = this.getCardByGuid({ guid: payload.guid })
      if (card === undefined) {
        return
      }
      const { subjects, options, ...restQuestion } = payload.question
      Object.assign(card.question, restQuestion)

      if (subjects) Object.assign(card.question.subjects, subjects)
      if (options) Object.assign(card.question.options, options)

      this.updateMaskingReceivers({
        sender: card,
        cards: this.cards,
      })
    },

    updateCardText(payload: { guid: CardGuid; text: string }): void {
      const card = this.getCardByGuid({ guid: payload.guid })
      if (card === undefined) {
        return
      }
      Object.assign(card.text, { text: payload.text, type: 'text' })
    },

    updateCardMedia(payload: { guid: string; media: Media | null }): void {
      const card = this.getCardByGuid({ guid: payload.guid })
      if (card === undefined) {
        return
      }
      if (card.media && payload.media) {
        Object.assign(card.media, payload.media)
        return
      }
      card.media = payload.media ?? null
    },

    setAnswers(payload: { cardGuid: CardGuid; answers: OptionAnswer[] }): void {
      const card = this.getCardByGuid({ guid: payload.cardGuid })
      if (card === undefined) {
        return
      }
      card.question.options.answers = payload.answers
    },

    setCardRandomized(payload: { guid: CardGuid; isRandomized: boolean }): void {
      const card = this.getCardByGuid({ guid: payload.guid })
      if (card === undefined) {
        return
      }
      card.isRandomized = payload.isRandomized
    },

    setQuestionMaxSelections(payload: {
      cardGuid: CardGuid
      maxSelectionLimit: CardQuestionOptions['maxSelections']
    }): void {
      const card = this.getCardByGuid({ guid: payload.cardGuid })
      if (card === undefined) {
        return
      }
      card.question.options.maxSelections = payload.maxSelectionLimit
    },

    setAnswerNext({
      cardGuid,
      answerId,
      nextGuid,
    }: {
      cardGuid: CardGuid
      answerId: string
      nextGuid: CardGuid | null
    }): void {
      const answer = this.getCardByGuid({ guid: cardGuid })?.question.options.answers.find(
        ({ id }) => id === answerId,
      )
      if (!answer) return
      answer.nextGuid = nextGuid
    },

    setAnswerText({
      cardGuid,
      answerId,
      value,
    }: {
      cardGuid: CardGuid
      answerId: string
      value: string
    }): void {
      const answer = this.getCardByGuid({ guid: cardGuid })?.question.options.answers.find(
        ({ id }) => id === answerId,
      )
      if (!answer) return
      answer.text = value
    },

    updateAnswerQualifying({
      cardGuid,
      answerId,
      isQualifying,
    }: {
      cardGuid: CardGuid
      answerId: string
      isQualifying: boolean
    }): void {
      const card = this.getCardByGuid({ guid: cardGuid })
      if (card === undefined) {
        return
      }
      const answer = card.question.options.answers.find(({ id }) => id === answerId)
      if (answer === undefined) {
        return
      }

      answer.isQualifying = isQualifying
      if (!isQualifying) {
        this.deleteAnswerQuota({
          card,
          answerId,
        })
      }

      const cardIsQualiyfing = card.question.options.answers.some(a => a.isQualifying)
      card.question.options.isQualifying = cardIsQualiyfing

      if (!cardIsQualiyfing) {
        this.deleteAnswerQuotasFromQuestion(card)
      }
      if (cardIsQualiyfing) {
        this.setAnswers({
          cardGuid,
          answers: card.question.options.answers.map(a => {
            return {
              ...a,
              nextGuid: a.isQualifying ? a.nextGuid : null,
            }
          }),
        })
      }
    },

    removeAnswerOption({ cardGuid, index }: { cardGuid: CardGuid; index: number }): void {
      const displayLogicStore = useEditorDisplayLogicStore()

      const card = this.getCardByGuid({ guid: cardGuid })
      if (card === undefined) {
        return
      }
      const { options } = card.question ?? {}
      if (!options) {
        return
      }
      const answer = options.answers[index]
      if (!answer) {
        return
      }

      if (answer.quotaId) {
        this.deleteAnswerQuota({ card, answerId: answer.id })
      }

      getDisplayLogicTargetsForAnswer(
        answer.id,
        Object.values(displayLogicStore.guidToDisplayLogicRule),
      ).forEach(guid =>
        displayLogicStore.removeDisplayLogicConditionsByAnswer(guid, cardGuid, answer.id),
      )
      getMaskingReceivers(card, this.cards).forEach(receiver =>
        receiver.question.type === 'grid'
          ? this.removeSubjectHeader({ cardGuid: receiver.guid, headerIndex: index })
          : this.removeAnswerOption({ cardGuid: receiver.guid, index }),
      )

      this.updateCardQuestion({
        guid: cardGuid,
        question: { ...card?.question, options: removeOptionAnswer(options, index) },
      })
    },

    removeSubjectHeader({ cardGuid, headerIndex }: { cardGuid: CardGuid; headerIndex: number }) {
      const displayLogicStore = useEditorDisplayLogicStore()
      const card = this.getCardByGuid({ guid: cardGuid })
      const { subjects } = card?.question ?? {}
      if (!subjects) {
        return
      }
      const header = subjects.headers[headerIndex]

      if (header === undefined) {
        return
      }

      getDisplayLogicTargetsForSubject(
        header.id,
        Object.values(displayLogicStore.guidToDisplayLogicRule),
      ).forEach(guid =>
        displayLogicStore.removeDisplayLogicConditionsBySubject(guid, cardGuid, header.id),
      )
      this.updateCardQuestion({
        guid: cardGuid,
        question: { ...card?.question, subjects: removeSubjectsHeader(subjects, headerIndex) },
      })
    },

    setAnswerVisibilityForAudience({
      cardGuid,
      audienceId,
      answerId,
      visible,
    }: {
      cardGuid: string
      audienceId: string
      answerId: string
      visible: boolean
    }): void {
      const card = this.getCardByGuid({ guid: cardGuid })
      if (card === undefined) {
        return
      }
      const answer = card.question.options.answers.find(a => a.id === answerId)
      if (answer === undefined) {
        return
      }
      if (visible) {
        answer.omittedFromAudiences.delete(audienceId)
      } else {
        answer.omittedFromAudiences.add(audienceId)
      }
    },

    setCardLocalisation({
      cardGuid,
      omittedFromAudiences,
    }: {
      cardGuid: string
      omittedFromAudiences: Set<string>
    }): void {
      const cardBeingEdited = this.getCardByGuid({ guid: cardGuid })
      if (cardBeingEdited === undefined) {
        return
      }

      const maskingReceivers = getMaskingReceivers(cardBeingEdited, this.cards)
      const cardsToUpdate = [...maskingReceivers, cardBeingEdited]

      for (const card of cardsToUpdate) {
        card.omittedFromAudiences = new Set([...omittedFromAudiences])
      }
    },

    setSubjectVisibilityForAudience({
      cardGuid,
      audienceId,
      subjectId,
      visible,
    }: {
      cardGuid: string
      audienceId: string
      subjectId: string
      visible: boolean
    }): void {
      const card = this.getCardByGuid({ guid: cardGuid })
      if (card === undefined) {
        return
      }
      const subject = card.question.subjects.headers.find(h => h.id === subjectId)
      if (subject === undefined) {
        return
      }
      if (visible) {
        subject.omittedFromAudiences.delete(audienceId)
      } else {
        subject.omittedFromAudiences.add(audienceId)
      }
    },

    resetLocalisation(): void {
      for (const card of flattenCards(this.cards)) {
        card.mediaOverrides = {}
        card.omittedFromAudiences.clear()
        for (const answer of card.question.options.answers) {
          answer.omittedFromAudiences.clear()
          answer.mediaOverrides = {}
        }
        for (const subject of card.question.subjects.headers) {
          subject.omittedFromAudiences.clear()
        }
      }
    },

    removeLocalisationForAudience(payload: { audienceId: string }): void {
      for (const card of flattenCards(this.cards)) {
        delete card.mediaOverrides[payload.audienceId]
        card.omittedFromAudiences.delete(payload.audienceId)
        for (const answer of card.question.options.answers) {
          answer.omittedFromAudiences.delete(payload.audienceId)
          delete answer.mediaOverrides[payload.audienceId]
        }
        for (const subject of card.question.subjects.headers) {
          subject.omittedFromAudiences.delete(payload.audienceId)
        }
      }
    },

    updateAnswerOrder({
      cardGuid,
      updatedPositions,
    }: {
      cardGuid: CardGuid
      updatedPositions: Record<string, number>
    }): void {
      const card = this.getCardByGuid({ guid: cardGuid })
      if (card === undefined) {
        return
      }
      this.setAnswers({
        cardGuid,
        answers: card.question.options.answers.toSorted(
          (a, b) => (updatedPositions[a.id] ?? 0) - (updatedPositions[b.id] ?? 0),
        ),
      })
      this.updateMaskingReceiverAnswerOrders({
        sender: card,
        updatedPositions,
        cards: this.cards,
      })
    },

    updateSubjectOrder({
      cardGuid,
      updatedPositions,
    }: {
      cardGuid: CardGuid
      updatedPositions: Record<string, number>
    }): void {
      const card = this.getCardByGuid({ guid: cardGuid })
      if (card === undefined) {
        return
      }
      card.question.subjects.headers = card.question.subjects.headers.toSorted(
        (a, b) => (updatedPositions[a.id] ?? 0) - (updatedPositions[b.id] ?? 0),
      )
    },

    setSelectedCardGuid(guid: string | null): void {
      if (guid === null) {
        this.selectedGroupedCardGuid = null
        this.selectedCardGuid = null
        return
      }

      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return
      }
      const isGroupedCardGuid = this.getGroupedCardGuids.has(guid)
      const isGroupCardGuid = isGroupCard(card)
      if (isGroupedCardGuid) {
        const parentGroupCard = getCardGroupedIn(this.cards, guid)
        this.selectedGroupedCardGuid = guid
        this.selectedCardGuid = parentGroupCard.guid
        return
      }

      if (isGroupCardGuid) {
        const currentlySelectedGroupedCard =
          this.selectedCardGuid === guid
            ? card.group.cards.find(c => c.guid === this.selectedGroupedCardGuid)
            : undefined
        if (currentlySelectedGroupedCard !== undefined) {
          return
        }
        const firstGroupedCardGuid = card.group.cards[0]?.guid ?? null
        this.selectedGroupedCardGuid = firstGroupedCardGuid
        this.selectedCardGuid = guid
        return
      }
      if (this.selectedCardGuid === guid && isGroupCardGuid) {
        return
      }

      this.selectedGroupedCardGuid = null
      this.selectedCardGuid = guid
    },

    updateQuestionType({ guid, types }: { guid: CardGuid; types: CombinedCardTypeQuestion }): void {
      const card = this.getCardByGuid({ guid })
      if (card === undefined) {
        return
      }
      const oldTypes = createCombinedCardType(card.type, card.question.type)
      if (oldTypes.subType === types.subType) {
        return
      }

      if (oldTypes.type === 'text') {
        card.title = card.text.text
      }

      this.updateCard({ ...updateQuestionType(card, types), guid })

      if (
        types.type === 'question' &&
        types.subType !== 'single_choice' &&
        types.subType !== 'multiple_choice'
      ) {
        card.question.options.isQualifying = false
        for (const answer of card.question.options.answers) {
          answer.isQualifying = false
        }
      }

      if (!isChoiceCardQuestionType(types.subType)) {
        useEditorDisplayLogicStore().removeDisplayLogicConditionsByReferenceQuestion(guid)
        this.deleteAnswerQuotasFromQuestion(card)
      }

      if (oldTypes.subType === 'grid') {
        useEditorDisplayLogicStore().removeDisplayLogicConditionsByReferenceQuestion(guid)
      }
      if (types.subType === 'grid' && card.question.options.maskingQuestion?.isToggled) {
        card.question.options.answers.forEach(answer => {
          answer.media = null
        })
        card.question.subjects.headers.forEach(header => {
          header.isPinned = false
        })
        card.question.options.isImage = false
      }
      if (types.subType === 'single_choice')
        useEditorDisplayLogicStore().updateDisplayLogicConditionsByReferenceQuestion(guid, {
          answerOperator: DISPLAY_LOGIC_ANSWER_OPERATOR.ANY_SELECTED,
        })

      if (types.subType !== 'multiple_choice') {
        getMaskingReceivers(card, this.cards).forEach(maskingReceiverCard => {
          maskingReceiverCard.question.options.maskingQuestion = null
          maskingReceiverCard.question.options.answers.forEach(answer => (answer.mask = null))
        })
      }

      if (card.question.options.maskingQuestion?.isToggled) {
        this.toggleQuestionMasking({
          isToggled: canMaskCard(card),
          receiver: card,
          cards: this.cards,
        })
      }
    },

    updateTextType({ guid }: { guid: CardGuid }): void {
      const cardToUpdate = this.getCardByGuid({ guid })
      if (cardToUpdate === undefined) {
        return
      }
      const card = updateMessageType(cardToUpdate)
      this.updateCard({ ...card, guid })

      useEditorDisplayLogicStore().removeDisplayLogicConditionsByReferenceQuestion(guid)
      const updatedCard = this.getCardByGuid({ guid })
      if (updatedCard === undefined) {
        return
      }
      getMaskingReceivers(updatedCard, this.cards).forEach(maskingReceiverCard => {
        maskingReceiverCard.question.options.maskingQuestion = null
        maskingReceiverCard.question.options.answers.forEach(answer => (answer.mask = null))
      })
    },

    setAnswerQuotas(answerQuotas: Record<AnswerQuotaId, AnswerQuota>): void {
      this.answerQuotas = answerQuotas
    },

    deleteAnswerQuota(options: { card: Card; answerId: string }): void {
      const { card, answerId } = options
      const answer = card.question.options.answers.find(({ id }) => id === answerId)
      if (!answer) {
        throw new Error(`editor commit deleteAnswerQuota: no answer with id ${answerId}`)
      }
      if (answer.quotaId) delete this.answerQuotas[answer.quotaId]
      answer.quotaId = null
    },

    deleteAnswerQuotasFromQuestion(card: Card) {
      for (const answer of card.question.options.answers) {
        this.deleteAnswerQuota({ card, answerId: answer.id })
      }
    },

    updateAnswerQuota({
      card,
      answerId,
      minTarget,
    }: {
      card: Card
      answerId: string
      minTarget: number
    }) {
      const answer = card.question.options.answers.find(({ id }) => id === answerId)
      if (!answer) {
        throw new Error(`editor commit updateAnswerQuota: no answer with id ${answerId}`)
      }
      const answerQuota = answer.quotaId ? this.answerQuotas[answer.quotaId] : undefined
      const quota = answerQuota ? { ...answerQuota, minTarget } : createAnswerQuota({ minTarget })

      answer.quotaId = quota.id
      this.answerQuotas[quota.id] = quota
    },

    setCardGuidToApiNodeGuid(cardGuidToApiNodeGuid: Map<string, string>): void {
      this.cardGuidToApiNodeGuid = cardGuidToApiNodeGuid
    },

    setAnswerAndCardGuidToApiAnswerGuid(answerAndCardGuidToApiAnswerGuid: {
      [cardGuid: string]: { [answerGuid: string]: string }
    }) {
      this.answerAndCardGuidToApiAnswerGuid = answerAndCardGuidToApiAnswerGuid
    },

    setSubjectAndCardGuidToApiSubjectGuid(subjectAndCardGuidToApiSubjectGuid: {
      [cardGuid: string]: { [subjectGuid: string]: string }
    }) {
      this.subjectAndCardGuidToApiSubjectGuid = subjectAndCardGuidToApiSubjectGuid
    },

    toggleQuestionMasking({
      isToggled,
      receiver,
      cards,
      sender = (() => {
        const { maskingQuestion } = receiver.question.options
        if (maskingQuestion) return cards.find(({ guid }) => guid === maskingQuestion.senderGuid)
        return cards.find(
          ({ guid, question: { type } }) => guid !== receiver.guid && type === 'multiple_choice',
        )
      })(),
    }: {
      isToggled: boolean
      receiver: Card
      sender?: Card
      cards: Card[]
    }): void {
      if (!sender) return
      this.updateMaskingReceiver({ sender, receiver, isToggled, cards })
    },

    updateMaskingReceiver({
      sender,
      receiver,
      cards,
      isToggled,
    }: {
      isToggled: boolean
      sender: Card
      receiver: Card
      cards: Card[]
    }) {
      if (!isCardPotentialSender(sender)) {
        receiver.question.options.maskingQuestion = null
        receiver.question.options.answers.forEach(answer => (answer.mask = null))
        return
      }
      sender.question.hasOther = false
      receiver.question.options.maskingQuestion = { isToggled, senderGuid: sender.guid }
      receiver.omittedFromAudiences = new Set([...sender.omittedFromAudiences])

      if (receiver.question.type === 'grid') {
        this.setMaskingGridQuestion({
          receiver,
          sender,
          isToggled,
        })
        return
      }

      this.setMaskingChoiceQuestion({
        receiver,
        sender,
        isToggled,
      })
      this.updateMaskingReceivers({ sender: receiver, cards })
    },

    updateMaskingReceivers({ sender, cards }: { sender: Card; cards: Card[] }) {
      getMaskingReceivers(sender, cards).forEach(receiver =>
        this.updateMaskingReceiver({
          sender,
          receiver,
          cards,
          isToggled: receiver.question.options.maskingQuestion?.isToggled ?? false,
        }),
      )
    },

    setMaskingChoiceQuestion({
      sender,
      receiver,
      isToggled,
    }: {
      receiver: Card
      sender: Card
      isToggled: boolean
    }) {
      if (receiver.question.options.maskingQuestion?.isToggled) {
        receiver.question.options.isImage = sender.question.options.isImage
        receiver.question.hasNone = sender.question.hasNone
        receiver.question.hasNa = sender.question.hasNa
        receiver.question.options.isRandomized = sender.question.options.isRandomized
        receiver.question.hasOther = false
      }
      receiver.question.options.answers = generateForwardedAnswersForChoiceQuestion(
        sender,
        receiver,
        isToggled,
      )
    },

    setMaskingGridQuestion({
      sender,
      receiver,
      isToggled,
    }: {
      receiver: Card
      sender: Card
      isToggled: boolean
    }) {
      if (receiver.question.options.maskingQuestion?.isToggled) {
        receiver.question.subjects.isRandomized = sender.question.options.isRandomized
      }
      const headers = generateForwardedHeadersForGridQuestion(sender, receiver)
      receiver.question.subjects.headers = isToggled
        ? headers.map(header => ({
            ...header,
            isPinned: false,
            omittedFromAudiences: new Set<string>(),
          }))
        : headers
            .map(header => ({
              ...header,
              text: '',
              isPinned: false,
              omittedFromAudiences: new Set<string>(),
            }))
            .slice(0, 10)
    },

    updateMaskingReceiverAnswerOrders({
      sender,
      cards,
      updatedPositions,
    }: {
      sender: Card
      cards: Card[]
      updatedPositions: Record<string, number>
    }) {
      getMaskingReceivers(sender, cards).forEach(receiver => {
        if (receiver.question.type === 'grid') {
          receiver.question.subjects.headers = getUpdatedGridHeaderPositions(
            receiver,
            updatedPositions,
          )
          return
        }
        receiver.question.options.answers = getUpdatedAnswerPositions(receiver, updatedPositions)
        this.updateMaskingReceiverAnswerOrders({
          sender: receiver,
          cards,
          updatedPositions: Object.fromEntries(
            receiver.question.options.answers.map((answer, index) => [answer.id, index]),
          ),
        })
      })
    },

    addPipeFromCard({
      card,
      pipingSourceGuid,
    }: {
      card: Card
      pipingSourceGuid: CardGuid
    }): Card | undefined {
      const pipeFromIndex = this.cardNumbers[pipingSourceGuid]
      const title = card.title === '' ? `{Q${pipeFromIndex}}` : `${card.title} {Q${pipeFromIndex}}`
      this.cardGuidToPipingSourceGuids[card.guid] = [
        ...(this.cardGuidToPipingSourceGuids[card.guid] ?? []),
        pipingSourceGuid,
      ]
      this.updateCardAndReferences({ guid: card.guid, title })
      return this.getCardByGuid({ guid: card.guid })
    },

    updateCardGuidToPipingSourceGuids(): void {
      for (const card of flattenCards(this.cards)) {
        this.cardGuidToPipingSourceGuids[card.guid] = parsePipedQuestionsFromTitle(
          card.title,
          this.orderedCards,
        ).filter(isDefined)
      }
    },

    updateCardsPiping(): void {
      for (const card of flattenCards(this.cards)) {
        const pipingSourceGuids = this.cardGuidToPipingSourceGuids[card.guid]
        if (pipingSourceGuids !== undefined && pipingSourceGuids.length > 0) {
          card.title = getCardTitleWithUpdatedShortcodes(
            card.title,
            pipingSourceGuids,
            this.orderedCards,
            this.cardNumbers,
          )
        }
      }
    },

    setRecurrence(recurrence: Recurrence | null): void {
      this.recurrence = recurrence
    },

    setVersion(version: number): void {
      this.version = version
    },

    setMakerGuid(makerGuid: string | null): void {
      this.makerGuid = makerGuid
    },

    setCreatedTimestamp(createdTimestamp: number | null): void {
      this.createdTimestamp = createdTimestamp
    },

    setGuid(guid: string | null): void {
      this.guid = guid
    },

    setIsShared(isShared: boolean): void {
      this.isShared = isShared
    },

    setViewership(viewership: Viewership): void {
      this.viewership = viewership
    },

    setTimeZoneId(timeZoneId: string | null): void {
      this.timeZoneId = timeZoneId
    },

    setStatus(status: EditorStatus): void {
      this.status = status
    },

    setStartTimestamp(startTimestamp: number | null): void {
      this.startTimestamp = startTimestamp
    },
  },
  getters: {
    cards(): Card[] {
      return Object.values(this.survey.cards)
    },

    orderedCards(): Card[] {
      return getCardsByServingOrder(this.routingGraph, this.cards)
    },

    getCardByGuid(): (options: { guid: string }) => Card | undefined {
      return ({ guid }) => {
        return this.survey.cards[guid] ?? getCardByGuidHelper(this.cards, guid)
      }
    },

    getGroupedCardGuids(): Set<CardGuid> {
      return new Set(
        Object.values(this.survey.cards).flatMap(card => card.group.cards.map(({ guid }) => guid)),
      )
    },

    cardNumbers(): Record<string, number> {
      return getCardNumbersFromOrderedCards(this.orderedCards)
    },

    getCardId(): (options: { guid: string }) => string | undefined {
      return ({ guid }) => {
        const card = this.getCardByGuid({ guid })
        const cardNumber = this.cardNumbers[guid]
        if (cardNumber === undefined || card === undefined) {
          return undefined
        }
        return getIdDisplay(cardNumber, card.type)
      }
    },

    getIsCardRouted(): (cardId: string) => boolean {
      return cardId => {
        const card = this.getCardByGuid({ guid: cardId })
        if (!card?.nextGuid) return false
        const nextCard = this.getCardByGuid({ guid: card.nextGuid })
        if (!nextCard) return false
        return nextCard !== findFirstRight(card, this.cards)
      }
    },

    numberOfRoutes(): number {
      return getNumberOfPathsInGraph(this.routingGraphAndCycles.graph)
    },

    numberOfQuestions(): number {
      return getNumberQuestions(this.cards)
    },

    routingGraphAndCycles(): {
      graph: RoutingGraph
      edgesThatCauseCycles: EdgeInformation[]
    } {
      return createRoutingGraph(this.cards)
    },

    routingGraph(): RoutingGraph {
      return this.routingGraphAndCycles.graph
    },

    nextFreePositionFromSelectedCard(): CardPosition {
      if (!this.selectedCard) return { x: 0, y: 0 }

      return getFreePositionAfterLastCardOnRow(this.selectedCard.position, this.orderedCards)
    },

    selectedCard(): Card | null {
      if (!this.selectedCardGuid) return null
      return this.getCardByGuid({ guid: this.selectedCardGuid }) || null
    },

    getActiveAnswerQuotasForCard(): (cardGuid: string) => AnswerQuota[] {
      return cardId => {
        const card = this.getCardByGuid({ guid: cardId })
        if (card === undefined) {
          return []
        }
        return getActiveAnswerQuotas(card, this.answerQuotas)
      }
    },

    hasAnswerQuotas(): boolean {
      return this.cards.some(card => this.getActiveAnswerQuotasForCard(card.guid).length > 0)
    },

    isSurveyOwner(): boolean {
      return this.viewership === 'me'
    },

    isVideoResponseSurvey(): boolean {
      return flattenCards(this.cards).some(card => card.question.type === 'video')
    },

    hasSurveyTranslations(): boolean {
      return Object.keys(this.translations).length > 0
    },

    hasCompleteTranslationsForLanguage(): (countryLanguage: {
      language: string
      country: string
      audienceIds: string[]
    }) => boolean {
      return countryLanguage => {
        const key = translationKey(countryLanguage)
        if (!this.translations[key]) {
          return false
        }
        return this.cards
          .flatMap(card => (isGroupCard(card) ? card.group.cards : card))
          .every(card =>
            hasCompleteTranslationsForLanguage({
              card,
              countryLanguage,
              routingGraph: this.routingGraphAndCycles.graph,
            }),
          )
      }
    },
    audiencesWithLocalisations(): Set<string> {
      return new Set(
        flattenCards(this.cards)
          .map(card =>
            card.question.options.answers
              .map(answer => [
                ...answer.omittedFromAudiences,
                ...Object.keys(answer.mediaOverrides),
              ])
              .concat(
                card.question.subjects.headers.map(subject => [...subject.omittedFromAudiences]),
              )
              .concat(Object.keys(card.mediaOverrides))
              .concat([...card.omittedFromAudiences]),
          )
          .flat(2),
      )
    },

    someCardIsPublished(): boolean {
      return this.cards.some(
        card =>
          card.publishedTimestamp !== undefined ||
          card.group.cards.some(groupedCard => groupedCard.publishedTimestamp !== undefined),
      )
    },
  },
})
