import { createEntity, type Entity } from './base'

export type ValidationEntity<D extends ValidationEntityData = ValidationEntityData> = {} & Entity<D>
type ValidationEntityData = {
  id: string
  type: string
  description?: string
}

export function createValidationEntity({
  id,
  type,
  description,
}: ValidationEntityData): ValidationEntity {
  return createEntity({ name: 'validation', version: '1-0-0' }, { id, type, description })
}
