import { toString } from 'ramda'

import {
  isRangeFilters,
  useCardResponseFiltersStore,
  useResultsDemographicFiltersStore,
} from '@attest/results-saved-filters'
import {
  type ChartStateEntity,
  createChartStateEntity,
  createExportEntity,
  createFilterGroupEntity,
  createRangeFilterEntity,
  createSelectFilterEntity,
  type Entity,
  type ExportEntity,
} from '@attest/tracking'
import { never } from '@attest/util'

import { useStudyWaveAudienceFiltersStore, useStudyWaveFiltersStore } from './stores'

type ChartType =
  | 'line'
  | 'column'
  | 'stacked-column'
  | 'nps'
  | 'text-column'
  | 'ranked-line'
  | 'ranked-column'
  | 'pie'
  | 'stacked'

export function createStudyInsightsChartStateEntity(data: { type: ChartType }): ChartStateEntity {
  return createChartStateEntity({ type: chartTypeToChartStateType(data.type) })
}

export function createStudyInsightsChartExportEntity(): ExportEntity {
  return createExportEntity({ id: 'trend-chart', format: 'png' })
}

export function chartTypeToChartStateType(type: ChartType): ChartStateEntity['data']['type'] {
  switch (type) {
    case 'line':
    case 'nps':
    case 'ranked-line':
      return 'line'
    case 'column':
    case 'ranked-column':
    case 'stacked-column':
    case 'text-column':
    case 'stacked':
      return 'bar'
    case 'pie':
      return 'pie'
    default:
      never(type)
      throw new Error('Unhandled chart type')
  }
}

export function createStudyInsightsDemographicFilterEntities(): Entity[] {
  const isEnabled = useResultsDemographicFiltersStore().isEnabled

  const filters = useResultsDemographicFiltersStore().nameToFilters
  const filterIds = Object.keys(useResultsDemographicFiltersStore().nameToFilters)

  return [
    createFilterGroupEntity({
      id: 'demographic',
      isEnabled,
      filterIds: isEnabled ? filterIds : [],
    }),
    ...(isEnabled
      ? filterIds.map(id => {
          const values = filters[id]
          return isRangeFilters(values)
            ? createRangeFilterEntity({ id, values })
            : createSelectFilterEntity({ id, values })
        })
      : []),
  ]
}

export function createStudyInsightsCountryFilterEntities(): Entity[] {
  return [
    createFilterGroupEntity({
      id: 'country',
      isEnabled: true,
      filterIds: [...useStudyWaveFiltersStore().serialisedAudiences]
        .map(audience => useStudyWaveAudienceFiltersStore().deSerialiseAudience(audience))
        .filter(deserialisedAudience => !!deserialisedAudience)
        .map(audience => audience.country),
    }),
  ]
}

export function createStudyInsightsDateFilterEntities(): Entity[] {
  return [
    createFilterGroupEntity({
      id: 'dates',
      isEnabled: true,
      filterIds: [...useStudyWaveFiltersStore().publishedTimestamps].map(toString),
    }),
  ]
}

export function createStudyInsightsCardResponseFilterEntities(): Entity[] {
  return [
    createFilterGroupEntity({
      id: 'card-response-filters',
      isEnabled: useCardResponseFiltersStore().shouldFilter,
      filterIds: Object.keys(useCardResponseFiltersStore().cardIdToFilters),
    }),
    ...Object.entries(useCardResponseFiltersStore().cardIdToFilters).map(([cardId, filters]) =>
      createSelectFilterEntity({
        id: cardId,
        values: [...filters.answerIds, ...filters.responseIds],
      }),
    ),
  ]
}
