import type { RouteRecordRaw } from 'vue-router'

import { SURVEY_LIVE_ROUTES } from '@attest/router'
import { createSurveyEntity, useGetTrackingEntities } from '@attest/tracking'

export function createSurveyLiveRoute(): RouteRecordRaw {
  return {
    name: SURVEY_LIVE_ROUTES.SURVEY_LIVE.name,
    path: SURVEY_LIVE_ROUTES.SURVEY_LIVE.path,
    alias: SURVEY_LIVE_ROUTES.SURVEY_LIVE.alias,
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      authenticationRequired: 'authenticated',
      ...useGetTrackingEntities(route => [createSurveyEntity({ id: route.params.guid as string })]),
    },
    component: async () => (await import('./page/SurveyLive.vue')).default,
  }
}
