<script lang="tsx">
import { nanoid } from 'nanoid'
import { defineComponent, type ExtractPublicPropTypes, type PropType } from 'vue'

import { create, type ElementAttributes } from '@attest/vue-tsx'

export type AttestIconSize = 8 | 16 | 20 | 24 | 32

export type AttestIconProps = ExtractPublicPropTypes<typeof props>
const props = {
  title: { type: String, default: '' },
  size: { type: Number as PropType<AttestIconSize>, default: 20 },
  color: { type: String, default: 'currentcolor' },
}
const AttestIcon = defineComponent({
  props,
  render() {
    const baseClass = 'attest-icon'
    const id = `icon-${nanoid()}`

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.size}
        height={this.size}
        viewBox={`0 0 ${this.size} ${this.size}`}
        aria-labelledby={id}
        aria-hidden={!this.title}
        role="presentation"
        class={baseClass}
        fill={this.color}
      >
        {this.title && (
          <title id={id} lang="en">
            {this.title}
          </title>
        )}
        <g>{this.$slots.default?.()}</g>
      </svg>
    )
  },
})

export default create<AttestIconProps & ElementAttributes<'svg'>>(AttestIcon)
</script>

<style lang="postcss">
.attest-icon {
  display: inline-block;
  vertical-align: baseline;
}
</style>
