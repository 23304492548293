import { reasonErrorToInternalError, service, type ServiceRequest } from '@attest/_api'
import type { ListExclusiveSamplesError } from '@attest/_api/model/survey'

import type { ExclusiveSample } from './model'

export const REQUEST_URL = {
  EXCLUSIVE_SAMPLES: (guid: string) => `/api/survey/${guid}/exclusive-samples` as const,
  PUT_EXCLUSIVE_SAMPLES: (guid: string) => `/api/surveys/${guid}/exclusive-samples` as const,
} as const

type ApiExclusiveSample = {
  guid: string
  max_responses: number
  responses: number
  starts: number
  time_zone_id: string
  title: string
}

export const listExclusiveSamples: ServiceRequest<
  ExclusiveSample[],
  ListExclusiveSamplesError,
  { guid: string }
> = async args => {
  try {
    const response = await service().get<ApiExclusiveSample[]>(
      REQUEST_URL.EXCLUSIVE_SAMPLES(args.guid),
    )
    return response.data.map(e => ({
      guid: e.guid,
      title: e.title,
      maxResponses: e.max_responses,
      timeZoneId: e.time_zone_id,
      startTimestamp: e.starts,
      responses: e.responses,
    }))
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export const setExclusiveSamples: ServiceRequest<
  void,
  ListExclusiveSamplesError,
  { guid: string; excludedGuids: string[] }
> = async args => {
  try {
    await service().put(REQUEST_URL.PUT_EXCLUSIVE_SAMPLES(args.guid), {
      value: args.excludedGuids,
    })
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}
