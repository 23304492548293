import { useDemographicsStore } from '@attest/audience-demographic'
import { useStudyInsightsStore } from '@attest/results-core'
import { useStudyStore } from '@attest/study'
import { never } from '@attest/util'

import type { ColumnDefinitionType } from './model'

export function getDefinitionTitle(args: { type: ColumnDefinitionType; variable: string }): string {
  switch (args.type) {
    case 'static':
      return ''
    case 'date':
      return 'Waves'
    case 'question':
      return getQuestionDefinitionTitle(args.variable)
    case 'demographic':
      return getDemographicDefinitionTitle(args.variable)
    case 'age':
      return 'Age'
    case 'country':
      return 'Country'
    case 'audience':
      return 'Audiences'
    case 'segment':
      return 'Segments'
    default:
      never(args.type)
      return ''
  }
}

function getDemographicDefinitionTitle(name: string): string {
  const demographic = useDemographicsStore().demographicNameToDemographic[name]

  const countriesInStudy = new Set(useStudyStore().sentWaveSurveys.map(survey => survey.country))

  const supportedCountries = [
    ...new Set(
      demographic.supportedCountryLanguages
        .filter(supportedLanguage => countriesInStudy.has(supportedLanguage.split('_')[0]))
        .map(supportedLanguage => supportedLanguage.split('_')[0].replace('GB', 'UK')),
    ),
  ]

  const appliesToAllCountryCodes = countriesInStudy.size === supportedCountries.length

  return appliesToAllCountryCodes
    ? demographic.display
    : `${demographic.display} (${supportedCountries.join(', ')})`
}

export function getQuestionDefinitionTitle(cardId: string): string {
  const card = useStudyInsightsStore().idToCard[cardId]
  if (card.type !== 'question') throw new Error('card is not of type question')
  return `Q${getQuestionNumberByCardId(card.id)} | ${card.title}`
}

export function getQuestionNumberByCardId(cardId: string): number {
  return (
    useStudyInsightsStore()
      .filteredCards.filter(card => card.type === 'question')
      .findIndex(({ id }) => id === cardId) + 1
  )
}
