import { createEntity, type Entity } from '../base'

export type SelectFilterEntity<D extends SelectFilterEntityData = SelectFilterEntityData> =
  {} & Entity<D>

type SelectFilterEntityData = {
  id: string
  values: string[]
}

export function createSelectFilterEntity({
  id,
  values,
}: SelectFilterEntityData): SelectFilterEntity {
  return createEntity({ name: 'select-filter', version: '1-0-0' }, { id, values })
}
