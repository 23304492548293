import {
  type Card,
  type CardGuid,
  isQuestionCardOrUninitialized,
  supportsPipingFrom,
} from '@attest/editor-card'
import { getAllIntersectingNodesTo, type RoutingGraph } from '@attest/editor-routing-graph'
import { removeMarkdownCharacters } from '@attest/markdown'
import { isDefined } from '@attest/util'

import {
  findCardByGuid,
  flattenCards,
  getGroupedCards,
  getLongestAnswer,
  isChildOfRandomizedGroup,
} from './card-service'

export const PIPING_REGEXP = {
  get shortcode(): RegExp {
    return /{Q(\d\d?)}/
  },
  get shortcodeGlobal(): RegExp {
    return new RegExp(PIPING_REGEXP.shortcode, 'g')
  },
}

export function parsePipedQuestionsFromTitle(
  cardTitle: string,
  cardsByServingOrder: Card[],
): (CardGuid | undefined)[] {
  return parseShortcodes(cardTitle).map(shortCode =>
    findCardGuidFromPipingShortcode(shortCode, cardsByServingOrder),
  )
}

export function parseShortcodes(s: string): string[] {
  return s.match(PIPING_REGEXP.shortcodeGlobal) || []
}

export function findCardGuidFromPipingShortcode(
  shortcode: string,
  cardsByServingOrder: Card[],
): CardGuid | undefined {
  const groupedCards = getGroupedCards(cardsByServingOrder)
  const questionNumberString = shortcode.match(PIPING_REGEXP.shortcode)?.[1]

  if (typeof questionNumberString !== 'string') {
    throw new TypeError('Unable to parse shortcode.')
  }

  const questionNumber = Number.parseInt(questionNumberString)

  if (Number.isNaN(questionNumber)) {
    throw new TypeError('Unable to parse shortcode.')
  }

  const sortedQuestionCards = cardsByServingOrder
    .filter(isQuestionCardOrUninitialized)
    .filter(card => !groupedCards.has(card) || isChildOfRandomizedGroup(card, cardsByServingOrder))
  const card: Card | undefined = sortedQuestionCards[questionNumber - 1]

  return card?.guid
}

export function getCardTitleWithUpdatedShortcodes(
  title: string,
  pipingSourceGuids: CardGuid[],
  cardsByServingOrder: Card[],
  cardNumbers: Record<string, number>,
): string {
  let shortCodeIndex = 0
  return title.replace(PIPING_REGEXP.shortcodeGlobal, match => {
    if (!pipingSourceGuids) {
      return match
    }
    if (!findCardGuidFromPipingShortcode(match, cardsByServingOrder)) {
      return match
    }
    const questionNumber = Number.parseInt(match.match(PIPING_REGEXP.shortcode)?.[1] ?? '-1')
    const pipingSourceGuid = pipingSourceGuids[shortCodeIndex]
    const newCardNumber =
      pipingSourceGuid === undefined ? -1 : (cardNumbers[pipingSourceGuid] ?? -1)
    if (questionNumber === newCardNumber || newCardNumber < 0) {
      return match
    }
    shortCodeIndex = shortCodeIndex + 1
    return `{Q${newCardNumber}}`
  })
}

export function getTitleWithoutShortcodes(title: string): string {
  return title.replace(PIPING_REGEXP.shortcodeGlobal, '')
}

export function getLongestTitleLengthWithPipedAnswers(
  title: string,
  cards: Card[],
  pipingFromGuids: (CardGuid | undefined)[],
): number {
  return (
    getTitleWithoutShortcodes(removeMarkdownCharacters(title)).length +
    pipingFromGuids
      .flatMap(guid => {
        const card = guid ? findCardByGuid(guid, cards) : null
        return card ? [card] : []
      })
      .map(getLongestAnswer)
      .join('').length
  )
}

export function getPipableSourceCardsForCard(
  guid: string,
  cards: Card[],
  routingGraph: RoutingGraph,
): Card[] {
  if (cards.length === 0) {
    return []
  }
  const guidToCard = Object.fromEntries(flattenCards(cards).map(card => [card.guid, card]))

  return getAllIntersectingNodesTo(routingGraph, guid)
    .map(node => guidToCard[node])
    .filter(isDefined)
    .filter(card => card.guid !== guid && supportsPipingFrom(card))
}
