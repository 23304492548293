import type {
  DemographicApiOption,
  DemographicApiOptionRange,
  GetDemographicsResponseDemographics,
} from '../model/api'
import type { Demographic, DemographicOption } from '../model/demographic'

export function toDemographic(demographic: GetDemographicsResponseDemographics): Demographic {
  const isDefault = isDefaultDemographic(demographic)
  const type = isDefault ? 'default' : 'range'

  return {
    name: demographic.name,
    display: demographic.display,
    category: demographic.category,
    order: demographic.order,
    targetable: demographic.targetable,
    isMultipleChoice: demographic.multiple_choice,
    supportedCountryLanguages: demographic.supported_country_languages,
    options: toOptions(demographic.options, type),
    type,
  }
}

function toOptions(
  options: DemographicApiOption[],
  type: 'default' | 'range',
): DemographicOption[] {
  if (type === 'default') {
    return options
  }

  return options.filter(isDemographicOptionRange)
}

function isDefaultDemographic(demographic: GetDemographicsResponseDemographics) {
  return demographic.name !== 'age'
}

function isDemographicOptionRange(
  option: DemographicApiOption,
): option is DemographicApiOptionRange {
  const rangeOption = option as DemographicApiOptionRange
  const isRangeOption = rangeOption.min !== undefined && rangeOption.max !== undefined
  return isRangeOption
}
