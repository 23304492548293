import { isRange, type Range } from '@attest/util'

export type Segmentations = Record<string, SegmentationOptions>

export type SegmentationOption = string | Range
export type SegmentationOptions = string[] | Range[]

export type SegmentationWithQuotas = {
  [value: string]: {
    display: string
    quota?: number
  }
}

export type SegmentationsWithQuotas = {
  [segmentationKey: string]: SegmentationWithQuotas
}

export function isRangeOption(option: SegmentationOption | number): option is Range {
  return isRange(option)
}

export function isRangeOptions(options: SegmentationOptions | number[]): options is Range[] {
  const option = options[0]
  if (option === undefined) {
    return false
  }
  return isRangeOption(option)
}

export function isStringOptions(options: string[] | Range[] | number[]): options is string[] {
  return Array.isArray(options) && (options.length > 0 && typeof options[0]) === 'string'
}

export function isNumberRangeOptions(options: string[] | Range[] | number[]): options is number[] {
  return options.length > 0 && typeof options[0] === 'number'
}
