import { type ResponseData, service } from '@attest/_api'

export type UserResetPasswordRequestData = {
  email: string
  password: string
  resetCode: string
  mfaCode?: string
}

export type UserResetPasswordResponseData = {} & ResponseData<Record<string, never>>

const RESET_PASSWORD_URL = '/json/password-reset-update'

export async function resetPassword(data: UserResetPasswordRequestData) {
  const formData = new URLSearchParams()
  formData.append('email', data.email)
  formData.append('new-password', data.password)
  formData.append('reset-code', data.resetCode)
  if (data.mfaCode) {
    formData.append('code', data.mfaCode)
  }
  return service().post<UserResetPasswordResponseData>(RESET_PASSWORD_URL, formData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}
