import { expandRangesToValues, expandValuesToRanges } from '@attest/util'

import { isRangeOptions, isStringOptions, type Segmentations } from '../model'

import type { ApiSegmentations } from './model'

export function transformApiSegmentationsToSegmentations(
  segmentations: ApiSegmentations,
): Segmentations {
  const transformed: Segmentations = {}
  for (const key in segmentations) {
    const options = segmentations[key]
    if (options === undefined) {
      continue
    }
    transformed[key] = isApiRange(options) ? expandValuesToRanges([...options]) : options
  }
  return transformed
}

function isApiRange(segmentation: string[] | number[]): segmentation is number[] {
  return segmentation.length > 0 && typeof segmentation[0] === 'number'
}

export function toApiSegmentations(segmentations: Segmentations): ApiSegmentations {
  const transformed: ApiSegmentations = {}
  for (const key in segmentations) {
    const options = segmentations[key]
    if (options === undefined) {
      continue
    }
    if (isRangeOptions(options) && expandRangesToValues([...options]).length > 0) {
      transformed[key] = expandRangesToValues([...options])
    }

    if (isStringOptions(options)) {
      transformed[key] = options
    }
  }

  return transformed
}
