<script lang="tsx">
import { nanoid } from 'nanoid'
import { defineComponent, TransitionGroup } from 'vue'

import { create } from '@attest/vue-tsx'

import Toast from './Toast.vue'
import {
  DEFAULT_TOASTER_NAME,
  registerToaster,
  type ToastOptions,
  unregisterToaster,
} from './toaster-manager'

type ToasterProps = {
  name?: string
}

type ToastMode = 'success' | 'primary' | 'error'

type ToastProps = {
  content: string
  badge?: string
  icon?: string | { default: string }
  uid: string
  duration: number
  isPersist?: boolean
  mode?: ToastMode
}

const DEFAULT_DURATION = 5000

const Toaster = defineComponent({
  props: {
    name: { type: String, default: DEFAULT_TOASTER_NAME },
  },

  data() {
    return {
      toasts: [] as ToastProps[],
      isHover: false,
    }
  },

  mounted() {
    const { toast, dismiss } = this
    registerToaster(this.name, { toast, dismiss })
  },

  beforeUnmount() {
    unregisterToaster(this.name)
  },

  methods: {
    toast(content: string, options: ToastOptions = {}) {
      const { uid = nanoid(), duration = DEFAULT_DURATION, mode, badge, icon } = options

      const hasToast = this.resetToast(uid)
      if (hasToast) return uid

      this.toasts.push({
        badge,
        content,
        icon,
        uid,
        duration,
        mode,
      })

      return uid
    },

    resetToast(uid: string): boolean {
      const toast = this.toasts.find(toastItem => toastItem.uid === uid)
      if (toast) toast.duration += 1

      return !!toast
    },

    dismiss(uid: string) {
      this.toasts = this.toasts.filter(toast => toast.uid !== uid)
    },

    beforeLeave(el: Element) {
      if (el instanceof HTMLElement) {
        Object.assign(el.style, {
          top: `${el.offsetTop}px`,
          left: `${el.offsetLeft}px`,
        })
      }
    },
  },

  render() {
    const baseClass = 'c-toaster'

    return (
      <TransitionGroup
        onBeforeLeave={this.beforeLeave}
        name={`${baseClass}__t-slide`}
        tag="div"
        {...{ class: baseClass }}
        v-slots={{
          default: () =>
            this.toasts.map(props => (
              <Toast
                class={`${baseClass}__toast`}
                {...props}
                isPersist={this.isHover}
                data-name="ToasterToast"
                data-uid={props.uid}
                onMouseleave={() => (this.isHover = false)}
                onMouseenter={() => (this.isHover = true)}
                onDismiss={() => this.dismiss(props.uid)}
                key={props.uid}
              />
            )),
        }}
      />
    )
  },
})

export default create<ToasterProps>(Toaster)
</script>

<style lang="postcss">
:root {
  --c-toaster-transition-duration: var(--attest-timing-slow);
}

.c-toaster {
  position: fixed;
  z-index: var(--z-index-toaster);
  top: var(--attest-spacing-8);
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  .c-toaster__toast {
    margin-bottom: var(--attest-spacing-4);
  }

  &__t-slide {
    &-move {
      transition-duration: var(--c-toaster-transition-duration);
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.75, -0.5, 0, 1.5);
    }

    &-enter-active,
    &-leave-active {
      transition-duration: var(--c-toaster-transition-duration);
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.75, -0.5, 0, 1.5);
    }

    &-leave-active {
      position: absolute;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(var(--attest-spacing-4));
    }
  }
}
</style>
