<script lang="tsx">
import type { FunctionalComponent } from 'vue'

import { create } from '@attest/vue-tsx'

import { Popover } from '../Popover'

import type { NavbarPopoverProps } from './NavbarPopover.interface'

const NavbarPopover: FunctionalComponent<NavbarPopoverProps> = (props, { slots }) => {
  const { targetClass, ...attrs } = props

  return (
    <Popover
      class="c-navbar-popover"
      target={targetClass}
      triggerEvents={['hover']}
      defaultHorizontalAlign="right"
      defaultVerticalAlign="center"
      forceAlign
      {...attrs}
    >
      {slots.default?.()}
    </Popover>
  )
}

export default create<NavbarPopoverProps>(NavbarPopover)
</script>

<style lang="postcss">
.c-navbar-popover {
  display: none;
  white-space: nowrap;

  @context scoped {
    padding: var(--attest-spacing-1);
    border-radius: var(--attest-spacing-1);
    margin-left: var(--attest-spacing-2);
    background-color: var(--attest-color-text-default);
    box-shadow: none;
    color: var(--attest-color-background-default);
  }

  @media (--attest-media-s) {
    display: inline-table;
  }

  @media (--attest-media-xl) {
    display: none;
  }
}
</style>
