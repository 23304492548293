import { isNotNil, reject } from 'ramda'

import { isRangeOption, useDemographicsStore } from '@attest/audience-demographic'
import { c } from '@attest/intl'
import { useStudyInsightsStore } from '@attest/results-core'
import { useSavedFiltersStore } from '@attest/results-saved-filters'
import {
  type MergedStudyQuestionStructure,
  type SentWaveSurvey,
  type StudyQuestionGroup,
  useStudyStore,
} from '@attest/study'
import { Thyme, ThymeFormats } from '@attest/thyme'

import type { StackedColumnDefinitionValue } from '../store'

import type { ColumnDefinitionValue } from './model'

export function getDefinitionValueTitle(definition?: ColumnDefinitionValue): string {
  if (!definition) {
    return ''
  }
  if ((definition as StackedColumnDefinitionValue).stackedColumnDefinitionValue) {
    const topTitle = getDefinitionValueTitle(
      (definition as StackedColumnDefinitionValue).stackedColumnDefinitionValue,
    )
    if (topTitle) {
      if (doesDefinitionMatchStackedColumn(definition as StackedColumnDefinitionValue)) {
        return `${topTitle} - ${c('subtotal')}`
      }

      return `${topTitle} - ${getDefinitionValueTitle({
        type: definition.type,
        variable: definition.variable,
        value: definition.value,
        titleOverride: definition.titleOverride,
      })}`
    }
  }
  if (definition.titleOverride) {
    return definition.titleOverride
  }
  switch (definition.type) {
    case 'static':
      return getStaticDefinitionValueTitle(definition.variable)
    case 'date':
      return getDateDefinitionValueTitle(definition.value)
    case 'question':
      return getQuestionDefinitinoValueTitle(definition.variable, definition.value)
    case 'demographic':
      return getDemographicDefinitionValueTitle(definition.variable, definition.value)
    case 'age':
      return getAgeDefinitionValueTitle(definition.value)
    case 'country':
      return getCountryDefinitionValueTitle(definition.value)
    case 'audience':
      return getAudienceDefinitionValueTitle(definition.value)
    case 'segment':
      return getSegmentDefinitionValueTitle(definition.value)
    default:
      throw new Error('column type not supported')
  }
}

function doesDefinitionMatchStackedColumn(definition: StackedColumnDefinitionValue): boolean {
  const stackedColumnDefinition = definition.stackedColumnDefinitionValue
  return (
    !!stackedColumnDefinition &&
    stackedColumnDefinition.type === definition.type &&
    stackedColumnDefinition.value === definition.value &&
    stackedColumnDefinition.variable === definition.variable
  )
}

export function getDefinitionValueMetadata(definition: ColumnDefinitionValue): {
  isForwarded: boolean
  isQualifying: boolean
  routingTargetCardId?: string | number
} {
  if (definition.type === 'question') {
    const mergedStructure = useStudyInsightsStore().cardIdToMergedStructure[
      definition.variable
    ] as MergedStudyQuestionStructure

    const answerIds = definition.value.split(',')
    const answerOptions = answerIds.map(answerId =>
      mergedStructure.options.find(o => o.id === answerId),
    )
    return {
      isForwarded: answerOptions.every(answerOption => answerOption?.mask),
      isQualifying: answerOptions.every(answerOption => answerOption?.isQualifying),
      routingTargetCardId:
        answerOptions.every(answerOption => answerOption?.next) &&
        new Set(answerOptions.map(answerOption => answerOption?.next)).size === 1
          ? answerOptions.find(answerOption => answerOption?.next)?.next
          : undefined,
    }
  }
  return { isForwarded: false, isQualifying: false }
}

function getStaticDefinitionValueTitle(variable: string): string {
  if (variable === 'total') return 'Total'
  return ''
}

function getDateDefinitionValueTitle(value: string): string {
  return [
    ...new Set(
      getSentWavesByPublishedTimestamp(Number.parseInt(value))
        .map(({ startTimestamp }) => startTimestamp)
        .filter(isNotNil)
        .map(timestamp => Thyme.fromMillis(timestamp).toLocaleString(ThymeFormats.DATE_MED)),
    ),
  ].toString()
}

function getDemographicDefinitionValueTitle(name: string, value: string): string {
  const demographicsStore = useDemographicsStore()
  const demographic = demographicsStore.demographicNameToDemographic[name]
  if (!demographic) throw new Error('Demographic not found')

  const options = reject(isRangeOption, demographic.options).filter(option => option.name === value)
  if (options.length === 0) throw new Error('Options not found')
  return options.map(({ display }) => display).join(', ')
}

function getAgeDefinitionValueTitle(value: string): string {
  const [min, max] = value.split(',')
  return max === undefined ? `${min}+` : `${min} - ${max}`
}

function getCountryDefinitionValueTitle(value: string): string {
  return useDemographicsStore().getCountryDisplay(value) ?? ''
}

function getAudienceDefinitionValueTitle(value: string): string {
  return useStudyStore().getWaveSurveyByAudienceId(value)?.name ?? ''
}

function getQuestionDefinitinoValueTitle(cardId: string, value: string): string {
  const optionIds = value.split(',')
  const studyInsightsStore = useStudyInsightsStore()
  const mergedStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)
  const card = studyInsightsStore.getQuestionCardById(cardId)
  const foundGroup = card.question.optionGroups.find(group =>
    group.ids.every(id => optionIds.includes(id)),
  )

  if (foundGroup) return getGroupTitle(cardId, foundGroup)
  return mergedStructure.options
    .filter(({ id }) => optionIds.includes(id))
    .map(({ text }) => text)
    .join(', ')
}

function getGroupTitle(cardId: string, group: StudyQuestionGroup): string {
  const studyInsightsStore = useStudyInsightsStore()
  const mergedStructure = studyInsightsStore.getMergedQuestionStructureByCardId(cardId)

  return (
    group.text ??
    group.ids
      .map(id => mergedStructure.options.find(option => option.id === id))
      .filter(isNotNil)
      .map(({ text }) => text)
      .join(', ')
  )
}

function getSegmentDefinitionValueTitle(id: string): string {
  const filter = useSavedFiltersStore().filterIdToFilter[id]
  return filter?.name ?? ''
}

function getSentWavesByPublishedTimestamp(timestamp: number): SentWaveSurvey[] {
  return useStudyStore().sentWaveSurveys.filter(
    ({ publishedTimestamp }) => publishedTimestamp === timestamp,
  )
}
