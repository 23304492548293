import type { LocationQueryRaw, RouteRecordRaw } from 'vue-router'

import { VIEWS } from '@attest/router'
import { createRouteWaveEntity, type StudyNavbarProps } from '@attest/study'
import {
  createQuestionEntity,
  createStudyEntity,
  type QuestionEntity,
  useGetTrackingEntities,
} from '@attest/tracking'

export enum RESULTS_TEXT_ANALYSIS_ROUTE_NAME {
  TEXT_ANALYSIS_WAVE_SURVEY = 'RESULTS::TEXT_ANALYSIS::WAVE',
}

const RESULTS_TEXT_ANALYSIS_ROUTE_PATH = {
  [RESULTS_TEXT_ANALYSIS_ROUTE_NAME.TEXT_ANALYSIS_WAVE_SURVEY]:
    '/survey/:studyId/text-analysis/:waveSurveyId',
}

export function createResultsTextAnalysisWaveSurveyRoute(): RouteRecordRaw {
  return {
    name: RESULTS_TEXT_ANALYSIS_ROUTE_NAME.TEXT_ANALYSIS_WAVE_SURVEY,
    path: RESULTS_TEXT_ANALYSIS_ROUTE_PATH[
      RESULTS_TEXT_ANALYSIS_ROUTE_NAME.TEXT_ANALYSIS_WAVE_SURVEY
    ],
    meta: {
      showNavbarTopbar: true,
      showProgressBar: true,
      isAccessibleWhenAuthenticated: true,
      authenticationRequired: 'either',
      ...useGetTrackingEntities(route => [
        ...createQuestionEntityFromRouteQuery(route.query),
        createStudyEntity({ id: route.params.studyId as string }),
        createRouteWaveEntity(route.params.waveSurveyId as string),
      ]),
    },
    components: {
      default: async () => (await import('./page/TextAnalysis/TextAnalysis.vue')).default,
      [VIEWS.NAVBAR]: async () =>
        (await import('@attest/study/src/components/organism/StudyNavbar/StudyNavbar.vue')).default,
    },
    props: {
      default(route): { studyId: string; waveSurveyId: string } {
        return {
          studyId: route.params.studyId as string,
          waveSurveyId: route.params.waveSurveyId as string,
        }
      },
      [VIEWS.NAVBAR](route): StudyNavbarProps {
        return {
          studyId: route.params.studyId as string,
          waveSurveyId: route.params.waveSurveyId as string,
        }
      },
    },
  }
}

function createQuestionEntityFromRouteQuery(query: LocationQueryRaw): QuestionEntity[] {
  if (typeof query.guid !== 'string') return []
  return query.guid.split(',').map(guid => createQuestionEntity({ id: guid }))
}
