import { isDefined } from '@attest/util'

type Predicate<Payload> = (payload: Payload) => boolean

type ErrorPredicates<ErrorType extends string, Payload> = {
  [errorType in ErrorType]: Predicate<Payload>
}

export function createGetErrorsFor<ErrorType extends string, Payload>(
  predicates: ErrorPredicates<ErrorType, Payload>,
): ((payload: Payload) => { type: ErrorType }[]) & ErrorPredicates<ErrorType, Payload> {
  const getErrors = (payload: Payload): { type: ErrorType }[] => {
    return (Object.entries(predicates) as [ErrorType, Predicate<Payload>][])
      .filter(([_errorType, predicate]) => predicate(payload))
      .map(([type]) => ({ type }))
  }

  Object.assign(getErrors, predicates)
  return getErrors as any
}

type PayloadPredicate<
  ErrorMap extends Record<string, unknown>,
  ErrorKey extends keyof ErrorMap,
  Payload,
> = (payload: Payload) => ErrorMap[ErrorKey] | undefined

type PayloadErrorPredicates<ErrorMap extends Record<string, unknown>, Payload> = {
  [ErrorKey in keyof ErrorMap]: PayloadPredicate<ErrorMap, ErrorKey, Payload>
}

export function createErrorPayloadGetter<ErrorMap extends Record<string, unknown>, Payload>(
  predicates: PayloadErrorPredicates<ErrorMap, Payload>,
): ((payload: Payload) => ErrorMap[keyof ErrorMap][]) & PayloadErrorPredicates<ErrorMap, Payload> {
  const getErrors = (payload: Payload): ErrorMap[keyof ErrorMap][] => {
    return Object.values(predicates)
      .map(predicate => predicate(payload))
      .filter(isDefined)
  }

  Object.assign(getErrors, predicates)
  return getErrors as any
}
