import { nanoid } from 'nanoid'

import { EflService, type FilterBlock, Logic } from '@attest/efl-antlr4'
import {
  isRangeCondition,
  type RangeCondition,
  type Segment,
  type ValueCondition,
} from '@attest/segments'

import {
  AND,
  AND_GROUP,
  BETWEEN,
  CONTAINS,
  EQUAL,
  IN,
  INCLUDES,
  NONE,
  NOT,
  OR,
  OR_GROUP,
} from '../factory'
import { type FilterNode, isFilterBlock, isFilterExpression } from '../model'

function createSegmentFilterNode(segment: Segment): FilterNode {
  const groups =
    !segment.groups || segment.groups.length <= 0
      ? [{ logic: segment.logic, conditions: [], id: nanoid() }]
      : segment.groups

  const groupBlocks = groups.map(group => {
    const expressions = group.conditions.map(createSegmentConditionFilterNode)
    return group.logic === Logic.AND ? AND_GROUP(expressions) : OR_GROUP(expressions)
  })

  return segment.logic === Logic.AND ? AND(groupBlocks) : OR(groupBlocks)
}

function createSegmentConditionFilterNode(condition: ValueCondition | RangeCondition): FilterNode {
  if (isRangeCondition(condition)) {
    return createRangeConditionFilterNode(condition)
  }

  if (condition.type === 'openText') {
    return createOpenTextFilterNode(condition)
  }

  return createValueConditionFilterNode(condition)
}

function createRangeConditionFilterNode(condition: RangeCondition): FilterNode {
  return condition.values.map(value => {
    const expression = BETWEEN(createFilterNodeKey(condition), [value.min, value.max])
    return condition.isNegated ? NOT(expression) : expression
  })[0]
}

function createOpenTextFilterNode(condition: ValueCondition): FilterNode {
  const expressions = condition.values.map(value => {
    const expression = CONTAINS(createFilterNodeKey(condition), value)
    return condition.isNegated ? NOT(expression) : expression
  })

  return condition.isNegated ? AND(expressions) : OR(expressions)
}

function createValueConditionFilterNode(condition: ValueCondition): FilterNode {
  const expression = (() => {
    if (condition.isMultipleChoice) {
      return INCLUDES(createFilterNodeKey(condition), valuesForCondition(condition))
    }

    if (condition.values.flatMap(val => val.split(',')).length > 1) {
      return IN(createFilterNodeKey(condition), valuesForCondition(condition))
    }

    return EQUAL(createFilterNodeKey(condition), valuesForCondition(condition)[0])
  })()

  return condition.isNegated ? NOT(expression) : expression
}

function valuesForCondition(condition: ValueCondition): string[] | number[] {
  switch (condition.type) {
    case 'waves':
      return condition.values.map(val => Number(val))
    case 'answer':
      return condition.values.flatMap(val => val.split(','))
    case 'demographic':
    case 'openText':
    case 'audience':
    case 'country':
    default:
      return condition.values
  }
}

function createFilterNodeKey(condition: ValueCondition | RangeCondition): string {
  switch (condition.type) {
    case 'waves':
      return `wave_published_time`
    case 'answer':
      return `answers.${condition.variable}.id`
    case 'openText':
      return `answers.${condition.variable}.text`
    case 'demographic':
    case 'audience':
    case 'country':
    default:
      return condition.variable
  }
}

export function generateEFlString(node: FilterNode): string | undefined {
  if (isFilterBlock(node)) {
    // eslint-disable-next-line no-console
    console.log(node)
    return EflService.getQueryStringFromFilterBlock(node)
  }

  if (isFilterExpression(node)) {
    // eslint-disable-next-line no-console
    console.log(node)
    return EflService.getQueryStringFromFilterBlock(NONE(node) as FilterBlock)
  }

  return undefined
}

export function generateEflFromSegment(segment: Segment): string | undefined {
  return generateEFlString(createSegmentFilterNode(segment))
}
