<script lang="tsx">
import { defineComponent } from 'vue'

import { create, createEmitter, EMPTY_VNODE } from '@attest/vue-tsx'

import { DOMMutationObserver } from '../DOMMutationObserver'

import type {
  DescendantsObserverEvents,
  DescendantsObserverProps,
} from './DescendantsObserver.interface'

const emit = createEmitter<DescendantsObserverEvents>()

const getMatchingNodesFromRoot = (root: Node, selector: string): Node[] => {
  if (!(root instanceof Element)) return []
  return [...(root.matches(selector) ? [root] : []), ...root.querySelectorAll(selector)]
}

const DescendantsObserver = defineComponent({
  props: {
    selector: { type: String, default: '*' },
    immediate: { type: Boolean, default: false },
  },

  mounted() {
    if (this.immediate && this.$el) {
      this.emitNodes('descendantAdded', getMatchingNodesFromRoot(this.$el, this.selector))
    }
  },

  methods: {
    onMutation(mutation: MutationRecord): void {
      switch (mutation.type) {
        case 'childList': {
          this.emitNodes(
            'descendantAdded',
            [...mutation.addedNodes].flatMap(node => getMatchingNodesFromRoot(node, this.selector)),
          )
          this.emitNodes(
            'descendantRemoved',
            [...mutation.removedNodes].flatMap(node =>
              getMatchingNodesFromRoot(node, this.selector),
            ),
          )
        }
      }
    },

    emitNodes(type: keyof DescendantsObserverEvents, nodeList: Iterable<Node>): void {
      for (const node of nodeList) {
        emit(this, type, node)
      }
    },
  },

  render() {
    if (!this.$slots.default?.()[0]) return EMPTY_VNODE

    return (
      <DOMMutationObserver subtree childList onMutation={this.onMutation}>
        {this.$slots.default?.()[0]}
      </DOMMutationObserver>
    )
  },
})

export default create<DescendantsObserverProps, DescendantsObserverEvents>(DescendantsObserver)
</script>
