import { type ResponseData, service } from '@attest/_api'

export type UserValidateEmailRequestData = {
  email: string
  guid: string
}

export type UserValidateEmailResponseData = {} & ResponseData<Record<string, never>>

const VALIDATE_EMAIL_URL = '/json/validate-email'

export async function validateEmail(data: UserValidateEmailRequestData) {
  return service().post<UserValidateEmailResponseData>(VALIDATE_EMAIL_URL, data)
}
