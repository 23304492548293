import { mergeDeepLeft, mergeDeepRight } from 'ramda'
import type { O, T } from 'ts-toolbelt'

export type MergeAllDeep<Os extends readonly Record<any, any>[]> =
  O.Assign<Record<any, any>, Os, 'deep'> extends infer M
    ? Record<any, any> extends M
      ? T.UnionOf<Os>
      : M
    : never

export function mergeDeepAllRight<Os extends Record<any, any>[]>(list: Os): MergeAllDeep<Os> {
  // eslint-disable-next-line unicorn/no-array-reduce
  return list.reduce(mergeDeepRight, {} as any) as MergeAllDeep<Os>
}

export function mergeDeepAllLeft<Os extends Record<any, any>[]>(list: Os): MergeAllDeep<Os> {
  // eslint-disable-next-line unicorn/no-array-reduce
  return list.reduce(mergeDeepLeft, {} as any) as MergeAllDeep<Os>
}
