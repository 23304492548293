type ExpectedError<E> = [response: undefined, error: E]
type ExpectedResolved<R> = [response: R, error: undefined]

export type ExpectedHandler<R, E> = Expected<R, E | Error>
export type Expected<R, E = Error> = Promise<ExpectedResolved<R> | ExpectedError<E>>

/**
 * Convert promise into go-like error flow
 *
 * @example
 * ```ts
 * import { expectedHandler } from '@attest/util'
 *
 * const [response, error] = expectedHandler(expected () => Promise.resolve('hello'))
 *
 * if (error) {
 *   // handle error
 *   return
 * }
 *
 * // type of response is string as error flow has been handled
 * typeof response === 'string'
 * ```
 */
export async function expectedHandler<R, E = Error>(fn: () => Promise<R>): ExpectedHandler<R, E> {
  return expected(fn())
}

/**
 * Convert expected callback into go-like error flow
 *
 * @example
 * ```ts
 * import { expected } from '@attest/util'
 *
 * const [response, error] = expected(Promise.resolve('hello'))
 *
 * if (error) {
 *   // handle error
 *   return
 * }
 *
 * // type of response is string as error flow has been handled
 * typeof response === 'string'
 * ```
 */
export async function expected<R, E = Error>(promise: Promise<R>): Expected<R, E> {
  try {
    return [await promise, undefined]
  } catch (e) {
    return createExpectedError(e as E)
  }
}

/**
 * Create error tuple for expected return value
 *
 * @example
 * ```ts
 * import { expectedHandler, createExpectedError } from '@attest/util'
 *
 * const [response, error] = expectedHandler(expected () => {
 *   if (condition) return createExpectedError(new Error('boo'))
 *
 *   return Promise.resolve('hello')
 * })
 * ```
 */
export function createExpectedError<E>(error: E): ExpectedError<E> {
  return [undefined, error]
}

/**
 * Create resolved tuple for expected return value
 *
 * @example
 * ```ts
 * import { expectedHandler, createExpectedResolved } from '@attest/util'
 *
 * const [response, error] = expectedHandler(expected () => {
 *   return createExpectedResolved([])
 * })
 * ```
 */
export function createExpectedResolved<R>(response?: R): ExpectedResolved<R> {
  return [response as R, undefined]
}

/**
 * Assert that is error type and not undefined, useful in testing
 *
 * @example
 * ```ts
 * import { expected, assertExpectedError } from '@attest/util'
 *
 * it('should do x', () => {
 *    const [_, error] = expected(Promise.reject('hello'))
 *    assertExpectedError(error)
 *
 *    expect(error).toStrictEqual('hello')
 * })
 * ```
 */
export function assertExpectedError<E>(e: E): asserts e is E {
  if (e === undefined) throw new Error('is not error')
}

/**
 * Check if is expected error
 *
 * @example
 * ```ts
 * import { expected, isExpectedError } from '@attest/util'
 *
 * const result = await expected(Promise.reject('hello'))
 * if (isExpectedError(result)) return
 *
 * // type of response is string as error flow has been handled
 * typeof result[0] === 'undefined'
 * ```
 */
export function isExpectedError<K, E>(e: Awaited<Expected<K, E>>): e is ExpectedError<E> {
  return e[1] !== undefined
}

/**
 * Check if is expected error
 *
 * @example
 * ```ts
 * import { expected, isExpectedResolved } from '@attest/util'
 *
 * const result = await expected(Promise.resolve()))
 * if (isExpectedResolved(result)) return
 *
 * ```
 */
export function isExpectedResolved<K, E>(e: Awaited<Expected<K, E>>): e is ExpectedResolved<K> {
  return !isExpectedError(e)
}
