import { nanoid } from 'nanoid'
import type { MarkRequired } from 'ts-essentials'

import { STATIC_ANSWER_ID } from '@attest/study'

import type {
  StudyResultsAnswer,
  StudyResultsAnswerChoiceResponse,
  StudyResultsAnswerOrderedResponse,
  StudyResultsAnswerResponse,
  StudyResultsAnswerTextResponse,
} from './model'

export function createStudyResultsAnswer<
  T extends StudyResultsAnswerResponse = StudyResultsAnswerResponse,
>(override: MarkRequired<Partial<StudyResultsAnswer<T>>, 'type'>): StudyResultsAnswer<T> {
  return {
    id: nanoid(),
    responseIds: new Set([]),
    responseIdToResponse: {},
    ...override,
  }
}

export function createStudyResultsOtherAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerTextResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerTextResponse> {
  return {
    ...createStudyResultsTextAnswer(),
    id: STATIC_ANSWER_ID.OTHER,
    ...override,
  }
}

export function createStudyResultsNAAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerChoiceResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerChoiceResponse> {
  return {
    ...createStudyResultsChoiceAnswer(),
    id: STATIC_ANSWER_ID.NA,
    ...override,
  }
}

export function createStudyResultsNoneAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerChoiceResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerChoiceResponse> {
  return {
    ...createStudyResultsChoiceAnswer(),
    id: STATIC_ANSWER_ID.NONE,
    ...override,
  }
}

export function createStudyResultsSkippedAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerChoiceResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerChoiceResponse> {
  return {
    ...createStudyResultsChoiceAnswer(),
    id: STATIC_ANSWER_ID.SKIPPED,
    ...override,
  }
}

export function createStudyResultsChoiceAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerChoiceResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerChoiceResponse> {
  return {
    ...createStudyResultsAnswer({ type: 'choice' }),
    ...override,
  }
}

export function createStudyResultsTextAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerTextResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerTextResponse> {
  return {
    ...createStudyResultsAnswer({ type: 'text' }),
    ...override,
  }
}

export function createStudyResultsOrderedAnswer(
  override: Partial<StudyResultsAnswer<StudyResultsAnswerOrderedResponse>> = {},
): StudyResultsAnswer<StudyResultsAnswerOrderedResponse> {
  return {
    ...createStudyResultsAnswer({ type: 'ordered' }),
    ...override,
  }
}

export function createStudyResultAnswerTextResponse(
  override: Partial<StudyResultsAnswerTextResponse>,
): StudyResultsAnswerTextResponse {
  return {
    id: nanoid(),
    text: '',
    ...override,
  }
}
