import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useAudienceEditorStore } from '@attest/audience'
import {
  getMaximumQuestionsForAudience,
  getMaximumQuestionsInGraph,
  nodesReachableForAudience,
} from '@attest/editor-routing-graph'

import { useEditorSurveyStore } from './store'

const EDITOR_ROUTING_GRAPH_STORE_NAMESPACE = 'editorRoutingGraph'

export const useEditorRoutingGraphStore = defineStore(EDITOR_ROUTING_GRAPH_STORE_NAMESPACE, () => {
  const editorSurveyStore = useEditorSurveyStore()
  const audienceEditorStore = useAudienceEditorStore()

  const countOfQuestionsInLongestRouteForAudiences = computed(() =>
    Object.fromEntries(
      audienceEditorStore.filledAudiences.map(audience => [
        audience.id,
        getMaximumQuestionsForAudience(editorSurveyStore.routingGraph, audience.id),
      ]),
    ),
  )

  const countOfQuestionsInLongestRouteOverall = computed(() =>
    getMaximumQuestionsInGraph(editorSurveyStore.routingGraph),
  )

  const cardsReachableForAudiences = computed<{ [audienceId: string]: Set<string> }>(() =>
    Object.fromEntries(
      audienceEditorStore.filledAudiences.map(audience => [
        audience.id,
        nodesReachableForAudience({
          graph: editorSurveyStore.routingGraph,
          audienceId: audience.id,
        }),
      ]),
    ),
  )

  return {
    countOfQuestionsInLongestRouteForAudiences,
    countOfQuestionsInLongestRouteOverall,
    cardsReachableForAudiences,
  }
})
