import axios, { type AxiosError } from 'axios'

import {
  type ApiError,
  type InternalError,
  isAppError,
  reasonErrorToInternalError,
  service,
  type ServiceError,
  type ServiceRequest,
  type ServiceResponse,
} from '@attest/_api'
import type { ApiGridQuestionCard } from '@attest/_api/model/card'
import type { ApiNode } from '@attest/_api/model/node'
import type { PostSurveyConvertedResponseData } from '@attest/_api/model/survey-convert'
import type { ApiSurveyListingV2Item } from '@attest/_api/model/surveys'
import { capture } from '@attest/telemetry'
import { expectedHandler, type ExpectedHandler } from '@attest/util'

import type { ExclusiveSample } from '../exclusive-samples'
import type { Survey, SurveyGuid, SurveyStatus } from '../model'
import { zipSurveyCardQuestionGuids } from '../util'

import type {
  ApiGetExclusiveSamples,
  ApiListSurveys,
  ApiListSurveysV2,
  ApiSurvey,
  CloneByGuidRequestData,
  CloneByGuidResponseData,
  CloseByGuidRequestData,
  GetSurveyRequestData,
  ListSurveysRequestData,
  ListSurveysV2RequestData,
  OpenByGuidRequestData,
  PutAnswerQuotasRequestData,
  RemoveQuotasRequestData,
  ShareSurveyStatusRequestData,
  StudyListingQueryOptions,
  SurveysResponseData,
} from './model'
import { toSurveysModel, toSurveysModelV2 } from './transformer'

export const RequestUrl = {
  GET_SURVEY: (guid: string) => `/api/survey/${guid}` as const,
  GET_EXCLUSIVE_SAMPLES: (guid: string) => `/api/survey/${guid}/exclusive-samples` as const,
  LIST_SURVEYS: '/api/surveys',
  LIST_SURVEYS_V2: '/api/v2/surveys',
  CLONE_SURVEY: (guid: string) => `/json/clone/surveys/${guid}` as const,
  OPEN_SURVEY: '/json/surveys/open',
  CLOSE_SURVEY: '/json/surveys/close',
  SHARE_SURVEY: '/json/surveys/share',
  ANSWER_QUOTAS: (guid: string) => `/api/surveys/${guid}/answer-quotas` as const,
  REMOVE_QUOTAS: (guid: string) => `/api/surveys/${guid}/target-segmentations` as const,
  INTERNAL_TITLE: (guid: string) => `/api/surveys/${guid}/internal-title` as const,
  STUDY_UNGROUP: (id: string, params?: string) =>
    `/api/study/${id}/ungrouped${params ? `?${params}` : ''}` as const,
  STUDY: ({ id }: { id: string }): string => `/api/study/${id}` as const,
  GET_STUDY_LIST: (options: StudyListingQueryOptions) =>
    `/api/study?${studyListingQueryOptionsToURLSearchParams(options)}`,
  GENERATE_STUDY: ({ surveyGuids }: { surveyGuids: SurveyGuid[] }): string =>
    `/api/study/${surveyGuids.join(',')}/generated`,
}

export async function get(
  data: GetSurveyRequestData,
): Promise<ServiceResponse<SurveysResponseData<ApiSurvey>>> {
  const requestUrl = RequestUrl.GET_SURVEY(data.guid)
  const response = await service().get<ApiSurvey>(requestUrl)

  return {
    ...response,
    data: { success: response.data },
  }
}

export const list: ServiceRequest<
  { surveys: Record<string, Survey> },
  ApiError,
  ListSurveysRequestData
> = async data => {
  const requestUrl = `${RequestUrl.LIST_SURVEYS}?${createListSurveysRequestQuery(data).toString()}`

  try {
    const response = await service().get<ApiListSurveys>(requestUrl)
    const success = response.data

    return {
      surveys: toSurveysModel(success),
    }
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export const listV2: ServiceRequest<
  ApiListSurveysV2,
  ApiError,
  ListSurveysV2RequestData
> = async payload => {
  try {
    const response = await service().get<{
      values: ApiSurveyListingV2Item[]
      pagination: { next: string }
    }>(`${RequestUrl.LIST_SURVEYS_V2}?${createListSurveysV2RequestQuery(payload).toString()}`)
    const { values, pagination } = response.data

    return {
      surveys: toSurveysModelV2(values),
      commentsCounts: Object.fromEntries(
        values.map(({ guid, comment_count: commentCount }) => [guid, commentCount]),
      ),
      pagination,
    }
  } catch (error) {
    capture(error)
    return reasonErrorToInternalError(error)
  }
}

export const createListSurveysV2RequestQuery = (
  payload: ListSurveysV2RequestData,
): URLSearchParams => {
  return new URLSearchParams([
    ...(payload.guids?.map(guid => ['guid', guid]) ?? []),
    ...(payload.tagIds?.map(tagId => ['tag_id', tagId]) ?? []),
    ...(payload.questionGuids?.map(questionGuid => ['question_guid', questionGuid]) ?? []),
    ...(payload.owner ? [['owner', payload.owner === 'own' ? 'me' : `${payload.owner}`]] : []),
    ...(payload.state ? [['state', payload.state?.join(',')]] : []),
    ...(payload.search ? [['search', payload.search]] : []),
    ...(payload.pageSize ? [['page_size', `${payload.pageSize}`]] : []),
    ...(payload.organisationId ? [['organisation_id', `${payload.organisationId}`]] : []),
    ...(payload.userId ? [['maker_id', `${payload.userId}`]] : []),
    ...(payload.country ? [['country_code', payload.country]] : []),
  ])
}

export const listV2More: ServiceRequest<
  ApiListSurveysV2,
  ApiError,
  { cursor: string }
> = async payload => {
  try {
    const response = await service().get<{
      values: ApiSurveyListingV2Item[]
      pagination: { next: string }
    }>(
      `${RequestUrl.LIST_SURVEYS_V2}?${new URLSearchParams({
        cursor: payload.cursor,
      }).toString()}`,
    )
    const { values, pagination } = response.data

    return {
      surveys: toSurveysModelV2(values),
      commentsCounts: Object.fromEntries(
        values.map(({ guid, comment_count: commentCount }) => [guid, commentCount]),
      ),
      pagination,
    }
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export const createListSurveysRequestQuery = (data: ListSurveysRequestData): URLSearchParams => {
  const queryParam = new URLSearchParams()
  queryParam.append('drafts', `${data.drafts}`)

  if (data.include) {
    data.include.forEach(include => queryParam.append('include', `${include}`))
  }

  if (data.guids) {
    data.guids.forEach(guid => queryParam.append('guid', `${guid}`))
  }

  if (data.questionIds) {
    data.questionIds.forEach(questionId => queryParam.append('question_id', `${questionId}`))
  }

  if (data.questionGuids) {
    data.questionGuids.forEach(questionGuid =>
      queryParam.append('question_guid', `${questionGuid}`),
    )
  }

  if (data.owner) {
    queryParam.append('owner', data.owner === 'own' ? 'me' : `${data.owner}`)
  }
  return queryParam
}

function studyListingQueryOptionsToURLSearchParams(
  options: StudyListingQueryOptions,
): URLSearchParams {
  const studyListURLSearchParams = new URLSearchParams()

  if (options.organisationId) {
    studyListURLSearchParams.set('organisation_guid', options.organisationId)
  }

  return studyListURLSearchParams
}

export async function getQuestionGuidsForSurveyGuids(surveyGuids: string[]): Promise<string[]> {
  const response = await list({
    drafts: false,
    owner: 'all',
    include: ['cards'],
    guids: surveyGuids,
  })

  if (isAppError(response)) throw response

  return zipSurveyCardQuestionGuids(Object.values(response.surveys).map(({ cards }) => cards))
}

export async function listExclusiveSamplesForSurvey(request: {
  guid: string
}): Promise<ExclusiveSample[] | InternalError<ApiError>> {
  try {
    const requestUrl = RequestUrl.GET_EXCLUSIVE_SAMPLES(request.guid)
    const response = await service().get<ApiGetExclusiveSamples[]>(requestUrl)
    return (response.data ?? []).map(toExclusiveSampleModel)
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export async function updateInternalTitle({
  guid,
  internalTitle,
}: {
  guid: string
  internalTitle: string
}): Promise<void | InternalError<ApiError>> {
  try {
    await service().put(RequestUrl.INTERNAL_TITLE(guid), { value: internalTitle })
    return
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export async function cloneByGuid(data: CloneByGuidRequestData) {
  try {
    const response = await service().post<CloneByGuidResponseData>(
      RequestUrl.CLONE_SURVEY(data.guid),
    )
    return response
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export async function openOrCloseSurvey({
  action,
  guid,
}: {
  guid: string
  action: 'open' | 'close'
}): Promise<void | InternalError<ServiceError>> {
  try {
    await (action === 'open' ? openByGuid({ guid }) : closeByGuid({ guid }))
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export async function closeByGuid(data: CloseByGuidRequestData) {
  const requestUrl = `${RequestUrl.CLOSE_SURVEY}/${data.guid}`

  return service().post(requestUrl)
}

export async function openByGuid(data: OpenByGuidRequestData) {
  const requestUrl = `${RequestUrl.OPEN_SURVEY}/${data.guid}`

  return service().post(requestUrl)
}

export async function shareSurveyStatus(data: ShareSurveyStatusRequestData) {
  const requestUrl = `${RequestUrl.SHARE_SURVEY}`
  const formData = new URLSearchParams()
  formData.append('guid', data.guid)
  formData.append('is-shared', `${data.isShared}`)

  return service().post<SurveysResponseData<boolean>>(requestUrl, formData)
}

export async function removeQuotas(data: RemoveQuotasRequestData) {
  const requestUrl = `${RequestUrl.REMOVE_QUOTAS(data.guid)}`
  try {
    const response = await service().put(requestUrl, {
      quotas: data.quotas.map(({ limit, segmentations }) => ({ limit, segmentations })),
      'target-segmentations': data.targetSegmentations,
    })
    return response
  } catch (error) {
    return reasonErrorToInternalError(error)
  }
}

export async function undoConvertStudy(studyId: string): Promise<void | InternalError<ApiError>> {
  try {
    await service().post<void>(RequestUrl.STUDY_UNGROUP(studyId, 'unhide_waves=true'))
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export async function updateStudyOwner(args: {
  studyId: string
  ownerId: string
}): Promise<Record<string, never> | InternalError<ApiError>> {
  try {
    await service().patch(RequestUrl.STUDY({ id: args.studyId }), {
      owned_by: args.ownerId,
    })
    return {}
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export async function saveStudyTitle({
  id,
  title,
}: {
  title: string
  id: string
}): Promise<void | InternalError<ApiError>> {
  try {
    await service().patch<void>(RequestUrl.STUDY({ id }), {
      internal_title: title,
    })
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export function isApiGridQuestion(node: ApiNode): node is ApiGridQuestionCard {
  return node.card_type === 'grid'
}

export async function updateAnswerQuotas(args: {
  guid: string
  requestData: PutAnswerQuotasRequestData
}): Promise<void | InternalError<ApiError>> {
  try {
    await service().put(RequestUrl.ANSWER_QUOTAS(args.guid), { ...args.requestData })
    return
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export async function updateSurveyArchive({
  guid,
  archive,
}: {
  guid: string
  archive: boolean
}): ExpectedHandler<{ status: SurveyStatus }, Error | AxiosError> {
  return expectedHandler(async () => {
    const response = await axios.patch(RequestUrl.STUDY({ id: guid }), { archive })
    const { status } = response.data
    return {
      status,
    }
  })
}

function toExclusiveSampleModel(exclusiveSample: ApiGetExclusiveSamples): ExclusiveSample {
  return {
    guid: exclusiveSample.guid,
    title: exclusiveSample.title,
    responses: exclusiveSample.responses,
    maxResponses: exclusiveSample.max_responses,
    startTimestamp: exclusiveSample.starts,
    timeZoneId: exclusiveSample.time_zone_id || null,
  }
}

export async function convertSurvey({
  surveyGuids,
}: {
  surveyGuids: SurveyGuid[]
}): Promise<{ studyId: string } | InternalError<ApiError>> {
  try {
    const { data } = await service().post<PostSurveyConvertedResponseData>(
      RequestUrl.GENERATE_STUDY({ surveyGuids }),
    )

    return transformApiConvertSurveyResponseData(data)
  } catch (e) {
    return reasonErrorToInternalError(e)
  }
}

export function transformApiConvertSurveyResponseData({ id }: PostSurveyConvertedResponseData): {
  studyId: string
} {
  return { studyId: id }
}
