import axios from 'axios'
import type { Pinia } from 'pinia'
import type { RouteLocation, Router } from 'vue-router'

import { ERROR_MESSAGES_GENERAL } from '@attest/_constant'
import { useAppErrorStore } from '@attest/app-error'
import { CONFIG } from '@attest/config'
import { getVariation, identify, initializeClient, toContext } from '@attest/feature-switch'
import { HttpError } from '@attest/server/error'
import { capture } from '@attest/telemetry'
import { useUserStore } from '@attest/user'

function routePassesFeatureSwitchTest(route: Pick<RouteLocation, 'meta'>): boolean {
  if (!route.meta || !('featureSwitchKey' in route.meta)) return true
  return !!getVariation(route.meta.featureSwitchKey as any)
}

export async function setupFeatureSwitchRouteHandler(router: Router): Promise<void> {
  await router.isReady()
  if (!routePassesFeatureSwitchTest(router.currentRoute.value)) {
    useAppErrorStore().set(new HttpError(ERROR_MESSAGES_GENERAL.NOT_FOUND, 404))
  }

  router.beforeResolve((to, _, next) => {
    if (routePassesFeatureSwitchTest(to)) {
      next()
    } else {
      useAppErrorStore().set(new HttpError(ERROR_MESSAGES_GENERAL.NOT_FOUND, 404))
      next()
    }
  })
}

export async function setupFeatureSwitchClient(router: Router, pinia: Pinia): Promise<void> {
  try {
    const context = toContext({ ...useUserStore().$state }, { preview: CONFIG.APP.PREVIEW_ID })
    const { data } = await axios.get(`/fs/validate/${context.key}`)
    await initializeClient(
      'Cypress' in window ? 'cypress' : CONFIG.APP.SDK.LAUNCHDARKLY_KEY,
      context,
      data.hash,
    )
    await identify(context, data.hash)
  } catch (error) {
    capture(error)
  }

  let authenticated = useUserStore().authenticated
  router.beforeResolve(async (_to, _from, next) => {
    try {
      if (useUserStore().authenticated !== authenticated) {
        authenticated = !authenticated
        const context = toContext({ ...useUserStore().$state }, { preview: CONFIG.APP.PREVIEW_ID })
        const { data } = await axios.get(`/fs/validate/${context.key}`)
        await identify(context, data.hash)
      }
      next()
    } catch (e) {
      capture(e)
      next()
    }
  })
}
