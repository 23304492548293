import {
  ExpressionCategory,
  FieldType,
  FilterBlock,
  FilterExpression,
  Logic,
  Operator,
} from '@attest/efl-antlr4'

import {
  ArrayOperator,
  type FilterNode,
  isFilterBlock,
  isFilterExpression,
  type Value,
} from './model'

function createFilterExpression(
  key: string,
  operator: Operator,
  value: Value,
  options: ExpressionOptions,
): FilterExpression {
  return new FilterExpression(
    key,
    createFieldType(value),
    createExpressionCategory(operator, value),
    // backwards support for includesany/includesnone operators
    operator === Operator.INCLUDES && options.valueArrayOperator === ArrayOperator.ANY
      ? Operator.INCLUDESANY
      : Operator.INCLUDES && options.valueArrayOperator === ArrayOperator.ALL
        ? Operator.INCLUDESALL
        : operator,
    value ?? null,
    !!options.not,
  )
}

function createExpressionCategory(operator: Operator, values: Value): ExpressionCategory {
  if (Array.isArray(values)) {
    return Operator.BETWEEN === operator ? ExpressionCategory.RANGE : ExpressionCategory.LIST
  }

  return ExpressionCategory.SCALAR
}

function createFieldType(value: Value): FieldType {
  if (!Array.isArray(value)) {
    switch (typeof value) {
      case 'number':
        return FieldType.NUMBER
      case 'string':
        return FieldType.STRING
      default:
        return FieldType.NULL
    }
  }

  return createFieldType(value[0])
}

export function NOT(expression: FilterExpression) {
  return new FilterExpression(
    expression.key,
    expression.type,
    expression.category,
    expression.operator,
    expression.value,
    true,
  )
}

export function INVERSE(expression: FilterExpression) {
  return new FilterExpression(
    expression.key,
    expression.type,
    expression.category,
    expression.operator,
    expression.value,
    !expression.not,
  )
}

type ExpressionOptions = {
  not?: boolean
  fieldArrayOperator?: ArrayOperator
  valueArrayOperator?: ArrayOperator
}

function createDefaultExpressionOptions(
  value: Value,
  options: ExpressionOptions,
): ExpressionOptions {
  if (options.valueArrayOperator !== undefined) {
    return options
  }

  return {
    not: options.not,
    fieldArrayOperator: options.fieldArrayOperator ?? ArrayOperator.NONE,
    valueArrayOperator: Array.isArray(value) ? ArrayOperator.ANY : ArrayOperator.NONE,
  }
}

export function EQUAL(key: string, value: Value = null, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.EQ,
    value ?? null,
    createDefaultExpressionOptions(value, options),
  )
}

export function IN(key: string, value: Value = null, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.IN,
    value ?? null,
    createDefaultExpressionOptions(value, options),
  )
}

export function INCLUDES(key: string, value: Value = null, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.INCLUDES,
    value ?? null,
    createDefaultExpressionOptions(value, options),
  )
}

export function BETWEEN(
  key: string,
  value: [min: number, max: number] | [min: number, max: number][],
  options: ExpressionOptions = {},
) {
  return createFilterExpression(
    key,
    Operator.BETWEEN,
    value as number[],
    createDefaultExpressionOptions(value as number[], options),
  )
}

export function LESS_THAN(key: string, value: number | number[], options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.LT,
    value,
    createDefaultExpressionOptions(value, options),
  )
}
export function LESS_THAN_OR_EQUAL(key: string, value: number, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.LE,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function GREATER_THAN(key: string, value: number, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.GT,
    value,
    createDefaultExpressionOptions(value, options),
  )
}
export function GREATER_THAN_OR_EQUAL(key: string, value: number, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.GE,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function CONTAINS(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.CONTAINS,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function STRICT_CONTAINS(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.STRICTCONTAINS,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function STARTS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.STARTSWITH,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function STRICT_STARTS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.STRICTSTARTSWITH,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function ENDS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.ENDSWITH,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

export function STRICT_ENDS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(
    key,
    Operator.STRICTENDSWITH,
    value,
    createDefaultExpressionOptions(value, options),
  )
}

function createFilterNode(items: FilterNode[], logic: Logic): FilterNode {
  if (items.length === 1) {
    return items[0]
  }
  if (items.every(child => isSameBlockOrExpression(child, logic))) {
    return new FilterBlock(
      items.flatMap(item => (isFilterBlock(item) ? item.items : [item])),
      logic,
    )
  }

  return new FilterBlock(items, logic)
}

export function AND(...items: FilterNode[] | [FilterNode[]]): FilterNode {
  return createFilterNode(items.flat(), Logic.AND)
}

// Used where we want to preserve the items as a block ie `(item IS a AND item IS b)`
export function AND_GROUP(...items: FilterNode[] | [FilterNode[]]): FilterBlock {
  return new FilterBlock(items.flat(), Logic.AND)
}

export function OR(...items: FilterNode[] | [FilterNode[]]): FilterNode {
  return createFilterNode(items.flat(), Logic.OR)
}

// Used where we want to preserve the items as a block ie `(item IS a OR item IS b)`
export function OR_GROUP(...items: FilterNode[] | [FilterNode[]]): FilterBlock {
  return new FilterBlock(items.flat(), Logic.OR)
}

export function NONE(item?: FilterNode | [FilterNode]): FilterBlock {
  return new FilterBlock(Array.isArray(item) ? item : item === undefined ? [] : [item], Logic.NONE)
}

export function filterNodeToBlock(node: FilterNode): FilterBlock {
  if (isFilterExpression(node)) {
    return NONE(node)
  }

  if (isFilterBlock(node)) {
    return node
  }

  throw new Error('can not convert node to block')
}

function isSameBlockOrExpression(child: FilterNode, logic: Logic): boolean {
  return (
    (isFilterBlock(child) && (child.logic === logic || child.logic === Logic.NONE)) ||
    isFilterExpression(child)
  )
}
