/**
 * Given a function that generates a key, turns a list of objects into an
 * object indexing the objects by the given key. Note that if multiple
 * objects generate the same value for the indexing key only the last value
 * will be included in the generated object.
 *
 * @example
 * ```ts
 * import { keyBy } from '@attest/util'
 *
 * const items = [{ id: 'a' }, { id: 'b' }]
 *
 * keyBy(items, item => item.id) // { a: { id: 'a' }, b: { id: 'b' } }
 * ```
 */
export function keyBy<T, K extends PropertyKey>(
  list: readonly T[],
  fn: (a: T) => K,
): { [key in K]: T } {
  return Object.fromEntries(list.map(item => [fn(item), item])) as { [key in K]: T }
}
