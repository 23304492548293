<script lang="tsx">
import { mapStores } from 'pinia'
import { defineComponent } from 'vue'

import { Dropdown } from '@attest/_components/src/common/Dropdown'
import { UserAvatar } from '@attest/_components/src/common/UserAvatar'
import { c } from '@attest/intl'
import { castSlots, createEmitter } from '@attest/vue-tsx'

import type { User } from '../../model'
import { useUserStore } from '../../store'

import type {
  OrganisationMembersDropdownEvents,
  OrganisationMembersDropdownSlots,
} from './OrganisationMembersDropdown.interface'

const emit = createEmitter<OrganisationMembersDropdownEvents>()

export default defineComponent({
  props: {
    selected: { type: String, default: undefined },
  },

  computed: {
    ...mapStores(useUserStore),

    members(): User[] {
      return this.userStore.organisation.users
    },
  },

  mounted() {
    this.userStore.listUsers()
  },

  render() {
    const slots = castSlots<OrganisationMembersDropdownSlots>(this.$slots)
    const baseClass = 'c-organisation-members-dropdown'
    return (
      <Dropdown
        class={baseClass}
        data-name="OrganisationMembersDropdown"
        hasSearch
        items={this.members.map(user => ({
          id: user.guid,
          text: `${user.email} ${user.firstName} ${user.lastName}`,
        }))}
        selectedItemId={this.selected ?? ''}
        placeholderText={c('organisation.members-dropdown.placeholder')}
        onSelectItem={({ id, event }) => {
          emit(this, 'input', {
            value: id,
            event,
          })
          emit(this, 'change', event)
        }}
        v-slots={{
          item: ({ id }) => {
            const member = this.members.find(({ guid }) => guid === id)
            if (!member) return undefined
            return (
              slots.item?.(member) ?? [
                <UserAvatar class={`${baseClass}__avatar`} user={member} />,
                member.firstName,
                ' ',
                member.lastName,
              ]
            )
          },
        }}
      />
    )
  },
})
</script>

<style lang="postcss">
.c-organisation-members-dropdown {
  --item-height: var(--attest-spacing-10);

  &__avatar {
    display: inline-flex;
    margin-right: var(--attest-spacing-2);
    margin-left: var(--attest-spacing-1);
  }
}
</style>
