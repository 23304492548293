import { isRangeOption, type RangeOption } from '@attest/audience-demographic'
import { filterSet, type Range, union } from '@attest/util'

import type {
  StudyResultsRespondent,
  StudyResultsRespondentSegmentation,
} from '../respondent/model'

import { demographicFilterGroups } from './demographic-filter-groups'

type DemographicFilters = Record<string, Range[] | string[]>

const demographicFilterNameToGroupName = Object.fromEntries(
  Object.entries(demographicFilterGroups).flatMap(([demographicGroupName, demographicNames]) =>
    demographicNames.map(demographicName => [demographicName, demographicGroupName]),
  ),
) as Record<string, keyof typeof demographicFilterGroups>

export function filterRespondentIdsByDemographics(
  respondentIds: Set<string>,
  respondentIdToRespondent: Record<string, StudyResultsRespondent>,
  demographics: DemographicFilters,
): Set<string> {
  // eslint-disable-next-line unicorn/no-array-reduce
  return Object.keys(demographics).reduce((filteredRespondentIds, name) => {
    const groupName = demographicFilterNameToGroupName[name]

    return union(
      (groupName ? demographicFilterGroups[groupName] : [name])
        .filter(demographicName => !!demographics[demographicName])
        .map(demographicName => {
          return getRespondentsMatchingDemographicFilters(
            { name: demographicName, filters: demographics[demographicName] },
            filteredRespondentIds,
            respondentIdToRespondent,
          )
        }),
    )
  }, respondentIds)
}

function getRespondentsMatchingDemographicFilters(
  { name, filters }: { name: string; filters: RangeOption[] | string[] },
  respondentIds: Set<string>,
  respondentIdToRespondent: Record<string, StudyResultsRespondent>,
): Set<string> {
  return filterSet(
    id => isRespondentMatchingDemographicFilters(respondentIdToRespondent[id], { name, filters }),
    respondentIds,
  )
}

function isRespondentMatchingDemographicFilters(
  respondent: StudyResultsRespondent,
  { name, filters }: { name: string; filters: RangeOption[] | string[] },
): boolean {
  const respondentSegmentation = respondent.nameToSegmentation[name]
  return (
    !!respondentSegmentation &&
    filters.some(filter => isSegmentationMatchingDemographicFilter(respondentSegmentation, filter))
  )
}

function isSegmentationMatchingDemographicFilter(
  segmentation: StudyResultsRespondentSegmentation,
  filter: RangeOption | string,
): boolean {
  if (isRangeOption(filter)) {
    return (
      typeof segmentation === 'number' && filter.min <= segmentation && filter.max >= segmentation
    )
  }

  return Array.isArray(segmentation) ? segmentation.includes(filter) : segmentation === filter
}
