import type { RouteLocationNamedRaw } from 'vue-router'

import { SETTINGS } from '@attest/editor-settings'

export const SURVEY_DRAFTER_ROUTE_NAME = {
  CARD_EDITOR: 'SURVEY_DRAFTER::CARD_EDITOR',
  CARD_EDITOR_LIST_VIEW: 'SURVEY_DRAFTER::CARD_EDITOR::LIST_VIEW',
  CARD_EDITOR_MAP_VIEW: 'SURVEY_DRAFTER::CARD_EDITOR::EDITOR_VIEW',
  AUDIENCE: 'SURVEY_DRAFTER::AUDIENCE',
  AUDIENCE_CONTENT_EDITOR: 'SURVEY_DRAFTER::AUDIENCE::CONTENT_EDITOR',
  AUDIENCE_EXCLUSIONS: 'SURVEY_DRAFTER::AUDIENCE::EXCLUSIONS',
  EDIT_AUDIENCE_EXCLUSIONS: 'SURVEY_DRAFTER::AUDIENCE::EXCLUSIONS::EDIT',
  REVIEW: 'SURVEY_DRAFTER::REVIEW',
  REVIEW_CHECKOUT: 'SURVEY_DRAFTER::REVIEW::CHECKOUT',
}

export type SurveyDrafterRouteName =
  (typeof SURVEY_DRAFTER_ROUTE_NAME)[keyof typeof SURVEY_DRAFTER_ROUTE_NAME]

export const CARD_EDITOR_VIEW = {
  LIST: 'list',
  MAP: 'map',
} as const

export const SurveyDrafterCardEditorViewToRouteName = {
  [CARD_EDITOR_VIEW.LIST]: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR_LIST_VIEW,
  [CARD_EDITOR_VIEW.MAP]: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR_MAP_VIEW,
}

export function createNewSurveyDrafterLocation(
  options: Partial<RouteLocationNamedRaw> = {},
): RouteLocationNamedRaw {
  return {
    name: SURVEY_DRAFTER_ROUTE_NAME.CARD_EDITOR,
    params: {
      guid: SETTINGS.DEFAULT_GUID,
    },
    ...options,
  }
}
