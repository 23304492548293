<script lang="tsx">
import { defineComponent } from 'vue'

import { create } from '@attest/vue-tsx'

export type ExpandAnimatingPaneProps = {}

const ExpandAnimatingPane = defineComponent({
  props: {},
  data() {
    return {
      timeoutRef: null as null | NodeJS.Timeout,
    }
  },
  beforeUpdate() {
    this.handleContentUpdate()
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
    }
    this.timeoutRef = setTimeout(() => {
      this.handleContentUpdateEnd()
    }, 180)
  },
  beforeUnmount() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
    }
  },
  methods: {
    getOuterRef() {
      const outer = this.$refs.outer
      if (!(outer instanceof Element)) {
        return
      }

      return outer as HTMLDivElement
    },

    isVisible(): boolean {
      const outer = this.getOuterRef()
      return outer ? outer.offsetParent !== null : false
    },

    passOuterHeightToInner() {
      const innerHeightPx = this.getInnerHeight()
      if (innerHeightPx === null) {
        return
      }

      if (innerHeightPx === 0 && !this.isVisible()) {
        return
      }

      this.setOuterHeight(innerHeightPx)
    },

    clearOuterHeight() {
      this.setOuterHeight(null)
    },

    setOuterHeight(heightPx: null | number) {
      const outer = this.$refs.outer
      if (!(outer instanceof HTMLElement)) {
        return
      }

      const styleHeight = heightPx !== null ? `${heightPx}px` : ''
      outer.style.height = styleHeight
    },

    getInnerHeight(): number | null {
      const inner = this.$refs.inner
      if (!(inner instanceof Element)) {
        return null
      }

      return inner.scrollHeight
    },

    handleContentUpdate() {
      this.passOuterHeightToInner()
      this.$nextTick(this.passOuterHeightToInner)
    },

    handleContentUpdateEnd() {
      this.clearOuterHeight()
    },
  },

  render() {
    const baseClass = 'c-expand-animating-pane'

    return (
      <div class={baseClass} ref="outer">
        <div class={`${baseClass}__inner`} ref="inner">
          {this.$slots.default?.()}
        </div>
      </div>
    )
  },
})

export default create<ExpandAnimatingPaneProps>(ExpandAnimatingPane)
</script>

<style lang="postcss">
.c-expand-animating-pane {
  overflow: visible;
  transition: height var(--attest-timing-default);

  &__inner {
    min-width: 100%;
  }
}
</style>
