import type { MultiDirectedGraph } from 'graphology'
import type { Attributes } from 'graphology-types'

export type RouteNode = {
  guid: string
  type: 'question' | 'text' | 'group' | null
  nextGuid: string | null
  position: { x: number; y: number }
  group: {
    cards: RouteNode[]
  }
  omittedFromAudiences: Set<string>
  question: {
    options: {
      maskingQuestion: {
        isToggled: boolean
        senderGuid: string
      } | null
      answers: {
        id: string
        nextGuid: string | null
        omittedFromAudiences: Set<string>
        mask: { answerId: string } | null
      }[]
    }
  }
}

export type RoutingGraphAttributes = {} & Attributes

export type RoutingNodeAttributes = {
  type: 'question' | 'text' | 'group' | null
  x: number
  y: number
  omittedFromAudiences: Set<string>
} & Attributes

export type RoutingEdgeAttributes = {
  type: 'DEFAULT' | 'ANSWER'
  omittedFromAudiences: Set<string>
} & Attributes

export type RoutingGraph = {} & MultiDirectedGraph<
  RoutingNodeAttributes,
  RoutingEdgeAttributes,
  RoutingGraphAttributes
>

export function isRoutedNode<T extends { nextGuid: string | null }>(
  node: T,
): node is T & { nextGuid: string } {
  return node.nextGuid !== null
}
