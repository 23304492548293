import { isNil, isNotNil } from 'ramda'
import type {
  LocationQueryValue,
  RouteLocationNormalizedLoaded,
  RouteLocationResolvedGeneric,
} from 'vue-router'

export function getStoreKeyForRoute(
  route: RouteLocationNormalizedLoaded | RouteLocationResolvedGeneric,
): string {
  if (route.name === 'STUDY::INSIGHTS::TRENDS') return `${route.name}::${route.params.studyId}`
  return `${route.params.studyId}`
}

export function parseQueryValue(value: LocationQueryValue | LocationQueryValue[]): string[] {
  if (isNil(value)) return []
  if (Array.isArray(value)) return value.filter(isNotNil)
  return value.split(',')
}
