import axios, { type AxiosError } from 'axios'

import { type ApiError, type InternalError, reasonErrorToInternalError } from '@attest/_api'
import { capture } from '@attest/telemetry'
import { type ExpectedHandler, expectedHandler, keyBy } from '@attest/util'

import type { EmptyStudyResultsCard, StudyResultsRespondent } from '../../models'

import type {
  ApiStudyResultsResponseData,
  ApiStudyResultsVideoResponsesData,
  StudyResultsVideoResponsesData,
} from './model'
import {
  createIdToEmptyStudyResultsCard,
  createRespondent,
  createVideoResponse,
} from './transformer'

export type {
  ApiStudyResultsResponseData,
  ApiStudyResultsVideoResponsesData,
  StudyResultsVideoResponsesData,
}

export type StudyResultsResponseData = {
  cardIdToCard: Record<string, EmptyStudyResultsCard>
  respondentIdToRespondent: Record<string, Readonly<StudyResultsRespondent>>
}

export async function getStudyResultsByWave(args: {
  id: string
  waveSurveyIds: string[]
}): ExpectedHandler<
  {
    cardIdToCard: Record<string, EmptyStudyResultsCard>
    respondentIdToRespondent: Record<string, Readonly<StudyResultsRespondent>>
  },
  AxiosError
> {
  return expectedHandler(async () => {
    const { data } = await axios.get<ApiStudyResultsResponseData>(
      `/api/v2/study/${args.id}/results?waves=${args.waveSurveyIds.join(',')}`,
    )

    return {
      cardIdToCard: createIdToEmptyStudyResultsCard(data.responses),
      respondentIdToRespondent: keyBy(data.responses.map(createRespondent), ({ id }) => id),
    }
  })
}

export async function getVideoResponses(args: {
  videoIds: string[]
}): Promise<StudyResultsVideoResponsesData | InternalError<ApiError>> {
  try {
    const { data } = await axios.post<ApiStudyResultsVideoResponsesData>(
      '/api/service/video/public/v1/video/access',
      { video_ids: args.videoIds },
    )

    return createVideoResponse(data)
  } catch (e) {
    if (e.response.status !== 404) capture(e)
    return reasonErrorToInternalError(e)
  }
}
