import { defineStore } from 'pinia'

import {
  type SampleSizeError,
  type TargetingError,
  type TargetingsError,
  trackEditorErrors,
} from '@attest/_lib/src/editor-error/editor-error'

import { isOwnAudience } from '../model'
import { getSampleSizeErrors } from '../sample-size'
import { useAudienceEditorStore } from '../store'
import { getTargetingErrors, getTargetingsErrors } from '../targeting'

export type AudienceEditorErrorState = {
  sampleSizeErrors: Record<string, SampleSizeError[]>
  targetingErrors: Record<string, TargetingError[]>
  targetingsErrors: TargetingsError[]
}

export const AUDIENCE_EDITOR_ERROR_STORE_NAMESPACE = 'audienceEditorError'

export function createAudienceEditorErrorState(
  override: Partial<AudienceEditorErrorState> = {},
): AudienceEditorErrorState {
  return {
    sampleSizeErrors: {},
    targetingErrors: {},
    targetingsErrors: [],
    ...override,
  }
}

export const useAudienceEditorErrorStore = defineStore(AUDIENCE_EDITOR_ERROR_STORE_NAMESPACE, {
  state: createAudienceEditorErrorState,
  actions: {
    updateSampleSizeErrorsForaudienceId({
      audienceId,
      isVideoResponseSurvey,
    }: {
      audienceId: string
      isVideoResponseSurvey: boolean
    }): void {
      const audienceEditorStore = useAudienceEditorStore()
      const audience = audienceEditorStore.audiences.find(({ id }) => id === audienceId)
      if (!audience) return

      this.sampleSizeErrors[audienceId] = getSampleSizeErrors({
        sampleSize: audience.sampleSize,
        availableSampleSize: audienceEditorStore.availableSampleSizes[audienceId] ?? null,
        audienceType: 'panel',
        isVideoResponseSurvey: isVideoResponseSurvey,
      })
    },
    updateTargetingErrorsForAudienceId({
      audienceId,
      isVideoResponseSurvey,
    }: {
      audienceId: string
      isVideoResponseSurvey: boolean
    }): void {
      const audienceEditorStore = useAudienceEditorStore()
      const audience = audienceEditorStore.audiences.find(({ id }) => id === audienceId)
      if (!audience) return

      this.targetingErrors[audienceId] = getTargetingErrors({
        sampleSize: audience.sampleSize,
        segmentations: audience.segmentations,
        quotas: audience.quotas,
        surveyIsVideoResponseSurvey: isVideoResponseSurvey,
      })
    },

    updateSampleSizeErrors({ isVideoResponseSurvey }: { isVideoResponseSurvey: boolean }): void {
      const currentSampleSizeErrors = [...this.sampleSizeErrorsFlat]
      this.sampleSizeErrors = {}
      useAudienceEditorStore().audiences.forEach(({ id }) =>
        this.updateSampleSizeErrorsForaudienceId({ audienceId: id, isVideoResponseSurvey }),
      )
      trackEditorErrors(currentSampleSizeErrors, this.sampleSizeErrorsFlat)
    },

    updateTargetingErrors({ isVideoResponseSurvey }: { isVideoResponseSurvey: boolean }): void {
      const currentTargetingErrors = [...this.targetingErrorsFlat]
      useAudienceEditorStore().audiences.forEach(audience =>
        this.updateTargetingErrorsForAudienceId({
          audienceId: audience.id,
          isVideoResponseSurvey,
        }),
      )
      trackEditorErrors(currentTargetingErrors, this.targetingErrorsFlat)
    },

    updateTargetingsErrors(): void {
      const currentTargetingsErrors = this.targetingsErrors
      this.targetingsErrors = getTargetingsErrors({
        targetings: useAudienceEditorStore().audiences,
        audienceType: useAudienceEditorStore().type,
        draftLanguage: useAudienceEditorStore().draftLanguage ?? '',
      })
      trackEditorErrors(currentTargetingsErrors, this.targetingsErrors)
    },
  },
  getters: {
    sampleSizeErrorsFlat(): SampleSizeError[] {
      if (isOwnAudience(useAudienceEditorStore().type)) return []
      return [
        ...new Set(
          Object.values(this.sampleSizeErrors)
            .flat()
            .map(({ type }) => type),
        ),
      ].map(error => ({ type: error }))
    },

    targetingErrorsFlat(): TargetingError[] {
      return [
        ...new Set(
          Object.values(this.targetingErrors)
            .flat()
            .map(({ type }) => type),
        ),
      ].map(error => ({ type: error }))
    },
  },
})
