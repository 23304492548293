import type { FunctionDirective, ObjectDirective } from 'vue'

const HANDLER = 'DIRECTIVE_FOCUS_WINDOW'
type Handler = HTMLElement & { DIRECTIVE_FOCUS_WINDOW: (e: any) => void }

const bind: FunctionDirective<HTMLElement, () => void> = (el, binding) => {
  const value = binding.value
  const elBinding = el as Handler
  window.removeEventListener('focus', elBinding[HANDLER])
  elBinding[HANDLER] = () => value()
  window.addEventListener('focus', elBinding[HANDLER])
}

export const focusWindow: ObjectDirective<HTMLElement, () => void> = {
  beforeMount: bind,

  updated(el, binding, vnode, oldVnode) {
    if (binding.value === binding.oldValue) return
    bind(el, binding, vnode, oldVnode)
  },

  unmounted(el) {
    const elBinding = el as Handler

    window.removeEventListener('focus', elBinding[HANDLER])
  },
}
