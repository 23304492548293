import { type ResponseData, service, type ServiceResponse } from '@attest/_api'
import { centsToCreditCents } from '@attest/_lib/src/pricing/pricing'
import type { RegistrationExperience } from '@attest/authentication'

import type { PricingModel, ProfileRole } from './model'
import { toProfileRole } from './transformer'

const RequestUrl = {
  PROFILE: '/json/profile',
} as const

type WalletRaw = {
  balance: string | null
  plan: PricingModel
}

type LegalRaw = {
  'data-controller-email': string | null
  'legal-name': string | null
  'privacy-policy-url': string | null
}

function walletResponseToCreditCents(wallet: null | WalletRaw): number {
  if (wallet === null || !wallet.balance) return 0
  const cents = Number.parseFloat(wallet.balance.replace(/[\s,a-z]+/gi, '')) * 100
  const credits = centsToCreditCents(cents)
  return credits
}

export type UserGetResponseDataRaw = {} & ResponseData<{
  guid: string
  'first-name': string
  'last-name': string
  email: string
  'intercom-user-hash': string
  wallet: null | WalletRaw
  'organisation-guid': string
  'organisation-name': string
  'registration-timestamp': number
  roles?: string[]
  experience?: RegistrationExperience
  'legal-entity': null | LegalRaw
}>

export type UserGetResponseData = {} & ResponseData<{
  user: {
    guid: string
    firstName: string
    lastName: string
    email: string
    role: ProfileRole[] | undefined
    registrationTimestamp: number
  }
  organisation: {
    guid: string | null
    name: string | null
  }
  wallet: {
    balance: number | null
    plan: PricingModel | null
  }
  legalEntity: {
    dataControllerEmail: string | null
    legalName: string | null
    privacyPolicyUrl: string | null
  }
}>

export async function getUserDetails(): Promise<ServiceResponse<UserGetResponseData>> {
  const response = await service().get<UserGetResponseDataRaw>(RequestUrl.PROFILE)
  const { success } = response.data
  const tranformed = {
    user: {
      guid: success.guid,
      firstName: success['first-name'],
      lastName: success['last-name'],
      intercomUserHash: success['intercom-user-hash'],
      email: success.email,
      role: success.roles?.map(toProfileRole) ?? [],
      experience: success.experience || null,
      registrationTimestamp: success['registration-timestamp'],
    },
    organisation: {
      guid: success['organisation-guid'] || null,
      name: success['organisation-name'] || null,
    },
    wallet: {
      balance: walletResponseToCreditCents(success.wallet),
      plan: success.wallet ? success.wallet.plan : null,
    },
    legalEntity: {
      dataControllerEmail: success['legal-entity']
        ? success['legal-entity']['data-controller-email']
        : null,
      legalName: success['legal-entity'] ? success['legal-entity']['legal-name'] : null,
      privacyPolicyUrl: success['legal-entity']
        ? success['legal-entity']['privacy-policy-url']
        : null,
    },
  }

  const result: ServiceResponse<UserGetResponseData> = response as any
  result.data.success = tranformed
  return result
}
