import { COMPANY_SECTORS, COMPANY_SIZES } from './constant'

export type CompanySector = (typeof COMPANY_SECTORS)[number]['value']

export type CompanySize = (typeof COMPANY_SIZES)[number]['value']

export function isCompanySize(size: string | null | undefined): size is CompanySize {
  return COMPANY_SIZES.some(keyValue => keyValue.value === size)
}

export function isCompanySector(sector: string | null | undefined): sector is CompanySector {
  return COMPANY_SECTORS.some(keyValue => keyValue.value === sector)
}

export type InterestTag = {
  name: string
  isSelected: boolean
}

export type MFAType = 'totp'

export type Profile = {
  guid: string
  email: string
  firstName: string
  lastName: string
  jobRole?: string | null
  phoneNumber?: string
  hasResearchExperience?: boolean | null
  interestTags?: InterestTag[]
  companySector?: string
  companySize?: string
  mfa?: MFAType
}

export type UserProfileCompleteness = {
  hasEmail: boolean
  hasPersonalInfo: boolean
  hasCompanyInfo: boolean
  hasInterests: boolean
}
