import type { Router } from 'vue-router'

import { capture } from '@attest/telemetry'
import { trackAction } from '@attest/tracking'

import { useAppAutoUpdateStore } from './store'

export async function setupAppAutoUpdate(router: Router): Promise<void> {
  await router.isReady()
  router.beforeResolve(async (to, from, next) => {
    try {
      if (to.path === from.path) {
        next()
        return
      }

      const [latest, current] = await useAppAutoUpdateStore().getLatestVersion()

      if (current.hash !== undefined && latest.hash !== current.hash) {
        trackAction({ id: 'auto-update-application', entities: [] })
        window.location.assign(to.fullPath)
      }

      next()
    } catch (error) {
      capture(error)
      next()
    }
  })
}
