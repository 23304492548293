import { type ResponseData, service } from '@attest/_api'

export type UserForgotPasswordResponseData = {} & ResponseData<Record<string, never>>

export type UserForgotPasswordRequestData = {
  email: string
}

const FORGOT_PASSWORD_URL = '/json/password-reset'

export async function forgotPassword(data: UserForgotPasswordRequestData) {
  const formData = new URLSearchParams()
  formData.append('email', data.email)

  return service().post<UserForgotPasswordResponseData>(FORGOT_PASSWORD_URL, formData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  })
}
