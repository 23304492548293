export type Config = {
  APP: {
    ENV?: string
    PREVIEW_ID?: number
    VERSION: string
    IMAGE: string
    DEVTOOLS: boolean
    PERFORMANCE_TOOLS: boolean
    DISABLE_SERVICE_WORKER: boolean
    LANDING_URL: string
    API_BASE_URL?: string
    MAKER_URL: string
    TAKER_URL: string
    OPINION_URL: string
    SDK: {
      GOOGLE_TAG_MANAGER_ID: string
      GOOGLE_TAG_MANAGER_ENV: string
      GOOGLE_TAG_MANAGER_AUTH: string
      SENTRY_URL: string
      CLICKSTREAM_ENDPOINT: string
      CLICKSTREAM_APP_ID: string
      LAUNCHDARKLY_KEY: string
      CALENDLY_URL?: string
      CALENDLY_ZAPIER_URL?: string
    }
    PRICING: {
      CREDIT_VALUE_PENCE: number
      CREDIT_UNIT_COST_PENCE: number
    }
    MEDIA: {
      MAX_IMAGE_FILE_SIZE: number
      MAX_IMAGE_WIDTH: number
      MAX_IMAGE_HEIGHT: number
      MAX_AUDIO_DURATION: number
      MIN_AUDIO_DURATION: number
      MAX_AUDIO_FILE_SIZE: number
      MAX_VIDEO_DURATION: number
    }
    TRIPLE_WIN: {
      EXPIRY_DAYS: number
      MAX_RESPONSES: number
      PRICE_EX_VAT_POUNDS: number
    }
  }
  SERVER: {
    CERTS?: {
      DOMAIN: string | null
    }
    SERVICE_NAME: string
    MAKER: {
      SERVICE_NAME: string
      URL: string
    }
    SDK: {
      LAUNCHDARKLY_KEY: string
    }
    LOGGER: {
      COLOR: boolean
      LEVEL: 'DEBUG' | 'INFO' | 'WARN' | 'ERROR'
    }
    ZIPKIN: {
      URL: string | null
      LOG: boolean
      SAMPLE_RATE: number
    }
    SECURITY: {
      SAFE_FRAME_ANCESTORS: string[]
      CSP_REPORT_URI: string
    }
  }
}

export const CONFIG: Config =
  typeof globalThis === 'undefined' ? (window as any).__CONFIG__ : (globalThis as any).__CONFIG__
